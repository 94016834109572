import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import * as Notifications from 'react-notification-system-redux';
import * as _ from 'lodash';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import { ReceiveCurrentUser } from './Account';

export interface StatsState {
    requestStatsPrice: {
        requestTime?: number;
        isLoading: boolean;
    },
    includeOrigin: boolean;
    includeDestination: boolean;
    currencyId: number;
    statsPriceState: {
        dialogIsOpen: boolean;
        loadedRequestModel?: Api.RequestStatsPriceModel;
        statsPrice?: Api.StatsPriceModel;
        yearsSelected: { [year: number]: boolean };
        sizeTypeIdsSelected: { [year: number]: boolean };
        containerType: Api.CriteriaModelContainerTypeEnum;
    }
}

interface RequestStatsPrice {
    type: "REQUEST_STATS_PRICE";
    payload: { requestTime: number; };
}
interface ReceiveStatsPrice {
    type: "RECEIVE_STATS_PRICE";
    payload: {
        requestTime: number;
        statsPrice?: Api.StatsPriceModel,
        requestModel?: Api.RequestStatsPriceModel
    };
    error?: any;
}

interface UpdateStatsIncludeOrigin {
    type: "UPDATE_STATS_INCLUDEORIGIN";
    payload: { value: boolean; };
}
interface UpdateStatsIncludeDestination {
    type: "UPDATE_STATS_INCLUDEODESTINATION";
    payload: { value: boolean; };
}

interface UpdateStatsCurrencyId {
    type: "UPDATE_STATS_CURRENCYID";
    payload: { value: number; };
}

interface OpenStatsPriceDialog {
    type: "OPEN_STATSPRICE_DIALOG";
}
interface CloseStatsPriceDialog {
    type: "CLOSE_STATSPRICE_DIALOG";
}
interface UpdateStatsPriceYearIsSelected {
    type: "UPDATE_STATSPRICE_YEAR_ISSELECTED";
    payload: { value: boolean; year: number; };
}
interface UpdateStatsPriceSizeTypeIdIsSelected {
    type: "UPDATE_STATSPRICE_SIZETYPEID_ISSELECTED";
    payload: { value: boolean; sizeTypeId: number; };
}
interface UpdateStatsPriceContainerType {
    type: "UPDATE_STATSPRICE_CONTAINERTYPE";
    payload: { value: Api.CriteriaModelContainerTypeEnum };
}

type KnownAction = ReceiveCurrentUser
    | RequestStatsPrice
    | ReceiveStatsPrice
    | UpdateStatsIncludeOrigin
    | UpdateStatsIncludeDestination
    | UpdateStatsCurrencyId
    | OpenStatsPriceDialog
    | CloseStatsPriceDialog
    | UpdateStatsPriceYearIsSelected
    | UpdateStatsPriceSizeTypeIdIsSelected
    | UpdateStatsPriceContainerType;

export const actionCreators = {
    requestStats: (requestTime: number, model: Api.RequestStatsPriceModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StatsApi();
        let task = api.getStatsPrice({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(statsPrice => {
                dispatch({
                    type: "RECEIVE_STATS_PRICE",
                    payload: {
                        requestTime: requestTime,
                        statsPrice: statsPrice,
                        requestModel: model
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_STATS_PRICE",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
                dispatch(Notifications.error({ message: "Error fetching data", title: "Error", position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_STATS_PRICE",
            payload: { requestTime: requestTime }
        });
        addTask(task);
        return task;
    },
    openStatsPriceDialog: (): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: "OPEN_STATSPRICE_DIALOG"
        });
    },
    closeStatsPriceDialog: () => <CloseStatsPriceDialog>{
        type: "CLOSE_STATSPRICE_DIALOG"
    },
    updateIncludeOrigin: (value: boolean) => <UpdateStatsIncludeOrigin>{
        type: 'UPDATE_STATS_INCLUDEORIGIN',
        payload: { value: value }
    },
    updateIncludeDestination: (value: boolean) => <UpdateStatsIncludeDestination>{
        type: 'UPDATE_STATS_INCLUDEODESTINATION',
        payload: { value: value }
    },
    updateStatsCurrencyId: (value: number) => <UpdateStatsCurrencyId>{
        type: 'UPDATE_STATS_CURRENCYID',
        payload: { value: value }
    },
    updateYearIsSelected: (year: number, value: boolean) => <UpdateStatsPriceYearIsSelected>{
        type: "UPDATE_STATSPRICE_YEAR_ISSELECTED",
        payload: {
            year: year,
            value: value
        }
    },
    updateSizeTypeIdIsSelected: (sizeTypeId: number, value: boolean) => <UpdateStatsPriceSizeTypeIdIsSelected>{
        type: "UPDATE_STATSPRICE_SIZETYPEID_ISSELECTED",
        payload: {
            sizeTypeId: sizeTypeId,
            value: value
        }
    },
    updateContainerType: (value: Api.CriteriaModelContainerTypeEnum) => <UpdateStatsPriceContainerType>{
        type: "UPDATE_STATSPRICE_CONTAINERTYPE",
        payload: {
            value: value
        }
    }
};

const unloadedState: StatsState = {
    requestStatsPrice: {
        isLoading: false,
    },
    includeOrigin: false,
    includeDestination: false,
    currencyId: 1,
    statsPriceState: {
        dialogIsOpen: false,
        yearsSelected: {},
        sizeTypeIdsSelected: {},
        containerType: "Dry"
    }
};

export const reducer: Reducer<StatsState> = (state: StatsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_STATS_PRICE":
            return {
                ...state,
                requestStatsPrice: {
                    ...state.requestStatsPrice,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_STATS_PRICE":
            if (action.payload.requestTime !== state.requestStatsPrice.requestTime)
                return state;

            if (action.error)
                return {
                    ...state,
                    requestStatsPrice: {
                        ...state.requestStatsPrice,
                        isLoading: false
                    }
                };

            return {
                ...state,
                requestStatsPrice: {
                    ...state.requestStatsPrice,
                    isLoading: false
                },
                statsPriceState: {
                    ...state.statsPriceState,
                    statsPrice: action.payload.statsPrice,
                    loadedRequestModel: action.payload.requestModel,
                    yearsSelected: _.mapValues(_.keyBy(_.sortBy(
                        _.uniq(action.payload.statsPrice.monthStatsPrices
                            .map(x => x.year)))), x => x === Moment().year())
                }
            };
        case "UPDATE_STATS_INCLUDEORIGIN":
            return {
                ...state,
                includeOrigin: action.payload.value
            };
        case "UPDATE_STATS_INCLUDEODESTINATION":
            return {
                ...state,
                includeDestination: action.payload.value
            };
        case "UPDATE_STATS_CURRENCYID":
            return {
                ...state,
                currencyId: action.payload.value
            };
        case "RECEIVE_CURRENT_USER":
            if (action.error)
                return state;

            return {
                ...unloadedState,
                currencyId: action.payload.currentUser.clientModel.currencyId
            };
        case "OPEN_STATSPRICE_DIALOG":
            return {
                ...state,
                statsPriceState: {
                    ...state.statsPriceState,
                    dialogIsOpen: true
                }
            };
        case "CLOSE_STATSPRICE_DIALOG":
            return {
                ...state,
                statsPriceState: {
                    ...state.statsPriceState,
                    dialogIsOpen: false
                }
            };
        case "UPDATE_STATSPRICE_YEAR_ISSELECTED":
            return {
                ...state,
                statsPriceState: {
                    ...state.statsPriceState,
                    yearsSelected: {
                        ...state.statsPriceState.yearsSelected,
                        [action.payload.year]: action.payload.value
                    }
                }
            };
        case "UPDATE_STATSPRICE_SIZETYPEID_ISSELECTED":
            return {
                ...state,
                statsPriceState: {
                    ...state.statsPriceState,
                    sizeTypeIdsSelected: {
                        ...state.statsPriceState.sizeTypeIdsSelected,
                        [action.payload.sizeTypeId]: action.payload.value
                    }
                }
            };
        case "UPDATE_STATSPRICE_CONTAINERTYPE":
            return {
                ...state,
                statsPriceState: {
                    ...state.statsPriceState,
                    containerType: action.payload.value,
                    sizeTypeIdsSelected: {}
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
