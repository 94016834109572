import * as React from "react";
import Moment from "moment";
import * as Api from "../api/api";
import * as _ from "lodash";
import * as StatsStore from "../store/Stats";
import RequestStatsPriceForm from "./RequestStatsPriceForm";
import CheckBox from "./CheckBox";
import Select  from "./Select";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import DialogModal from "./DialogModal";
import Chart, { ChartDataset } from "./Chart";
import Loader from './Loader';
import { createSelector } from "reselect";
import { containerTypeOptions } from "./CriteriaLclForm";
import { getText } from "../utils/langTexts";

type StatsPriceDialogProps = StatsPriceDialogOwnProps
    & StatsStore.StatsState
    & typeof StatsStore.actionCreators;

interface StatsPriceDialogOwnProps {
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
    chartDataSets: Array<ChartDataset>;
}

const getYears = (statsPrice: Api.StatsPriceModel) => _.sortBy(_.uniq(statsPrice.monthStatsPrices.map(x => x.year)));
const statsPriceSelector = (state: ApplicationState) => state.stats.statsPriceState.statsPrice;
const yearsSelectedSelector = (state: ApplicationState) => state.stats.statsPriceState.yearsSelected;
const sizeTypeIdsSelectedSelector = (state: ApplicationState) => state.stats.statsPriceState.sizeTypeIdsSelected;
const containerTypeSelector = (state: ApplicationState) => state.stats.statsPriceState.containerType;
const currencyIdSelector = (state: ApplicationState) => state.stats.currencyId;
const includeOriginSelector = (state: ApplicationState) => state.stats.includeOrigin;
const includeDestinationSelector = (state: ApplicationState) => state.stats.includeDestination;
const currenciesSelector = (state: ApplicationState) => state.seed.currencies;
const sizeTypesSelector = (state: ApplicationState) => state.seed.sizeTypes;

const colors = [
    "#4472C4"
    , "#EB6F1B"
    , "#9B9B9B"
    , "#FFB900"
    , "#FF00DC"
];
export const dataSetSelector = createSelector(statsPriceSelector
    , yearsSelectedSelector
    , sizeTypeIdsSelectedSelector
    , containerTypeSelector
    , currencyIdSelector
    , includeOriginSelector
    , includeDestinationSelector
    , currenciesSelector
    , sizeTypesSelector
    , (statsPrice, yearsSelected, sizeTypeIdsSelected, containerType, currencyId, includeOrigin, includeDestination, currencies, sizeTypes) => {
        if (!statsPrice)
            return [];

    let years = getYears(statsPrice);
    return _.map(_.sortBy(_.groupBy(statsPrice.monthStatsPrices
        .filter(x => yearsSelected[x.year] !== false), x => x.year.toString()), (xs, key) => parseInt(key)), xs => {
            let color = colors[years.findIndex(year => year.toString() == xs[0].year.toString()) % colors.length];
            return {
                label: xs[0].year.toString(),
                backgroundColor: color,
                borderColor: color,
                lineTension: 0.1,
                fill: false,
                data: _.sortBy(xs, x => x.month)
                    .map(data => Math.round(_.mean(data.sizeTypePriceAverages
                        .filter(x => sizeTypeIdsSelected[x.sizeTypeId] !== false
                            && sizeTypes[x.sizeTypeId].type === containerType)
                        .map(x => x.frtAvg
                            + (includeOrigin ? x.originAvg : 0)
                            + (includeDestination ? x.destinationAvg : 0)))
                        / currencies[currencyId].value)
                    )
            } as ChartDataset;
        });
})

class StatsPriceDialog extends React.Component<StatsPriceDialogProps, {}> {
    constructor(props: StatsPriceDialogProps) {
        super(props);
    }

    getYears(props: StatsPriceDialogProps): Array<number> {
        return _.sortBy(_.uniq(props.statsPriceState.statsPrice.monthStatsPrices.map(x => x.year)));
    }

    sizeTypeIsDisabled(sizeTypeId: number): boolean {
        return !this.props.statsPriceState.statsPrice
            || !this.props.statsPriceState.statsPrice.monthStatsPrices
                .some(y => y.sizeTypePriceAverages
                    .some(z => z.sizeTypeId === sizeTypeId));
    }

    get chartData() {
        return this.props.statsPriceState.statsPrice
            ? {
                labels: [
                    Moment(new Date(2000, 0, 1)).format("MMM"),
                    Moment(new Date(2000, 1, 1)).format("MMM"),
                    Moment(new Date(2000, 2, 1)).format("MMM"),
                    Moment(new Date(2000, 3, 1)).format("MMM"),
                    Moment(new Date(2000, 4, 1)).format("MMM"),
                    Moment(new Date(2000, 5, 1)).format("MMM"),
                    Moment(new Date(2000, 6, 1)).format("MMM"),
                    Moment(new Date(2000, 7, 1)).format("MMM"),
                    Moment(new Date(2000, 8, 1)).format("MMM"),
                    Moment(new Date(2000, 9, 1)).format("MMM"),
                    Moment(new Date(2000, 10, 1)).format("MMM"),
                    Moment(new Date(2000, 11, 1)).format("MMM"),
                ],
                datasets: this.props.chartDataSets
            }
            : {
                labels: [],
                datasets: []
            };
    }

    public render() {
        return (
            <DialogModal isOpen={this.props.statsPriceState.dialogIsOpen}
                maxWidth={"xl"}
                onRequestClose={() => this.props.closeStatsPriceDialog()}
                contentLabel={"Price stats"}
            >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch", height: "100%" }}>
                    <div style={{ flex: "0 0 auto", fontWeight: 'bold', fontSize: 20, textAlign: "center", marginBottom: 30 }}>{getText("FakStatistics")}</div>
                    <div style={{
                        flex: "0 0 auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <div style={{ width: "50%" }}>
                            <RequestStatsPriceForm
                                onSubmit={(values) => this.props.requestStats(new Date().getTime(), values) as any} />
                        </div>
                        <div style={{
                            width: "60%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            margin: "10px 0px"
                        }}>
                            <div style={{
                                flex: 2,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}>
                                {_.values(this.props.sizeTypes)
                                    .filter(x => x.type === this.props.statsPriceState.containerType)
                                    .map(x => <div key={x.sizeTypeId}
                                        style={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                    }}>
                                        <CheckBox disabled={this.sizeTypeIsDisabled(x.sizeTypeId)}
                                            value={this.props.statsPriceState.sizeTypeIdsSelected[x.sizeTypeId] !== false}
                                            onChange={(value) => this.props.updateSizeTypeIdIsSelected(x.sizeTypeId, value)}
                                            label={x.name} />
                                    </div>)}
                            </div>
                            <div style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <div style={{ width: 180 }}>
                                    <Select options={containerTypeOptions()}
                                    onChange={(value) => this.props.updateContainerType(value)}
                                        value={this.props.statsPriceState.containerType} />
                                </div>
                            </div>
                        </div>
                        <div style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            <div style={{
                                flex: "1", display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <CheckBox label={"Origin charges"}
                                    value={this.props.includeOrigin}
                                    onChange={(value) => this.props.updateIncludeOrigin(value)} />
                            </div>
                            <div style={{
                                flex: "1", display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <CheckBox label={"Destination charges"}
                                    value={this.props.includeDestination}
                                    onChange={(value) => this.props.updateIncludeDestination(value)} />
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "row-reverse" }}>
                        <div style={{ width: 75 }}>
                            <Select value={this.props.currencyId}
                                onChange={(value) => this.props.updateStatsCurrencyId(value)}
                                options={_.values(this.props.currencies)
                                    .map(x => ({ label: x.code, value: x.currencyId }))} />
                        </div>
                    </div>
                    <div style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        position: "relative",
                        minHeight: 250
                    }}>
                        <Chart
                            type={"bar"}
                            data={this.chartData}
                            options={{
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true,
                                            min: 0
                                        }
                                    }]
                                },
                                legend: {
                                    display: false
                                }
                            }}
                            style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%"
                            }} />
                    </div>
                    <div style={{
                        flex: "0 0 auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {this.props.statsPriceState.statsPrice && _.sortBy(_.uniq(this.props.statsPriceState.statsPrice.monthStatsPrices.map(x => x.year)))
                                .map((year, index) => <div key={year}
                                    style={{
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}>
                                    <div style={{ flex: "2" }}></div>
                                    <div style={{
                                        flex: "0 0 auto",
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>
                                        <CheckBox label={year.toString()}
                                            value={this.props.statsPriceState.yearsSelected[year] !== false}
                                            onChange={(value) => this.props.updateYearIsSelected(year, value)} />
                                    </div>
                                    <div style={{
                                        flex: "1",
                                        height: 8,
                                        backgroundColor: colors[index % colors.length],
                                        cursor: "pointer"
                                    }}
                                        onClick={() => this.props.updateYearIsSelected(year, !this.props.statsPriceState.yearsSelected[year])}>

                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
                <Loader
                    style={{ zIndex: 99999 }}
                    isVisible={this.props.requestStatsPrice.isLoading}
                    top={200}
                    size={120} />
            </DialogModal>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.stats,
    currencies: state.seed.currencies,
    sizeTypes: state.seed.sizeTypes,
    chartDataSets: dataSetSelector(state)
} as StatsPriceDialogProps), { ...StatsStore.actionCreators })(StatsPriceDialog) as any;


