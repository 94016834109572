import * as React from 'react';
import * as Api from '../api/api';
import * as QuotationStore from '../store/Quotation';
import CheckBox from './CheckBox'
import InputNumber from "./InputNumber";
import Select  from './Select';
import { inputNumberStyle, rowStyle, borderStyle } from "../styles/quotationStyles";
import { QuotationFeeMode } from '../store/Quotation';
import { CSSProperties } from 'react';
import { quotColors } from '../styles/colors';
import { Close } from "@material-ui/icons";

const oneToThree = [1, 2, 3, 4];
const feeNameStyle = { width: "100%" };
const currencySelectStyle = { width: 60, display: "inline-block" as any };

interface QuotationFeeProps {
    quotationFee: Api.QuotationFeeModel;
    quotationFeeState: QuotationStore.QuotationFeeState;
    criteriaSizeTypes: Array<Api.CriteriaSizeTypeModel>;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: Array<Api.CurrencyModel>;
    perBl: boolean;
    remove: () => void;
    onSelect: (value: boolean) => void;
    updateName: (value: string) => void;
    updateUnit: (value: string) => void;
    updateBaseAmount: (value: number, sizeTypeId?: number) => void;
    updateAmount: (value: number, sizeTypeId?: number) => void;
    selectCurrency: (currencyId: number, sizeTypeId?: number) => void;
    updateLabel: (value: string, sizeTypeId?: number) => void;
    updateMode: (value: QuotationFeeMode, sizeTypeId?: number) => void;
    addQuotationFeeLabel: () => void;
    removeQuotationFeeLabel: (index: number) => void;
    updateQuotationFeeLabel: (index: number, value: string) => void;
}

const topModeStyle: CSSProperties = {
    flex: 1,
    borderTop: "1px solid",
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderBottom: "1px solid",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    cursor: "pointer",
    marginRight: 3
};
const botModeStyle: CSSProperties = {
    flex: 1,
    borderBottom: "1px solid",
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    cursor: "pointer",
    marginRight: 3
};

const modeSelectedStyle: CSSProperties = {
    fontWeight: "bold"
}

export default class QuotationFee extends React.Component<QuotationFeeProps, {}> {

    getSizeTypeId(position: number, includeNotSelected: boolean = false): number {
        let cst = this.props.criteriaSizeTypes
            .find(st => this.props.sizeTypes[st.sizeTypeId].position === position && (st.number > 0 || includeNotSelected));
        return cst ? cst.sizeTypeId : null;
    }

    public render() {
        return (
            <div style={{
                ...rowStyle,
                ...quotColors.contentRowColor,
                borderLeft: borderStyle
            }}>
                <div style={{
                    flex: 2,
                    borderRight: borderStyle,
                    //paddingRight: !this.props.perBl ? 2 : 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch"
                }}>
                    <div style={{
                        flex: "0 0 auto",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                        <div style={{
                            flex: "0 0 auto",
                        }}>
                            <CheckBox
                                size={"small"}
                                value={this.props.quotationFee.visible}
                                onChange={(value) => this.props.onSelect(value)}
                            />
                        </div>
                        <div style={{
                            flex: 1, display: "flex",
                            flexDirection: "row", alignItems: "center"
                        }}>
                            <input
                                type="text"
                                style={{ ...feeNameStyle, flex: 2 }}
                                value={this.props.quotationFee.name || ""}
                                onChange={(e) => { this.props.updateName(e.target.value); }}
                            />
                            {(this.props.quotationFee.type === "Bl"
                                || this.props.quotationFee.type === "BlOrigin"
                                || this.props.quotationFee.type === "BlFreight"
                                || this.props.quotationFee.type === "BlDestination")
                                && <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", overflow: "hidden" }}>
                                    <div style={{ flex: "0 0 auto" }}>per</div>
                                    <input
                                        type="text"
                                        style={{ flex: 1 }}
                                        value={this.props.quotationFee.unit}
                                        onChange={(e) => { this.props.updateUnit(e.target.value); }}
                                    />
                                </div>}
                        </div>
                        <div style={{ flex: "0 0 auto" }}>
                            <Select
                                style={{ minWidth: 0, fontSize: 12 }}
                                options={this.props.currencies
                                    .map(cu => ({
                                        label: cu.code,
                                        value: cu.currencyId
                                    }))}
                                value={this.props.quotationFee.quotationFeeAmounts[0]
                                    ? this.props.quotationFee.quotationFeeAmounts[0].currencyId
                                    : 2}
                                onChange={(value) => {
                                    if (this.props.perBl) {
                                        this.props.selectCurrency(value, null);
                                    } else {
                                        oneToThree.forEach(nb => {
                                            this.props.selectCurrency(value, this.getSizeTypeId(nb, true));
                                        });
                                    }
                                }} />
                        </div>
                        <div style={{
                            flex: "0 0 auto",
                            marginRight: 6,
                        }}>
                            <Close
                                onClick={(e) => {
                                    this.props.remove();
                                    e.preventDefault();
                                }}
                                style={{ cursor: "pointer", height: 16 }} />
                        </div>
                    </div>
                    {this.props.quotationFee.quotationFeeLabels.length === 0
                        && <div style={{
                            flex: "0 0 auto",
                            textAlign: "center",
                            cursor: "pointer"
                        }}
                        onClick={(e) => {
                            this.props.addQuotationFeeLabel();
                            e.stopPropagation();
                        }}>
                        {"+ add internal label"}
                        </div>}
                    <div style={{
                        flex: "0 0 auto",
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <div style={{
                            flex: "0 0 auto",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            textAlign: "center",
                            paddingLeft: 5
                        }}>
                            {this.props.quotationFee.quotationFeeLabels
                                && this.props.quotationFee.quotationFeeLabels.length !== 0
                                && <img src={"/images/iconLabelArrow.png"} height={20} />}
                        </div>
                        {this.props.quotationFee.quotationFeeLabels.map((feeLabel, feeLabelIndex) => <div style={{
                            flex: "0 0 auto",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            <input
                                type="text"
                                style={{ flex: 1 }}
                                value={feeLabel.content}
                                onChange={(e) => { this.props.updateQuotationFeeLabel(feeLabelIndex, e.target.value); }}
                            />
                            <div style={{
                                flex: "0 0 auto",
                                marginLeft: 6,
                            }}>
                                <Close
                                    onClick={(e) => {
                                        this.props.removeQuotationFeeLabel(feeLabelIndex);
                                        e.stopPropagation();
                                    }}
                                    style={{ cursor: "pointer", height: 16 }} />
                            </div>
                        </div>)}
                    </div>
                </div>
                <div style={{ flex: 4, display: "flex", flexDirection: "row", alignItems: "stretch" }}>
                    {!this.props.perBl
                        && oneToThree.map(nb =>
                            <div style={{ flex: 1, borderRight: borderStyle }}>
                                {this.getSizeTypeId(nb)
                                    && this.props.quotationFee.quotationFeeAmounts
                                        .some(qfa => qfa.sizeTypeId === this.getSizeTypeId(nb))
                                    && <div>
                                        <QuotationFeeAmount
                                            currencies={this.props.currencies}
                                            mode={!this.props.quotationFeeState.sizeTypesMode[this.getSizeTypeId(nb)]
                                                ? "Normal"
                                                : this.props.quotationFeeState.sizeTypesMode[this.getSizeTypeId(nb)]}
                                            onModeChanges={(value) => this.props.updateMode(value, this.getSizeTypeId(nb))}
                                            onLabelChanges={(value) => this.props.updateLabel(value, this.getSizeTypeId(nb))}
                                            onAmountChanges={(value) => this.props.updateAmount(value, this.getSizeTypeId(nb))}
                                            onBaseAmountChanges={(value) => this.props.updateBaseAmount(value, this.getSizeTypeId(nb))}
                                            quotationFeeAmount={this.props.quotationFee.quotationFeeAmounts
                                                .find(qfa => qfa.sizeTypeId === this.getSizeTypeId(nb))
                                                || { baseAmount: 0, amount: 0, currencyId: 2, sizeTypeId: this.getSizeTypeId(nb) }} />
                                    </div>}
                            </div>)}
                    {this.props.perBl
                        && <div style={{ flex: 3, borderRight: borderStyle }}>
                            <QuotationFeeAmount
                                currencies={this.props.currencies}
                                mode={this.props.quotationFeeState.mode}
                                onModeChanges={(value) => this.props.updateMode(value, null)}
                                onLabelChanges={(value) => this.props.updateLabel(value, null)}
                                onBaseAmountChanges={(value) => this.props.updateBaseAmount(value, null)}
                                onAmountChanges={(value) => this.props.updateAmount(value, null)}
                                quotationFeeAmount={this.props.quotationFee.quotationFeeAmounts[0]
                                    || { baseAmount: 0, amount: 0, currencyId: 2 }} />
                        </div>}
                </div>
            </div>
        );
    }
}

interface QuotationFeeAmountProps {
    quotationFeeAmount: Api.QuotationFeeAmountModel;
    currencies: Array<Api.CurrencyModel>;
    mode: QuotationStore.QuotationFeeMode;
    onModeChanges: (value: QuotationFeeMode) => void;
    onLabelChanges: (value: string) => void;
    onBaseAmountChanges: (value: number) => void;
    onAmountChanges: (value: number, ) => void;
}

class QuotationFeeAmount extends React.Component<QuotationFeeAmountProps, {}> {
    public render() {
        return (
            <div style={{ textAlign: "center", height: "100%" }}>
                {this.props.mode === "Normal"
                    && <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ flex: 1, minWidth: 50 }}>
                            <InputNumber
                                style={{ ...inputNumberStyle }}
                                value={this.props.quotationFeeAmount.baseAmount}
                                onChange={(value) => this.props.onBaseAmountChanges(value)} />
                        </div>
                        <div style={{ flex: "0 0 auto", margin: "0px 5px" }}> / </div>
                        <div style={{ flex: 1, minWidth: 50 }}>
                            <InputNumber
                                style={{ ...inputNumberStyle }}
                                value={this.props.quotationFeeAmount.amount}
                                onChange={(value) => this.props.onAmountChanges(value)} />
                        </div>

                        <img onClick={() => this.props.onModeChanges("Label")}
                            style={{ cursor: "pointer", flex: "0 0 auto" }}
                            src='/images/iconSwitch.png' alt='Switch' height='25' />
                    </div>}
                {this.props.mode === "Label"
                    && <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center" }}>
                        <input
                        style={{ flex: "1", minWidth: 100 }}
                            value={this.props.quotationFeeAmount.label}
                            onChange={(e) => this.props.onLabelChanges(e.target.value)} />
                        <img onClick={() => this.props.onModeChanges("Normal")}
                            style={{ cursor: "pointer", flex: "0 0 auto" }}
                            src='/images/iconSwitchBack.png' alt='Switch back' height='25' />
                    </div>}
            </div>
        );
    }
}

