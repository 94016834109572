import * as Api from '../api/api';
import * as React from 'react';
import * as Colors from '../styles/colors';
import * as FormField from './FormField';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import { getFirmName } from '../utils/utils';
import {vFormStyle} from "../styles/baseStyles";

type CarrierRatingFormProps = ReduxForm.InjectedFormProps<Api.CarrierRatingModel, {}, string>
    & CarrierRatingFormOwnProps
    & CarrierRatingFormExternalProps;

interface CarrierRatingFormOwnProps {
    initialValues?: Api.CarrierRatingModel;
    firms: Array<Api.FirmModel>;
}

interface CarrierRatingFormExternalProps {
    onSubmit: (values: Api.CarrierRatingModel) => Promise<any>;
    mode: "Create" | "Update";
}

export const iconList = [
    "1.png", "2.png", "3.png", "4.png", "5.png", "6.png", "7.png", "8.png", "9.png"
    , "heart.png", "heartBlue.png", "heartGreen.png", "heartRed.png", "heartYellow.png"
    , "ok.png", "okBlue.png", "okGreen.png", "okRed.png", "okYellow.png",
    , "star.png", "starBlue.png", "starGreen.png", "starRed.png", "starYellow.png",
    , "x.png", "xBlue.png", "xGreen.png", "xRed.png", "xYellow.png",
];

const iconsField = (fields) => {
    return <div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap"
        }}>
            {iconList.map(i => <div style={{
                padding: fields.input.value == i
                    ? 0
                    : 1,
                border: fields.input.value == i
                    ? "1px solid"
                    : "",
            }}
                onClick={() => { fields.input.onChange(i); }}>
                <img src={"/images/carrierRating/" + i}
                    height={15}
                    width={15} />
            </div>)}
        </div>
        <FormField.FieldErrors {...fields.meta} />
    </div>
}

class CarrierRatingForm extends React.Component<CarrierRatingFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-horizontal container-fluid">
                <div style={{ ...vFormStyle, alignItems: "stretch" }}>
                    <div className="text-danger" style={{ textAlign: "center" }}>
                        {this.props.error && <span>{this.props.error}</span>}
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label">Company/branch: </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             name="firmId"
                                             validate={[FormField.required]}
                                             component={FormField.getSelectField(this.props.firms
                                                 .map(x => ({
                                                     label: getFirmName(x),
                                                     value: x.firmId
                                                 })))} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label">Label: </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             name="label"
                                             validate={[FormField.required]}
                                             component={FormField.renderField}
                                             type="text" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label">Icon: </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             name="icon"
                                             validate={[FormField.required]}
                                             component={iconsField} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label"></label>
                        <div className="col-sm-9">
                            <button
                                type="submit"
                                style={{ ...Colors.revertColors.main3 }}
                                disabled={this.props.submitting || this.props.pristine}
                                className="btn">
                                {this.props.mode === "Create" && getText("GenCreate")}
                                {this.props.mode === "Update" && getText("GenSaveChanges")}
                            </button>
                        </div>
                    </div>
                </div>
            </FormField.BaseForm>
            );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createCarrierRating',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(CarrierRatingForm) as any;
const createSelector = ReduxForm.formValueSelector('createCarrierRating')

let updateForm = ReduxForm.reduxForm({
    form: 'updateCarrierRating',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(CarrierRatingForm) as any;
const updateSelector = ReduxForm.formValueSelector('updateCarrierRating')

export const CreateCarrierRatingForm = connect((state: ApplicationState) => {
    return {
        firms: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency)
            .concat(state.account.currentUser.clientModel.subscriptions
                .filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company))
            .concat(state.account.currentUser.clientModel.subscriptions
                .filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company.companyGroup))
            .filter(x => x)
    } as CarrierRatingFormOwnProps;
})(createForm) as any as React.ComponentClass<CarrierRatingFormExternalProps>

export const UpdateCarrierRatingForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.carrierRating.carrierRatings
            .find(x => x.carrierRatingId === state.carrierRating.selectedCarrierRatingId),
        firms: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency)
            .concat(state.account.currentUser.clientModel.subscriptions
                .filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company))
            .concat(state.account.currentUser.clientModel.subscriptions
                .filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company.companyGroup))
            .filter(x => x)
    } as CarrierRatingFormOwnProps;
})(updateForm) as any as React.ComponentClass<CarrierRatingFormExternalProps>