import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import * as Notifications from 'react-notification-system-redux';
import * as _ from 'lodash';
import { AppThunkAction } from './';
import { push } from 'connected-react-router';
import { getDefaultHeaders } from '../utils/utils';
import { ResetTodayDate } from './Seed';
import {formValueSelector} from "redux-form";

export interface InlandCriteriaState {
    requestTime?: number;
    isLoading: boolean;
    inlandCriteria?: Api.InlandCriteriaModel;
    createState: {
        requestTime?: number;
        isLoading: boolean;
    }
}

interface RequestInlandCriteria {
    type: "REQUEST_INLAND_CRITERIA";
    payload: { requestTime: number };
}
interface ReceiveInlandCriteria {
    type: "RECEIVE_INLAND_CRITERIA";
    payload: { requestTime: number; inlandCriteria?: Api.InlandCriteriaModel; };
    error?: any;
}


interface RequestCreateInlandCriteria {
    type: "REQUEST_CREATE_INLAND_CRITERIA";
    payload: { requestTime: number };
}
interface ReceiveCreateInlandCriteria {
    type: "RECEIVE_CREATE_INLAND_CRITERIA";
    payload: { requestTime: number; inlandCriteria?: Api.InlandCriteriaModel; };
    error?: any;
}

interface UpdateInlandCriteriaSizeType {
    type: "UPDATE_INLAND_CRITERIA_SIZETYPE";
    payload: { sizeTypeId: number; value: number; };
}

interface UpdateInlandCriteriaDateBegin {
    type: "UPDATE_INLAND_CRITERIA_DATEBEGIN";
    payload: { value: Date };
}
interface UpdateInlandCriteriaDateEnd {
    type: "UPDATE_INLAND_CRITERIA_DATEEND";
    payload: { value: Date };
}

interface UpdateInlandCriteriaOrigin {
    type: "UPDATE_INLAND_CRITERIA_ORIGIN";
    payload: { value: Api.LocationModel };
}
interface UpdateInlandCriteriaDestination {
    type: "UPDATE_INLAND_CRITERIA_DESTINATION";
    payload: { value: Api.LocationModel };
}
interface UpdateInlandCriteriaPriceDisplay {
    type: "UPDATE_INLAND_CRITERIA_PRICEDISPLAY";
    payload: { value: Api.InlandCriteriaModelPriceDisplayModeEnum };
}
interface UpdateInlandCriteriaCarrierRates {
    type: "UPDATE_INLAND_CRITERIA_CARRIERRATES";
    payload: { value: boolean; };
}


interface ResetInlandCriteria {
    type: "RESET_INLAND_CRITERIA";
}

interface SelectInlandContainerType {
    type: 'SELECT_INLAND_CONTAINER_TYPE',
    payload: { value: Api.CriteriaModelContainerTypeEnum; sizeTypes: Array<Api.SizeTypeModel>; inlandCriteriaSizeTypes: Array<Api.InlandCriteriaSizeTypeModel> }
}
interface UpdateInlandChargeCriteria { type: 'UPDATE_INLAND_CHARGE_CRITERIA', value: Array<Api.ChargeModelCriteriaEnum> }

type KnownAction = RequestInlandCriteria
    | ReceiveInlandCriteria
    | RequestCreateInlandCriteria
    | ReceiveCreateInlandCriteria
    | ResetInlandCriteria
    | UpdateInlandCriteriaSizeType
    | UpdateInlandCriteriaDateBegin
    | UpdateInlandCriteriaDateEnd
    | UpdateInlandCriteriaOrigin
    | UpdateInlandCriteriaDestination
    | UpdateInlandCriteriaPriceDisplay
    | ResetTodayDate
    | SelectInlandContainerType
    | UpdateInlandChargeCriteria
    | UpdateInlandCriteriaCarrierRates
    ;

const formSelector = formValueSelector('inlandCriteria')
export const actionCreators = {
    requestInlandCriteria: (requestTime: number, code: string): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.InlandCriteriaApi();
        let task = api.getCriteriaByCode({
            code: code
        }, { cridentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(inlandCriteria => {
                dispatch({
                    type: "RECEIVE_INLAND_CRITERIA",
                    payload: {
                        requestTime: requestTime,
                        inlandCriteria: inlandCriteria
                    }
                });
                return inlandCriteria;
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_INLAND_CRITERIA",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
                dispatch(Notifications.error({ message: "Error fetching criteria", title: "Error", position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_INLAND_CRITERIA",
            payload: { requestTime: requestTime }
        });
        addTask(task);
        return task;
    },
    requestCreateInlandCriteria: (requestTime: number, model: Api.InlandCriteriaModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.InlandCriteriaApi();
        let task = api.createCriteria({
            model: model
        }, { cridentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(inlandCriteria => {
                dispatch({
                    type: "RECEIVE_CREATE_INLAND_CRITERIA",
                    payload: {
                        requestTime: requestTime,
                        inlandCriteria: inlandCriteria
                    }
                });
                return inlandCriteria;
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_INLAND_CRITERIA",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
                dispatch(Notifications.error({ message: "Error creating criteria", title: "Error", position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_CREATE_INLAND_CRITERIA",
            payload: { requestTime: requestTime }
        });
        addTask(task);
        return task;
    },
    selectContainerType: (value: Api.CriteriaModelContainerTypeEnum): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: 'SELECT_INLAND_CONTAINER_TYPE',
            payload: {
                value: value,
                //We send the new sizetypes the form has to display here
                //Because we dont have it in the criteria part of the store tree
                //Using the middleware to gather wanted part of the tree is ok, keep it simple though
                sizeTypes: _.values(getState().seed.sizeTypes)
                    .filter(st => st.type === value && (st.position < 4)),
                inlandCriteriaSizeTypes: formSelector(getState(), "inlandCriteriaSizeTypes")
                    || getState().inlandCriteria.inlandCriteria.inlandCriteriaSizeTypes
            }
        });
    },
    updateChargeCriteria: (value: Array<Api.ChargeModelCriteriaEnum>) => <UpdateInlandChargeCriteria>{ type: 'UPDATE_INLAND_CHARGE_CRITERIA', value: value },
    resetInlandCriteria: () => <ResetInlandCriteria>{
        type: "RESET_INLAND_CRITERIA"
    },
    updateInlandCriteriaSizeType: (sizeTypeId: number, value: number) => <UpdateInlandCriteriaSizeType>{
        type: "UPDATE_INLAND_CRITERIA_SIZETYPE",
        payload: { sizeTypeId: sizeTypeId, value: value }
    },
    updateInlandCriteriaDateBegin: (value: Date) => <UpdateInlandCriteriaDateBegin>{
        type: "UPDATE_INLAND_CRITERIA_DATEBEGIN",
        payload: { value: value }
    },
    updateInlandCriteriaDateEnd: (value: Date) => <UpdateInlandCriteriaDateEnd>{
        type: "UPDATE_INLAND_CRITERIA_DATEEND",
        payload: { value: value }
    },
    updateInlandCriteriaOrigin: (value: Api.LocationModel) => <UpdateInlandCriteriaOrigin>{
        type: "UPDATE_INLAND_CRITERIA_ORIGIN",
        payload: { value: value }
    },
    updateInlandCriteriaDestination: (value: Api.LocationModel) => <UpdateInlandCriteriaDestination>{
        type: "UPDATE_INLAND_CRITERIA_DESTINATION",
        payload: { value: value }
    },
    updateInlandCriteriaPriceDisplay: (value: Api.InlandCriteriaModelPriceDisplayModeEnum) => <UpdateInlandCriteriaPriceDisplay>{
        type: "UPDATE_INLAND_CRITERIA_PRICEDISPLAY",
        payload: { value: value }
    },
    updateInlandCriteriaCarrierRates: (value: boolean) => <UpdateInlandCriteriaCarrierRates>{
        type: "UPDATE_INLAND_CRITERIA_CARRIERRATES",
        payload: { value: value }
    },
    goToSelection: (code: string): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch(push(`/inland/selection/${getState().inlandCriteria.inlandCriteria.code}`) as any);
    }
};

const unloadedState: InlandCriteriaState = {
    inlandCriteria: {
        containerType: "Dry",
        inlandCriteriaSizeTypes: [
            { sizeTypeId: 1, number: 0 },
            { sizeTypeId: 2, number: 0 },
            { sizeTypeId: 3, number: 0 }
        ],
        chargeCriterias: [],
        dateBegin: Moment().startOf("day").toDate(),
        dateEnd: Moment().startOf("day").add(1, "month").toDate(),
    },
    isLoading: false,
    createState: {
        isLoading: false
    }
};

export const reducer: Reducer<InlandCriteriaState> = (state: InlandCriteriaState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_INLAND_CRITERIA":
            return {
                ...state,
                requestTime: action.payload.requestTime,
                isLoading: true
            };
        case "RECEIVE_INLAND_CRITERIA":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                inlandCriteria: action.error
                    ? state.inlandCriteria
                    : action.payload.inlandCriteria
            };
        case "REQUEST_CREATE_INLAND_CRITERIA":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_CREATE_INLAND_CRITERIA":
            if (state.createState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: false
                },
                inlandCriteria: action.error
                    ? state.inlandCriteria
                    : action.payload.inlandCriteria
            };
        case "RESET_INLAND_CRITERIA":
            return {
                ...state,
                inlandCriteria: unloadedState.inlandCriteria
            };
        case "UPDATE_INLAND_CRITERIA_DATEBEGIN":
            return {
                ...state,
                inlandCriteria: {
                    ...state.inlandCriteria,
                    dateBegin: action.payload.value
                }
            };
        case "UPDATE_INLAND_CRITERIA_DATEEND":
            return {
                ...state,
                inlandCriteria: {
                    ...state.inlandCriteria,
                    dateEnd: action.payload.value
                }
            };
        case "UPDATE_INLAND_CRITERIA_ORIGIN":
            return {
                ...state,
                inlandCriteria: {
                    ...state.inlandCriteria,
                    origin: action.payload.value
                }
            };
        case "UPDATE_INLAND_CRITERIA_DESTINATION":
            return {
                ...state,
                inlandCriteria: {
                    ...state.inlandCriteria,
                    destination: action.payload.value
                }
            };
        case "UPDATE_INLAND_CRITERIA_PRICEDISPLAY":
            return {
                ...state,
                inlandCriteria: {
                    ...state.inlandCriteria,
                    priceDisplayMode: action.payload.value
                }
            };
        case "UPDATE_INLAND_CRITERIA_SIZETYPE":
            return {
                ...state,
                inlandCriteria: {
                    ...state.inlandCriteria,
                    inlandCriteriaSizeTypes: state.inlandCriteria.inlandCriteriaSizeTypes
                        .map(x => x.sizeTypeId === action.payload.sizeTypeId
                            ? { ...x, number: action.payload.value }
                            : x)
                }
            };
        case "RESET_TODAY_DATE":
            return {
                ...state,
                inlandCriteria: {
                    ...state.inlandCriteria,
                    dateBegin: Moment(action.payload.today).utc().add(0, "days").toDate(),
                    dateEnd: Moment(action.payload.today).utc().add(1, "months").toDate()
                }
            };
        case 'SELECT_INLAND_CONTAINER_TYPE':
            return {
                ...state,
                inlandCriteria: {
                    ...state.inlandCriteria,
                    containerType: action.payload.value,
                    inlandCriteriaSizeTypes: action.payload.sizeTypes.map((st, i) => {
                        return {
                            sizeTypeId: st.sizeTypeId,
                            number: action.payload.inlandCriteriaSizeTypes[i]
                                ? action.payload.inlandCriteriaSizeTypes[i].number
                                : 0,
                            criteriaSizeTypeId: 0
                        }
                    })
                }
            };
        case "UPDATE_INLAND_CHARGE_CRITERIA":
            return {
                ...state,
                inlandCriteria: {
                    ...state.inlandCriteria,
                    chargeCriterias: action.value
                }
            };
        case "UPDATE_INLAND_CRITERIA_CARRIERRATES":
            return {
                ...state,
                inlandCriteria: {
                    ...state.inlandCriteria,
                    includeCarrierRates: action.payload.value
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
