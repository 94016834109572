import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as InlandCriteriaStore from '../store/InlandCriteria';
import * as InlandSelectionStore from '../store/InlandSelection';
import * as Api from '../api/api';
import CriteriaSizeType from './CriteriaSizeType';
import CheckBox from './CheckBox';
import {Add, Close, Remove} from "@material-ui/icons";
import * as Colors from "../styles/colors";
import Select from "./Select";
import {inlandContainerTypeOptions} from "./InlandCriteriaForm";
import ChargeCriteriaInput from "./ChargeCriteriaInput";
import {getText} from "../utils/langTexts";
import PopperButton from "./PopperButton";

type InlandSelectionFilterCriteriaProps = InlandSelectionFilterCriteriaOwnProps
    & typeof InlandCriteriaStore.actionCreators
    & typeof InlandSelectionStore.actionCreators
    ;

interface InlandSelectionFilterCriteriaOwnProps {
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    inlandCriteria: Api.InlandCriteriaModel;
    advContainerOpened: boolean;
    isLoading: boolean;
}

class InlandSelectionFilterCriteria extends React.Component<InlandSelectionFilterCriteriaProps, {}> {
    get selectedCtrOptions(): number {
        if(!this.props.inlandCriteria.chargeCriterias)
            return 0;
        
        return (this.props.inlandCriteria.chargeCriterias.some(x => x === "Goh") ? 1 : 0)
            + (this.props.inlandCriteria.chargeCriterias.some(x => x === "GohDouble") ? 1 : 0)
            + (this.props.inlandCriteria.chargeCriterias.some(x => x === "Hazardous") ? 1 : 0)
            + (this.props.inlandCriteria.chargeCriterias.some(x => x === "Owned") ? 1 : 0);
    }
    
    updateSearch(): Promise<any> {
        let promise = (this.props.requestCreateInlandCriteria(new Date().getTime(), this.props.inlandCriteria) as any) as Promise<Api.InlandCriteriaModel>;
        return promise.then((inlandCriteria) => {
            this.props.goToSelection(inlandCriteria.code);
        });
    }
    
    public render() {
        return (
            <div style={{}}>
                <div style={{ marginBottom: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {this.props.inlandCriteria.inlandCriteriaSizeTypes
                            && this.props.inlandCriteria.inlandCriteriaSizeTypes.map((cs, index) =>
                                <CriteriaSizeType
                                    key={cs.sizeTypeId}
                                    iconStyle={{height: 17}}
                                    buttonStyle={{ height: 22, width: 22, borderRadius: 7 }}
                                    style={{ marginRight: 10}}
                                    sizeTypes={this.props.sizeTypes}
                                    sizeTypeId={cs.sizeTypeId}
                                    value={cs.number}
                                    onChange={(value) => this.props.updateInlandCriteriaSizeType(cs.sizeTypeId, value)}
                                    min={0}
                                    max={this.props.inlandCriteria.priceDisplayMode === "ByCtr" ? 1 : 9}
                                />)}
                    </div>
                    <PopperButton
                        open={this.props.advContainerOpened}
                        onChange={(val) => {
                            if (val) {
                                this.props.updateAdvContainer(true);
                            } else {
                                this.props.updateAdvContainer(false);
                            }
                        }}
                        style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            alignItems: "row",
                            marginTop: 20,
                        }}
                        disablePortal={true}
                        popperStyle={{}}
                        buttonStyle={{ height: 28, minWidth: 90, fontSize: 13, position: "relative" }}
                        buttonContent={<div>
                            Options <Add style={{ fontSize: 13 }} />
                            {this.selectedCtrOptions !== 0
                            && <div style={{
                                position: "absolute",
                                top: -10, right: -5,
                                borderRadius: 18,
                                width: 18,
                                height: 18,
                                textAlign: "center",
                                fontSize: 13,
                                backgroundColor: "white",
                                color: Colors.baseColors.lightBlue,
                                border: `1px solid ${Colors.baseColors.lightBlue}`,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "row"
                            }}><div style={{ textAlign: "center", width: "100%", lineHeight: "18px" }}>{this.selectedCtrOptions}</div></div>}
                        </div>}
                        buttonOpenedContent={<div>Options <Remove style={{ fontSize: 13 }} /></div>}>
                        <div style={{
                            paddingTop: 6
                        }}>
                            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                <Close style={{ height: 13, cursor: "pointer", color: Colors.baseColors.lightBlue }}
                                       onClick={(e) => this.props.updateAdvContainer(false)} />
                            </div>
                            <div style={{ padding: "13px 20px 26px 20px" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 20
                                }}>
                                    <div style={{ width: 70 }}>Type</div>
                                    <div style={{}}>
                                        <Select
                                            options={inlandContainerTypeOptions()}
                                            value={this.props.inlandCriteria.containerType}
                                            onChange={(value) => this.props.selectContainerType(value)} />
                                    </div>
                                </div>
                                <div style={{}}>
                                    <ChargeCriteriaInput
                                        label={getText("GenHazardous")}
                                        labelPlacement={"start"}
                                        labelStyle={{ minWidth: 100 }}
                                        chargeCriteria={"Hazardous"}
                                        value={this.props.inlandCriteria.chargeCriterias}
                                        onChange={(value) => this.props.updateChargeCriteria(value)} />
                                </div>
                            </div>
                        </div>
                    </PopperButton>
                </div>
                <div style={{ fontSize: 20, marginBottom: 10, marginTop: 20}}>Rates displayed</div>
                <div style={{ marginBottom: 10, alignItems: "center", marginLeft: 50}}>
                    <CheckBox label={"Price by container"}
                        value={this.props.inlandCriteria.priceDisplayMode === "ByCtr"}
                        onChange={(value) => {
                            if (value) {
                                this.props.updateInlandCriteriaPriceDisplay("ByCtr");
                            } else {
                                this.props.updateInlandCriteriaPriceDisplay("AllIn");
                            }
                        }} />
                </div>
                <div style={{ marginBottom: 10, alignItems: "center", marginLeft: 50}}>
                    <CheckBox label={getText("CrtIncludeCarrierRates")}
                              value={this.props.inlandCriteria.includeCarrierRates}
                              disabled={this.props.isLoading}
                              onChange={(value) => {
                                  if(value !== this.props.inlandCriteria.includeCarrierRates) {
                                      this.props.updateInlandCriteriaCarrierRates(value);
                                      setTimeout(() => this.updateSearch(), 200);
                                  }
                              }} />
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    sizeTypes: state.seed.sizeTypes,
    inlandCriteria: state.inlandCriteria.inlandCriteria,
    advContainerOpened: state.inlandSelection.advContainerOpened,
    isLoading: state.inlandSelection.isLoading || state.inlandCriteria.isLoading
} as any), {
    ...InlandCriteriaStore.actionCreators,
    ...InlandSelectionStore.actionCreators
})(InlandSelectionFilterCriteria) as any;