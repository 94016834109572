import * as React from 'react';
import * as Api from '../api/api';
import Moment from 'moment';
import * as Colors from '../styles/colors';
import DialogModal from './DialogModal';

interface FirmNewsesProps {
    tickTimer?: number;
    firmNewses: Array<Api.FirmNewsModel>;
    selectedFirmNewsId: number;
    isOpen: boolean;
    openDialog: (firmNewsId: number) => void;
    closeDialog: () => void;
}

interface FirmNewsesState {
    displayedTitle: string;
    firmNewsId: number;
}

const containerStyle = {
    minHeight: 40, display: "flex",
    flexDirection: "column" as any,
    alignItems: "center" as any
} as React.CSSProperties;
const innerStyle = { padding: "5px 20px" } as React.CSSProperties;
const titleBoxStyle = {
    padding: "5px 20px",
    textAlign: "center" as any,
    borderRadius: 15,
    cursor: "pointer" as any,
    backgroundColor: Colors.baseColors.lightBlue,
    color: "white"
} as React.CSSProperties;

const titleStyle: React.CSSProperties = { margingTop: 10, marginBottom: 10, fontWeight: "bold", fontSize: 18 } as React.CSSProperties;
const contentStyle: React.CSSProperties = { whiteSpace: "pre-wrap" } as React.CSSProperties;

export default class FirmNewses extends React.Component<FirmNewsesProps, FirmNewsesState> {
    private interval;
    private index: number;

    constructor(props: FirmNewsesProps) {
        super(props);
        this.state = {
            displayedTitle: this.props.firmNewses.length !== 0
                ? this.props.firmNewses[0].title
                : "",
            firmNewsId: this.props.firmNewses.length !== 0
                ? this.props.firmNewses[0].firmNewsId
                : 0
        }
    }

    onTick(props: FirmNewsesProps) {
        this.setState({
            displayedTitle: this.props.firmNewses.length !== 0
                ? this.props.firmNewses[this.index % this.props.firmNewses.length].title
                : "",
            firmNewsId: this.props.firmNewses.length !== 0
                ? this.props.firmNewses[this.index % this.props.firmNewses.length].firmNewsId
                : 0
        });
        this.index++;
        if (this.index === Number.MAX_SAFE_INTEGER) //I know ... i know
            this.index = 0;


    }

    componentWillReceiveProps(nextProps: FirmNewsesProps) {
        this.onTick(nextProps);
    }

    componentDidMount() {
        this.index = 0;
        this.interval = setInterval(() =>
            this.onTick(this.props),
            this.props.tickTimer || 4000);
        this.interval = null;
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    get selectedFirmNews(): Api.FirmNewsModel {
        return this.props.selectedFirmNewsId
            ? this.props.firmNewses.find(x => x.firmNewsId === this.props.selectedFirmNewsId)
            : null;
    }

    public render() {
        return (
            <div>
                <div style={containerStyle}>
                    <div style={innerStyle}>
                        <div style={titleBoxStyle} onClick={(e) => {
                            this.props.openDialog(this.state.firmNewsId);
                            e.preventDefault();
                        }}>
                            {this.state.displayedTitle}
                        </div>
                    </div>
                </div>
                <DialogModal
                    isOpen={this.props.isOpen}
                    contentLabel={"news"}
                    onRequestClose={() => this.props.closeDialog()}>
                    {this.selectedFirmNews &&
                        <div>
                            <div style={titleStyle}>
                                {this.selectedFirmNews.title}
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            Valid from
                            {" "}
                            <span style={{ fontWeight: "bold" }}>{Moment(this.selectedFirmNews.dateBegin).format("DD/MM/YYYY")}</span>
                            to
                            {" "}
                            <span style={{ fontWeight: "bold" }}>{Moment(this.selectedFirmNews.dateEnd).format("DD/MM/YYYY")}</span>
                        </div>
                            <div style={contentStyle}>
                                {this.selectedFirmNews.content}
                            </div>
                        </div>}
                </DialogModal>
            </div>

            );
    }
} 