import * as React from 'react';
import * as Api from '../api/api';
import Moment from 'moment';
import * as SelectionState from '../store/Selection';
import { getText } from '../utils/langTexts';
import * as Colors from '../styles/colors';
import { getSelectedDepart } from '../utils/routeUtils';
import CenteredContentTabs from './CenteredContentTabs';
import OfferContact from './OfferContact';


interface OfferContactsProps {
    subscriptionType: Api.SubscriptionModelSubscriptionTypeEnum;
    carrierOffer: Api.CarrierOfferModel;
    carrierOfferState: SelectionState.CarrierOfferState;
    carriers: { [id: number]: Api.CarrierModel };
}

interface OfferContactsState {

}

export default class OfferContacts extends React.Component<OfferContactsProps, OfferContactsState> {

    get privateContacts(): Array<Api.ContactModel> {
        return this.props.subscriptionType === "Agent"
            ? []
            : this.props.carrierOffer.contacts.filter(co => co.firmId);
    }

    get publicContacts(): Array<Api.ContactModel> {
        return this.props.subscriptionType === "Agent"
            ? []
            : this.props.carrierOffer.contacts.filter(co => !co.firmId);
    }


    public render() {
        let selectedDepart = getSelectedDepart(this.props.carrierOffer, this.props.carrierOfferState);
        return (
            <div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    height: 60,
                }}>
                    <div style={{
                        height: 60,
                        width: 200,
                    }}>
                        <img style={{
                            paddingLeft: 30,
                            maxHeight: 60,
                            maxWidth: 150,
                        }}
                            src={'/images/carriers/'
                                + this.props.carriers[this.props.carrierOffer.carrierId].code.toLowerCase()
                                + '.png'} />
                    </div>
                    <div style={{
                        height: 100,
                        width: 200,
                    }}>
                        <div style={{
                            fontFamily: "Calibri",
                            fontSize: 25,
                            color: Colors.baseColors.darkGray,
                            width: "100%",
                            textAlign: "center",
                        }}>
                            {this.props.carriers[this.props.carrierOffer.carrierId].name}
                        </div>
                    </div>
                    <div style={{
                        marginLeft: 45,
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            fontFamily: "Calibri",
                            fontWeight: "bold",
                            fontSize: 15,
                            color: Colors.baseColors.lightBlue,
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: 25,
                            }}>
                                <div>
                                    Depart
                            </div>
                                <div>
                                    Arrival
                            </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: 25,
                            }}>
                                <div>
                                    {this.props.carrierOffer.originPort.name/*origin*/}
                                </div>
                                <div>
                                    {this.props.carrierOffer.destinationPort.name /*destination*/}
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                <div>
                                    {selectedDepart && Moment(selectedDepart.etd).format("DD/MM/YYYY")/*etd*/}
                                </div>
                                <div>
                                    {selectedDepart && Moment(selectedDepart.eta).format("DD/MM/YYYY")/*eta*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.carrierOffer.contacts.length === 0 || this.props.subscriptionType === "Agent"
                    ?
                    <div style={{ marginTop: 20 }}>
                        {getText("SlcContactsNoContacts")}
                    </div>
                    : null}
                <div>
                    <div>
                        <CenteredContentTabs
                            data={[
                                {
                                    title: getText("CtcPrivate"),
                                    disabled: this.privateContacts.length == 0,
                                    tabContent: <div>
                                        <h3 style={{
                                            fontFamily: "Calibri",
                                            color: Colors.baseColors.darkGray,
                                        }}>
                                            {getText("CtcPrivate")}
                                        </h3>
                                        <div>
                                            {this.privateContacts.map(co =>
                                                <div key={co.contactId}>
                                                    <OfferContact key={co.contactId} contact={co} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                },
                                {
                                    title: getText("CtcPublic"),
                                    disabled: this.publicContacts.length == 0,
                                    tabContent: <div>
                                        <h3 style={{
                                            fontFamily: "Calibri",
                                            color: Colors.baseColors.darkGray,
                                        }}>
                                            {getText("CtcPublic")}
                                        </h3>
                                        <div>
                                            {this.publicContacts.map(co =>
                                                <div key={co.contactId}>
                                                    <OfferContact key={co.contactId} contact={co} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            ]}
                            centered={true}>
                        </CenteredContentTabs>
                    </div>
                </div>
            </div>
        );
    }
}