import * as React from 'react';
import * as Api from '../api/api';
import Tooltip from "./Tooltip";
import { matchExactChriteria } from '../utils/ratesUtils';

interface FreetimeProps {
    ddCharges: Array<Api.DdChargeModel>;
    criteria : Api.CriteriaModel;
}

interface DdChargeProps {
    title: string;
    ddCharges: Array<Api.DdChargeModel>;
}

class DdCharge extends React.Component<DdChargeProps, {}> {
    get freeCharge(): Api.DdChargeModel {
        return this.props.ddCharges.find(x => x.amountType === "Free");
    }

    get bodyRender(): JSX.Element {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "0px 4px"
                }}>
                    <div>{this.freeCharge
                        ? this.freeCharge.untilDay
                        : 0}</div>
                    <div>{this.props.title}</div>
                </div>
                {this.freeCharge
                    && this.freeCharge.dayCountCategory === "Wrk"
                    && <div style={{ padding: "0px 0px" }}>*</div>}
            </div>
            );
    }

    public render() {
        if (this.freeCharge
            && this.freeCharge.dayCountCategory === "Wrk") {
            return (
                <Tooltip placement={"top"}
                    overlay={<div>Working days</div>}>
                    {this.bodyRender}
                </Tooltip>
            );
        } else {
            return this.bodyRender;
        }
    }
} 

export default class Freetime extends React.Component<FreetimeProps, {}> {

    get demAndDem(): Array<Api.DdChargeModel> {
        return this.props.ddCharges
            .filter(x => this.isForSelectedCriteria(x, this.props.criteria) && x.ddChargeNameId === 3);
    }


    get dem(): Array<Api.DdChargeModel> {
        return this.props.ddCharges
            .filter(x => this.isForSelectedCriteria(x, this.props.criteria) && x.ddChargeNameId === 2);
    }

    get det(): Array<Api.DdChargeModel> {
        return this.props.ddCharges
            .filter(x => this.isForSelectedCriteria(x, this.props.criteria) && x.ddChargeNameId === 1);
    }

    get pgi(): Array<Api.DdChargeModel> {
        return this.props.ddCharges
            .filter(x => this.isForSelectedCriteria(x, this.props.criteria) && x.ddChargeNameId === 4);
    }

    get bt(): Array<Api.DdChargeModel> {
        return this.props.ddCharges
            .filter(x => this.isForSelectedCriteria(x, this.props.criteria) && x.ddChargeNameId === 5);
    }

    get chassis(): Array<Api.DdChargeModel> {
        return this.props.ddCharges
            .filter(x => this.isForSelectedCriteria(x, this.props.criteria) && x.ddChargeNameId === 6);
    }

    private isForSelectedCriteria(ddCharge: Api.DdChargeModel, criteria: Api.CriteriaModel): boolean {
        return (ddCharge.containerType === null || ddCharge.containerType === criteria.containerType) && (((!this.props.criteria.chargeCriterias
            || this.props.criteria.chargeCriterias.length === 0)
            && !ddCharge.criteria)
            || matchExactChriteria(ddCharge.criteria, this.props.criteria.chargeCriterias)); 
    }

    public render() {

        //console.log(this.props.ddCharges);
        //console.log(this.props.criteria);

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                    {this.demAndDem.length !== 0 && <DdCharge title={"D&D combined"} ddCharges={this.demAndDem} />}
                    {this.dem.length !== 0 && <DdCharge title={"Dem"} ddCharges={this.dem} />}
                    {this.dem.length !== 0 && this.det.length !== 0  && <div>+</div>}
                    {this.det.length !== 0 && <DdCharge title={"Det"} ddCharges={this.det} />}
                    {this.pgi.length !== 0 && (this.det.length !== 0 || this.dem.length !== 0) && <div>/</div>}
                    {this.pgi.length !== 0 && <DdCharge title={"Plug"} ddCharges={this.pgi} />}

                    {(this.bt.length !== 0 || this.chassis.length !== 0)
                        && (this.pgi.length !== 0 || this.det.length !== 0 || this.dem.length !== 0) && <div>/</div>}
                    {this.bt.length !== 0 && <DdCharge title={"Bt"} ddCharges={this.bt} />}
                    {this.bt.length !== 0 && this.chassis.length !== 0 && <div>+</div>}
                    {this.chassis.length !== 0 && <DdCharge title={"Cha"} ddCharges={this.chassis} />}
                </div>
            </div>
            );
    }
}