import * as Selection from './Selection';
import * as Criteria from './Criteria';
import * as Seed from './Seed';
import * as Account from './Account';
import * as ForgotPassword from './ForgotPassword';
import * as MultiPort from './MultiPort';
import * as MultiPortHistory from './MultiPortHistory';
import * as AskBooking from './AskBooking';
import * as AskRates from './AskRates';
import * as Location from './Location';
import * as RatesOffer from './RatesOffer';
import * as WorkDocuments from './WorkDocuments';
import * as FirmNews from './FirmNews';
import * as FirmDocument from './FirmDocument';
import * as Document from './Document';
import * as Quotation from './Quotation';
import * as AgentPortal from './AgentPortal';
import * as Error from './Error';
import * as History from './History';
import * as Transfer from './Transfer';
import * as ContactBox from './ContactBox';
import * as QuotationContact from './QuotationContact';
import * as QuotationChrono from './QuotationChrono';
import * as Tables from './Tables';
import * as InlandCriteria from './InlandCriteria';
import * as InlandSelection from './InlandSelection';
import * as CarrierRating from './CarrierRating';
import * as CarrierCarrierRating from './CarrierCarrierRating';
import * as SellingRates from './SellingRates';
import * as OkargoHub from './OkargoHub';
import * as InlandRatesOffer from './InlandRatesOffer';
import * as QuotationSettings from './QuotationSettings';
import * as CarrierRatesSettings from './CarrierRatesSettings';
import * as RouteRatesPlatformData from './RouteRatesPlatformData';
import * as Stats from './Stats';
import * as Tabs from './Tabs';
import * as Scroll from './Scroll';
import * as CarrierRatesPlatform from './CarrierRatesPlatform';
import * as CarrierRatesOfferInformation from './CarrierRatesOfferInformation';
import * as Tutorial from './Tutorial';
import * as RatesFetcher from './RatesFetcher';
import * as Users from './Users';
import { reducer as notifications } from 'react-notification-system-redux';
import { reducer as reduxForm } from 'redux-form';
import {okargoApi, priceListApi} from "../api/rtkApi"
import ratesExtractionReducer, {RatesExtractionState} from "../slices/ratesExtraction"

// The top-level state object
export interface ApplicationState {
    selection: Selection.SelectionState,
    transfer: Transfer.TransferState,
    criteria: Criteria.CriteriaState,
    seed: Seed.SeedState,
    account: Account.AccountState,
    forgotPassword: ForgotPassword.ForgotPasswordState,
    multiPort: MultiPort.MultiPortState,
    multiPortHistory: MultiPortHistory.MultiPortState,
    askBooking: AskBooking.AskBookingState,
    askRates: AskRates.AskRatesState,
    location: Location.LocationState,
    ratesOffer: RatesOffer.RatesOfferState,
    workDocuments: WorkDocuments.WorkDocumentsState,
    firmNews: FirmNews.FirmNewsState,
    firmDocument: FirmDocument.FirmDocumentState,
    document: Document.DocumentState,
    quotation: Quotation.QuotationState,
    agentPortal: AgentPortal.AgentPortalState,
    quotationContact: QuotationContact.QuotationContactState,
    quotationChrono: QuotationChrono.QuotationChronoState,
    contactBox: ContactBox.ContactBoxState,
    history: History.HistoryState,
    error: Error.ErrorState,
    tables: Tables.TablesState,
    inlandCriteria: InlandCriteria.InlandCriteriaState,
    inlandSelection: InlandSelection.InlandSelectionState,
    carrierRating: CarrierRating.CarrierRatingState,
    carrierCarrierRating: CarrierCarrierRating.CarrierCarrierRatingState,
    sellingRates: SellingRates.SellingRatesState,
    stats: Stats.StatsState,
    inlandRatesOffer: InlandRatesOffer.InlandRatesOfferState,
    quotationSettings: QuotationSettings.QuotationSettingsState,
    okargoHub: OkargoHub.OkargoHubState,
    carrierRatesSettings: CarrierRatesSettings.CarrierRatesSettingsState,
    routeRatesPlatformDatas: RouteRatesPlatformData.RouteRatesPlatformDatasState,
    carrierRatesOfferInformation: CarrierRatesOfferInformation.CarrierRatesOfferInformationState,
    users: Users.UserState,
    tabs: Tabs.TabsState,
    scroll: Scroll.ScrollState,
    carrierRatesPlatform: CarrierRatesPlatform.CarrierRatesPlatformState,
    tutorial: Tutorial.TutorialState,
    ratesFetcher: RatesFetcher.RatesFetcherState,
    notifications: any,
    priceListApi: any,
    api: any,
    router: any,
    ratesExtraction: RatesExtractionState
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    selection: Selection.reducer,
    transfer: Transfer.reducer,
    criteria: Criteria.reducer,
    seed: Seed.reducer,
    account: Account.reducer,
    forgotPassword: ForgotPassword.reducer,
    multiPort: MultiPort.reducer,
    multiPortHistory: MultiPortHistory.reducer,
    askBooking: AskBooking.reducer,
    askRates: AskRates.reducer,
    location: Location.reducer,
    ratesOffer: RatesOffer.reducer,
    workDocuments: WorkDocuments.reducer,
    firmNews: FirmNews.reducer,
    firmDocument: FirmDocument.reducer,
    document: Document.reducer,
    quotation: Quotation.reducer,
    agentPortal: AgentPortal.reducer,
    quotationContact: QuotationContact.reducer,
    quotationChrono: QuotationChrono.reducer,
    contactBox: ContactBox.reducer,
    history: History.reducer,
    error: Error.reducer,
    tables: Tables.reducer,
    inlandCriteria: InlandCriteria.reducer,
    inlandSelection: InlandSelection.reducer,
    carrierRating: CarrierRating.reducer,
    carrierCarrierRating: CarrierCarrierRating.reducer,
    sellingRates: SellingRates.reducer,
    inlandRatesOffer: InlandRatesOffer.reducer,
    quotationSettings: QuotationSettings.reducer,
    okargoHub: OkargoHub.reducer,
    carrierRatesSettings: CarrierRatesSettings.reducer,
    routeRatesPlatformDatas: RouteRatesPlatformData.reducer,
    carrierRatesOfferInformation: CarrierRatesOfferInformation.reducer,
    users: Users.reducer,
    stats: Stats.reducer,
    tabs: Tabs.reducer,
    carrierRatesPlatform: CarrierRatesPlatform.reducer,
    tutorial: Tutorial.reducer,
    ratesFetcher: RatesFetcher.reducer,
    scroll: Scroll.reducer,
    notifications: notifications,
    form: reduxForm,
    priceListApi: priceListApi.reducer,
    api: okargoApi.reducer,
    ratesExtraction: ratesExtractionReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction, TResult> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): TResult;
}