import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import * as Api from "./api";
//Will remove api in time and use rtk code gen
import { emptySplitApi as emptyApi } from "./emptyApi";
export interface PriceListCompanyModel
{
    period: "Month" | "Year";
    priceListCount?: number;
    priceListMax?: number;
    company: Api.FirmModel;
}

export const priceListApi = createApi({
    reducerPath: "priceListApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl: '/',
        prepareHeaders: (headers, api) => {
            return headers;
        },
    }),
    endpoints: (build) => ({
        listPriceListByPeriod: build.query<PriceListCompanyModel, { period: "Month" | "Year" }>({
            query: (queryArg) => ({
                url: `/api/PriceList/ListPriceListByPeriod`,
                params: {
                    period: queryArg.period
                },
                prepareHeaders: headers => {
                    return headers
                }
            })
        }),
    }),
});

export const { useListPriceListByPeriodQuery } = priceListApi;

export interface RatesExtractionModel
{
    ratesExtractionId?: number;
    name?: string;
    containerType?: Api.ChargeModelContainerTypeEnum;
    ratesType?: Api.RatesOfferModelTypeEnum;
    validityType?: "Grid" | "Spot";
    ratesSourceFlag?: Array<"IntegratedPl" | "Ocq" | "SelfUpload">;
    applicationFlag?: Array<"Origin" | "Prepaid" | "Destination" | "Collect">;
    creationDate?: Date; 
    currencyId?: number;
    ratesExtractionPols?: RatesExtractionPortModel[];
    ratesExtractionPods?: RatesExtractionPortModel[];
    ratesExtractionCarriers?: RatesExtractionCarrierModel[];
}

export interface RatesExtractionPortModel
{
    portId?: number;
    port?: any;
}

export interface RatesExtractionCarrierModel
{
    carrierId?: number;
}

type tagtest = "dwdw" | "www";
export const addTagTypes = [
    "RatesExtraction"
] as const;

const injectedRtkApi = emptyApi
    .enhanceEndpoints({
    addTagTypes,
}).injectEndpoints({
        endpoints: (build) => ({
            listRatesExtraction: build.query<RatesExtractionModel[], { name?: string; pols?: string; pods?: string }>({
                query: (queryArg) => ({
                    url: `/api/RatesExtraction/List`,
                    params: {
                        name: queryArg.name,
                        pols: queryArg.pols,
                        pods: queryArg.pods
                    },
                    prepareHeaders: headers => {
                        return headers
                    }
                }),
                providesTags: ["RatesExtraction" ]
        }),
        createRatesExtraction: build.mutation<RatesExtractionModel, RatesExtractionModel>({
            query: (queryArg) => ({
                url: `/api/RatesExtraction/Create`,
                method: "POST",
                body: queryArg,
            }),
            invalidatesTags: ["RatesExtraction"],
        }),
        updateRatesExtraction: build.mutation<RatesExtractionModel, RatesExtractionModel>({
            query: (queryArg) => ({
                url: `/api/RatesExtraction/Update`,
                method: "POST",
                body: queryArg,
            }),
            invalidatesTags: ["RatesExtraction"],
        }),
        downloadRatesExtraction: build.mutation<{}, { ratesExtractionId: number, startDate?: Date, endDate?: Date }>({
            query: (queryArg) => ({
                url: `/api/RatesExtraction/Download`,
                method: "POST",
                body: queryArg,
            }),
            invalidatesTags: ["RatesExtraction"],
        }),
        deleteRatesExtraction: build.mutation<{}, { ratesExtractionId: number }>({
            query: (queryArg) => ({
                url: `/api/RatesExtraction/Delete`,
                method: "POST",
                body: queryArg,
            }),
            invalidatesTags: ["RatesExtraction"],
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as okargoApi };
export const {
    useListRatesExtractionQuery,
    useCreateRatesExtractionMutation,
    useUpdateRatesExtractionMutation,
    useDownloadRatesExtractionMutation,
    useDeleteRatesExtractionMutation
} = injectedRtkApi;
