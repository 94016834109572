import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import * as _ from 'lodash';
import { AppThunkAction } from './';
import { push } from 'connected-react-router';
import * as CriteriaUtils from '../utils/criteriaUtils';
import * as selectionConnect from '../connects/selectionConnect';
import {
    getCarrierOfferKey, dictionnaryToArray,
    getDefaultHeaders,
    goToFormModel,
    removeFirstOccurence
} from "../utils/utils"
import * as Criteria from './Criteria';
import { Logout } from './Account';
import { ReceiveCurrentUser } from "./Account";
import { superTransitId } from "../utils/constants";
import * as Notifications from 'react-notification-system-redux';
import Download from "downloadjs";
import * as MimeTypes from "mime-types";
import { getText } from '../utils/langTexts';
import { requestSelectionSellingRates } from './SellingRates';
import { requestSeedCarriers } from './Seed';
import {getAllDeparts, getRouteConfig, getSelectedDepart} from '../utils/routeUtils';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface SelectionState {
    code: string;
    criteriaLoaded: Api.CriteriaModel;
    selection: Api.SelectionModel;
    firmNewsesState: FirmNewsesState;
    carrierRatesOffersCache: Array<Api.CarrierOfferModel>;
    carrierOfferStates: { [id: string]: CarrierOfferState };
    carrierRatesStates: {
        [code: string]: {
            requestTime?: number;
            isLoading: boolean;
            carrierRatesPlatformId: number;
            errors: Array<Api.RatesFetcherErrorModel>
        }
    }
    selectedCarrierOfferId: string;
    filterModel: SelectionFilterModel;
    isLoading: boolean;
    isCarrierRatesLoading: boolean;
    isSchedulesLoading: boolean;
    requestTime: number;
    advCriteriaOpened: boolean;
    advContainerOpened: boolean;
    currentPage: number;
    errorMessages: Array<string>;
    warningMessages: Array<string>;
}

export interface FirmNewsesState {
    isOpen: boolean;
    selectedFirmNewsId?: number;
}

export interface CarrierOfferState {
    selectedDepartId: number;
    selectedServiceId: number;
    detailsOpened: boolean;
    sizeTypeDetailsOpened: { [id: number]: boolean };
    documentInfoDetailsOpened: { [id: number]: boolean };
    blDetailsOpened: boolean;
    lclDetailsOpened: boolean;
    ratesDetailsOpened: boolean;
    ratesOfferDescriptionOpened: boolean;
    contactsOpened: boolean;
    isLoading: boolean;
    groupOpened: boolean;
    sendQuotationState: RequestState;
    downloadDocumentState: RequestState;
    goToWebSiteState: RequestState;
}

export interface SelectionFilterModel {
    maxRatesDate: number;
    minRatesDate: number;
    maxEtaDate: number;
    minEtaDate: number;
    hideNoRates: boolean;
    ratesType: RatesTypeFilter;
    ratesTypeName: string;
    maxAllIn: number;
    maxTransitTime: number;
    maxTransShipment: number;
    onlyShowBestTt: boolean;
    byRatesOffer: boolean;
    byTransShipment: boolean;
    carriersOpened: boolean;
    carrierGroupsOpened: { [id: number]: boolean };
    carriersSelected: { [id: number]: boolean };
    carrierOffersSelected: { [id: string]: boolean };
    sortBy: CarrierOfferSort;
}

export interface SelectionFilterOptions {
    minAllIn: number;
    maxAllIn: number;
    minEtaDate: number;
    maxEtaDate: number;
    minTransitTime: number;
    maxTransitTime: number;
    minRatesDate: number;
    maxRatesDate: number;
    maxTransShipment: number;
    carriersSelected: { [id: number]: boolean }
}

export type CarrierOfferSort =
    "AllIn"
    | "TransitTime"
    | "TransShipments"
    | "Carrier"
    | "Validity"
    | "Pol"
    | "Pod"
    | "AllInCtr1"
    | "AllInCtr2"
    | "AllInCtr3"
    | "AllInCtr4"
    ;

export type RatesTypeFilter = "Fak"
    | "Special"
    | "AllRates";

export enum Source {
    Origin = 1,
    Destination = 2
}

interface RequestState {
    isLoading: boolean;
    requestTime?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
interface RequestSelection { type: 'REQUEST_SELECTION'; payload: { code: string, requestTime: number } }
export interface ReceiveSelection {
    type: 'RECEIVE_SELECTION';
    payload: {
        requestTime: number;
        selection: Api.SelectionModel;
        criteria: Api.CriteriaModel;
        errorMessage?: string
    }
    error?: any;
}
//interface InitializeFilter { type: 'INITIALIZE_FILTER', currencies: { [id: number]: Api.CurrencyModel }, criteria: Api.CriteriaModel}
interface SelectionResetFilter {
    type: 'SELECTION_RESET_FILTER',
    payload: { filterOptions: SelectionFilterOptions, defaultHideNoRates: boolean; }
}

interface SelectFilterMaxAllIn { type: 'SELECT_FILTER_MAX_ALLIN', value: number }
interface SelectFilterMaxTransitTime { type: 'SELECT_FILTER_MAX_TRANSITTIME', value: number }
interface SelectFilterMaxTransShipment { type: 'SELECT_FILTER_MAX_TRANSSHIPMENT', payload: { value: number } }

interface SelectFilterEtaDate { type: 'SELECT_FILTER_ETA_DATE', payload: { value: number } }
interface SelectFilterRatesDate { type: 'SELECT_FILTER_RATES_DATE', min: number, max: number }
interface SelectFilterHideNoRates { type: 'SELECT_FILTER_HIDE_NO_RATES', value: boolean }

interface SelectFilterRatesType { type: 'SELECT_FILTER_RATES_TYPE', value: RatesTypeFilter }
interface SelectFilterCarrier { type: 'SELECT_FILTER_CARRIER', carrierId: number, value: boolean }
interface SelectFilterCarriers { type: 'SELECT_FILTER_CARRIERS', carrierIds: Array<number>, value: boolean }
interface SelectFilterCarrierOffer { type: 'SELECT_FILTER_CARRIEROFFER', carrierOfferKey: string, value: boolean }
interface SelectFilterAllCarrierOffer { type: 'SELECT_FILTER_ALL_CARRIEROFFER', value: boolean }
interface SelectFilterSort { type: 'SELECT_FILTER_SORT', value: CarrierOfferSort }
interface UpdateFilterRatesTypeName { type: 'UPDATE_FILTER_RATESTYPE_NAME', value: string }
interface ToggleFilterCarrierGroup { type: 'TOGGLE_FILTER_CARRIERGROUP', carrierGroupId: number }
interface SelectFilterAllCarrier { type: "SELECT_FILTER_ALL_CARRIER"; payload: { value: boolean; carrierIds: Array<number> } }
interface SelectFilterByRatesOffer { type: "SELECT_FILTER_BY_RATESOFFER"; payload: { value: boolean; } }
interface SelectFilterByTranshipment { type: "SELECT_FILTER_BY_TRANSHIPMENT"; payload: { value: boolean; } }

interface ToggleAdvCriteria { type: 'TOGGLE_ADV_CRITERIA', value: boolean }
interface OpenAdvContainer { type: 'OPEN_ADV_CONTAINER' }
interface CloseAdvContainer { type: 'CLOSE_ADV_CONTAINER' }

interface RequestDownloadRates { type: 'REQUEST_DOWNLOAD_RATES', chargeSetId: number }
interface ReceiveDownloadRates { type: 'RECEIVE_DOWNLOAD_RATES', success: boolean }

interface SelectCarrierOffer { type: 'SELECT_CARRIEROFFER', carrierOfferId: string }
interface SelectCarrierOfferDepart { type: 'SELECT_CARRIEROFFER_DEPART', id: string, departId: number }
interface ToggleCarrierOfferDetails { type: 'TOGGLE_CARRIEROFFER_DETAILS', id: string, value: boolean }
interface ToggleCarrierOfferSizeTypeDetails { type: 'TOGGLE_CARRIEROFFER_SIZETYPE_DETAILS', id: string, value: boolean, sizeTypeId: number }
interface ToggleCarrierOfferDocumentDetails { type: 'TOGGLE_CARRIEROFFER_DOCUMENTINFO_DETAILS', id: string, value: boolean, documentInfoId: number }
interface ToggleCarrierOfferBlDetails { type: 'TOGGLE_CARRIEROFFER_BL_DETAILS', id: string, value: boolean }
interface ToggleCarrierOfferLclDetails { type: 'TOGGLE_CARRIEROFFER_LCL_DETAILS', payload: { id: string, value: boolean } }
interface ToggleCarrierOfferRatesDetails { type: 'TOGGLE_CARRIEROFFER_RATES_DETAILS', id: string, value: boolean }
interface ToggleCarrierOfferContacts { type: 'TOGGLE_CARRIEROFFER_CONTACTS', id: string, value: boolean }
interface ToggleCarrierOfferRatesOfferDescription { type: 'TOGGLE_CARRIEROFFER_RATESOFFER_DESCRIPTION', id: string, value: boolean }

export interface SelectionGoToQuotation { type: 'SELECTION_GO_TO_QUOTATION'; payload: { chargeSetId: number; departId: number; time: number } }
interface SelectionSetCarrierOfferStateLoading { type: 'SELECTION_SET_CARRIEROFFERSTATE_LOADING'; id: string; value: boolean }

interface SelectionRequestSendAgentQuotation { type: 'SELECTION_REQUEST_SEND_AGENT_QUOTATION'; requestTime: number; key: string }
interface SelectionReceiveSendAgentQuotation { type: 'SELECTION_RECEIVE_SEND_AGENT_QUOTATION'; requestTime: number; key: string }

interface SelectionRequestDownloadRatesDocument { type: 'SELECTION_REQUEST_DOWNLOAD_RATES_DOCUMENT'; requestTime: number; key: string }
interface SelectionReceiveDownloadRatesDocument { type: 'SELECTION_RECEIVE_DOWNLOAD_RATES_DOCUMENT'; requestTime: number; key: string }

interface SelectionOpenFirmNews { type: 'SELECTION_OPEN_FIRMNEWS'; firmNewsId: number }
interface SelectionCloseFirmNews { type: 'SELECTION_CLOSE_FIRMNEWS' }

interface SelectionRequestGoCarrierWebSite { type: 'SELECTION_REQUEST_GO_CARRIER_WEBSITE'; payload: { id: string; requestTime: number; } }
interface SelectionReceiveGoCarrierWebSite { type: 'SELECTION_RECEIVE_GO_CARRIER_WEBSITE'; payload: { id: string; requestTime: number; } }

interface SelectionOpenCarrierOfferGroup {
    type: 'SELECTION_OPEN_CARRIEROFFER_GROUP';
    payload: { id: string; }
}
interface SelectionCloseCarrierOfferGroup {
    type: 'SELECTION_CLOSE_CARRIEROFFER_GROUP';
    payload: { id: string; }
}

interface SelectionSelectCarrierOfferService {
    type: 'SELECTION_SELECT_CARRIEROFFER_SERVICE';
    payload: { key: string; routeServiceId: number; }
}

export interface SelectionCarrierRatesLoadingStarted {
    type: 'SELECTION_CARRIERRATES_LOADING_STARTED';
}
export interface SelectionCarrierRatesLoadingEnded {
    type: 'SELECTION_CARRIERRATES_LOADING_ENDED';
}
export interface SelectionSchedulesLoadingStarted {
    type: 'SELECTION_SCHEDULES_LOADING_STARTED';
}
export interface SelectionSchedulesLoadingEnded {
    type: 'SELECTION_SCHEDULES_LOADING_ENDED';
}

export interface SelectionRequestCarrierRates {
    type: 'SELECTION_REQUEST_CARRIERRATES';
    payload: { carrierRatesPlatform: Api.CarrierRatesPlatformModel; requestTime: number; }
}

export interface SelectionReceiveCarrierRates {
    type: 'SELECTION_RECEIVE_CARRIERRATES';
    payload: {
        carrierRatesPlatform: Api.CarrierRatesPlatformModel;
        requestTime: number;
        carrierOffers?: Array<Api.CarrierOfferModel>;
        fetcherErrors?: Array<Api.RatesFetcherErrorModel>;
        isLoading: boolean;
        error?: any;
    }
    error?: any;
}

interface SelectionUpdateOnlyBestTt {
    type: "SELECTION_UPDATE_ONLYBESTTT";
    payload: { value: boolean; }
}

interface SelectPage { type: 'SELECT_PAGE', page: number }
export interface SelectionResetOptions {
    type: 'SELECTION_RESET_OPTIONS';
    payload: { filter: SelectionFilterModel; criteria: Api.CriteriaModel; }
}

interface SelectionFilterOpenCarriers {
    type: "SELECTION_FILTER_OPEN_CARRIERS";
}
interface SelectionFilterCloseCarriers {
    type: "SELECTION_FILTER_CLOSE_CARRIERS";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestSelection
    | ReceiveSelection
    | SelectFilterMaxAllIn | SelectFilterMaxTransitTime
    | SelectFilterEtaDate | ToggleAdvCriteria | SelectPage
    | SelectFilterRatesType | SelectFilterRatesDate
    | SelectFilterMaxTransShipment
    | SelectCarrierOfferDepart
    | ToggleCarrierOfferDetails
    | ToggleCarrierOfferSizeTypeDetails
    | ToggleCarrierOfferDocumentDetails
    | ToggleCarrierOfferBlDetails
    | ToggleCarrierOfferRatesDetails | SelectionResetFilter
    | SelectionRequestSendAgentQuotation
    | SelectionReceiveSendAgentQuotation
    | SelectFilterCarrier
    | SelectFilterCarriers
    | ToggleFilterCarrierGroup
    | SelectFilterCarrierOffer
    | SelectFilterAllCarrierOffer
    | SelectFilterHideNoRates
    | ToggleCarrierOfferContacts | SelectFilterSort
    | SelectionRequestDownloadRatesDocument
    | SelectionReceiveDownloadRatesDocument
    | ToggleCarrierOfferRatesOfferDescription
    | SelectCarrierOffer
    | UpdateFilterRatesTypeName
    | SelectionGoToQuotation
    | SelectionSetCarrierOfferStateLoading
    | SelectionOpenFirmNews | SelectionCloseFirmNews
    | ReceiveCurrentUser | SelectFilterAllCarrier
    | SelectFilterByRatesOffer
    | SelectFilterByTranshipment
    | ToggleCarrierOfferLclDetails
    | SelectionRequestGoCarrierWebSite
    | SelectionReceiveGoCarrierWebSite
    | Logout
    | SelectionOpenCarrierOfferGroup
    | SelectionCloseCarrierOfferGroup
    | SelectionCarrierRatesLoadingStarted
    | SelectionCarrierRatesLoadingEnded
    | SelectionSchedulesLoadingStarted
    | SelectionSchedulesLoadingEnded
    | SelectionSelectCarrierOfferService
    | SelectionRequestCarrierRates
    | SelectionReceiveCarrierRates
    | SelectionUpdateOnlyBestTt
    | SelectionResetOptions
    | SelectionFilterOpenCarriers
    | SelectionFilterCloseCarriers
    | OpenAdvContainer
    | CloseAdvContainer
    ;
type KnownCriteriaAction = Criteria.RequestCriteriaAction | Criteria.ReceiveCriteriaAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const getTableTransitTime = (carrierOffer: Api.CarrierOfferModel, carrierOfferStates: { [key: string]: CarrierOfferState }): number | undefined => {
    if((carrierOffer.chargeSet?.transitTime 
            || (carrierOffer.chargeSet?.transShipments && carrierOffer.chargeSet?.transShipments.length !== 0))
        && !carrierOffer.chargeSet?.ratesFetchedRecordId){
        return carrierOffer.chargeSet?.transitTime;
    }

    if (carrierOffer.chargeSet
        && carrierOffer.chargeSet.departs
        && carrierOffer.chargeSet.departs.length !== 0
        && carrierOffer.chargeSet.departs[0].transitTime) {
        return carrierOffer.chargeSet.departs[0].transitTime
    }

    if (carrierOffer.chargeSet && carrierOffer.chargeSet.transitTime > 0) {
        return carrierOffer.chargeSet.transitTime
    }


    let routeConfig = getRouteConfig(carrierOffer, carrierOfferStates[getCarrierOfferKey(carrierOffer)]);
    if (!routeConfig)
        return undefined;

    if (carrierOffer.chargeSet?.routeServiceId && routeConfig.routeServiceId !== carrierOffer.chargeSet.routeServiceId) {
        return null;
    }

    return routeConfig.departs.length !== 0
        && routeConfig.departs[0].transitTime
        ? routeConfig.departs[0].transitTime
        : routeConfig.transitTime;
}

export const actionCreators = {
    searchSelection: (code: string, requestTime: number): AppThunkAction<KnownAction | KnownCriteriaAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().selection.requestTime)
            return Promise.resolve();

        let selectionApi = new Api.SelectionApi();
        let criteriaApi = new Api.CriteriaApi();
        let ratesFetcherApi = new Api.RatesFetcherApi();

        let task = new Promise<void>((resolve, reject) => {
            criteriaApi.getCriteria({ code: code },
                { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(criteria => {
                    dispatch({ type: 'RECEIVE_CRITERIA', criteria: criteria, requestTime: requestTime });
                    if (CriteriaUtils.hasAdvanced(criteria)) {
                        dispatch({ type: 'TOGGLE_ADV_CRITERIA', value: true });
                    }
                    if (criteria.includeCarrierRates) {
                        ratesFetcherApi.getAvailableRatesFetchers({
                            credentials: "same-origin",
                            headers: getDefaultHeaders(getState())
                        })
                            .then(async carrierRatesPlatforms => {
                                let pols = [criteria.origin];
                                let pods = [criteria.destination];
                                if (criteria.origin.locationType === "PortGroup") {
                                    pols = criteria.origin.portsInGroup;
                                }
                                if (criteria.destination.locationType === "PortGroup") {
                                    pods = criteria.destination.portsInGroup;
                                }
                                let reqTime = new Date().getTime();

                                let requestedFetchers = [];

                                let tasks = [[]];
                                carrierRatesPlatforms
                                    .filter(x => x.carrierId)
                                    .forEach(carrierRatesPlatform => {
                                        pols.forEach(pol => {
                                            pods.forEach(async pod => {
                                                if ((carrierRatesPlatform.type == "Fcl" || carrierRatesPlatform.type == "Extension") && criteria.type == "Fcl") {  //in future support for LCL
                                                    tasks[tasks.length - 1].push(() => {
                                                        return new Promise(resolve => setTimeout(resolve, 50))
                                                            .then(() => {
                                                                dispatch({
                                                                    type: "SELECTION_REQUEST_CARRIERRATES",
                                                                    payload: {
                                                                        carrierRatesPlatform: carrierRatesPlatform,
                                                                        requestTime: reqTime
                                                                    }
                                                                });
                                                                return ratesFetcherApi.getOnlineCarrierOffers({
                                                                    model: {
                                                                        criteria: {
                                                                            ...criteria,
                                                                            origin: pol,
                                                                            destination: pod,
                                                                        },
                                                                        ratesFetchers: [carrierRatesPlatform.code]
                                                                    }
                                                                }, {
                                                                    credentials: "same-origin",
                                                                    headers: getDefaultHeaders(getState())
                                                                }).then(carrierOffers => {
                                                                    let isLoading = requestedFetchers.includes(carrierRatesPlatform.code);
                                                                    dispatch({
                                                                        type: "SELECTION_RECEIVE_CARRIERRATES",
                                                                        payload: {
                                                                            carrierRatesPlatform: carrierRatesPlatform,
                                                                            requestTime: reqTime,
                                                                            carrierOffers: carrierOffers.carrierOffers,
                                                                            fetcherErrors: carrierOffers.errorByFetcherCode[carrierRatesPlatform.code],
                                                                            isLoading: isLoading
                                                                        }
                                                                    });
                                                                })
                                                                    .catch(err => {
                                                                        let isLoading = requestedFetchers.includes(carrierRatesPlatform.code);
                                                                        dispatch({
                                                                            type: "SELECTION_RECEIVE_CARRIERRATES",
                                                                            payload: {
                                                                                carrierRatesPlatform: carrierRatesPlatform,
                                                                                requestTime: reqTime,
                                                                                fetcherErrors: err && err.status == 429 ? [{
                                                                                    type: "OkargoApiLimitReached",
                                                                                    carrierRatesPlatformId: carrierRatesPlatform.carrierRatesPlatformId
                                                                                }] : [],
                                                                                isLoading: isLoading
                                                                            },
                                                                            error: err
                                                                        });
                                                                    });
                                                            });
                                                    });
                                                } else {
                                                    return null;
                                                }
                                            });
                                        });
                                        tasks.push([]);
                                    });
                                let promises = [];
                                const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                                for (let i = 0; i < tasks.length; i++) {
                                    const asyncRequests = (async () => {
                                        for(let j = 0; j < tasks[i].length; j++){
                                            console.log(Moment().format("LTS"), "Start request " + i);
                                            await tasks[i][j]();
                                            await sleep(5000);
                                        }
                                    })
                                    promises.push(asyncRequests());
                                    // let promises = [];
                                    // tasks[i].forEach(x => {
                                    //     console.log(Moment().format("LTS"), "Start request");
                                    //     promises.push(x());
                                    // });
                                    // console.log(Moment().format("LTS"), "Await request");
                                    // await Promise.all(promises);
                                    //console.log(Moment().format("LTS"), "Finished request");
                                }
                                await Promise.all(promises);
                                dispatch({type: "SELECTION_CARRIERRATES_LOADING_ENDED"});
                            });
                    }

                    return selectionApi.searchSelection({ code: code }, { headers: getDefaultHeaders(getState()) })
                        .then(selection => {
                            let currentState = getState();
                            //When new carriers have been added
                            let seedCarriersLoading = selection.carrierOffers
                                .some(x => !currentState.seed.carriers[x.carrierId])
                                ? requestSeedCarriers(new Date().getTime(), dispatch as any, getState)
                                : Promise.resolve();

                            return seedCarriersLoading.then(() => {
                                dispatch({
                                    type: 'RECEIVE_SELECTION',
                                    payload: {
                                        requestTime: requestTime,
                                        selection: selection,
                                        criteria: criteria
                                    }
                                });
                                if (criteria.includeSellingRates) {
                                    requestSelectionSellingRates(requestTime, criteria, dispatch as any, getState)
                                        .then(() => {
                                            resolve();
                                        }).catch(err => {
                                            dispatch(Notifications.error({ message: "Error loading selling rates", title: "Error", position: "tc" }) as any);
                                            resolve();
                                        });
                                } else {
                                    resolve();
                                }
                            })
                                .catch(err => {
                                    dispatch(Notifications.error({
                                        message: "Error loading selection: New carriers could not be loaded, please refresh the page",
                                        title: "Error",
                                        position: "tc"
                                    }) as any);
                                    reject(err);
                                });
                        }).catch(error => {
                            let errorMessage = getText("SlcError");
                            if (error && error.status === 403) {
                                errorMessage = getText("SlcMultipleSessionError");
                            }
                            dispatch({
                                type: 'RECEIVE_SELECTION',
                                payload: {
                                    requestTime: requestTime,
                                    selection: unloadedState.selection,
                                    criteria: criteria,
                                    errorMessage: errorMessage
                                },
                                error: error
                            });
                            dispatch(Notifications.error({ message: "Error loading selection: " + errorMessage, title: "Error", position: "tc" }) as any);
                            console.error('Error fetching selection');
                            console.error(error);
                            reject(error);
                        });
                }).catch(error => {
                    console.log('Error fetching criteria: ' + error.message);
                    reject(error);
                });
        });

        dispatch({ type: 'REQUEST_CRITERIA', code: code, requestTime: requestTime });
        dispatch({ type: 'REQUEST_SELECTION', payload: { code: code, requestTime: requestTime } });

        return task;
    },
    goToCriteria: (criteriaType: Api.CriteriaModelTypeEnum): AppThunkAction<KnownAction | KnownCriteriaAction, void> => (dispatch, getState) => {
        if (criteriaType === "Lcl") {
            dispatch(push('/lcl/criteria/' + getState().criteria.criteria.code) as any);
        } else {
            dispatch(push('/criteria/' + getState().criteria.criteria.code) as any);
        }
    },
    goToQuotation: (departId: number | undefined, chargeSetId: number, routeId:number): AppThunkAction<KnownAction | KnownCriteriaAction, void> => (dispatch, getState) => {
        let carrierOfferKey = getCarrierOfferKey(getState().selection.selection.carrierOffers
            .find(co => ((!co.chargeSet && !chargeSetId)
                || (co.chargeSet && co.chargeSet.chargeSetId === chargeSetId))
                && (!departId || getAllDeparts(co)
                    .some(dp => dp.departId === departId))));

        dispatch({ type: "SELECTION_SET_CARRIEROFFERSTATE_LOADING", value: true, id: carrierOfferKey });
        CriteriaUtils.getCriteriaCode(getState, getState().criteria.criteria, getState().selection.criteriaLoaded).then(code => {
            dispatch({
                type: "SELECTION_GO_TO_QUOTATION",
                payload: { departId: departId, chargeSetId: chargeSetId, time: new Date().getTime() }
            });
            dispatch({ type: "SELECTION_SET_CARRIEROFFERSTATE_LOADING", value: false, id: carrierOfferKey });
            dispatch(push("/quotation/" + code + "/" + departId + "/" + chargeSetId + "/null/" + routeId +"/Normal") as any);
        }).catch(error => {
            dispatch(Notifications.error({ message: "Failed to go to quotation", title: "Error", position: "tc" }) as any);
            dispatch({ type: "SELECTION_SET_CARRIEROFFERSTATE_LOADING", value: false, id: carrierOfferKey });
        });
    },
    requestSendAgentQuotation: (requestTime: number, carrierOfferKey: string): AppThunkAction<KnownAction | KnownCriteriaAction, void> => (dispatch, getState) => {
        let carrierOfferState = getState().selection.carrierOfferStates[carrierOfferKey];
        if (carrierOfferState && carrierOfferState.sendQuotationState.requestTime === requestTime)
            return;

        let carrierOffer = getState().selection.selection.carrierOffers
            .find(co => getCarrierOfferKey(co) === carrierOfferKey);

        CriteriaUtils.getCriteriaCode(getState, getState().criteria.criteria, getState().selection.criteriaLoaded).then(code => {
            let api = new Api.QuotationApi();
            api.sendAgentQuotation({
                model: {
                    chargeSetId: carrierOffer.chargeSet.chargeSetId,
                    departId: getSelectedDepart(carrierOffer, carrierOfferState)?.departId,
                    criteriaCode: code
                }
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }).then(() => {
                dispatch(Notifications.success({
                    message: "You quotation has beend sent by email",
                    title: "Success", position: "tc"
                }) as any);
                dispatch({ type: "SELECTION_RECEIVE_SEND_AGENT_QUOTATION", key: carrierOfferKey, requestTime: requestTime })
            }).catch(error => {
            });
        }).catch(error => {
            dispatch(Notifications.error({ message: "Failed to send your quotation", title: "Error", position: "tc" }) as any);
            dispatch({ type: "SELECTION_RECEIVE_SEND_AGENT_QUOTATION", key: carrierOfferKey, requestTime: requestTime })
        });

        dispatch({ type: "SELECTION_REQUEST_SEND_AGENT_QUOTATION", key: carrierOfferKey, requestTime: requestTime })
    },
    requestDownloadRatesDocument: (requestTime: number, carrierOfferKey: string, chargeSetId: number): AppThunkAction<KnownAction | KnownCriteriaAction, void> => (dispatch, getState) => {
        let carrierOfferState = getState().selection.carrierOfferStates[carrierOfferKey];
        if (carrierOfferState && carrierOfferState.sendQuotationState.requestTime === requestTime)
            return;

        let carrierOffer = getState().selection.selection.carrierOffers
            .find(co => getCarrierOfferKey(co) === carrierOfferKey);

        let api = new Api.SelectionApi();
        api.downloadWorkDocuments({
            chargeSetId: chargeSetId || carrierOffer.chargeSet.chargeSetId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "SELECTION_RECEIVE_DOWNLOAD_RATES_DOCUMENT", key: carrierOfferKey, requestTime: requestTime })
                let fileName = "Okargo_Documents_" + Moment().format("YYYY_MM_DD") + ".zip";
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            })
            .catch(error => {
                dispatch({ type: "SELECTION_RECEIVE_DOWNLOAD_RATES_DOCUMENT", key: carrierOfferKey, requestTime: requestTime })
                dispatch(Notifications.error({ message: "Failed to download your document", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "SELECTION_REQUEST_DOWNLOAD_RATES_DOCUMENT", key: carrierOfferKey, requestTime: requestTime })
    },
    filterUpdateMaxAllIn: (value: number) => <SelectFilterMaxAllIn>{ type: 'SELECT_FILTER_MAX_ALLIN', value: value },
    filterUpdateMaxTransShipment: (value: number) => <SelectFilterMaxTransShipment>{
        type: 'SELECT_FILTER_MAX_TRANSSHIPMENT', payload: { value: value }
    },
    filterToggleCarrierGroup: (carrierGroupId: number) => <ToggleFilterCarrierGroup>{ type: 'TOGGLE_FILTER_CARRIERGROUP', carrierGroupId: carrierGroupId },
    filterSelectAllCarrier: (value: boolean): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: 'SELECT_FILTER_ALL_CARRIER',
            payload: { value: value, carrierIds: _.values(getState().seed.carriers).map(x => x.carrierId) }
        });
    },
    filterSelectCarrier: (carrierId: number, value: boolean) => <SelectFilterCarrier>{ type: 'SELECT_FILTER_CARRIER', carrierId: carrierId, value: value },
    filterSelectCarrierGroup: (carrierGroupId: number, value: boolean): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let carrierIds = dictionnaryToArray<Api.CarrierModel>(getState().seed.carriers)
            .filter(ca => ca.carrierGroupId === carrierGroupId)
            .map(ca => ca.carrierId);
        //let isSelected = carrierIds.some(cId => getState().selection.filterModel.carriersSelected[cId]);
        dispatch({ type: "SELECT_FILTER_CARRIERS", carrierIds: carrierIds, value: value });
    },
    filterUpdateMaxTransitTime: (value: number) => <SelectFilterMaxTransitTime>{ type: 'SELECT_FILTER_MAX_TRANSITTIME', value: value },
    filterUpdateEtaDate: (value: number) => <SelectFilterEtaDate>{
        type: 'SELECT_FILTER_ETA_DATE', payload: { value: value }
    },
    filterUpdateRatesDate: (min: number, max: number) => <SelectFilterRatesDate>{ type: 'SELECT_FILTER_RATES_DATE', min: min, max: max },
    filterUpdateRatesType: (value: RatesTypeFilter) => <SelectFilterRatesType>{ type: 'SELECT_FILTER_RATES_TYPE', value: value },
    filterSelectCarrierOffer: (carrierOfferKey: string, value: boolean) => <SelectFilterCarrierOffer>{ type: 'SELECT_FILTER_CARRIEROFFER', carrierOfferKey: carrierOfferKey, value: value },
    filterSelectAllCarrierOffer: (value: boolean) => <SelectFilterAllCarrierOffer>{ type: "SELECT_FILTER_ALL_CARRIEROFFER", value: value },
    filterSelectFilterSort: (value: CarrierOfferSort) => <SelectFilterSort>{ type: "SELECT_FILTER_SORT", value: value },
    filterUpdateRatesTypeName: (value: string) => <UpdateFilterRatesTypeName>{ type: "UPDATE_FILTER_RATESTYPE_NAME", value: value },
    filterSelectHideNoRates: (value: boolean) => <SelectFilterHideNoRates>{ type: "SELECT_FILTER_HIDE_NO_RATES", value: value },
    filterSelectByRatesOffer: (value: boolean) => <SelectFilterByRatesOffer>{
        type: "SELECT_FILTER_BY_RATESOFFER", payload: { value: value }
    },
    filterSelectTranshipment: (value: boolean) => <SelectFilterByTranshipment>{
        type: "SELECT_FILTER_BY_TRANSHIPMENT", payload: { value: value }
    },
    selectCarrierOffer: (carrierOfferId: string) => <SelectCarrierOffer>{ type: 'SELECT_CARRIEROFFER', carrierOfferId: carrierOfferId },
    selectCarrierOfferDepart: (id: string, departId: number) => <SelectCarrierOfferDepart>{ type: 'SELECT_CARRIEROFFER_DEPART', id: id, departId: departId },
    selectCarrierOfferService: (id: string, routeServiceId: number) => <SelectionSelectCarrierOfferService>{
        type: 'SELECTION_SELECT_CARRIEROFFER_SERVICE', payload: { key: id, routeServiceId: routeServiceId }
    },
    toggleCarrierOfferDetails: (id: string, value: boolean) => <ToggleCarrierOfferDetails>{ type: 'TOGGLE_CARRIEROFFER_DETAILS', id: id, value: value },
    toggleCarrierOfferBlDetails: (id: string, value: boolean) => <ToggleCarrierOfferBlDetails>{ type: 'TOGGLE_CARRIEROFFER_BL_DETAILS', id: id, value: value },
    toggleCarrierOfferLclDetails: (id: string, value: boolean) => <ToggleCarrierOfferLclDetails>{
        type: 'TOGGLE_CARRIEROFFER_LCL_DETAILS',
        payload: { id: id, value: value }
    },
    toggleCarrierOfferContacts: (id: string, value: boolean) => <ToggleCarrierOfferContacts>{ type: 'TOGGLE_CARRIEROFFER_CONTACTS', id: id, value: value },
    openCarrierOfferGroup: (id: string) => <SelectionOpenCarrierOfferGroup>{
        type: 'SELECTION_OPEN_CARRIEROFFER_GROUP', payload: { id: id }
    },
    closeCarrierOfferGroup: (id: string) => <SelectionCloseCarrierOfferGroup>{
        type: 'SELECTION_CLOSE_CARRIEROFFER_GROUP', payload: { id: id }
    },
    toggleCarrierOfferSizeTypeDetails: (id: string, value: boolean, sizeTypeId: number) =>
        <ToggleCarrierOfferSizeTypeDetails>{ type: 'TOGGLE_CARRIEROFFER_SIZETYPE_DETAILS', id: id, value: value, sizeTypeId: sizeTypeId },
    toggleCarrierOfferDocumentInfoDetails: (id: string, value: boolean, documentInfoId: number) =>
        <ToggleCarrierOfferDocumentDetails>{ type: 'TOGGLE_CARRIEROFFER_DOCUMENTINFO_DETAILS', id: id, value: value, documentInfoId: documentInfoId },
    toggleCarrierOfferRatesDetails: (id: string, value: boolean) => <ToggleCarrierOfferRatesDetails>{ type: 'TOGGLE_CARRIEROFFER_RATES_DETAILS', id: id, value: value },
    toggleCarrierOfferRatesOfferDescription: (id: string, value: boolean) =>
        <ToggleCarrierOfferRatesOfferDescription>{ type: 'TOGGLE_CARRIEROFFER_RATESOFFER_DESCRIPTION', id: id, value: value },
    selectPage: (page: number) => <SelectPage>{ type: 'SELECT_PAGE', page: page },
    resetFilter: (filterOptions: SelectionFilterOptions): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: "SELECTION_RESET_FILTER",
            payload: {
                filterOptions: filterOptions,
                defaultHideNoRates: getState().account.currentUser.clientModel.defaultHideNoRates
            }
        });
    },
    openFirmNews: (firmNewsId: number) => <SelectionOpenFirmNews>{ type: "SELECTION_OPEN_FIRMNEWS", firmNewsId: firmNewsId },
    closeFirmNews: () => <SelectionCloseFirmNews>{ type: "SELECTION_CLOSE_FIRMNEWS" },
    requestGoToCarrierWebSite: (id: string, requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let state = getState().selection.carrierOfferStates[id];
        if (state && state.goToWebSiteState.requestTime === requestTime)
            return;

        let api = new Api.SelectionApi();
        let carrierOffer = getState().selection.selection.carrierOffers
            .find(x => getCarrierOfferKey(x) === id);

        api.getCarrierForm({
            routeId: carrierOffer.chargeSet?.routeId || carrierOffer.routeConfigs[0]?.routeId,
            from: new Date(),
            to: Moment().add(7, 'days').toDate()
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(form => {
                if (form.result) {
                    goToFormModel(form.result);
                }
                dispatch({
                    type: "SELECTION_RECEIVE_GO_CARRIER_WEBSITE",
                    payload: { id: id, requestTime: requestTime }
                });
            })
            .catch(error => {
                dispatch(Notifications.error({ message: "Error going to carrier website", title: "Error", position: "tc" }) as any);
                console.log(error.message);
                dispatch({
                    type: "SELECTION_RECEIVE_GO_CARRIER_WEBSITE",
                    payload: { id: id, requestTime: requestTime }
                });
            });

        dispatch({ type: "SELECTION_REQUEST_GO_CARRIER_WEBSITE", payload: { id: id, requestTime: requestTime } });
    },
    toggleAdvCriteria: (value: boolean): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: 'TOGGLE_ADV_CRITERIA', value: value });
    },
    updateOnlyShowBestTt: (value: boolean) => <SelectionUpdateOnlyBestTt>{
        type: "SELECTION_UPDATE_ONLYBESTTT",
        payload: { value: value }
    },
    resetOptions: (): AppThunkAction<KnownAction | KnownCriteriaAction, void> => (dispatch, getState) => {
        let filterOptions = selectionConnect.getFilterOptions(getState());
        let criteria = getState().criteria.criteria;
        dispatch({
            type: "SELECTION_RESET_OPTIONS",
            payload: {
                filter: {
                    ...unloadedFilterModel,
                    maxAllIn: filterOptions.maxAllIn,
                    maxTransitTime: filterOptions.maxTransitTime,
                    minRatesDate: filterOptions.minRatesDate,
                    maxRatesDate: filterOptions.maxRatesDate,
                    minEtaDate: filterOptions.minEtaDate,
                    maxEtaDate: filterOptions.maxEtaDate,
                    maxTransShipment: filterOptions.maxTransShipment,
                    hideNoRates: getState().account.currentUser.clientModel.defaultHideNoRates
                },
                criteria: {
                    ...criteria,
                    ...Criteria.getDefaultCriteria(getState().account.currentUser),
                }
            }
        });
    },
    updateFilterCarriersOpened: (val: boolean) => val
        ? <SelectionFilterOpenCarriers>{ type: "SELECTION_FILTER_OPEN_CARRIERS" }
        : <SelectionFilterCloseCarriers>{ type: "SELECTION_FILTER_CLOSE_CARRIERS" },
    openAdvContainer: () => <OpenAdvContainer>{ type: "OPEN_ADV_CONTAINER" },
    closeAdvContainer: () => <CloseAdvContainer>{ type: "CLOSE_ADV_CONTAINER" },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedFilterModel: SelectionFilterModel = {
    carriersSelected: {},
    carrierGroupsOpened: {},
    maxAllIn: 0,
    maxRatesDate: Number.MAX_SAFE_INTEGER,
    maxEtaDate: Number.MAX_SAFE_INTEGER,
    minRatesDate: 0,
    minEtaDate: 0,
    maxTransitTime: 0,
    maxTransShipment: 0,
    hideNoRates: false,
    carrierOffersSelected: {},
    ratesType: "AllRates",
    ratesTypeName: "",
    sortBy: "AllIn",
    byRatesOffer: false,
    byTransShipment: false,
    onlyShowBestTt: true,
    carriersOpened: false
};

const unloadedState: SelectionState = {
    code: null,
    errorMessages: [],
    warningMessages: [],
    carrierRatesOffersCache: [],
    requestTime: 0,
    isLoading: false,
    advCriteriaOpened: false,
    advContainerOpened: false,
    carrierOfferStates: {},
    criteriaLoaded: null,
    selectedCarrierOfferId: null,
    isCarrierRatesLoading: false,
    isSchedulesLoading: false,
    selection: {
        carrierOffers: [],
        firmNewses: [],
        carrierRatings: {},
        errors: []
    },
    firmNewsesState: {
        isOpen: false
    },
    filterModel: unloadedFilterModel,
    currentPage: 1,
    carrierRatesStates: {
    }
};

export const unloadedCarrierOfferState: CarrierOfferState = {
    blDetailsOpened: false,
    lclDetailsOpened: false,
    detailsOpened: false,
    ratesDetailsOpened: false,
    sizeTypeDetailsOpened: {},
    documentInfoDetailsOpened: {},
    selectedDepartId: 0,
    selectedServiceId: 0,
    contactsOpened: false,
    ratesOfferDescriptionOpened: false,
    isLoading: false,
    groupOpened: false,
    downloadDocumentState: {
        isLoading: false,
    },
    sendQuotationState: {
        isLoading: false,
    },
    goToWebSiteState: {
        isLoading: false,
    },
}

export const reducer: Reducer<SelectionState> = (state: SelectionState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SELECTION':
            return {
                ...state,
                carrierRatesOffersCache: [],
                code: action.payload.code,
                requestTime: action.payload.requestTime,
                isLoading: true,
                isSchedulesLoading: false,
                isCarrierRatesLoading: false
            };
        case 'RECEIVE_SELECTION':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                selection: {
                    ...action.payload.selection,
                    carrierOffers: action.payload.selection.carrierOffers
                        .concat(state.carrierRatesOffersCache)
                },
                criteriaLoaded: {
                    ...state.criteriaLoaded,
                    ...action.payload.criteria,
                    dateBegin: Moment.utc(action.payload.criteria.dateBegin).local().toDate(),
                    dateEnd: Moment.utc(action.payload.criteria.dateEnd).local().toDate(),
                },
                carrierOfferStates: {},
                filterModel: {
                    ...unloadedFilterModel,
                    hideNoRates: state.filterModel.hideNoRates
                },
                currentPage: 1,
                errorMessages: [action.payload.errorMessage]
                    .concat(action.payload.selection.errors)
                    .filter(x => x),
                carrierRatesStates: action.payload.criteria.includeCarrierRates
                    ? state.carrierRatesStates
                    : {}
            };
        case 'SELECT_FILTER_MAX_ALLIN':
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    maxAllIn: action.value
                }
            };
        case 'SELECT_FILTER_MAX_TRANSSHIPMENT':
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    maxTransShipment: action.payload.value
                }
            };
        case 'SELECT_FILTER_MAX_TRANSITTIME':
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    maxTransitTime: action.value
                }
            };
        case 'SELECT_FILTER_ETA_DATE':
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    maxEtaDate: action.payload.value
                }
            };
        case 'SELECT_FILTER_RATES_DATE':
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    minRatesDate: action.min,
                    maxRatesDate: action.max
                }
            };
        case 'SELECT_FILTER_RATES_TYPE':
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    ratesType: action.value
                }
            };
        case 'TOGGLE_ADV_CRITERIA':
            return {
                ...state,
                advCriteriaOpened: action.value,
                advCriteriaCloseWarning: false
            };
        case 'SELECT_CARRIEROFFER_DEPART':
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.id]: {
                        ...state.carrierOfferStates[action.id] || unloadedCarrierOfferState,
                        selectedDepartId: action.departId
                    }
                }
            };
        case "SELECTION_SELECT_CARRIEROFFER_SERVICE":
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.payload.key]: {
                        ...state.carrierOfferStates[action.payload.key] || unloadedCarrierOfferState,
                        selectedServiceId: action.payload.routeServiceId
                    }
                }
            };
        case 'TOGGLE_CARRIEROFFER_DETAILS':
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.id]: {
                        ...state.carrierOfferStates[action.id] || unloadedCarrierOfferState,
                        detailsOpened: action.value
                    }
                }
            };
        case 'TOGGLE_CARRIEROFFER_BL_DETAILS':
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.id]: {
                        ...state.carrierOfferStates[action.id] || unloadedCarrierOfferState,
                        blDetailsOpened: action.value
                    }
                }
            };
        case 'TOGGLE_CARRIEROFFER_LCL_DETAILS':
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.payload.id]: {
                        ...state.carrierOfferStates[action.payload.id] || unloadedCarrierOfferState,
                        lclDetailsOpened: action.payload.value
                    }
                }
            };
        case 'TOGGLE_CARRIEROFFER_RATES_DETAILS':
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.id]: {
                        ...state.carrierOfferStates[action.id] || unloadedCarrierOfferState,
                        ratesDetailsOpened: action.value
                    }
                }
            };
        case "TOGGLE_CARRIEROFFER_RATESOFFER_DESCRIPTION":
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.id]: {
                        ...state.carrierOfferStates[action.id] || unloadedCarrierOfferState,
                        ratesOfferDescriptionOpened: action.value
                    }
                }
            };
        case 'TOGGLE_CARRIEROFFER_SIZETYPE_DETAILS':
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.id]: {
                        ...state.carrierOfferStates[action.id] || unloadedCarrierOfferState,
                        sizeTypeDetailsOpened: {
                            ...state.carrierOfferStates[action.id].sizeTypeDetailsOpened,
                            [action.sizeTypeId]: action.value
                        }
                    }
                }
            };
        case 'TOGGLE_CARRIEROFFER_DOCUMENTINFO_DETAILS':
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.id]: {
                        ...state.carrierOfferStates[action.id] || unloadedCarrierOfferState,
                        documentInfoDetailsOpened: {
                            ...state.carrierOfferStates[action.id].documentInfoDetailsOpened,
                            [action.documentInfoId]: action.value
                        }
                    }
                }
            };
        case 'TOGGLE_CARRIEROFFER_CONTACTS':
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.id]: {
                        ...state.carrierOfferStates[action.id] || unloadedCarrierOfferState,
                        contactsOpened: action.value
                    }
                }
            };
        case 'SELECT_PAGE':
            return {
                ...state,
                currentPage: action.page
            };
        case 'SELECTION_RESET_FILTER':
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    maxAllIn: action.payload.filterOptions.maxAllIn,
                    maxTransitTime: action.payload.filterOptions.maxTransitTime,
                    minRatesDate: action.payload.filterOptions.minRatesDate,
                    maxRatesDate: action.payload.filterOptions.maxRatesDate,
                    minEtaDate: action.payload.filterOptions.minEtaDate,
                    maxEtaDate: action.payload.filterOptions.maxEtaDate,
                    maxTransShipment: action.payload.filterOptions.maxTransShipment,
                    hideNoRates: action.payload.defaultHideNoRates
                },
                currentPage: 1
            };

        case "SELECT_FILTER_CARRIER":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    carriersSelected: {
                        ...state.filterModel.carriersSelected,
                        [action.carrierId]: action.value
                    }
                }
            };
        case "SELECT_FILTER_CARRIERS":
            let newCarriersSelected = {};
            action.carrierIds.forEach(cId => {
                newCarriersSelected[cId] = action.value
            });

            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    carriersSelected: {
                        ...state.filterModel.carriersSelected,
                        ...newCarriersSelected
                    }
                }
            };
        case "SELECT_FILTER_ALL_CARRIER":
            let facNewCarriersSelected = {};
            action.payload.carrierIds
                .forEach(x => {
                    facNewCarriersSelected[x] = action.payload.value;
                });

            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    carriersSelected: facNewCarriersSelected
                }
            };
        case "TOGGLE_FILTER_CARRIERGROUP":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    carrierGroupsOpened: {
                        ...state.filterModel.carrierGroupsOpened,
                        [action.carrierGroupId]: state.filterModel.carrierGroupsOpened[action.carrierGroupId]
                            ? false : true
                    }
                }
            };
        case "SELECT_FILTER_CARRIEROFFER":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    carrierOffersSelected: {
                        ...state.filterModel.carrierOffersSelected,
                        [action.carrierOfferKey]: action.value
                    }
                }
            };
        case "SELECT_FILTER_ALL_CARRIEROFFER":
            let newCarrierOffersSelected = {};
            state.selection.carrierOffers
                .forEach(co => {
                    newCarrierOffersSelected[getCarrierOfferKey(co)] = action.value;
                });

            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    carrierOffersSelected: newCarrierOffersSelected
                }
            };
        case "SELECT_FILTER_SORT":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    sortBy: action.value
                }
            };
        case "SELECT_FILTER_HIDE_NO_RATES":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    hideNoRates: action.value
                }
            };
        case "SELECT_CARRIEROFFER":
            return {
                ...state,
                selectedCarrierOfferId: action.carrierOfferId
            };
        case "UPDATE_FILTER_RATESTYPE_NAME":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    ratesTypeName: action.value
                }
            };
        case "SELECT_FILTER_BY_RATESOFFER":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    byRatesOffer: action.payload.value
                }
            };
        case "SELECT_FILTER_BY_TRANSHIPMENT":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    byTransShipment: action.payload.value,
                    onlyShowBestTt: false,

                }
            };
        case "SELECTION_SET_CARRIEROFFERSTATE_LOADING":
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.id]: {
                        ...state.carrierOfferStates[action.id] || unloadedCarrierOfferState,
                        isLoading: action.value
                    }
                }
            };
        case "SELECTION_GO_TO_QUOTATION":
            return state;
        case "SELECTION_REQUEST_SEND_AGENT_QUOTATION":
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.key]: {
                        ...state.carrierOfferStates[action.key] || unloadedCarrierOfferState,
                        sendQuotationState: {
                            ...(state.carrierOfferStates[action.key]
                                ? state.carrierOfferStates[action.key].sendQuotationState
                                : {}),
                            isLoading: true,
                            requestTime: action.requestTime
                        }
                    }
                }
            };
        case "SELECTION_RECEIVE_SEND_AGENT_QUOTATION":
            if (!state.carrierOfferStates[action.key]
                || state.carrierOfferStates[action.key].sendQuotationState.requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.key]: {
                        ...state.carrierOfferStates[action.key] || unloadedCarrierOfferState,
                        sendQuotationState: {
                            ...state.carrierOfferStates[action.key].sendQuotationState,
                            isLoading: false
                        }
                    }
                }
            };
        case "SELECTION_REQUEST_DOWNLOAD_RATES_DOCUMENT":
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.key]: {
                        ...state.carrierOfferStates[action.key] || unloadedCarrierOfferState,
                        downloadDocumentState: {
                            ...state.carrierOfferStates[action.key].downloadDocumentState,
                            isLoading: true,
                            requestTime: action.requestTime
                        }
                    }
                }
            };
        case "SELECTION_RECEIVE_DOWNLOAD_RATES_DOCUMENT":
            if (!state.carrierOfferStates[action.key]
                || state.carrierOfferStates[action.key].downloadDocumentState.requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.key]: {
                        ...state.carrierOfferStates[action.key] || unloadedCarrierOfferState,
                        downloadDocumentState: {
                            ...state.carrierOfferStates[action.key].downloadDocumentState,
                            isLoading: false
                        }
                    }
                }
            };
        case "SELECTION_OPEN_FIRMNEWS":
            return {
                ...state,
                firmNewsesState: {
                    ...state.firmNewsesState,
                    isOpen: true,
                    selectedFirmNewsId: action.firmNewsId
                }
            };
        case "SELECTION_CLOSE_FIRMNEWS":
            return {
                ...state,
                firmNewsesState: {
                    ...state.firmNewsesState,
                    isOpen: false
                }
            };
        case "SELECTION_REQUEST_GO_CARRIER_WEBSITE":
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.payload.id]: {
                        ...state.carrierOfferStates[action.payload.id] || unloadedCarrierOfferState,
                        goToWebSiteState: {
                            ...(state.carrierOfferStates[action.payload.id]
                                ? state.carrierOfferStates[action.payload.id].goToWebSiteState
                                : {}),
                            isLoading: true,
                            requestTime: action.payload.requestTime
                        }
                    }
                }
            };
        case "SELECTION_RECEIVE_GO_CARRIER_WEBSITE":
            if (!state.carrierOfferStates[action.payload.id]
                || state.carrierOfferStates[action.payload.id].goToWebSiteState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.payload.id]: {
                        ...state.carrierOfferStates[action.payload.id],
                        goToWebSiteState: {
                            ...state.carrierOfferStates[action.payload.id].goToWebSiteState,
                            isLoading: false
                        }
                    }
                }
            };
        case "SELECTION_OPEN_CARRIEROFFER_GROUP":
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.payload.id]: {
                        ...(unloadedCarrierOfferState || state.carrierOfferStates[action.payload.id]),
                        groupOpened: true
                    }
                }
            };
        case "SELECTION_CLOSE_CARRIEROFFER_GROUP":
            return {
                ...state,
                carrierOfferStates: {
                    ...state.carrierOfferStates,
                    [action.payload.id]: {
                        ...(unloadedCarrierOfferState || state.carrierOfferStates[action.payload.id]),
                        groupOpened: false
                    }
                }
            };
        case "RECEIVE_CURRENT_USER":
            if (action.error)
                return state;

            let isTestAccount = action.payload.currentUser.clientModel.subscriptions
                .some(x => x.subscriptionType === "Okargo"
                    && x.agency.companyId === superTransitId);

            return {
                ...state,
                warningMessages: isTestAccount
                    ? [getText("SlcTestWarning")]
                    : [],
                filterModel: {
                    ...state.filterModel,
                    byRatesOffer: action.payload.currentUser
                        ? action.payload.currentUser.clientModel.defaultByRatesOffer
                        : state.filterModel.byRatesOffer,
                    hideNoRates: action.payload.currentUser
                        ? action.payload.currentUser.clientModel.defaultHideNoRates
                        : state.filterModel.hideNoRates
                }
            };
        case "SELECTION_CARRIERRATES_LOADING_STARTED":
            return {
                ...state,
                isCarrierRatesLoading: true
            };
        case "SELECTION_CARRIERRATES_LOADING_ENDED":
            return {
                ...state,
                isCarrierRatesLoading: false
            };
        case "SELECTION_SCHEDULES_LOADING_STARTED":
            return {
                ...state,
                isSchedulesLoading: true
            };
        case "SELECTION_SCHEDULES_LOADING_ENDED":
            return {
                ...state,
                isSchedulesLoading: false
            };
        case "SELECTION_REQUEST_CARRIERRATES":
            return {
                ...state,
                carrierRatesStates: {
                    ...state.carrierRatesStates,
                    [action.payload.carrierRatesPlatform.code]: {
                        ...state.carrierRatesStates[action.payload.carrierRatesPlatform.code],
                        isLoading: true,
                        requestTime: action.payload.requestTime,
                        carrierRatesPlatformId: action.payload.carrierRatesPlatform.carrierRatesPlatformId,
                        errors: []
                    }
                }
            };
        case "SELECTION_RECEIVE_CARRIERRATES":
            if (!state.carrierRatesStates[action.payload.carrierRatesPlatform.code]
                || state.carrierRatesStates[action.payload.carrierRatesPlatform.code].requestTime !== action.payload.requestTime)
                return state;

            if (action.error) {
                return {
                    ...state,
                    carrierRatesStates: {
                        ...state.carrierRatesStates,
                        [action.payload.carrierRatesPlatform.code]: {
                            ...state.carrierRatesStates[action.payload.carrierRatesPlatform.code],
                            isLoading: action.payload.isLoading,
                            carrierRatesPlatformId: action.payload.carrierRatesPlatform.carrierRatesPlatformId,
                            errors: action.payload.fetcherErrors && action.payload.fetcherErrors.length !== 0
                                ? action.payload.fetcherErrors
                                : [action.error]
                        }
                    }
                };
            }

            return {
                ...state,
                carrierRatesOffersCache: state.isLoading
                    ? state.carrierRatesOffersCache.concat(action.payload.carrierOffers)
                    : state.carrierRatesOffersCache,
                selection: state.isLoading
                    ? state.selection
                    : {
                        ...state.selection,
                        carrierOffers: state.selection.carrierOffers
                            .concat(action.payload.carrierOffers)
                    },
                carrierRatesStates: {
                    ...state.carrierRatesStates,
                    [action.payload.carrierRatesPlatform.code]: {
                        ...state.carrierRatesStates[action.payload.carrierRatesPlatform.code],
                        isLoading: action.payload.isLoading,
                        carrierRatesPlatformId: action.payload.carrierRatesPlatform.carrierRatesPlatformId,
                        errors: action.payload.fetcherErrors
                            ? (state.carrierRatesStates[action.payload.carrierRatesPlatform.code].errors
                                ? state.carrierRatesStates[action.payload.carrierRatesPlatform.code].errors.concat(action.payload.fetcherErrors)
                                : action.payload.fetcherErrors)
                            : state.carrierRatesStates[action.payload.carrierRatesPlatform.code].errors
                    }
                }
            };
        case "SELECTION_UPDATE_ONLYBESTTT":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    onlyShowBestTt: action.payload.value,
                    byTransShipment: false
                }
            };
        case "SELECTION_RESET_OPTIONS":
            return {
                ...state,
                filterModel: action.payload.filter,
            };
        case "SELECTION_FILTER_OPEN_CARRIERS":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    carriersOpened: true
                }
            };
        case "SELECTION_FILTER_CLOSE_CARRIERS":
            return {
                ...state,
                filterModel: {
                    ...state.filterModel,
                    carriersOpened: false
                }
            };
        case "OPEN_ADV_CONTAINER":
            return {
                ...state,
                advContainerOpened: true
            };
        case "CLOSE_ADV_CONTAINER":
            return {
                ...state,
                advContainerOpened: false
            };
        case "LOGOUT":
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
