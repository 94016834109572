import * as React from 'react';
import * as Colors from '../styles/colors';
import Eye from '@material-ui/icons/Visibility';
import Closedeye from '@material-ui/icons/VisibilityOff';

const showPassword = {
    position: "relative",
    right: "1.5em",
    top: "8px",
    color: Colors.baseColors.lightBlue,
    cursor: "pointer",
} as React.CSSProperties;

type Props = {
    IsVisible: boolean;
    style?: React.CSSProperties;
}

export default class extends React.Component<Props> {
    public constructor(props) {
        super(props);
        this.state = {
            style: showPassword
        };
    }
    public render() {
        return (
            <span>
               
                <Eye
                    style={{
                        position: "relative",
                        right: "1.5em",
                        top: "8px",
                        color: Colors.baseColors.lightBlue,
                        cursor: "pointer",
                        display: this.props.IsVisible? "none": "",
                        ...this.props.style
                    }}
                />
                <Closedeye
                    style={{
                        position: "relative",
                        right: "1.5em",
                        top: "8px",
                        color: Colors.baseColors.lightBlue,
                        cursor: "pointer",
                        display: !this.props.IsVisible ? "none" : "",
                        ...this.props.style
                    }}
                />
            </span>
        );
    }
}