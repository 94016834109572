import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import * as QuotationStore from '../store/Quotation';
import Moment from 'moment';
import { RatesCalculator, ChargeModification, CurrencyResultSet, CurrencyResult } from '../utils/ratesUtils';
import { dictionnaryToArray, groupBy, getPort, scrollToAppTop } from '../utils/utils';
import Select  from './Select';
import CriteriaSizeType from '../components/CriteriaSizeType';
import CheckBox from './CheckBox'
import QuotationFee from './QuotationFee';
import { rowStyle, borderStyle } from "../styles/quotationStyles";
import InputNumber from "./InputNumber";
import Tooltip from "./Tooltip";
import { getText } from '../utils/langTexts';
import * as _ from 'lodash';
import QuotationCreateTemplateDialog from './QuotationCreateTemplateDialog';
import QuotationPreviewTemplateDialog from './QuotationPreviewTemplateDialog';
import { quotColors } from '../styles/colors';
import QuotationApplicationCharges from './QuotationApplicationCharges';
import { chargeAgentsToMoficiations } from '../utils/mapping';
import { Grid } from "@material-ui/core";
import Button from "./Button";
import { SplitButton } from "./SplitButton";

type QuotationRatesProps =
    QuotationStore.QuotationState
    & QuotationRatesOwnProps
    & typeof QuotationStore.actionCreators;

interface QuotationRatesOwnProps {
    chargeNames: { [id: number]: Api.ChargeNameModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    languages: { [id: number]: Api.LanguageModel };
    currencies: { [id: number]: Api.CurrencyModel };
    carriers: { [id: number]: Api.CarrierModel; }
}

interface QuotationParams {
    quotationId: string;
    criteriaCode: string;
    departId: string;
    chargeSetId: string;
}

export const totalDisplayOptions = (): Array<{ label: string, value: Api.QuotationModelTotalTypeEnum }> => [
    { label: getText("QtnTotalTypeNone"), value: "None" },
    { label: getText("QtnTotalTypeByCurrency"), value: "ByCurrency" },
    { label: getText("QtnTotalTypeAllIn"), value: "AllIn" }
];
export const detailsLevelOptions = (): Array<{ label: string, value: Api.QuotationModelDetailsLevelEnum }> => [
    { label: getText("QtnDetailsLevelNone"), value: "None" },
    { label: getText("QtnDetailsLevelPartialCharges"), value: "PartialCharges" },
    { label: getText("QtnDetailsLevelPartialTotal"), value: "PartialTotal" },
    { label: getText("QtnDetailsLevelFull"), value: "Full" }
];

const otherInfoStyle = {};
const actionButtonStyle = { margin: 5, float: "right" as any };

export const quotationChargeModifications = (quotation: Api.QuotationModel): Array<ChargeModification> => {
    return quotation.quotationCharges
        .map(x => !x.visible
            ? [{
                chargeNameId: x.chargeNameId
                , modificationType: "Exclude"
                , amount: 0
                , application: x.application
                , level: x.level
                ,originalChargeId: 0
            } as ChargeModification]
            : x.quotationChargeAmounts.map(y => ({
                amount: y.amount,
                originalChargeId: y.originalChargeId,
                chargeNameId: x.chargeNameId,
                sizeTypeId: y.sizeTypeId,
                modificationType: "ReplaceAsMargin",
                application: x.application,
                level: x.level,
            } as ChargeModification)))
        .reduce((a, b) => a.concat(b), [])
};

export const quotationFeeCurrencyResultSet = (quotation: Api.QuotationModel,
    criteria: Api.CriteriaModel,
    currencies: { [id: number]: Api.CurrencyModel }): CurrencyResultSet => {
    var currencyResultSet = new CurrencyResultSet();
    quotation.quotationFees.forEach(x => {
        x.quotationFeeAmounts.forEach(y => {
            var nbUnit = !y.sizeTypeId
                ? 1
                : criteria.criteriaSizeTypes.some(z => z.sizeTypeId === y.sizeTypeId)
                    ? criteria.criteriaSizeTypes.find(z => z.sizeTypeId === y.sizeTypeId).number
                    : 0;
            currencyResultSet.add(new CurrencyResult(currencies[y.currencyId],
                y.baseAmount * nbUnit,
                (y.amount - y.baseAmount) * nbUnit))
        });
    });
    return currencyResultSet;
};

const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20
}

const labelStyle: React.CSSProperties = {
    flex: "0 0 auto",
    minWidth: 320,
    textAlign: "right",
    marginRight: 6
}

class QuotationRates extends React.Component<QuotationRatesProps, {}> {

    private ratesCalculator: RatesCalculator;
    private topRef: HTMLDivElement;
    private containerRef: HTMLDivElement;

    constructor(props: QuotationRatesProps) {
        super(props);
        this.ratesCalculator = new RatesCalculator(_.mapValues(props.currencies, x => ({
            ...x,
            value: props.quotation.quotationCurrencies.some(y => y.currencyId === x.currencyId)
                ? props.quotation.quotationCurrencies.find(y => y.currencyId === x.currencyId).value
                : x.value
        })),
            props.sizeTypes,
            props.criteria);
    }

    onPropsUpdate(props: QuotationRatesProps) {
        if (this.props.quotation.quotationCurrencies !== props.quotation.quotationCurrencies
            || this.props.currencies !== props.currencies
            || this.props.sizeTypes !== props.sizeTypes
            || this.props.criteria !== props.criteria) {
            this.ratesCalculator = new RatesCalculator(_.mapValues(props.currencies, x => ({
                ...x,
                value: props.quotation.quotationCurrencies.some(y => y.currencyId === x.currencyId)
                    ? props.quotation.quotationCurrencies.find(y => y.currencyId === x.currencyId).value
                    : x.value
            })),
                props.sizeTypes,
                props.criteria);
        }
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: QuotationRatesProps) {
        if (this.props.criteria !== nextProps.criteria
            || this.props.currencies !== nextProps.currencies)
            this.onPropsUpdate(nextProps);
    }

    componentDidMount() {
        scrollToAppTop();
        if (this.topRef && this.containerRef) {
            let scrollContainer = document.getElementById("scroll-container");
            if (!scrollContainer)
                return;

            scrollContainer.addEventListener("scroll", () => {
                if (!this.topRef
                    || !this.containerRef)
                    return;
                if (scrollContainer.scrollTop >= 40) {
                    this.topRef.style.setProperty("position", "fixed");
                    this.topRef.style.setProperty("margin", "auto");
                    this.topRef.style.setProperty("width", this.containerRef.getBoundingClientRect().width + "px");
                    this.topRef.style.setProperty("top", "110px");
                } else {
                    this.topRef.style.setProperty("position", undefined);

                    this.topRef.style.setProperty("margin", undefined);
                    this.topRef.style.setProperty("width", undefined);
                    this.topRef.style.setProperty("top", undefined);
                }
            });
        }
    }

    getSizeTypePositionName(position: number): string {
        return this.props.criteria.criteriaSizeTypes
            .some(st => this.props.sizeTypes[st.sizeTypeId].position === position)
            ? this.props.sizeTypes[this.props.criteria.criteriaSizeTypes
                .find(st => this.props.sizeTypes[st.sizeTypeId].position === position).sizeTypeId].name
            : "";
    }


    findMaxLevel(charges: Api.ChargeModel[]) {
        var lvl = groupBy(charges, x => x.sizeTypeId?.toString()).sort(x => x.members.length).reverse()[0];
        return lvl.members.length;
    }

    findChargeNamesFromApplication(application: Api.ChargeNameModelApplicationEnum, perBl: boolean): Array<Api.QuotationChargeNameLevels> {
        let charges: Array<Api.ChargeModel> = [];
        let chargeModifications = [];

        if (!perBl) {
            this.props.criteria.criteriaSizeTypes.filter(cst => cst.number > 0).forEach(cst => {
                charges = charges.concat(
                    this.ratesCalculator.findChargesToApply(
                        this.props.carrierOffer.chargeSet, cst, application, chargeModifications));

            });
        } else {
            charges = charges.concat(
                this.ratesCalculator.findBlChargesToApply(
                    this.props.carrierOffer.chargeSet, application, chargeModifications));
        }

        charges = groupBy(charges, c => c.chargeId.toString()).map(x => x.members[0]); //removes duplicities
        var resultNames = groupBy(charges, x => `${x.chargeNameId}-${x.currencyId}`);
        let chargeNamesWithUssageCount = resultNames.map(gc => ({ chargeName: this.props.chargeNames[parseInt(gc.key)], levels: this.findMaxLevel(gc.members), currencyId: gc.members[0].currencyId }));

        return chargeNamesWithUssageCount;
    }


    get currencies(): Array<Api.CurrencyModel> {
        return _.values(this.props.currencies).filter(cu => cu.primary
            || cu.currencyId === getPort(this.props.criteria.origin).country.currencyId
            || cu.currencyId === getPort(this.props.criteria.destination).country.currencyId
            || (this.props.carrierOffer
                && this.props.carrierOffer.chargeSet
                && this.props.carrierOffer.chargeSet.charges.some(ch => ch.currencyId === cu.currencyId)));
    }

    public render() {
        let allInResultSet = this.props.carrierOffer.chargeSet
            ? this.ratesCalculator.calculateAllIn(this.props.carrierOffer.chargeSet,
                chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)
                    .concat(quotationChargeModifications(this.props.quotation)))
            : new CurrencyResultSet();
        allInResultSet.addSet(quotationFeeCurrencyResultSet(this.props.quotation,
            this.props.criteria,
            this.props.currencies));
        const containerStyle = {
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start"
        } as React.CSSProperties;
        const navContainerStyle = {
            order: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderRadius: 8
        } as React.CSSProperties;
        const navInnerContainerStyle = {
            paddingBottom: 0
        } as React.CSSProperties;


        const oneToThree = []; //sometimes criteria came unsorted
        var tmp = _.sortBy(this.props.criteria.criteriaSizeTypes, x => x.sizeTypeId);
        tmp.forEach(c => {
            let index = this.props.criteria.criteriaSizeTypes.findIndex(st => st == c);
            oneToThree.push(index);
        });

        return (
            <Grid container>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <div ref={(ref) => this.containerRef = ref}>
                        <div style={{ paddingTop: 0 }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                            }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div style={{
                                            flex: "0 0 auto",
                                            minHeight: 123.2,
                                        }}>
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <div style={{
                                                        ...rowStyle,
                                                        zIndex: 9,
                                                         }}
                                                    ref={(ref) => this.topRef = ref}>
                                                    <div style={{
                                                        flex: 2,
                                                        borderBottom: borderStyle,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "stretch",
                                                        backgroundColor: "white"
                                                    }}>
                                                    <div style={{
                                                        ...rowStyle,
                                                        flex: 4,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center"
                                                    }}>
                                                        <div style={{ flex: "0 0 auto" }}>
                                                            <img onClick={() => this.props.quotationPrevStep()}
                                                                style={{ cursor: "pointer", flex: "0 0 auto" }}
                                                                src='/images/goBack.png' alt='Go back' width='39' height='27' />
                                                        </div>
                                                        <div style={{ flex: "1", textAlign: "center" }}>{getText("QtnSelectTemplate")}</div>
                                                    </div>
                                                    <div style={{
                                                        ...rowStyle,
                                                        flex: 2,
                                                        padding: "0px 10px"
                                                    }}>
                                                        <div style={{
                                                            display: "flex", flexDirection: "row",
                                                            alignItems: "center", width: "100%"
                                                        }}>
                                                            <div style={{ flex: "1" }}>
                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={[{ label: "--- " + getText("GenNone") + "---", value: 0 }]
                                                                        .concat(_.sortBy(_.sortBy(this.props.quotationTemplates, x => x.name),
                                                                            x => x.quotationTemplateSettings.templateType === "Master" ? 0 : 1)
                                                                            .map(x => ({
                                                                                label: x.name,
                                                                                value: x.quotationId,
                                                                                isMaster: x.quotationTemplateSettings.templateType === "Master"
                                                                            })))}
                                                                    value={this.props.loadedTemplateId}
                                                                    onChange={(value) => this.props.quotationLoadTemplate(value)}
                                                                    optionComponent={(opt) => <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        fontWeight: (opt as any).isMaster ? "bold" : undefined
                                                                    }}>
                                                                        <div style={{ flex: 1 }}>{opt.label}</div>
                                                                        {opt.value && <div style={{ flex: "0 0 auto" }}
                                                                            onClick={(e) => {
                                                                                this.props.quotationSelectTemplatePreview(opt.value);
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                            }}>Preview</div>}
                                                                    </div>} />
                                                            </div>
                                                            <div style={{ flex: "0 0 auto" }}>
                                                                <button className="btn btn-sm"
                                                                    type={"button"}
                                                                    style={{ backgroundColor: "white", color: quotColors.contentRowColor.color }}
                                                                    onClick={(e) => {
                                                                        this.props.quotationOpenTemplateListing();
                                                                        e.preventDefault();
                                                                    }}>
                                                                    ...
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...rowStyle,
                                                        flex: "0 0 auto",
                                                        padding: "6px 10px"
                                                    }}>
                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center"
                                                        }}>
                                                            <CheckBox label={getText("CrtPriceByContainer")}
                                                                value={this.props.quotation.byContainer}
                                                                onChange={(value) => this.props.quotationUpdateByContainer(value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: 4, display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "stretch"
                                                }}>
                                                    <div style={{
                                                        ...rowStyle,
                                                        ...quotColors.titleColor,
                                                        backgroundColor: "rgb(0,175,213)",
                                                        fontWeight: 700,
                                                        flex: 1,
                                                        minHeight: 60,
                                                        flexDirection: "column",
                                                        fontSize: 18,
                                                        padding: "6px 0px"
                                                    }}>
                                                        <div style={{ flex: 1, textAlign: "center" }}>
                                                            {this.props.carriers[this.props.carrierOffer.carrierId].name}
                                                        </div>
                                                        <div style={{ flex: 1, textAlign: "center" }}>
                                                            {this.props.carrierOffer.originPort.name}
                                                            {" "}&#62;{" "}
                                                            {this.props.carrierOffer.destinationPort.name}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...rowStyle,
                                                        ...quotColors.subTitleColor,
                                                        backgroundColor: "rgb(0,175,213)",
                                                        color: "white",
                                                        flex: 1,
                                                        minHeight: 60
                                                    }}>
                                                        {oneToThree.map(nb =>
                                                            <div key={nb} style={{ flex: 1, padding: "0px 10px" }}>
                                                                {this.props.criteria.criteriaSizeTypes[nb]
                                                                    && <CriteriaSizeType
                                                                        style={{ textAlign: "center" }}
                                                                        buttonStyle={{ backgroundColor: 'white', color: "black" }}
                                                                        min={0}
                                                                        max={this.props.quotation.byContainer ? 1 : 9}
                                                                        sizeTypes={this.props.sizeTypes}
                                                                        sizeTypeId={this.props.criteria.criteriaSizeTypes[nb].sizeTypeId}
                                                                        value={this.props.criteria.criteriaSizeTypes[nb].number}
                                                                        onChange={(value) => this.props.quotationUpdateCriteriaSizeType(value,
                                                                            this.props.criteria.criteriaSizeTypes[nb].sizeTypeId)} />}
                                                             </div>
                                                        )}
                                                     </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div style={{
                                        ...rowStyle, ...quotColors.mainRowColor,
                                        backgroundColor: "rgb(0,175,213)",
                                        color: "white",
                                        display: "flex", flexDirection: "row",
                                        alignItems: "center",
                                        fontWeight: 700,
                                        borderLeft: borderStyle,
                                        borderRight: borderStyle,
                                        paddingTop: 110
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            padding: "6px 12px"
                                        }}>
                                            <div style={{ flex: "0 0 auto" }}>FOB charges from </div>
                                            <input className="form-control input-sm"
                                                style={{ flex: "0 0 auto", maxWidth: 150, margin: "0px 10px" }}
                                                value={this.props.quotation.fobOrigin}
                                                onChange={(e) => this.props.quotationUpdateFobOrigin(e.target.value)} />
                                            <div style={{ flex: "0 0 auto" }}> to </div>
                                            <input className="form-control input-sm"
                                                style={{ flex: "0 0 auto", maxWidth: 150, margin: "0px 10px" }}
                                                value={this.props.quotation.fobDestination}
                                                onChange={(e) => this.props.quotationUpdateFobDestination(e.target.value)} />
                                        </div>
                                    </div>
                                    <div style={{
                                        ...rowStyle, ...quotColors.subRowColor,
                                        display: "flex", flexDirection: "row",
                                        alignItems: "center",
                                        backgroundColor: "rgb(240,240,240)",
                                        fontWeight: 500,
                                        borderLeft: borderStyle,
                                        borderRight: borderStyle,
                                    }}>
                                        <div style={{ paddingLeft: 40 }}>{getText("QtnPreTransport")}</div>
                                    </div>
                                    {this.props.quotation.quotationFees.filter(qf => qf.type === "PreTransport").map(qf =>
                                        <QuotationFee
                                            key={this.props.quotation.quotationFees.indexOf(qf)}
                                            quotationFee={qf}
                                            criteriaSizeTypes={this.props.criteria.criteriaSizeTypes}
                                            sizeTypes={this.props.sizeTypes}
                                            currencies={this.currencies}
                                            perBl={false}
                                            remove={() => this.props.quotationRemoveFee(this.props.quotation.quotationFees.indexOf(qf))}
                                            updateBaseAmount={(value, sizeTypeId) =>
                                                this.props.quotationUpdateFeeBaseAmount(this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                                            updateAmount={(value, sizeTypeId) =>
                                                this.props.quotationUpdateFeeAmount(this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                                            selectCurrency={(currencyId, sizeTypeId) =>
                                                this.props.quotationSelectFeeCurrency(this.props.quotation.quotationFees.indexOf(qf), currencyId, sizeTypeId)}
                                            updateName={(value) =>
                                                this.props.quotationUpdateFeeName(this.props.quotation.quotationFees.indexOf(qf), value)}
                                            updateUnit={(value) =>
                                                this.props.quotationUpdateFeeUnit(this.props.quotation.quotationFees.indexOf(qf), value)}
                                            onSelect={(value) =>
                                                this.props.quotationSelectFee(this.props.quotation.quotationFees.indexOf(qf), value)}
                                            quotationFeeState={this.props.feeStates[this.props.quotation.quotationFees.indexOf(qf)]
                                                || QuotationStore.unloadedFeeState}
                                            updateLabel={(value, sizeTypeId) => this.props.quotationUpdateFeeLabel(
                                                this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                                            updateMode={(value, sizeTypeId) => this.props.quotationUpdateFeeMode(
                                                this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                                            addQuotationFeeLabel={() => this.props.quotationAddQuotationFeeLabel(
                                                this.props.quotation.quotationFees.indexOf(qf))}
                                            removeQuotationFeeLabel={(index) => this.props.quotationRemoveQuotationFeeLabel(
                                                this.props.quotation.quotationFees.indexOf(qf), index)}
                                            updateQuotationFeeLabel={(index, value) => this.props.quotationUpdateQuotationFeeLabel(
                                                this.props.quotation.quotationFees.indexOf(qf), index, value)}
                                        />
                                    )}
                                    <div style={{
                                        ...rowStyle,
                                        borderLeft: borderStyle,
                                        borderRight: borderStyle,
                                    }}>
                                        <div style={{ flex: 2, borderRight: borderStyle, }}>
                                            <div style={{ cursor: "pointer", fontWeight: 200, textAlign: "center" }}
                                                onClick={(e) => {
                                                    this.props.quotationAddFee("PreTransport");
                                                    e.preventDefault();
                                                }}>
                                                {getText("QtnAddCharge")}
                                            </div>
                                        </div>
                                        <div style={{
                                            flex: 4,
                                            marginTop: 10,
                                            borderTop: "1px dotted"
                                        }}>
                                        </div>
                                    </div>
                                    <QuotationApplicationCharges
                                        title={"Origin charges"}
                                        application={"Origin"}
                                        feeType={"Origin"}
                                        usableCurrencies={this.currencies}
                                        perBl={false}
                                        ratesCalculator={this.ratesCalculator}
                                        findChargeNamesFromApplication={(application, perBl) => this.findChargeNamesFromApplication(application, perBl)}
                                        {...this.props} />
                                    <QuotationApplicationCharges
                                        title={null}
                                        application={"Origin"}
                                        feeType={"BlOrigin"}
                                        usableCurrencies={this.currencies}
                                        perBl={true}
                                        ratesCalculator={this.ratesCalculator}
                                        findChargeNamesFromApplication={(application, perBl) => this.findChargeNamesFromApplication(application, perBl)}
                                        {...this.props} />

                                            <div style={{
                                                ...rowStyle, ...quotColors.mainRowColor,
                                                display: "flex", flexDirection: "row",
                                                alignItems: "center",
                                                fontWeight: 700,
                                                borderLeft: borderStyle,
                                                borderRight: borderStyle,
                                                backgroundColor: "rgb(0,175,213)",
                                                color: "white",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    padding: "6px 12px"
                                                }}>
                                                    <div style={{ flex: "0 0 auto" }}>Freight from </div>
                                                    <input className="form-control input-sm"
                                                        style={{ flex: "0 0 auto", maxWidth: 150, margin: "0px 10px" }}
                                                        value={this.props.quotation.frtOrigin}
                                                        onChange={(e) => this.props.quotationUpdateFrtOrigin(e.target.value)} />
                                                    <div style={{ flex: "0 0 auto" }}> to </div>
                                                    <input className="form-control input-sm"
                                                        style={{ flex: "0 0 auto", maxWidth: 150, margin: "0px 10px" }}
                                                        value={this.props.quotation.frtDestination}
                                                        onChange={(e) => this.props.quotationUpdateFrtDestination(e.target.value)} />
                                                </div>
                                            </div>
                                            <QuotationApplicationCharges
                                                title={"Ocean charges"}
                                                application={"Freight"}
                                                feeType={"Freight"}
                                                usableCurrencies={this.currencies}
                                                perBl={false}
                                                ratesCalculator={this.ratesCalculator}
                                                findChargeNamesFromApplication={(application, perBl) => this.findChargeNamesFromApplication(application, perBl)}
                                                {...this.props} />
                                            <QuotationApplicationCharges
                                                title={null}
                                                application={"Freight"}
                                                feeType={"BlFreight"}
                                                usableCurrencies={this.currencies}
                                                perBl={true}
                                                ratesCalculator={this.ratesCalculator}
                                                findChargeNamesFromApplication={(application, perBl) => this.findChargeNamesFromApplication(application, perBl)}
                                                {...this.props}
                                            />

                                            <div style={{
                                                ...rowStyle, ...quotColors.mainRowColor,
                                                fontWeight: 700,
                                                borderLeft: borderStyle,
                                                borderRight: borderStyle,
                                                backgroundColor: "rgb(0,175,213)",
                                                color: "white",

                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    padding: "6px 12px"
                                                }}>
                                                    <div style={{ flex: "0 0 auto" }}>Destination charges from </div>
                                                    <input className="form-control input-sm"
                                                        style={{ flex: "0 0 auto", maxWidth: 150, margin: "0px 10px" }}
                                                        value={this.props.quotation.destinationOrigin}
                                                        onChange={(e) => this.props.quotationUpdateDestinationOrigin(e.target.value)} />
                                                    <div style={{ flex: "0 0 auto" }}> to </div>
                                                    <input className="form-control input-sm"
                                                        style={{ flex: "0 0 auto", maxWidth: 150, margin: "0px 10px" }}
                                                        value={this.props.quotation.destinationDestination}
                                                        onChange={(e) => this.props.quotationUpdateDestinationDestination(e.target.value)} />
                                                </div>
                                            </div>
                                            <QuotationApplicationCharges
                                                title={"Destination charges"}
                                                application={"Destination"}
                                                feeType={"Destination"}
                                                usableCurrencies={this.currencies}
                                                perBl={false}
                                                ratesCalculator={this.ratesCalculator}
                                                findChargeNamesFromApplication={(application, perBl) => this.findChargeNamesFromApplication(application, perBl)}
                                                {...this.props}
                                            />
                                            <QuotationApplicationCharges
                                                title={null}
                                                application={"Destination"}
                                                feeType={"BlDestination"}
                                                usableCurrencies={this.currencies}
                                                perBl={true}
                                                ratesCalculator={this.ratesCalculator}
                                                findChargeNamesFromApplication={(application, perBl) => this.findChargeNamesFromApplication(application, perBl)}
                                                {...this.props}
                                            />
                                            <div style={{
                                                ...rowStyle, ...quotColors.subRowColor,
                                                backgroundColor: "rgb(240,240,240)",
                                                display: "flex", flexDirection: "row",
                                                alignItems: "center",
                                                fontWeight: 500,
                                                borderLeft: borderStyle,
                                                borderRight: borderStyle,
                                            }}>
                                                <div style={{ paddingLeft: 40 }}>{getText("QtnPostTransport")}</div>
                                            </div>
                                            {this.props.quotation.quotationFees.filter(qf => qf.type === "PostTransport").map(qf =>
                                                <QuotationFee
                                                    key={this.props.quotation.quotationFees.indexOf(qf)}
                                                    quotationFee={qf}
                                                    criteriaSizeTypes={this.props.criteria.criteriaSizeTypes}
                                                    sizeTypes={this.props.sizeTypes}
                                                    currencies={this.currencies}
                                                    perBl={false}
                                                    remove={() => this.props.quotationRemoveFee(this.props.quotation.quotationFees.indexOf(qf))}
                                                    updateBaseAmount={(value, sizeTypeId) =>
                                                        this.props.quotationUpdateFeeBaseAmount(this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                                                    updateAmount={(value, sizeTypeId) =>
                                                        this.props.quotationUpdateFeeAmount(this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                                                    selectCurrency={(currencyId, sizeTypeId) =>
                                                        this.props.quotationSelectFeeCurrency(this.props.quotation.quotationFees.indexOf(qf), currencyId, sizeTypeId)}
                                                    updateName={(value) =>
                                                        this.props.quotationUpdateFeeName(this.props.quotation.quotationFees.indexOf(qf), value)}
                                                    updateUnit={(value) =>
                                                        this.props.quotationUpdateFeeUnit(this.props.quotation.quotationFees.indexOf(qf), value)}
                                                    onSelect={(value) =>
                                                        this.props.quotationSelectFee(this.props.quotation.quotationFees.indexOf(qf), value)}
                                                    quotationFeeState={this.props.feeStates[this.props.quotation.quotationFees.indexOf(qf)]
                                                        || QuotationStore.unloadedFeeState}
                                                    updateLabel={(value, sizeTypeId) => this.props.quotationUpdateFeeLabel(
                                                        this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                                                    updateMode={(value, sizeTypeId) => this.props.quotationUpdateFeeMode(
                                                        this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                                                    addQuotationFeeLabel={() => this.props.quotationAddQuotationFeeLabel(
                                                        this.props.quotation.quotationFees.indexOf(qf))}
                                                    removeQuotationFeeLabel={(index) => this.props.quotationRemoveQuotationFeeLabel(
                                                        this.props.quotation.quotationFees.indexOf(qf), index)}
                                                    updateQuotationFeeLabel={(index, value) => this.props.quotationUpdateQuotationFeeLabel(
                                                        this.props.quotation.quotationFees.indexOf(qf), index, value)}
                                                />
                                            )}
                                            <div style={{
                                                ...rowStyle,
                                                borderLeft: borderStyle,
                                                borderRight: borderStyle,
                                            }}>
                                                <div style={{ flex: 2, borderRight: borderStyle }}>
                                                    <div style={{ cursor: "pointer", fontWeight: 200, textAlign: "center" }}
                                                        onClick={(e) => {
                                                            this.props.quotationAddFee("PostTransport");
                                                            e.preventDefault();
                                                        }}>
                                                        {getText("QtnAddCharge")}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: 4,
                                                    marginTop: 10,
                                                    borderTop: "1px dotted"
                                                }}>
                                                </div>
                                            </div>
                                            <div style={{
                                                ...rowStyle,
                                                ...quotColors.subTitleColor,
                                                backgroundColor: "rgb(0,175,213)",
                                                color: "white",
                                                minHeight: 50,
                                                fontWeight: 600,
                                                padding: "12px 24px"
                                            }}>
                                                <div style={{ flex: 1 }}>
                                                    <div style={{
                                                        display: "flex", flexDirection: "row",
                                                        alignItems: "baseline"
                                                    }}>
                                                        <div style={{
                                                            flex: 1,
                                                            textAlign: "right",
                                                            marginRight: 6
                                                        }}>All-in buy: </div>
                                                        <div style={{
                                                            flex: 1,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "stretch"
                                                        }}>
                                                            {allInResultSet.currencyResults.map(x =>
                                                                <div style={{ flex: 1 }}>{Math.round(x.amount * 10) / 10} {x.currency.code}</div>)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <div style={{
                                                        display: "flex", flexDirection: "row",
                                                        alignItems: "baseline"
                                                    }}>
                                                        <div style={{
                                                            flex: 1,
                                                            textAlign: "right",
                                                            marginRight: 6
                                                        }}>All-in sell: </div>
                                                        <div style={{
                                                            flex: 1,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "stretch"
                                                        }}>
                                                            {allInResultSet.currencyResults.map(x =>
                                                                <div style={{ flex: 1 }}>{Math.round(x.total * 10) / 10} {x.currency.code}</div>)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <div style={{
                                                        display: "flex", flexDirection: "row",
                                                        alignItems: "baseline"
                                                    }}>
                                                        <div style={{
                                                            flex: 1,
                                                            textAlign: "right",
                                                            marginRight: 6
                                                        }}>Margin total: </div>
                                                        <div style={{
                                                            flex: 1,
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center"
                                                        }}>
                                                            <div style={{ flex: "0 0 auto", paddingRight: 6 }}>
                                                                {Math.round((_.sum(allInResultSet.currencyResults.map(x => x.margin * x.currency.value))
                                                                    / this.props.currencies[this.props.marginCurrencyId].value) * 10) / 10}
                                                            </div>
                                                            <div style={{ flex: "0 0 75px" }}>
                                                                <Select
                                                                    style={{ color: "white" }}
                                                                    options={_.values(this.props.currencies)
                                                                        .map(x => ({
                                                                            label: x.code,
                                                                            value: x.currencyId
                                                                        }))}
                                                                    value={this.props.marginCurrencyId}
                                                                    onChange={(value) => this.props.quotationUpdateMarginCurrencyId(value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid" style={{ ...otherInfoStyle, paddingTop: 25 }}>
                                            <div style={{ ...fieldStyle }}>
                                                <label style={{ ...labelStyle }}>{getText("QtnAddInfo")}</label>
                                                <div style={{ flex: 1 }}>
                                                    <textarea
                                                        className="form-control"
                                                        value={this.props.quotation.addInfo}
                                                        rows={8}
                                                        cols={ 100}
                                                        onChange={(e) => this.props.quotationUpdateAdditionalInfo(e.target.value)}>
                                                    </textarea>                                     
                                                </div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <label style={{ ...labelStyle }}>{getText("QtnTerms")}</label>
                                                <div style={{ flex: 1 }}>
                                                    <textarea
                                                        className="form-control"
                                                        value={this.props.quotation.terms}
                                                        rows={20}
                                                        cols={100}
                                                        onChange={(e) => this.props.quotationUpdateTerms(e.target.value)}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <label style={{ ...labelStyle }}>{getText("QtnDetailsLevel")}</label>
                                                <div style={{ flex: 1 }}>
                                                    <Select
                                                        options={detailsLevelOptions()}
                                                        value={this.props.quotation.detailsLevel}
                                                        onChange={(value) => this.props.quotationSelectDetailsLevel(value)}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <label style={{ ...labelStyle }}>{getText("QtnTotalDisplay")}</label>
                                                <div style={{ flex: 1 }}>
                                                    <Select
                                                        disabled={this.props.quotation.byContainer}
                                                        options={totalDisplayOptions()}
                                                        value={this.props.quotation.totalType}
                                                        onChange={(value) => this.props.quotationSelectTotalType(value)}
                                                    />
                                                </div>
                                                {this.props.quotation.totalType === "AllIn"
                                                    && <div style={{ flex: 1 }}>
                                                        <Select
                                                            options={this.currencies
                                                                .map(cu => ({ label: cu.code, value: cu.currencyId }))}
                                                            value={this.props.quotation.currencyId}
                                                            onChange={(value) => this.props.quotationSelectAllInCurrency(value)}
                                                        />
                                                    </div>}
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <div style={{ ...labelStyle }}></div>
                                                <div style={{ flex: 1 }}>
                                                    <CheckBox value={this.props.quotation.showExchangeRates}
                                                        label={getText("QtnShowExchangeRates")}
                                                        onChange={(value) => this.props.quotationUpdateShowExchangeRates(value)} />
                                                </div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <label style={{ ...labelStyle }}>{getText("QtnExchangeRates")} <span style={{ fontStyle: "italic" }}>
                                                    {Moment(this.props.exchangeRatesDate ? this.props.exchangeRatesDate : new Date()).format("DD/MM/YYYY")}</span>: </label>
                                                <div style={{ flex: 1 }}>
                                                    {this.props.quotation.quotationCurrencies.map((quotCurrency, index) => <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <div className="form-control-static" style={{ textAlign: "right", flex: 1 }}>
                                                            <span>1 {quotCurrency.displayType === "ToUsd"
                                                                ? this.props.currencies[quotCurrency.currencyId].code
                                                                : "USD"} =</span>
                                                        </div>
                                                        <div style={{ flex: 1, padding: "0px 3px" }}>
                                                            <InputNumber className={"form-control input-sm"}
                                                                unit={quotCurrency.displayType === "ToUsd"
                                                                    ? "USD"
                                                                    : this.props.currencies[quotCurrency.currencyId].code}
                                                                value={quotCurrency.value}
                                                                onChange={(value) => this.props.quotationUpdateCurrencyValue(index, value)} />
                                                        </div>
                                                        <div style={{ flex: "0 0 auto", padding: "0px 6px" }}>
                                                            <Tooltip overlay={<div>Switch direction</div>}>
                                                                <i className="glyphicon glyphicon-transfer form-control-static"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={(e) => {
                                                                        this.props.quotationUpdateCurrencyDisplayType(index,
                                                                            quotCurrency.displayType === "FromUsd" ? "ToUsd" : "FromUsd");
                                                                        e.preventDefault();
                                                                    }}></i>
                                                            </Tooltip>
                                                        </div>
                                                        <div style={{ flex: "0 0 auto", padding: "0px 6px" }}>
                                                            <Tooltip overlay={<div>Reset to current</div>}>
                                                                <i className="glyphicon glyphicon-repeat form-control-static"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={(e) => {
                                                                        this.props.quotationUpdateCurrencyValue(index, this.props.currencies[quotCurrency.currencyId].value);
                                                                        e.preventDefault();
                                                                    }}></i>
                                                            </Tooltip>
                                                        </div>
                                                    </div>)}
                                                </div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <label style={{ ...labelStyle }}>{getText("QtnLanguage")}</label>
                                                <div style={{ flex: 1 }}>
                                                    <Select
                                                        options={dictionnaryToArray(this.props.languages).map(lg =>
                                                            ({ label: lg.name, value: lg.languageId }))}
                                                        value={this.props.quotation.languageId}
                                                        onChange={(value) => this.props.quotationSelectLanguage(value)} />
                                                </div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <label style={{ ...labelStyle }}>{getText("QtnName")}</label>
                                                <div style={{ flex: 1 }}>
                                                    <input name={"quotationName"}
                                                        type={"text"}
                                                        className="form-control"
                                                        value={this.props.quotation.name}
                                                        onChange={(e) => this.props.quotationUpdateName(e.target.value)} />
                                                </div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <label style={{ ...labelStyle }}>Files: </label>
                                                <div style={{ flex: 1 }}>
                                                    <input name={"quotationDocuments"}
                                                        type={"file"}
                                                        multiple
                                                        onChange={(e) => this.props.quotationRequestUploadDocuments(new Date().getTime(), e.target)} />
                                                </div>
                                                <div style={{
                                                    flex: 1,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    {this.props.uploadDocumentsState.isLoading && <img src="/images/loader.svg" height={20} />}
                                                    {this.props.quotation.quotationDocuments
                                                        && this.props.quotation.quotationDocuments.length
                                                        && <div>
                                                            {this.props.quotation.quotationDocuments.length} document{this.props.quotation.quotationDocuments.length > 1 ? "s" : ""}
                                                        </div>}
                                                </div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <div style={{ ...labelStyle }}></div>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "row-reverse", alignItems: "center" }}>
                                                    <SplitButton
                                                        style={{ margin: 5, float: "right" as any, alignItems: "flex-end" }}
                                                        defaultIndex={0}
                                                        disabled={this.props.createState.isLoading}
                                                        options={[{
                                                            label: getText("QtnSendAndValidate"),
                                                            action: () => {
                                                                this.props.requestCreateQuotation(new Date().getTime(), "Send");
                                                            }
                                                        },
                                                        {
                                                            label: getText("QtnDownloadExcelAndValidate"),
                                                            action: () => {
                                                                this.props.requestCreateQuotation(new Date().getTime(), "DownloadExcel");
                                                            }
                                                        },
                                                        {
                                                            label: getText("QtnDownloadPdfAndValidate"),
                                                            action: () => {
                                                                this.props.requestCreateQuotation(new Date().getTime(), "DownloadPdf");
                                                            }
                                                        }]} />
                                                    <Button
                                                        type={"button"}
                                                        style={{ ...actionButtonStyle, backgroundColor: "rgb(0,175,213)", flex: "0 0 auto", order: 1, color: "white" }}
                                                        disabled={this.props.downloadState.isLoading}
                                                        onClick={(e) => {
                                                            this.props.requestDownloadQuotation(new Date().getTime());
                                                            e.preventDefault();
                                                        }}>
                                                        {getText("QtnDownloadPreview")}
                                                    </Button>
                                                    <Button
                                                        type={"button"}
                                                        style={{ ...actionButtonStyle, flex: "0 0 auto", order: 2 }}
                                                        onClick={(e) => {
                                                            this.props.quotationBackToSelection();
                                                            e.preventDefault();
                                                        }}>
                                                        {getText("QtnBackToOffers")}
                                                    </Button>
                                                    <div style={{ flex: 1, order: 3 }}></div>
                                                    <Button
                                                        type={"button"}
                                                        style={{ ...actionButtonStyle, backgroundColor: "rgb(0,175,213)", flex: "0 0 auto", order: 4, color: "white" }}
                                                        onClick={(e) => {
                                                            this.props.quotationOpenTemplateDialog();
                                                            e.preventDefault();
                                                        }}>
                                                        {getText("QtnSaveAsTemplate")}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <QuotationCreateTemplateDialog
                                    isOpen={this.props.templateState.isOpen}
                                    isLoading={this.props.templateState.isLoading}
                                    templates={this.props.quotationTemplates}
                                    onRequestClose={() => this.props.quotationCloseTemplateDialog()}
                                    requestCreateQuotationTemplate={(values) => this.props.requestCreateQuotationTemplate(new Date().getTime(), values) as any} />
                                <QuotationPreviewTemplateDialog
                                    isOpen={this.props.templateState.previewIsOpen}
                                    templates={this.props.quotationTemplates}
                                    onRequestClose={() => this.props.quotationCloseTemplatePreview()} />
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={1} />
            </Grid>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.quotation,
    chargeNames: state.seed.chargeNames,
    currencies: state.seed.currencies,
    languages: state.seed.languages,
    sizeTypes: state.seed.sizeTypes,
    carriers: state.seed.carriers,
} as any), QuotationStore.actionCreators)(QuotationRates) as any;