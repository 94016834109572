import { createTheme } from '@material-ui/core/styles';
import * as Colors from "./colors";

export default createTheme({
    palette: {
        primary: {
            main: Colors.baseColors.lightBlue,
        },
        secondary: {
            main: Colors.baseColors.lightGray,
        },
        text: {
            primary: Colors.baseColors.darkGray
        }
    },
    typography: {
        fontFamily: "Calibri"
    }
});