import React from "react";
import {FieldError, FieldErrors} from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

export function FormField({ children, errors, style, name }: { 
    children: JSX.Element;
    errors?: FieldErrors;
    style?: React.CSSProperties;
    name: string;
}) {
    return (
        <div style={{ ...style, }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                {children}
                <ErrorMessage render={(err) => <div style={{  }}>{err.message}</div>} errors={errors} name={name} />
                {/*{fieldState */}
                {/*&& fieldState.error */}
                {/*&& <div style={{ color: "darkred" }}>{fieldState.error.message}</div>}*/}
            </div>
        </div>
        
    );
}