import * as React from 'react';
import * as Api from '../api/api';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as AskBookingStore from '../store/AskBooking';
import * as AskRatesStore from '../store/AskRates';
import * as ContactBoxStore from '../store/ContactBox';
import * as SelectionStore from '../store/Selection';
import * as CriteriaStore from '../store/Criteria';
import * as TransferStore from '../store/Transfer';
import * as SelectionConnect from '../connects/selectionConnect';
import { RouteComponentProps } from 'react-router';
import MultiPortBox from './MultiPortBox';
import SelectionOffers, { departPerPage } from './SelectionOffers';
import SelectionFilter from './SelectionFilter';
import SelectionCriteria from './SelectionCriteria';
import SelectionOffersTable from './SelectionOffersTable'; 
import AskBooking from './AskBooking';
import AskRatesByEmail from './AskRatesByEmail';
import AskRates from './AskRates';
import AskBookingByEmail from './AskBookingByEmail';
import TransferOffer from './TransferOffer';
import StatsPriceDialog from './StatsPriceDialog';
import DialogModal from './DialogModal';
import ScrollToTop from "./ScrollToTop";
import * as Colors from "../styles/colors";
import { scrollToAppTop, isClient } from '../utils/utils';
import SelectionCriteriaAdvanced from './SelectionCriteriaAdvanced';
import { Paper } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import Loader from './Loader';
import { getText } from '../utils/langTexts';
import MfaPopup from "./MfaPopup";
import {hasDiffSearchCriteria} from "../utils/criteriaUtils";

type SelectionProps = SelectionOwnProps
    & typeof AskBookingStore.actionCreators
    & typeof AskRatesStore.actionCreators
    & typeof SelectionStore.actionCreators
    & typeof TransferStore.actionCreators
    & typeof ContactBoxStore.actionCreators
    & RouteComponentProps<{ code: string }>

interface SelectionOwnProps {
    carrierOffersGrouped: Array<Array<Api.CarrierOfferModel>>;
    selection: SelectionStore.SelectionState;
    criteria: CriteriaStore.CriteriaState;
    filterOptions: SelectionStore.SelectionFilterOptions;
    multiPortIsVisible: boolean;
    askBookingIsOpen: boolean;
    askRatesIsOpen: boolean;
    askRatesByEmailIsOpen: boolean;
    askBookingByEmailIsOpen: boolean;
    transferIsOpen: boolean;
    hasExportParameters: boolean;
    hasTmsSettings: boolean;
}

interface SelectionState {
}

class Selection extends React.Component<SelectionProps, SelectionState> {
    contactTimeout = null;

    initFilter(props: SelectionProps) {
        this.props.resetFilter(props.filterOptions);
    }

    componentWillMount() {
        this.initFilter(this.props);
    }

    updateSelection(props: SelectionProps): Promise<void> {
        return new Promise((resolve, reject) => {
            let code = props.match ? props.match.params.code : '';
            if (code !== props.selection.code) {
                //get okargo offers
                return (this.props.searchSelection(code, new Date().getTime()) as any as Promise<any>);
            }

            resolve();
        });
    }

    componentWillReceiveProps(nextProps: SelectionProps) {
        if (this.props.match.params !== nextProps.match.params)
            this.updateSelection(nextProps);

        if (nextProps.selection.selection.carrierOffers != this.props.selection.selection.carrierOffers
            || nextProps.criteria.criteria != this.props.criteria.criteria) {
            this.initFilter(nextProps);
        }
        if (isClient()
            && nextProps.selection !== this.props.selection
            && nextProps.selection.selection.carrierOffers
            && nextProps.selection.selection.carrierOffers.length !== 0) {
            if (this.contactTimeout) {
                clearTimeout(this.contactTimeout);
            }
            this.contactTimeout = setTimeout(() => {
                this.props.openFeedback(this.props.selection.selection.clientActionId);
                this.contactTimeout = null;
            }, 5000);
        }

        if (nextProps.carrierOffersGrouped != this.props.carrierOffersGrouped) {
            if (Math.ceil(nextProps.carrierOffersGrouped.length / departPerPage) * departPerPage  < nextProps.selection.currentPage * departPerPage) {
                this.props.selectPage(1);
            }
        }
    }

    componentDidMount() {
        scrollToAppTop();
        this.updateSelection(this.props);
    }

    get isLcl(): boolean {
        return this.props.match.path.includes('/lcl/');
    }

    get mustReload(): boolean {
        return this.props.selection.criteriaLoaded 
            && hasDiffSearchCriteria(this.props.criteria.criteria, this.props.selection.criteriaLoaded);
    }

    public render() {
        return <div style={{ padding: "0px 20px 60px 60px" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "row", alignItems: "stretch" }}>
                    <div style={{ flex: 1, minWidth: 280, maxWidth: 360 }}>
                        <Paper elevation={1} style={{
                            border: `1px solid ${Colors.baseColors.lightGray}`,
                            borderRadius: 12,
                            marginBottom: 30
                        }}>
                            <SelectionFilter criteriaType={this.isLcl ? "Lcl" : "Fcl"} />
                        </Paper>
                    </div>
                    <div style={{
                        flex: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        padding: "0px 25px"
                    }}>
                        <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ flex: 1 }}>
                                <SelectionCriteria criteriaType={this.isLcl ? "Lcl" : "Fcl"} />
                            </div>
                            <div style={{ flex: "0 0 auto" }}>
                                <MultiPortBox />
                            </div>
                        </div>
                        <div style={{
                            flex: "0 0 auto",
                            padding: "30px 0px 0px 0px",
                            ...(this.props.selection.advCriteriaOpened
                                ? { padding: "8px 0px 0px 0px" }
                                : {})
                        }}>
                            <SelectionCriteriaAdvanced criteriaType={this.isLcl ? "Lcl" : "Fcl"} />
                        </div>
                        <div style={{
                            flex: "0 0 auto",
                            minHeight: 30,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {this.mustReload && <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", columnGap: 3 }}>
                                    <Warning style={{
                                        color: Colors.baseColors.lightBlue,
                                        fontSize: 20,
                                        position: "relative",
                                        bottom: 3
                                    }} />
                                    <div>{getText("SlcCrtReloadRequired")}</div>
                                </div>}
                            </div>
                        </div>
                        <div style={{
                            flex: "1"
                        }}>
                            <div className="sticky-top" //for safary
                            style={{
                                position: "sticky",
                                top: 0,
                            }}>
                                <SelectionOffersTable criteriaType={this.isLcl ? "Lcl" : "Fcl"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ flex: "1", paddingTop: 60 }}>
                    <SelectionOffers criteriaType={this.isLcl ? "Lcl" : "Fcl"} match={this.props.match} />
                </div>
            </div>
            <DialogModal
                maxWidth={"md"}
                contentLabel="Ask rates"
                onRequestClose={() => this.props.closeEmailAskRates()}
                isOpen={this.props.askRatesByEmailIsOpen}>
                <AskRatesByEmail />
            </DialogModal>
            <DialogModal
                maxWidth={"md"}
                contentLabel="Ask rates"
                onRequestClose={() => this.props.closeAskRates()}
                isOpen={this.props.askRatesIsOpen}>
                <AskRates />
            </DialogModal>
            <DialogModal
                maxWidth={"md"}
                contentLabel="Ask booking"
                onRequestClose={() => this.props.closeAskBooking()}
                isOpen={this.props.askBookingIsOpen}>
                <AskBooking />
            </DialogModal>
            <DialogModal
                maxWidth={"md"}
                contentLabel="Ask booking by email"
                onRequestClose={() => this.props.toggleAskBookingByEmailOpen(false)}
                isOpen={this.props.askBookingByEmailIsOpen}>
                <AskBookingByEmail />
            </DialogModal>
            <DialogModal
                maxWidth={"sm"}
                contentLabel="Transfer data"
                onRequestClose={() => this.props.closeTransferData()}
                isOpen={this.props.transferIsOpen}>
            <TransferOffer />
            </DialogModal>
            <StatsPriceDialog />
            <MfaPopup />
            <Loader size={200} top={400} isVisible={this.props.selection.isLoading} />
            <ScrollToTop />
        </div>
    }
}

export default connect((state: ApplicationState) => {
    let filterOptions = SelectionConnect.getFilterOptions(state);
    let carrierGroupedResult = SelectionConnect.getGroupedCarrierOffers(state);

    return {
        carrierOffersGrouped: carrierGroupedResult,
        selection: state.selection,
        criteria: state.criteria,
        filterOptions: filterOptions,
        multiPortIsVisible: state.multiPort.isVisible,
        askRatesIsOpen: state.askRates.isOpen,
        askRatesByEmailIsOpen: state.askRates.emailIsOpen,
        askBookingIsOpen: state.askBooking.isOpen,
        askBookingByEmailIsOpen: state.askBooking.isAskByEmailOpen,
        transferIsOpen: state.transfer.isOpen,
        hasExportParameters: state.account.currentUser
            && state.account.currentUser.clientModel.subscriptions.some(x => x.hasExportParameters),
        hasTmsSettings: state.account.currentUser
            && state.account.currentUser.clientModel.subscriptions.some(x => x.hasTmsSettings),
    } as any
}, {
        ...SelectionStore.actionCreators,
        ...AskBookingStore.actionCreators,
        ...AskRatesStore.actionCreators,
        ...TransferStore.actionCreators,
        ...ContactBoxStore.actionCreators,
})(Selection) as any
