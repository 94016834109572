import * as React from 'react';
import * as Api from '../api/api';
import { getText } from '../utils/langTexts';
import * as Colors from '../styles/colors';

interface OfferContactProps {
    contact: Api.ContactModel;
}

interface OfferContactState {

}

const contentStyle: React.CSSProperties = {
    marginLeft: 35,
    textAlign: "left",
    minWidth: 270,
    fontWeight: "bold",
    marginTop: 5,
};

const contentStyleEmail2: React.CSSProperties = {
    marginLeft: 15,
    textAlign: "left",
    minWidth: 270,
    fontWeight: "bold",
    marginTop: 5,
};
const contentStylePhone2: React.CSSProperties = {
    marginLeft: 5,
    textAlign: "left",
    minWidth: 270,
    fontWeight: "bold",
    marginTop: 5,
};


export default class OfferContact extends React.Component<OfferContactProps, OfferContactState> {

    public render() {
        return (
            <div style={{
                fontFamily: "Calibri",
            }}>
                <ul style={{
                    listStyle: "none",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                }}>
                    <li style={{
                        display: "inline-grid",
                    }}>
                        <div style={{
                            color: Colors.baseColors.lightBlue,
                            fontWeight: "bold",
                            marginBottom: 10,
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <div style={{
                                minWidth: "11.8%",
                                marginRight: 35,
                                textAlign: "right"
                            }}>
                                {this.props.contact.type === "Booking"
                                    ? getText("CtcBooking")
                                    : getText("CtcSales")}
                            </div>
                            <div style={{
                                textAlign: "left",
                                minWidth: 200,
                            }}>
                                {this.props.contact.firstName} {this.props.contact.lastName}
                            </div>
                        </div>
                    </li>
                    <li >
                        <div style={{
                            listStyle: "none",
                            display: "flex",
                            flexDirection: "row",
                            fontSize: 16,
                        }}>
                            <div style={{
                                color: Colors.baseColors.darkGray,
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "#E8E8E8",
                                borderRadius: "5px 0px 0px 5px",
                                width: "100%",
                                height: 30
                            }}>
                                <div style={{
                                    textAlign: "right",
                                    minWidth: "24%",
                                    marginTop: 5,
                                }}>
                                    {getText("CtcEmail1")}
                                </div>
                                <div style={contentStyle}>
                                    {this.props.contact.email1}
                                </div>
                            </div>
                            <div style={{
                                color: Colors.baseColors.darkGray,
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "#E8E8E8",
                                borderRadius: "0px 5px 5px 0px",
                                width: "100%",
                                height: 30
                            }}>
                                <div style={{
                                    textAlign: "left",
                                    minWidth: "20%",
                                    marginTop: 5,
                                    marginLeft: 23
                                }}>
                                    {getText("CtcEmail2")}
                                </div>
                                <div style={contentStyleEmail2}>
                                    {this.props.contact.email2}
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div style={{
                            color: Colors.baseColors.darkGray,
                            listStyle: "none",
                            display: "flex",
                            flexDirection: "row",
                            height: 30,
                        }}>
                            <div style={{
                                textAlign: "right",
                                minWidth: "12%",
                                marginTop: 5,
                            }}>
                                {getText("CtcPhone1")}
                            </div>
                            <div style={contentStyle}>
                                {this.props.contact.phone1}
                            </div>
                            <div style={{
                                textAlign: "left",
                                minWidth: "11.5%",
                                marginTop: 5,
                            }}>
                                {getText("CtcPhone2")}
                            </div>
                            <div style={contentStylePhone2}>
                                {this.props.contact.phone2}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div style={{
                            color: Colors.baseColors.darkGray,
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#E8E8E8",
                            borderRadius: 5,
                            height: 30,
                        }}>
                            <div style={{
                                textAlign: "right",
                                minWidth: "12%",
                                marginTop: 5,
                            }}>
                                {getText("CtcComments")}
                            </div>
                            <div style={contentStyle}>
                                {this.props.contact.comment}
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
        );
    }
}