import * as React from 'react';
import { Popper, Paper } from "@material-ui/core";
import * as Colors from '../styles/colors';
import { Close } from '@material-ui/icons';

interface OkPopperProps {
    popperContent: JSX.Element | string;
    childContainerStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    popperStyle?: React.CSSProperties;
    paperStyle?: React.CSSProperties;
    open?: boolean;
    onChange?: (value: boolean) => void;
    windowContainer?: boolean;
    disablePortal?: boolean;
}
interface OkPopperState {
    isOpen: boolean;
}

export default class OkPopper extends React.Component<OkPopperProps, OkPopperState> {
    btnRef = null;
    divRef = null;

    constructor(props: OkPopperProps) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    get isOpen(): boolean {
        return this.props.open !== undefined ? this.props.open : this.state.isOpen;
    }

    public render() {
        return (
            <div ref={(ref => this.divRef = ref)} style={{ ...this.props.style }}>
                <div
                    style={{ cursor: "pointer", ...this.props.childContainerStyle }}
                    ref={ref => this.btnRef = ref}
                    onClick={(e) => {
                        this.setState({ isOpen: !this.state.isOpen })
                        if (this.props.onChange) {
                            this.props.onChange(!this.props.open);
                        }
                    }}>
                    {this.props.children}
                </div>
                <Popper
                    style={{ zIndex: 100, ...this.props.popperStyle }}
                    disablePortal={this.props.disablePortal}
                    open={this.isOpen}
                    anchorEl={this.btnRef}
                    container={this.props.windowContainer ? undefined: this.divRef}>
                    <Paper style={{ border: `1px solid ${Colors.baseColors.darkGray}`, padding: "6px 12px", borderRadius: 12, ...this.props.paperStyle }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row-reverse"
                        }}>
                            <Close style={{ cursor: "pointer", color: Colors.baseColors.lightBlue, fontSize: 14 }}
                                onClick={() => {
                                    if (this.props.onChange) {
                                        this.props.onChange(false);
                                    } else {
                                        this.setState({ isOpen: false });
                                    }}} />
                        </div>
                        <div>{this.props.popperContent}</div>
                    </Paper>
                </Popper>
            </div>
            );
    }
}