import * as React from 'react';
import Moment from 'moment';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as RatesOffer from '../store/RatesOffer';
import { ApplicationState } from '../store';
import ModeratorRatesOfferEditForm from "./ModeratorRatesOfferEditForm";
import Loader from './Loader';
import Table, { Column } from './Table';
import Select, { Option } from "./Select";
import * as Colors from "../styles/colors";
import { getText } from '../utils/langTexts';
import Button from './Button';
import { filterForm, searchFieldStyle, searchContainerStyle, formContainerStyle, inputStyleModerator, buttonStyle, selectContainerStyle } from '../styles/moderatorStyles';

type ModeratorRatesOfferProps = ModeratorRatesOfferOwnProps
    & RatesOffer.RatesOfferState
    & typeof RatesOffer.actionCreators;

interface ModeratorRatesOfferOwnProps {
    carriers: { [id: number]: Api.CarrierModel }
}

const getColumns = (props: ModeratorRatesOfferProps): Array<Column<Api.RatesOfferModel>> => {
    return [
        {
            header: getText("MdtRatesInfoCarrier"),
            id: 'carrier',
            accessor: (d: Api.RatesOfferModel) => (d.carriers.map(c => props.carriers[c.carrierId].name).join(',')),
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesInfoReference"),
            id: 'reference',
            accessor: (d: Api.RatesOfferModel) => d.references ? (d.references.map(r=>r.value).join(','))   : "",
            align: "center",
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesInfoShortname"),
            id: 'shortName',
            align: "center",
            accessor: (d: Api.RatesOfferModel) => d.shortName,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: "Owner",
            id: 'owner',
            align: "center",
            accessor: (d: Api.RatesOfferModel) => d.owner,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: "Last update",
            id: 'lastUpdate',
            align: "center",
            accessor: (d: Api.RatesOfferModel) => d.lastUpdate,
            cell: (d: any) => <div>
                {Moment(d).format("DD/MM/YYYY")}
            </div>
        }
        ,
        {
            header: "",
            id: 'action',
            accessor: (d: Api.RatesOfferModel) => d,
            cell: (d: Api.RatesOfferModel) => <div style={d.ratesOfferId === props.editState.ratesOfferId
                ? {
                    color: Colors.baseColors.lightBlue
                } : {}}>
                {getText("GenEdit")}
            </div>
        }
    ];
}






const trStyle = { cursor: "pointer" as any }

class ModeratorRatesOffer extends React.Component<ModeratorRatesOfferProps, {}> {

    get carriersOptions(): Array<Option> {
        return _.sortBy(_.values(this.props.carriers), x => x.name)
            .map(ca => ({ label: ca.name, value: ca.carrierId }));
    }

    public render() {
        return (
            <div>
                <h2>{getText("MdtRatesInfo")}</h2>
                <div style={searchContainerStyle}>
                    <form className="form-inline" style={ filterForm}
                        onSubmit={(e) => {
                            this.props.requestRatesOffers(new Date().getTime());
                            e.preventDefault();
                        }}>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label">{getText("MdtRatesInfoCarrierFilter")}</label>
                            <div style={selectContainerStyle}>
                                <Select
                                    style={{ minWidth: 200, padding: "4px 4px 4px 8px" }}
                                    options={this.carriersOptions}
                                    value={this.props.searchRatesOffersModel.carrierId}
                                    onChange={(value) => this.props.selectCarrierId(value)}
                                    search={true}
                                />
                            </div>
                        </div>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label" style={{ paddingRight: "8px" }}>{getText("MdtRatesInfoNameFilter")}</label>
                            <div style={selectContainerStyle}>
                                <input type="text" className="form-control" style={inputStyleModerator}
                                    value={this.props.searchRatesOffersModel.searchTerm}
                                    onChange={(e) => this.props.updateSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <Button type="submit"
                            style={buttonStyle}>
                            {getText("MdtRatesInfoSearch")}
                        </Button>
                        <Loader isVisible={this.props.isLoading} size={100} />
                    </form>
                </div>
                <div onClick={(e) => { console.log(this.props.isLoading) }}>
                    <Table
                        tableKey={"modRatesOffer"}
                        data={this.props.ratesOffers}
                        columns={getColumns(this.props)}
                        showPagination={true}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        noDataText={getText("MdtNoRatesInformation")}
                        onThClick={() => {}}
                        autoSort={true}
                        onBodyTrClick={(ratesOffer: Api.RatesOfferModel) => {
                            if (ratesOffer) {
                                this.props.selectRatesOffer(ratesOffer.ratesOfferId);
                            }
                        }}
                    />
                </div>
                <div>
                    <div style={formContainerStyle}>
                        <ModeratorRatesOfferEditForm
                            disabled={this.props.editState.ratesOfferId ? false : true}
                            onSubmit={(values) => {
                                return this.props.requestEditRatesOffer(new Date().getTime(), values) as any;
                            }}
                        />
                        </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.ratesOffer,
        carriers: state.seed.carriers
    } as ModeratorRatesOfferProps),
    RatesOffer.actionCreators)(ModeratorRatesOffer) as any