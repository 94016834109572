import * as React from 'react';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import { ApplicationState } from '../store';

type NotificatorProps = NotificatorOwnProps;

interface NotificatorOwnProps {
    notifications: Array<any>
}

interface NotificatorState {

}

const style = {
    NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
            zIndex: 9999
        },
        success: { // Applied only to the success notification item
        }
    }
};

class Notificator extends React.Component<NotificatorProps, NotificatorState> {

    public render() {
        return (
            <Notifications
                notifications={this.props.notifications}
                style={style}
            ></Notifications>
            );
    }
}

export default connect((state: ApplicationState) => {
    return {
        notifications: state.notifications
    }
}, null)(Notificator) as any