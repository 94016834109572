import { Action } from 'redux';
import { Reducer } from 'redux';

export type TablesState = { [tableKey: string]: TableState }
export interface TableFiltered { id: string; value: string }; 
export interface TableSorted { id: string; desc: boolean }

export interface TableState {
    filtered: Array<TableFiltered>;
    sorted?: TableSorted;
    page?: number;
    rowsPerPage?: number;
    rowsOpened: { [key: string]: boolean };
}

interface TableUpdateFiltered {
    type: "TABLE_UPDATE_FILTERED";
    payload: { tableKey: string; filtered: Array<TableFiltered>; }
}

interface TableUpdateSorted {
    type: "TABLE_UPDATE_SORTED";
    payload: { tableKey: string; sorted: TableSorted; }
}

interface TableUpdatePage {
    type: "TABLE_UPDATE_PAGE";
    payload: { tableKey: string;value: number }
}

interface TableUpdateRowsPerPage {
    type: "TABLE_UPDATE_ROWPERPAGE";
    payload: { tableKey: string;value: number }
}

interface TableUpdateRowOpened {
    type: "TABLE_UPDATE_ROWOPENED";
    payload: { tableKey: string; rowKey: string; value: boolean }
}

type KnownAction = TableUpdateFiltered
    | TableUpdateSorted
    | TableUpdatePage
    | TableUpdateRowsPerPage
    | TableUpdateRowOpened
    ;

export const actionCrators = {
    updateFiltered: (tableKey: string, filtered: Array<TableFiltered>) => <TableUpdateFiltered>{
        type: "TABLE_UPDATE_FILTERED",
        payload: { tableKey: tableKey, filtered: filtered }
    },
    updateSorted: (tableKey: string, sorted: TableSorted) => <TableUpdateSorted>{
        type: "TABLE_UPDATE_SORTED",
        payload: { tableKey: tableKey, sorted: sorted }
    },
    updatePage: (tableKey: string, value: number) => <TableUpdatePage>{
        type: "TABLE_UPDATE_PAGE",
        payload: { tableKey: tableKey, value: value }
    },
    updateRowsPerPage: (tableKey: string, value: number) => <TableUpdateRowsPerPage>{
        type: "TABLE_UPDATE_ROWPERPAGE",
        payload: { tableKey: tableKey, value: value }
    },
    updateRowOpened: (tableKey: string, rowKey: string, value: boolean) => <TableUpdateRowOpened>{
        type: "TABLE_UPDATE_ROWOPENED",
        payload: {
            tableKey: tableKey,
            value: value,
            rowKey: rowKey
        }
    }
}
export const unloadedTableState: TableState = {
    filtered: [],
    rowsOpened: {}
}
const unloadedState: TablesState = {}

export const reducer: Reducer<TablesState> = (state: TablesState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "TABLE_UPDATE_FILTERED":
            return {
                ...state,
                [action.payload.tableKey]: {
                    ...(state[action.payload.tableKey] || unloadedTableState),
                    filtered: action.payload.filtered
                }
            };
        case "TABLE_UPDATE_SORTED":
            return {
                ...state,
                [action.payload.tableKey]: {
                    ...(state[action.payload.tableKey] || unloadedTableState),
                    sorted: action.payload.sorted
                }
            };
        case "TABLE_UPDATE_PAGE":
            return {
                ...state,
                [action.payload.tableKey]: {
                    ...(state[action.payload.tableKey] || unloadedTableState),
                    page: action.payload.value
                }
            };
        case "TABLE_UPDATE_ROWPERPAGE":
            return {
                ...state,
                [action.payload.tableKey]: {
                    ...(state[action.payload.tableKey] || unloadedTableState),
                    rowsPerPage: action.payload.value
                }
            };
        case "TABLE_UPDATE_ROWOPENED":
            return {
                ...state,
                [action.payload.tableKey]: {
                    ...(state[action.payload.tableKey] || unloadedTableState),
                    rowsOpened: {
                        ...(state[action.payload.tableKey] || unloadedTableState).rowsOpened,
                        [action.payload.rowKey]: action.payload.value
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};