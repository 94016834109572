import * as React from 'react';
import * as Api from '../api/api';
import DialogModal from './DialogModal';
import { PreviewTemplateForm } from './QuotationTemplateForm';

interface QuotationSaveTemplateDialogProps {
    isOpen: boolean;
    templates: Array<Api.QuotationModel>;
    onRequestClose: () => void;
}

export default class QuotationSaveTemplateDialog extends React.Component<QuotationSaveTemplateDialogProps, {}> {
    public render() {
        return (
            <DialogModal
                isOpen={this.props.isOpen}
                contentLabel={"Template preview"}
                maxWidth={"lg"}
                onRequestClose={() => this.props.onRequestClose()}>
                <div>
                    <PreviewTemplateForm
                        readonly
                        templates={this.props.templates}
                        onSubmit={(values) => Promise.resolve()} />
                </div>
            </DialogModal>
        );
    } 
}