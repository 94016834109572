import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import * as InlandCriteriaState from '../store/InlandCriteria';
import InlandCriteriaForm from "./InlandCriteriaForm";
import { getSubscription } from '../utils/utils';

// At runtime, Redux will merge together...
type InlandCriteriaProps =
    InlandCriteriaState.InlandCriteriaState        // ... state we've requested from the Redux store
    & InlandCriteriaOwnProps
    & typeof InlandCriteriaState.actionCreators      // ... plus action creators we've requested
    & RouteComponentProps<{ code: string }>; // ... plus incoming routing parameters   

interface InlandCriteriaOwnProps {
    client: Api.ClientModel;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    subscription: Api.SubscriptionModel;
}

class InlandCriteria extends React.Component<InlandCriteriaProps, {}> {
    private criteriaAgentForm;

    constructor(props) {
        super(props);
    }

    onPropsUpdate(props: InlandCriteriaProps) {
        let code = this.props.match
            ? this.props.match.params.code
            : '';
        if (code)
            this.props.requestInlandCriteria(new Date().getTime(), code);
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: InlandCriteriaProps) {
        if (this.props.match.params.code !== nextProps.match.params.code)
            this.onPropsUpdate(nextProps);
    }

    public render() {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 100
            }}>
                <div style={{
                    flex: "0 0 auto",
                    textAlign: "center",
                    padding: "20px 20px",
                    fontWeight: "bold",
                    fontSize: 40,
                }}>
                    INLAND
                    </div>
                <div style={{ flex: 1, width: 900, marginTop: 0 }}>
                    <div style={{ padding: "10px 40px 20px 40px" }}>
                        <InlandCriteriaForm
                            onSubmit={(values) => {
                                return (this.props.requestCreateInlandCriteria(new Date().getTime(), values) as any as Promise<Api.InlandCriteriaModel>)
                                    .then(inlandCriteria => {
                                        this.props.goToSelection(inlandCriteria.code);
                                    });
                            }}
                            resetInlandCriteria={() => this.props.resetInlandCriteria()}
                        />
                    </div>
                </div>
            </div>);
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            ...state.criteria,
            client: state.account.currentUser.clientModel,
            sizeTypes: state.seed.sizeTypes,
            subscription: getSubscription(state.account.currentUser.clientModel)
                || { subscriptionType: null }
        } as any
    },
    InlandCriteriaState.actionCreators)
    (InlandCriteria) as any