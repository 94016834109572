import * as _ from 'lodash';
import * as Api from '../api/api';
import * as React from 'react';
import * as Colors from '../styles/colors';
import * as CarrierCarrierRatingStore from '../store/CarrierCarrierRating';
import * as CarrierRatingStore from '../store/CarrierRating';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import { CreateCarrierCarrierRatingForm, UpdateCarrierCarrierRatingForm } from "./CarrierCarrierRatingForm";
import Table, { Column } from './Table';
import DialogModal from './DialogModal';
import CarrierRatings from './CarrierRatings';
import { getFirmName } from '../utils/utils';
import * as TabsStore from "../store/Tabs";
import CenteredContentTabs from './CenteredContentTabs';

type CarrierCarrierRatingsProps = CarrierCarrierRatingStore.CarrierCarrierRatingState
    & CarrierCarrierRatingsOwnProps
    & typeof CarrierCarrierRatingStore.actionCreators
    & typeof CarrierRatingStore.actionCreators
    & typeof TabsStore.actionCreators;

interface CarrierCarrierRatingsOwnProps {
    isCarrierRatingOpen: boolean;
    firms: { [id: number]: Api.FirmModel };
    carriers: { [id: number]: Api.CarrierModel };
    portsZones: { [id: number]: Api.PortsZoneModel };
    carrierRatings: { [id: number]: Api.CarrierRatingModel };
}

type ContactColumn = Column<Api.CarrierCarrierRatingModel>;

class CarrierCarrierRatings extends React.Component<CarrierCarrierRatingsProps, {}> {

    componentWillMount() {
        (this.props.requestCarrierRatings(new Date().getTime()) as any as Promise<any>)
            .then(() => this.props.requestCarrierCarrierRatings(new Date().getTime()));
    }

    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                <div style={{ flex: 1 }}>
                    <Table
                        tableKey={"carrierCarrierRatings"}
                        columns={[
                            {
                                id: "firm",
                                header: <div>Company/branch</div>,
                                accessor: x => getFirmName(this.props.firms[x.firmId]),
                            },
                            {
                                id: "carrier",
                                header: <div>Carrier</div>,
                                accessor: x => this.props.carriers[x.carrierId].name,
                            },
                            {
                                id: "carrierRatingId",
                                header: <div>Rating</div>,
                                accessor: x => this.props.carrierRatings[x.carrierRatingId]
                                    ? this.props.carrierRatings[x.carrierRatingId].label
                                    : "",
                            },
                            {
                                id: "fromPortZoneId",
                                header: <div>From Zone</div>,
                                accessor: x => x.fromPortsZoneId
                                    ? this.props.portsZones[x.fromPortsZoneId].name
                                    : "",
                            },
                            {
                                id: "toPortZoneId",
                                header: <div>To Zone</div>,
                                accessor: x => x.toPortsZoneId
                                    ? this.props.portsZones[x.toPortsZoneId].name
                                    : "",
                            },
                            {
                                id: "ratesType",
                                header: <div>Rates type</div>,
                                accessor: x => x.ratesType,
                            },
                            {
                                id: "ratesOffer",
                                header: <div>Contract</div>,
                                accessor: x => x.ratesOffer
                                    ? (x.ratesOffer.shortName
                                        || x.ratesOffer.references.map(r => r.value).join(','))
                                    : "",
                            },
                            {
                                id: "edit",
                                header: <div></div>,
                                accessor: (d) => d.carrierCarrierRatingId,
                                cell: d => <div>
                                    <a style={{
                                        cursor: "pointer",
                                        margin: "0px 5px",
                                        ...(this.props.selectedCarrierCarrierRatingId === d ? { color: Colors.baseColors.lightBlue } : {})
                                    }} onClick={(e) => {
                                        this.props.updateSelectedCarrierId(d);
                                        this.props.selectTab("moderatorCarrierRates", "edit");
                                    }}>{getText("GenEdit")}</a>
                                    <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                        this.props.requestDeleteCarrierRating(new Date().getTime(), d);
                                    }}>{getText("GenDelete")}</a>
                                </div>
                            },
                        ] as Array<ContactColumn>}
                        showPagination={true}
                        showPageSizeOptions={false}
                        rowsPerPage={10}
                        data={this.props.carrierCarrierRatings} />
                </div>

                <div >
                    <CenteredContentTabs
                        tabKey={"moderatorCarrierRates"}
                        data={[
                            {
                                title: getText("GenCreate"),
                                tabKey: "create",
                                tabContent: <div style={{ padding: 10 }}>
                                    <h3>{getText("GenCreate")}</h3>
                                    <div style={{ maxWidth: 1000 }}>
                                        <CreateCarrierCarrierRatingForm
                                            mode={"Create"}
                                            openCarrierRatings={() => this.props.openCarrierRatings()}
                                            onSubmit={(values) => {
                                                return this.props.requestCreateCarrierCarrierRating(new Date().getTime(), values) as any;
                                            }} />
                                    </div>
                                </div>
                            },
                            {
                                title: getText("GenEdit"),
                                tabKey: "edit",
                                tabContent: <div style={{ padding: 10 }}>
                                    <div style={{ maxWidth: 1000 }}>
                                        <h3>Edit selected Carrier Rating</h3>
                                        {this.props.selectedCarrierCarrierRatingId
                                            && <div>
                                                <UpdateCarrierCarrierRatingForm
                                                    mode={"Update"}
                                                    openCarrierRatings={() => this.props.openCarrierRatings()}
                                                    onSubmit={(values) => {
                                                        return this.props.requestUpdateCarrierCarrierRating(new Date().getTime(), values) as any;
                                                    }} />
                                            </div>}
                                    </div>
                                </div>
                            }
                        ]} />
                </div>
                <DialogModal isOpen={this.props.isCarrierRatingOpen}
                    contentLabel={"Carrier rating"}
                    maxWidth={"md"}
                    onRequestClose={() => this.props.closeCarrierRatings()}>
                    <CarrierRatings />
                </DialogModal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.carrierCarrierRating,
    isCarrierRatingOpen: state.carrierRating.isOpen,
    firms: _.keyBy(state.account.currentUser.clientModel.subscriptions
        .filter(x => x.subscriptionType === "Okargo")
        .map(x => x.agency)
        .concat(state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency.company))
        .concat(state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency.company.companyGroup))
        .filter(x => x), x => x.firmId),
    carriers: state.seed.carriers,
    portsZones: state.seed.portsZones,
    carrierRatings: _.keyBy(state.carrierRating.carrierRatings, x => x.carrierRatingId)
} as CarrierCarrierRatingsProps), {
    ...CarrierCarrierRatingStore.actionCreators,
    ...CarrierRatingStore.actionCreators,
    ...TabsStore.actionCreators
})(CarrierCarrierRatings) as any