import { Action, Reducer } from 'redux';

export interface OkargoHubState {
    isConnected: boolean;
}

interface OkargoHubConnectionOpened {
    type: "USERHUB_CONNECTION_OPENED",
}

interface OkargoHubConnectionClosed {
    type: "USERHUB_CONNECTION_CLOSED",
}

export type KnownAction = OkargoHubConnectionOpened
    | OkargoHubConnectionClosed;

//No need I think
export const actionCreators = {

};


const unloadedState: OkargoHubState = {
    isConnected: false,
};

export const reducer: Reducer<OkargoHubState> = (state: OkargoHubState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "USERHUB_CONNECTION_OPENED":
            return {
                ...state,
                isConnected: true
            };
        case "USERHUB_CONNECTION_CLOSED":
            return {
                ...state,
                isConnected: false
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};
