import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from "redux";
import * as Api from "../api/api";
import Moment from "moment";
import * as MultiPortStore from './MultiPort';
import { AppThunkAction } from "./";
import { ApplicationState } from "./index"
import { RequestState } from "../models/models"
import { languageCookie } from "../utils/constants";
import * as Notifications from 'react-notification-system-redux';
import { SubmissionError, change } from 'redux-form';
import * as Cookies from "js-cookie";
import { getText } from "../utils/langTexts";
import { connectUserHub } from "../signalR/connectedUsers";
import {requestTutorial} from "./Tutorial";
import {getDefaultHeaders} from "../utils/utils";

export interface AccountState {
    isLoading: boolean;
    isLoaded: boolean;
    requestTime: number;
    isMenuOpen: boolean;
    login: LoginState;
    languageId: number;
    currentUser: Api.CurrentUserModel;
    editClient: RequestState;
    editQuotationClientSettings: RequestState;
    editPassword: RequestState;
    acceptGcuState: RequestState;
    forgotPasswordState: ForgotPasswordState;
}

export interface LoginState {
    isLoading: boolean;
    requestTime: number;
}

export interface ForgotPasswordState {
    isLoading: boolean;
    requestTime: number;
    tokenSent: boolean;
    email: string;
}

interface RequestCurrentUser { type: "REQUEST_CURRENT_USER", payload: { requestTime: number } }
export interface ReceiveCurrentUser {
    type: "RECEIVE_CURRENT_USER",
    payload: { requestTime: number, currentUser?: Api.CurrentUserModel }
    error?: any;
}

interface RequestEditClient { type: "REQUEST_EDIT_CLIENT", model: Api.ClientModel, requestTime: number }
export interface ReceiveEditClient { type: "RECEIVE_EDIT_CLIENT", model: Api.ClientModel, requestTime: number }

interface RequestEditQuotationClientSettings {
    type: "REQUEST_EDIT_QUOTATION_CLIENT_SETTINGS",
    payload: { requestTime: number }
}
export interface ReceiveEditQuotationClientSettings {
    type: "RECEIVE_EDIT_QUOTATION_CLIENT_SETTINGS",
    payload: { model: Api.QuotationClientSettingsModel, requestTime: number }
    error?: any;
}

interface RequestEditPassword { type: "REQUEST_EDIT_PASSWORD", model: Api.EditPasswordModel, requestTime: number }
interface ReceiveEditPassword { type: "RECEIVE_EDIT_PASSWORD", requestTime: number }

interface RequestLogin { type: "REQUEST_LOGIN", login: Api.LoginModel, requestTime: number }
interface ReceiveLogin { type: "RECEIVE_LOGIN", token: Api.TokenModel, requestTime: number  }

interface RequestAcceptGcu { type: "REQUEST_ACCEPT_GCU"; requestTime: number }
interface ReceiveAcceptGcu { type: "RECEIVE_ACCEPT_GCU"; requestTime: number; success: boolean }

interface SelectUserCurrency { type: "SELECT_USER_CURRENCY", currencyId: number }
interface SelectUserInlandCurrency { type: "SELECT_USER_INLANDCURRENCY", payload: { currencyId: number } }
export interface SelectApplicationLanguage { type: "SELECT_APPLICATION_LANGUAGE", languageId: number }
export interface Logout { type: "LOGOUT" }

interface RequestUpdateDefaultQuotationContact {
    type: "REQUEST_UPDATE_DEFAULT_QUOTATIONCONTACT";
    payload: { requestTime: number; quotationContactId: number }
}
interface ReceiveUpdateDefaultQuotationContact {
    type: "RECEIVE_UPDATE_DEFAULT_QUOTATIONCONTACT";
    payload: { requestTime: number; quotationContactId: number };
    error?: any;
}

interface OpenNavigationMenu {
    type: "OPEN_NAVIGATION_MENU";
}


interface CloseNavigationMenu {
    type: "CLOSE_NAVIGATION_MENU";
}

export type KnownAction = RequestCurrentUser
    | ReceiveCurrentUser
    | RequestLogin
    | ReceiveLogin
    | SelectUserCurrency
    | RequestEditClient | ReceiveEditClient
    | RequestEditPassword | ReceiveEditPassword
    | Logout | SelectApplicationLanguage
    | RequestAcceptGcu | ReceiveAcceptGcu
    | RequestUpdateDefaultQuotationContact
    | ReceiveUpdateDefaultQuotationContact
    | RequestEditQuotationClientSettings
    | ReceiveEditQuotationClientSettings
    | OpenNavigationMenu
    | CloseNavigationMenu
    | SelectUserInlandCurrency
    ;

export const requestCurrentUser = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState)
    : Promise<any> => {
    // Only load data if it's something we don't already have (and are not already loading)
    if (getState().account.requestTime === requestTime)
        return Promise.resolve();

    let api = new Api.AccountApi();
    let fetchTask = api.getCurrentUser({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(data => {
            dispatch({
                type: "RECEIVE_CURRENT_USER",
                payload: { requestTime: requestTime, currentUser: data }
            });
            return connectUserHub(dispatch as any, getState);
        }).catch(error => {
            if (error.text) {
                error.text().then(x => {
                    console.error("Error fetching current client: " + x);
                });
            } else {
                console.error("Error fetching current client: " + (error.message || error));
            }
            dispatch({
                type: "RECEIVE_CURRENT_USER",
                payload: { requestTime: requestTime },
                error: error
            });
            //dispatch(Notifications.error({ message: "Error fetching current client", title: "Error", position: "tc" }));
        });

    dispatch({ type: "REQUEST_CURRENT_USER", payload: { requestTime: requestTime } });
    return fetchTask;
};

export const actionCreators = {
    requestCurrentUser: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestCurrentUser(requestTime, dispatch, getState);
    },
    requestLogin: (login: Api.LoginModel, returnPath: string, requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().account.login.requestTime === requestTime)
            return Promise.resolve();

        let api = new Api.AccountApi();
        let fetchTask = api.createToken({
            model: login
        }, { credentials: 'include' }).then(tokenResult => {
            if (tokenResult.token == null) {
                dispatch({ type: "RECEIVE_LOGIN", token: null, requestTime: requestTime });
                dispatch(change("login", "username", null));
                dispatch(change("login", "password", null));
                throw new SubmissionError({ _error: tokenResult.message } as any);
            } else {
                dispatch({ type: "RECEIVE_LOGIN", token: tokenResult.token, requestTime: requestTime });
                requestTutorial(new Date().getTime(), dispatch, getState);
                return requestCurrentUser(new Date().getTime(), dispatch, getState)
                    .then(() => {
                        return MultiPortStore.getCurrentMultiPort(new Date().getTime(), dispatch as any, getState)
                            .catch(error => {
                                throw error;
                            });
                    })
                    .catch(error => {
                        throw error;
                    });
            }
        })
        .catch(error => {
            throw error;
        });
        dispatch({ type: "REQUEST_LOGIN", login: login, requestTime: requestTime });
        addTask(fetchTask);
        return fetchTask;
    },
    requestEditClient: (clientModel: Api.ClientModel, requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().account.editClient.requestTime)
            return new Promise<void>((resolve, reject) => resolve());

        let accountApi = new Api.AccountApi();
        let fetchTask = accountApi.editClient({ model: clientModel },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(data => {
                dispatch({ type: "RECEIVE_EDIT_CLIENT", model: data, requestTime: requestTime });
                dispatch(Notifications.success({
                    message: getText("StgAccountChangesSaved"),
                    title: "Success", position: "tc"
                }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_EDIT_CLIENT", model: clientModel, requestTime: requestTime });

                throw new SubmissionError({ _error: "Failed to update your account" } as any)
            });
        addTask(fetchTask);
        dispatch({ type: "REQUEST_EDIT_CLIENT", model: clientModel, requestTime: requestTime });

        return fetchTask;
    },
    requestEditQuotationClientSettings: (requestTime: number, model: Api.QuotationClientSettingsModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let accountApi = new Api.AccountApi();
        let fetchTask = accountApi.editQuotationClientSettings({ model: model },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(quotationClientSettings => {
                dispatch({
                    type: "RECEIVE_EDIT_QUOTATION_CLIENT_SETTINGS",
                    payload: { model: quotationClientSettings, requestTime: requestTime }
                });
                dispatch(Notifications.success({
                    message: getText("StgQuotationSettingsSuccess"),
                    title: "Success", position: "tc"
                }) as any);
            }).catch(error => {
                dispatch({
                    type: "RECEIVE_EDIT_QUOTATION_CLIENT_SETTINGS",
                    payload: { model: undefined, requestTime: requestTime },
                    error: error
                });
                throw new SubmissionError({ _error: "Failed to update your account" } as any)
            });
        addTask(fetchTask);
        dispatch({
            type: "REQUEST_EDIT_QUOTATION_CLIENT_SETTINGS",
            payload: { requestTime: requestTime }
        });

        return fetchTask;
    },
    requestEditPassword: (editPasswordModel: Api.EditPasswordModel, requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().account.editPassword.requestTime)
            return Promise.reject('already did');

        let accountApi = new Api.AccountApi();
        let fetchTask = accountApi.editPassword({ model: editPasswordModel },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({ type: "RECEIVE_EDIT_PASSWORD", requestTime: requestTime });
                dispatch(Notifications.success({
                    message: getText("StgPasswordChangeSuccess"),
                    title: "Success", position: "tc"
                }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_EDIT_PASSWORD", requestTime: requestTime });
                dispatch(Notifications.error({
                    message: "Failed to change password",
                    title: "Error", position: "tc"
                }) as any);
                throw new SubmissionError({ _error: "Failed to change password" } as any)
            });
        addTask(fetchTask);
        dispatch({ type: "REQUEST_EDIT_PASSWORD", model: editPasswordModel, requestTime: requestTime });

        return fetchTask;
    },
    requestAcceptGcu: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().account.acceptGcuState.requestTime)
            return;

        let accountApi = new Api.AccountApi();
        let fetchTask = accountApi.acceptGcu(
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({ type: "RECEIVE_ACCEPT_GCU", requestTime: requestTime, success: true });
            }).catch(error => {
                dispatch({ type: "RECEIVE_ACCEPT_GCU", requestTime: requestTime, success: false });
            });
        addTask(fetchTask);
        dispatch({ type: "REQUEST_ACCEPT_GCU", requestTime: requestTime });
    },
    selectCurrency: (currencyId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: "SELECT_USER_CURRENCY", currencyId: currencyId });
        let api = new Api.AccountApi();
        api.editCurrency({ currencyId: currencyId },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) });
    },
    selectInlandCurrency: (currencyId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: "SELECT_USER_INLANDCURRENCY", payload: { currencyId: currencyId }});
        let api = new Api.AccountApi();
        api.editInlandCurrency({ currencyId: currencyId },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) });
    },
    selectApplicationLanguage: (languageId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: "SELECT_APPLICATION_LANGUAGE", languageId: languageId });
        Cookies.set(
            languageCookie, "c={langCode}|uic={uiLangCode}".replace("{langCode}",
                getState().seed.languages[languageId].code.toLowerCase())
                .replace("{uiLangCode}",
                getState().seed.languages[languageId].code.toLowerCase()), {
                expires: Moment().add("year", 1).toDate()
            });
        if (typeof window !== "undefined") {
            window.location.reload();
        }
    },
    openNavigationMenu: (): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: "OPEN_NAVIGATION_MENU" });
    },
    closeNavigationMenu: (): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: "CLOSE_NAVIGATION_MENU" });
    },
    logout: (): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let accountApi = new Api.AccountApi();
        let fetchTask = accountApi.logout({ credentials: "same-origin" }).then(() => {
            dispatch({ type: "LOGOUT" });
        }).catch((error) => {
            dispatch({ type: "LOGOUT" });
            dispatch(Notifications.error({ message: "Error login out from the server", title: "Error", position: "tc" }));
        });
    },
    requestUpdateDefaultQuotationContactId: (requestTime: number, value: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_UPDATE_DEFAULT_QUOTATIONCONTACT", payload: { requestTime: requestTime, quotationContactId: value } });
        dispatch({ type: "RECEIVE_UPDATE_DEFAULT_QUOTATIONCONTACT", payload: { requestTime: requestTime, quotationContactId: value } });
        let accountApi = new Api.AccountApi();
        accountApi.editDefaultQuotationContact({ defaultQuotationContactId: value },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) });
    },
};


const unloadedState: AccountState = {
    isLoading: false,
    isLoaded: false,
    isMenuOpen: false,
    requestTime: 0,
    languageId: 0,
    currentUser: null,
    login: { isLoading: false, requestTime: 0 },
    editClient: { isLoading: false, requestTime: 0 },
    editPassword: { isLoading: false, requestTime: 0 },
    acceptGcuState: { isLoading: false, requestTime: 0 },
    editQuotationClientSettings: { isLoading: false, requestTime: 0 },
    forgotPasswordState: { isLoading: false, requestTime: 0, email: "", tokenSent: false }
};

export const reducer: Reducer<AccountState> = (state: AccountState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CURRENT_USER":
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_CURRENT_USER":
            if (action.payload.requestTime === state.requestTime) {
                return {
                    ...state,
                    isLoading: false,
                    isLoaded: action.error
                        ? state.isLoaded
                        : true,
                    currentUser: action.error
                        ? state.currentUser
                        : action.payload.currentUser
                };
            }
            break;
        case "REQUEST_LOGIN":
            return {
                ...state,
                login: {
                    ...state.login,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            }
        case "RECEIVE_LOGIN":
            if (action.requestTime !== state.login.requestTime)
                return state;

            if (action.token)
            {
                return {
                    ...state,
                    currentUser: {
                        expiration: action.token.expirationDate
                    },
                    login: {
                        ...state.login,
                        isLoading: false
                    }
                }
            }
            return {
                ...state,
                currentUser: {  },
                login: {
                    ...state.login,
                    isLoading: false
                }
            }
        case 'SELECT_USER_CURRENCY':
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    clientModel: {
                        ...state.currentUser.clientModel,
                        currencyId: action.currencyId
                    }
                }
            };
        case 'SELECT_USER_INLANDCURRENCY':
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    clientModel: {
                        ...state.currentUser.clientModel,
                        inlandCurrencyId: action.payload.currencyId
                    }
                }
            };
        case 'REQUEST_EDIT_CLIENT':
            return {
                ...state,
                editClient: {
                    ...state.editClient,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case 'RECEIVE_EDIT_CLIENT':
            if (action.requestTime !== state.editClient.requestTime)
                return state;

            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    clientModel: {
                        ...action.model,
                        subscriptions: state.currentUser.clientModel.subscriptions
                    }
                },
                editClient: {
                    ...state.editClient,
                    isLoading: false
                }
            };
        case "REQUEST_EDIT_QUOTATION_CLIENT_SETTINGS":
            return {
                ...state,
                editQuotationClientSettings: {
                    ...state.editQuotationClientSettings,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_EDIT_QUOTATION_CLIENT_SETTINGS":
            if (action.payload.requestTime !== state.editQuotationClientSettings.requestTime)
                return state;

            return {
                ...state,
                editQuotationClientSettings: {
                    ...state.editQuotationClientSettings,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                },
                currentUser: action.error
                    ? state.currentUser
                    : {
                        ...state.currentUser,
                        clientModel: {
                            ...state.currentUser.clientModel,
                            quotationClientSettings: {
                                ...state.currentUser.clientModel.quotationClientSettings,
                                ...action.payload.model
                            }
                        }
                    }
            };
        case "REQUEST_EDIT_PASSWORD":
            return {
                ...state,
                editPassword: {
                    ...state.editPassword,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_EDIT_PASSWORD":
            return {
                ...state,
                editPassword: {
                    ...state.editPassword,
                    isLoading: false,
                },
            };
        case 'REQUEST_UPDATE_DEFAULT_QUOTATIONCONTACT':
            return {
                ...state,
                editClient: {
                    ...state.editClient,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case 'RECEIVE_UPDATE_DEFAULT_QUOTATIONCONTACT':
            if (action.payload.requestTime !== state.editClient.requestTime)
                return state;

            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    clientModel: {
                        ...state.currentUser.clientModel,
                        defaultQuotationContactId: action.payload.quotationContactId
                    }
                },
                editClient: {
                    ...state.editClient,
                    isLoading: false
                }
            };
        case "LOGOUT":
            return {
                ...state,
                currentUser: {},
            };
        case "SELECT_APPLICATION_LANGUAGE":
            return {
                ...state,
                languageId: action.languageId
            };
        case "REQUEST_ACCEPT_GCU":
            return {
                ...state,
                acceptGcuState: {
                    ...state.acceptGcuState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_ACCEPT_GCU":
            if (action.requestTime !== state.acceptGcuState.requestTime)
                return state;

            return {
                ...state,
                acceptGcuState: {
                    ...state.acceptGcuState,
                    isLoading: false
                },
                currentUser: {
                    ...state.currentUser,
                    clientModel: {
                        ...state.currentUser.clientModel,
                        cgu: action.success
                            ? true
                            : state.currentUser.clientModel.cgu
                    }
                }
            };
        case "OPEN_NAVIGATION_MENU":
            return {
                ...state,
                isMenuOpen: true
            };
        case "CLOSE_NAVIGATION_MENU":
            return {
                ...state,
                isMenuOpen: false
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};