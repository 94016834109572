import * as React from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';

interface CenteredTabsProps {
    selected: number | string;
    centered: boolean;
    onChange: (val: any) => void;
}

export default class CenteredTabs extends React.Component<CenteredTabsProps, {}> {
    public render() {
        return (
            <Paper>
                <Tabs
                    value={this.props.selected}
                    onChange={(e, val) => this.props.onChange(val)}
                    indicatorColor="primary"
                    textColor="primary"
                    centered={this.props.centered}>
                    {this.props.children}
                </Tabs>
            </Paper>
        );
    }
}