import * as React from "react";
import { Guid } from "../utils/utils";

export interface DataPoint {
    x: number;
    y: number;
}

export interface ChartData {
    labels?: Array<string>;
    datasets: Array<ChartDataset>;
}

export interface ChartDataset {
    label?: string;
    data: Array<DataPoint | number>;
    backgroundColor: string;
    lineTension?: number;
    fill?: boolean;
    showLine?: boolean;
}

interface ChartProps {
    type: string;
    style?: React.CSSProperties;
    title?: string;
    data: ChartData;
    options: any;
    height?: number;
    width?: number;
}

export default class ChartReact extends React.Component<ChartProps, {}> {
    private containerId: string;
    private chartRef: any;

    constructor(props: ChartProps) {
        super(props);
        this.containerId = Guid.newGuid();
    }

    componentDidMount() {
        let ctx = document.getElementById(this.containerId);
        // @ts-ignore
        this.chartRef = new Chart(ctx, {
            type: this.props.type,
            data: this.props.data,
            options: this.chartOptions
        });
    }

    componentWillUnmount() {
        if (this.chartRef) {
            this.chartRef.destroy();
        }
    }

    componentWillReceiveProps(nextProps: ChartProps) {
        if (this.chartRef) {
            this.chartRef.data = nextProps.data;
            this.chartRef.update();
        }
    }

    get chartOptions(): any {
        return {
            ...this.props.options,
            title: {
                display: this.props.title
                    ? true
                    : false,
                text: this.props.title,
                fontSize: 18
            }
        };
    }

    public render() {
        return (
            <canvas id={this.containerId}
                style={this.props.style}>
            </canvas>
            );
    }
}