import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

const StyledExpansionPanel = styled(Accordion)({
    border: 'none',
    boxShadow: 'none',
    margin: 0,
    padding: 0,
    '& .Mui-expanded': {
        margin: 0,
    },
    "& .MuiAccordion-root.Mui-expanded": {
        margin: 0,
    },
    '& .MuiAccordion-root:before': {
        height: "0 !important",
        background: 'none',
        backgroundColor: 'none'
    },
    '&:before': {
        display: "none"
    }
});

const StyledExpansionPanelSummary = styled(AccordionSummary)({
    border: 'none',
    boxShadow: 'none',
    margin: 0,
    padding: 0,
    minHeight: "0 !important",
    '& .Mui-expanded': {
        margin: 0
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 0,
    },
    '& .MuiAccordionSummary-content': {
        margin: 0
    },
    '&:before': {
        display: "none"
    }
});

const StyledExpansionPanelDetails = styled(AccordionDetails)({
    border: 'none',
    boxShadow: 'none',
    margin: 0,
    padding: 0,
});

interface ExpensionPanelProps {
    expanded: boolean;
    onChange: (value: boolean) => void;
    head: JSX.Element;
    children: any;
    style?: React.CSSProperties;
}

export default class ExpensionPanel extends React.Component<ExpensionPanelProps, {}> {
    public render() {
        return (
            <StyledExpansionPanel
                style={{ ...this.props.style }}
                expanded={this.props.expanded}
                onChange={(e, value) => this.props.onChange(value)}>
                <StyledExpansionPanelSummary>
                    {this.props.head}
                </StyledExpansionPanelSummary>
                <StyledExpansionPanelDetails>
                    {this.props.children}
                </StyledExpansionPanelDetails>
            </StyledExpansionPanel>
            );
    }
}