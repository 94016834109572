import * as React from 'react';
import * as _ from 'lodash';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import Moment from 'moment';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getFirmName } from '../utils/utils';
import Select from "./Select";
import { getText } from '../utils/langTexts';
import { revertColors } from '../styles/colors';

type ModeratorFirmNewsEditFormProps = ReduxForm.InjectedFormProps<{}, {}, string> & ModeratorFirmNewsEditFormOwnProps;

interface ModeratorFirmNewsEditFormOwnProps {
    subscriptions: Array<Api.SubscriptionModel>;
    carriers: { [id: number]: Api.CarrierModel };
    onSubmit: (values: Api.FirmDocumentModel) => void;
}

const defaultFormValues = { dateBegin: Moment().add(0, "days").toDate(), dateEnd: Moment().add(30, "days").toDate() };

const textAreaField = FormField.textAreaField(4, 50);
const btnStyle = { marginRight: 10 };

class ModeratorFirmNewsEditForm extends React.Component<ModeratorFirmNewsEditFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-horizontal container-fluid">
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtDocumentsEditCompany")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            name="firmId"
                            validate={[FormField.required]}
                            component={(field: any) => (
                                <div style={{ width: "100%" }}>
                                    <Select
                                        options={_.uniqBy(this.props.subscriptions
                                            .filter(su => su.subscriptionType === "Okargo")
                                            .filter(x => x.agency.company.companyGroup)
                                            .map(su => ({
                                                label: getFirmName(su.agency.company.companyGroup),
                                                value: su.agency.company.companyGroupId
                                            })).concat(this.props.subscriptions
                                                .filter(su => su.subscriptionType === "Okargo")
                                                .map(su => ({
                                                    label: getFirmName(su.agency.company),
                                                    value: su.agency.companyId
                                                })).concat(
                                                    this.props.subscriptions
                                                        .filter(su => su.subscriptionType === "Okargo")
                                                        .map(su => ({
                                                            label: getFirmName(su.agency),
                                                            value: su.agencyId
                                                        }))
                                                )), x => x.value)}
                                        value={field.input.value}
                                        onChange={(value) => field.input.onChange(value)}
                                    />
                                </div>
                            )} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtDocumentsEditCarrier")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            name="carrierId"
                            component={FormField.getSelectField([{ label: "------", value: null }].concat(_.sortBy(_.values(this.props.carriers), x => x.name)
                                .map(ca => ({
                                    label: ca.name,
                                    value: ca.carrierId
                                }))))} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtDocumentsEditName")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            validate={[FormField.required]}
                            name="name"
                            component={FormField.renderField}
                            type="text" />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtDocumentsEditFile")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            validate={[]}
                            name="file"
                            component={FormField.fileField}
                            type="text" />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label"></label>
                    <div className="col-sm-9">
                        <button
                            type="submit"
                            style={{ ...btnStyle, ...revertColors.main3 }}
                            className="btn"
                        >
                            {getText("MdtDocumentsEditCreate")}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let ModeratorFirmNewsEditFormWrapped = ReduxForm.reduxForm({
    form: 'moderatorEditFirmDocument',
})(ModeratorFirmNewsEditForm) as any;

export default connect((state: ApplicationState) =>
    ({
        carriers: state.seed.carriers,
        subscriptions: state.account.currentUser.clientModel.subscriptions.filter(x => x.allowInformUser)
    } as ModeratorFirmNewsEditFormOwnProps))(ModeratorFirmNewsEditFormWrapped) as any as React.ComponentClass<{ onSubmit: (values: Api.FirmDocumentModel) => any }>;