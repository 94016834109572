import * as React from 'react';
import * as ReduxForm from 'redux-form';
import * as AskBookingStore from '../store/AskBooking';
import * as FormField from './FormField';
import { getText } from '../utils/langTexts';
import * as Colors from '../styles/colors';


type AskBookingByEmailFormProps = ReduxForm.InjectedFormProps<{}, {}, string> & AskBookingByEmailFormOwnProps;

interface AskBookingByEmailFormOwnProps {
    prevStep: () => void;
    type: AskBookingStore.AskBookingType;
}

const textAreaField = FormField.textAreaFieldCustom(5, 45);

const consigneeOrShipper = (value, allValues) => ((!allValues.consigneeName && !allValues.shipperName) && getText("GenOneRequired"));
const containerStyle = { display: "flex", flexDirection: "row", marginBottom: 10 } as React.CSSProperties;
const labelStyle = { width: 200, fontFamily: "Calibri", textAlign: "right", marginRight: 10, paddingTop: 7, color: "Colors.baseColors.darkGray" } as React.CSSProperties;
const inputStyle = { height: 30, backgroundColor: "white", marginBottom: 10, borderRadius: 5, borderColor: "Colors.baseColors.darkGray", borderWidth: 0.5, width: 250 };

class AskBookingByEmailForm extends React.Component<AskBookingByEmailFormProps, {}> {
    public render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div style={{ textAlign: "center"}}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                {this.props.type === "byAgent" &&
                    <div >
                        <h3 style={{
                            fontFamily: "Calibri",
                        }}>
                            {getText("SlcBookingYourClient")}</h3>
                        <div style={containerStyle}>
                            <label >{getText("SlcBookingConsignee")}</label>
                            <div >
                                <ReduxForm.Field
                                    validate={consigneeOrShipper}
                                    name="consigneeName"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={containerStyle}>
                            <label >{getText("SlcBookingShipper")}</label>
                            <div >
                                <ReduxForm.Field
                                    validate={consigneeOrShipper}
                                    name="shipperName"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={containerStyle}>
                            <label >{getText("SlcBookingPoNumber")}</label>
                            <div >
                                <ReduxForm.Field
                                    validate={FormField.required}
                                    name="poNumber"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={containerStyle}>
                            <label >{getText("SlcBookingIncoterm")}</label>
                            <div >
                                <ReduxForm.Field
                                    validate={FormField.required}
                                    name="incoterm"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                    </div>}

                <h3 style={{
                    color: "Colors.baseColors.lightGray",
                    fontFamily: "Calibri",
                    marginLeft: 50,
                }}>{getText("SlcBookingBookingInfo")}</h3>
                <div style={{
                    paddingLeft: 30,
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <div style={containerStyle}>
                        <div style={{
                            marginLeft: 102,
                        }}>
                            <ReduxForm.Field
                                name="isHazardous"
                                component={FormField.checkBoxField(getText("SlcBookingIsHazardous"))} />
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={{
                            width: 200,
                            fontFamily: "Calibri",
                            textAlign: "right",
                            marginRight: 10,
                            color: "Colors.baseColors.darkGray",
                            marginTop: 5
                        }}>{getText("SlcBookingDateMad")}</label>
                        <div >
                            <ReduxForm.Field
                                name="madDate"
                                component={(fields) => <FormField.DatePickerField {...fields} />} />
                        </div>
                    </div>
                    <div style={containerStyle} >
                        <label style={{
                            width: 200, fontFamily: "Calibri", textAlign: "right", marginRight: 10, color: "Colors.baseColors.darkGray"
                        }}>{getText("SlcBookingLocationMad")}</label>
                        <div>
                            <ReduxForm.Field
                                style={inputStyle}
                                validate={FormField.required}
                                name="madLocation"
                                component={FormField.renderField}
                                type="text" />
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={labelStyle}>{getText("SlcBookingDeadline")}</label>
                        <div >
                            <ReduxForm.Field
                                name="deadline"
                                component={(fields) => <FormField.DatePickerField {...fields} />} />
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={labelStyle}>{getText("SlcBookingWeight")}</label>
                        <div >
                            <ReduxForm.Field
                                style={inputStyle}
                                validate={FormField.required}
                                name="cargoWeight"
                                component={FormField.renderField}
                                type="text" />
                        </div>
                    </div>
                    <div style={containerStyle} >
                        <label style={labelStyle}>{getText("SlcBookingCargo")}</label>
                        <div >
                            <ReduxForm.Field
                                style={inputStyle}
                                name="cargoDescription"
                                component={textAreaField} />
                        </div>
                    </div>

                    {this.props.type === "byAgent" &&
                        <div style={containerStyle}>
                            <label style={labelStyle}>{getText("SlcBookingRoutingInfo")}</label>
                            <div >
                                <ReduxForm.Field
                                    style={inputStyle}
                                    name="routingInstruction"
                                    component={textAreaField} />
                            </div>
                        </div>}

                    <div >
                        <button
                            style={{ backgroundColor: "white", color: Colors.baseColors.lightBlue, borderRadius: 10, borderWidth: 0.5, fontWeight: "bold", flex: 1, height: 30, float: "left" }}
                            type="button"
                            onClick={(e) => {
                                this.props.prevStep();
                                e.preventDefault();
                            }}
                        >{getText("GenPrevStep")}</button>
                        <button
                            style={{ backgroundColor: "white", color: Colors.baseColors.lightBlue, borderRadius: 10, borderWidth: 0.5, fontWeight: "bold", flex: 1, height: 30, float: "right", marginRight: 50 }}
                            type="submit"
                        >{getText("GenNextStep")}</button>
                    </div>
                </div>
            </form>);
    }
}

export default ReduxForm.reduxForm({ form: 'askBookingByEmail' })(AskBookingByEmailForm) as any;
