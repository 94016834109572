import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as QuotationStore from '../store/Quotation';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getText } from '../utils/langTexts';
import * as _ from 'lodash';
import { CSSProperties } from 'react';
import { detailsLevelOptions, totalDisplayOptions } from './QuotationRates';
import { revertColors } from '../styles/colors';
import { getDefaultHeaders } from '../utils/utils';
import {hFieldStyle, hInputStyle, hLabelStyle, vFormStyle} from "../styles/baseStyles";
import * as Colors from "../styles/colors";
import Button from "./Button";

type QuotationTemplateFormProps = ReduxForm.InjectedFormProps<QuotationStore.QuotationCreateTemplateModel, {}, string>
    & QuotationTemplateFormExternalProps
    & QuotationTemplateFormOwnProps;

interface QuotationTemplateFormExternalProps {
    readonly?: boolean;
    templates: Array<Api.QuotationModel>;
    onSubmit: (values: QuotationStore.QuotationCreateTemplateModel) => Promise<any>
}

interface QuotationTemplateFormOwnProps {
    quotation: Api.QuotationModel;
    criteria: Api.CriteriaModel;
    client: Api.ClientModel;
    mode: "Create" | "Update";
    currencies: { [id: number]: Api.CurrencyModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    languages: { [id: number]: Api.LanguageModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    quotationFees: { [index: number]: boolean };
    quotationCharges: { [index: number]: boolean };
}

const noLabelCheckBox = FormField.checkBoxField(undefined);

const fieldStyle: CSSProperties = { display: "flex", flexDirection: "row", alignItems: "center" };
const formPartStyle: CSSProperties = { flex: 1 };
const reminderPartStyle: CSSProperties = { flex: 2, fontStyle: "italic" };
const templateTypeOptions = () => [
    { label: "Normal", value: "Normal" },
    { label: "Master", value: "Master" },
] as Array<{ label: string, value: Api.QuotationTemplateSettingsModelTemplateTypeEnum }>

class QuotationTemplateForm extends React.Component<QuotationTemplateFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} resetOnSubmit={false}>
                <div style={{ ...vFormStyle, alignItems: "stretch" }}>
                    {!this.props.readonly && <h2 style={{ ...hFieldStyle }}>{getText("QtnTemplateCreateTitle")}</h2>}
                    <div style={{ ...hFieldStyle, padding: "10px 0px" }}>
                        {getText("QtnCreateTemplateHelp")}
                    </div>
                    {this.props.client.subscriptions
                        .some(x => x.subscriptionType === "Okargo" && x.allowQuotationManagement)
                        && <div style={{ ...hFieldStyle }}>
                                <div style={{ ...hLabelStyle }}>Type: </div>
                                <div style={{ ...hInputStyle }}>
                                <ReduxForm.Field name={'quotationTemplateSettings.templateType'}
                                    disabled={this.props.readonly}
                                    component={FormField.getSelectField(templateTypeOptions())} />
                                </div>
                            </div>}
                    <h3 style={{ ...hFieldStyle }}>{getText("QtnTemplateAddedCharges")}</h3>
                    <div style={{ ...hFieldStyle }}>
                        <div style={{ ...hLabelStyle, minWidth: 385 }}>
                        </div>
                        <div style={{ ...hInputStyle, display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {[0, 1, 2].map(nb => <div style={{ flex: 1, textAlign: "center" }}>
                                {this.props.criteria.criteriaSizeTypes[nb]
                                    && this.props.sizeTypes[this.props.criteria.criteriaSizeTypes[nb].sizeTypeId].name}
                            </div>)}
                        </div>
                    </div>
                    {_.values(this.props.quotationFees).map((quotationFee, index) => <div style={{ ...hFieldStyle }}>
                        <div style={{ ...hLabelStyle, fontSize: 12, display: "flex", flexDirection: "row-reverse", alignItems: "center", minWidth: 385 }}>
                            <ReduxForm.Field name={'quotationFees[' + index + ']'}
                                             disabled={this.props.readonly}
                                             component={noLabelCheckBox} />
                            <div>{this.props.quotation.quotationFees[index].name}</div>
                        </div>
                        <div style={{ ...hInputStyle, display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {this.props.quotation.quotationFees[index].type.startsWith("Bl")
                                && <div style={{ flex: 1, textAlign: "center" }}>
                                    <span>
                                        {this.props.quotation.quotationFees[index].quotationFeeAmounts[0].baseAmount}
                                        {"/"}
                                        {this.props.quotation.quotationFees[index].quotationFeeAmounts[0].amount}
                                        {" "}
                                        {this.props.currencies[this.props.quotation.quotationFees[index].quotationFeeAmounts[0].currencyId].code}
                                        {" Per "}
                                        {this.props.quotation.quotationFees[index].unit}
                                    </span>
                                </div>}
                            {!this.props.quotation.quotationFees[index].type.startsWith("Bl")
                                && [0, 1, 2].map(nb => <div style={{ flex: 1, textAlign: "center" }}>
                                    {this.props.criteria.criteriaSizeTypes[nb]
                                        && this.props.criteria.criteriaSizeTypes[nb].number > 0
                                        && this.props.quotation.quotationFees[index].quotationFeeAmounts
                                            .some(x => x.sizeTypeId === this.props.sizeTypes[this.props.criteria.criteriaSizeTypes[nb].sizeTypeId].sizeTypeId)
                                        && <span>
                                            {this.props.quotation.quotationFees[index].quotationFeeAmounts
                                                .find(x => x.sizeTypeId === this.props.sizeTypes[this.props.criteria.criteriaSizeTypes[nb].sizeTypeId].sizeTypeId).baseAmount}
                                            {"/"}
                                            {this.props.quotation.quotationFees[index].quotationFeeAmounts
                                                .find(x => x.sizeTypeId === this.props.sizeTypes[this.props.criteria.criteriaSizeTypes[nb].sizeTypeId].sizeTypeId).amount}
                                            {" "}
                                            {this.props.currencies[this.props.quotation.quotationFees[index].quotationFeeAmounts
                                                .find(x => x.sizeTypeId === this.props.sizeTypes[this.props.criteria.criteriaSizeTypes[nb].sizeTypeId].sizeTypeId).currencyId].code}
                                        </span>}
                                </div>)}
                        </div>
                    </div>)}
                    <h3 style={{ ...hFieldStyle }}>{getText("QtnTemplateAdditionalInfoAndTerms")}</h3>
                    {(!this.props.readonly 
                        || this.props.quotation.quotationTemplateSettings.includeAdditionalInfo) && <div style={{ ...hFieldStyle }}>
                        <div style={{  ...hLabelStyle }}> 
                            <ReduxForm.Field name={'quotationTemplateSettings.includeAdditionalInfo'}
                                           disabled={this.props.readonly}
                                           component={FormField.checkBoxField(getText("QtnTemplateAdditionalInfo"))} />
                        </div>
                        <div style={{ ...hInputStyle, fontStyle: "italic" }}>
                            {this.props.quotation.addInfo}
                        </div>
                    </div>}
                    {(!this.props.readonly
                        || this.props.quotation.quotationTemplateSettings.includeTerms) && <div style={{ ...hFieldStyle }}>
                        <div style={{  ...hLabelStyle }}>
                            <ReduxForm.Field name={'quotationTemplateSettings.includeTerms'}
                                             disabled={this.props.readonly}
                                             component={FormField.checkBoxField(getText("QtnTemplateTerms"))} />
                        </div>
                        <div style={{ ...hInputStyle, fontStyle: "italic" }}>
                            {this.props.quotation.terms}
                        </div>
                    </div>}
                    <h3>{getText("QtnTemplateSettings")}</h3>
                    {(!this.props.readonly
                        || this.props.quotation.quotationTemplateSettings.includeDetailsLevel) && <div style={{ ...hFieldStyle }}>
                        <div style={{  ...hLabelStyle }}>
                            <ReduxForm.Field name={'quotationTemplateSettings.includeDetailsLevel'}
                                             disabled={this.props.readonly}
                                             component={FormField.checkBoxField(getText("QtnTemplateDetailsLevel"))} />
                        </div>
                        <div style={{ ...hInputStyle, fontStyle: "italic" }}>
                            {detailsLevelOptions().find(x => x.value === this.props.quotation.detailsLevel).label}
                        </div>
                    </div>}
                    {(!this.props.readonly
                        || this.props.quotation.quotationTemplateSettings.includeTotalType) && <div style={{ ...hFieldStyle }}>
                        <div style={{  ...hLabelStyle }}>
                            <ReduxForm.Field name={'quotationTemplateSettings.includeTotalType'}
                                             disabled={this.props.readonly}
                                             component={FormField.checkBoxField(getText("QtnTemplateTotalType"))} />
                        </div>
                        <div style={{ ...hInputStyle, fontStyle: "italic" }}>
                            {totalDisplayOptions().find(x => x.value === this.props.quotation.totalType).label}
                        </div>
                    </div>}
                    {(!this.props.readonly
                        || this.props.quotation.quotationTemplateSettings.includeShowExchangeRates) && <div style={{ ...hFieldStyle }}>
                        <div style={{  ...hLabelStyle }}>
                            <ReduxForm.Field name={'quotationTemplateSettings.includeShowExchangeRates'}
                                             disabled={this.props.readonly}
                                             component={FormField.checkBoxField(getText("QtnShowExchangeRates"))} />
                        </div>
                        <div style={{ ...hInputStyle, fontStyle: "italic" }}>
                            {getText("QtnShowExchangeRates")} {this.props.quotation.quotationTemplateSettings
                        && this.props.quotation.quotationTemplateSettings.includeShowExchangeRates
                            ? (this.props.quotation.showExchangeRates ? getText("GenYes") : getText("GenNo"))
                            : ""}
                        </div>
                    </div>}
                    {(!this.props.readonly
                        || this.props.quotation.quotationTemplateSettings.includeExchangeRates) && <div style={{ ...hFieldStyle }}>
                        <div style={{  ...hLabelStyle }}>
                            <ReduxForm.Field name={'quotationTemplateSettings.includeExchangeRates'}
                                             disabled={this.props.readonly}
                                             component={FormField.checkBoxField(getText("QtnTemplateExchangeRates"))} />
                        </div>
                        <div style={{ ...hInputStyle, fontStyle: "italic", display: "flex", flexDirection: "row", alignItems: "center", columnGap: 12 }}>
                            {this.props.quotation.quotationCurrencies.map(x => <div style={{ flex: "0 0 auto", textAlign: "center" }}>
                                1 {this.props.currencies[x.currencyId].code} = {x.value} USD
                            </div>)}
                        </div>
                    </div>}
                    {(!this.props.readonly
                        || this.props.quotation.quotationTemplateSettings.includeLanguage) && <div style={{ ...hFieldStyle }}>
                        <div style={{  ...hLabelStyle }}>
                            <ReduxForm.Field name={'quotationTemplateSettings.includeLanguage'}
                                             disabled={this.props.readonly}
                                             component={FormField.checkBoxField(getText("QtnTemplateLanguage"))} />
                        </div>
                        <div style={{ ...hInputStyle, fontStyle: "italic" }}>
                            {this.props.languages[this.props.quotation.languageId].name}
                        </div>
                    </div>}
                    <div style={{ ...hFieldStyle }}>
                        <div style={{  ...hLabelStyle }}>{getText("QtnTemplateName")}</div>
                        <div style={{  ...hInputStyle }}>
                            <ReduxForm.Field name={'name'}
                                             validate={[FormField.required]}
                                             disabled={this.props.readonly}
                                             display="block"
                                             className={this.props.mode === "Create" ? "form-control" : ""}
                                             component={this.props.mode === "Create"
                                                 ? FormField.renderField
                                                 : FormField.getSelectField(this.props.templates
                                                     .map(x => ({ label: x.name, value: x.name })))}
                                             type={"text"} />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }}>
                        <div style={{  ...hLabelStyle }}></div>
                        <div style={{  ...hInputStyle, display: "flex", flexDirection: "row-reverse", alignItems: "center", columnGap: 12 }}>
                            <ReduxForm.Field name="mode"
                                             disabled={this.props.readonly}
                                             component={FormField.renderField}
                                             value="Create"
                                             type="radio" />
                            <ReduxForm.Field name="mode"
                                             disabled={this.props.readonly}
                                             component={FormField.renderField}
                                             value="Update"
                                             type="radio" />
                        </div>
                    </div>
                    {!this.props.readonly && <div style={{ ...hFieldStyle }}>
                        <div style={{  ...hLabelStyle }}></div>
                        <div style={{  ...hInputStyle, display: "flex", flexDirection: "row-reverse", alignItems: "center", columnGap: 12 }}>
                            <Button
                                type={"submit"}
                                style={{
                                    color: "white",
                                    backgroundColor: Colors.baseColors.lightBlue,
                                    border: 0,
                                    width: 120
                                }}>{getText("SlcCrtSearch")}</Button>
                        </div>
                    </div>}
                </div>
            </FormField.BaseForm>
        );
    }
}

let previewForm = ReduxForm.reduxForm({
    form: 'quotationPreviewTemplate',
})(QuotationTemplateForm) as any;
const previewSelector = ReduxForm.formValueSelector('quotationPreviewTemplate')

let createForm = ReduxForm.reduxForm({
    form: 'quotationCreateTemplate',
    destroyOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    asyncValidate: (values, dispatch, props) => {
        let model = values as QuotationStore.QuotationCreateTemplateModel;
        let formProps = props as QuotationTemplateFormProps;

        return new Promise<void>((resolve, reject) => {
            if (model.mode === "Update") {
                resolve();
                return;
            }

            let api = new Api.QuotationApi();
            api.templateNameIsValid({
                name: model.name
            }, { credentials: "same-origin", headers: getDefaultHeaders() }).then(result => {
                if (result.result) {
                    resolve();
                    return;
                }

                reject({ "name": getText("QtnTemplateNameNotValid") });
            }).catch(err => {
                reject({ "name": "Failed to verify name, network error" });
            });
        });
    },
    shouldAsyncValidate: (params) => {
        return params.trigger === "submit";
    }
})(QuotationTemplateForm) as any;
const createSelector = ReduxForm.formValueSelector('quotationCreateTemplate')

export const PreviewTemplateForm = connect((state: ApplicationState) => {
    let quotation = state.quotation.quotationTemplates
        .find(x => x.quotationId === state.quotation.templateState.previewQuotationId);

    let quotationCharges = {};
    let quotationFees = {};

    quotation.quotationCharges.forEach((x, xi) => {
        quotationCharges[xi] = false;
    });
    quotation.quotationFees.forEach((x, xi) => {
        quotationFees[xi] = true;
    });

    return {
        initialValues: {
            name: quotation.name,
            quotationCharges: quotationCharges,
            quotationFees: quotationFees,
            quotationTemplateSettings: quotation.quotationTemplateSettings,
            mode: "Update"
        } as QuotationStore.QuotationCreateTemplateModel,
        client: state.account.currentUser
            ? state.account.currentUser.clientModel
            : null,
        chargeNames: state.seed.chargeNames,
        currencies: state.seed.currencies,
        sizeTypes: state.seed.sizeTypes,
        languages: state.seed.languages,
        quotation: quotation,
        criteria: state.quotation.criteria,
        mode: previewSelector(state, "mode"),
        quotationCharges: previewSelector(state, "quotationCharges"),
        quotationFees: previewSelector(state, "quotationFees")
    } as QuotationTemplateFormOwnProps;
}, null)(previewForm) as any as React.ComponentClass<QuotationTemplateFormExternalProps>;

export const CreateTemplateForm = connect((state: ApplicationState) => {
    let quotationCharges = {};
    let quotationFees = {};

    state.quotation.quotation.quotationCharges.forEach((x, xi) => {
        quotationCharges[xi] = false;
    });
    state.quotation.quotation.quotationFees.forEach((x, xi) => {
        quotationFees[xi] = true;
    });

    return {
        initialValues: {
            name: "",
            quotationTemplateSettings: {
                templateType: "Normal",
                includeAdditionalInfo: true,
                includeTerms: true,
                includeDetailsLevel: true,
                includeTotalType: true,
                includeExchangeRates: false,
                includeShowExchangeRates: false,
                includeLanguage: true,
            },
            quotationCharges: quotationCharges,
            quotationFees: quotationFees,
            mode: "Create"
        } as QuotationStore.QuotationCreateTemplateModel,
        client: state.account.currentUser
            ? state.account.currentUser.clientModel
            : null,
        chargeNames: state.seed.chargeNames,
        currencies: state.seed.currencies,
        sizeTypes: state.seed.sizeTypes,
        languages: state.seed.languages,
        quotation: state.quotation.quotation,
        criteria: state.quotation.criteria,
        mode: createSelector(state, "mode"),
        quotationCharges: createSelector(state, "quotationCharges"),
        quotationFees: createSelector(state, "quotationFees")
    } as QuotationTemplateFormOwnProps
}, null)(createForm) as any as React.ComponentClass<QuotationTemplateFormExternalProps>;