import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as TablesStore from "../store/Tables";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import * as Colors from "../styles/colors";
import { styled } from "@material-ui/styles";
import { ArrowDropDown, ExpandMore, ExpandLess, SubdirectoryArrowRight } from "@material-ui/icons";

type OkTableProps = OkTableExternalProps
    & OkTableOwnProps
    & typeof TablesStore.actionCrators;

type StyleFunction = (d: any) => React.CSSProperties;

interface OkTableExternalProps {
    tableKey: string;
    className?: string;
    style?: React.CSSProperties;
    headTrStyle?: React.CSSProperties;
    bodyTrStyle?: React.CSSProperties | StyleFunction;
    size?: "small" | "medium";
    padding?: 'default' | 'checkbox' | 'none'; 
    autoSort?: boolean;
    dataKey?: (data: any) => string;
    columns: Array<Column<any>>;
    data: Array<any>;
    showPagination?: boolean;
    showPageSizeOptions?: boolean;
    rowsPerPage?: number;
    onBodyTrClick?: (data: any) => any;
    onThClick?: (col: Column<any>) => any;
    isLoading?: boolean;
    loadingComponent?: () => JSX.Element;
    noDataText?: string;
}

interface OkTableOwnProps {
    tables: TablesStore.TablesState;
}

export type Column<T> = {
    header: JSX.Element | string;
    id: string;
    className?: string;
    accessor: (d: T) => any;
    align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
    headerAlign?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
    cell?: (d: any) => any;
    sorted?: () => "asc" | "desc";
    sortingDisabled?: boolean;
};

interface EnhancedTableHeadProps {
    columns: Array<Column<any>>;
    sorted: TablesStore.TableSorted;
    updateSorted: (sorted: TablesStore.TableSorted) => void;
    headTrStyle?: React.CSSProperties;
    onThClick?: (col: Column<any>) => any;
    autoSort?: boolean;
    hasSubRows: boolean;
}

class EnhancedTableHead extends React.Component<EnhancedTableHeadProps, {}>{

    sortDirection(col: Column<any>) {
        return this.props.autoSort
            ? (this.props.sorted
                && this.props.sorted.id === col.id
                ? (this.props.sorted.desc ? "desc" : "asc")
                : false)
            : (col.sorted
                ? col.sorted()
                : undefined);
    }

    public render() {
        return (
            <TableHead>
                <TableRow style={{ ...this.props.headTrStyle }}>
                    <TableCell
                            key={"_expend"}
                            style={{
                                backgroundColor: Colors.baseColors.lightBlue,
                                color: "white",
                                borderTopLeftRadius: 10,
                                borderBottomLeftRadius: 10,
                            }}
                            align={"left"}
                            padding={'default'}>
                    </TableCell>
                    {this.props.columns.map((col, index) => (
                        <TableCell
                            key={col.id}
                            style={{
                                backgroundColor: Colors.baseColors.lightBlue,
                                color: "white",
                                ...(index === this.props.columns.length - 1
                                        ? {
                                            borderTopRightRadius: 10,
                                            borderBottomRightRadius: 10,
                                        }
                                        : {})
                            }}
                            align={col.headerAlign || "center"}
                            padding={'default'}
                            sortDirection={this.sortDirection(col)}>
                            {col.sortingDisabled
                                ? <div style={{ color: "white" }}>
                                    {col.header}
                                </div>
                                : <TableSortLabel
                                    active={this.sortDirection(col) ? true : false}
                                    style={{ color: "white" }}
                                    IconComponent={(props) => <ArrowDropDown {...props} />}
                                    direction={this.sortDirection(col) || "asc"}
                                    onClick={() => {
                                        if (this.props.onThClick) {
                                            this.props.onThClick(col);
                                        }
                                        if (this.props.autoSort) {
                                            this.props.updateSorted({
                                                id: col.id,
                                                desc: !this.sortDirection(col) || this.sortDirection(col) === "asc"
                                            });
                                        }
                                    }}>
                                    {col.header}
                                </TableSortLabel>}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            );
    }
}

const StyledTable = styled(Table)({
    "& .MuiTableCell-sizeSmall": {
        padding: "3px 3px 3px 3px",
        maxHeight: 30
    }
});

class OkTable extends React.Component<OkTableProps, {}> {

    get tableState(): TablesStore.TableState {
        return this.props.tables[this.props.tableKey]
            || TablesStore.unloadedTableState;
    }

    get data(): Array<any> {
        let sorted = this.props.tables[this.props.tableKey]
            && this.props.tables[this.props.tableKey].sorted;
        let columnSort = sorted
            ? this.props.columns.find(x => x.id === this.props.tables[this.props.tableKey].sorted.id)
            : undefined;
        let page = this.props.tables[this.props.tableKey]
            ? (this.props.tables[this.props.tableKey].page || 0)
            : 0;
        let rowsPerPage = this.props.tables[this.props.tableKey]
            ? (this.props.tables[this.props.tableKey].rowsPerPage || this.props.rowsPerPage || Number.MAX_SAFE_INTEGER)
            : 10;
        let startRow = this.props.showPagination ? page * rowsPerPage : 0;
        let endRow = this.props.showPagination ? startRow + rowsPerPage : this.props.data.length;

        let baseSortData = (this.props.autoSort
            ? _.sortBy(this.props.data, x =>
                columnSort
                    ? columnSort.accessor(x)
                    : 1)
            : this.props.data);
        return this.props.autoSort && sorted && sorted.desc 
            ?   baseSortData
                .reverse()
                .slice(startRow, endRow)
            : baseSortData
            .slice(startRow, endRow);
    }

    public render() {
        if (!this.props.columns.some(x => x.header))
            return null;
        return (
            <Paper style={this.props.showPagination
                ? {}
                : { boxShadow: 'none' }}>
                <TableContainer>
                    <StyledTable
                        padding={this.props.padding || "default"}
                        className={this.props.className}
                        style={this.props.style}
                        size={this.props.size || 'medium'}>
                        <EnhancedTableHead
                            headTrStyle={this.props.headTrStyle}
                            columns={this.props.columns}
                            hasSubRows={this.props.data.some(x => x.subRows && x.subRows.length)}
                            updateSorted={(sorted) => this.props.updateSorted(this.props.tableKey, sorted)}
                            autoSort={this.props.autoSort}
                            onThClick={(col) => this.props.onThClick(col)}
                            sorted={this.props.tables[this.props.tableKey]
                                && this.props.tables[this.props.tableKey].sorted}
                        />
                        <TableBody>
                            {this.data.length !== 0 ? (
                            this.data
                                    .map((d, index) => {
                                    let hasSubRows = d.subRows && d.subRows.length ? true : false;
                                    let rowKey = (this.props.dataKey ? this.props.dataKey(d) : index.toString()) as string;
                                    return (
                                        <React.Fragment>
                                        <TableRow
                                            key={rowKey}
                                            onClick={(e) => {
                                                if (this.props.onBodyTrClick) {
                                                    this.props.onBodyTrClick(d);
                                                }
                                            }}
                                            style={{
                                                cursor: this.props.onBodyTrClick ? "pointer" : "auto",
                                                ...(this.props.bodyTrStyle
                                                    ? ({}.toString.call(this.props.bodyTrStyle) === '[object Function]'
                                                        ? (this.props.bodyTrStyle as any)(d)
                                                        : this.props.bodyTrStyle)
                                                    : {})
                                            }}
                                            hover>
                                            <TableCell style={{
                                                borderColor: "transparent",
                                                borderTopLeftRadius: 10,
                                                borderBottomLeftRadius: 10,
                                                ...((index + 1) % 2 === 0
                                                    ? {
                                                        backgroundColor: "#f0f0f0",
                                                    }
                                                    : {})
                                            }}
                                                align={"center"}
                                                component="td"
                                                id={"_expension"}
                                                scope="row"
                                                padding="default"
                                                onClick={(e) => {
                                                    this.props.updateRowOpened(this.props.tableKey
                                                        , rowKey
                                                        , this.props.tables[this.props.tableKey]
                                                            && this.props.tables[this.props.tableKey].rowsOpened[rowKey]
                                                            ? false : true);
                                                    e.stopPropagation();
                                                }}>
                                                    {hasSubRows
                                                        ? (this.props.tables[this.props.tableKey]
                                                            && this.props.tables[this.props.tableKey].rowsOpened[rowKey]
                                                            ? <ExpandLess className="hover-blue" />
                                                            : <ExpandMore className="hover-blue" />)
                                                        : ""}
                                            </TableCell>
                                            {this.props.columns.map((col, colIndex) =>
                                                <TableCell
                                                    key={col.id + (this.props.dataKey ? this.props.dataKey(d) : index)}
                                                    style={{
                                                        borderColor: "transparent",
                                                        ...(colIndex === this.props.columns.length - 1
                                                                ? { borderTopRightRadius: 10, borderBottomRightRadius: 10 }
                                                                : {}),
                                                        ...((index + 1) % 2 === 0
                                                            ? {
                                                                backgroundColor: "#f0f0f0",
                                                            }
                                                            : {})
                                                    }}
                                                    align={col.align || "inherit"}
                                                    component="td"
                                                    id={col.id}
                                                    scope="row"
                                                    padding="default">
                                                    {col.cell
                                                        ? col.cell(col.accessor(d))
                                                        : col.accessor(d)}
                                                </TableCell>)}
                                            </TableRow>
                                            {hasSubRows
                                                && this.props.tables[this.props.tableKey]
                                                && this.props.tables[this.props.tableKey].rowsOpened[rowKey]
                                                && d.subRows.map(subData => {
                                                    let subRowKey = (this.props.dataKey ? this.props.dataKey(subData) : index.toString()) as string;
                                                    return (
                                                        <TableRow
                                                            key={subRowKey}
                                                        onClick={(e) => {
                                                            if (this.props.onBodyTrClick) {
                                                                this.props.onBodyTrClick(subData);
                                                            }
                                                        }}
                                                        style={{
                                                            cursor: this.props.onBodyTrClick ? "pointer" : "auto",
                                                            ...(this.props.bodyTrStyle
                                                                ? ({}.toString.call(this.props.bodyTrStyle) === '[object Function]'
                                                                    ? (this.props.bodyTrStyle as any)(subData)
                                                                    : this.props.bodyTrStyle)
                                                                : {})
                                                        }}
                                                            hover>
                                                            <TableCell
                                                                key={"_expend" + (this.props.dataKey ? this.props.dataKey(subData) : index)}
                                                                style={{
                                                                    borderColor: "transparent",
                                                                    borderTopLeftRadius: 10,
                                                                    borderBottomLeftRadius: 10,
                                                                    ...((index + 1) % 2 === 0
                                                                        ? {
                                                                            backgroundColor: "#f0f0f0",
                                                                        }
                                                                        : {})
                                                                }}
                                                                align={"center"}
                                                                component="td"
                                                                id={"_expend"}
                                                                scope="row"
                                                                padding="default">
                                                                <SubdirectoryArrowRight style={{ height: 12 }} />
                                                            </TableCell>
                                                        {this.props.columns.map((col, colIndex) =>
                                                            <TableCell
                                                                key={col.id + (this.props.dataKey ? this.props.dataKey(subData) : index)}
                                                                style={{
                                                                    borderColor: "transparent",
                                                                    ...(colIndex === this.props.columns.length - 1
                                                                            ? { borderTopRightRadius: 10, borderBottomRightRadius: 10 }
                                                                            : {}),
                                                                    ...((index + 1) % 2 === 0
                                                                        ? {
                                                                            backgroundColor: "#f0f0f0",
                                                                        }
                                                                        : {})
                                                                }}
                                                                align={col.align || "inherit"}
                                                                component="td"
                                                                id={col.id}
                                                                scope="row"
                                                                padding="default">
                                                                {col.cell
                                                                    ? col.cell(col.accessor(subData))
                                                                    : col.accessor(subData)}
                                                            </TableCell>)}
                                                        </TableRow>
                                                    );
                                                })}
                                        </React.Fragment>
                                    );
                                    })
                            ) : (<div>{this.props.noDataText}</div>)
                            }
                            {this.props.loadingComponent && React.createElement(this.props.loadingComponent)} 
                        </TableBody>
                    </StyledTable>
                </TableContainer>
                {this.props.showPagination
                    && <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <TablePagination
                        rowsPerPageOptions={this.props.showPageSizeOptions ? [5, 10, 25] : []}
                        component="div"
                        count={this.props.data.length}
                        rowsPerPage={this.props.tables[this.props.tableKey]
                            && this.props.tables[this.props.tableKey].rowsPerPage
                            ? this.props.tables[this.props.tableKey].rowsPerPage
                            : (this.props.rowsPerPage || 20)}
                        page={this.props.tables[this.props.tableKey]
                            && this.props.tables[this.props.tableKey].page
                            ? this.props.tables[this.props.tableKey].page
                            : 0}
                        onPageChange={(e, val) => this.props.updatePage(this.props.tableKey, val)}
                        onRowsPerPageChange={(e) => this.props.updateRowsPerPage(this.props.tableKey, e.target.value as any)}
                    />
                    </div>}
            </Paper>
            );
    }
}

type ExternalType = React.ComponentClass<OkTableExternalProps>;
export default connect((state: ApplicationState) => ({
    tables: state.tables
} as OkTableProps), TablesStore.actionCrators)(OkTable) as any as ExternalType;