import { isClient } from "./utils";

class KeyboardHandler {
    private keys: { [key: number]: boolean };

    constructor() {
        this.keys = {};
    }

    public initialize(): void {
        if (isClient()) {
            window.onkeydown = (e) => {
                this.keys[e.keyCode] = true;
            };
            window.onkeyup = (e) => {
                this.keys[e.keyCode] = false;
            };
        }
    }

    public isKeyPressed(keyCode: number): boolean {
        return this.keys[keyCode];
    }
}

const keyboardHandler = new KeyboardHandler();
export default keyboardHandler;