import React from 'react';
import { ButtonGroup, Grid, MenuItem, MenuList, Popper, Paper, Grow } from '@material-ui/core';
import Button from "./Button";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

interface SplitButtonProps {
    defaultIndex: number;
    options: Array<SplitButtonOptions>;
    style?: React.CSSProperties;
    disabled?: boolean;
}

export interface SplitButtonOptions {
    label: string;
    disabled?: boolean;
    action: () => void;
}

interface SplitButtonState {
    isOpen: boolean;
    index: number;
}

export class SplitButton extends React.Component<SplitButtonProps, SplitButtonState> {
    anchorRef = null;

    constructor(props: SplitButtonProps) {
        super(props);
        this.state = {
            isOpen: false,
            index: this.props.defaultIndex
        };
    }

    public render() {
        return (
            <Grid style={this.props.style} container direction="column" alignItems="center">
                <Grid item xs={12}>
                    <ButtonGroup style={{ boxShadow: "none" }} variant="contained" color="primary" ref={(ref) => this.anchorRef = ref} aria-label="split button">
                        <Button disabled={this.props.disabled}
                            style={{ backgroundColor: "rgb(0,175,213)", color: "white" }}
                            type={"button"} onClick={() => {
                            if (this.props.options[this.state.index]
                                && this.props.options[this.state.index].action) {
                                this.props.options[this.state.index].action();
                                }
                            }}>{this.props.options[this.state.index].label}</Button>
                        <Button
                            type={"button"}
                            color="primary"
                            size="small"
                            style={{ backgroundColor: "rgb(0,175,213)", color: "white" }}
                            aria-controls={this.state.isOpen ? 'split-button-menu' : undefined}
                            aria-expanded={this.state.isOpen ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={() => this.setState({ isOpen: true })}>
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper open={this.state.isOpen} anchorEl={this.anchorRef} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}>
                                <Paper>
                                    <ClickAwayListener onClickAway={() => this.setState({ isOpen: false })}>
                                        <MenuList id="split-button-menu">
                                            {this.props.options.map((option, index) => (
                                                <MenuItem
                                                    key={option.label}
                                                    disabled={option.disabled}
                                                    selected={index === this.state.index}
                                                    onClick={(event) => {
                                                        this.setState({
                                                            index: index,
                                                            isOpen: false
                                                        });
                                                    }}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>);
    }
}