import * as Api from '../api/api';
import Moment from 'moment';
import { Md5 } from 'ts-md5/dist/md5';
import { ApplicationState } from '../store';
import { getDefaultHeaders } from './utils';
import * as _ from 'lodash';

export function generateHash(criteriaModel: Api.CriteriaModel): string {
    let md5 = new Md5();
    md5.appendStr(JSON.stringify(criteriaModel));
    return md5.end().toString();
}

export function hasDiffSearchCriteria(current: Api.CriteriaModel, loaded: Api.CriteriaModel): boolean {
    if (current === loaded)
        return true;

    if (!loaded)
        return false;

    return (current.suggested !== loaded.suggested)
            || (current.origin && current.origin.locationId !== loaded.origin.locationId)
            || (current.destination && current.destination.locationId !== loaded.destination.locationId)
            || (current.dateBegin && Moment(current.dateBegin).format("DDMMYYYY") !== Moment(loaded.dateBegin).format("DDMMYYYY"))
            || (current.dateEnd && Moment(current.dateEnd).format("DDMMYYYY") !== Moment(loaded.dateEnd).format("DDMMYYYY"))
            || (current.includeCarrierRates !== loaded.includeCarrierRates)
            || (current.originShippingType !== loaded.originShippingType)
            || (current.destinationShippingType !== loaded.destinationShippingType)
            || (current.lastRates !== loaded.lastRates)
            || (!loaded.includeSellingRates
                && current.includeSellingRates !== loaded.includeSellingRates)
            || (current.allMarkets !== loaded.allMarkets)
            || (current.includeCarrierRates && current.containerType !== loaded.containerType)
            || (current.includeCarrierRates
                && _.sortBy(current.criteriaSizeTypes, x => x.sizeTypeId).map(x => `${x.number}x${x.sizeTypeId}`).join("+")
                !== _.sortBy(loaded.criteriaSizeTypes, x => x.sizeTypeId).map(x => `${x.number}x${x.sizeTypeId}`).join("+"))
}

export function hasAdvanced(criteriaModel: Api.CriteriaModel): boolean {
    return criteriaModel.containerType !== "Dry" || criteriaModel.containerPrice ||
        criteriaModel.lastRates || criteriaModel.suggested ||
        criteriaModel.allMarkets;
}

export function isValid(criteriaModel: Api.CriteriaModel): boolean {
    return (criteriaModel.criteriaSizeTypes.some(cst => cst.number > 0)
        && criteriaModel.origin && criteriaModel.origin.locationId
        && criteriaModel.destination && criteriaModel.destination.locationId
        && criteriaModel.dateBegin && criteriaModel.dateEnd) ? true : false;
}

export function getCriteriaCode(getState: () => ApplicationState,
    criteria: Api.CriteriaModel,
    criteriaLoaded: Api.CriteriaModel): Promise<string> {
    if (criteria === criteriaLoaded
        || (JSON.stringify(criteria) === JSON.stringify(criteriaLoaded))) {
        return Promise.resolve<string>(criteria.code);
    }
    let api = new Api.CriteriaApi();
    return new Promise<string>((resolve, reject) => {
        api.createCriteria({ model: criteria }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then((criteria) => {
                resolve(criteria.code);
            }).catch(error => {
                reject(error);
            });
    });
}

export function getCriteria(getState: () => ApplicationState,
    criteria: Api.CriteriaModel,
    criteriaLoaded: Api.CriteriaModel): Promise<Api.CriteriaModel> {
    if (criteria === criteriaLoaded
        || (JSON.stringify(criteria) === JSON.stringify(criteriaLoaded))) {
        return Promise.resolve<Api.CriteriaModel>(criteria);
    }
    let api = new Api.CriteriaApi();
    return new Promise<Api.CriteriaModel>((resolve, reject) => {
        api.createCriteria({ model: criteria }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then((criteria) => {
                resolve(criteria);
            }).catch(error => {
                reject(error);
            });
    });
}