import * as React from 'react';
import * as Api from '../api/api';
import * as CarrierRatesPlatformStore from "../store/CarrierRatesPlatform";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import LocationSelect from "./LocationSelect";
import Button from './Button';

type CarrierRatesPlatformStatusProps = CarrierRatesPlatformStore.CarrierRatesPlatformState
    & CarrierRatesPlatformStatusOwnProps
    & typeof CarrierRatesPlatformStore.actionCreators;

interface CarrierRatesPlatformStatusOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
    criteriaLoaded: Api.CriteriaModel;
}

const labelStyle: React.CSSProperties = {
    marginRight: 5, minWidth: 100, textAlign: "right" 
}

class CarrierRatesPlatformStatus extends React.Component<CarrierRatesPlatformStatusProps, {}> {
    componentWillMount() {
        if (this.props.status.statusSearch.originPort
            && this.props.status.statusSearch.destinationPort) {
            this.props.requestCarrierRatesPlatform(new Date().getTime());
        }
    }
    public render() {
        return (
            <div style={{ padding: "0px 20px 40px 20px" }}>
                <div style={{ marginBottom: 40, fontSize: 20 }}>Carrier quotes status</div>
                <div style={{
                    marginBottom: 20,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                    rowGap: 10
                }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 6 }}>
                        <div style={labelStyle}>POL:</div>
                        <div>
                            <LocationSelect
                                value={this.props.status.statusSearch.originPort}
                                onChange={(value) => this.props.updateCarrierRatesPlatformOrigin(value)}
                                locationSearchType={"OnlyPorts"}
                                inputKey={"carrierRatesPlatformPol"}
                                inputStyle={{ height: 35 }} />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 6 }}>
                        <div style={labelStyle}>POD:</div>
                        <div>
                            <LocationSelect
                                value={this.props.status.statusSearch.destinationPort}
                                onChange={(value) => this.props.updateCarrierRatesPlatformDestination(value)}
                                locationSearchType={"OnlyPorts"}
                                inputKey={"carrierRatesPlatformPod"}
                                inputStyle={{ height: 35 }} />
                        </div>
                    </div>
                    <div>
                        <Button onClick={(e) => this.props.requestCarrierRatesPlatform(new Date().getTime())}
                            disabled={this.props.status.isLoading}>
                            Refresh
                        </Button>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                    <div style={{ minWidth: 100, textAlign: "left", fontWeight: "bold", fontSize: 20 }}>API:</div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "left", flexWrap: "wrap", rowGap: 10 }}>
                        {this.props.status.carrierRatesPlatforms.map(x => {
                            if (x.authenticationType === "Token") {
                                return (
                                    <div style={{
                                        flex: "0 0 auto",
                                        width: 150,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "left",
                                        margin: "0px 10px"
                                    }}>
                                        <div style={{ fontSize: 14 }}> {this.props.carriers[x.carrierId].name} </div>
                                        <div style={{
                                            width: 50,
                                            height: 16,
                                            borderRadius: 4,
                                            backgroundColor: "grey",
                                            ...(x.status === "Ok" ? { backgroundColor: "green" } : {}),
                                            ...(x.status === "NotStable" ? { backgroundColor: "yellow" } : {}),
                                            ...(x.status === "NotWorking" ? { backgroundColor: "red" } : {}),
                                        }}/>
                                    </div>);
                            }
                        }
                        )}
                    </div>
                    <div style={{ minWidth: 100, textAlign: "left", marginTop: 15, fontWeight: "bold", fontSize: 20 }}>WEB:</div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "left", flexWrap: "wrap", rowGap: 10 }}>
                        {this.props.status.carrierRatesPlatforms.map(x => {
                            if (x.authenticationType === "Credentials") {
                                return (
                                    <div style={{
                                        flex: "0 0 auto",
                                        width: 150,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "left",
                                        margin: "0px 10px"
                                    }}>
                                        <div style={{ fontSize: 14, }}> {this.props.carriers[x.carrierId].name} </div>
                                        <div style={{
                                            width: 50,
                                            height: 16,
                                            borderRadius: 4,
                                            backgroundColor: "grey",
                                            ...(x.status === "Ok" ? { backgroundColor: "green" } : {}),
                                            ...(x.status === "NotStable" ? { backgroundColor: "yellow" } : {}),
                                            ...(x.status === "NotWorking" ? { backgroundColor: "red" } : {}),
                                        }}></div>
                                    </div>);
                            }
                        }
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.carrierRatesPlatform,
    criteriaLoaded: state.selection.criteriaLoaded,
    carriers: state.seed.carriers
} as any), { ...CarrierRatesPlatformStore.actionCreators })(CarrierRatesPlatformStatus) as any