import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as FirmDocumentStore from '../store/FirmDocument';
import { ApplicationState } from '../store';
import ModeratorFirmDocumentEditForm from "./ModeratorFirmDocumentEditForm";
import Loader from './Loader';
import { Option } from "./Select";
import Table, { Column } from "./Table";
import { getFirmName } from '../utils/utils';
import * as Colors from "../styles/colors";
import { getText } from '../utils/langTexts';

type ModeratorFirmDocumentProps = ModeratorFirmDocumentOwnProps
    & FirmDocumentStore.FirmDocumentState
    & typeof FirmDocumentStore.actionCreators;

interface ModeratorFirmDocumentOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
    subscriptions: Array<Api.SubscriptionModel>;
}

const getColumns = (props: ModeratorFirmDocumentProps): Array<Column<Api.FirmDocumentModel>> => {
    return [
        {
            header: getText("MdtDocumentsCarrier"),
            id: 'carrier',
            accessor: (d: Api.FirmDocumentModel) => d.carrierId,
            cell: (d: any) => <div>
                {d ? props.carriers[d].name : ""}
            </div>
        },
        {
            header: getText("MdtDocumentsFor"),
            id: 'firm',
            accessor: (d: Api.FirmDocumentModel) => d.firm,
            cell: (d: any) => {
                let firm = d as Api.FirmModel;
                return <div>
                    {getFirmName(firm)}
                </div>
            }
        },
        {
            header: getText("MdtDocumentsName"),
            id: 'name',
            accessor: (d: Api.FirmDocumentModel) => d.name,
            cell: (d: any) => {
                return <div>
                    {d}
                </div>
            }
        },
        {
            header: '',
            id: 'actions',
            accessor: (d: Api.FirmDocumentModel) => d.firmDocumentId,
            cell: (d: any) => {
                return <div>
                    <span
                        className="hover-blue"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            props.requestDeleteFirmDocument(new Date().getTime(), d);
                            e.preventDefault();
                        }}>{getText("GenDelete")}</span>
                </div>
            }
        },
    ];
}

const formContainerStyle = { maxWidth: 500, marginTop: 20 };
const trStyle = { cursor: "pointer" as any }
const selectedTrStyle = { ...trStyle, backgroundColor: Colors.colors.main7.backgroundColor }

class ModeratorFirmDocument extends React.Component<ModeratorFirmDocumentProps, {}> {

    onPropsUpdate(props: ModeratorFirmDocumentProps) {
        if (new Date().getTime() - this.props.requestTime > 1000 * 60 * 10) {
            this.props.requestFirmDocuments(new Date().getTime());
        }
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: ModeratorFirmDocumentProps) {
        this.onPropsUpdate(nextProps);
    }

    get carriersOptions(): Array<Option> {
        return [{ label: "------", value: null }].concat(
            _.sortBy(_.values(this.props.carriers), x => x.name)
            .map(ca => ({ label: ca.name, value: ca.carrierId })));
    }

    public render() {
        return (
            <div>
                <h2>{getText("MdtDocuments")}</h2>
                <div>
                    <Table
                        tableKey={"modFirmDocuments"}
                        data={this.props.firmDocuments}
                        columns={getColumns(this.props)}
                        rowsPerPage={10}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        showPagination={true}
                        noDataText=""
                    />
                </div>
                <div>
                    <div style={formContainerStyle}>
                        <ModeratorFirmDocumentEditForm
                            onSubmit={(values) => {
                                return this.props.requestCreateFirmDocument(new Date().getTime(), values);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.firmDocument,
        carriers: state.seed.carriers,
        subscriptions: state.account.currentUser.clientModel.subscriptions
    } as any),
    FirmDocumentStore.actionCreators)(ModeratorFirmDocument) as any