import * as React from 'react';
import Tab from '@material-ui/core/Tab';
import CenteredTabs from "./CenteredTabs";
import * as TabsStore from "../store/Tabs";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import * as _ from 'lodash';

type CenteredContentTabsProps = TabsStore.TabsState
    & typeof TabsStore.actionCreators
    & CenteredContentTabsOwnProps
    & CenteredContentTabsExternalProps
    ;

interface CenteredContentTabsOwnProps {
}

interface CenteredContentTabsExternalProps {
    data: {
        title: string;
        titleIcon?: JSX.Element;
        disabled?: boolean;
        tabKey?: string;
        tabContent: JSX.Element;
    }[];
    centered?: boolean;
    tabKey?: string;
    defaultTab?: string;
}

interface CenteredContentTabsState {
    centered: boolean;
}

class CenteredContentTabs extends React.Component<CenteredContentTabsProps, CenteredContentTabsState> {
    guid: string;

    constructor(props) {
        super(props);
        this.guid = _.uniqueId();
        this.state = {
            centered: false,
        };
    }

    get _centered(): boolean {
        return this.props.centered
            || this.state.centered;
    }

    get tabKey(): string {
        return this.props.tabKey || this.guid;
    }

    get tabState(): TabsStore.TabState {
        let firstNotDisabled = _.sortBy(this.props.data, x => !x.disabled)[0];
        return this.props[this.tabKey]
            || {
            selectedTab: firstNotDisabled.tabKey
                    || this.props.data.indexOf(firstNotDisabled)
            };
    }

    render() {
        return (
            <div>
                <CenteredTabs selected={this.tabState.selectedTab || this.props.defaultTab}
                    onChange={(val) => this.props.selectTab(this.tabKey, val)}
                    centered={this._centered}>
                    {this.props.data.map((item, index) =>
                        <Tab key={index}
                            icon={item.titleIcon}
                            value={item.tabKey ? item.tabKey : index}
                            label={item.title}
                            //hidden={item.hidden}
                            disabled={item.disabled} />)}
                </CenteredTabs>
                {this.props.data.map((item, index) =>
                    <div key={index}
                        hidden={item.tabKey
                            ? item.tabKey !== this.tabState.selectedTab
                            : index !== this.tabState.selectedTab} >
                        {this.props.data[index].tabContent}
                    </div>
                )}
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.tabs } as any),
    TabsStore.actionCreators)(CenteredContentTabs) as any as React.ComponentClass<CenteredContentTabsExternalProps>;