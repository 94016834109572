import * as _ from 'lodash';
import * as Api from '../api/api';
import * as React from 'react';
import * as CarrierRatingStore from '../store/CarrierRating';
import * as TabsStore from "../store/Tabs";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import {CreateCarrierRatingForm, UpdateCarrierRatingForm } from "./CarrierRatingForm";
import Table, { Column } from './Table';
import { getFirmName } from '../utils/utils';
import CenteredContentTabs from './CenteredContentTabs';

type CarrierRatingsProps = CarrierRatingStore.CarrierRatingState
    & CarrierRatingsOwnProps
    & typeof TabsStore.actionCreators
    & typeof CarrierRatingStore.actionCreators;

interface CarrierRatingsOwnProps {
    firms: { [id: number]: Api.FirmModel };
}

type ContactColumn = Column<Api.CarrierRatingModel>;

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class CarrierRatings extends React.Component<CarrierRatingsProps, {}> {

    componentWillMount() {
        this.props.requestCarrierRatings(new Date().getTime());
    }

    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                <div style={{ flex: 1 }}>
                    <Table
                        tableKey={"carrierRatings"}
                        columns={[
                            {
                                id: "firm",
                                header: <div>Company/branch</div>,
                                accessor: x => getFirmName(this.props.firms[x.firmId]),
                            },
                            {
                                id: "level",
                                header: <div>Icon</div>,
                                accessor: x => x.icon,
                                cell: d => <div>
                                    <img src={"/images/carrierRating/" + d} height={14} />
                                </div>
                            },
                            {
                                id: "label",
                                header: <div>Label</div>,
                                accessor: x => x.label,
                            },
                            {
                                id: "actions",
                                header: "",
                                accessor: x => x.carrierRatingId,
                                cell: d => <div>
                                    <a onClick={(e) => {
                                        this.props.updateSelectedId(d);
                                        this.props.selectTab("carrierRatings", "edit");
                                    }}>edit</a>
                                </div>
                            },
                        ] as Array<ContactColumn>}
                        data={this.props.carrierRatings} />
                </div>
                <div style={{ flex: "0 0 auto" }}>
                    <CenteredContentTabs tabKey={"carrierRatings"}
                        data={[
                            {
                                tabKey: "create",
                                title: getText("GenCreate"),
                                tabContent: <div style={{ padding: 10 }}>
                                    <h3>{getText("GenCreate")}</h3>
                                    <div style={{ maxWidth: 1000 }}>
                                        <CreateCarrierRatingForm
                                            mode={"Create"}
                                            onSubmit={(values) => {
                                                return this.props.requestCreateCarrierRating(new Date().getTime(), values) as any;
                                            }} />
                                    </div>
                                </div>
                            },
                            {
                                tabKey: "edit",
                                title: getText("GenEdit"),
                                tabContent: <div style={{ padding: 10 }}>
                                    <h3>{getText("GenEdit")}</h3>
                                    <div style={{ maxWidth: 1000 }}>
                                        <UpdateCarrierRatingForm
                                            mode={"Update"}
                                            onSubmit={(values) => {
                                                return this.props.requestUpdateCarrierRating(new Date().getTime(), values) as any;
                                            }} />
                                    </div>
                                </div>
                            }
                        ]} />
                </div>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.carrierRating,
    firms: _.keyBy(state.account.currentUser.clientModel.subscriptions
        .filter(x => x.subscriptionType === "Okargo")
        .map(x => x.agency)
        .concat(state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency.company))
        .concat(state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency.company.companyGroup))
        .filter(x => x), x => x.firmId)
} as CarrierRatingsProps), {
        ...CarrierRatingStore.actionCreators,
        ...TabsStore.actionCreators
})(CarrierRatings) as any