import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import { Logout } from './Account';

export interface CarrierRatingState {
    isOpen: boolean;
    isLoading: boolean;
    requestTime?: number;
    carrierRatings: Array<Api.CarrierRatingModel>;
    carrierRatingsState: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    },
    createState: {
        isLoading: boolean;
        requestTime?: number;
    },
    selectedCarrierRatingId?: number;
}

interface RequestCarrierRatings {
    type: "REQUEST_CARRIER_RATINGS";
    payload: { requestTime: number; }
}
interface ReceiveCarrierRatings {
    type: "RECEIVE_CARRIER_RATINGS";
    payload: {
        requestTime: number;
        carrierRatings?: Array<Api.CarrierRatingModel>;
    };
    error?: any;
}

interface RequestCreateCarrierRating {
    type: "REQUEST_CREATE_CARRIER_RATING";
    payload: { requestTime: number; }
}
interface ReceiveCreateCarrierRating {
    type: "RECEIVE_CREATE_CARRIER_RATING";
    payload: {
        requestTime: number;
        carrierRating?: Api.CarrierRatingModel;
    };
    error?: any;
}

interface RequestUpdateCarrierRating {
    type: "REQUEST_UPDATE_CARRIER_RATING";
    payload: { requestTime: number; id: number; }
}
interface ReceiveUpdateCarrierRating {
    type: "RECEIVE_UPDATE_CARRIER_RATING";
    payload: {
        requestTime: number;
        id: number;
        carrierRating?: Api.CarrierRatingModel;
    };
    error?: any;
}

interface UpdateCarrierRatingSelected {
    type: "UPDATE_CARRIER_RATINGS_SELECTED";
    payload: { id: number };
}

interface OpenCarrierRatings {
    type: "OPEN_CARRIER_RATINGS";
}
interface CloseCarrierRatings {
    type: "CLOSE_CARRIER_RATINGS";
}

type KnownAction = RequestCarrierRatings
    | ReceiveCarrierRatings
    | RequestCreateCarrierRating
    | ReceiveCreateCarrierRating
    | RequestUpdateCarrierRating
    | ReceiveUpdateCarrierRating
    | UpdateCarrierRatingSelected
    | OpenCarrierRatings
    | CloseCarrierRatings
    | Logout;

export const actionCreators = {
    requestCarrierRatings: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CarrierRatingApi();
        let fetchTask = api.getEntities({
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        })
            .then(carrierRatings => {
                dispatch({
                    type: "RECEIVE_CARRIER_RATINGS",
                    payload: {
                        requestTime: requestTime,
                        carrierRatings: carrierRatings
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CARRIER_RATINGS",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CARRIER_RATINGS",
            payload: {
                requestTime: requestTime,
            }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestCreateCarrierRating: (requestTime: number, model: Api.CarrierRatingModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CarrierRatingApi();
        let fetchTask = api.create({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(carrierRating => {
                dispatch({
                    type: "RECEIVE_CREATE_CARRIER_RATING",
                    payload: {
                        requestTime: requestTime,
                        carrierRating: carrierRating
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_CARRIER_RATING",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CREATE_CARRIER_RATING",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requestUpdateCarrierRating: (requestTime: number, model: Api.CarrierRatingModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CarrierRatingApi();
        let data = {
            ...model,
            carrierRatingId: getState().carrierRating.selectedCarrierRatingId
        };
        let fetchTask = api.update({
            model: data
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(carrierRating => {
                dispatch({
                    type: "RECEIVE_UPDATE_CARRIER_RATING",
                    payload: {
                        requestTime: requestTime,
                        carrierRating: carrierRating,
                        id: data.carrierRatingId
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_CARRIER_RATING",
                    payload: {
                        requestTime: requestTime,
                        id: data.carrierRatingId
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_CARRIER_RATING",
            payload: {
                requestTime: requestTime,
                id: data.carrierRatingId
            }
        });
        return fetchTask;
    },
    updateSelectedId: (id: number) => <UpdateCarrierRatingSelected>{
        type: "UPDATE_CARRIER_RATINGS_SELECTED",
        payload: { id: id }
    },
    openCarrierRatings: () => <OpenCarrierRatings>{
        type: "OPEN_CARRIER_RATINGS"
    },
    closeCarrierRatings: () => <CloseCarrierRatings>{
        type: "CLOSE_CARRIER_RATINGS"
    }
}

const unloadedState: CarrierRatingState = {
    isOpen: false,
    isLoading: false,
    carrierRatings: [],
    createState: {
        isLoading: false
    },
    carrierRatingsState: {}
};


export const reducer: Reducer<CarrierRatingState> = (state: CarrierRatingState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CARRIER_RATINGS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime,
            };
        case "RECEIVE_CARRIER_RATINGS":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                carrierRatings: action.error
                    ? state.carrierRatings
                    : action.payload.carrierRatings
            };
        case "REQUEST_CREATE_CARRIER_RATING":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_CARRIER_RATING":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: false,
                    requestTime: action.payload.requestTime
                },
                carrierRatings: action.error
                    ? state.carrierRatings
                    : state.carrierRatings
                        .concat([action.payload.carrierRating])
            };
        case "REQUEST_UPDATE_CARRIER_RATING":
            return {
                ...state,
                carrierRatingsState: {
                    ...state.carrierRatingsState,
                    [action.payload.id]: {
                        ...state.carrierRatingsState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_UPDATE_CARRIER_RATING":
            if (state.carrierRatingsState[action.payload.id]
                && state.carrierRatingsState[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                carrierRatingsState: {
                    ...state.carrierRatingsState,
                    [action.payload.id]: {
                        ...state.carrierRatingsState[action.payload.id],
                        isLoading: false,
                    }
                },
                carrierRatings: action.error
                    ? state.carrierRatings
                    : state.carrierRatings.map(x => x.carrierRatingId === action.payload.id
                        ? action.payload.carrierRating
                        : x)
            };
        case "UPDATE_CARRIER_RATINGS_SELECTED":
            return {
                ...state,
                selectedCarrierRatingId: action.payload.id
            };
        case "OPEN_CARRIER_RATINGS":
            return {
                ...state,
                isOpen: true
            };
        case "CLOSE_CARRIER_RATINGS":
            return {
                ...state,
                isOpen: false
            };
        case "LOGOUT":
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
