import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import { AppThunkAction, ApplicationState } from './';
import { RequestState } from '../models/models';
import { getDefaultHeaders } from "../utils/utils";
import { getCriteriaCode } from '../utils/criteriaUtils';
import * as Notifications from 'react-notification-system-redux';
import { ReceiveCurrentUser } from "./Account";
import Download from "downloadjs";
import * as MimeTypes from "mime-types";


export interface MultiPortState {
    isLoading: boolean;
    requestTime: number;
    isVisible: boolean;
    isLoaded: boolean;
    isOpen: boolean;
    newName: string;
    multiPort: Api.MultiPortInstanceModel;
    addOfferState: RequestState;
    deleteOfferState: RequestState;
    downloadingOffersState: RequestState;
    updateMultiPortState: RequestState;
    deleteMultiPortState: RequestState;
}

interface RequestCurrentMultiPort {
    type: 'REQUEST_CURRENT_MULTIPORT';
    requestTime: number;
}
interface ReceiveCurrentMultiPort {
    type: 'RECEIVE_CURRENT_MULTIPORT';
    requestTime: number;
    multiPort: Api.MultiPortInstanceModel;
}

interface RequestCreateMultiPortOffers {
    type: 'REQUEST_CREATE_MULTIPORTOFFERS';
    payload: {
        requestTime: number;
        createMultiPortOffers: Api.CreateMultiPortOffersModel;
    }
}
interface ReceiveCreateMultiPortOffers {
    type: 'RECEIVE_CREATE_MULTIPORTOFFERS';
    payload: {
        requestTime: number;
        multiPortOffers: Array<Api.MultiPortOfferModel>;
    };
    error?: any;
}

interface RequestDeleteMultiPortOffer {
    type: 'REQUEST_DELETE_MULTIPORTOFFER';
    requestTime: number;
    multiPortOfferId: number;
}
interface ReceiveDeleteMultiPortOffer {
    type: 'RECEIVE_DELETE_MULTIPORTOFFER';
    requestTime: number;
    multiPortOfferId: number;
}

interface RequestDeleteMultiPortInstance {
    type: 'REQUEST_DELETE_MULTIPORTINSTANCE';
    payload: { requestTime: number; multiPortInstanceId: number; }
}
interface ReceiveDeleteMultiPortInstance {
    type: 'RECEIVE_DELETE_MULTIPORTINSTANCE';
    payload: { requestTime: number; multiPortInstanceId: number; }
    error?: any;
}

interface RequestDownloadMultiPort {
    type: "REQUEST_DOWNLOAD_MULTIPORT";
    requestTime: number;
    multiPortInstanceId: number;
}
interface ReceiveDownloadMultiPort {
    type: "RECEIVE_DOWNLOAD_MULTIPORT";
    requestTime: number;
    multiPortInstanceId: number;
}

interface ToggleMultiPortOpened {
    type: 'TOGGLE_MULTIPORT_OPENED';
    value: boolean;
}
interface ToggleMultiPortVisible {
    type: "TOGGLE_MULTIPORT_VISIBLE";
    value: boolean;
}
interface UpdateMultiPortName {
    type: 'UPDATE_MULTIPORT_NAME';
    value: string;
}
interface RequestUpdateMultiPort {
    type: "REQUEST_UPDATE_MULTIPORT";
    requestTime: number;
    multiPortInstanceId: number;
}
interface ReceiveUpdateMultiPort {
    type: "RECEIVE_UPDATE_MULTIPORT";
    requestTime: number;
    multiPortInstanceId: number;
}

export type KnownAction = RequestCurrentMultiPort | ReceiveCurrentMultiPort
    | RequestCreateMultiPortOffers | ReceiveCreateMultiPortOffers
    | ToggleMultiPortOpened | ToggleMultiPortVisible
    | RequestDeleteMultiPortOffer | ReceiveDeleteMultiPortOffer
    | RequestDownloadMultiPort | ReceiveDownloadMultiPort | ReceiveCurrentUser
    | UpdateMultiPortName | RequestUpdateMultiPort | ReceiveUpdateMultiPort
    |RequestDeleteMultiPortInstance
    | ReceiveDeleteMultiPortInstance
    ;

export const getCurrentMultiPort = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    if (requestTime === getState().multiPort.requestTime)
        return Promise.resolve();

    let api = new Api.MultiPortApi();
    let fetchTask = api.currentMultiPort({ credentials: "include", headers: getDefaultHeaders(getState()) })
        .then(result => {
            dispatch({ type: "RECEIVE_CURRENT_MULTIPORT", multiPort: result.result, requestTime: requestTime });
        }).catch(error => {
            dispatch({ type: "RECEIVE_CURRENT_MULTIPORT", multiPort: null, requestTime: requestTime });
            dispatch(Notifications.error({ message: "Error fetching current multiport", title: "Error", position: "tc" }) as any);
        });

    addTask(fetchTask);
    dispatch({ type: "REQUEST_CURRENT_MULTIPORT", requestTime: requestTime });
    return fetchTask;
};

export const actionCreators = {
    requestCurrentMultiPort: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return getCurrentMultiPort(requestTime, dispatch, getState);
    },
    requestCreateMultiPortOffers: (requestTime: number, createMultiPortOffers: Api.CreateMultiPortOffersModel):
        AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
            if (requestTime === getState().multiPort.addOfferState.requestTime)
                return Promise.resolve();

            var task = new Promise<void>((resolve, reject) => {
                getCriteriaCode(getState, getState().criteria.criteria, getState().selection.criteriaLoaded).then(criteriaCode => {
                    let api = new Api.MultiPortApi();
                    let fetchTask = api.addOffers({
                        model: {
                            ...createMultiPortOffers,
                            criteriaCode: criteriaCode
                        }
                    },
                        { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                        .then(multiPortOffers => {
                            dispatch({
                                type: "RECEIVE_CREATE_MULTIPORTOFFERS",
                                payload: { multiPortOffers: multiPortOffers, requestTime: requestTime }
                            });
                            resolve();
                        }).catch(error => {
                            dispatch({
                                type: "RECEIVE_CREATE_MULTIPORTOFFERS",
                                error: error,
                                payload: { multiPortOffers: null, requestTime: requestTime }
                            });
                            dispatch(Notifications.error({ message: "Error adding multi port offer", title: "Error", position: "tc" }) as any);
                            reject(error);
                        });
                }).catch(error => {
                    reject(error);
                    dispatch(Notifications.error({ message: "Error creating criteria", title: "Error", position: "tc" }) as any);
                });
            });

            dispatch({
                type: "REQUEST_CREATE_MULTIPORTOFFERS",
                payload: {
                    createMultiPortOffers: createMultiPortOffers,
                    requestTime: requestTime
                }
            });
            return task;
        },
    requestDeleteMultiPortOffer: (requestTime: number, multiPortOfferId: number):
        AppThunkAction<KnownAction, void> => (dispatch, getState) => {
            if (requestTime === getState().multiPort.deleteOfferState.requestTime)
                return;

            let api = new Api.MultiPortApi();
            let fetchTask = api.deleteOffer({ multiPortOfferId: multiPortOfferId },
                { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(value => {
                    dispatch({ type: "RECEIVE_DELETE_MULTIPORTOFFER", requestTime: requestTime, multiPortOfferId: multiPortOfferId });
                }).catch(error => {
                    dispatch({ type: "RECEIVE_DELETE_MULTIPORTOFFER", requestTime: requestTime, multiPortOfferId: 0 });
                    dispatch(Notifications.error({ message: "Error removing offer", title: "Error", position: "tc" }) as any);
                });
            dispatch({ type: "REQUEST_DELETE_MULTIPORTOFFER", requestTime: requestTime, multiPortOfferId: multiPortOfferId });
            addTask(fetchTask);
        },
    requestDeleteMultiPortInstance: (requestTime: number, multiPortInstanceId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.MultiPortApi();
        let fetchTask = api.deleteMultiPortInstance({ multiPortInstance: multiPortInstanceId },{ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_DELETE_MULTIPORTINSTANCE",
                    payload: {
                        requestTime: requestTime,
                        multiPortInstanceId: multiPortInstanceId
                    }
                });
            })
            .catch(error => {
                console.error(`Error: ${error.message}`);
                dispatch({
                    type: "RECEIVE_DELETE_MULTIPORTINSTANCE",
                    payload: {
                        requestTime: requestTime,
                        multiPortInstanceId: multiPortInstanceId
                    },
                    error: error
                });
            });

        dispatch({
            type: "REQUEST_DELETE_MULTIPORTINSTANCE",
            payload: {
                requestTime: requestTime,
                multiPortInstanceId: multiPortInstanceId
            }
        });
        return fetchTask;
    },
    requestDownloadMultiPort: (requestTime: number, multiPortInstanceId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().multiPort.downloadingOffersState.requestTime)
            return Promise.reject("Already requested");

        let api = new Api.MultiPortApi();
        let fetchTask = api.downloadMultiPorts({
            multiPortInstanceId: multiPortInstanceId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({
                    type: "RECEIVE_DOWNLOAD_MULTIPORT",
                    requestTime: requestTime,
                    multiPortInstanceId: multiPortInstanceId
                });
                let fileName = "Multiport_" + Moment().format("YYYY_MM_DD") + ".xlsx";
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            })
            .catch(error => {
                console.log("Error downloading multiport: " + error.message);
                //We unlock the loading state
                dispatch({
                    type: "RECEIVE_DOWNLOAD_MULTIPORT",
                    requestTime: requestTime,
                    multiPortInstanceId: null
                });
                dispatch(Notifications.error({
                    message: "Error downloading multiport file",
                    title: "Error",
                    position: "tc"
                }) as any);
            });

        dispatch({
            type: "REQUEST_DOWNLOAD_MULTIPORT",
            requestTime: requestTime,
            multiPortInstanceId: multiPortInstanceId
        });
        addTask(fetchTask);

        return fetchTask;
    },
    requestUpdateMultiPort: (requestTime: number, multiPortInstanceId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().multiPort.updateMultiPortState.requestTime)
            return Promise.reject("Already did");

        let api = new Api.MultiPortApi();
        let fetchTask = api.updateMultiPort({
            model: {
                multiPortInstanceId: multiPortInstanceId,
                name: getState().multiPort.newName
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({ type: "RECEIVE_UPDATE_MULTIPORT", multiPortInstanceId: multiPortInstanceId, requestTime: requestTime });
            })
            .catch(error => {
                dispatch(Notifications.error({
                    message: "Error updating multiport",
                    title: "Error",
                    position: "tc"
                }) as any);
                dispatch({ type: "RECEIVE_UPDATE_MULTIPORT", multiPortInstanceId: multiPortInstanceId, requestTime: requestTime });
            });

        dispatch({ type: "REQUEST_UPDATE_MULTIPORT", multiPortInstanceId: multiPortInstanceId, requestTime: requestTime });
        addTask(fetchTask);
        return fetchTask;
    },
    toggleMultiPortOpened: (value: boolean) => <ToggleMultiPortOpened>{ type: "TOGGLE_MULTIPORT_OPENED", value: value },
    updateMultiPortName: (value: string) => <UpdateMultiPortName>{ type: "UPDATE_MULTIPORT_NAME", value: value },
    toggleMultiPortVisible: (value: boolean): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: "TOGGLE_MULTIPORT_VISIBLE", value: value });
        if (!getState().multiPort.isLoaded && !getState().multiPort.isLoading) {
            getCurrentMultiPort(new Date().getTime(), dispatch, getState)
        }
    }
};

const unloadedState: MultiPortState = {
    isLoading: false,
    isOpen: false,
    isLoaded: false,
    requestTime: 0,
    multiPort: null,
    isVisible: false,
    newName: "",
    addOfferState: {
        isLoading: false,
        requestTime: 0
    },
    deleteOfferState: {
        isLoading: false,
        requestTime: 0
    },
    downloadingOffersState: {
        isLoading: false,
        requestTime: 0
    },
    updateMultiPortState: {
        isLoading: false,
        requestTime: 0
    },
    deleteMultiPortState: {
        isLoading: false,
        requestTime: 0
    }
};

export const reducer: Reducer<MultiPortState> = (state: MultiPortState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CURRENT_MULTIPORT":
            return {
                ...state,
                isLoading: true,
                requestTime: action.requestTime
            };
        case "RECEIVE_CURRENT_MULTIPORT":
            if (action.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                newName: action.multiPort
                    ? action.multiPort.name : "",
                multiPort: action.multiPort
            };
        case "REQUEST_CREATE_MULTIPORTOFFERS":
            return {
                ...state,
                addOfferState: {
                    ...state.addOfferState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_MULTIPORTOFFERS":
            return {
                ...state,
                multiPort: {
                    ...state.multiPort,
                    multiPortOffers: action.error
                        ? state.multiPort.multiPortOffers
                        : state.multiPort.multiPortOffers
                        .concat(action.payload.multiPortOffers)
                },
                addOfferState: {
                    ...state.addOfferState,
                    isLoading: action.payload.requestTime !== state.addOfferState.requestTime
                        ? state.addOfferState.isLoading : false
                }
            };
        case "TOGGLE_MULTIPORT_OPENED":
            return {
                ...state,
                isOpen: action.value
            };
        case "TOGGLE_MULTIPORT_VISIBLE":
            return {
                ...state,
                isVisible: action.value
            };
        case "REQUEST_DELETE_MULTIPORTOFFER":
            return {
                ...state,
                deleteOfferState: {
                    ...state.deleteOfferState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_DELETE_MULTIPORTOFFER":
            return {
                ...state,
                multiPort: {
                    ...state.multiPort,
                    multiPortOffers: state.multiPort.multiPortOffers
                        .filter(mo => mo.multiPortOfferId !== action.multiPortOfferId)
                },
                deleteOfferState: {
                    ...state.deleteOfferState,
                    isLoading: action.requestTime !== state.deleteOfferState.requestTime
                }
            };
        case "REQUEST_DOWNLOAD_MULTIPORT":
            return {
                ...state,
                downloadingOffersState: {
                    ...state.downloadingOffersState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_DOWNLOAD_MULTIPORT":
            if (action.requestTime !== state.downloadingOffersState.requestTime)
                return state;

            return {
                ...state,
                isOpen: false,
                multiPort: (state.multiPort && action.multiPortInstanceId === state.multiPort.multiPortInstanceId)
                    ? null : state.multiPort,
                downloadingOffersState: {
                    ...state.downloadingOffersState,
                    isLoading: false
                }
            };
        case "RECEIVE_CURRENT_USER":
            if (action.error)
                return state;

            return {
                ...state,
                isVisible: action.payload.currentUser.clientModel.defaultMultiPorts
            };
        case "UPDATE_MULTIPORT_NAME":
            return {
                ...state,
                newName: action.value
            };
        case "REQUEST_UPDATE_MULTIPORT":
            return {
                ...state,
                updateMultiPortState: {
                    ...state.updateMultiPortState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_UPDATE_MULTIPORT":
            if (action.requestTime !== state.updateMultiPortState.requestTime)
                return state;

            return {
                ...state,
                updateMultiPortState: {
                    ...state.updateMultiPortState,
                    isLoading: false
                }
            };
        case "REQUEST_DELETE_MULTIPORTINSTANCE":
            return {
                ...state,
                deleteMultiPortState: {
                    ...state.deleteMultiPortState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_DELETE_MULTIPORTINSTANCE":
            if (action.payload.requestTime !== state.deleteMultiPortState.requestTime)
                return state;

            return {
                ...state,
                multiPort: action.error 
                    ? state.multiPort 
                    : null,
                deleteMultiPortState: {
                    ...state.deleteMultiPortState,
                    isLoading: false
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};