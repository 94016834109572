import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import * as Notifications from 'react-notification-system-redux';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import { ReceiveCurrentUser } from './Account';
import { ReceiveSelection } from "./Selection";

export interface SellingRatesState {
    requestTime?: number;
    isLoading: boolean;
    sellingRates: Array<Api.SellingRatesModel>;
    selectionSellingRates: Array<Api.SellingRatesModel>;
    createState: {
        isLoading: boolean;
        requestTime?: number;
    },
    updateState: {
        isLoading: boolean;
        requestTime?: number;
    },
    deleteState: {
        isLoading: boolean;
        requestTime?: number;
    },
    selectionSellingRatesState: {
        isLoading: boolean;
        requestTime?: number;
    },
    selectedSellingRatesId?: number;
}

interface RequestSellingRates {
    type: "REQUEST_SELLINGRATES";
    payload: { requestTime: number; };
}
interface ReceiveSellingRates {
    type: "RECEIVE_SELLINGRATES";
    payload: {
        requestTime: number;
        sellingRateses?: Array<Api.SellingRatesModel>
    };
    error?: any;
}

interface RequestSelectionSellingRates {
    type: "REQUEST_SELECTION_SELLINGRATES";
    payload: { requestTime: number; };
}
interface ReceiveSelectionSellingRates {
    type: "RECEIVE_SELECTION_SELLINGRATES";
    payload: {
        requestTime: number;
        sellingRateses?: Array<Api.SellingRatesModel>
    };
    error?: any;
}

interface RequestCreateSellingRates {
    type: "REQUEST_CREATE_SELLINGRATES";
    payload: { requestTime: number; };
}
interface ReceiveCreateSellingRates {
    type: "RECEIVE_CREATE_SELLINGRATES";
    payload: {
        requestTime: number;
        sellingRates?: Api.SellingRatesModel
    };
    error?: any;
}

interface RequestUpdateSellingRates {
    type: "REQUEST_UPDATE_SELLINGRATES";
    payload: { requestTime: number; };
}
interface ReceiveUpdateSellingRates {
    type: "RECEIVE_UPDATE_SELLINGRATES";
    payload: {
        requestTime: number;
        sellingRates?: Api.SellingRatesModel
    };
    error?: any;
}

interface RequestDeleteSellingRates {
    type: "REQUEST_DELETE_SELLINGRATES";
    payload: { requestTime: number; };
}
interface ReceiveDeleteSellingRates {
    type: "RECEIVE_DELETE_SELLINGRATES";
    payload: {
        requestTime: number;
        sellingRatesId: number
    };
    error?: any;
}

interface SellingRatesUpdateSelected {
    type: "SELLINGRATES_UPDATE_SELECTED";
    payload: { id: number; };
}

type KnownAction = ReceiveCurrentUser
    | RequestSellingRates
    | ReceiveSellingRates
    | RequestCreateSellingRates
    | ReceiveCreateSellingRates
    | RequestUpdateSellingRates
    | ReceiveUpdateSellingRates
    | RequestDeleteSellingRates
    | ReceiveDeleteSellingRates
    | SellingRatesUpdateSelected
    | RequestSelectionSellingRates
    | ReceiveSelectionSellingRates
    | ReceiveSelection;

export const requestSelectionSellingRates = (requestTime: number, model: Api.CriteriaModel, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<void> => {
    let api = new Api.SellingRatesApi();
    let task = api.getByTraffic({
        model: model
    }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(sellingRates => {
            dispatch({
                type: "RECEIVE_SELECTION_SELLINGRATES",
                payload: {
                    requestTime: requestTime,
                    sellingRateses: sellingRates
                }
            });
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_SELECTION_SELLINGRATES",
                payload: {
                    requestTime: requestTime,
                },
                error: err
            });
            dispatch(Notifications.error({ message: "Error searching selling rates", title: "Error", position: "tc" }) as any);
        });

    dispatch({
        type: "REQUEST_SELECTION_SELLINGRATES",
        payload: { requestTime: requestTime }
    });
    return task;
}

export const actionCreators = {
    requestSellingRates: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SellingRatesApi();
        let task = api.getAll({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(sellingRates => {
                dispatch({
                    type: "RECEIVE_SELLINGRATES",
                    payload: {
                        requestTime: requestTime,
                        sellingRateses: sellingRates
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_SELLINGRATES",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
                dispatch(Notifications.error({ message: "Error searching selling rates", title: "Error", position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_SELLINGRATES",
            payload: { requestTime: requestTime }
        });
        addTask(task);
        return task;
    },
    requestSelectionSellingRates: (requestTime: number, model: Api.CriteriaModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestSelectionSellingRates(requestTime, model, dispatch, getState);
    },
    requestCreateSellingRates: (requestTime: number, model: Api.SellingRatesModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SellingRatesApi();
        let task = api.create({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(sellingRates => {
                dispatch({
                    type: "RECEIVE_CREATE_SELLINGRATES",
                    payload: {
                        requestTime: requestTime,
                        sellingRates: sellingRates
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_SELLINGRATES",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
                dispatch(Notifications.error({ message: "Error creating selling rates", title: "Error", position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_CREATE_SELLINGRATES",
            payload: { requestTime: requestTime }
        });
        addTask(task);
        return task;
    },
    requestUpdateSellingRates: (requestTime: number, model: Api.SellingRatesModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SellingRatesApi();
        let task = api.update({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(sellingRates => {
                dispatch({
                    type: "RECEIVE_UPDATE_SELLINGRATES",
                    payload: {
                        requestTime: requestTime,
                        sellingRates: sellingRates
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_SELLINGRATES",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
                dispatch(Notifications.error({ message: "Error creating selling rates", title: "Error", position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_UPDATE_SELLINGRATES",
            payload: { requestTime: requestTime }
        });
        addTask(task);
        return task;
    },
    requestDeleteSellingRates: (requestTime: number, sellingRatesId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SellingRatesApi();
        let task = api._delete({
            sellingRatesId: sellingRatesId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_DELETE_SELLINGRATES",
                    payload: {
                        requestTime: requestTime,
                        sellingRatesId: sellingRatesId
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DELETE_SELLINGRATES",
                    payload: {
                        requestTime: requestTime,
                        sellingRatesId: sellingRatesId
                    },
                    error: err
                });
                dispatch(Notifications.error({ message: "Error deleting selling rates", title: "Error", position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_DELETE_SELLINGRATES",
            payload: { requestTime: requestTime }
        });
        addTask(task);
        return task;
    },
    sellingRatesUpdateSelected: (id: number) => <SellingRatesUpdateSelected>{ type: "SELLINGRATES_UPDATE_SELECTED", payload: { id: id } }
};

const unloadedState: SellingRatesState = {
    isLoading: false,
    sellingRates: [],
    selectionSellingRates: [],
    createState: {
        isLoading: false
    },
    updateState: {
        isLoading: false
    },
    deleteState: {
        isLoading: false
    },
    selectionSellingRatesState: {
        isLoading: false
    }
};

export const reducer: Reducer<SellingRatesState> = (state: SellingRatesState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_SELLINGRATES":
            return {
                ...state,
                requestTime: action.payload.requestTime,
                isLoading: true
            };
        case "RECEIVE_SELLINGRATES":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                sellingRates: action.error
                    ? state.sellingRates
                    : action.payload.sellingRateses
            };
        case "REQUEST_SELECTION_SELLINGRATES":
            return {
                ...state,
                selectionSellingRatesState: {
                    ...state.selectionSellingRatesState,
                    isLoading: false,
                    requestTime: action.payload.requestTime
                },
                selectionSellingRates: []
            };
        case "RECEIVE_SELECTION_SELLINGRATES":
            if (action.payload.requestTime !== state.selectionSellingRatesState.requestTime)
                return state;

            return {
                ...state,
                selectionSellingRatesState: {
                    ...state.selectionSellingRatesState,
                    isLoading: false
                },
                selectionSellingRates: action.error
                    ? state.selectionSellingRates
                    : action.payload.sellingRateses
            };
        case "REQUEST_CREATE_SELLINGRATES":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_SELLINGRATES":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: false,
                },
                sellingRates: action.error
                    ? state.sellingRates
                    : state.sellingRates
                    .concat([action.payload.sellingRates])
            };
        case "REQUEST_UPDATE_SELLINGRATES":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_SELLINGRATES":
            return {
                ...state,
                deleteState: {
                    ...state.deleteState,
                    isLoading: false,
                },
                sellingRates: action.error
                    ? state.sellingRates
                    : state.sellingRates
                        .map(x => x.sellingRatesId === action.payload.sellingRates.sellingRatesId
                            ? { ...x, ...action.payload.sellingRates }
                            : x)
            };
        case "REQUEST_DELETE_SELLINGRATES":
            return {
                ...state,
                deleteState: {
                    ...state.deleteState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_DELETE_SELLINGRATES":
            return {
                ...state,
                deleteState: {
                    ...state.deleteState,
                    isLoading: false,
                },
                sellingRates: action.error
                    ? state.sellingRates
                    : state.sellingRates
                        .filter(x => x.sellingRatesId !== action.payload.sellingRatesId)
            };
        case "SELLINGRATES_UPDATE_SELECTED":
            return {
                ...state,
                selectedSellingRatesId: action.payload.id
            };
        case "RECEIVE_SELECTION":
            return {
                ...state,
                selectionSellingRates: [],
            };
        case "RECEIVE_CURRENT_USER":
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
