import * as React from 'react';
import * as Api from '../api/api';
import { getChargeAmount } from '../utils/utils';
import { RatesCalculator } from '../utils/ratesUtils';
import { frtId } from '../utils/constants';
import { getText } from '../utils/langTexts';

interface ChargeDisplayProps {
    showUnit?: boolean;
    chargeSet: Api.ChargeSetModel;
    charge: Api.ChargeModel;
    sizeType?: Api.SizeTypeModel;
    currencies: { [id: number]: Api.CurrencyModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    ratesCalculator: RatesCalculator;
    sellingRatesValue?: Api.SellingRatesValueModel;
}

export const getPaymentOptionLabel = (paymentOption: Api.ChargeModelPaymentOptionEnum): string => {
    if (paymentOption === "Collect")
        return "COLLECT";

    if (paymentOption === "Prepaid")
        return "PREPAID";

    return "";
}

const chargeLabelStyle: React.CSSProperties = {
    minWidth: 200,
    textAlign: "right",
    marginRight: 20,
    fontWeight: 700
};

const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0px 0px"
};

interface ChargeListDisplayProps {
    showUnit?: boolean;
    chargeSet: Api.ChargeSetModel;
    charges: Api.ChargeModel[];
    sizeType?: Api.SizeTypeModel;
    currencies: { [id: number]: Api.CurrencyModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    ratesCalculator: RatesCalculator;
    sellingRatesValue?: Api.SellingRatesValueModel;
    style?: React.CSSProperties;
}

class ChargeDisplay extends React.Component<ChargeDisplayProps, {}> {

    get sourceCharge(): Api.ChargeModel {
        return this.props.ratesCalculator
            .findChargesToApplybySizeId(this.props.chargeSet, this.props.sizeType ? this.props.sizeType.sizeTypeId : 1)
            .find(x => x.chargeNameId === this.props.charge.sourceChargeNameId);
    }

    public render() {

        var showMinUnit = this.props.chargeSet.ratesUnitType == 'Lcl';
        var hasMultipleUnits = this.props.chargeSet.charges
            .some(x => (x.chargeId !== this.props.charge.chargeId
                && x.application === this.props.charge.application
                && x.chargeNameId === this.props.charge.chargeNameId
                && x.unit !== this.props.charge.unit)
                || this.props.showUnit);

        if (hasMultipleUnits &&  [284,65,66,67,233,235].includes(  this.props.charge.chargeNameId )  ) { ///NES 284

            hasMultipleUnits = false;
        }

        var unitForCalculation = this.props.chargeSet.ratesUnitType == 'Lcl' ? Math.max(this.props.ratesCalculator.criteria.cbm, this.props.ratesCalculator.criteria.ton)  : 1;

        return <div key={this.props.charge.chargeId} style={{ ...fieldStyle }}>
            <div style={{ ...chargeLabelStyle }}>
                {getPaymentOptionLabel(this.props.charge.paymentOption)
                    && `(${getPaymentOptionLabel(this.props.charge.paymentOption)}) `}
                {this.props.charge.chargeNameId
                    ? this.props.chargeNames[this.props.charge.chargeNameId].shortName
                    : this.props.charge.name}:
            </div>
            <div>
                {this.props.charge.chargeType == "Value"
                    && (<span>
                        {getChargeAmount(
                            this.props.charge,
                            this.props.sizeType ? this.props.sizeType.teu : unitForCalculation)}
                    {' '}{this.props.currencies[this.props.charge.currencyId].code}
                    {hasMultipleUnits
                        && (<span>
                        {' ('}{this.props.chargeSet.charges
                            .filter(x => x.chargeNameId === this.props.charge.chargeNameId
                                && x.chargeType === "Value"
                                && x.application === this.props.charge.application)
                            .map(x =>
                                x.amount
                                + " "
                                + this.props.currencies[x.currencyId].code
                                + " "
                                + getText("GenPerUnit", { unit: x.unit === "Decl" ? "Declaration" : x.unit }))
                            .join(" / ")}

                        {!showMinUnit &&  ")"}
                        </span>)}
                        { showMinUnit
                        && (<span>
                        {!hasMultipleUnits && ` (${this.props.charge.amount} ${this.props.currencies[this.props.charge.currencyId].code}/${this.props.charge.unit}${(this.props.charge.minUnit ? `, min ${this.props.charge.minUnit}/${this.props.charge.unit}` :'')}`}   
                        {hasMultipleUnits && 
                            ', min ' + this.props.chargeSet.charges
                                .filter(x => x.chargeNameId === this.props.charge.chargeNameId
                                    && x.chargeType === "Value"
                                    && x.application === this.props.charge.application)
                                .map(x =>
                                    (x.minUnit ? x.minUnit : 0) +' '+  x.unit
                                  )
                                .join(" / ")
                        }
                        {")"}
                            </span>)}
                        {this.props.sellingRatesValue
                            && (<span style={{ fontWeight: 'bold' }}>
                                {' / '}<span>{this.props.sellingRatesValue.sellingMargin
                                    + getChargeAmount(
                                        this.props.charge,
                                        this.props.sizeType ? this.props.sizeType.teu : 1)}</span>
                                {' '}{this.props.currencies[this.props.sellingRatesValue.currencyId].code}
                            </span>)}
                    </span>)}
                {this.props.charge.chargeType == "Source"
                    && (<span>{this.props.charge.type == "Incl"
                        ? <span>{getText("GenIncluded", { value: this.props.chargeNames[this.props.charge.sourceChargeNameId].shortName })}</span>
                        : <span>
                            {this.sourceCharge
                                && <span>
                                    {Math.round(getChargeAmount(this.sourceCharge,
                                        this.props.sizeType ? this.props.sizeType.teu : 1) * 100 * this.props.charge.amount) / 100}
                                    {" "}
                                    {this.props.currencies[this.sourceCharge.currencyId].code}</span>}
                            {" "}
                            {(Math.round(this.props.charge.amount * 1000) / 10)}% of {this.props.chargeNames[this.props.charge.sourceChargeNameId].shortName}</span>}
                        </span>)}
            </div>
        </div>
    }
}


export class ChargeListDisplay extends React.Component<ChargeListDisplayProps, {}> {
    public render() {
        return <div style={{ ...this.props.style }}>
            {this.props.charges.length !== 0
                ? this.props.charges.map(ch =>
                    <ChargeDisplay key={ch.chargeId} charge={ch}
                        chargeSet={this.props.chargeSet}
                        showUnit={this.props.showUnit}
                        sizeType={this.props.sizeType}
                        currencies={this.props.currencies}
                        chargeNames={this.props.chargeNames}
                        ratesCalculator={this.props.ratesCalculator}
                        sellingRatesValue={ch.chargeNameId === frtId
                            ? this.props.sellingRatesValue
                            : undefined} />)
                : <div style={{ ...fieldStyle }}>
                    <div style={{ ...chargeLabelStyle }}></div>
                    <div style={{ textAlign: "right" }}>{getText("SlcOfferRatesNoRates")}</div>
                </div>}
        </div>
    }
}