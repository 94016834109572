import * as React from 'react';
import * as Api from '../api/api';
import { getText } from '../utils/langTexts';
import Linkify from "react-linkify";
import * as Colors from "../styles/colors";
import { Warning } from '@material-ui/icons';

interface InlandOfferRatesOfferProps {
    inlandChargeSet: Api.InlandChargeSetModel;
    inlandRatesOffer: Api.InlandRatesOfferModel;
    offerInformations: Array<Api.OfferInformationModel>;
}

const fieldStyle = { marginBottom: 10, marginTop: 10 };
const containerStyle = {};
const innerStyle = { padding: 10, };
const labelStyle = { marginRight: 3 };

export default class InlandOfferRatesOffer extends React.Component<InlandOfferRatesOfferProps, {}> {
    constructor(props) {
        super(props);
    }

    public render() {
        return <div style={containerStyle}>
            <div style={innerStyle}>
                <div style={fieldStyle}>
                    <label style={labelStyle}>{getText("SlcOfferRatesReference")}</label>
                    <span>{(this.props.inlandChargeSet && this.props.inlandChargeSet.reference) ||
                        (this.props.inlandRatesOffer && this.props.inlandRatesOffer.reference)}</span>
                </div>
                <div style={fieldStyle}>
                    <label style={labelStyle}>{getText("SlcOfferRatesShortName")}</label>
                    <span>{this.props.inlandRatesOffer && this.props.inlandRatesOffer.shortName
                        ? this.props.inlandRatesOffer.shortName
                        : <i>not defined</i>}</span>
                </div>
                <div style={fieldStyle}>
                    <div>
                        <label style={labelStyle}>{getText("SlcOfferRatesDescription")}</label>
                    </div>
                    <div style={{ whiteSpace: "pre-wrap" }}>
                        {this.props.inlandRatesOffer && this.props.inlandRatesOffer.comment
                            ? <Linkify properties={{ target: "_blank" }}>{this.props.inlandRatesOffer.comment}</Linkify>
                            : <i>not defined</i>}
                    </div>
                </div>
                {this.props.offerInformations && this.props.offerInformations
                    .map(x =>
                        <div style={fieldStyle} key={x.offerInformationId}>
                            <div>
                                <label style={labelStyle}>
                                    {x.level === "High" && <Warning style={{ marginRight: 3, fontSize: 12, color: Colors.baseColors.lightBlue }} />}
                                    {getText("SlcOfferRatesSpecificInfoTitle")}
                                </label>
                            </div>
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                <Linkify properties={{ target: "_blank" }}>{x.content}</Linkify>
                            </div>
                        </div>)}
            </div>
        </div>
    }
}