import { fetch } from 'domain-task';
import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import { AppThunkAction, ApplicationState } from './';
import { RequestState } from '../models/models';
import { getDefaultHeaders } from "../utils/utils";
import { getCriteriaCode } from '../utils/criteriaUtils';
import * as Notifications from 'react-notification-system-redux';
import { ReceiveCurrentUser } from "./Account";
import Download from "downloadjs";
import * as MimeTypes from "mime-types";


export interface MultiPortState {
    isLoading: boolean;
    requestTime: number;
    isDeleteLoading: boolean;
    deleteRequestTime?: number;
    searchMultiPorts: Api.SearchMultiPortsModel;
    multiPorts: Array<Api.MultiPortInstanceModel>;
    downloadRecentState: DownloadRecentState;
    updateMutiPortState: {
        isLoading: boolean;
        requestTime?: number;
    };
    updateName: string;
}

interface DownloadRecentState {
    isLoading: boolean;
    requestTime: number;
    downloadRecentModel: Api.DownloadRecentMultiPortModel;
}

interface UpdateMultiPortFromDate {
    type: "UPDATE_MULTIPORT_FROMDATE";
    value: Date;
}

interface RequestMultiPortInstances {
    type: "REQUEST_MULTIPORT_INSTANCES";
    requestTime: number;
}

interface ReceiveMultiPortInstances {
    type: "RECEIVE_MULTIPORT_INSTANCES";
    requestTime: number;
    multiPorts: Array<Api.MultiPortInstanceModel>;
}

interface RequestDownloadRecentMultiPort {
    type: "REQUEST_DOWNLOAD_RECENT_MULTIPORT";
    requestTime: number;
}

interface ReceiveDownloadRecentMultiPort {
    type: "RECEIVE_DOWNLOAD_RECENT_MULTIPORT";
    requestTime: number;
}

interface SelectHistoryMultiPort {
    type: "SELECT_HISTORY_MULTIPORT";
    multiPortInstanceId: number;
}

interface UpdateDownloadRecentDateBegin {
    type: "UPDATE_DOWNLOAD_RECENT_DATEBEGIN";
    value: Date;
}

interface UpdateDownloadRecentDateEnd {
    type: "UPDATE_DOWNLOAD_RECENT_DATEEND";
    value: Date;
}

interface UpdateHisoryMultiPortName {
    type: "UPDATE_HISTORY_MULTIPORT_NAME";
    payload: { value: string };
}

interface RequestUpdateHistoryMultiPort {
    type: "REQUEST_UPDATE_HISTORY_MULTIPORT";
    payload: {
        multiPortInstanceId: number;
        requestTime: number;
    }
}

interface ReceiveUpdateHistoryMultiPort {
    type: "RECEIVE_UPDATE_HISTORY_MULTIPORT";
    payload: {
        multiPortInstanceId: number;
        requestTime: number;
        name: string;
    };
    error?: any;
}

interface RequestDeleteMultiPortInstance {
    type: "REQUEST_DELETE_MULTIPORT_INSTANCE_HST";
    payload: {
        multiPortInstanceId: number;
        requestTime: number;
    };
}
interface ReceiveDeleteMultiPortInstance {
    type: "RECEIVE_DELETE_MULTIPORT_INSTANCE_HST";
    payload: {
        multiPortInstanceId: number;
        requestTime: number;
    };
    error?: any;
}

interface UpdateHisoryMultiPortName {
    type: "UPDATE_HISTORY_MULTIPORT_NAME";
    payload: { value: string };
}

export type KnownAction = RequestMultiPortInstances | ReceiveMultiPortInstances
    | UpdateMultiPortFromDate | SelectHistoryMultiPort
    | UpdateDownloadRecentDateBegin | UpdateDownloadRecentDateEnd
    | RequestDownloadRecentMultiPort | ReceiveDownloadRecentMultiPort
    | RequestUpdateHistoryMultiPort | ReceiveUpdateHistoryMultiPort
    | UpdateHisoryMultiPortName
    | RequestDeleteMultiPortInstance
    | ReceiveDeleteMultiPortInstance;

export const actionCreators = {
    requestSearchMultiPorts: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().multiPortHistory.requestTime)
            return Promise.reject("Already did");

        let api = new Api.MultiPortApi();
        let fetchTask = api.searchMultiPorts({
            model: getState().multiPortHistory.searchMultiPorts
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(multiPorts => {
                dispatch({ type: "RECEIVE_MULTIPORT_INSTANCES", requestTime: requestTime, multiPorts: multiPorts });
            }).catch(error => {
                dispatch({ type: "RECEIVE_MULTIPORT_INSTANCES", requestTime: requestTime, multiPorts: [] });
                dispatch(Notifications.error({
                    message: "Error searching your multiports",
                    title: "Error",
                    position: "tc"
                }) as any);
            });

        dispatch({ type: "REQUEST_MULTIPORT_INSTANCES", requestTime: requestTime });
        addTask(fetchTask);
        return fetchTask;
    },
    requestDownloadRecentMultiPort: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().multiPortHistory.requestTime)
            return Promise.reject("Already did");

        let model = getState().multiPortHistory.downloadRecentState.downloadRecentModel;
        if (!model.multiPortInstanceId)
            return Promise.reject("No multiport selected");

        let api = new Api.MultiPortApi();
        let fetchTask = api.downloadRecentMultiPort({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({
                    type: "RECEIVE_DOWNLOAD_RECENT_MULTIPORT",
                    requestTime: requestTime
                });
                let fileName = "Multiport_" + Moment().format("YYYY_MM_DD") + ".xlsx";
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            })
            .catch(error => {
                console.log("Error downloading multiport: " + error.message);
                //We unlock the loading state
                dispatch({
                    type: "RECEIVE_DOWNLOAD_RECENT_MULTIPORT",
                    requestTime: requestTime
                });
                dispatch(Notifications.error({
                    message: "Error downloading multiport file",
                    title: "Error",
                    position: "tc"
                }) as any);
            });

        dispatch({ type: "REQUEST_DOWNLOAD_RECENT_MULTIPORT", requestTime: requestTime });
        addTask(fetchTask);
        return fetchTask;
    },
    updateHisoryFromDate: (value: Date) => <UpdateMultiPortFromDate>{ type: "UPDATE_MULTIPORT_FROMDATE", value: value },
    updateDownloadRecentDateBegin: (value: Date) => <UpdateDownloadRecentDateBegin>{ type: "UPDATE_DOWNLOAD_RECENT_DATEBEGIN", value: value },
    updateDownloadRecentDateEnd: (value: Date) => <UpdateDownloadRecentDateEnd>{ type: "UPDATE_DOWNLOAD_RECENT_DATEEND", value: value },
    selectHistoryMultiPort: (multiPortInstanceId: number) => <SelectHistoryMultiPort>{
        type: "SELECT_HISTORY_MULTIPORT", multiPortInstanceId: multiPortInstanceId
    },
    updateHistoryMultiPortName: (value: string) => <UpdateHisoryMultiPortName>{
        type: "UPDATE_HISTORY_MULTIPORT_NAME",
        payload: { value: value }
    },
    requestUpdateHistoryMultiPort: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.MultiPortApi();
        let multiPortInstanceId = getState()
            .multiPortHistory
            .downloadRecentState
            .downloadRecentModel
            .multiPortInstanceId;
        let name = getState().multiPortHistory.updateName;
        let fetchTask = api.updateMultiPort(
            {
                model: {
                    multiPortInstanceId: multiPortInstanceId,
                    name: name
                }
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_UPDATE_HISTORY_MULTIPORT",
                    payload: {
                        multiPortInstanceId: multiPortInstanceId,
                        name: name,
                        requestTime: requestTime
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_HISTORY_MULTIPORT",
                    payload: {
                        multiPortInstanceId: multiPortInstanceId,
                        name: name,
                        requestTime: requestTime
                    },
                    error: err
                });
                console.error(err);
            });

        dispatch({
            type: "REQUEST_UPDATE_HISTORY_MULTIPORT",
            payload: {
                multiPortInstanceId: multiPortInstanceId,
                requestTime: requestTime
            }
        });
        return fetchTask;
    },
    requestDeleteMultiPortInstanceHst: (requestTime: number, multiPortInstanceId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.MultiPortApi();
        let fetch = api.deleteMultiPortInstance({
            multiPortInstance: multiPortInstanceId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_DELETE_MULTIPORT_INSTANCE_HST",
                    payload: {
                        multiPortInstanceId: multiPortInstanceId,
                        requestTime: requestTime
                    }
                });
            }).catch(err => {
                dispatch({
                    type: "RECEIVE_DELETE_MULTIPORT_INSTANCE_HST",
                    payload: {
                        multiPortInstanceId: multiPortInstanceId,
                        requestTime: requestTime
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_DELETE_MULTIPORT_INSTANCE_HST",
            payload: {
                multiPortInstanceId: multiPortInstanceId,
                requestTime: requestTime
            }
        });
        return fetch;
    }

};

const unloadedState: MultiPortState = {
    isLoading: false,
    requestTime: 0,
    isDeleteLoading: false,
    multiPorts: [],
    searchMultiPorts: {
        fromDate: Moment().add("month", -1).toDate()
    },
    downloadRecentState: {
        downloadRecentModel: {
            dateBegin: Moment().add("months", 0).toDate(),
            dateEnd: Moment().add("months", 1).toDate()
        },
        isLoading: false,
        requestTime: 0
    },
    updateName: "",
    updateMutiPortState: {
        isLoading: false,
    }
};

export const reducer: Reducer<MultiPortState> = (state: MultiPortState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_MULTIPORT_INSTANCES":
            return {
                ...state,
                requestTime: action.requestTime,
                isLoading: true
            };
        case "RECEIVE_MULTIPORT_INSTANCES":
            if (action.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                multiPorts: action.multiPorts
            };
        case "UPDATE_MULTIPORT_FROMDATE":
            return {
                ...state,
                searchMultiPorts: {
                    ...state.searchMultiPorts,
                    fromDate: action.value
                }
            };
        case "REQUEST_DOWNLOAD_RECENT_MULTIPORT":
            return {
                ...state,
                downloadRecentState: {
                    ...state.downloadRecentState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_DOWNLOAD_RECENT_MULTIPORT":
            if (state.downloadRecentState.requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                downloadRecentState: {
                    ...state.downloadRecentState,
                    isLoading: false
                }
            };
        case "SELECT_HISTORY_MULTIPORT":
            return {
                ...state,
                downloadRecentState: {
                    ...state.downloadRecentState,
                    downloadRecentModel: {
                        ...state.downloadRecentState.downloadRecentModel,
                        multiPortInstanceId: action.multiPortInstanceId
                    }
                },
                updateName: state.multiPorts.some(x => x.multiPortInstanceId === action.multiPortInstanceId)
                    ? state.multiPorts.find(x => x.multiPortInstanceId === action.multiPortInstanceId).name
                    : ""
            }
        case "UPDATE_DOWNLOAD_RECENT_DATEBEGIN":
            return {
                ...state,
                downloadRecentState: {
                    ...state.downloadRecentState,
                    downloadRecentModel: {
                        ...state.downloadRecentState.downloadRecentModel,
                        dateBegin: action.value
                    }
                }
            };
        case "UPDATE_DOWNLOAD_RECENT_DATEEND":
            return {
                ...state,
                downloadRecentState: {
                    ...state.downloadRecentState,
                    downloadRecentModel: {
                        ...state.downloadRecentState.downloadRecentModel,
                        dateEnd: action.value
                    }
                }
            };
        case "REQUEST_UPDATE_HISTORY_MULTIPORT":
            return {
                ...state,
                updateMutiPortState: {
                    ...state.updateMutiPortState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_HISTORY_MULTIPORT":
            if (action.payload.requestTime !== state.updateMutiPortState.requestTime)
                return state;

            return {
                ...state,
                updateMutiPortState: {
                    ...state.updateMutiPortState,
                    isLoading: false,
                },
                multiPorts: action.error ? state.multiPorts : state.multiPorts
                    .map(x => x.multiPortInstanceId === action.payload.multiPortInstanceId
                        ? { ...x, name: action.payload.name }
                        : x)
            };
        case "UPDATE_HISTORY_MULTIPORT_NAME":
            return {
                ...state,
                updateName: action.payload.value
            };
        case "REQUEST_DELETE_MULTIPORT_INSTANCE_HST":
            return {
                ...state,
                isDeleteLoading: true,
                deleteRequestTime: action.payload.requestTime
            };
        case "RECEIVE_DELETE_MULTIPORT_INSTANCE_HST":
            if (state.deleteRequestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isDeleteLoading: false,
                multiPorts: action.error
                    ? state.multiPorts
                    : state.multiPorts.filter(x => x.multiPortInstanceId !== action.payload.multiPortInstanceId)
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};