import { Action, Reducer } from 'redux';

export type TabsState = { [key: string]: TabState };

export interface TabState {
    selectedTab: string | number;
}

interface SelectTab { type: "SELECT_TAB"; namespace: string; name: string | number }
interface ResetTabs { type: "RESET_TABS"; namespace: string; }

type KnownAction = SelectTab | ResetTabs;

export const actionCreators = {
    selectTab: (namespace: string, name: string | number) => <SelectTab>{ type: "SELECT_TAB", namespace: namespace, name: name }
}

const unloadedState: TabsState = {
    "ratesExtraction": {
        selectedTab: "create"
    }
};

export const reducer: Reducer<TabsState> = (state: TabsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SELECT_TAB":
            return {
                ...state,
                [action.namespace]: {
                    ...state[action.namespace],
                    selectedTab: action.name
                }
            };
        case "RESET_TABS":
            return {
                ...state,
                [action.namespace]: {
                    ...state[action.namespace],
                    selectedTab: undefined
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};
