import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store/configureStore';
import {CarrierModel, CurrencyModel} from "../api/api";

export interface RatesExtractionState {
    ratesExtractionUpdateId?: number;
    ratesExtractionDownloadId?: number;
}

const initialState: RatesExtractionState = {
};

export const ratesExtractionSlice = createSlice({
    name: 'ratesExtraction',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
    //     updateColor: (state, index: PayloadAction<number>) => {
    //         window.localStorage.setItem("colorIndex", index.payload.toString());
    //         state.colorIndex = index.payload;
    //     }
        updateRatesExtractionUpdateId: (state, value: PayloadAction<number>) => {
            state.ratesExtractionUpdateId = value.payload;
        },
        updateRatesExtractionDownloadId: (state, value: PayloadAction<number>) => {
            state.ratesExtractionDownloadId = value.payload;
        },
     },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         ;
    // },
});

export const {  updateRatesExtractionUpdateId, updateRatesExtractionDownloadId  } = ratesExtractionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const carriersSelector = (state: RootState): { [id: number]: CarrierModel } => state.seed.carriers;
export const currenciesSelector = (state: RootState): { [id: number]: CurrencyModel } => state.seed.currencies;
export const ratesExtractionUpdateIdSelector = (state: RootState): number => state.ratesExtraction.ratesExtractionUpdateId;
export const ratesExtractionDownloadIdSelector = (state: RootState): number => state.ratesExtraction.ratesExtractionDownloadId;

export default ratesExtractionSlice.reducer;
