import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import * as SelectionStore from '../store/Selection';
import * as TransferStore from '../store/Transfer';
import Button from './Button';
import { getCarrierOfferKey } from '../utils/utils';
import { getText } from '../utils/langTexts';
import { getSelectedDepart } from '../utils/routeUtils';
import * as Colors from '../styles/colors';
import * as selectionConnect from '../connects/selectionConnect';


type TransferOfferProps = TransferOfferOwnProps
    & typeof TransferStore.actionCreators;

interface TransferOfferOwnProps {
    carrierOffer: Api.CarrierOfferModel;
    carrierOfferState: SelectionStore.CarrierOfferState;
    transferState: TransferStore.TransferState;
    currencyId: number;
    hasExportParameters: boolean;
    hasTmsSettings: boolean;
}

const partStyle = { marginBottom: 20 };
const innerStyle = { padding: 10 };
const containerStyle = { display: "flex", flexDirection: "row", marginLeft: 20, minWidth: 145, } as React.CSSProperties;
const labelStyle = { width: 145, textAlign: "right", marginRight: 10, paddingTop: 8, fontFamily: "Calibri", color: Colors.baseColors.darkGray, } as React.CSSProperties;
const inputStyle = { minWidth: 350, height: 30, borderRadius: 5, borderColor: Colors.baseColors.darkGray, borderWidth: 0.5, } as React.CSSProperties;

class TransferOffer extends React.Component<TransferOfferProps, {}> {
    constructor(props) {
        super(props);
    }

    //unlike exprot we add it to the action params because it may be fired from elsewhere
    get getOfferSelection(): Api.OfferSelectionModel {
        return {
            chargeSetId: this.props.carrierOffer.chargeSet ? this.props.carrierOffer.chargeSet.chargeSetId : null,
            currencyId: this.props.currencyId,
            departId: getSelectedDepart(this.props.carrierOffer, this.props.carrierOfferState)?.departId
        };
    }

    public render() {
        return <div >
            <div style={innerStyle}>
                <div style={partStyle}>
                    <h3 style={{
                        fontFamily: "calibri",
                        color: Colors.baseColors.darkGray,
                    }}>
                        {getText("SlcOfferCreateMemo")}</h3>
                    <div >
                        <div style={{ display: "inline-block", marginRight: 5 }}>
                            <Button
                                style={{
                                    minWidth: 250,
                                    height: 30,
                                }}
                                onClick={(e) => {
                                    this.props.requestSendMemo(new Date().getTime(), this.getOfferSelection);
                                    e.preventDefault();
                                }}
                                disabled={this.props.transferState.createMemoState.isLoading}>
                                {getText("SlcOfferMemoEmail")}
                            </Button>
                        </div>
                        <div style={{ display: "inline-block", marginRight: 5 }}>
                            <Button
                                style={{
                                    marginLeft: 20,
                                    minWidth: 200,
                                    height: 30,
                                }}
                                onClick={(e) => {
                                    this.props.requestDownloadMemo(new Date().getTime(), this.getOfferSelection);
                                    e.preventDefault();
                                }}
                                disabled={this.props.transferState.createMemoState.isLoading}>
                                {getText("SlcOfferMemoDownload")}
                            </Button>
                        </div>
                    </div>
                    <div ></div>
                </div>
                {this.props.hasExportParameters && <div style={partStyle}>
                    <form
                        onSubmit={(e) => {
                            this.props.requestExportOffer(new Date().getTime(), this.getOfferSelection);
                            e.preventDefault();
                            return false;
                        }}>
                        <h3 style={{
                            fontFamily: "calibri",
                            color: Colors.baseColors.darkGray,
                        }}>
                            {getText("SlcOfferTransferData")}</h3>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={{
                                marginBottom: 10,
                            }} >
                                <div style={containerStyle}>
                                    <label style={labelStyle}>{getText("SlcOfferTransferIdentification")}</label>
                                    <input
                                        style={inputStyle}
                                        value={this.props.transferState.identification}
                                        onChange={(e) => this.props.transferUpdateIdentitifcation(e.target.value)} />
                                </div>
                            </div>
                            <div style={{
                                marginBottom: 10,
                            }}  >
                                <div style={containerStyle}>
                                    <label style={labelStyle}
                                    >{getText("SlcOfferTransferAdd1")}</label>
                                    <div >
                                        <input
                                            style={inputStyle}
                                            value={this.props.transferState.additionalData1}
                                            onChange={(e) => this.props.transferUpdateAdditionalInfo1(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                marginBottom: 10,
                            }}>
                                <div style={containerStyle}>
                                    <label style={labelStyle}
                                    >{getText("SlcOfferTransferAdd2")}</label>
                                    <div >
                                        <input
                                            style={inputStyle}
                                            value={this.props.transferState.additionalData2}
                                            onChange={(e) => this.props.transferUpdateAdditionalInfo2(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }}>
                                <Button type="submit"
                                    disabled={this.props.transferState.exportOfferState.isLoading}
                                    style={{
                                        marginRight: 30,
                                        minWidth: 150,
                                        height: 30,
                                    }}>
                                    {getText("SlcOfferTransferGo")}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
                }
                {this.props.hasTmsSettings && <div style={partStyle}>
                    <h3 style={{
                        fontFamily: "calibri",
                        color: Colors.baseColors.darkGray,
                    }}>
                        {getText("SlcOfferTransferToTms")}</h3>
                    <div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }}>
                        <Button
                            style={{
                                minWidth: 200,
                                height: 30,
                                marginRight: 30
                            }}
                            onClick={(e) => {
                                this.props.requestExportOfferToTms(new Date().getTime(), this.getOfferSelection);
                                e.preventDefault();
                            }}
                            disabled={this.props.transferState.exportOfferToTmsState.isLoading}>
                            {getText("SlcOfferTransferToTms")}
                        </Button>
                    </div>
                </div>}
            </div>
        </div>
    }
}

export default connect((state: ApplicationState) => {

    return {
        carrierOffer: selectionConnect.getGroupedCarrierOffers(state)
            .reduce((a,b) => a.concat(b), [])
            .find(co => getCarrierOfferKey(co) === state.transfer.carrierOfferId),
        carrierOfferState: state.selection.carrierOfferStates[state.transfer.carrierOfferId],
        transferState: state.transfer,
        currencyId: state.account.currentUser.clientModel.currencyId,
        hasExportParameters: state.account.currentUser
            && state.account.currentUser.clientModel.subscriptions.some(x => x.hasExportParameters),
        hasTmsSettings: state.account.currentUser
            && state.account.currentUser.clientModel.subscriptions.some(x => x.hasTmsSettings)
    }
}, {
    ...SelectionStore.actionCreators,
    ...TransferStore.actionCreators
})(TransferOffer) as any