import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import SendEmail from './SendEmail';
import CheckBox from './CheckBox';
import * as SelectionStore from '../store/Selection';
import * as AskRatesStore from '../store/AskRates';
import { getCarrierOfferKey } from '../utils/utils';
import { getText } from '../utils/langTexts';
import { getSelectedDepart } from '../utils/routeUtils';

type AskRatesByEmailProps = AskRatesByEmailOwnProps
    & AskRatesStore.AskRatesState
    & typeof AskRatesStore.actionCreators;

interface AskRatesByEmailOwnProps {
    client: Api.ClientModel;
    criteria: Api.CriteriaModel;
    carrierOffer: Api.CarrierOfferModel;
    carrierOfferState: SelectionStore.CarrierOfferState;
    carrierOffers: Array<Api.CarrierOfferModel>;
    carriers: { [id: number]: Api.CarrierModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    chargeNames: { [id: number]: Api.SizeTypeModel };
}

const partStyle = { marginBottom: 20 };
const fieldStyle = { marginBottom: 10, marginTop: 10 };
const containerStyle = {};
const innerStyle = { padding: 10 };
const btnStyle = { margin: 5 };
const descStyle = { ...fieldStyle, textAlign: "center" };
const inputStyle = {};
const labelStyle = { minWidth: 60, display: "inline-block", textAlign: "right", marginRight: 2 }
const shortLabelStyle = { ...labelStyle, minWidth: 60 };
const btnsStyle = { ...fieldStyle, textAlign: "center" };

class AskRatesByEmail extends React.Component<AskRatesByEmailProps, {}> {
    constructor(props) {
        super(props);
    }

    get selectedDepart(): Api.DepartModel {
        return getSelectedDepart(this.props.carrierOffer, this.props.carrierOfferState);
    }

    onPropsUpdate(props: AskRatesByEmailProps) {

    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: AskRatesByEmailProps) {
        this.onPropsUpdate(nextProps);
    }

    handleSendingEmail() {
        ((this.props.requestSendEmail(new Date().getTime()) as any) as Promise<any>).then(value => {
            this.props.closeEmailAskRates();
        });
    }

    //TODO get carrier url
    public render() {
        return <div style={containerStyle}>
            <div style={innerStyle}>
                {!this.props.carrierOffer
                    && <div className="row">
                        <label className="col-md-2 control-label">Carriers:</label>
                    <div className="col-md-8" style={{
                        display: "flex", flexDirection: "row",
                        alignItems: "center", paddingLeft: 30,
                        flexWrap: "wrap"
                    }}>
                        {_.values(this.props.carriers)
                            .filter(x => x.askRatesByEmail && this.props.carrierOffers.some(y => y.carrierId === x.carrierId))
                                .map(carrier => <div style={{ flex: "0 0 auto", marginRight: 10, marginBottom: 5 }}>
                                    <CheckBox label={carrier.name}
                                        value={this.props.carriersSelected[carrier.carrierId] !== false}
                                        onChange={(value) => this.props.setCarrierSelected(carrier.carrierId, value)} />
                                </div>)}
                        </div>
                    </div>}
                <div>
                    <SendEmail
                        isLoading={this.props.isLoading}
                        backLabel={getText("GenClose")}
                        to={this.props.to}
                        toTextarea={!this.props.carrierOfferId ? true : false}
                        onToUpdate={this.props.updateTo}
                        cc={this.props.cc}
                        onCcUpdate={this.props.updateCc}
                        subject={this.props.subject}
                        onSubjectUpdate={this.props.updateSubject}
                        content={this.props.content}
                        onContenttUpdate={this.props.updateContent}
                        send={() => this.handleSendingEmail()}
                        goBack={() => this.props.toggleAskRatesByEmailOpen(false)}
                    />
                </div>
            </div>
        </div>
    }
}

export default connect((state: ApplicationState) => ({
    ...state.askRates,
    carriers: state.seed.carriers,
    sizeTypes: state.seed.sizeTypes,
    chargeNames: state.seed.chargeNames,
    client: state.account.currentUser.clientModel,
    criteria: state.criteria.criteria,
    carrierOffers: state.selection.selection.carrierOffers,
    carrierOffer: state.selection.selection.carrierOffers
        .find(co => getCarrierOfferKey(co) === state.askRates.carrierOfferId),
    carrierOfferState: state.selection.carrierOfferStates[state.askRates.carrierOfferId]
} as any), AskRatesStore.actionCreators)(AskRatesByEmail) as any