import * as React from 'react';
import HistoryOfferMemo from "./HistoryOfferMemo"
import HistorySearchCriteria from "./HistorySearchCriteria"
import HistoryMultiPort from "./HistoryMultiPort"
import { Route, Switch } from 'react-router-dom';
import * as Colors from "../styles/colors";
import { getText } from '../utils/langTexts';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText, Divider } from '@material-ui/core';

type HistoryProps = HistoryOwnProps;

interface HistoryOwnProps {
}

const containerStyle = {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start"
} as React.CSSProperties;

const navContainerStyle = {
    marginTop: 50,
    order: 1,
    flex: "0 0 200",
    border: `1px solid ${Colors.baseColors.darkGray}`,
    borderRadius: 8
} as React.CSSProperties;
const navInnerContainerStyle = { paddingTop: 20, paddingBottom: 100 } as React.CSSProperties;

const contentContainerStyle = { height: "100%", order: 2, flex: 1, display: "flex", flexDirection: "column" } as React.CSSProperties;
const innerContentStyle = { padding: "20px 50px" } as React.CSSProperties;

const navStyle = { width: "100%", textAlign: "center", color: "white" } as React.CSSProperties;
const navLinkStyle = {padding:"15px 50px", margin: "0px -25px"} as React.CSSProperties;
const navLinkActiveStyle = { ...navLinkStyle, color: Colors.baseColors.lightBlue, fontWeight: "bold" } as React.CSSProperties;
const navLinkTabStyle = { fontSize: "20px", fontWeight: "bold", textAlign: "left" } as React.CSSProperties;

export default class History extends React.Component<HistoryProps, {}> {

    public render() {
        return (
            <div style={containerStyle}>
                <div style={{ flex: "0 0 auto", width: 20 }}></div>
                <div style={navContainerStyle}>
                    <div style={navInnerContainerStyle}>
                        <ListItem>
                            <ListItemText disableTypography style={navLinkTabStyle} primary="FCL" />
                        </ListItem>
                        <ListItem button >
                            <ListItemText style={navStyle}>
                                <NavLink className={"navlink"} style={navLinkStyle} exact activeStyle={navLinkActiveStyle} to="/history">{getText("HstSearchCriteria")}</NavLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem button>
                            <ListItemText style={navStyle}>
                                <NavLink className={"navlink"} style={navLinkStyle} exact activeStyle={navLinkActiveStyle} to="/history/memo">{getText("HstMemoOffer")}</NavLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem button>
                            <ListItemText style={navStyle}>
                                <NavLink className={"navlink"} style={navLinkStyle} exact activeStyle={navLinkActiveStyle} to="/history/multiport">{"  "+ getText("HstMultiPort")+"  "}</NavLink>
                            </ListItemText>
                        </ListItem>
                        <Divider variant="middle" />
                        <ListItem hidden>
                            <ListItemText disableTypography style={navLinkTabStyle} primary="Inland" />
                        </ListItem>
                        <ListItem button disabled hidden>
                            <ListItemText style={navStyle}>
                                <NavLink className={"navlink"} style={navLinkStyle} exact activeStyle={navLinkActiveStyle} to="/history">{getText("HstSearchCriteria")}</NavLink>
                            </ListItemText>
                        </ListItem>
                    </div>
                </div>
                <div style={contentContainerStyle}>
                    <div style={innerContentStyle}>
                        <Switch>
                            <Route path="/history/memo" component={HistoryOfferMemo} />
                            <Route path="/history/multiport" component={HistoryMultiPort} />
                            <Route path="/history" component={HistorySearchCriteria} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}