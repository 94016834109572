import * as React from 'react';
import * as Api from '../api/api';
import { CSSProperties } from 'react';
import Select from './Select';
import InputNumber from './InputNumber';

export const cargoWeightOption = (): Array<{ label: string; value: Api.CriteriaSizeTypeModelWeightUnitEnum }> => [
    { label: "Kg", value: "Kg" },
    { label: "Ton", value: "Ton" },
];


interface WeightPickerProps {
    style?: CSSProperties;
    buttonStyle?: CSSProperties;
    iconStyle?: CSSProperties;
    sizeTypes?: { [id: number]: Api.SizeTypeModel };
    criteriaSize: Api.CriteriaSizeTypeModel;
    showLabel: boolean;
    onChange: (value: Api.CriteriaSizeTypeModel) => void;
}

const fieldStyle: React.CSSProperties & { height: number } = {
    height: 25,
    maxWidth: 70,
}


export default class WeightPicker extends React.Component<WeightPickerProps, {}> {


    private handleChange(weight: any, unit: any) {
        this.props.onChange({
            weight: !weight && !unit ? null : weight || this.props.criteriaSize.weight,
            weightUnit: "Kg",
            sizeTypeId: this.props.criteriaSize.sizeTypeId
        });
    }

    public render() {

        return <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 10,
            ...this.props.style
        }}>
            <div style={{ flex: 1, display: "flex", flexDirection: "row", verticalAlign: "bottom", alignItems: "end" }}>
                {this.props.showLabel &&  this.props.sizeTypes && this.props.sizeTypes[this.props.criteriaSize.sizeTypeId]
                    ? `${this.props.sizeTypes[this.props.criteriaSize.sizeTypeId].name}: `
                    : ''}
                <div style={{ flex: 1, marginRight: 5, marginLeft: 5, minWidth: 55 }}>
                    <InputNumber
                        className="form-control"
                        style={{ ...fieldStyle, height: fieldStyle.height - 2 }}
                        integer={false}
                        allowEmpty={true}
                        value={this.props.criteriaSize.weight}
                        onChange={(value) => this.handleChange(value, null)}
                    />
                </div>
                <div style={{ flex: 1, marginRight: 5 }}>
                    KG
                </div>
            </div>
        </div>
    }
}


