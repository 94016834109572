import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { SubmissionError } from 'redux-form';
import { Logout } from './Account';

export interface InlandRatesOfferState {
    isLoading: boolean;
    requestTime: number;
    searchInlandRatesOffers: Api.SearchInlandRatesOffersModel;
    inlandRatesOffers: Array<Api.InlandRatesOfferModel>;
    editState: EditInlandRatesOfferState;
}

export interface EditInlandRatesOfferState {
    inlandRatesOfferId?: number;
    isLoading: boolean;
    requestTime: number;
}

interface InlandRatesOffersSelectInlandCarrier { type: "INLANDRATESOFFERS_SELECT_INLANDCARRIER"; payload: { value: number; }}
interface InlandRatesOffersUpdateSearchTerm { type: "INLANDRATESOFFERS_UPDATE_SEARCH_TERM"; payload: { value: string; } }

interface RequestInlandRatesOffers { type: "REQUEST_INLANDRATESOFFERS"; payload: { requestTime: number; } }
interface ReceiveInlandRatesOffers { type: "RECEIVE_INLANDRATESOFFERS"; payload: { requestTime: number; inlandRatesOffers: Array<Api.InlandRatesOfferModel> }; error?: any }

interface SelectInlandRatesOffer { type: "SELECT_INLANDRATESOFFER"; payload: { inlandRatesOfferId: number } }

interface RequestEditInlandRatesOffer { type: "REQUEST_EDIT_INLANDRATESOFFER"; payload: { requestTime: number; } }
interface ReceiveEditInlandRatesOffer { type: "RECEIVE_EDIT_INLANDRATESOFFER"; payload: { requestTime: number; inlandRatesOffer: Api.InlandRatesOfferModel }; error?: any }

type KnownAction = InlandRatesOffersSelectInlandCarrier | InlandRatesOffersUpdateSearchTerm
    | RequestInlandRatesOffers | ReceiveInlandRatesOffers | SelectInlandRatesOffer
    | RequestEditInlandRatesOffer | ReceiveEditInlandRatesOffer
    | Logout;


export const actionCreators = {
    requestInlandRatesOffers: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let api = new Api.InlandRatesOfferApi();
        var fetchTask = api.search({
            model: getState().inlandRatesOffer.searchInlandRatesOffers
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(inlandRatesOffers => {
                dispatch({
                    type: "RECEIVE_INLANDRATESOFFERS",
                    payload: { requestTime: requestTime, inlandRatesOffers: inlandRatesOffers }
                });
            }).catch(error => {
                console.log('Error: ' + error.message);
                dispatch(Notifications.error({ message: "Error searching rates information", title: "Error", position: "tc" }) as any);
                dispatch({ type: "RECEIVE_INLANDRATESOFFERS", payload: { requestTime: requestTime, inlandRatesOffers: [] }, error: error });
            });
        addTask(fetchTask);
        dispatch({ type: "REQUEST_INLANDRATESOFFERS", payload: { requestTime: requestTime } });
    },
    requestEditInlandRatesOffer: (requestTime: number, inlandRatesOffer: Api.InlandRatesOfferModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().ratesOffer.editState.requestTime)
            return Promise.resolve();

        let api = new Api.InlandRatesOfferApi();
        let fetchTask = api.update({ model: inlandRatesOffer },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(inlandRatesOfferResult => {
                dispatch({
                    type: "RECEIVE_EDIT_INLANDRATESOFFER",
                    payload: {
                        requestTime: requestTime,
                        inlandRatesOffer: inlandRatesOfferResult
                    }
                });
                dispatch(Notifications.success({ message: "Your rates information have been updated", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_EDIT_INLANDRATESOFFER", payload: { requestTime: requestTime, inlandRatesOffer: inlandRatesOffer }, error: error });
                throw new SubmissionError({ _error: "Failed to edit" } as any)
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_EDIT_INLANDRATESOFFER", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    updateSearchTerm: (value: string) => <InlandRatesOffersUpdateSearchTerm>{
        type: "INLANDRATESOFFERS_UPDATE_SEARCH_TERM", payload: { value: value }
    },
    selectInlandCarrierId: (inlandCarrierId: number) => <InlandRatesOffersSelectInlandCarrier>{
        type: "INLANDRATESOFFERS_SELECT_INLANDCARRIER", payload: { value: inlandCarrierId }
    },
    selectInlandRatesOffer: (inlandRatesOfferId: number) => <SelectInlandRatesOffer>{
        type: "SELECT_INLANDRATESOFFER", payload: { inlandRatesOfferId: inlandRatesOfferId }
    },
}

const unloadedState: InlandRatesOfferState = {
    isLoading: false,
    requestTime: 0,
    inlandRatesOffers: [],
    searchInlandRatesOffers: {

    },
    editState: {
        isLoading: false,
        inlandRatesOfferId: null,
        requestTime: 0
    }
};


export const reducer: Reducer<InlandRatesOfferState> = (state: InlandRatesOfferState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_INLANDRATESOFFERS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime,
                editState: {
                    ...state.editState,
                    //Don't wanna risk a null reference
                    inlandRatesOfferId: null
                }
            };
        case "RECEIVE_INLANDRATESOFFERS":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                inlandRatesOffers: action.payload.inlandRatesOffers
            };
        case "INLANDRATESOFFERS_SELECT_INLANDCARRIER":
            return {
                ...state,
                searchInlandRatesOffers: {
                    ...state.searchInlandRatesOffers,
                    inlandCarrierId: action.payload.value
                }
            };
        case "INLANDRATESOFFERS_UPDATE_SEARCH_TERM":
            return {
                ...state,
                searchInlandRatesOffers: {
                    ...state.searchInlandRatesOffers,
                    searchTerm: action.payload.value
                }
            };
        case "SELECT_INLANDRATESOFFER":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    inlandRatesOfferId: action.payload.inlandRatesOfferId
                }
            };
        case "REQUEST_EDIT_INLANDRATESOFFER":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_EDIT_INLANDRATESOFFER":
            return {
                ...state,
                inlandRatesOffers: action.error
                    ? state.inlandRatesOffers
                    : state.inlandRatesOffers
                        .map(ro => ro.inlandRatesOfferId === action.payload.inlandRatesOffer.inlandRatesOfferId
                            ? action.payload.inlandRatesOffer
                            : ro),
                editState: {
                    ...state.editState,
                    isLoading: action.payload.requestTime !== state.editState.requestTime
                }
            };
        case "LOGOUT":
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
