import * as Api from '../api/api';
import * as React from 'react';
import * as ReduxForm from 'redux-form';
import * as FormField from './FormField';
import { getText } from "../utils/langTexts";
import Button from './Button';
import { NavLink } from 'react-router-dom';
import * as Colors from '../styles/colors';
import * as BaseStyles from '../styles/baseStyles';

type LoginFormProps = ReduxForm.InjectedFormProps<Api.LoginModel, {}, string>
    & LoginFormOwnProps
    & LoginFormExternalProps;

interface LoginFormOwnProps {
}
interface LoginFormExternalProps {
    onSubmit: (values: Api.LoginModel) => any;
}
interface LoginFormState {
    hoverState: boolean;
}
var ForgotPasswordStyle = {
    fontSize: 13,
    paddingLeft: 8,
    marginTop: 6,
    textDecoration: "underline",
};

class LoginForm extends React.Component<LoginFormProps, LoginFormState, {}> {
    constructor(props) {
        super(props);
        this.state = {
            hoverState: false
        }
    }
    Mouseover() {
        this.setState({ hoverState: true })
    }
    Mouseout() {
        this.setState({ hoverState: false })
    }
    public render() {
        return (
            <FormField.BaseForm {...this.props} resetOnSubmit={false}>
                <div style={{ ...BaseStyles.vFieldStyle }}>
                    <label style={{ paddingLeft: 8 }}>{getText("LogEmail")}</label>
                    <div>
                        <ReduxForm.Field
                            validate={FormField.required}
                            name="username"
                            style={{ width: 500 }}
                            component={FormField.InputTextField} />
                    </div>
                </div>
                <div style={{ ...BaseStyles.vFieldStyle }}>
                    <label style={{ paddingLeft: 8 }}>{getText("LogPassword")}</label>
                    <div>
                        <ReduxForm.Field
                            validate={FormField.required}
                            name="password"
                            component={FormField.InputTextField}
                            style={{ width: 500 }}
                            type="password" />
                    </div>

                    <NavLink to={"/forgotpassword"}>
                        <span onMouseOver={(e) => { this.Mouseover() }} onMouseOut={(e) => { this.Mouseout() }} style={{ ...ForgotPasswordStyle, color: this.state.hoverState == true ? Colors.baseColors.lightBlue : Colors.baseColors.darkGray }}>
                            {getText("LogForgotPassword")}
                        </span>
                    </NavLink>
                </div>
                <div style={{ ...BaseStyles.vFieldStyle, marginTop: 50 }}>
                    <Button color={"primary"}
                        type="submit"
                        style={{ height: 48, fontSize: 22, fontWeight: 700 }}>
                            {getText("LogLogin")}
                        </Button>
                </div>
            </FormField.BaseForm>
            );
    }
}

export default ReduxForm.reduxForm({
    form: 'login',
})(LoginForm) as any as React.ComponentClass<LoginFormExternalProps>;
