import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as WorkDocumentsStore from '../store/WorkDocuments';
import * as FormField from './FormField';
import { getText } from '../utils/langTexts';
import * as Colors from '../styles/colors';
import Button from './Button';
import { getSubscription, getFirmName } from '../utils/utils';
import _, { values } from 'lodash';
import { Select } from '@material-ui/core';
import Moment from 'moment';
import { trafficTypeOptions, offerType, containerType } from '../utils/enumUtils';


type ModeratorWokrdocumentsUploadFormProps = ReduxForm.InjectedFormProps<{}, {}, string>
    & ModeratorWokrdocumentsUploadFormExternalProps
    & ModeratorWokrdocumentsUploadFormOwnProps
    & typeof WorkDocumentsStore.actionCreators;

interface ModeratorWokrdocumentsUploadFormOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
    subscriptions: Array<Api.SubscriptionModel>;
    agencies: Array<Api.FirmModel>;
    firms: Array<Api.FirmModel>;
    ratesType: Api.RatesOfferModelTypeEnum

}

interface ModeratorWokrdocumentsUploadFormExternalProps {
    onSubmit: (values: Api.WorkDocumentsClientUploadFilesModel) => void;
    requestTemplateDownload: (values: Api.WorkDocumentsClientUploadModel) => void;
}
interface ModeratorState {

}


const textAreaField = FormField.textAreaField(4, 50);



const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12
}

const labelStyle: React.CSSProperties = {
    flex: "0 0 auto",
    minWidth: 200,
    textAlign: "right",
    marginRight: 6
}




class ModeratorWokrdocumentsDownloadTemplateForm extends React.Component<ModeratorWokrdocumentsUploadFormProps, {}> {
    constructor(props) {
        super(props);
    }



    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-horizontal container-fluid"  >
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div style={{ ...fieldStyle }}>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label">{getText("MdtPricelistIntegerratedFor")} </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                name="firmId"
                                style={{ minWidth: 200 }}
                                validate={[FormField.required]}
                                component={FormField.getSelectField(_.sortBy(this.props.subscriptions.filter(x => x.subscriptionType === "Okargo" && x.agency.enabled)
                                    .map(x => ({
                                        label: getFirmName(x.agency),
                                        value: x.agency.firmId
                                    })).concat(this.props.subscriptions
                                        .filter(x => x.subscriptionType === "Okargo" && x.agency.enabled)
                                        .map(x => ({
                                            label: getFirmName(x.agency.company),
                                            value: x.agency.companyId
                                        })))
                                    .concat(this.props.subscriptions
                                        .filter(x => x.subscriptionType === "Okargo"
                                            && x.agency.company.companyGroup && x.agency.enabled)
                                        .map(x => ({
                                            label: getFirmName(x.agency.company.companyGroup),
                                            value: x.agency.company.companyGroupId
                                        }))), x => x.label))} />
                        </div>
                    </div>
                </div>
                <div style={{ ...fieldStyle }}>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label">{getText("MdtRatesDocumentsTrafficType")}</label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                name="trafficType"
                                style={{ minWidth: 200 }}
                                validate={[FormField.required]}
                                component={FormField.getSelectField(trafficTypeOptions)} />
                        </div>
                    </div>
                </div>
                <div style={{ ...fieldStyle }}>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label">{getText("MdtPricelistOfferType")} </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                name="ratesType"
                                style={{ minWidth: 200 }}
                                validate={[FormField.required]}
                                component={FormField.getSelectField(offerType)} />
                        </div>
                    </div>
                </div>
                {this.props.ratesType == "Special" &&
                    <div className="form-group row">
                        <label className="col-sm-3 control-label">{getText("GenNac")}</label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                validate={[]}
                                name="nac"
                                component={FormField.renderField}
                                type="text"
                                onChange={() => { }}
                            />
                        </div>
                    </div>
                }
                {this.props.ratesType == "Special" &&

                    <div className="form-group row">
                        <label className="col-sm-3 control-label">{getText("SlcOfferRatesCommodity")}</label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                validate={[]}
                                name="commodity"
                                component={FormField.renderField}
                                type="text"
                                onChange={() => { }}
                            />
                        </div>
                    </div>
                }
                <div style={{ ...fieldStyle }}>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label">{ getText("MdtRatesInfoCarrier") } </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                name="carrierId"
                                validate={[FormField.required]}
                                style={{ minWidth: 200 }}
                                component={FormField.getSelectField(_.sortBy(_.values(this.props.carriers)
                                    .filter(y => y.enabled)

                                    .map(x => ({ label: x.name, value: x.carrierId })), x => x.label))} />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("RepReference")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            validate={[]}
                            name="reference"
                            component={FormField.renderField}
                            type="text"
                            onChange={() => { }}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtRatesDocumentsValidityStart")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field
                            validate={[]}
                            name="validityBegin"
                            nullable={true}
                            component={FormField.DatePickerField} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtRatesDocumentsValidityEnd")} </label>
                    <div className="col-sm-9">
                        <ReduxForm.Field
                            validate={[]}
                            name="validityEnd"
                            nullable={true}
                            component={FormField.DatePickerField} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("GenInformation")} </label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            validate={[]}
                            name="additionalInfo"
                            component={textAreaField}
                            type="text"
                            onChange={() => { }}
                        />
                    </div>
                </div>
                <div style={{ ...fieldStyle }}>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label">{getText("CrtContainerType")}</label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                name="containerType"
                                style={{ minWidth: 200 }}
                                validate={[FormField.required]}
                                component={FormField.getSelectField(containerType)} />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label" ></label>
                    <div className="col-sm-9">

                        <Button
                            type="button"
                            onClick={this.props.handleSubmit(values => this.props.requestTemplateDownload(values as Api.WorkDocumentsClientUploadModel))}

                            style={{ color: Colors.baseColors.lightBlue }}   >
                            {getText("MdtPricelistDownloadTemplate")}
                        </Button>

                    </div>
                </div>
            </FormField.BaseForm >





        );
    }
}


class ModeratorWokrdocumentsUploadmplateForm extends React.Component<ModeratorWokrdocumentsUploadFormProps, ModeratorState, {}> {
    constructor(props) {
        super(props);

    }



    public render() {



        return (
            <FormField.BaseForm {...this.props} className="form-horizontal container-fluid"  >
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div style={{ ...fieldStyle }}>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtPricelistSourceDocument")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            validate={[]}
                            name="sourceFile"
                            component={FormField.fileField}
                            type="text" />
                    </div>
                </div>
                </div>
                <div style={{ ...fieldStyle }}>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtPricelistTemplate")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            validate={[]}
                            name="file"
                            component={FormField.fileField}
                            type="text" />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                
                    <label className="col-sm-3 control-label" ></label>
                    <div className="col-sm-9">
 
                        <Button type="button"
                            onClick={this.props.handleSubmit(values => this.props.onSubmit(values as Api.WorkDocumentsClientUploadFilesModel))}
                            style={{ color: "white", backgroundColor: Colors.baseColors.lightBlue, marginRight: "1em" }}>
                            {getText("MdtPricelistUploadDocument")}
                        </Button>
                    </div>
                </div>
            </FormField.BaseForm >





        );
    }
}

let downloadForm = ReduxForm.reduxForm({
    form: 'downloadTemplateForm',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(ModeratorWokrdocumentsDownloadTemplateForm) as any;




let uploadForm = ReduxForm.reduxForm({
    form: 'uploadTemplateForm',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(ModeratorWokrdocumentsUploadmplateForm) as any;



const createSelector = ReduxForm.formValueSelector('downloadTemplateForm')



export const CreateModeratorWorkdocumentsUploadForm = connect((state: ApplicationState) => {
    return {
    } as ModeratorWokrdocumentsUploadFormOwnProps;
})(uploadForm) as any as React.ComponentClass<ModeratorWokrdocumentsUploadFormExternalProps>


export const CreateModeratorWorkdocumentsTemplateForm = connect((state: ApplicationState) => {
    return {
        initialValues: {
            validityBegin: Moment().utc().startOf("day").toDate(),
            validityEnd: Moment().utc().add(1, "months").startOf("day").toDate(),
            trafficType: "Export",
            ratesType: "Fak"
        },
        ratesType: createSelector(state, "ratesType"),
        carriers: state.seed.carriers,
        subscriptions: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo"),
        agencies: state.account.currentUser.clientModel.agencies,
        firms: state.account.currentUser.clientModel.subscriptions.filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency.company)
            .concat(state.account.currentUser.clientModel.subscriptions.filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company.companyGroup))
            .concat(state.account.currentUser.clientModel.agencies)
            .filter(x => x),
    } as ModeratorWokrdocumentsUploadFormOwnProps;
})(downloadForm) as any as React.ComponentClass<ModeratorWokrdocumentsUploadFormExternalProps>



