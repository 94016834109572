import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import * as CriteriaState from '../store/Criteria';
import CriteriaLclForm from '../components/CriteriaLclForm';
import { getSubscription } from '../utils/utils';

// At runtime, Redux will merge together...
type CriteriaLclProps =
    CriteriaState.CriteriaState        // ... state we've requested from the Redux store
    & CriteriaLclOwnProps
    & typeof CriteriaState.actionCreators      // ... plus action creators we've requested
    & RouteComponentProps<{ code: string }>; // ... plus incoming routing parameters   

interface CriteriaLclOwnProps {
    client: Api.ClientModel;
    subscription: Api.SubscriptionModel;
}

class CriteriaLcl extends React.Component<CriteriaLclProps, {}> {
    constructor(props) {
        super(props);
    }

    onPropsUpdate(props: CriteriaLclProps) {
        let code = this.props.match ? this.props.match.params.code : '';
        if (code)
            this.props.requestCriteria(code, new Date().getTime());
    }

    componentWillMount() {
        // This method runs when the component is first added to the page
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: CriteriaLclProps) {
        // This method runs when incoming props (e.g., route params) change
        if (this.props.match.params.code !== nextProps.match.params.code)
            this.onPropsUpdate(nextProps);
    }

    criteriaGo(): Promise<any> {
        let promise = (this.props.requestCreateCriteria(new Date().getTime()) as any) as Promise<Api.CriteriaModel>;
        return promise.then((criteria) => {
            this.props.setCriteria(criteria);
            return this.props.criteriaGo();
        });
    }

    doWithoutPropagation(e: any, action: () => void): void {
        action();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    public render() {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                height: "100%"
            }}>
                <div style={{ flex: "0 0 auto", padding: "25px 0px", marginTop: 100 }}>
                    <div style={{ fontSize: 32, fontWeight: 700, textAlign: "center" }}>LCL</div>
                </div>
                <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ width: 900, marginTop: 0 }}>
                        <div style={{ padding: "10px 40px 20px 40px" }}>
                            {(this.props.subscription && this.props.subscription.subscriptionType === "Agent") && <div
                                style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: "red",
                                    fontSize: 18
                                }}>
                                BETA
                </div>}
                            <CriteriaLclForm
                                onSubmit={(values) => {
                                    this.props.setCriteria(values);
                                    return this.criteriaGo();
                                }}
                                resetCriteria={() => this.props.resetCriteria()}
                                advContainerOpened={this.props.advContainerOpened}
                                advDateOpened={this.props.advDateOpened}
                                advLocationOpened={this.props.advLocationOpened}
                                toggleAdvContainer={(value) => this.props.toggleAdvContainer(value)}
                                toggleAdvDate={(value) => this.props.toggleAdvDate(value)}
                                toggleAdvLocation={(value) => this.props.toggleAdvLocation(value)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            ...state.criteria,
            client: state.account.currentUser.clientModel,
            subscription: getSubscription(state.account.currentUser.clientModel)
                || { subscriptionType: null }
        } as any
    },
    CriteriaState.actionCreators)
    (CriteriaLcl) as any