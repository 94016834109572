import * as React from 'react';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as QuotationSettingsStore from '../store/QuotationSettings';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import QuotationSettingsForm from "./QuotationSettingsForm";

type ModeratorCompanySettingsProps = ModeratorCompanySettingsOwnProps
    & typeof QuotationSettingsStore.actionCreators;

interface ModeratorCompanySettingsOwnProps {
    isLoading: boolean;
    subscription: Api.SubscriptionModel;
}

class ModeratorCompanySettings extends React.Component<ModeratorCompanySettingsProps, {}> {

    componentWillMount() {
        this.props.requestQuotationSettings(
            new Date().getTime());
    }

    componentWillReceiveProps(nextProps: ModeratorCompanySettingsProps) {
    }

    public render() {
        return (
            <div>
                <h2>{getText("MdtCompanyNameSettings", { value: this.props.subscription.agency.company.name })}</h2>
                <div>
                    <QuotationSettingsForm
                        onSubmit={(model) => this.props.requestUpdateQuotationSettings(new Date().getTime(), model) as any} />
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        isLoading: state.quotationSettings.isLoading,
        subscription: state.account.currentUser.clientModel.subscriptions
            .find(x => x.subscriptionId === state.account.currentUser.clientModel.subscriptionId)
    } as any),
    {
        ...QuotationSettingsStore.actionCreators,
    })(ModeratorCompanySettings) as any