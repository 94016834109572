import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';

import * as WorkDocumentsStore from '../store/WorkDocuments';
import * as FormField from './FormField';
import { getText } from '../utils/langTexts';
import * as Colors from '../styles/colors';
import Button from './Button';
import { getSubscription, getFirmName } from '../utils/utils';



import { ApplicationState } from '../store/index';
import LocationSelect from './LocationSelect';
import { searchFieldStyle } from '../styles/moderatorStyles';
import { size, map } from 'lodash';
import _ from 'lodash';
import Autocomplete from "./Autocomplete";
import { red } from '@material-ui/core/colors';


type ModeratorSearchUploadEntitiesFormProps = ReduxForm.InjectedFormProps<{}, {}, string>
    & ModeratorSearchUploadEntitiesFormExternalProps
    & ModeratorSearchUploadEntitiesFormOwnProps
    & typeof WorkDocumentsStore.actionCreators;

interface ModeratorSearchUploadEntitiesFormOwnProps {

    port: Api.LocationModel,
    chargeName: Api.ChargeNameModel,
    chargeNames: { [id: number]: Api.ChargeNameModel };
    chargeText: string,
}

interface ModeratorSearchUploadEntitiesFormExternalProps {

}






const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12
}

const resultStyle: React.CSSProperties = {
    flex: "0 0 auto",
    minWidth: 200,

    marginTop: 6,
    fontSize: 18,
    fontWeight: 700
}

interface UploadEnttiesFormAutocompleteState {
    filteredCharges: Api.ChargeNameModel[]
    selected: Api.ChargeNameModel;
    portText: string;
}


class ModeratorSearchUploadEntitiesForm extends React.Component<ModeratorSearchUploadEntitiesFormProps, UploadEnttiesFormAutocompleteState, {}> {
    constructor(props) {
        super(props);

        this.state = {
            filteredCharges: [],
            selected: null,
            portText:null,
        }
    }



    handleChangeLocation(location: Api.LocationModel) {



        if (location != null) {
                 
            this.setState({
                portText: `${location.name} (${location.code}), ${location.country?.name}`
            });
        }

    };

    handleChangeCharge(charge: Api.ChargeNameModel) {
        if (charge != null) {    
            this.setState({
                filteredCharges: [],
                selected: charge,
            });
        }

    };

    onInputChargeChange(val: string) {

        if (val) {
            var res = _.map(this.props.chargeNames, x => x).filter(c =>
                c.code.toLowerCase().includes(val.toLowerCase())
                || (c.shortName != null && c.shortName.toLowerCase().includes(val.toLowerCase()))
                || (c.description != null && c.description.toLowerCase().includes(val.toLowerCase()))
                || (c.aliases != null && c.aliases.filter(a=>a.toLowerCase().includes(val.toLowerCase())).length>0)
                || (c.name != null && c.name.toLowerCase().includes(val.toLowerCase()))
            );

            this.setState({
                filteredCharges: res
            });
        }
    }

    getChargeName(chargeName: Api.ChargeNameModel): string {
        return `${chargeName.code} - ${chargeName.name}`;
    }

    public render() {

        return (<div>
            <h2>{getText("MdtPricelistSearchPortCode")}</h2>
            <div className="form-group row">

                <div className="col-sm-9">
                    <LocationSelect
                        className="form-control"
                        inputKey={"port"}
                        value={this.props.port}
                        onChange={(location) => { this.handleChangeLocation(location); }}
                    />
                </div>

                <div style={{ ...resultStyle }} className="col-sm-3">
                  {this.state.portText}
                </div>

            </div>
            <h2>{getText("MdtPricelistSearchChargeCode")}</h2>
            <div className="form-group row">

                <div className="col-sm-6">
                    <Autocomplete
                        getItemValue={(item: Api.ChargeNameModel) => item}
                        getItemLabel={(item: Api.ChargeNameModel) => this.getChargeName(item)}
                        emptyItems={() => this.setState({ filteredCharges: [] })}
                        items={this.state.filteredCharges}
                        renderItem={(item: Api.ChargeNameModel, isHighlighted) =>
                            <div className='action' style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                <span>{this.getChargeName(item)}</span>
                            </div>
                        }
                        inputValue={this.props.chargeText}
                        onInputValueChange={(val) => {
                            this.onInputChargeChange(val);
                        }}
                        value={this.props.chargeName}
                        onChange={(id) => this.handleChangeCharge(id)} />
                </div>
                <div style={{ width: 400 }}>
                {this.state.selected &&
                    <div className="form-group row">
                        <div className="col-sm-3">
                            <div style={{ ...resultStyle }}>
                            
                              {getText("GenCode")}:   {this.state.selected?.code} 
                            </div>
                           
                            {/*<div>*/}
                            {/*    <b>{getText("GenApplication")}:</b> {this.state.selected.application != "General" ? this.state.selected.application :  getText("MdtPricelistSearchChargeCodeOrigDest") }*/}
                            {/*</div>     */}
                            <div>
                                <b>{getText("GenAffectation")}:</b> { this.state.selected.affectation}
                            </div>                                
                            <div>
                                <b>{getText("SlcOfferRatesDescription")}</b>{this.state.selected.description}
                            </div>
                        </div>
                    </div>
                }
                </div>

            </div>
        </div>

        );
    }
}




let downloadForm = ReduxForm.reduxForm({
    form: 'getEntitiesForm',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(ModeratorSearchUploadEntitiesForm) as any;


const createSelector = ReduxForm.formValueSelector('getEntitiesForm')


export const CreateModeratorWokrdocumentsUploadEntitiesForm = connect((state: ApplicationState) => {
    return {
        chargeNames: state.workDocuments.uploadChargeNames,
    } as ModeratorSearchUploadEntitiesFormOwnProps;
})(downloadForm) as any as React.ComponentClass<ModeratorSearchUploadEntitiesFormExternalProps>



