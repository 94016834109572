import * as React from 'react';
import * as Api from '../api/api';
import DialogModal from './DialogModal';
import Table, { Column } from './Table';
import * as QuotationStore from '../store/Quotation';
import { getText } from '../utils/langTexts';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';

type QuotationTemplatesDialogProps = QuotationStore.QuotationState
    & QuotationTemplatesDialogOwnProps
    & typeof QuotationStore.actionCreators;

interface QuotationTemplatesDialogOwnProps {
    client: Api.ClientModel;
}

class QuotationTemplatesDialog extends React.Component<QuotationTemplatesDialogProps, {}> {

    get isManager(): boolean {
        return this.props.client.subscriptions
            .some(x => x.subscriptionType === "Okargo" && x.allowQuotationManagement);
    }

    public render() {
        return (
            <DialogModal
                isOpen={this.props.templateState.listingIsOpen}
                contentLabel={"Templates"}
                maxWidth={"md"}
                onRequestClose={() => this.props.quotationCloseTemplateListing()}>
                <div>
                    <div>
                        <Table
                            tableKey={"quotationTemplates"}
                            data={this.props.quotationTemplates}
                            columns={[
                                {
                                    accessor: d => d.name,
                                    id: "name",
                                    header: <div>{getText("QtnTemplateName")}</div>
                                },
                                {
                                    accessor: d => d,
                                    id: "actions",
                                    header: <div> </div>,
                                    cell: row => {
                                        let quotation = row as Api.QuotationModel;
                                        return <div>
                                            {(this.isManager || quotation.quotationTemplateSettings.templateType === "Normal")
                                                && <a style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                    if (this.props.templateState.templateStates[quotation.quotationId]
                                                        && this.props.templateState.templateStates[quotation.quotationId].isLoading)
                                                        return;

                                                        this.props.requestDeleteTemplate(new Date().getTime(), quotation.quotationId);
                                                        e.preventDefault();
                                                    }}>{getText("GenDelete")}</a>}
                                        </div>
                                    }
                                }
                            ] as Array<Column<Api.QuotationModel>>}
                            rowsPerPage={8}
                            showPageSizeOptions={false}
                            showPagination={true} />
                    </div>
                </div>
            </DialogModal>
        );
    } 
}

export default connect((state: ApplicationState) => ({
    ...state.quotation,
    client: state.account.currentUser
        ? state.account.currentUser.clientModel
        : null
} as any), QuotationStore.actionCreators)(QuotationTemplatesDialog) as any