import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import { AppThunkAction } from './';
import { push } from 'connected-react-router';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { ReceiveCurrentUser } from './Account';
import Download from "downloadjs";
import * as MimeTypes from "mime-types";
import { ResetTodayDate } from './Seed';
import { downloadQuotationTask } from "./Quotation";


export interface QuotationChronoState {
    requestTime?: number;
    isLoading: boolean;
    loadedFilter: Api.QuotationFilterModel;
    quotations: Array<Api.QuotationModel>;
    downloadStates: { [id: number]: RequestTime };
    updateStates: { [id: number]: RequestTime };
    detailsState: QuotationChronoDetailsState;
    commentState: QuotationChronoCommentState;
    duplicateQuotation?: Api.QuotationModel;
    duplicateName?: string;
    downloadCsvState: RequestTime;
}

export interface QuotationChronoDetailsState {
    quotationId?: number;
    isOpen: boolean;
}

export interface QuotationChronoCommentState {
    quotationId?: number;
    isOpen: boolean;
    requestTime?: number;
    isLoading: boolean;
    value: string;
}

interface RequestTime {
    isLoading: boolean;
    requestTime?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface QuotationsRequestQuotations { type: "QUOTATIONS_REQUEST_QUOTATIONS"; payload: { requestTime: number; filter: Api.QuotationFilterModel } };
interface QuotationsReceiveQuotations { type: "QUOTATIONS_RECEIVE_QUOTATIONS"; payload: { requestTime: number; quotations: Array<Api.QuotationModel> }; error?: any };

interface QuotationsRequestDownload { type: "QUOTATIONS_REQUEST_DOWNLOAD"; payload: { requestTime: number; quotationId: number } };
interface QuotationsReceiveDownload { type: "QUOTATIONS_RECEIVE_DOWNLOAD"; payload: { requestTime: number; quotationId: number }; error?: any };

interface QuotationsOpenDetails { type: "QUOTATIONS_OPEN_DETAILS"; payload: { quotationId: number } };
interface QuotationsCloseDetails { type: "QUOTATIONS_CLOSE_DETAILS" };
interface QuotationsSetDuplicate { type: "QUOTATIONS_SET_DUPLICATE"; payload: { quotationId: number; } };

interface QuotationsRequestUpateStatus {
    type: "QUOTATIONS_REQUEST_UPDATE_STATUS";
    payload: { requestTime: number; quotationId: number; }
};
interface QuotationsReceiveUpateStatus {
    type: "QUOTATIONS_RECEIVE_UPDATE_STATUS";
    payload: { requestTime: number; quotationId: number; status: Api.QuotationModelStatusEnum };
    error?: any;
};

interface QuotationsRequestDownloadCsv {
    type: "QUOTATIONS_REQUEST_DOWNLOADCSV";
    payload: { requestTime: number; }
};
interface QuotationsReceiveDownloadCsv {
    type: "QUOTATIONS_RECEIVE_DOWNLOADCSV";
    payload: { requestTime: number; }
    error?: any;
};

interface QuotationsOpenComment {
    type: "QUOTATIONS_OPEN_COMMENT";
    payload: { quotationId: number; }
};
interface QuotationsCloseComment {
    type: "QUOTATIONS_CLOSE_COMMENT";
};

interface QuotationsUpdateComment {
    type: "QUOTATIONS_UPDATE_COMMENT";
    payload: { value: string }
};


interface QuotationsRequestUpateComment {
    type: "QUOTATIONS_REQUEST_UPDATE_COMMENT";
    payload: { requestTime: number; quotationId: number; }
};
interface QuotationsReceiveUpateComment {
    type: "QUOTATIONS_RECEIVE_UPDATE_COMMENT";
    payload: { requestTime: number; quotationId: number; value: string };
    error?: any;
};

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ReceiveCurrentUser
    | QuotationsRequestQuotations | QuotationsReceiveQuotations
    | QuotationsRequestDownload | QuotationsReceiveDownload
    | QuotationsOpenDetails | QuotationsCloseDetails
    | QuotationsSetDuplicate
    | ResetTodayDate
    | QuotationsRequestUpateStatus
    | QuotationsReceiveUpateStatus
    | QuotationsRequestDownloadCsv
    | QuotationsReceiveDownloadCsv
    | QuotationsOpenComment
    | QuotationsCloseComment
    | QuotationsRequestUpateComment
    | QuotationsReceiveUpateComment
    | QuotationsUpdateComment;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestQuotations: (requestTime: number, filter: Api.QuotationFilterModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().quotationChrono.requestTime === requestTime)
            return Promise.reject("Already did");

        let api = new Api.QuotationApi();
        let task = api.searchQuotations({
            model: filter
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(quotations => {
                dispatch({ type: "QUOTATIONS_RECEIVE_QUOTATIONS", payload: { requestTime: requestTime, quotations: quotations } });
            }).catch(error => {
                dispatch({ type: "QUOTATIONS_RECEIVE_QUOTATIONS", payload: { requestTime: requestTime, quotations: [] }, error: error });
                dispatch(Notifications.error({ message: "Error loading your quotations", title: "Error", position: "tc" }) as any);
            });

        addTask(task);
        dispatch({ type: "QUOTATIONS_REQUEST_QUOTATIONS", payload: { requestTime: requestTime, filter: filter } });
        return task;
    },
    requestDownloadQuotation: (requestTime: number, quotationId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return downloadQuotationTask(requestTime, quotationId, dispatch, getState);
    },
    requestUpdateQuotationStatus: (requestTime: number, quotationId: number, status: Api.QuotationModelStatusEnum): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.QuotationApi();
        let fetchTask = api.updateQuotationStatus(
            {
                quotationId: quotationId,
                status: status
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(receivedStatus => {
                dispatch({
                    type: "QUOTATIONS_RECEIVE_UPDATE_STATUS", payload: {
                        quotationId: quotationId,
                        requestTime: requestTime,
                        status: receivedStatus as Api.QuotationModelStatusEnum
                    }
                });
            })
            .catch(error => {
                dispatch({
                    type: "QUOTATIONS_RECEIVE_UPDATE_STATUS", payload: {
                        quotationId: quotationId,
                        requestTime: requestTime,
                        status: null
                    },
                    error: error
                });
                dispatch(Notifications.error({ message: "Error updating your quotation", title: "Error", position: "tc" }) as any);
            });

        addTask(fetchTask);
        dispatch({ type: "QUOTATIONS_REQUEST_UPDATE_STATUS", payload: { quotationId: quotationId, requestTime: requestTime } });
        return fetchTask;
    },
    openQuotationDetails: (quotationId: number) => <QuotationsOpenDetails>{
        type: "QUOTATIONS_OPEN_DETAILS",
        payload: { quotationId: quotationId }
    },
    closeQuotationDetails: () => <QuotationsCloseDetails>{
        type: "QUOTATIONS_CLOSE_DETAILS"
    },
    openQuotationComment: (quotationId: number) => <QuotationsOpenComment>{
        type: "QUOTATIONS_OPEN_COMMENT",
        payload: { quotationId: quotationId }
    },
    closeQuotationComment: () => <QuotationsCloseComment>{
        type: "QUOTATIONS_CLOSE_COMMENT"
    },
    updateComment: (value: string) => <QuotationsUpdateComment>{
        type: "QUOTATIONS_UPDATE_COMMENT",
        payload: { value: value }
    },
    setQuotationsDuplicate: (quotationId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let quotation = getState().quotationChrono.quotations
            .find(x => x.quotationId === quotationId);
        dispatch({
            type: "QUOTATIONS_SET_DUPLICATE",
            payload: { quotationId: quotation.quotationId }
        });
        dispatch(push("/selection/" + quotation.criteria.code) as any);
    },
    requestDownloadQuotationCsv: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.QuotationApi();
        let fetchTask = api.downloadQuotationsCsv({
            model: getState().quotationChrono.loadedFilter
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({
                    type: "QUOTATIONS_RECEIVE_DOWNLOADCSV",
                    payload: { requestTime: requestTime }
                });
                let fileName = `Chrono_Quotations_${Moment().format("YYYYMMDD_HHmmSS")}.csv`;
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            })
            .catch(error => {
                dispatch({
                    type: "QUOTATIONS_RECEIVE_DOWNLOADCSV",
                    payload: { requestTime: requestTime },
                    error: error
                });
                dispatch(Notifications.error({ message: "Error downloading your csv", title: "Error", position: "tc" }) as any);
            });

        dispatch({
            type: "QUOTATIONS_REQUEST_DOWNLOADCSV",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdateComment: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.QuotationApi();
        let quotationId = getState().quotationChrono.commentState.quotationId;
        let comment = getState().quotationChrono.commentState.value;
        let fecthTask = api.updateQuotationComment({ quotationId: quotationId, comment: comment },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then((value) => {
                dispatch({
                    type: "QUOTATIONS_RECEIVE_UPDATE_COMMENT",
                    payload: {
                        requestTime: requestTime,
                        quotationId: quotationId,
                        value: comment
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "QUOTATIONS_RECEIVE_UPDATE_COMMENT",
                    payload: {
                        requestTime: requestTime,
                        quotationId: quotationId,
                        value: comment
                    },
                    error: err
                });
            });

        dispatch({
            type: "QUOTATIONS_REQUEST_UPDATE_COMMENT",
            payload: { requestTime: requestTime, quotationId: quotationId }
        });
        return fecthTask;
    }
};
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: QuotationChronoState = {
    isLoading: false,
    requestTime: 0,
    loadedFilter: {
        dateBegin: Moment().add(-7, 'days').toDate(),
        dateEnd: Moment().toDate()
    },
    quotations: [],
    downloadStates: {},
    updateStates: {},
    detailsState: {
        isOpen: false,
    },
    commentState: {
        isOpen: false,
        isLoading: false,
        value: ""
    },
    downloadCsvState: {
        isLoading: false,
        requestTime: 0
    }
};

export const reducer: Reducer<QuotationChronoState> = (state: QuotationChronoState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "RECEIVE_CURRENT_USER":
            return unloadedState;
        case "QUOTATIONS_REQUEST_QUOTATIONS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime,
                loadedFilter: action.payload.filter
            };
        case "QUOTATIONS_RECEIVE_QUOTATIONS":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                quotations: action.error
                    ? state.quotations
                    : action.payload.quotations
            };
        case "QUOTATIONS_REQUEST_DOWNLOAD":
            return {
                ...state,
                downloadStates: {
                    ...state.downloadStates,
                    [action.payload.quotationId]: {
                        ...state.downloadStates[action.payload.quotationId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "QUOTATIONS_RECEIVE_DOWNLOAD":
            if (state.downloadStates[action.payload.quotationId]
                && state.downloadStates[action.payload.quotationId].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                downloadStates: {
                    ...state.downloadStates,
                    [action.payload.quotationId]: {
                        ...state.downloadStates[action.payload.quotationId],
                        isLoading: false
                    }
                }
            };
        case "QUOTATIONS_REQUEST_UPDATE_STATUS":
            return {
                ...state,
                updateStates: {
                    ...state.updateStates,
                    [action.payload.quotationId]: {
                        ...state.updateStates[action.payload.quotationId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "QUOTATIONS_RECEIVE_UPDATE_STATUS":
            if (state.updateStates[action.payload.quotationId]
                && state.updateStates[action.payload.quotationId].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                updateStates: {
                    ...state.updateStates,
                    [action.payload.quotationId]: {
                        ...state.updateStates[action.payload.quotationId],
                        isLoading: false
                    }
                },
                quotations: action.error
                    ? state.quotations
                    : state.quotations
                        .map(x => x.quotationId === action.payload.quotationId ? { ...x, status: action.payload.status } : x)
            };
        case "QUOTATIONS_OPEN_DETAILS":
            return {
                ...state,
                detailsState: {
                    ...state.detailsState,
                    isOpen: true,
                    quotationId: action.payload.quotationId
                }
            };
        case "QUOTATIONS_CLOSE_DETAILS":
            return {
                ...state,
                detailsState: {
                    ...state.detailsState,
                    isOpen: false
                }
            };
        case "QUOTATIONS_SET_DUPLICATE":
            return {
                ...state,
                duplicateQuotation: state.quotations
                    .find(x => x.quotationId === action.payload.quotationId)
            };
        case "RESET_TODAY_DATE":
            return {
                ...state,
                loadedFilter: {
                    ...state.loadedFilter,
                }
            };
        case "QUOTATIONS_REQUEST_DOWNLOADCSV":
            return {
                ...state,
                downloadCsvState: {
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "QUOTATIONS_RECEIVE_DOWNLOADCSV":
            return {
                ...state,
                downloadCsvState: {
                    isLoading: action.payload.requestTime === state.downloadCsvState.requestTime
                        ? false
                        : state.downloadCsvState.isLoading
                }
            };
        case "QUOTATIONS_OPEN_COMMENT":
            return {
                ...state,
                commentState: {
                    ...state.commentState,
                    isOpen: true,
                    quotationId: action.payload.quotationId,
                    value: state.quotations.find(x => x.quotationId === action.payload.quotationId).comment
                }
            };
        case "QUOTATIONS_CLOSE_COMMENT":
            return {
                ...state,
                commentState: {
                    ...state.commentState,
                    isOpen: false,
                    quotationId: undefined
                }
            };
        case "QUOTATIONS_REQUEST_UPDATE_COMMENT":
            return {
                ...state,
                commentState: {
                    ...state.commentState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "QUOTATIONS_RECEIVE_UPDATE_COMMENT":
            return {
                ...state,
                commentState: {
                    ...state.commentState,
                    isLoading: false,
                    value: action.error
                        ? state.quotations.find(x => x.quotationId === action.payload.quotationId).comment
                        : action.payload.value
                },
                quotations: action.error
                    ? state.quotations
                        .map(x => x.quotationId === action.payload.quotationId
                        ? {
                            ...x,
                            comment: action.payload.value
                        }
                        : x)
                    : state.quotations
            };
        case "QUOTATIONS_UPDATE_COMMENT":
            return {
                ...state,
                commentState: {
                    ...state.commentState,
                    value: action.payload.value
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
