import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as SelectionStore from '../store/Selection';
import * as CriteriaState from '../store/Criteria';
import * as Api from '../api/api';
import DatePicker from './DatePicker';
import CriteriaLocationSelect from '../components/CriteriaLocationSelect';
import {  getSubscription } from '../utils/utils';
import { getText } from "../utils/langTexts";
import Button from './Button';
import * as Colors from "../styles/colors";
import { isSmallScreen } from '../utils/screenUtils';
import * as ReduxForm from "redux-form";
import * as FormField from "./FormField";

type SelectionCriteriaProps = SelectionCriteriaOwnProps
    & SelectionCriteriaExternalProps
    & CriteriaState.CriteriaState
    & typeof CriteriaState.actionCreators
    & typeof SelectionStore.actionCreators
    ;

interface SelectionCriteriaOwnProps {
    advCriteriaOpened: boolean;
    selectionIsLoading: boolean;
    subscription: Api.SubscriptionModel;
}

interface SelectionCriteriaExternalProps {
    criteriaType: Api.CriteriaModelTypeEnum;
    height?: number;
}

interface SelectionCriteriaState {

}

const formFieldStyle: React.CSSProperties = {
    margin: "0px 10px",
    flex: "0 0 auto"
}

const arrowPadding = 20;

const getInputSize = (): {
    height: number;
    width: number;
    fontSize: number;
    arrowWidth: number;
    arrowMarginRight: number;
} => {
    if (isSmallScreen()) {
        return {
            height: 34,
            width: 160,
            fontSize: 14,
            arrowWidth: 40,
            arrowMarginRight: 10
        };
    } else {
        return {
            height: 45,
            width: 200,
            fontSize: 16,
            arrowWidth: 65,
            arrowMarginRight: 0 //size of img makes the margin here
        };
    }
}

class SelectionCriteria extends React.Component<SelectionCriteriaProps, SelectionCriteriaState> {

    search() {
        if (!this.props.criteria.origin || !this.props.criteria.destination)
            return;

        let promise = (this.props.requestCreateCriteria(new Date().getTime()) as any) as Promise<Api.CriteriaModel>;
        promise.then((criteria) => {
            this.props.setCriteria(criteria);
            this.props.criteriaGo();
        });
    }

    get criteriaDisabled(): boolean {
        return (this.props.subscription && this.props.subscription.subscriptionType === "Agent")
            ? true : false;
    }

    public render() {
        let inputSize = getInputSize();
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", rowGap: 5 }}>
                <div style={{
                    display: "flex", 
                    flex: "0 0 auto",
                    flexDirection: "row",
                    alignItems: "center",
                }}>
                    <div style={{
                        width: inputSize.arrowWidth,
                        color: Colors.baseColors.lightBlue,
                        textAlign: "center",
                        paddingLeft: arrowPadding,
                        marginRight: inputSize.arrowMarginRight
                    }}>
                        <img height={inputSize.height}
                            src={"/images/iconBigArrow.png"}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => this.props.goToCriteria(this.props.criteriaType)} />
                    </div>
                    <div style={{ ...formFieldStyle }}>
                        <DatePicker value={this.props.criteria.dateBegin}
                            style={{ height: inputSize.height }}
                            inputContainerStyle={{ width: inputSize.width }}
                            iconContainerStyle={{
                                height: inputSize.height ,
                                width: inputSize.height,
                                minHeight: inputSize.height,
                                fontSize: inputSize.height - 5
                            }}
                            disabled={this.criteriaDisabled}
                            minDate={null}
                            maxDate={this.props.criteria.dateEnd}
                            onChange={(date) => this.props.selectDateBegin(date)} />
                    </div>
                    <div style={{ ...formFieldStyle }}>
                        <DatePicker value={this.props.criteria.dateEnd}
                            style={{ height: inputSize.height }}
                            inputContainerStyle={{ width: inputSize.width }}
                            iconContainerStyle={{
                                height: inputSize.height,
                                width: inputSize.height,
                                minHeight: inputSize.height,
                                fontSize: inputSize.height - 5
                            }}
                            disabled={this.criteriaDisabled}
                            minDate={this.props.criteria.dateBegin}
                            maxDate={null}
                            onChange={(date) => this.props.selectDateEnd(date)} />
                    </div>
                </div>
                <div style={{
                    display: "flex", 
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                    <div style={{ flex: "0 0 auto", width: inputSize.arrowWidth + inputSize.arrowMarginRight }}>
                    </div>
                    <div style={{ ...formFieldStyle }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", rowGap: "5" }}>
                            <CriteriaLocationSelect inputKey={"CrtSlcOrigin"}
                                                    disabled={this.criteriaDisabled}
                                                    style={{ width: inputSize.width }}
                                                    inputStyle={{ height: inputSize.height, width: inputSize.width + 20 }}
                                                    buttonStyle={{
                                                        height: inputSize.height,
                                                        width: inputSize.height,
                                                        minHeight: inputSize.height,
                                                        fontSize: inputSize.height - 5
                                                    }}
                                                    value={this.props.criteria.origin}
                                                    locationSearchType={"Normal"}
                                                    onChange={(value) => this.props.selectLocation(CriteriaState.Source.Origin, value)} />
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 8 }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <input type={"radio"}
                                           style={{ width: 16, height: 16, accentColor: '#00A1C4' }}
                                           checked={this.props.criteria.originShippingType === "Cy"}
                                           onChange={() => this.props.updateOriginShippingType("Cy")} />
                                    <div>CY</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <input type={"radio"}
                                           style={{ width: 16, height: 16, accentColor: '#00A1C4' }}
                                           checked={this.props.criteria.originShippingType === "Door"}
                                           onChange={() => this.props.updateOriginShippingType("Door")} />
                                    <div>Door</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ ...formFieldStyle }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", rowGap: "5" }}>
                            <CriteriaLocationSelect inputKey={"CrtSlcDestination"}
                                style={{ width: inputSize.width }}
                                inputStyle={{ height: inputSize.height, width: inputSize.width + 20 }}
                                buttonStyle={{
                                    height: inputSize.height,
                                    width: inputSize.height,
                                    minHeight: inputSize.height,
                                    fontSize: inputSize.height - 5
                                }}
                                disabled={this.criteriaDisabled}
                                value={this.props.criteria.destination}
                                locationSearchType={"Normal"}
                                onChange={(value) => this.props.selectLocation(CriteriaState.Source.Destination, value)} />
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 12 }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <input type={"radio"}
                                           style={{ width: 16, height: 16, accentColor: '#00A1C4' }}
                                           checked={this.props.criteria.destinationShippingType === "Cy"}
                                           onChange={() => this.props.updateDestinationShippingType("Cy")} />
                                    <div>CY</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <input type={"radio"}
                                           style={{ width: 16, height: 16, accentColor: '#00A1C4' }}
                                           checked={this.props.criteria.destinationShippingType === "Door"}
                                           onChange={() => this.props.updateDestinationShippingType("Door")} />
                                    <div>Door</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 1, maxWidth: 100 }}></div>
                    <div style={{ ...formFieldStyle }}>
                        <Button
                            style={{
                                color: "white",
                                backgroundColor: Colors.baseColors.lightBlue,
                                height: inputSize.height,
                                border: 0,
                                width: 120,
                                ...(!this.props.criteria.criteriaSizeTypes.some(x => 0 < x.number) 
                                    ? { backgroundColor: "rgb(111, 111, 110)" } 
                                    : {})
                            }}
                            disabled={this.props.selectionIsLoading
                                || this.props.isLoading
                                || !this.props.criteria.criteriaSizeTypes.some(x => 0 < x.number)
                                || this.criteriaDisabled}
                            onClick={() => {
                                this.search();
                            }}>{getText("SlcCrtSearch")}</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default
    connect((state: ApplicationState) => {
            return {
                ...state.criteria,
                advCriteriaOpened: state.selection.advCriteriaOpened,
                selectionIsLoading: state.selection.isLoading,
                subscription: getSubscription(state.account.currentUser.clientModel)
                    || { subscriptionType: null }
            } as SelectionCriteriaProps
    },
        {
            ...CriteriaState.actionCreators,
            ...SelectionStore.actionCreators,
        })
        (SelectionCriteria) as any as React.ComponentClass<SelectionCriteriaExternalProps>