import React from "react";
import {
    useListRatesExtractionQuery,
    useCreateRatesExtractionMutation,
    useUpdateRatesExtractionMutation,
    RatesExtractionModel,
    useDownloadRatesExtractionMutation,
    useDeleteRatesExtractionMutation,
} from "../api/rtkApi";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import Loader from "./Loader";
import Table, {Column} from "./Table";
import {getLocationName} from "../utils/utils";
import ratesExtraction, {
    carriersSelector,
    ratesExtractionDownloadIdSelector,
    ratesExtractionUpdateIdSelector, updateRatesExtractionDownloadId
} from "../slices/ratesExtraction";
import {getText} from "../utils/langTexts";
import CenteredContentTabs from "./CenteredContentTabs";
import {RatesExtractionForm} from "./RatesExtractionForm";
import { updateRatesExtractionUpdateId } from "../slices/ratesExtraction";
import {RatesExtractionDownload} from "./RatesExtractionDownload";
import {titleStyle} from "../styles/baseStyles";


export default function RatesExtraction({}: {}) {

    const {
        data: ratesExtractions,
        isLoading: ratesExtractionsIsLoading,
        isFetching: ratesExtractionsIsFetching,
        refetch: refetchList
    } = useListRatesExtractionQuery({});

    const [createRatesExtraction, { isLoading:  createRatesExtractionIsLoading, data: createRatesExtractionData }] = useCreateRatesExtractionMutation();
    const [updateRatesExtraction, { isLoading:  updateRatesExtractionIsLoading, data: updateRatesExtractionData }] = useUpdateRatesExtractionMutation();
    const [downloadRatesExtraction, { isLoading:  downloadRatesExtractionIsLoading, data: downloadRatesExtractionData }] = useDownloadRatesExtractionMutation();
    const [deleteRatesExtraction, { isLoading:  deleteRatesExtractionIsLoading, data: deleteRatesExtractionData }] = useDeleteRatesExtractionMutation();
    const carriers = useAppSelector(carriersSelector);
    const ratesExtractionIdUpdate = useAppSelector(ratesExtractionUpdateIdSelector);
    const ratesExtractionDownloadId = useAppSelector(ratesExtractionDownloadIdSelector);
    
    const dispatch = useAppDispatch();
    
    if(ratesExtractionsIsLoading){
        return (<Loader isVisible={true} />);
    }

    return (<div style={{
        display: "grid",
        gridTemplateRows: "auto auto 1fr",
        gridTemplateColumns: "1fr",
        gap: "calc(1vw + 9px)",
        padding: "calc(1vw + 50px)"
    }}>
        <div style={{gridRow: "1 / 2", gridColumn: "1 / 2", ...titleStyle}}>
            Rates extraction tool
        </div>
        <div style={{gridRow: "2 / 3", gridColumn: "1 / 2"}}>

        </div>
        <div style={{gridRow: "3 / 4", gridColumn: "1 / 2", minHeight: "calc(5vw + 200px)"}}>
            <Table
                tableKey={"selectionOffers"}
                size={"small"}
                data={ratesExtractions || []}
                columns={[
                    {
                        header: "Name",
                        id: 'name',
                        accessor: (d: RatesExtractionModel) => d.name
                    },
                    {
                        header: "POLs",
                        id: 'pols',
                        accessor: (d: RatesExtractionModel) => d.ratesExtractionPols
                            .map(x => getLocationName(x.port))
                            .join(", ")
                    },
                    {
                        header: "PODs",
                        id: 'pods',
                        accessor: (d: RatesExtractionModel) => d.ratesExtractionPods
                            .map(x => getLocationName(x.port))
                            .join(", ")
                    },
                    {
                        header: "Carriers",
                        id: 'ratesExtractionCarriers',
                        accessor: (d: RatesExtractionModel) => d.ratesExtractionCarriers
                            .map(x => carriers[x.carrierId].name)
                            .join(", ")
                    },
                    {
                        header: "Container type",
                        id: 'containerType',
                        accessor: (d: RatesExtractionModel) => d.containerType
                    },
                    {
                        header: "Rates type",
                        id: 'ratesType',
                        accessor: (d: RatesExtractionModel) => d.ratesType ? d.ratesType : "All"
                    },
                    {
                        header: "Rates source",
                        id: 'ratesSourceFlag',
                        accessor: (d: RatesExtractionModel) => !d.ratesSourceFlag ? "All" : d.ratesSourceFlag.join(", ")
                    },
                    {
                        header: "Validity type",
                        id: 'validityType',
                        accessor: (d: RatesExtractionModel) => d.validityType ? d.validityType : "All"
                    },
                    {
                        header: "Surcharges",
                        id: 'applicationFlag',
                        accessor: (d: RatesExtractionModel) => !d.applicationFlag ? "All" : d.applicationFlag.join(", ")
                    },
                    {
                        header: '',
                        id: 'actions',
                        accessor: (d: RatesExtractionModel) => d.ratesExtractionId,
                        cell: (d: any) => {
                            let isSelected = d === ratesExtractionDownloadId || d === ratesExtractionIdUpdate;
                            return <div
                                style={{display: "flex", flexDirection: "row", alignItems: "center", columnGap: "8px"}}>
                                <div
                                    className="hover-blue"
                                    style={{cursor: "pointer"}}
                                    onClick={(e) => {
                                        deleteRatesExtraction({ratesExtractionId: d});
                                    }}>{getText("GenDelete")}</div>
                                <div
                                    className="hover-blue"
                                    style={{cursor: "pointer", ...(d === ratesExtractionIdUpdate ? {color: "rgb(0, 175, 213)"} : {})}}
                                    onClick={(e) => {
                                        dispatch(updateRatesExtractionUpdateId(d))
                                        dispatch(updateRatesExtractionDownloadId(null))
                                        dispatch({type: "SELECT_TAB", namespace: "ratesExtraction", name: "edit"})
                                    }}>{getText("GenEdit")}</div>
                                <div
                                    className="hover-blue"
                                    style={{cursor: "pointer", ...(d === ratesExtractionDownloadId ? {color: "rgb(0, 175, 213)"} : {})}}
                                    onClick={(e) => {
                                        dispatch(updateRatesExtractionDownloadId(d))
                                        dispatch(updateRatesExtractionUpdateId(null))
                                        dispatch({type: "SELECT_TAB", namespace: "ratesExtraction", name: "download"})
                                    }}>{getText("GenDownload")}</div>
                            </div>
                        }
                    },
                ]}
                showPagination={false}
                headTrStyle={{}}
                bodyTrStyle={(d) => {
                    return {};
                }}
                onThClick={(col) => {
                }}
                onBodyTrClick={(d) => {
                }}
            />
        </div>
        <div style={{gridRow: "4 / 5", gridColumn: "1 / 2"}}>
            <CenteredContentTabs
                tabKey={"ratesExtraction"}
                defaultTab={"create"}
                data={[
                    {
                        title: getText("GenCreate"),
                        tabKey: "create",
                        tabContent: <div style={{padding: 10}}>
                            <h3>{getText("GenCreate")}</h3>
                            <div style={{maxWidth: 1000}}>
                                <RatesExtractionForm type={"Create"} defaultValues={{
                                    ratesType: null,
                                    validityType: null,
                                    ratesSourceFlag: ["IntegratedPl", "Ocq", "SelfUpload"],
                                    containerType: "Dry",
                                    applicationFlag: ["Origin", "Destination", "Prepaid", "Collect"],
                                    currencyId: 1
                                }}
                                                     disabled={createRatesExtractionIsLoading}
                                                     onSubmit={(ratesExtraction) => createRatesExtraction(ratesExtraction)}/>
                            </div>
                        </div>
                    },
                    {
                        title: getText("GenEdit"),
                        tabKey: "edit",
                        disabled: !ratesExtractionIdUpdate,
                        tabContent: <div style={{padding: 10}}>
                            <div style={{maxWidth: 1000}}>
                                <h3>{getText("GenEdit")}</h3>
                                <div style={{maxWidth: 1000}}>
                                    {ratesExtractionIdUpdate && <RatesExtractionForm type={"Update"}
                                                                                     defaultValues={ratesExtractions.find(x => x.ratesExtractionId === ratesExtractionIdUpdate) || {}}
                                                                                     disabled={updateRatesExtractionIsLoading}
                                                                                     onSubmit={(ratesExtraction) => updateRatesExtraction(ratesExtraction)}/>}
                                </div>
                            </div>
                        </div>
                    },
                    {
                        title: getText("GenDownload"),
                        tabKey: "download",
                        disabled: !ratesExtractionDownloadId,
                        tabContent: <div style={{padding: 10}}>
                            <div style={{maxWidth: 1000}}>
                                <h3>{getText("GenDownload")}</h3>
                                <div style={{maxWidth: 1000, display: "flex"}}>
                                    {ratesExtractionDownloadId
                                        && ratesExtractions.find(x => x.ratesExtractionId === ratesExtractionDownloadId)
                                        && <RatesExtractionDownload
                                            ratesExtraction={ratesExtractions.find(x => x.ratesExtractionId === ratesExtractionDownloadId)}
                                            onDownload={(startDate, endDate) => {
                                                return downloadRatesExtraction({
                                                    ratesExtractionId: ratesExtractionDownloadId,
                                                    startDate: startDate,
                                                    endDate: endDate
                                                });
                                            }}/>}
                                </div>
                            </div>
                        </div>
                    }
                ]}
            ></CenteredContentTabs>
        </div>
    </div>);
}