import { isClient } from "./utils";

export const isSmallScreen = (): boolean => {
    if (isClient()) {
        if (window.innerWidth <= 1650) {
            return true;
        }

        return false;
    } else {
        return false;
    }
}