import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getText } from '../utils/langTexts';
import { revertColors } from '../styles/colors';

type InlandRatesOfferFormProps = ReduxForm.InjectedFormProps<{}, {}, string>
    & InlandRatesOfferFormExternalProps
    & InlandRatesOfferFormOwnProps;

interface InlandRatesOfferFormExternalProps {
    inlandRatesOfferId?: number;
    actionName: string;
    hideReset?: boolean;
    onSubmit: (values: Api.InlandRatesOfferModel) => Promise<any>;
}

interface InlandRatesOfferFormOwnProps {
    reference: string;
    inlandCarrierId: number;
    inlandCarriers: { [id: number]: Api.InlandCarrierModel }
}

const textAreaField = FormField.textAreaField(4, 50);

class InlandRatesOfferForm extends React.Component<InlandRatesOfferFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-horizontal container-fluid">
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtRatesInfoEditCarrier")}</label>
                    <div className="col-sm-9">
                        <input className="form-control"
                            disabled
                            value={this.props.inlandCarriers[this.props.inlandCarrierId].name}
                            onChange={(e) => { }} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtRatesInfoEditReference")}</label>
                    <div className="col-sm-9">
                        <input className="form-control"
                            disabled
                            value={this.props.reference}
                            onChange={(e) => { }}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtRatesInfoEditShortname")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            validate={[]}
                            name="shortName"
                            component={FormField.renderField}
                            type="text" />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtRatesInfoEditDescription")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            validate={[]}
                            name="comment"
                            component={textAreaField} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label"></label>
                    <div className="col-sm-9">
                        <button type="submit"
                            style={{ ...revertColors.main3 }}
                            className="btn">{getText("GenSaveChanges")}</button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let InlandRatesOfferFormWrapped = ReduxForm.reduxForm({
    form: 'moderatorEditInlandRatesOffers',
    enableReinitialize: true
})(InlandRatesOfferForm) as any;
const formSelector = ReduxForm.formValueSelector('moderatorEditInlandRatesOffers')


export default connect((state: ApplicationState) => {
    let source = state.inlandRatesOffer.inlandRatesOffers
        .find(ro => ro.inlandRatesOfferId === state.inlandRatesOffer.editState.inlandRatesOfferId);

    return {
        initialValues: source,
        inlandCarriers: state.seed.inlandCarriers,
        inlandCarrierId: formSelector(state, "inlandCarrierId") || source.inlandCarrierId,
        reference: formSelector(state, "reference") || source.reference,
    } as InlandRatesOfferFormOwnProps
})(InlandRatesOfferFormWrapped) as any