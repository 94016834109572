import * as React from 'react';
import * as Api from '../api/api';
import * as Colors from '../styles/colors';
import { CSSProperties } from 'react';
import FLoatingButton from './FloatingButton';
import { Add, Remove } from '@material-ui/icons';

interface CriteriaSizeTypeProps {
    style?: CSSProperties;
    buttonStyle?: CSSProperties;
    iconStyle?: CSSProperties;
    sizeTypeId: number;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    value: number;
    onChange: (value: number) => void;
    min: number;
    max: number;
}

export default class CriteriaSizeType extends React.Component<CriteriaSizeTypeProps, {}> {

    public render() {
        return <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            ...this.props.style
        }}>
            <div style={{ flex: 1, textAlign: "center" }}>
                {this.props.sizeTypes[this.props.sizeTypeId]
                    ? this.props.sizeTypes[this.props.sizeTypeId].name
                    : ''}
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                <FLoatingButton style={{
                    flex: "0 0 auto",
                    width: 30,
                    height: 30,
                    minHeight: 0,
                    ...(this.props.value <= this.props.min ? {
                        backgroundColor: "white",
                        color: Colors.baseColors.darkGray,
                        border: `1px solid ${Colors.baseColors.darkGray}`
                    } : {
                             backgroundColor: Colors.baseColors.lightBlue,
                            color: "white"
                        }),
                    ...this.props.buttonStyle
                }}
                    disabled={this.props.value <= this.props.min}
                    onClick={(e) => this.props.onChange(this.props.value - 1)}>
                    <Remove style={{ ...this.props.iconStyle }} />
                </FLoatingButton>
                <div style={{ flex: 1, padding: "0px 9px", textAlign: "center" }}>
                    {this.props.value}
                </div>
                <FLoatingButton style={{
                    flex: "0 0 auto",
                    width: 30,
                    height: 30,
                    minHeight: 0,
                    ...(this.props.value >= this.props.max ? {
                        backgroundColor: "white",
                        color: Colors.baseColors.darkGray,
                        border: `1px solid ${Colors.baseColors.darkGray}`
                    } : {
                            backgroundColor: Colors.baseColors.lightBlue,
                            color: "white"
                        }),
                    ...this.props.buttonStyle
                }}
                    disabled={this.props.value >= this.props.max}
                    onClick={(e) => this.props.onChange(this.props.value + 1)}>
                    <Add style={{ ...this.props.iconStyle }} />
                </FLoatingButton>
            </div>
        </div>;
    }
}
