import * as React from 'react';
import * as ReduxForm from 'redux-form';
import DatePicker from './DatePicker';
import CheckBox from '../components/CheckBox';
import InputNumber from '../components/InputNumber';
import Select, { Option } from '../components/Select';
import { getText } from "../utils/langTexts";
import LocationSelect from './LocationSelect';
import { CSSProperties } from 'react';
import InputText from './InputText';

export const required = value => (value ? undefined : getText("GenRequired"));
export const requiredOrZero = value => (value || value === 0 ? undefined : getText("GenRequired"));
export const requiredOne = value => (!value || !value.length || value.length === 0 ? "required at least one" : undefined);

export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined

export const minLength = min => value =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined

export const passwordValid = value =>
    value && value.length >= 6
        && (/[A-Z]/.test(value) || /[a-z]/.test(value))
        && /[0-9]/.test(value)
        ? undefined
        : "Your password must contain a letter and a number and be at least 8 characters long";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailValid = value =>
    value && emailRegex.test(value) ? undefined : "Email incorrect";

const phoneRegex = /^[0-9 ]*/;
export const phoneValid = value => !value || phoneRegex.test(value) ? undefined : "A phone must only contains numbers";

export const renderField = ({
    disabled,
    input,
    className,
    placeholder,
    style,
    display,
    type,
    meta: { touched, error, warning }
}) => (
        <div style={{ display: display || "inline-block" }}>
            <div>
                <input placeholder={placeholder} style={style} {...input} className={className} type={type} disabled={disabled} />
            </div>
            <div className="text-danger">
                {touched &&
                    ((error && <span>{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )

type InputTextFieldProps = InputTextFieldOwnProps
    & ReduxForm.WrappedFieldProps;

interface InputTextFieldOwnProps {
    style?: React.CSSProperties;
    type?: string;
}

export class InputTextField extends React.Component<InputTextFieldProps, {}> {
    public render() {
        return (
            <InputText
                {...this.props.input}
                type={this.props.type || "text"}
                style={{ ...this.props.style }}
                value={this.props.input.value}
                onChange={(e) => this.props.input.onChange(e.target.value)}
                error={(this.props.meta.submitFailed
                    || this.props.meta.touched)
                    && this.props.meta.error ? true : false}
                helperText={(this.props.meta.submitFailed
                    || this.props.meta.touched)
                    && this.props.meta.error} />
        );
    }

}

type NumberFieldProps = NumberFieldOwnProps
    & ReduxForm.WrappedFieldProps;

interface NumberFieldOwnProps {
    className: string;
    style?: React.CSSProperties;
    unit?: string;
    unitStyle?: React.CSSProperties;
    allowEmpty?: boolean;
    disabled?: boolean;
}

export class NumberField extends React.Component<NumberFieldProps, {}> {
    public render() {
        return (
            <div>
                <div>
                    <InputNumber
                        disabled={this.props.disabled}
                        className={this.props.className}
                        style={{
                            backgroundColor: this.props.disabled
                                ? "lightgray"
                                : "white",
                            ...this.props.style
                        }}
                        value={this.props.input.value}
                        unit={this.props.unit}
                        unitStyle={this.props.unitStyle}
                        allowEmpty={this.props.allowEmpty}
                        onChange={(value) => this.props.input.onChange(value as any)}
                    />
                </div>
                <FieldErrors {...this.props.meta} />
            </div>
        );
    }

}


type RadioFieldProps = RadioFieldOwnProps
    & ReduxForm.WrappedFieldProps;

interface RadioFieldOwnProps {
    className: string;
    radioValue: any;
    style?: React.CSSProperties;
    label?: string;
    disabled?: boolean;
}

export class RadioField extends React.Component<RadioFieldProps, {}> {
    public render() {
        return (
            <div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer"
                }}
                    onClick={() => {
                        if (!this.props.disabled) {
                            this.props.input.onChange(this.props.radioValue);
                        }
                    }}>
                    <input
                        disabled={this.props.disabled}
                        className={this.props.className}
                        type="radio"
                        checked={this.props.input.value === this.props.radioValue}
                        value={this.props.radioValue}
                        style={{
                            backgroundColor: this.props.disabled
                                ? "lightgray"
                                : "white",
                            ...this.props.style,
                            flex: "0 0 auto",
                            margin: "0px 6px 0px 0px"
                        }} />
                    <div style={{ flex: "0 0 auto" }}>
                        {this.props.label}
                    </div>
                </div>
                <FieldErrors {...this.props.meta} />
            </div>
        );
    }

}


type DatePickerFieldProps = DatePickerFieldOwnProps
    & ReduxForm.WrappedFieldProps;

interface DatePickerFieldOwnProps {
    minDate?: Date;
    maxDate?: Date;
    className?: string;
    setDateButton?: JSX.Element;
    dateToSet?: Date;
    style?: React.CSSProperties;
    iconContainerStyle?: React.CSSProperties;
    inputContainerStyle?: React.CSSProperties;
}

export class DatePickerField extends React.Component<DatePickerFieldProps, {}> {
    public render() {
        return (
            <div>
                <div>
                    <DatePicker
                        style={{ height: 43, ...this.props.style }}
                        iconContainerStyle={{ ...this.props.iconContainerStyle }}
                        inputContainerStyle={{ ...this.props.inputContainerStyle }}
                        value={this.props.input.value}
                        setDateButton={this.props.setDateButton}
                        dateToSet={this.props.dateToSet ? this.props.dateToSet : undefined}
                        onChange={(value) => this.props.input.onChange(value as any)}
                        minDate={this.props.minDate ? this.props.minDate : null}
                        maxDate={this.props.maxDate ? this.props.maxDate : null} />
                </div>
                <FieldErrors {...this.props.meta} />
            </div>
        );
    }

}

export class HiddenField extends React.Component<ReduxForm.WrappedFieldProps, {}> {

    componentWillReceiveProps(nextProps: ReduxForm.WrappedFieldProps) {
        if (nextProps.input.value !== this.props.input.value) {
            nextProps.input.onChange(nextProps.input.value);
        }
    }

    public render() {
        return (
            <input type="hidden"
                value={this.props.input.value}
                onChange={(e) => this.props.input.onChange(e)} />
        );
    }

}

export const textAreaFieldCustom = (row: number, cols: number, placeHolder: string = "") => ({
    input,
    disabled,
    className,
    type,
    meta: { touched, error, warning }
}) => (
        <div>
            <div >
                <textarea
                    disabled={disabled}
                    style={{ borderRadius: 5, borderColor: "black", borderWidth: 0.5, width: 250 }}
                    value={input.value}
                    onChange={(e) => { input.onChange(e.target.value) }}
                    rows={row} cols={cols} placeholder={placeHolder} />
            </div>
            <div className="text-danger">
                {touched &&
                    ((error && <span>{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    );

export const textAreaField = (row: number, cols: number, placeHolder: string = "") => ({
    input,
    disabled,
    className,
    type,
    meta: { touched, error, warning }
}) => (
        <div>
            <div>
                <textarea
                    disabled={disabled}
                    value={input.value}
                    onChange={(e) => { input.onChange(e.target.value) }}
                    rows={row} cols={cols} placeholder={placeHolder} />
            </div>
            <div className="text-danger">
                {touched &&
                    ((error && <span>{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    );

export const checkBoxField = (label: string) => (field: any) => (
    <div>
        <CheckBox
            label={label}
            disabled={field.disabled}
            value={field.input.value}
            labelStyle={field.labelStyle}
            size={field.size || "small"}
            onChange={(value) => field.input.onChange(value)}
        />
        <FieldErrors {...field.meta} />
    </div>
);

export const fileField = (field: any) => (
    <input
        type="file"
        onChange={(e) => field.input.onChange(e.target.files[0])}
    />
);

export const getSelectField = (options: Array<Option>, standard?: boolean): any => ({
    input,
    className,
    type,
    disabled,
    onChange,
    style,
    onValueChange,
    search,
    meta: { touched, error, warning }
}) => (
        <div>
            <Select
                options={options}
                value={input.value}
                disabled={disabled}
                style={style}
                standard={standard}
                onChange={(value) => {
                    input.onChange(value);
                    if (onChange) { //Legacy, to delete later
                        onChange(value);
                    }
                    if (onValueChange) {
                        onValueChange(value);
                    }
                }}
                search={search}
            />
            <div className="text-danger">
                {touched &&
                    ((error && <span>{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    );


export class FieldErrors extends React.Component<(ReduxForm.FieldArrayMetaProps
    | ReduxForm.WrappedFieldMetaProps), {}> {
    public render() {
        return (
            <div>
                <div className="text-danger">
                    {(this.props.submitFailed || (this.props as any).touched)
                        && (this.props.error && <span>{this.props.error}</span>)}
                </div>
                <div className="text-warning">
                    {this.props.warning && <span>{this.props.warning}</span>}
                </div>
            </div>
        );
    }
}

export const getLocationField = (key: string) => ({
    input,
    className,
    meta: { touched, error, warning }
}) => (
        <div>
            <div>
                <LocationSelect
                    className={className || "form-control"}
                    inputKey={key}
                    inputStyle={{ height: 40 }}
                    value={input.value}
                    onChange={(location) => {
                        input.onChange(location);
                    }} />
            </div>
            <div className="text-danger">
                {touched &&
                    ((error && <span>{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    );

interface BaseFormProps {
    className?: string;
    style?: CSSProperties;
    resetOnSubmit?: boolean;
    autoComplete?: string;
}

export class BaseForm extends React.Component<ReduxForm.InjectedFormProps<{}, {}, {}> & BaseFormProps> {

    submitWrapped(e) {
        let maybePromise = (this.props.handleSubmit(e) as any);
        if (maybePromise && maybePromise.then) {
            let promise = maybePromise as Promise<any>;
            promise
                .then(() => {
                    if (this.props.resetOnSubmit !== false) {
                        this.props.reset();
                    }
                });
        }
    }

    public render() {
        return (
            <form
                autoComplete={this.props.autoComplete}
                style={this.props.style}
                onSubmit={(e) => {
                    if (e.preventDefault) {
                        e.preventDefault();
                    }
                    return this.submitWrapped(e);
                }}>
                <div style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div style={{ textAlign: "center" }}>
                    {this.props.warning && <span>{this.props.warning}</span>}
                </div>
                <div>
                    {this.props.children}
                </div>
            </form>
        );
    }
}