import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as InlandSelectionStore from '../store/InlandSelection';
import * as InlandCriteriaState from '../store/InlandCriteria';
import * as ContactBoxStore from '../store/ContactBox';
import { RouteComponentProps } from 'react-router';
import * as Api from '../api/api';
import Loader from './Loader';
import InlandSelectionOffers from "./InlandSelectionOffers";
import * as Colors from "../styles/colors";
import InlandSelectionFilterCriteria from './InlandSelectionFilterCriteria';
import InlandSelectionFilter from './InlandSelectionFilter';
import InlandSelectionCriteria from './InlandSelectionCriteria';
import InlandSelectionOfferTable from './InlandSelectionOffersTable';
import { roadTransportId } from '../utils/constants';
import { RatesCalculator } from '../utils/ratesUtils';

type InlandSelectionProps = InlandSelectionOwnProps
    & typeof InlandSelectionStore.actionCreators
    & typeof InlandCriteriaState.actionCreators
    & typeof ContactBoxStore.actionCreators
    & RouteComponentProps<{ code: string }>
    & InlandSelectionStore.InlandSelectionState

interface InlandSelectionOwnProps {
    feedback: ContactBoxStore.FeedbackState;
    inlandCriteria: Api.InlandCriteriaModel;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
}

const offerPerPage = 6;

const InputStyle: React.CSSProperties = {
    height: "45px",
    width: "150px",
}

class InlandSelection extends React.Component<InlandSelectionProps, {}> {
    ratesCalculator: RatesCalculator;

    updateRatesCalculator(props: InlandSelectionProps) {
        this.ratesCalculator = new RatesCalculator(
            props.currencies,
            props.sizeTypes,
            InlandSelectionStore.inlandCriteriaToCriteria(props.inlandCriteria)
        );
    }

    componentWillMount() {
        this.updateRatesCalculator(this.props);
    }
    
    get inlandCarrierOffers(): Array<Api.InlandCarrierOfferModel> {
        if (!this.props.inlandSelection)
            return [];

        let carrierOffers = this.props.inlandSelection.inlandCarrierOffers
            .filter(x => this.props.filter.inlandCarriersSelected[x.inlandCarrierId] !== false
                && (!x.inlandChargeSet
                    || this.props.inlandCriteria.inlandCriteriaSizeTypes
                        .some(y => 0 < y.number && this.ratesCalculator.findChargesToApply(x.inlandChargeSet, y).some(z => z.chargeNameId === roadTransportId))));

        return _.sortBy(
            carrierOffers.filter(x => !x.inlandChargeSet)
                .concat(_.uniqBy(carrierOffers.filter(x => x.inlandChargeSet),
                    x => `${x.inlandCarrierId}|`
                        + `${x.inlandRoute.inlandRouteId}|`
                        + `${x.inlandChargeSet.reference}|`
                        + `${x.inlandChargeSet.dateBegin}|`
                        + `${x.inlandChargeSet.dateEnd}|`
                        + `${x.inlandChargeSet.pickupEmptyName}|`
                        + `${_.sortBy(_.uniq(x.inlandChargeSet.charges
                            .filter(y => this.props.inlandCriteria.inlandCriteriaSizeTypes
                                .some(z => z.sizeTypeId === y.sizeTypeId && z.number > 0))
                            .map(z => `${z.sizeTypeId}|${z.minWeight}|${z.maxWeight}`)), y => y)}`)), x => (x.inlandChargeSet
                                ? this.ratesCalculator.calculateAllIn(x.inlandChargeSet).totalUsd
                                : Number.MAX_SAFE_INTEGER));
    }

    requestSelection(props: InlandSelectionProps) {
        (this.props.requestInlandCriteria(new Date().getTime(),
            props.match.params.code) as any as Promise<Api.InlandCriteriaModel>)
            .then(inlandCriteria => {
                return this.props.requestInlandSelection(
                    new Date().getTime(),
                    inlandCriteria);
            });
    }

    componentDidMount() {
        if (this.props.match.params.code) {
            this.requestSelection(this.props);
        }
    }

    componentWillReceiveProps(nextProps: InlandSelectionProps) {
        if (this.props.match.params.code !== nextProps.match.params.code
            && nextProps.match.params.code) {
            this.requestSelection(nextProps);
        }

        if (this.props.currencies !== nextProps.currencies
            || this.props.sizeTypes !== nextProps.sizeTypes
            || this.props.inlandCriteria !== nextProps.inlandCriteria) {
            this.updateRatesCalculator(nextProps);
        }
        if (this.props.match.params.code !== nextProps.match.params.code
            && nextProps.match.params.code) {
            this.requestSelection(nextProps);
        }
    }

    public render() {
        return <div style={{ padding: "0px 20px 0px 60px" }}>
            <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                <div style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    padding: "0px 25px",
                }}>
                    <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20 }}>

                    </div>
                </div>
                <div style={{ position: "relative", display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "row" , flexGrow: 1}}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ backgroundColor: "white", fontSize: 14, flex: "0 0 250px", order: 1, border: `1px solid ${Colors.baseColors.darkGray}`, borderRadius: "15px", height: 600, width: 250 }}>
                                <div style={{ padding: "10px 10px 10px 10px" }}>
                                    <div style={{ fontSize: 20, marginBottom: 10, marginTop: 10 }}>Container</div>
                                    <InlandSelectionFilterCriteria />
                                    <InlandSelectionFilter />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                            <div style={{ flex: "0 0 auto" }}>
                                <div style={{ padding: "10px 20px 10px 20px" }}>
                                    <InlandSelectionCriteria />
                                </div>
                            </div>
                            <div style={{ flex: 1, paddingLeft: 25 }}>
                                <div className="sticky-top" //for safary
                                    style={{
                                        position: "sticky",
                                        top: 0,
                                    }}>
                                    <InlandSelectionOfferTable
                                        ratesCalculator={this.ratesCalculator}
                                        inlandCarrierOffersFiltered={this.inlandCarrierOffers}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style={{ flex: 1 }}>
                    <div style={{ paddingTop: "20px" }}>
                        <InlandSelectionOffers
                            ratesCalculator={this.ratesCalculator}
                            inlandCarrierOffersFiltered={this.inlandCarrierOffers}
                        />
                    </div>
                </div>
            </div>
            <Loader
                isVisible={this.props.isLoading}
                top={200}
                size={240}
            />
        </div>
    }
}

export default connect((state: ApplicationState) => ({
    ...state.inlandSelection,
    feedback: state.contactBox,
    inlandCriteria: state.inlandCriteria.inlandCriteria,
    sizeTypes: state.seed.sizeTypes,
    currencies: state.seed.currencies,
} as any), {
    ...InlandSelectionStore.actionCreators,
    ...InlandCriteriaState.actionCreators,
    ...ContactBoxStore.actionCreators,
})(InlandSelection) as any
