import * as Api from '../api/api';
import * as React from "react";
import * as CriteriaStore from '../store/Criteria';
import * as SelectionStore from '../store/Selection';
import * as MultiPortStore from '../store/MultiPort';
import * as StatsStore from '../store/Stats';
import * as Colors from '../styles/colors';
import Button from "./Button";
import ExpensionPanel from "./ExpansionPanel";
import CheckBox from './CheckBox'
import { getText } from "../utils/langTexts";
import { Add, Remove } from "@material-ui/icons";
import { canUseSuggested, canUseCarrierRates } from '../security/userRights';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { getSubscription } from '../utils/utils';
import { Paper } from '@material-ui/core';
import Switch from "./Switch";
import { isSmallScreen } from '../utils/screenUtils';

type SelectionCriteriaAdvancedProps = SelectionCriteriaAdvancedOwmProps
    & CriteriaStore.CriteriaState
    & SelectionCriteriaAdvancedExternalProps
    & typeof SelectionStore.actionCreators
    & typeof CriteriaStore.actionCreators
    & typeof MultiPortStore.actionCreators
    & typeof StatsStore.actionCreators
    ;

interface SelectionCriteriaAdvancedOwmProps {
    advCriteriaOpened: boolean;
    selectionIsLoading: boolean;
    multiPortIsVisible: boolean;
    criteriaLoaded: Api.CriteriaModel;
    subscription: Api.SubscriptionModel;
    subscriptions: Array<Api.SubscriptionModel>;
}

interface SelectionCriteriaAdvancedExternalProps {
    criteriaType: Api.CriteriaModelTypeEnum;
}

const checkBoxLabelStyle: React.CSSProperties = {
    fontSize: 14,
    minWidth: 240
}


const getButtonSize = (): {
    height: number;
} => {
    if (isSmallScreen()) {
        return {
            height: 30,
        };
    } else {
        return {
            height: 36,
        };
    }
}

class SelectionCriteriaAdvanced extends React.Component<SelectionCriteriaAdvancedProps, {}> {

    get loadedAdvCount(): number {
        return this.props.criteriaLoaded
            ? ((this.props.criteriaLoaded.suggested ? 1 : 0)
                + (this.props.criteriaLoaded.includeCarrierRates ? 1 : 0)
                + (this.props.criteriaLoaded.lastRates ? 1 : 0)
                + (this.props.criteriaLoaded.allMarkets ? 1 : 0)
                + (this.props.criteriaLoaded.includeSellingRates ? 1 : 0))
            : 0;
    }

    public render() {
        let buttonSize = getButtonSize();

        return (
            <div>
                <ExpensionPanel
                    style={{ margin: 0 }}
                    expanded={this.props.advCriteriaOpened}
                    onChange={() => { }}
                    head={<div></div>}>
                    <Paper style={{
                        width: "100%",
                        border: `1px solid ${Colors.baseColors.lightGray}`,
                        padding: "25px 25px 25px 25px",
                        borderRadius: 12
                    }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "stretch", width: "100%" }}>
                            <div style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <div>
                                    <CheckBox
                                        labelStyle={{ ...checkBoxLabelStyle, minWidth: 120 }}
                                        size={"small"}
                                        label={getText("CrtShowSellingRates")}
                                        value={this.props.criteria.includeSellingRates}
                                        onChange={(v) => this.props.updateIncludeSellingRates(v)} />
                                </div>
                            </div>
                            <div style={{ flex: 1 }}>

                            </div>
                            <div style={{ flex: 1 }}>
                                <div>
                                    {canUseSuggested(this.props.subscription)
                                        && <CheckBox size={"small"}
                                            labelStyle={checkBoxLabelStyle}
                                            label={getText("CrtIncludeSuggested")}
                                            onChange={(value) => this.props.selectSuggested(value)}
                                            value={this.props.criteria.suggested} />}
                                </div>
                                <div>
                                    {canUseCarrierRates(this.props.subscription)
                                        && <CheckBox size={"small"}
                                            labelStyle={checkBoxLabelStyle}
                                            label={getText("CrtIncludeCarrierRates")}
                                            onChange={(value) => this.props.selectCarrierRates(value)}
                                            value={this.props.criteria.includeCarrierRates} />}
                                </div>
                            </div>
                            <div style={{ flex: 1 }}>

                            </div>
                            <div style={{ flex: 1 }}>
                                <div>
                                    <CheckBox size={"small"}
                                        labelStyle={checkBoxLabelStyle}
                                        label={getText("CrtIncludeLastRates")}
                                        onChange={(value) => this.props.selectLastRates(value)}
                                        value={this.props.criteria.lastRates} />
                                </div>
                                {this.props.subscriptions.some(x => x.subscriptionType === "Okargo"
                                    && x.agency.agencyMarkets && x.agency.agencyMarkets.length > 0)
                                    && <div>
                                        <CheckBox size={"small"}
                                            labelStyle={checkBoxLabelStyle}
                                            label={getText("CrtAllMarkets")}
                                            onChange={(value) => this.props.selectAllMarket(value)}
                                            value={this.props.criteria.allMarkets} />
                                    </div>}
                            </div>
                            <div style={{ flex: 1 }}>

                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{
                                    flex: 1,
                                    fontWeight: 500,
                                    fontSize: 21,
                                    textAlign: "right",
                                    paddingRight: 30,
                                    minWidth: 180
                                }}>Multi port Mode</div>
                                <div style={{ flex: "0 0 auto" }}>
                                    <Switch value={this.props.multiPortIsVisible}
                                        onChange={(v) => this.props.toggleMultiPortVisible(v)} />
                                </div>
                            </div>
                        </div>
                    </Paper>
                </ExpensionPanel>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%"
                }}>
                    <div>
                        <Button
                            style={{
                                position: "relative",
                                backgroundColor: "white",
                                height: buttonSize.height,
                                ...(this.props.advCriteriaOpened
                                    ? {
                                        position: "relative",
                                        bottom: buttonSize.height / 2
                                    }
                                    : {})
                            }}
                            onClick={(e) => this.props.toggleAdvCriteria(this.props.advCriteriaOpened ? false : true)}>
                            Options {this.props.advCriteriaOpened
                                ? <Remove style={{ fontSize: 15, fontWeight: "bold" }} />
                                : <Add style={{ fontSize: 15, fontWeight: "bold" }} />}
                            {!this.props.advCriteriaOpened
                                && this.loadedAdvCount !== 0
                                && <div style={{
                                position: "absolute",
                                top: -15, right: -5,
                                borderRadius: 25,
                                width: 25,
                                height: 25,
                                textAlign: "center",
                                fontSize: 18,
                                backgroundColor: "white",
                                color: Colors.baseColors.lightBlue,
                                border: `1px solid ${Colors.baseColors.lightBlue}`,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "row"
                            }}><div style={{ textAlign: "center", width: "100%", lineHeight: "25px" }}>{this.loadedAdvCount}</div></div>}
                        </Button>
                    </div>
                </div>
            </div>
            );
    }
}

export default
    connect((state: ApplicationState) => {
        return {
            ...state.criteria,
            advCriteriaOpened: state.selection.advCriteriaOpened,
            selectionIsLoading: state.selection.isLoading,
            multiPortIsVisible: state.multiPort.isVisible,
            subscription: getSubscription(state.account.currentUser.clientModel)
                || { subscriptionType: null },
            subscriptions: state.account.currentUser.clientModel.subscriptions,
            criteriaLoaded: state.selection.criteriaLoaded
        } as any
    },
        {
            ...StatsStore.actionCreators,
            ...MultiPortStore.actionCreators,
            ...CriteriaStore.actionCreators,
            ...SelectionStore.actionCreators,
        })
        (SelectionCriteriaAdvanced) as any as React.ComponentClass<SelectionCriteriaAdvancedExternalProps>