import * as React from 'react';
import { getText } from '../utils/langTexts';
import * as Colors from '../styles/colors';

interface SendEmailProps {
    isLoading: boolean;
    to: string;
    cc: string;
    subject: string;
    content: string;
    backLabel: string;
    toTextarea?: boolean;
    send: () => void;
    goBack: () => void;
    onToUpdate: (value: string) => void;
    onCcUpdate: (value: string) => void;
    onSubjectUpdate: (value: string) => void;
    onContenttUpdate: (value: string) => void;
}

interface SendEmailState {

}

const fieldStyle = { marginBottom: 5 } as React.CSSProperties;
const labelStyle = { width: 50, display: "inline-block", fontFamily: "Calibri", textAlign: "right", marginRight: 10, paddingTop: 7, color: "Colors.baseColors.darkGray" } as React.CSSProperties;
const contentStyle = { width: "100%", minHeight: 300, paddingTop: 5, borderRadius: 5, borderWidth: 0.5, border: "thin solid " } as React.CSSProperties;
const btnStyleRight = { backgroundColor: "white", color: Colors.baseColors.lightBlue, borderRadius: 10, borderWidth: 0.5, fontWeight: "bold", flex: 1, height: 30, float: "right" } as React.CSSProperties;
const btnStyleLeft = { backgroundColor: "white", color: Colors.baseColors.lightBlue, borderRadius: 10, borderWidth: 0.5, fontWeight: "bold", flex: 1, height: 30, float: "left"} as React.CSSProperties;
const inputStyle = { height: 30, backgroundColor: "white", marginBottom: 10, borderRadius: 5, borderColor: "black", borderWidth: 0.5, minWidth: 450 } as React.CSSProperties;


export default class SendEmail extends React.Component<SendEmailProps, SendEmailState> {

    public render() {
        return (
            <div>
                <div style={fieldStyle}>
                    <label style={labelStyle}>{getText("GenEmailTo")}</label>
                    {this.props.toTextarea
                        && <textarea value={this.props.to}
                            style={inputStyle}
                            rows={3}
                            onChange={(e) => this.props.onToUpdate(e.target.value)}></textarea>}
                    {!this.props.toTextarea
                        && <input value={this.props.to}
                            style={inputStyle}
                            onChange={(e) => this.props.onToUpdate(e.target.value)} />}
                </div>
                <div style={fieldStyle}>
                    <label style={labelStyle}>Cc:</label>
                    <input value={this.props.cc}
                        style={inputStyle}
                        onChange={(e) => this.props.onCcUpdate(e.target.value)} />
                </div>
                <div style={fieldStyle}>
                    <label style={labelStyle}>{getText("GenEmailSubject")}</label>
                    <input value={this.props.subject}
                        style={inputStyle}
                        onChange={(e) => this.props.onSubjectUpdate(e.target.value)} />
                </div>
                <div>
                    <textarea
                        style={contentStyle}
                        value={this.props.content}
                        onChange={(e) => this.props.onContenttUpdate(e.target.value)}
                    />
                </div>
                <div style={fieldStyle}>
                    <button
                        disabled={this.props.isLoading}
                        style={btnStyleLeft}
                        onClick={(e) => { this.props.goBack(); e.preventDefault(); }}
                        className="btn btn-gray">{this.props.backLabel}</button>
                    <button
                        disabled={this.props.isLoading}
                        style={btnStyleRight}
                        onClick={(e) => { this.props.send(); e.preventDefault(); }}
                        className="btn">{getText("GenEmailSend")}</button>
                    <div className="clearfix"></div>
                </div>
            </div>
            );
    }
}