import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import InputText from './InputText';

//MuiInputBase-root MuiOutlinedInput-root MuiAutocomplete-inputRoot MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiOutlinedInput-adornedEnd
const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiAutocomplete-inputRoot": {
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 0,
    }
});

interface ComboBoxProps {
    options: Array<{label: string, value: any}>;
    value: any;
    onChange: (value: any) => void;
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
}

interface ComboBoxState {
    inputValue: string;
}

export default class ComboBox extends React.Component<ComboBoxProps, ComboBoxState> {
    constructor(props: ComboBoxProps) {
        super(props);
        this.state = {
            inputValue: ""
        };
    }

    public render() {
        return (
            <StyledAutocomplete
                options={this.props.options}
                getOptionLabel={(option: any) => option.label}
                style={{
                    width: 300,
                    padding: 0,
                    ...this.props.style
                }}
                getOptionSelected={(label, value) => value == this.props.value}
                renderInput={(params) => <InputText
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            height: 35,
                            ...this.props.inputStyle
                        }
                    }}
                    value={this.state.inputValue}
                    onChange={(e, value) => this.setState({ inputValue: value })} />}
                />
            );
    }
}