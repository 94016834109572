import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import OfferReminder from './OfferReminder';
import * as SelectionStore from '../store/Selection';
import * as AskRatesStore from '../store/AskRates';
import { getCarrierOfferKey } from '../utils/utils';
import { getText } from '../utils/langTexts';
import { revertColors } from '../styles/colors';
import _, { sortBy } from 'lodash';


interface DDSetTableProps {

    ddSet: Api.DdSetModel;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
}


const tableStyle = { border: "1px solid", textAlign: "left", borderCollapse: "collapse", marginBottom:10, marginTop:5 } as React.CSSProperties;
const cellStyle = { border: "1px solid", padding: "4px" } as React.CSSProperties;
const rightAligin = { textAlign: "right" } as React.CSSProperties;

const containerStyle = {} as React.CSSProperties;
const innerStyle = { padding: 10 } as React.CSSProperties;

const dayType: Array<{ key: Api.DdChargeModelDayCountCategoryEnum; value: string  }> = [
    { key: "Cal" ,value:"Calendar"},
    { key: "Wrk", value: "Work" },

];

const ddChargeName: Array<{ key: number; value: string }> = [
    { key: 3, value: "Detention and Demurage" },
    { key: 4, value: "Plug In" },
    { key: 2, value: "Demurage" },
    { key: 1, value: "Detention" },
    { key: 5, value: "Bt" },
    { key: 6, value: "Chassis" },

];



export default class DDSetTable extends React.Component<DDSetTableProps, {}> {
    constructor(props) {
        super(props);
    }




    public render() {


        var grouped = _.groupBy(this.props.ddSet.charges, x => `${x.ddChargeNameId} ${x.application}`);



        return <div style={containerStyle}>
            <div style={innerStyle}>
                {this.props.ddSet &&
                    (<div>
                        D&D information:
                        {_.map(grouped, x => {

                            let firstCharge =  _.sortBy(x, c => c.currencyId != null).reverse()[0];
                            var units = _.sortBy(_.uniq(x.map(c => c.sizeTypeId != null ? this.props.sizeTypes[c.sizeTypeId].name : c.unit)));

                            var periods = _.sortBy(_.groupBy(x, p => `${p.fromDay}${p.untilDay}`), x => x[0].fromDay);
             
                            return (<div>

                                {ddChargeName.find(d => d.key == firstCharge.ddChargeNameId).value} at {firstCharge.application}: cost per {dayType.find(d => d.key == firstCharge.dayCountCategory).value} days {(firstCharge.currencyId !=null ? `in ${this.props.currencies[firstCharge.currencyId].code}`:``)}

                                <table style={tableStyle}>
                                    <tr>
                                        <td style={cellStyle}>Period</td>
                                        {units.map(u => <td style={cellStyle}>{u}</td>)}
                                    </tr>
                                    {_.map(periods, p => {
                                        var period = p[0];
                                        var charges = _.sortBy(p, c => c.sizeTypeId != null ? this.props.sizeTypes[c.sizeTypeId].name : c.unit);
                                        return (<tr>
                                            <td style={cellStyle}>{period.fromDay} {(period.untilDay === null ? `+`: `- ${ period.untilDay}`)} </td>
                                            {charges.map(c => <td style={{ border: "1px solid", padding: "4px",textAlign:"right" }}>{c.amountType == "Free" ? "Free" : c.amount}</td>)}
                                        </tr>
                                        )
                                    })

                                    }

                                </table>
                          
                            </div>)
                        })
                        }
                    </div>
                    )}
            </div>
        </div>
    }
}

