import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
//import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect'
import { ApplicationState } from '../store/index';
import { isAuthenticated } from './UserIsAuthenticated';

export default connectedRouterRedirect({
    // The url to redirect user to if they fail
    redirectPath: '/',
    authenticatedSelector: (state: ApplicationState) => isAuthenticated(state)
        && state.account.currentUser.clientModel.subscriptions.some(x => x.allowAgentManagement
            || x.allowExportManagement
            || x.allowInformUser
            || x.allowQuotationManagement
            || x.allowRatesControl
            || x.allowRatesManagement),
    wrapperDisplayName: 'UserIsModerator'
})