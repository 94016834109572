import * as React from 'react';
import Moment, { isMoment } from 'moment';
import { DateRange } from "@material-ui/icons";
import FloatingButton from './FloatingButton';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import * as Colors from '../styles/colors';
import { isUndefined } from 'lodash';

interface OkDatePickerProps {
    value: Date;
    onChange: (date: Date) => void;
    maxDate?: Date;
    minDate?: Date;
    disabled?: boolean;
    style?: React.CSSProperties;
    inputContainerStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    iconContainerStyle?: React.CSSProperties;
    setDateButton?: JSX.Element;
    dateToSet?: Date;
    height?: number;
}


const DateTextField = (props: any) => <TextField inputProps={{ style: { color: Colors.baseColors.darkGray } }} {...props} variant="outlined"></TextField>;

 const DatePickerField =  styled(DateTextField)({
    border: 0,
    '& input': {
        padding: "0px 6px",
        textTransform: "none"
    },
    '& .MuiInputBase-root': {
        borderRadius: 8,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        height:"100%",
        border: `1px solid ${Colors.baseColors.darkGray}`,
    },
    '& .MuiAutocomplete-input': {
        padding: "0px 6px !important"
    },
    '& :not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        borderWidth: "0px !important",
    },
    color: Colors.baseColors.darkGray,
});

interface OkDatePickerState {
    isOpen: boolean;
}


//MuiButtonBase-root MuiPickersDay-root MuiPickersDay-dayWithMargin
export default class OkDatePicker extends React.Component<OkDatePickerProps, OkDatePickerState> {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    inputRef = null;
    
    readDateFromProps(date: any): Moment.Moment {

        if (isUndefined(date)) {
            return null;
        }

        return isMoment(date) ? date : Moment(date);
    }

    render() {



        return (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", minWidth: 100 }}>
            <DatePicker 
                selected={this.readDateFromProps(this.props.value)}
                disabled={this.props.disabled}
                maxDate={this.readDateFromProps(this.props.maxDate)}
                minDate={this.readDateFromProps(this.props.minDate)}
                dateFormat="DD/MM/YYYY"
                onChange={(date) => this.props.onChange(date.toDate())}
                customInput={<DatePickerField innerRef={(ref) => this.inputRef = ref} style={{
                    height: (this.props.height || 49),
                    ...(this.props.style && this.props.style.height
                        ? { height: (this.props.style.height as number) }
                        : {}),
                }} />}
            />
            <FloatingButton style={{
                flex: "0 0 auto",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                height: (this.props.height || 49),
                width: (this.props.height || 49),
                ...(this.props.style && this.props.style.height
                    ? { height: (this.props.style.height as number) }
                    : {}),
                ...this.props.iconContainerStyle
            }}
                onClick={(e) => {
                    this.setState({ isOpen: true });
                    if(this.inputRef && this.inputRef.getElementsByClassName("MuiInputBase-input")[0]){
                        this.inputRef.getElementsByClassName("MuiInputBase-input")[0].focus(); 
                    }
                }}>
                <DateRange
                    style={{
                        height: 33,
                        ...this.props.iconStyle
                    }} />
            </FloatingButton>
            {this.props.setDateButton && <div style={{ flex: "0 0 auto" }}
                onClick={(e) => {
                    this.props.onChange(this.props.dateToSet);
                    e.stopPropagation();
                }}>
                {this.props.setDateButton}
            </div>}
        </div>);
    }
}