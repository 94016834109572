import * as React from "react";
import * as Api from "../api/api";
import * as QuotationContactStore from "../store/QuotationContact";
import * as AccountStore from "../store/Account";
import DialogModal from "./DialogModal";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { getText } from "../utils/langTexts";
import { CreateQuotationContactForm, UpdateQuotationContactFormMulti } from "./QuotationContactForm";
import { getQuotationContactName, getSubscription } from '../utils/utils';
import { ZoomIn } from "@material-ui/icons";
import * as Colors from '../styles/colors';
import * as _ from 'lodash';
import ComboBox from "./ComboBox";

type QuotationFfContactDialogProps = QuotationFfContactDialogOwnProps
    & QuotationContactStore.QuotationContactState
    & typeof AccountStore.actionCreators
    & typeof QuotationContactStore.actionCreators;

interface QuotationFfContactDialogOwnProps {
    client: Api.ClientModel;
}

const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
}

const labelStyle: React.CSSProperties = {
    minWidth: 180,
    textAlign: "right",
    marginRight: 6
}

const inputStyle: React.CSSProperties = {
}

class QuotationFfContactDialog extends React.Component<QuotationFfContactDialogProps, {}> {

    get subscriptionOkargo(): Api.SubscriptionModel {
        let subscription = getSubscription(this.props.client);
        return subscription.subscriptionType === "Okargo"
            ? subscription
            : this.props.client.subscriptions.find(x => x.subscriptionType === "Okargo");
    }

    public render() {
        return (
            <div>
                <div style={{
                    cursor: "pointer",
                    height: 35,
                    width: 35,
                    minWidth: 35,
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    backgroundColor: Colors.baseColors.lightBlue,
                    color: "white"
                }} onClick={(e) => this.props.quotationContactOpenFfDialog()}>
                    <ZoomIn style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: 6,
                        fontSize: 25
                    }}>
                    </ZoomIn>
                </div>
                <DialogModal
                    isOpen={this.props.ffIsOpen}
                    contentLabel={"contacts"}
                    maxWidth={"lg"}
                    contentStyle={{ top: 0, bottom: 0, left: "10%", right: "10%" }}
                    onRequestClose={() => this.props.quotationContactCloseFfDialog()}>
                    <div>
                        <h3>{getText("QtnContactMyOkargoContact")}</h3>
                        {this.subscriptionOkargo
                            && <div style={{ maxWidth: 900 }}>
                                <div style={{ marginBottom: 10, marginTop: 10 }}>
                                    <div style={{ ...fieldStyle }}>
                                        <div style={{ ...labelStyle }}>Default contact:</div>
                                        <div style={{ ...inputStyle }}>
                                        <ComboBox options={[{ label: "My okargo contact", value: null }]
                                            .concat(_.sortBy(this.props.quotationContacts.map(x => ({
                                                label: getQuotationContactName(x),
                                                value: x.quotationContactId
                                            })), x => x.label))}
                                                onChange={(value) => this.props.requestUpdateDefaultQuotationContactId(new Date().getTime(), value)}
                                                value={this.props.client.defaultQuotationContactId} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                <div style={{ ...fieldStyle }}>
                                    <div style={{ ...labelStyle }}>{getText("QtnFfCompany")}</div>
                                    <div style={{ ...inputStyle }}>
                                           {this.subscriptionOkargo.agency.company.name}
                                        </div>
                                    </div>
                                <div style={{ ...fieldStyle }}>
                                    <div style={{ ...labelStyle }}>{getText("QtnFfAgency")}</div>
                                    <div style={{ ...inputStyle }}>{this.subscriptionOkargo.agency.name}</div>
                                    </div>
                                <div style={{ ...fieldStyle }}>
                                    <div style={{ ...labelStyle }}>{getText("QtnFfFirstName")}</div>
                                    <div style={{ ...inputStyle }}>
                                            {this.props.client.firstName}
                                        </div>
                                    </div>
                                <div style={{ ...fieldStyle }}>
                                    <div style={{ ...labelStyle }}>{getText("QtnFfLastName")}</div>
                                    <div style={{ ...inputStyle }}>
                                            {this.props.client.lastName}
                                        </div>
                                    </div>
                                </div>
                                <div >
                                <div style={{ ...fieldStyle }}>
                                    <div style={{ ...labelStyle }}>{getText("QtnFfPhone1")}</div>
                                    <div style={{ ...inputStyle }}>
                                            {this.props.client.phone1}
                                        </div>
                                    </div>
                                <div style={{ ...fieldStyle }}>
                                    <div style={{ ...labelStyle }}>{getText("QtnFfPhone2")}</div>
                                    <div style={{ ...inputStyle }}>{this.props.client.phone2}</div>
                                    </div>
                                <div style={{ ...fieldStyle }}>
                                    <div style={{ ...labelStyle }}>{getText("QtnFfEmail")}</div>
                                    <div style={{ ...inputStyle }}>
                                            {this.props.client.account.email}
                                        </div>
                                    </div>
                                </div>
                                <div >
                                <div style={{ ...fieldStyle }}>
                                    <div style={{ ...labelStyle }}>{getText("QtnFfAddress")}</div>
                                    <div style={{ ...inputStyle }}>
                                                {this.subscriptionOkargo.agency.address.streetNumber}
                                                {" "}
                                                {this.subscriptionOkargo.agency.address.street}
                                        </div>
                                    </div>
                                <div style={{ ...fieldStyle }}>
                                    <div style={{ ...labelStyle }}>{getText("QtnFfCity")}</div>
                                    <div style={{ ...inputStyle }}>{this.subscriptionOkargo.agency.address.postalCode}</div>
                                    <div style={{ ...inputStyle }}>{this.subscriptionOkargo.agency.address.city}</div>
                                    </div>
                                <div style={{ ...fieldStyle }}>
                                    <div style={{ ...labelStyle }}>{getText("QtnFfCountry")}</div>
                                    <div style={{ ...inputStyle }}>
                                            {this.subscriptionOkargo.agency.address.country.name}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        <h3>{getText("QtnContactCreateTitle")}</h3>
                        <div style={{ maxWidth: 900 }}>
                            <CreateQuotationContactForm
                                actionName={getText("QtnContactCreateButton")}
                                contactType={"FreightForwarder"}
                                onSubmit={(values) => {
                                    return this.props.requestCreateQuotationContact(new Date().getTime(), values) as any;
                                }}
                            />
                        </div>
                        <div style={{ maxWidth: 900 }}>
                            <h3>{getText("QtnContactMyContacts")}</h3>
                            {this.props.quotationContacts.filter(x => x.type === "FreightForwarder").map(x => <div key={x.quotationContactId}>
                                <h4>{getQuotationContactName(x)}</h4>
                                <UpdateQuotationContactFormMulti
                                    actionName={getText("GenSaveChanges")}
                                    quotationContactId={x.quotationContactId}
                                    contactType={"FreightForwarder"}
                                    onSubmit={(values) => {
                                        return this.props.requestUpdateQuotationContact(new Date().getTime(), values) as any;
                                    }}
                                />
                            </div>)}
                        </div>
                    </div>
                </DialogModal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.quotationContact,
    client: state.account.currentUser.clientModel
} as QuotationFfContactDialogProps), {
        ...QuotationContactStore.actionCreators,
        ...AccountStore.actionCreators
})(QuotationFfContactDialog) as any