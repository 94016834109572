import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { Source } from './Criteria';


export interface AgentPortalState {
    agentSearchsState: AgentSearchsState;
    chargeAgentsState: ChargeAgentsState;
    contactAgentsState: ContactAgentsState;
}

export interface AgentSearchsState {
    isLoading: boolean;
    requestTime: number;
    agentSearchs: Array<Api.AgentSearchModel>;
    searchAgentSearchs: Api.SearchAgentSearchModel;
    detailsOpened: boolean;
    clientActionId: number;
}
export interface ChargeAgentsState {
    isLoading: boolean;
    isLoaded: boolean;
    requestTime: number;
    chargeAgents: Array<Api.ChargeAgentModel>;
    deleteStates: { [id: number]: RequestState };
    createState: RequestState;
}
export interface ContactAgentsState {
    isLoading: boolean;
    isLoaded: boolean;
    requestTime: number;
    contacts: Array<Api.ContactModel>;
    deleteStates: { [id: number]: RequestState };
    createState: RequestState;
}

interface RequestState {
    isLoading: boolean;
    requestTime: number;
}

interface RequestAgentSearchs { type: "REQUEST_AGENT_SEARCHS"; requestTime: number; }
interface ReceiveAgentSearchs { type: "RECEIVE_AGENT_SEARCHS"; requestTime: number; agentSearchs: Array<Api.AgentSearchModel> }

interface RequestAgentCharges { type: "REQUEST_AGENT_CHARGES"; requestTime: number; }
interface ReceiveAgentCharges { type: "RECEIVE_AGENT_CHARGES"; requestTime: number; chargeAgents: Array<Api.ChargeAgentModel> }

interface RequestCreateAgentCharges { type: "REQUEST_CREATE_AGENT_CHARGE"; requestTime: number; }
interface ReceiveCreateAgentCharges { type: "RECEIVE_CREATE_AGENT_CHARGE"; requestTime: number; chargeAgent: Api.ChargeAgentModel  }

interface RequestDeleteAgentCharges { type: "REQUEST_DELETE_AGENT_CHARGE"; requestTime: number; chargeAgentId: number }
interface ReceiveDeleteAgentCharges { type: "RECEIVE_DELETE_AGENT_CHARGE"; requestTime: number; chargeAgentId: number; success: boolean }

interface RequestAgentContacts { type: "REQUEST_AGENT_CONTACTS"; requestTime: number; }
interface ReceiveAgentContacts { type: "RECEIVE_AGENT_CONTACTS"; requestTime: number; contacts: Array<Api.ContactModel> }

interface RequestCreateAgentContact { type: "REQUEST_CREATE_AGENT_CONTACT"; requestTime: number; }
interface ReceiveCreateAgentContact { type: "RECEIVE_CREATE_AGENT_CONTACT"; requestTime: number; contact: Api.ContactModel }

interface RequestDeleteAgentContact { type: "REQUEST_DELETE_AGENT_CONTACT"; requestTime: number; contactId: number }
interface ReceiveDeleteAgentContact { type: "RECEIVE_DELETE_AGENT_CONTACT"; requestTime: number; contactId: number; success: boolean }

interface AgentSelectLocation { type: "AGENT_SELECT_LOCATION"; location: Api.LocationModel; source: Source }

interface ChargeAgentSelectLocation { type: "CHARGE_AGENT_SELECT_LOCATION"; location: Api.LocationModel; source: Source }

interface UpdateAgentSearchFromDate { type: "UPDATE_AGENTSEARCH_FROMDATE", value: Date }
interface UpdateAgentSearchName { type: "UPDATE_AGENTSEARCH_NAME", value: string }

interface AgentOpenSearchCriteriaDetails { type: "AGENT_OPEN_SEARCH_CRITERIA_DETAILS", clientActionId: number }
interface AgentCloseSearchCriteriaDetails { type: "AGENT_CLOSE_SEARCH_CRITERIA_DETAILS" }

type KnownAction = RequestAgentSearchs | ReceiveAgentSearchs
    | RequestAgentContacts | ReceiveAgentContacts
    | RequestAgentCharges | ReceiveAgentCharges
    | RequestCreateAgentCharges | ReceiveCreateAgentCharges
    | RequestDeleteAgentCharges | ReceiveDeleteAgentCharges
    | RequestCreateAgentContact | ReceiveCreateAgentContact
    | RequestDeleteAgentContact | ReceiveDeleteAgentContact
     | AgentSelectLocation
    | UpdateAgentSearchFromDate | UpdateAgentSearchName
     | ChargeAgentSelectLocation
    | AgentOpenSearchCriteriaDetails | AgentCloseSearchCriteriaDetails
    ;


export const actionCreators = {
    requestAgentSearchs: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().agentPortal.agentSearchsState.requestTime === requestTime)
            return;

        let api = new Api.AgentApi();
        let fetchTask = api.agentSearchs({
            model: getState().agentPortal.agentSearchsState.searchAgentSearchs
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(agentSearchs => {
                dispatch({ type: "RECEIVE_AGENT_SEARCHS", requestTime: requestTime, agentSearchs: agentSearchs });
            }).catch(error => {
                console.log(error.message);
                dispatch(Notifications.error({ message: "Error fetching agent searchs", title: "Error", position: "tc" }) as any);
                dispatch({ type: "RECEIVE_AGENT_SEARCHS", requestTime: requestTime, agentSearchs: [] });
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_AGENT_SEARCHS", requestTime: requestTime });
    },
    requestChargeAgents: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().agentPortal.chargeAgentsState.requestTime === requestTime)
            return;

        let api = new Api.AgentApi();
        let fetchTask = api.chargesAgent({ credentials: "same-origin", headers: getDefaultHeaders(getState()) }).then(chargeAgents => {
            dispatch({ type: "RECEIVE_AGENT_CHARGES", requestTime: requestTime, chargeAgents: chargeAgents });
        }).catch(error => {
            console.log(error.message);
            dispatch(Notifications.error({ message: "Error fetching agent charges", title: "Error", position: "tc" }) as any);
            dispatch({ type: "RECEIVE_AGENT_CHARGES", requestTime: requestTime, chargeAgents: [] });
        });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_AGENT_CHARGES", requestTime: requestTime });
    },
    requestContactAgents: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().agentPortal.contactAgentsState.requestTime === requestTime)
            return;

        let api = new Api.AgentApi();
        let fetchTask = api.contactsAgent({ credentials: "same-origin", headers: getDefaultHeaders(getState()) }).then(contacts => {
            dispatch({ type: "RECEIVE_AGENT_CONTACTS", requestTime: requestTime, contacts: contacts });
        }).catch(error => {
            console.log(error.message);
            dispatch(Notifications.error({ message: "Error fetching agent contacts", title: "Error", position: "tc" }) as any);
            dispatch({ type: "RECEIVE_AGENT_CONTACTS", requestTime: requestTime, contacts: [] });
        });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_AGENT_CONTACTS", requestTime: requestTime });
    },
    requestCreateCharge: (requestTime: number, chargeAgent: Api.ChargeAgentModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().agentPortal.chargeAgentsState.createState.requestTime === requestTime)
            return Promise.resolve();

        let api = new Api.AgentApi();
        let fetchTask = api.createCharge({
            model: chargeAgent
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(chargeAgentResult => {
                dispatch({ type: "RECEIVE_CREATE_AGENT_CHARGE", requestTime: requestTime, chargeAgent: chargeAgentResult });
                dispatch(Notifications.success({ message: "Your charge has been aded", title: "Success", position: "tc" }) as any);
            }).catch(error => {
            console.log(error.message);
            dispatch(Notifications.error({ message: "Error creating agent charge", title: "Error", position: "tc" }) as any);
            dispatch({ type: "RECEIVE_CREATE_AGENT_CHARGE", requestTime: requestTime, chargeAgent: null });
        });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_CREATE_AGENT_CHARGE", requestTime: requestTime });
        return fetchTask;
    },
    requestDeleteCharge: (requestTime: number, chargeAgentId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let deleteState = getState().agentPortal.chargeAgentsState.deleteStates[chargeAgentId];
        if (deleteState
            && deleteState.requestTime === requestTime)
            return;

        let api = new Api.AgentApi();
        let fetchTask = api.deleteCharge({
            chargeId: chargeAgentId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_DELETE_AGENT_CHARGE", requestTime: requestTime,
                    chargeAgentId: chargeAgentId, success: true
                });
            }).catch(error => {
                console.log(error.message);
                dispatch(Notifications.error({ message: "Error deleting agent charge", title: "Error", position: "tc" }) as any);
                dispatch({
                    type: "RECEIVE_DELETE_AGENT_CHARGE", requestTime: requestTime,
                    chargeAgentId: chargeAgentId, success: false
                });
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_DELETE_AGENT_CHARGE", requestTime: requestTime, chargeAgentId: chargeAgentId });
    },
    requestCreateContact: (requestTime: number, contact: Api.ContactModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().agentPortal.contactAgentsState.createState.requestTime === requestTime)
            return Promise.resolve();

        let api = new Api.AgentApi();
        let fetchTask = api.createContact({
            model: contact
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(contactResult => {
                dispatch({ type: "RECEIVE_CREATE_AGENT_CONTACT", requestTime: requestTime, contact: contactResult });
                dispatch(Notifications.success({ message: "Your contact has been added", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                console.log(error.message);
                dispatch(Notifications.error({ message: "Error creating agent contact", title: "Error", position: "tc" }) as any);
                dispatch({ type: "RECEIVE_CREATE_AGENT_CONTACT", requestTime: requestTime, contact: null });
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_CREATE_AGENT_CONTACT", requestTime: requestTime });
        return fetchTask;
    },
    requestDeleteContact: (requestTime: number, contactId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let deleteState = getState().agentPortal.contactAgentsState.deleteStates[contactId];
        if (deleteState
            && deleteState.requestTime === requestTime)
            return;

        let api = new Api.AgentApi();
        let fetchTask = api.deleteContact({
            contactId: contactId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_DELETE_AGENT_CONTACT", requestTime: requestTime,
                    contactId: contactId, success: true
                });
            }).catch(error => {
                console.log(error.message);
                dispatch(Notifications.error({ message: "Error deleting agent contact", title: "Error", position: "tc" }) as any);
                dispatch({
                    type: "RECEIVE_DELETE_AGENT_CONTACT", requestTime: requestTime,
                    contactId: contactId, success: false
                });
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_DELETE_AGENT_CONTACT", requestTime: requestTime, contactId: contactId });
    },
   
    closeSearchCriteriaDetails: () => <AgentCloseSearchCriteriaDetails>{ type: "AGENT_CLOSE_SEARCH_CRITERIA_DETAILS" },
    openSearchCriteriaDetails: (clientActionId: number) => <AgentOpenSearchCriteriaDetails>{ type: "AGENT_OPEN_SEARCH_CRITERIA_DETAILS", clientActionId: clientActionId },
    selectLocation: (location: Api.LocationModel, source: Source) =>
        <AgentSelectLocation>{ type: "AGENT_SELECT_LOCATION", location: location, source: source },
    updateAgentSearchFromDate: (value: Date) =>
        <UpdateAgentSearchFromDate>{ type: "UPDATE_AGENTSEARCH_FROMDATE", value: value },
    updateAgentSearchName: (value: string) =>
        <UpdateAgentSearchName>{ type: "UPDATE_AGENTSEARCH_NAME", value: value },
    selectChargeLocation: (location: Api.LocationModel, source: Source) =>
        <ChargeAgentSelectLocation>{ type: "CHARGE_AGENT_SELECT_LOCATION", location: location, source: source },
}

const unloadedState: AgentPortalState = {
    chargeAgentsState: {
        isLoading: false,
        isLoaded: false,
        requestTime: 0,
        chargeAgents: [],
        deleteStates: {},
        createState: {
            isLoading: false,
            requestTime: 0
        }
    },
    contactAgentsState: {
        isLoading: false,
        isLoaded: false,
        requestTime: 0,
        contacts: [],
        deleteStates: {},
        createState: {
            isLoading: false,
            requestTime: 0
        }
    },
    agentSearchsState: {
        isLoading: false,
        requestTime: 0,
        agentSearchs: [],
        searchAgentSearchs: {
            fromDate: Moment().add(-1, "months").toDate()
        },
        clientActionId: 0,
        detailsOpened: false
    }
};


export const reducer: Reducer<AgentPortalState> = (state: AgentPortalState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_AGENT_SEARCHS":
            return {
                ...state,
                agentSearchsState: {
                    ...state.agentSearchsState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_AGENT_SEARCHS":
            if (state.agentSearchsState.requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                agentSearchsState: {
                    ...state.agentSearchsState,
                    isLoading: false,
                    agentSearchs: action.agentSearchs
                }
            };
        case "REQUEST_AGENT_CHARGES":
            return {
                ...state,
                chargeAgentsState: {
                    ...state.chargeAgentsState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_AGENT_CHARGES":
            if (state.chargeAgentsState.requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                chargeAgentsState: {
                    ...state.chargeAgentsState,
                    isLoading: false,
                    isLoaded: true,
                    chargeAgents: action.chargeAgents
                }
            };
        case "REQUEST_AGENT_CONTACTS":
            return {
                ...state,
                contactAgentsState: {
                    ...state.contactAgentsState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_AGENT_CONTACTS":
            if (state.contactAgentsState.requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                contactAgentsState: {
                    ...state.contactAgentsState,
                    isLoading: false,
                    isLoaded: true,
                    contacts: action.contacts
                }
            };
        case "REQUEST_CREATE_AGENT_CHARGE":
            return {
                ...state,
                chargeAgentsState: {
                    ...state.chargeAgentsState,
                    createState: {
                        ...state.chargeAgentsState.createState,
                        isLoading: true,
                        requestTime: action.requestTime
                    }
                }
            };
        case "RECEIVE_CREATE_AGENT_CHARGE":
            if (action.requestTime !== state.chargeAgentsState.createState.requestTime)
                return state;

            return {
                ...state,
                chargeAgentsState: {
                    ...state.chargeAgentsState,
                    chargeAgents: action.chargeAgent
                        ? state.chargeAgentsState.chargeAgents.concat([action.chargeAgent])
                        : state.chargeAgentsState.chargeAgents,
                    createState: {
                        ...state.chargeAgentsState.createState,
                        isLoading: false
                    }
                }
            };
        case "REQUEST_DELETE_AGENT_CHARGE":
            return {
                ...state,
                chargeAgentsState: {
                    ...state.chargeAgentsState,
                    deleteStates: {
                        ...state.chargeAgentsState.deleteStates,
                        [action.chargeAgentId]: {
                            ...state.chargeAgentsState.deleteStates[action.chargeAgentId],
                            isLoading: true,
                            requestTime: action.requestTime
                        }
                    }
                }
            };
        case "RECEIVE_DELETE_AGENT_CHARGE":
            if (state.chargeAgentsState.deleteStates[action.chargeAgentId]
                && state.chargeAgentsState.deleteStates[action.chargeAgentId].requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                chargeAgentsState: {
                    ...state.chargeAgentsState,
                    chargeAgents: action.success
                        ? state.chargeAgentsState.chargeAgents.filter(ca => ca.chargeAgentId !== action.chargeAgentId)
                        : state.chargeAgentsState.chargeAgents,
                    deleteStates: {
                        ...state.chargeAgentsState.deleteStates,
                        [action.chargeAgentId]: {
                            ...state.chargeAgentsState.deleteStates[action.chargeAgentId],
                            isLoading: false
                        }
                    }
                }
            };
        case "REQUEST_CREATE_AGENT_CONTACT":
            return {
                ...state,
                contactAgentsState: {
                    ...state.contactAgentsState,
                    createState: {
                        ...state.contactAgentsState.createState,
                        isLoading: true,
                        requestTime: action.requestTime
                    }
                }
            };
        case "RECEIVE_CREATE_AGENT_CONTACT":
            if (action.requestTime !== state.contactAgentsState.createState.requestTime)
                return state;

            return {
                ...state,
                contactAgentsState: {
                    ...state.contactAgentsState,
                    contacts: action.contact
                        ? state.contactAgentsState.contacts.concat([action.contact])
                        : state.contactAgentsState.contacts,
                    createState: {
                        ...state.contactAgentsState.createState,
                        isLoading: false
                    }
                }
            };
        case "REQUEST_DELETE_AGENT_CONTACT":
            return {
                ...state,
                contactAgentsState: {
                    ...state.contactAgentsState,
                    deleteStates: {
                        ...state.contactAgentsState.deleteStates,
                        [action.contactId]: {
                            ...state.contactAgentsState.deleteStates[action.contactId],
                            isLoading: true,
                            requestTime: action.requestTime
                        }
                    }
                }
            };
        case "RECEIVE_DELETE_AGENT_CONTACT":
            if (state.contactAgentsState.deleteStates[action.contactId]
                && state.contactAgentsState.deleteStates[action.contactId].requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                contactAgentsState: {
                    ...state.contactAgentsState,
                    contacts: action.success
                        ? state.contactAgentsState.contacts.filter(ca => ca.contactId !== action.contactId)
                        : state.contactAgentsState.contacts,
                    deleteStates: {
                        ...state.contactAgentsState.deleteStates,
                        [action.contactId]: {
                            ...state.contactAgentsState.deleteStates[action.contactId],
                            isLoading: false
                        }
                    }
                }
            };
        case "AGENT_SELECT_LOCATION":
            if (action.source === Source.Origin) {
                return {
                    ...state,
                    agentSearchsState: {
                        ...state.agentSearchsState,
                        searchAgentSearchs: {
                            ...state.agentSearchsState.searchAgentSearchs,
                            origin: action.location,
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    agentSearchsState: {
                        ...state.agentSearchsState,
                        searchAgentSearchs: {
                            ...state.agentSearchsState.searchAgentSearchs,
                            destination: action.location,
                        }
                    }
                };
            }
        case "UPDATE_AGENTSEARCH_FROMDATE":
            return {
                ...state,
                agentSearchsState: {
                    ...state.agentSearchsState,
                    searchAgentSearchs: {
                        ...state.agentSearchsState.searchAgentSearchs,
                        fromDate: action.value
                    }
                }
            };
        case "UPDATE_AGENTSEARCH_NAME":
            return {
                ...state,
                agentSearchsState: {
                    ...state.agentSearchsState,
                    searchAgentSearchs: {
                        ...state.agentSearchsState.searchAgentSearchs,
                        name: action.value
                    }
                }
            };
        case "CHARGE_AGENT_SELECT_LOCATION":
            if (action.source === Source.Origin) {
                return {
                    ...state,
                    chargeAgentsState: {
                        ...state.chargeAgentsState
                    }
                };
            } else {
                return {
                    ...state,
                    chargeAgentsState: {
                        ...state.chargeAgentsState
                    }
                };
            }
        case "AGENT_OPEN_SEARCH_CRITERIA_DETAILS":
            return {
                ...state,
                agentSearchsState: {
                    ...state.agentSearchsState,
                    clientActionId: action.clientActionId,
                    detailsOpened: true
                }
            };
        case "AGENT_CLOSE_SEARCH_CRITERIA_DETAILS":
            return {
                ...state,
                agentSearchsState: {
                    ...state.agentSearchsState,
                    clientActionId: 0,
                    detailsOpened: false
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
