import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getFirmName } from '../utils/utils';
import { Option } from "./Select";
import { getFirms } from './ModeratorAgentPortal';
import { revertColors } from '../styles/colors';
import {hFieldStyle, vFormStyle} from "../styles/baseStyles";

type ModeratorContactAgentFormProps = ReduxForm.InjectedFormProps<{}, {}, string> &
    ModeratorContactAgentFormOwnProps;

interface ModeratorContactAgentFormOwnProps {
    initialValues: Api.ContactModel;
    handleSubmit: (values: Api.ChargeAgentModel) => void;
    subscriptions: Array<Api.SubscriptionModel>;
    submitting: boolean;
}

interface ContactTypeOption { label: string; value: Api.ContactModelTypeEnum }
const contactTypeoptions: Array<ContactTypeOption> = [
    { label: "Booking", value: "Booking" },
    { label: "Sales", value: "Sales" }
];
const contactTypeSelect = FormField.getSelectField(contactTypeoptions);

const textAreaField = FormField.textAreaField(4, 50);
const currencyIfHasAmount = (value, allValues) => ((!allValues.amount && !allValues.currencyId) && "Currency required when there is an amount");
const labelStyle: React.CSSProperties = { display: "block", minWidth: 75 }

class ModeratorContactAgentForm extends React.Component<ModeratorContactAgentFormProps, {}> {

    get firmOptions(): Array<Option> {
        return getFirms(this.props.subscriptions).map(fi => ({
            label: getFirmName(fi),
            value: fi.firmId
        }));
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-horizontal container-fluid">
                <div style={{ ...vFormStyle }}>
                    <div className="text-danger" style={{ textAlign: "center" }}>
                        {this.props.error && <span>{this.props.error}</span>}
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={{ ...labelStyle }} className="col-sm-4 control-label">Type: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="type"
                                             component={contactTypeSelect}
                            />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={{ ...labelStyle }} className="col-sm-4 control-label">Firstname: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="firstName"
                                             component={FormField.renderField}
                                             type="text" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={{ ...labelStyle }} className="col-sm-4 control-label">Lastname: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="lastName"
                                             component={FormField.renderField}
                                             type="text" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={{ ...labelStyle }} className="col-sm-4 control-label">Email 1: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="email1"
                                             component={FormField.renderField}
                                             type="text" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={{ ...labelStyle }} className="col-sm-4 control-label">Email 2: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                             validate={[]}
                                             name="email2"
                                             component={FormField.renderField}
                                             type="text" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={{ ...labelStyle }} className="col-sm-4 control-label">Phone 1: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="phone1"
                                             component={FormField.renderField}
                                             type="text" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={{ ...labelStyle }} className="col-sm-4 control-label">Phone 2: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                             validate={[]}
                                             name="phone2"
                                             component={FormField.renderField}
                                             type="text" />
                        </div>
                    </div>

                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={{ ...labelStyle }} className="col-sm-4 control-label">Agency/Company: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="firmId"
                                             component={FormField.getSelectField(this.firmOptions)} />
                        </div>
                    </div>

                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={{ ...labelStyle }} className="col-sm-4 control-label"></label>
                        <div className="col-sm-8">
                            <button type="submit"
                                    style={{ ...revertColors.main3 }}
                                    className="btn">Create contact</button>
                        </div>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let ModeratorContactAgentFormWrapped = ReduxForm.reduxForm({
    form: 'moderatorContactAgent',
    enableReinitialize: true
})(ModeratorContactAgentForm) as any;

const init = {};
const selector = ReduxForm.formValueSelector('moderatorContactAgent')
export default connect((state: ApplicationState) => {
    return ({
        initialValues: init,
        subscriptions: state.account.currentUser.clientModel
            ? state.account.currentUser.clientModel.subscriptions
            : [],
    });
})(ModeratorContactAgentFormWrapped) as any as React.ComponentClass<{ onSubmit: (values: Api.ContactModel) => Promise<any> }>