import * as React from 'react';
import Throttle from '../utils/throttleHandler';
import * as Api from '../api/api';
import * as RatesOfferStore from '../store/RatesOffer';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Guid } from '../utils/utils';
import Autocomplete from "./Autocomplete";

type RatesOfferSelectProps =
    RatesOfferSelectOwnProps
    & RatesOfferSelectSelectProps
    & typeof RatesOfferStore.actionCreators;

interface RatesOfferSelectOwnProps {
    style?: any;
    className?: string;
    disabled?: boolean;
    inputKey: string;
    carrierId: number;
    value: Api.RatesOfferModel;
    onChange: (value: Api.RatesOfferModel) => void;
}

interface RatesOfferSelectSelectProps {
    ratesOfferByTerm: RatesOfferStore.RatesOfferByTermState;
}


class RatesOfferSelect extends React.Component<RatesOfferSelectProps, {}> {
    private guid: string;

    constructor(props) {
        super(props);
        this.guid = Guid.newGuid();
    }

    getRatesOfferName(ratesOffer: Api.RatesOfferModel): string {
        return `${ratesOffer.shortName || (ratesOffer.references ? ratesOffer.references.map(r => r.value).join(',') : "")  }`;
    }

    onInputChangeValue(value: string) {
        this.props.updatesearchRatesOfferTerm(this.props.inputKey, value);
        Throttle.throttle(this.guid, () => {
            if (value.length >= 3) {
                this.props.requestGetRatesOfferByTerm(this.props.inputKey,
                    this.props.carrierId,
                    new Date().getTime());
            }
        }, 300);
    }

    onSelect(id: number) {
        let ratesOffer = this.props.ratesOfferByTerm[this.props.inputKey]
            .ratesOffers.find(x => x.ratesOfferId === id);
        this.props.updatesearchRatesOfferTerm(this.props.inputKey, this.getRatesOfferName(ratesOffer));
        this.props.onChange(ratesOffer);
    };

    public render() {
        return (
            <div style={{ display: "inline-block" }}>
                <Autocomplete
                    getItemValue={(item: Api.RatesOfferModel) => item}
                    getItemLabel={(item: Api.RatesOfferModel) => this.getRatesOfferName(item)}
                    disabled={this.props.disabled}
                    items={this.props.ratesOfferByTerm[this.props.inputKey]
                        ? (this.props.ratesOfferByTerm[this.props.inputKey].ratesOffers || [])
                        : []}
                    emptyItems={() => this.props.emptySearchRatesOffer(this.props.inputKey)}
                    renderItem={(item: Api.RatesOfferModel, isHighlighted) =>
                        <div className='action' style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                            <span>{this.getRatesOfferName(item)}</span>
                        </div>
                    }
                    inputValue={this.props.ratesOfferByTerm[this.props.inputKey]
                        ? this.props.ratesOfferByTerm[this.props.inputKey].term
                        : ""}
                    onInputValueChange={(val) => {
                        if (!val
                            && this.props.ratesOfferByTerm[this.props.inputKey]
                            && this.props.ratesOfferByTerm[this.props.inputKey].term) {
                            this.props.onChange(null);
                        }
                        this.onInputChangeValue(val);
                    }}
                    value={this.props.value}
                    onChange={(id) => this.onSelect(id)} />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ratesOfferByTerm: state.ratesOffer.ratesOffersByTerm
} as any), RatesOfferStore.actionCreators)(RatesOfferSelect) as any as React.ComponentClass<RatesOfferSelectOwnProps>;