import * as React from "react";
import * as Api from "../api/api";
import * as FormField from './FormField';
import { connect } from "react-redux";
import { getText } from "../utils/langTexts";
import { ApplicationState } from "../store";
import * as ReduxForm from 'redux-form';
import { compose } from "redux";
import { contactTypeOptions } from "./QuotationInformationForm";
import Button from "./Button";

type QuotationContactFormProps = ReduxForm.InjectedFormProps<{}, {}, string>
    & QuotationContactFormExternalProps
    & QuotationContactFormOwnProps;

interface QuotationContactFormExternalProps {
    quotationContactId?: number;
    actionName: string;
    hideReset?: boolean;
    contactType: Api.QuotationContactModelTypeEnum;
    onSubmit: (values: Api.QuotationContactModel) => Promise<any>;
}

interface QuotationContactFormOwnProps {

}

const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
}

const labelStyle: React.CSSProperties = {
    minWidth: 180,
    textAlign: "right",
    marginRight: 6
}

const inputStyle: React.CSSProperties = {
}

class QuotationContactForm extends React.Component<QuotationContactFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnFfCompany")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="company"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnFfAgency")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="agency"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnFfFirstName")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="firstName"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnFfLastName")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="lastName"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnFfPhone1")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="phone1"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnFfPhone2")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="phone2"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnFfEmail")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="email"
                                    display="block"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        {this.props.contactType === "Client" && <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnClientType")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="clientType"
                                    display="block"
                                    component={FormField.getSelectField(contactTypeOptions())} />
                            </div>
                        </div>}
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnFfAddress")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="street1"
                            display="block"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}></div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="street2"
                                    display="block"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}></div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="street3"
                                    display="block"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnFfCity")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="postalCode"
                                    placeholder={getText("QtnFfZipCode")}
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="city"
                                    placeholder={getText("QtnFfCity")}
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ ...labelStyle }}>{getText("QtnFfCountry")}</div>
                            <div style={{ ...inputStyle }}>
                                <ReduxForm.Field 
                                    validate={[]}
                                    name="country"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <Button
                        disabled={this.props.pristine || this.props.submitting}
                        type={"submit"}>
                        {this.props.actionName}
                    </Button>
                    {!this.props.hideReset
                        && <Button
                            style={{ marginRight: 10 }}
                            disabled={this.props.pristine || this.props.submitting}
                            type={"button"}
                            onClick={(e) => this.props.reset()}>
                            {getText("GenReset")}
                    </Button>}
                </div>
            </FormField.BaseForm>
            );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createQuotationContact',
    destroyOnUnmount: false,
    enableReinitialize: true
})(QuotationContactForm) as any;
const createSelector = ReduxForm.formValueSelector('createQuotationContact')

let updateForm = ReduxForm.reduxForm({
    form: 'updateQuotationContact',
    destroyOnUnmount: false,
    enableReinitialize: true
})(QuotationContactForm) as any;
const updateSelector = ReduxForm.formValueSelector('updateQuotationContact')

export const CreateQuotationContactForm = connect((state: ApplicationState, props: QuotationContactFormProps) => {
    return {
        initialValues: {
            type: props.contactType,
            clientType: "None" as Api.QuotationContactModelClientTypeEnum
        }
    } as QuotationContactFormOwnProps;
})(createForm) as any as React.ClassicComponentClass<QuotationContactFormExternalProps>

export const UpdateQuotationContactForm = connect((state: ApplicationState, props: QuotationContactFormProps) => {
    return {
        initialValues: state.quotationContact.quotationContacts
            .find(x => x.quotationContactId === props.quotationContactId)
    } as QuotationContactFormOwnProps;
})(updateForm) as any as React.ClassicComponentClass<QuotationContactFormExternalProps>

export const UpdateQuotationContactFormMulti = compose(connect((state: ApplicationState, props: QuotationContactFormProps) => {
    return {
        form: "updateQuotationContactMulti" + props.quotationContactId,
        initialValues: state.quotationContact.quotationContacts.find(x => x.quotationContactId === props.quotationContactId)
    } as QuotationContactFormOwnProps;
}), ReduxForm.reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true
}))(QuotationContactForm) as any as React.ClassicComponentClass<QuotationContactFormExternalProps>
