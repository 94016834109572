import * as React from 'react';
import { Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import * as QuotationStore from '../store/Quotation';
import * as QuotationContactStore from '../store/QuotationContact';
import * as QuotationSettingsStore from '../store/QuotationSettings';
import * as SelectionStore from '../store/Selection';
import QuotationInformationForm from './QuotationInformationForm';
import QuotationRates from './QuotationRates';
import Loader from './Loader';
import { scrollToWhen } from 'react-redux-scroll';
import { getText } from '../utils/langTexts';
import QuotationTemplatesDialog from './QuotationTemplatesDialog';
import { revertColors } from '../styles/colors';
import { getCarrierOfferKey } from '../utils/utils';
import Button from "./Button";
import OfferReminder from './OfferReminder';

type QuotationProps = QuotationOwnProps
    & QuotationStore.QuotationState
    & typeof QuotationStore.actionCreators
    & typeof QuotationContactStore.actionCreators
    & typeof SelectionStore.actionCreators
    & typeof QuotationSettingsStore.actionCreators
    & RouteComponentProps<QuotationParams>;

interface QuotationOwnProps {
    duplicateQuotation?: Api.QuotationModel;
    carriers: { [id: number]: Api.CarrierModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
    languages: { [id: number]: Api.LanguageModel };
    carrierOfferState: { [key: string]: SelectionStore.CarrierOfferState };
    quotationContact: QuotationContactStore.QuotationContactState;
    client: Api.ClientModel;
}


interface QuotationParams {
    criteriaCode: string;
    departId: string;
    chargeSetId: string;
    routeId: string;
    quotationId?: string;
    mode?: QuotationStore.QuotationMode;
}

const containerStyle = { position: "relative" as any }
const innerContainerStyle = { padding: 20 }
const templateContainerStyle = { paddingTop: 20 };
const infoFormContainerStyle = { paddingTop: 20 };

const isStepChange = (action: Action): boolean => {
    if (action.type === "QUOTATION_NEXT_STEP"
        || action.type === "QUOTATION_PREV_STEP"
        || action.type === "RECEIVE_QUOTATION_DATA"
        || action.type === "REQUEST_QUOTATION_DATA") {
        return true;
    }

    return false;
}
const ScrollableToTop =
    scrollToWhen(isStepChange, null, null, null)("div");

class Quotation extends React.Component<QuotationProps, {}> {

    constructor(props: QuotationProps) {
        super(props);
    }

    onPropsUpdate(props: QuotationProps): Promise<any> {
        if ((!props.quotationContact.isLoaded && !props.isLoading)
            || (new Date().getTime() - props.requestTime > 1000 * 60 * 10)) {
            return props.requestQuotationContacts(new Date().getTime()) as any;
        }

        return Promise.resolve();
    }

    onParamsUpdate(props: QuotationProps, nextProps: QuotationProps, force?: boolean) {
        let params = nextProps.match.params;
        //If need reload
        if ((nextProps.enterSelectionTime
            && nextProps.enterSelectionTime != nextProps.loadedSelectionTime)
            || !nextProps.criteria
            || !nextProps.carrierOffer
            || params.criteriaCode !== nextProps.criteria.code
            || params.quotationId !== props.match.params.quotationId
            || (!nextProps.carrierOffer.chargeSet && params.chargeSetId.toLowerCase() !== "null")
            || (nextProps.carrierOffer.chargeSet && parseInt(params.chargeSetId) !== nextProps.carrierOffer.chargeSet.chargeSetId)
            || !nextProps.carrierOffer.routeConfigs
                .map(rc => rc.departs).reduce((a, b) => a.concat(b), [])
                .some(dp => dp.departId === parseInt(params.departId))) {

            let loadPromise = (this.props.requestQuotationData(
                new Date().getTime(),
                params.criteriaCode,
                parseInt(params.chargeSetId) || null,
                parseInt(params.departId),
                params.quotationId ? parseInt(params.quotationId) : null,
                params.routeId ? parseInt(params.routeId) : nextProps.carrierOffer.routeId,
                nextProps.match.params.mode) as any) as Promise<any>;

            loadPromise.then((result) => {
                if (result) {
                    if (this.props.match.params.mode !== "Update") {
                        this.props.requestNewQuotationReference(new Date().getTime());
                    }
                }
            });
        }
    }

    componentWillMount() {
        this.props.requestQuotationSettings(new Date().getTime());
        this.onPropsUpdate(this.props).then(() => {
            this.onParamsUpdate(this.props, this.props);
        });
    }

    componentWillReceiveProps(nextProps: QuotationProps) {
        //TODO Check why both match object arent the same, its a redux pattern error maybe due to context not being the same
        if (this.props.match.params.chargeSetId !== nextProps.match.params.chargeSetId
            || this.props.match.params.criteriaCode !== nextProps.match.params.criteriaCode
            || this.props.match.params.departId !== nextProps.match.params.departId
            || this.props.match.params.mode !== nextProps.match.params.mode
            || this.props.match.params.quotationId !== nextProps.match.params.quotationId) {
            this.onPropsUpdate(nextProps).then(() => {
                this.onParamsUpdate(this.props, nextProps);
            });
        }
    }

    public render() {
        return (
            <div style={containerStyle}>
                <div style={innerContainerStyle}>
                    <ScrollableToTop />
                    {this.props.step === 0 &&
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <div style={{ minWidth: 800, alignSelf: "center" }}>
                                <div className="row">
                                    <div className="col-md-2">
                                        <img onClick={() => this.props.quotationBackToSelection()}
                                            className='action' src='/images/goBack.png' alt='Go back' width='39' height='27' />
                                    </div>
                            </div>
                            {this.props.carrierOffer
                                && this.props.carrierOffer.chargeSet
                                && this.props.carrierOffer.chargeSet.routeSuggestedCalcId
                                && <div className="text-danger" style={{ textAlign: "center" }}>
                                {getText("QtnSuggestedWarning")}
                            </div>}
                            <div style={infoFormContainerStyle}>
                                {this.props.duplicateQuotation && <div style={{ textAlign: "center" }}>
                                    <Button
                                        style={{ ...revertColors.main3, }}
                                        onClick={(e) => {
                                        this.props.quotationLoadDuplicate();
                                        e.preventDefault();
                                        }}>Load quotation: {this.props.duplicateQuotation.name}</Button>
                                </div>}
                                <div style={{ padding: "15px 0px" }}>
                                    {this.props.carrierOffer && <OfferReminder
                                        carrierOffer={this.props.carrierOffer}
                                        carrierOfferState={{
                                            ...SelectionStore.unloadedCarrierOfferState,
                                            selectedDepartId: this.props.departId
                                        }}
                                        carriers={this.props.carriers} />}
                                </div>
                                <QuotationInformationForm
                                    onSubmit={(values) => {
                                        this.props.setQuotationInformation(values as QuotationStore.QuotationInformationModel);
                                        this.props.quotationNextStep();
                                    }}
                                    carrierScheduleState={this.props.carrierOffer ? this.props.carriers[this.props.carrierOffer.carrierId].scheduleState : "Hide"}
                                    requestGoToCarrierWebSite={() => {
                                        this.props.requestGoToCarrierWebSite(getCarrierOfferKey(this.props.carrierOffer), new Date().getTime());
                                    }}
                                    quotationSelectContactClient={(value) => this.props.quotationSelectContactClient(value)}
                                    isDownloadingDocument={this.props.downloadDocumentState.isLoading}
                                    requestDownloadDocument={() => this.props.requestDownloadRatesDocument(new Date().getTime()) as any}
                                    openClientMoreOptions={() => this.props.quotationOpenClientMoreOptions()}
                                    closeClientMoreOptions={() => this.props.quotationCloseClientMoreOptions()}
                                    updateIncotermLocation={(value) => this.props.quotationUpdateIncotermLocation(value)}
                                    mode={this.props.match.params.mode || "Normal"} />
                            </div>
                            </div>
                        </div>}
                    {this.props.step === 1 &&
                        <div style={{ paddingLeft: 50, paddingRight: 50 }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                                <QuotationRates />
                            </div>
                        </div>}
                </div>
                <Loader
                    style={{ zIndex: 99999 }}
                    isVisible={this.props.isLoading || this.props.createState.isLoading}
                    top={200}
                    size={120} />
                <QuotationTemplatesDialog />
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.quotation,
        duplicateQuotation: state.quotationChrono.duplicateQuotation,
        carriers: state.seed.carriers,
        chargeNames: state.seed.chargeNames,
        sizeTypes: state.seed.sizeTypes,
        currencies: state.seed.currencies,
        languages: state.seed.languages,
        client: state.account.currentUser.clientModel,
        quotationContact: state.quotationContact,
    } as any),
    {
        ...SelectionStore.actionCreators,
        ...QuotationStore.actionCreators,
        ...QuotationContactStore.actionCreators,
        ...QuotationSettingsStore.actionCreators
    })(Quotation) as any