import * as React from 'react';
import * as Colors from '../styles/colors';
import Slider from '@material-ui/core/Slider';
import { styled } from '@material-ui/core';

const StyledSlider = styled(Slider)({
    color: "black",
    '& .MuiSlider-track': {
        color: Colors.baseColors.lightBlue
    },
    '& .MuiSlider-thumb': {
        color: Colors.baseColors.lightBlue
    },
});

interface OkSliderProps {
    value: number;
    minValue: number;
    maxValue: number;
    onChange: (value: number) => void;
    valueRender: (value: number) => string;
}

interface OkSliderState {
}

export default class OkSlider extends React.Component<OkSliderProps, OkSliderState> {

    constructor(props) {
        super(props)
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: OkSliderProps) {
     
    }

    render() {
        return <StyledSlider
            value={this.props.value}
            max={this.props.maxValue}
            min={this.props.minValue}
            valueLabelFormat={(a, b) => this.props.valueRender(a)}
            onChange={(e, val: number) => this.props.onChange(val)} />;
    }
}