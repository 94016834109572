import * as React from "react";
import * as Colors from "../styles/colors";
import Tooltip from "./Tooltip";
import { getText } from "../utils/langTexts";

export default class OperationalAccuracy extends React.Component < {}, {} > {
    public render() {
        return (
            <div style={{
                display: "inline-block", cursor: "help",
                fontSize: 11, paddingLeft: 3, paddingTop: 1
            }}
                onClick={(e) => { e.stopPropagation(); }}>
                <Tooltip trigger={["click"]}
                    style={{ width: 400, opacity: 1 }}
                    overlay={<div style={{ whiteSpace: "pre-wrap" }}>
                        {getText("SlcOfferUnaccuracyMessage")}
                    </div>}>
                    <span>
                        {this.props.children}
                        {!this.props.children && <i className="glyphicon glyphicon-info-sign" style={{ color: Colors.frontColors.color3 }}>
                        </i>}
                    </span>
                </Tooltip>
            </div>
            );
    }
}