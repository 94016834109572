import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import * as Colors from "../styles/colors";
import { getText } from "../utils/langTexts";
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import { contactTypeOptions, incotermOptions } from './QuotationInformationForm';

type AccountEditQuotationClientFormProps = ReduxForm.InjectedFormProps<{}, {}, string>
    & AccountEditQuotationClientFormOwnProps;

interface AccountEditQuotationClientFormOwnProps {
}

const labelStyle = {
    width: "200px",
    paddingRight: "30px",
    paddingLeft: "30px",
    textAlign: "right",
    marginTop: "auto",
    marginBottom: "auto",
} as React.CSSProperties;

const containerStyle = {
    display: "flex",
    flexDirection: "row",
} as React.CSSProperties;

const inputFormStyle = {
    width: "400px",
    height: "42px",
    border: `1px solid ${Colors.baseColors.darkGray}`,
    borderRadius: "8px",
    color: Colors.baseColors.darkGray,
} as React.CSSProperties;

const saveButtonStyle = {
    borderRadius: "8px",
    color: Colors.baseColors.lightBlue,
    fontWeight: "bold",
    border: `2px solid ${Colors.baseColors.darkGray}`,
    backgroundColor: "white",
    position: "absolute",
    right: 0,
} as React.CSSProperties;

const checkCaseStyle = {
    paddignLeft: "200px",
    height: 50
} as React.CSSProperties;

const undoButtonStyle = {
    border: "none",
    backgroundColor: "white",
} as React.CSSProperties;

class AccountEditQuotationClientForm extends React.Component<AccountEditQuotationClientFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} style={{ maxWidth: 600 }}>
                <div style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div style={containerStyle}>
                    <label style={labelStyle}>{getText("StgQuotationSettingsClientType")}</label>
                    <div >
                        <ReduxForm.Field
                            style={inputFormStyle}
                            validate={FormField.required}
                            name="clientType"
                            component={FormField.getSelectField(contactTypeOptions())} />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", paddingBottom: 10 }}>
                    <div style={checkCaseStyle}>
                        <label ></label>
                        <div >
                            <ReduxForm.Field
                                style={checkCaseStyle}
                                validate={[]} S
                                name="hideIncluded"
                                component={FormField.checkBoxField(getText("StgQuotationSettingsHideIncluded"))} />
                        </div>
                    </div>
                    <div style={checkCaseStyle}>
                        <label ></label>
                        <div >
                            <ReduxForm.Field
                                validate={[]}
                                name="showCarrier"
                                component={FormField.checkBoxField(getText("StgQuotationSettingsShowCarrier"))} />
                        </div>
                    </div>
                </div>
                <div style={containerStyle}>
                    <label style={labelStyle}>Default incoterm: </label>
                    <div >
                        <ReduxForm.Field
                            style={inputFormStyle}
                            validate={[]}
                            name="incoterm"
                            component={FormField.getSelectField(incotermOptions())} />
                    </div>
                </div>
                <div >
                    <div style={{
                        paddingLeft: 460,
                        paddingTop: 20,
                        paddingBottom: 4,
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                    }}>
                        <button
                            style={undoButtonStyle}
                            type="button"
                            onClick={(e) => { this.props.reset(); e.preventDefault(); }}
                            disabled={this.props.pristine || this.props.submitting}>
                            <ReplayRoundedIcon style={{
                                color: Colors.baseColors.lightBlue,
                            }} />
                        </button>
                        <button
                            style={saveButtonStyle}
                            type="submit"
                            disabled={this.props.submitting}
                        >{getText("StgQuotationSettingsGo")}</button>

                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let form = ReduxForm.reduxForm({
    form: 'editQuotationClientForm',
    enableReinitialize: true
})(AccountEditQuotationClientForm) as any;

export default connect((state: ApplicationState) => ({
    initialValues: state.account.currentUser
        && state.account.currentUser.clientModel.quotationClientSettings
        ? state.account.currentUser.clientModel.quotationClientSettings
        : {
            clientType: "None",
            hideIncluded: false,
            showCarrier: false
        } as Api.QuotationClientSettingsModel,
}))(form) as any as React.ComponentClass<{ onSubmit: (value: Api.QuotationClientSettingsModel) => any }>
