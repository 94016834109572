import * as React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as QuotationChronoStore from "../store/QuotationChrono";
import QuotationChronoFilterForm from "./QuotationChronoFilterForm";
import { getText } from '../utils/langTexts';
import Loader from './Loader';
import Button from './Button';
import Table, { Column } from './Table';
import Tooltip from './Tooltip';
import QuotationChronoDetailsDialog from "./QuotationChronoDetailsDialog";
import QuotationChronoCommentDialog from "./QuotationChronoCommentDialog";
import { getQuotationContactName } from '../utils/utils';
import { CSSProperties } from 'react';

type QuotationChronoProps = QuotationChronoStore.QuotationChronoState
    & QuotationChronoOwnProps
    & typeof QuotationChronoStore.actionCreators;

interface QuotationChronoOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
}

type QuotationColumn = Column<Api.QuotationModel>;

export const quotationColumns = (props: QuotationChronoOwnProps) => [
    {
        id: "creationDate",
        header: "created on",
        accessor: (d) => d.creationDate,
        cell: row => <div>{Moment(row).format("DD/MM/YYYY")}</div>
    },
    {
        id: "reference",
        header: "reference",
        accessor: (d) => d.reference,
    },
    {
        id: "author",
        header: "author",
        accessor: (d) => d.author,
    },
    {
        id: "clientContact",
        header: "client",
        accessor: (d) => getQuotationContactName(d.clientContact),
    },
    {
        id: "originInfo",
        header: "origin",
        accessor: (d) => d.originInfo,
    },
    {
        id: "pol",
        header: "POL",
        accessor: (d) => d.criteria.origin.name,
    },
    {
        id: "pod",
        header: "POD",
        accessor: (d) => d.criteria.destination.name,
    },
    {
        id: "destinationInfo",
        header: "destination",
        accessor: (d) => d.destinationInfo,
    },
    {
        id: "carrier",
        header: "carrier",
        accessor: (d) => d.quotationOffers[0]
            ? (d.quotationOffers[0].carrierOffer.carrierId>0 ? props.carriers[d.quotationOffers[0].carrierOffer.carrierId].name : "-")
            : "",
    },
    {
        id: "trafficName",
        header: "traffic name",
        accessor: (d) => d.serviceName,
    },
    {
        id: "containers",
        header: "containers",
        accessor: (d) => d.criteria.criteriaSizeTypes
            .filter(x => x.number > 0)
            .map(x => `${x.number}x${props.sizeTypes[x.sizeTypeId].name}`)
            .join(" "),
    },
] as Array<QuotationColumn>;

const iconLinkStyle: CSSProperties = {
    marginRight: 4, cursor: "pointer"
};
const iconStyle: CSSProperties = {
    height: 12,
    margin: "0px 3px"
};
const title: CSSProperties = {
    margin: "1em 0 1em 2em"
};


class QuotationChrono extends React.Component<QuotationChronoProps, {}> {

    public render() {
        return (<div>
            <h2 style={ title }>{getText("GenQuotations")} :</h2>
            <div style={{ marginLeft: "3em" }}>
                <QuotationChronoFilterForm
                    onSubmit={(values) => {
                        return this.props.requestQuotations(new Date().getTime(), values) as any;
                    }} />
            </div>
            <div style={{ margin: "1em 0em 1em 4em" }}>
                <Button
                    disabled={this.props.quotations.length === 0}
                    onClick={() => this.props.requestDownloadQuotationCsv(new Date().getTime())}
                    type={"submit"}>
                    {getText("QtnQuotationDownloadCsv")}
                </Button>
            </div>
            <div style={{ paddingBottom: 35 }}>
                <div style={{ margin: "1em 2.5em 1em 2.5em"}}>
                    <Table
                        tableKey={"quotationChrono"}
                        size={"small"}
                        data={this.props.quotations}
                        headTrStyle={{ height: 50 }}
                        bodyTrStyle={(d: Api.QuotationModel) => ({
                            height: 30
                        })}
                        columns={quotationColumns(this.props).concat([
                            {
                            id: "status",
                            header: <div>Status</div>,
                            accessor: d => d,
                            cell: row => {
                                let quotation = row as Api.QuotationModel;
                                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div style={{
                                        color: quotation.status === "Success" ? "blue" : "black",
                                        fontWeight: "bold",
                                        cursor: "pointer"
                                    }}
                                        onClick={(e) => this.props.requestUpdateQuotationStatus(new Date().getTime(), quotation.quotationId, "Success")}>
                                        Success 
                                        </div>
                                    <div style={{ margin: "0px 5px" }}> | </div>
                                    <div style={{
                                        color: quotation.status === "Failure" ? "blue" : "black",
                                        fontWeight: "bold",
                                        cursor: "pointer"
                                    }}
                                        onClick={(e) => this.props.requestUpdateQuotationStatus(new Date().getTime(), quotation.quotationId, "Failure")}>
                                        Failure 
                                    </div>
                                </div>
                            }
                            },
                            {
                                id: "comment",
                                header: <div>{getText("QtnChronoComment")}</div>,
                                accessor: d => d,
                                cell: row => {
                                    let quotation = row as Api.QuotationModel;
                                    return (
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div style={{ flex: "0 0 auto", margin: "0px 5px", minWidth: 65 }}>
                                                <Tooltip overlay={<div style={{ maxWidth: 400, whiteSpace: "pre-wrap" }}>{quotation.comment}</div>}>
                                                    <div>
                                                        {(quotation.comment || "").substring(0, 8)}
                                                        {quotation.comment && quotation.comment.length > 8 && "..."}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div style={{ flex: "0 0 auto", margin: "0px 5px" }}>
                                                <i className="glyphicon glyphicon-edit"
                                                    onClick={(e) => this.props.openQuotationComment(quotation.quotationId)}></i>
                                            </div>
                                        </div>
                                    );
                                }
                            },
                            {
                            id: "actions",
                            header: "",
                            width: 300,
                            accessor: (d) => d,
                            cell: row => {
                                let quotation = row as Api.QuotationModel;
                                let criteriaCode = quotation.criteria.code;
                                if (!quotation.quotationOffers[0])
                                    return <div></div>;

                                let chargeSetId = quotation.quotationOffers[0].carrierOffer.chargeSet
                                    ? quotation.quotationOffers[0].carrierOffer.chargeSet.chargeSetId
                                    : null;
                                let departId = quotation.quotationOffers[0].departId;
                                let routeId = quotation.quotationOffers[0].carrierOffer.routeId;

                                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Tooltip overlay={<div>{getText("QtnChronoDetails")}</div>} placement={"top"}>
                                        <a onClick={(e) => {
                                        this.props.openQuotationDetails(quotation.quotationId);
                                        e.preventDefault();
                                        }} style={iconLinkStyle}><img style={iconStyle} src={"/images/iconDetails.svg"} /></a>
                                    </Tooltip>
                                    {" "}
                                    <Tooltip overlay={<div>{getText("QtnChronoDownload")}</div>} placement={"top"}>
                                    <a onClick={(e) => {
                                        this.props.requestDownloadQuotation(new Date().getTime(), quotation.quotationId);
                                        e.preventDefault();
                                    }} style={iconLinkStyle}><img style={iconStyle} src={"/images/iconDownload.svg"} /></a>
                                    </Tooltip>
                                    {" "}
                                    <Tooltip overlay={<div>{getText("QtnChronoEdit")}</div>} placement={"top"}>
                                        <a href={"/quotation/" + criteriaCode + "/" + departId + "/" + chargeSetId + "/" + quotation.quotationId + "/" + routeId + "/Update"}
                                        style={iconLinkStyle}>
                                        <img style={iconStyle} src={"/images/iconEdit.svg"} />
                                    </a>
                                    </Tooltip>
                                    {" "}
                                    <Tooltip overlay={<div>{getText("QtnChronoDuplicate")}</div>} placement={"top"}>
                                        <a href={"/quotation/" + criteriaCode + "/" + departId + "/" + chargeSetId + "/" + quotation.quotationId + "/" + routeId + "/Duplicate"}
                                        style={iconLinkStyle}>
                                        <img style={iconStyle} src={"/images/iconDuplicate.svg"} />
                                    </a>
                                    </Tooltip>
                                    {" "}
                                    <Tooltip overlay={<div>{getText("QtnChronoDuplicateSearch")}</div>} placement={"top"}>
                                    <a style={iconLinkStyle}
                                        onClick={(e) => {
                                            this.props.setQuotationsDuplicate(quotation.quotationId);
                                            e.preventDefault();
                                        }}>
                                        <img style={iconStyle} src={"/images/iconDuplicateSearch.svg"} />
                                    </a>
                                    </Tooltip>
                                    {" "}
                                    <Tooltip overlay={<div>{"Go back to offers"}</div>} placement={"top"}>
                                        <a href={"/selection/" + criteriaCode}
                                            style={iconLinkStyle}>
                                            <img style={iconStyle} src={"/images/iconMagnifyingGlass.svg"} />
                                        </a>
                                    </Tooltip>
                                </div>
                                }
                            }] as Array<Column<Api.QuotationModel>>)}
                        rowsPerPage={12}
                        showPageSizeOptions={false}
                        showPagination={true}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        noDataText="No quotations, please use the filter above" />
                </div>
            </div>
            <QuotationChronoDetailsDialog
                isOpen={this.props.detailsState.isOpen}
                carriers={this.props.carriers}
                sizeTypes={this.props.sizeTypes}
                quotation={this.props.quotations
                    .find(x => x.quotationId === this.props.detailsState.quotationId)}
                onRequestClose={() => this.props.closeQuotationDetails()}
                requestDownloadQuotation={(quotationId) => this.props.requestDownloadQuotation(new Date().getTime(), quotationId)} />
            <QuotationChronoCommentDialog
                isOpen={this.props.commentState.isOpen}
                isLoading={this.props.commentState.isLoading}
                quotation={this.props.quotations
                    .find(x => x.quotationId === this.props.commentState.quotationId)}
                commentValue={this.props.commentState.value}
                updateComment={(value) => this.props.updateComment(value)}
                onRequestClose={() => this.props.closeQuotationComment()}
                requestUpdateQuotationComment={() => this.props.requestUpdateComment(new Date().getTime())} />
        </div>);
    }
}

export default connect((state: ApplicationState) => ({
    ...state.quotationChrono,
    carriers: state.seed.carriers,
    sizeTypes: state.seed.sizeTypes
} as any), QuotationChronoStore.actionCreators)(QuotationChrono) as any;