import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Layout from './components/Layout';
import Selection from './components/Selection';
import Criteria from './components/Criteria';
import CriteriaLcl from './components/CriteriaLcl';
import Login from './components/Login';
import AccountSettings from './components/AccountSettings';
import Moderator from './components/Moderator';
import History from './components/History';
import Documents from './components/Documents';
import Quotation from './components/Quotation';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import QuotationChrono from './components/QuotationChrono';
import InlandCriteria from './components/InlandCriteria';
import InlandSelection from './components/InlandSelection';
import CarrierRatesSettings from './components/CarrierRatesSettings';
import RouteRatesPlatformDataSettings from './components/RouteRatesPlatformDataSettings';
import RatesExtraction from './components/RatesExtraction';
import UserIsAuthenticated from './security/UserIsAuthenticated';
import UserIsNotAuthenticated from './security/UserIsNotAuthenticated';
import UserIsModerator from './security/UserIsModerator';
import "./App.css";

export default () => (<Layout>
    <Switch>
        <Route path='/swagger' component={() => {
            window.location.href = 'https://app.okargo.com/swagger';
            return null;
        }}/>
        <Route path='/criteria/:code?' component={UserIsAuthenticated(Criteria)} />
        <Route path='/selection/:code' component={UserIsAuthenticated(Selection)} />
        <Route path='/settings' component={UserIsAuthenticated(AccountSettings)} />
        <Route path='/moderator/:module?' component={UserIsModerator(Moderator)} />
        <Route path='/history/:module?' component={UserIsAuthenticated(History)} />
        <Route path='/inland/criteria/:code?' component={UserIsAuthenticated(InlandCriteria)} />
        <Route path='/inland/selection/:code' component={UserIsAuthenticated(InlandSelection)} />
        <Route path='/lcl/criteria/:code?' component={UserIsAuthenticated(CriteriaLcl)} />
        <Route path='/lcl/selection/:code' component={UserIsAuthenticated(Selection)} />
        <Route path='/quotation/:criteriaCode/:departId/:chargeSetId/:quotationId?/:routeId?/:mode?' component={UserIsAuthenticated(Quotation)} />
        <Route path='/documents' component={UserIsAuthenticated(Documents)} />
        <Route path='/quotations' component={UserIsAuthenticated(QuotationChrono)} />
        <Route path='/carrierRatesSettings' component={UserIsAuthenticated(CarrierRatesSettings)} />
        <Route path='/routeRatesDataSettings' component={UserIsAuthenticated(RouteRatesPlatformDataSettings)} />
        <Route path="/ratesExtraction" component={UserIsAuthenticated(RatesExtraction)} />
        <Route path="/login" component={UserIsNotAuthenticated(Login)} />
        <Route path="/forgotpassword" component={UserIsNotAuthenticated(ForgotPassword)} />
        <Route path="/resetpassword/:token" component={UserIsNotAuthenticated(ResetPassword)} />
        <Redirect path='/' exact to='/criteria' />
    </Switch>
</Layout>);

