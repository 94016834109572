import { fetch } from 'domain-task';
import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { SubmissionError } from 'redux-form';


export interface FirmDocumentState {
    isLoading: boolean;
    requestTime: number;
    firmDocuments: Array<Api.FirmDocumentModel>;
    editState: EditFirmDocumentState;
    deleteStates: { [id: number]: DeleteFirmDocumentState }
}

export interface EditFirmDocumentState {
    isLoading: boolean;
    requestTime: number;
}

export interface DeleteFirmDocumentState {
    isLoading: boolean;
    requestTime: number;
}

interface RequestModeratorFirmDocuments { type: "REQUEST_MODERATOR_FIRMDOCUMENTS"; payload: { requestTime: number; } }
interface ReceiveModeratorFirmDocuments { type: "RECEIVE_MODERATOR_FIRMDOCUMENTS"; payload: { requestTime: number; firmDocuments: Array<Api.FirmDocumentModel> }; error?: any }

interface RequestDeleteFirmDocument { type: "REQUEST_DELETE_FIRMDOCUMENT"; payload: { requestTime: number; firmDocumentId: number } }
interface ReceiveDeleteFirmDocument { type: "RECEIVE_DELETE_FIRMDOCUMENT"; payload: { requestTime: number; firmDocumentId: number }; error?: any }

interface RequestCreateFirmDocument { type: "REQUEST_CREATE_FIRMDOCUMENT"; payload: { requestTime: number; } }
interface ReceiveCreateFirmDocument { type: "RECEIVE_CREATE_FIRMDOCUMENT"; payload: { requestTime: number; firmDocument: Api.FirmDocumentModel }; error?: any }

type KnownAction = RequestModeratorFirmDocuments | ReceiveModeratorFirmDocuments
    | RequestDeleteFirmDocument | ReceiveDeleteFirmDocument
    | RequestCreateFirmDocument | ReceiveCreateFirmDocument;


export const actionCreators = {
    requestFirmDocuments: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().ratesOffer.requestTime)
            return;

        let api = new Api.ModeratorApi();
        var fetchTask = api.getFirmDocuments({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(firmDocuments => {
                dispatch({ type: "RECEIVE_MODERATOR_FIRMDOCUMENTS", payload: { requestTime: requestTime, firmDocuments: firmDocuments } });
            }).catch(error => {
                console.log('Error: ' + error.message);
                dispatch(Notifications.error({ message: "Error getting documents", title: "Error", position: "tc" }) as any);
                dispatch({ type: "RECEIVE_MODERATOR_FIRMDOCUMENTS", payload: { requestTime: requestTime, firmDocuments: [] }, error: error });
            });
        addTask(fetchTask);
        dispatch({ type: "REQUEST_MODERATOR_FIRMDOCUMENTS", payload: { requestTime: requestTime } });
    },
    requestCreateFirmDocument: (requestTime: number, firmDocument: Api.FirmDocumentModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().firmDocument.editState.requestTime)
            return Promise.resolve();

        let file = (firmDocument as any).file;
        let formData = new FormData();
        formData.append(file.name, file);
        for (let key of Object.keys(firmDocument)) {
            formData.append(key, firmDocument[key]);
        }
        let fetchTask = (fetch("/api/Moderator/CreateFirmDocument", {
            method: "POST",
            body: formData,
            credentials: "same-origin",
            headers: getDefaultHeaders(getState())
        }).then(response => response.json() as Api.FirmDocumentModel)
            .then(firmDocumentResult => {
                dispatch({ type: "RECEIVE_CREATE_FIRMDOCUMENT", payload: { requestTime: requestTime, firmDocument: firmDocumentResult } });
                if (firmDocumentResult)
                    dispatch(Notifications.success({ message: "Your document have been created", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_CREATE_FIRMDOCUMENT", payload: { requestTime: requestTime, firmDocument: null }, error: error });
                dispatch(Notifications.error({ message: "Failed to create document", title: "Error", position: "tc" }) as any);
                throw new SubmissionError({ _error: "Failed to create" } as any)
            }) as any) as Promise<any>;

        addTask(fetchTask);
        dispatch({ type: "REQUEST_CREATE_FIRMDOCUMENT", payload: { requestTime: requestTime }});
        return fetchTask;
    },
    requestDeleteFirmDocument: (requestTime: number, firmDocumentId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().firmDocument.deleteStates[firmDocumentId]
            && requestTime === getState().firmDocument.deleteStates[firmDocumentId].requestTime)
            return Promise.resolve();

        let api = new Api.ModeratorApi();
        let fetchTask = api.deleteFirmDocument({ firmDocumentId: firmDocumentId },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(value => {
                dispatch({ type: "RECEIVE_DELETE_FIRMDOCUMENT", payload: { requestTime: requestTime, firmDocumentId: firmDocumentId } });
            }).catch(error => {
                dispatch({ type: "RECEIVE_DELETE_FIRMDOCUMENT", payload: { requestTime: requestTime, firmDocumentId: firmDocumentId }, error: error });
                dispatch(Notifications.success({ message: "Failed to delete document", title: "Success", position: "tc" }) as any);
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_DELETE_FIRMDOCUMENT", payload: { requestTime: requestTime, firmDocumentId: firmDocumentId } });
        return fetchTask;
    },
}

const unloadedState: FirmDocumentState = {
    isLoading: false,
    requestTime: 0,
    firmDocuments: [],
    deleteStates: {},
    editState: {
        isLoading: false,
        requestTime: 0
    }
};


export const reducer: Reducer<FirmDocumentState> = (state: FirmDocumentState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_MODERATOR_FIRMDOCUMENTS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_MODERATOR_FIRMDOCUMENTS":
            if (state.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                firmDocuments: action.payload.firmDocuments
            };
        case "REQUEST_CREATE_FIRMDOCUMENT":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_FIRMDOCUMENT":
            return {
                ...state,
                firmDocuments: action.error
                    ? state.firmDocuments
                    : state.firmDocuments.concat([action.payload.firmDocument]),
                editState: {
                    ...state.editState,
                    isLoading: state.editState.requestTime !== action.payload.requestTime ? state.editState.isLoading : false
                }
            };
        case "REQUEST_DELETE_FIRMDOCUMENT":
            return {
                ...state,
                deleteStates: {
                    ...state.deleteStates,
                    [action.payload.firmDocumentId]: {
                        ...state.deleteStates[action.payload.firmDocumentId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_DELETE_FIRMDOCUMENT":
            if (state.deleteStates[action.payload.firmDocumentId]
                && state.deleteStates[action.payload.firmDocumentId].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                firmDocuments: action.error
                    ? state.firmDocuments
                    : state.firmDocuments
                    .filter(fd => fd.firmDocumentId !== action.payload.firmDocumentId),
                deleteStates: {
                    ...state.deleteStates,
                    [action.payload.firmDocumentId]: {
                        ...state.deleteStates[action.payload.firmDocumentId],
                        isLoading: false
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
