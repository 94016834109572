import * as _ from 'lodash';
import * as Api from '../api/api';
import * as React from 'react';
import * as Colors from '../styles/colors';
import * as FormField from './FormField';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText, upperCaseFirstLetter } from '../utils/langTexts';
import { getSubscription } from '../utils/utils';
import LocationSelect from './LocationSelect';


type RouteRatesPlatformDataFormProps = ReduxForm.InjectedFormProps<Api.RouteRatesPlatformDataModel, {}, string>
    & RouteRatesPlatformDatasFormOwnProps
    & RouteRatesPlatformDatasFormOwnPropFormExternalProps;

interface RouteRatesPlatformDatasFormOwnProps {
    initialValues: Api.CarrierRatesSettingsModel;
    portsZones: { [id: number]: Api.PortsZoneModel };
    countries: { [id: number]: Api.LocationModel };
    carriers: { [id: number]: Api.CarrierModel };
    carrierRatesPlatforms: { [id: number]: Api.CarrierRatesPlatformModel };
    subscriptions: Array<Api.SubscriptionModel>;
    carrierId: number;
    subscription: Api.SubscriptionModel;
    agencies: Array<Api.FirmModel>;
    routeRatesPlatformDatas: Array<Api.RouteRatesPlatformDataModel>;
    subOkargoId: number;
    firmId: number;
}

interface RouteRatesPlatformDatasFormOwnPropFormExternalProps {
    onSubmit: (values: Api.RouteRatesPlatformDataModel) => Promise<any>;
    mode: "Create" | "Update";
}


const originPortField = (fields) => <div>
    <LocationSelect
        inputKey={"originPort"}
        value={fields.input.value}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>

const destinationPortField = (fields) => <div>
    <LocationSelect
        inputKey={"destinationPort"}
        value={fields.input.value}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>

const locationRequired = (value: any, values: Api.RouteRatesPlatformDataModel) => !values.originPortsZoneId
&& !values.originPort && !values.originCountryId && !values.destinationPort && !values.destinationCountryId && !values.destinationPortsZoneId
    ? "At least one location is required" : undefined;

class RouteRatesPlatformDataForm extends React.Component<RouteRatesPlatformDataFormProps> {

    constructor(props: RouteRatesPlatformDataFormProps) {
        super(props);

    }

    componentWillReceiveProps(nextProps: RouteRatesPlatformDataFormProps) {

    }

    public render() {
        return (
            <FormField.BaseForm {...this.props} autoComplete="off" className="form-horizontal">
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div style={{ fontSize: 18, fontWeight: "bold", margin: "10px 0px" }}>{upperCaseFirstLetter( getText("GenFrom"))}</div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <label className="col-md-4 control-label" style={{ paddingTop: "4px" }}  >{getText("GenPort")}:</label>
                            <div className="col-md-8">
                                <div>
                                    <ReduxForm.Field name={"originPort"}
                                        validate={[locationRequired]}
                                        component={originPortField} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <label className="col-md-4 control-label" style={{ paddingTop: "4px" }}>{getText("GenCountry")}:  </label>
                            <div className="col-md-8">
                                <ReduxForm.Field name={"originCountryId"}
                                    validate={[locationRequired]}
                                    style={{ width: "100%" }}
                                    search={true}
                                    component={FormField.getSelectField(
                                        _.sortBy(_.values(this.props.countries), x => x.name)
                                            .map(x => ({ label: x.name, value: x.locationId })))} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <label className="col-md-4 control-label" style={{ paddingTop: "4px" }}>{getText("GenZone")}:  </label>
                            <div className="col-md-8">
                                <ReduxForm.Field name={"originPortsZoneId"}
                                    validate={[locationRequired]}
                                    style={{ width: "100%" }}
                                    search={true}
                                    component={FormField.getSelectField(
                                        _.sortBy(_.values(this.props.portsZones), x => x.name)
                                            .map(x => ({ label: x.name, value: x.portsZoneId })))} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 18, fontWeight: "bold", margin: "10px 0px" }}>{upperCaseFirstLetter(getText("GenTo"))}</div>

                <div className="row">

                    <div className="col-md-4">
                        <div className="row">
                            <label className="col-md-4 control-label">{getText("GenPort")}: </label>
                            <div className="col-md-8">
                                <ReduxForm.Field name={"destinationPort"}
                                    validate={[locationRequired]}
                                    component={destinationPortField} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <label className="col-md-4 control-label" style={{ paddingTop: "4px" }}>{getText("GenCountry")}: </label>
                            <div className="col-md-8">
                                <ReduxForm.Field name={"destinationCountryId"}
                                    validate={[locationRequired]}
                                    style={{ width: "100%" }}
                                    search={true}
                                    component={FormField.getSelectField(
                                        _.sortBy(_.values(this.props.countries), x => x.name)
                                            .map(x => ({ label: x.name, value: x.locationId })))} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="row">
                            <label className="col-md-4 control-label" style={{ paddingTop: "4px" }}>{getText("GenZone")}: </label>
                            <div className="col-md-8">
                                <ReduxForm.Field name={"destinationPortsZoneId"}
                                    validate={[locationRequired]}
                                    style={{ width: "100%" }}
                                    search={true}
                                    component={FormField.getSelectField(
                                        _.sortBy(_.values(this.props.portsZones), x => x.name)
                                            .map(x => ({ label: x.name, value: x.portsZoneId })))} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 18, fontWeight: "bold", margin: "10px 0px" }}>{ upperCaseFirstLetter(getText("GenWith"))}</div>
                <div className="row">
                    <div className="col-md-4">

                        <div className="row">
                            <label className="col-md-4 control-label">{getText("GenRatesCarrier")}: </label>
                            <div className="col-md-8">
                                <ReduxForm.Field className="form-control"
                                    name="carrierId"
                                    style={{ width: "100%" }}
                                    component={FormField.getSelectField([{ label: "------", value: null }].concat(_.sortBy(_.values(this.props.carriers).map(x => ({ label: x.name, value: x.carrierId })), x => x.label)))} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse", margin: "10px 0px" }}>
                    <button onClick={(e) => { this.setState({}) }}
                        type="submit"
                        style={{ ...Colors.revertColors.main3 }}
                        disabled={this.props.submitting || this.props.pristine}
                        className="btn">
                        {this.props.mode === "Create" && getText("GenCreate")}
                        {this.props.mode === "Update" && getText("GenSaveChanges")}
                    </button>
                </div>

            </FormField.BaseForm>
        );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createRouteRatesPlatformData',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(RouteRatesPlatformDataForm) as any;
const createSelector = ReduxForm.formValueSelector('createRouteRatesPlatformData')

let updateForm = ReduxForm.reduxForm({
    form: 'updateRouteRatesPlatformData',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(RouteRatesPlatformDataForm) as any;
const updateSelector = ReduxForm.formValueSelector('updateRouteRatesPlatformData')

export const CreateRouteRatesPlatformDataForm = connect((state: ApplicationState) => {
    return {
        initialValues: {},
        carriers: state.seed.carriers,
        carrierRatesPlatforms: state.seed.carrierRatesPlatforms,
        carrierId: createSelector(state, "carrierId"),
        subscriptions: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo"),
        subscription: getSubscription(state.account.currentUser.clientModel),
        agencies: state.account.currentUser.clientModel.agencies,
        firmId: createSelector(state, "firmId"),
        routeRatesPlatformDatas: state.routeRatesPlatformDatas.entities,
        portsZones: state.seed.portsZones,
        countries: state.seed.countries,
    } as RouteRatesPlatformDatasFormOwnProps;
})(createForm) as any as React.ComponentClass<RouteRatesPlatformDatasFormOwnPropFormExternalProps>

export const UpdateRouteRatesPlatformDatasForm = connect((state: ApplicationState) => {

    return {
        initialValues: state.routeRatesPlatformDatas.entities
            .find(x => x.routeRatesPlatformDataId === state.routeRatesPlatformDatas.selectedId),
        carriers: state.seed.carriers,
        carrierRatesPlatforms: state.seed.carrierRatesPlatforms,
        carrierId: updateSelector(state, "carrierId"),
        subscriptions: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo"),
        subscription: getSubscription(state.account.currentUser.clientModel),
        agencies: state.account.currentUser.clientModel.agencies,
        firmId: updateSelector(state, "firmId"),
        routeRatesPlatformDatas: state.routeRatesPlatformDatas.entities,
        portsZones: state.seed.portsZones,
        countries: state.seed.countries,
    } as RouteRatesPlatformDatasFormOwnProps;
})(updateForm) as any as React.ComponentClass<RouteRatesPlatformDatasFormOwnPropFormExternalProps>