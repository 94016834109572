import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from "redux";
import * as Api from "../api/api";
import { AppThunkAction } from "./";
import { getDefaultHeaders, getPort } from "../utils/utils";
import { ReceiveSelection } from "./Selection";

export interface CarrierRatesPlatformState {
    status: {
        isOpen: boolean;
        isLoading: boolean;
        requestTime?: number;
        statusSearch: {
            originPort?: Api.LocationModel,
            destinationPort?: Api.LocationModel
        };
        currentStatusSearch?: Api.CarrierRatesPlatformsStatusModel;
        carrierRatesPlatforms: Array<Api.CarrierRatesPlatformModel>;
    }
}

interface RequestCarrierRatesPlatformStatus {
    type: "REQUEST_CARRIERRATESPLATFORM_STATUS";
    payload: { requestTime: number };
}
interface ReceiveCarrierRatesPlatformStatus {
    type: "RECEIVE_CARRIERRATESPLATFORM_STATUS";
    payload: {
        requestTime: number;
        currentStatusSearch: Api.CarrierRatesPlatformsStatusModel;
        entities?: Array<Api.CarrierRatesPlatformModel>
    };
    error?: any;
}

interface CarrierRatesPlatformUpdateOrigin {
    type: "CARRIERRATESPLATFORM_UPDATE_ORIGIN";
    payload: {
        value: Api.LocationModel;
    }
}
interface CarrierRatesPlatformUpdateDestination {
    type: "CARRIERRATESPLATFORM_UPDATE_DESTINATION";
    payload: {
        value: Api.LocationModel;
    }
}

interface OpenCarrierRatesPlatformStatus {
    type: "OPEN_CARRIERRATESPLATFORMSTATUS"
}
interface CloseCarrierRatesPlatformStatus {
    type: "CLOSE_CARRIERRATESPLATFORMSTATUS"
}

export type KnownAction = RequestCarrierRatesPlatformStatus
    | ReceiveCarrierRatesPlatformStatus
    | OpenCarrierRatesPlatformStatus
    | CloseCarrierRatesPlatformStatus
    | CarrierRatesPlatformUpdateOrigin
    | CarrierRatesPlatformUpdateDestination
    | ReceiveSelection
    ;

export const actionCreators = {
    requestCarrierRatesPlatform: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RatesFetcherApi();
        let model = {
            originPortId: getState().carrierRatesPlatform.status.statusSearch.originPort.locationId,
            destinationPortId: getState().carrierRatesPlatform.status.statusSearch.destinationPort.locationId,
        };
        let fetch = api.carrierRatesPlatformsStatus({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entities => {
                dispatch({
                    type: "RECEIVE_CARRIERRATESPLATFORM_STATUS",
                    payload: {
                        requestTime: requestTime,
                        entities: entities,
                        currentStatusSearch: model
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CARRIERRATESPLATFORM_STATUS",
                    payload: {
                        requestTime: requestTime,
                        currentStatusSearch: model
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CARRIERRATESPLATFORM_STATUS",
            payload: { requestTime: requestTime }
        });
        addTask(fetch);
        return fetch;
    },
    openCarrierRatesPlatformStatus: () => <OpenCarrierRatesPlatformStatus>{ type: "OPEN_CARRIERRATESPLATFORMSTATUS" },
    closeCarrierRatesPlatformStatus: () => <CloseCarrierRatesPlatformStatus>{ type: "CLOSE_CARRIERRATESPLATFORMSTATUS" },
    updateCarrierRatesPlatformOrigin: (value: Api.LocationModel) => <CarrierRatesPlatformUpdateOrigin>{
        type: "CARRIERRATESPLATFORM_UPDATE_ORIGIN", payload: { value: value }
    },
    updateCarrierRatesPlatformDestination: (value: Api.LocationModel) => <CarrierRatesPlatformUpdateDestination>{
        type: "CARRIERRATESPLATFORM_UPDATE_DESTINATION", payload: { value: value }
    },
};


const unloadedState: CarrierRatesPlatformState = {
    status: {
        isOpen: false,
        isLoading: false,
        carrierRatesPlatforms: [],
        statusSearch: {}
    }
};


export const reducer: Reducer<CarrierRatesPlatformState> = (state: CarrierRatesPlatformState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CARRIERRATESPLATFORM_STATUS":
            return {
                ...state,
                status: {
                    ...state.status,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CARRIERRATESPLATFORM_STATUS":
            if (state.status.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                status: {
                    ...state.status,
                    isLoading: false,
                    carrierRatesPlatforms: action.payload.entities,
                    currentStatusSearch: action.payload.currentStatusSearch
                }
            };
        case "OPEN_CARRIERRATESPLATFORMSTATUS":
            return {
                ...state,
                status: {
                    ...state.status,
                    isOpen: true
                }
            };
        case "CLOSE_CARRIERRATESPLATFORMSTATUS":
            return {
                ...state,
                status: {
                    ...state.status,
                    isOpen: false
                }
            };
        case "CARRIERRATESPLATFORM_UPDATE_ORIGIN":
            return {
                ...state,
                status: {
                    ...state.status,
                    statusSearch: {
                        ...state.status.statusSearch,
                        originPort: action.payload.value
                    }
                }
            };
        case "CARRIERRATESPLATFORM_UPDATE_DESTINATION":
            return {
                ...state,
                status: {
                    ...state.status,
                    statusSearch: {
                        ...state.status.statusSearch,
                        destinationPort: action.payload.value
                    }
                }
            };
        case "RECEIVE_SELECTION":
            return {
                ...state,
                status: {
                    ...state.status,
                    statusSearch: {
                        ...state.status.statusSearch,
                        originPort: getPort(action.payload.criteria.origin),
                        destinationPort: getPort(action.payload.criteria.destination)
                    }
                }
            }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};