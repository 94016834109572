import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from "redux";
import * as Api from "../api/api";
import * as Notifications from 'react-notification-system-redux';
import { AppThunkAction } from "./";
import { getDefaultHeaders } from "../utils/utils";
import { getText } from "../utils/langTexts";

export interface RouteRatesPlatformDatasState {
    isLoading: boolean;
    requestTime?: number;
    selectedId?: number;
    entities: Array<Api.RouteRatesPlatformDataModel>;
    createState: {
        isLoading: boolean;
        requestTime?: number;
    };
    updateState: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    }
    deleteState: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    };
}

interface RequestRouteRatesPlatformDatasEntities {
    type: "REQUEST_ROUTERATESPLATFORMDATAS_ENTITIES";
    payload: { requestTime: number };
}
interface ReceiveRouteRatesPlatformDatasEntities {
    type: "RECEIVE_ROUTERATESPLATFORMDATAS_ENTITIES";
    payload: { requestTime: number; entities?: Array<Api.RouteRatesPlatformDataModel> };
    error?: any;
}

interface RequestRouteRatesPlatformDatasCreate {
    type: "REQUEST_ROUTERATESPLATFORMDATAS_CREATE";
    payload: { requestTime: number };
}
interface ReceiveRouteRatesPlatformDatasCreate {
    type: "RECEIVE_ROUTERATESPLATFORMDATAS_CREATE";
    payload: { requestTime: number; entity?: Api.CarrierRatesSettingsModel };
    error?: any;
}

interface RequestRouteRatesPlatformDatasUpdate {
    type: "REQUEST_ROUTERATESPLATFORMDATAS_UPDATE";
    payload: { requestTime: number; id: number; };
}
interface ReceiveRouteRatesPlatformDatasUpdate {
    type: "RECEIVE_ROUTERATESPLATFORMDATAS_UPDATE";
    payload: { requestTime: number; id: number; entity?: Api.CarrierRatesSettingsModel };
    error?: any;
}

interface RequestRouteRatesPlatformDatasDelete {
    type: "REQUEST_ROUTERATESPLATFORMDATAS_DELETE";
    payload: { requestTime: number; id: number; };
}
interface ReceiveRouteRatesPlatformDatasDelete {
    type: "RECEIVE_ROUTERATESPLATFORMDATAS_DELETE";
    payload: { requestTime: number; id: number; };
    error?: any;
}

interface UpdateRouteRatesPlatformDatasSelectedId {
    type: "UPDATE_ROUTERATESPLATFORMDATAS_SELECTEDID";
    payload: { value: number; };
}

export type KnownAction = RequestRouteRatesPlatformDatasEntities
    | ReceiveRouteRatesPlatformDatasEntities
    | RequestRouteRatesPlatformDatasCreate
    | ReceiveRouteRatesPlatformDatasCreate
    | RequestRouteRatesPlatformDatasUpdate
    | ReceiveRouteRatesPlatformDatasUpdate
    | RequestRouteRatesPlatformDatasDelete
    | ReceiveRouteRatesPlatformDatasDelete
    | UpdateRouteRatesPlatformDatasSelectedId
    ;

export const actionCreators = {
    requestRouteratesPlatformDatas: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RouteRatesPlatformDatasApi();
        let fetch = api.getEntities({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entities => {
                dispatch({
                    type: "RECEIVE_ROUTERATESPLATFORMDATAS_ENTITIES",
                    payload: { requestTime: requestTime, entities: entities }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROUTERATESPLATFORMDATAS_ENTITIES",
                    payload: { requestTime: requestTime, },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_ROUTERATESPLATFORMDATAS_ENTITIES",
            payload: { requestTime: requestTime }
        });
        addTask(fetch);
        return fetch;
    },
    requestCreateRouteRatesPlatformDatas: (requestTime: number, model: Api.CarrierRatesSettingsModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RouteRatesPlatformDatasApi();
        let fetch = api.create({ model: model }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entity => {
                dispatch({
                    type: "RECEIVE_ROUTERATESPLATFORMDATAS_CREATE",
                    payload: { requestTime: requestTime, entity: entity }
                });
                dispatch(Notifications.success({ message: getText("BlckListApiSucces"), title: getText("GenSuccess"), position: "tc" }) as any);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROUTERATESPLATFORMDATAS_CREATE",
                    payload: { requestTime: requestTime, },
                    error: err
                });
                dispatch(Notifications.error({ message: "Uknown error", title: getText("GenError"), position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_ROUTERATESPLATFORMDATAS_CREATE",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRouteRatesPlatformDatas: (requestTime: number, model: Api.RouteRatesPlatformDataModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RouteRatesPlatformDatasApi();
        let fetch = api.update({ model: model }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entity => {
                dispatch({
                    type: "RECEIVE_ROUTERATESPLATFORMDATAS_UPDATE",
                    payload: { requestTime: requestTime, id: model.routeRatesPlatformDataId, entity: entity }
                });
                dispatch(Notifications.success({ message: getText("BlckListApiSucces"), title: getText("GenSuccess"), position: "tc" }) as any);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROUTERATESPLATFORMDATAS_UPDATE",
                    payload: { requestTime: requestTime, id: model.routeRatesPlatformDataId },
                    error: err
                });
                dispatch(Notifications.error({ message: "Uknown error", title: getText("GenError"), position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_ROUTERATESPLATFORMDATAS_UPDATE",
            payload: { requestTime: requestTime, id: model.routeRatesPlatformDataId }
        });
        return fetch;
    },
    requestDeleteRouteRatesPlatformData: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RouteRatesPlatformDatasApi();
        let fetch = api._delete({ id: id }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_ROUTERATESPLATFORMDATAS_DELETE",
                    payload: { requestTime: requestTime, id: id }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROUTERATESPLATFORMDATAS_DELETE",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_ROUTERATESPLATFORMDATAS_DELETE",
            payload: { requestTime: requestTime, id: id }
        });
        return fetch;
    },
    updatRouteratesPlatformDataSelectedId: (id: number) => <UpdateRouteRatesPlatformDatasSelectedId>{
        type: "UPDATE_ROUTERATESPLATFORMDATAS_SELECTEDID",
        payload: { value: id }
    }
};


const unloadedState: RouteRatesPlatformDatasState = {
    isLoading: false,
    createState: {
        isLoading: false
    },
    deleteState: {},
    updateState: {},
    entities: []
};


export const reducer: Reducer<RouteRatesPlatformDatasState> = (state: RouteRatesPlatformDatasState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ROUTERATESPLATFORMDATAS_ENTITIES":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_ROUTERATESPLATFORMDATAS_ENTITIES":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                entities: action.error
                    ? state.entities
                    : action.payload.entities
            };
        case "REQUEST_ROUTERATESPLATFORMDATAS_CREATE":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_ROUTERATESPLATFORMDATAS_CREATE":
            if (action.payload.requestTime !== state.createState.requestTime)
                return state;

            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: false,
                },
                entities: action.error
                    ? state.entities
                    : state.entities.concat([action.payload.entity])
            };
        case "REQUEST_ROUTERATESPLATFORMDATAS_UPDATE":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    [action.payload.id]: {
                        ...state.updateState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROUTERATESPLATFORMDATAS_UPDATE":
            if (!state.updateState[action.payload.id]
                || action.payload.requestTime !== state.updateState[action.payload.id].requestTime)
                return state;

            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    [action.payload.id]: {
                        ...state.updateState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                },
                entities: action.error
                    ? state.entities
                    : state.entities.map(x => x.routeRatesPlatformDataId === action.payload.entity.carrierRatesSettingsId
                        ? { ...x, ...action.payload.entity }
                        : x)
            };
        case "REQUEST_ROUTERATESPLATFORMDATAS_DELETE":
            return {
                ...state,
                deleteState: {
                    ...state.deleteState,
                    [action.payload.id]: {
                        ...state.deleteState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROUTERATESPLATFORMDATAS_DELETE":
            if (!state.deleteState[action.payload.id]
                || action.payload.requestTime !== state.deleteState[action.payload.id].requestTime)
                return state;

            return {
                ...state,
                deleteState: {
                    ...state.deleteState,
                    [action.payload.id]: {
                        ...state.deleteState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                },
                selectedId: action.payload.id === state.selectedId
                    ? undefined
                    : state.selectedId,
                entities: action.error
                    ? state.entities
                    : state.entities.filter(x => x.routeRatesPlatformDataId !== action.payload.id)
            };
        case "UPDATE_ROUTERATESPLATFORMDATAS_SELECTEDID":
            return {
                ...state,
                selectedId: action.payload.value
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};