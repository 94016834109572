import * as Api from '../api/api';
import { getText } from './langTexts';

export const getOnlineOfferErrorText = (error: Api.RatesFetcherErrorModel, params?: any): string => {
    let text = "";
    switch (error.type) {    //4637 - for now we want only 3 types of messages

        case "NoConnection":
            text = getText("SlcCarrierQuotesNotResponding", params);
            break;
        case "NoRightsForAccess":
            text = getText("SlcCarrierQuotesNoRights", params);
            break;
        case "AccountExpired":
            text = getText("SlcCarrierQuotesNoRights", params);
            break;
        case "AntiBotStarted":
            text = getText("SlcCarrierQuotesAntibot", params);
            break;
        case "AuthenticationError":
            text = getText("SlcCarrierQuotesAuthenticationFailed", params);
            break;
        case "WrongCredentionals":
            text = getText("SlcCarrierQuotesWrongCredentials", params);
            break;
        case "ApiLimitReached":
            text = getText("SlcCarrierQuotesApiLimitReached", params);
            break;
        case "OkargoApiLimitReached":
            text = getText("SlcCarrierQuotesOkargoApiLimitReached", params);
            break;
        case "EmptyResults":
            text = getText("SlcCarrierQuotesNoResults", params);
            break;
        case "Mfa":
            text = getText("SlcCarrierQuotesAdditionalVerification", params) ;
            break;
        default:
            text = getText("SlcCarrierQuotesError", params);
            break;
    }

    return text;
};