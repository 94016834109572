import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { SubmissionError } from 'redux-form';
import { Logout } from './Account';

export interface RatesOfferState {
    isLoading: boolean;
    requestTime: number;
    searchRatesOffersModel: Api.SearchRatesOffersModel;
    ratesOffers: Array<Api.RatesOfferModel>;
    editState: EditRatesOfferState;
    ratesOffersByTerm: RatesOfferByTermState;
}

export interface RatesOfferByTermState {
    [key: string]: {
        isLoading: boolean;
        requestTime ?: number;
        term: string;
        ratesOffers: Array<Api.RatesOfferModel>;
    }
}

export interface EditRatesOfferState {
    ratesOfferId?: number;
    isLoading: boolean;
    requestTime: number;
}

interface RatesOffersSelectCarrier { type: "RATESOFFERS_SELECT_CARRIER"; carrierId: number; }
interface RatesOffersUpdateSearchTerm { type: "RATESOFFERS_UPDATE_SEARCH_TERM"; value: string; }

interface RequestRatesOffers { type: "REQUEST_RATESOFFERS"; payload: { requestTime: number; } }
interface ReceiveRatesOffers { type: "RECEIVE_RATESOFFERS"; payload: { requestTime: number; ratesOffers: Array<Api.RatesOfferModel> }; error?: any }

interface SelectRatesOffer { type: "SELECT_RATESOFFER"; ratesOfferId: number }

interface RequestEditRatesOffer { type: "REQUEST_EDIT_RATESOFFER"; payload: { requestTime: number; } }
interface ReceiveEditRatesOffer { type: "RECEIVE_EDIT_RATESOFFER"; payload: { requestTime: number; ratesOffer: Api.RatesOfferModel }; error?: any }

interface RequestSearchRatesOffer {
    type: "REQUEST_SEARCH_RATESOFFER";
    payload: { key: string; requestTime: number; }
}
interface ReceiveSearchRatesOffer {
    type: "RECEIVE_SEARCH_RATESOFFER";
    payload: { key: string; requestTime: number; ratesOffers?: Array<Api.RatesOfferModel>; };
    error?: any;
}
interface UpdateSearchRatesOfferTerm {
    type: "UPDATE_SEARCH_RATESOFFER_TERM";
    payload: { key: string; value: string; };
}
interface EmptySearchRatesOfferTerm {
    type: "EMPTY_SEARCH_RATESOFFER";
    payload: { key: string; };
}

type KnownAction = RatesOffersSelectCarrier | RatesOffersUpdateSearchTerm
    | RequestRatesOffers | ReceiveRatesOffers | SelectRatesOffer
    | RequestEditRatesOffer | ReceiveEditRatesOffer
    | RequestSearchRatesOffer | ReceiveSearchRatesOffer
    | UpdateSearchRatesOfferTerm
    | EmptySearchRatesOfferTerm
    | Logout;


export const actionCreators = {
    requestRatesOffers: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().ratesOffer.requestTime)
            return;

        let api = new Api.ModeratorApi();
        let fetchTask = api.searchRatesOffers({
            model: getState().ratesOffer.searchRatesOffersModel
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(ratesOffers => {
                dispatch({ type: "RECEIVE_RATESOFFERS", payload: { requestTime: requestTime, ratesOffers: ratesOffers } });
        }).catch(error => {
            console.log('Error: ' + error.message);
            dispatch(Notifications.error({ message: "Error searching rates information", title: "Error", position: "tc" }) as any);
            dispatch({ type: "RECEIVE_RATESOFFERS", payload: { requestTime: requestTime, ratesOffers: [] }, error: error });
        });
        addTask(fetchTask);
        dispatch({ type: "REQUEST_RATESOFFERS", payload: { requestTime: requestTime } });
    },
    requestEditRatesOffer: (requestTime: number, ratesOffer: Api.RatesOfferModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().ratesOffer.editState.requestTime)
            return Promise.resolve();

        let api = new Api.ModeratorApi();
        let fetchTask = api.editRatesOffer({ model: ratesOffer },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(ratesOfferResult => {
                dispatch({ type: "RECEIVE_EDIT_RATESOFFER", payload: { requestTime: requestTime, ratesOffer: ratesOfferResult } });
                dispatch(Notifications.success({ message: "Your rates information have been updated", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_EDIT_RATESOFFER", payload: { requestTime: requestTime, ratesOffer: ratesOffer }, error: error });
                throw new SubmissionError({ _error: "Failed to edit" } as any)
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_EDIT_RATESOFFER", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    requestGetRatesOfferByTerm: (key:string, carrierId: number, requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RatesOfferApi();
        let fetchTask = api.getRatesOffersFromTerm({
            term: getState().ratesOffer.ratesOffersByTerm[key]
                ? getState().ratesOffer.ratesOffersByTerm[key].term
                : "",
            carrierId: carrierId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(ratesOffers => {
                dispatch({
                    type: "RECEIVE_SEARCH_RATESOFFER",
                    payload: { key: key, requestTime: requestTime, ratesOffers: ratesOffers }
                });
            }).catch(error => {
                console.log('Error: ' + error.message);
                dispatch(Notifications.error({ message: "Error searching rates information", title: "Error", position: "tc" }) as any);
                dispatch({
                    type: "RECEIVE_SEARCH_RATESOFFER",
                    payload: { key: key, requestTime: requestTime }, error: error
                });
            });

        dispatch({ type: "REQUEST_SEARCH_RATESOFFER", payload: { key: key, requestTime: requestTime } });
        return fetchTask;
    },
    updatesearchRatesOfferTerm: (key: string, value: string) => <UpdateSearchRatesOfferTerm>{
        type: "UPDATE_SEARCH_RATESOFFER_TERM", payload: { key: key, value: value } },
    updateSearchTerm: (value: string) => <RatesOffersUpdateSearchTerm>{ type: "RATESOFFERS_UPDATE_SEARCH_TERM", value: value },
    selectCarrierId: (carrierId: number) => <RatesOffersSelectCarrier>{ type: "RATESOFFERS_SELECT_CARRIER", carrierId: carrierId },
    selectRatesOffer: (ratesOfferId: number) => <SelectRatesOffer>{ type: "SELECT_RATESOFFER", ratesOfferId: ratesOfferId },
    emptySearchRatesOffer: (key: string) => <EmptySearchRatesOfferTerm>{ type: "EMPTY_SEARCH_RATESOFFER", payload: { key: key } },
}

const unloadedState: RatesOfferState = {
    isLoading: false,
    requestTime: 0,
    ratesOffers: [],
    searchRatesOffersModel: {

    },
    editState: {
        isLoading: false,
        ratesOfferId: null,
        requestTime: 0
    },
    ratesOffersByTerm: {}
};


export const reducer: Reducer<RatesOfferState> = (state: RatesOfferState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_RATESOFFERS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime,
                editState: {
                    ...state.editState,
                    //Don't wanna risk a null reference
                    ratesOfferId: null
                }
            };
        case "RECEIVE_RATESOFFERS":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                ratesOffers: action.payload.ratesOffers
            };
        case "RATESOFFERS_SELECT_CARRIER":
            return {
                ...state,
                searchRatesOffersModel: {
                    ...state.searchRatesOffersModel,
                    carrierId: action.carrierId
                }
            };
        case "RATESOFFERS_UPDATE_SEARCH_TERM":
            return {
                ...state,
                searchRatesOffersModel: {
                    ...state.searchRatesOffersModel,
                    searchTerm: action.value
                }
            };
        case "SELECT_RATESOFFER":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    ratesOfferId: action.ratesOfferId
                }
            };
        case "REQUEST_EDIT_RATESOFFER":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_EDIT_RATESOFFER":
            return {
                ...state,
                ratesOffers: action.error
                    ? state.ratesOffers
                    : state.ratesOffers
                    .map(ro => ro.ratesOfferId === action.payload.ratesOffer.ratesOfferId ? action.payload.ratesOffer : ro),
                editState: {
                    ...state.editState,
                    isLoading: action.payload.requestTime !== state.editState.requestTime
                }
            };
        case "REQUEST_SEARCH_RATESOFFER":
            return {
                ...state,
                ratesOffersByTerm: {
                    ...state.ratesOffersByTerm,
                    [action.payload.key]: {
                        ...state.ratesOffersByTerm[action.payload.key],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_SEARCH_RATESOFFER":
            if (!state.ratesOffersByTerm[action.payload.key]
                || state.ratesOffersByTerm[action.payload.key].requestTime != action.payload.requestTime)
                return state;

            return {
                ...state,
                ratesOffersByTerm: {
                    ...state.ratesOffersByTerm,
                    [action.payload.key]: {
                        ...state.ratesOffersByTerm[action.payload.key],
                        isLoading: false,
                        ratesOffers: action.error
                            ? (state.ratesOffersByTerm[action.payload.key]
                                ? state.ratesOffersByTerm[action.payload.key].ratesOffers
                                : [])
                            : action.payload.ratesOffers
                    }
                }
            };
        case "UPDATE_SEARCH_RATESOFFER_TERM":
            return {
                ...state,
                ratesOffersByTerm: {
                    ...state.ratesOffersByTerm,
                    [action.payload.key]: {
                        ...state.ratesOffersByTerm[action.payload.key],
                        term: action.payload.value
                    }
                }
            };
        case "EMPTY_SEARCH_RATESOFFER":
            return {
                ...state,
                ratesOffersByTerm: {
                    ...state.ratesOffersByTerm,
                    [action.payload.key]: {
                        ...state.ratesOffersByTerm[action.payload.key],
                        ratesOffers: []
                    }
                }
            };
        case "LOGOUT":
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
