import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import { Logout } from './Account';

export interface CarrierCarrierRatingState {
    isLoading: boolean;
    requestTime?: number;
    carrierCarrierRatings: Array<Api.CarrierCarrierRatingModel>;
    carrierCarrierRatingsState: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    },
    createState: {
        isLoading: boolean;
        requestTime?: number;
    },
    updateState: {
        isLoading: boolean;
        requestTime?: number;
    },
    selectedCarrierCarrierRatingId?: number;
}

interface RequestCarrierCarrierRatings {
    type: "REQUEST_CARRIER_CARRIER_RATINGS";
    payload: { requestTime: number; }
}
interface ReceiveCarrierCarrierRatings {
    type: "RECEIVE_CARRIER_CARRIER_RATINGS";
    payload: {
        requestTime: number;
        carrierCarrierRatings?: Array<Api.CarrierCarrierRatingModel>;
    };
    error?: any;
}

interface RequestCreateCarrierCarrierRating {
    type: "REQUEST_CREATE_CARRIER_CARRIER_RATING";
    payload: { requestTime: number; }
}
interface ReceiveCreateCarrierCarrierRating {
    type: "RECEIVE_CREATE_CARRIER_CARRIER_RATING";
    payload: {
        requestTime: number;
        carrierCarrierRating?: Api.CarrierCarrierRatingModel;
    };
    error?: any;
}

interface RequestUpdateCarrierCarrierRating {
    type: "REQUEST_UPDATE_CARRIER_CARRIER_RATING";
    payload: { requestTime: number; }
}
interface ReceiveUpdateCarrierCarrierRating {
    type: "RECEIVE_UPDATE_CARRIER_CARRIER_RATING";
    payload: {
        requestTime: number;
        carrierCarrierRating?: Api.CarrierCarrierRatingModel;
    };
    error?: any;
}

interface RequestDeleteCarrierCarrierRating {
    type: "REQUEST_DELETE_CARRIER_CARRIER_RATING";
    payload: { requestTime: number; id: number; }
}
interface ReceiveDeleteCarrierCarrierRating {
    type: "RECEIVE_DELETE_CARRIER_CARRIER_RATING";
    payload: {
        requestTime: number;
        id: number;
    };
    error?: any;
}
interface UpdateCarrierCarrierRatingSelected {
    type: "UPDATE_CARRIER_CARRIER_RATINGS_SELECTED";
    payload: { id: number };
}


type KnownAction = RequestCarrierCarrierRatings
    | ReceiveCarrierCarrierRatings
    | RequestCreateCarrierCarrierRating
    | ReceiveCreateCarrierCarrierRating
    | RequestDeleteCarrierCarrierRating
    | ReceiveDeleteCarrierCarrierRating
    | UpdateCarrierCarrierRatingSelected
    | RequestUpdateCarrierCarrierRating
    | ReceiveUpdateCarrierCarrierRating
    | Logout;

export const actionCreators = {
    requestCarrierCarrierRatings: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CarrierCarrierRatingApi();
        let fetchTask = api.getEntities({
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        })
            .then(carrierCarrierRatings => {
                dispatch({
                    type: "RECEIVE_CARRIER_CARRIER_RATINGS",
                    payload: {
                        requestTime: requestTime,
                        carrierCarrierRatings: carrierCarrierRatings
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CARRIER_CARRIER_RATINGS",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CARRIER_CARRIER_RATINGS",
            payload: {
                requestTime: requestTime,
            }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestCreateCarrierCarrierRating: (requestTime: number, model: Api.CarrierRatingModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CarrierCarrierRatingApi();
        let fetchTask = api.create({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(carrierCarrierRating => {
                dispatch({
                    type: "RECEIVE_CREATE_CARRIER_CARRIER_RATING",
                    payload: {
                        requestTime: requestTime,
                        carrierCarrierRating: carrierCarrierRating
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_CARRIER_CARRIER_RATING",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CREATE_CARRIER_CARRIER_RATING",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requestUpdateCarrierCarrierRating: (requestTime: number, model: Api.CarrierRatingModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CarrierCarrierRatingApi();
        let fetchTask = api.update({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(carrierCarrierRating => {
                dispatch({
                    type: "RECEIVE_UPDATE_CARRIER_CARRIER_RATING",
                    payload: {
                        requestTime: requestTime,
                        carrierCarrierRating: carrierCarrierRating
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_CARRIER_CARRIER_RATING",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_CARRIER_CARRIER_RATING",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requestDeleteCarrierRating: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CarrierCarrierRatingApi();
        let fetchTask = api._delete({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_DELETE_CARRIER_CARRIER_RATING",
                    payload: {
                        requestTime: requestTime,
                        id: id
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DELETE_CARRIER_CARRIER_RATING",
                    payload: {
                        requestTime: requestTime,
                        id: id
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_DELETE_CARRIER_CARRIER_RATING",
            payload: {
                requestTime: requestTime,
                id: id
            }
        });
        return fetchTask;
    },
    updateSelectedCarrierId: (id: number) => <UpdateCarrierCarrierRatingSelected>{
        type: "UPDATE_CARRIER_CARRIER_RATINGS_SELECTED",
        payload: { id: id }
    }
}

const unloadedState: CarrierCarrierRatingState = {
    isLoading: false,
    carrierCarrierRatings: [],
    createState: {
        isLoading: false
    },
    updateState: {
      isLoading: false  
    },
    carrierCarrierRatingsState: {}
};


export const reducer: Reducer<CarrierCarrierRatingState> = (state: CarrierCarrierRatingState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CARRIER_CARRIER_RATINGS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime,
            };
        case "RECEIVE_CARRIER_CARRIER_RATINGS":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                carrierCarrierRatings: action.error
                    ? state.carrierCarrierRatings 
                    : action.payload.carrierCarrierRatings
            };
        case "REQUEST_CREATE_CARRIER_CARRIER_RATING":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_CARRIER_CARRIER_RATING":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: false,
                    requestTime: action.payload.requestTime
                },
                carrierCarrierRatings: action.error
                    ? state.carrierCarrierRatings
                    : state.carrierCarrierRatings
                        .concat([action.payload.carrierCarrierRating])
            };
        case "REQUEST_UPDATE_CARRIER_CARRIER_RATING":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_CARRIER_CARRIER_RATING":
            if(state.updateState.requestTime !== action.payload.requestTime)
                return state;
                
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: false,
                    requestTime: action.payload.requestTime
                },
                carrierCarrierRatings: action.error
                    ? state.carrierCarrierRatings
                    : state.carrierCarrierRatings.map(x => x.carrierCarrierRatingId === action.payload.carrierCarrierRating.carrierCarrierRatingId
                        ? action.payload.carrierCarrierRating
                        : x)
            };
        case "REQUEST_DELETE_CARRIER_CARRIER_RATING":
            return {
                ...state,
                carrierCarrierRatingsState: {
                    ...state.carrierCarrierRatingsState,
                    [action.payload.id]: {
                        ...state.carrierCarrierRatingsState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_DELETE_CARRIER_CARRIER_RATING":
            if (state.carrierCarrierRatingsState[action.payload.id]
                && state.carrierCarrierRatingsState[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                carrierCarrierRatingsState: {
                    ...state.carrierCarrierRatingsState,
                    [action.payload.id]: {
                        ...state.carrierCarrierRatingsState[action.payload.id],
                        isLoading: false,
                    }
                },
                carrierCarrierRatings: action.error
                    ? state.carrierCarrierRatings
                    : state.carrierCarrierRatings.filter(x => x.carrierCarrierRatingId !== action.payload.id)
            };
        case "UPDATE_CARRIER_CARRIER_RATINGS_SELECTED":
            return {
                ...state,
                selectedCarrierCarrierRatingId: action.payload.id
            };
        case "LOGOUT":
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
