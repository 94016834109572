import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { SubmissionError } from 'redux-form';
import { Logout } from './Account';

export interface FirmNewsState {
    isLoading: boolean;
    requestTime: number;
    firmNewses: Array<Api.FirmNewsModel>;
    editState: EditFirmNewsState;
    deleteStates: { [id: number]: DeleteFirmNewsState };
}

export interface EditFirmNewsState {
    firmNewsId?: number;
    isLoading: boolean;
    requestTime: number;
    formAction: FormCrudeActions;
}

export interface DeleteFirmNewsState {
    isLoading: boolean;
    requestTime: number;
}

export enum FormCrudeActions {
    create = "create",
    edit = "edit",
    delete = "delete"
}

interface RequestFirmNeweses { type: "REQUEST_FIRMNEWSES"; requestTime: number; }
interface ReceiveFirmNeweses { type: "RECEIVE_FIRMNEWSES"; requestTime: number; firmNewses: Array<Api.FirmNewsModel> }

interface SelectFirmNews { type: "SELECT_FIRMNEWS"; firmNewsId: number }

interface RequestDeleteFirmNews { type: "REQUEST_DELETE_FIRMNEWS"; payload: { requestTime: number; firmNewsId: number } }
interface ReceiveDeleteFirmNews { type: "RECEIVE_DELETE_FIRMNEWS"; payload: { requestTime: number; firmNewsId: number }; error?: any  }

interface SetFirmNewsFormAction { type: "SET_FIRMNEWS_FORMACTION"; value: FormCrudeActions }

interface RequestEditFirmNews { type: "REQUEST_EDIT_FIRMNEWS"; payload: { requestTime: number; } }
interface ReceiveEditFirmNews { type: "RECEIVE_EDIT_FIRMNEWS"; payload: { requestTime: number; firmNews: Api.FirmNewsModel }; error?: any  }

interface RequestCreateFirmNews { type: "REQUEST_CREATE_FIRMNEWS"; payload: { requestTime: number; } }
interface ReceiveCreateFirmNews { type: "RECEIVE_CREATE_FIRMNEWS"; payload: { requestTime: number; firmNews: Api.FirmNewsModel }; error?: any }

type KnownAction = RequestFirmNeweses | ReceiveFirmNeweses
    | SelectFirmNews | RequestEditFirmNews | ReceiveEditFirmNews
    | RequestCreateFirmNews | ReceiveCreateFirmNews
    | RequestDeleteFirmNews | ReceiveDeleteFirmNews
    | SetFirmNewsFormAction | Logout;


export const actionCreators = {
    requestFirmNewses: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().ratesOffer.requestTime)
            return;

        let api = new Api.ModeratorApi();
        var fetchTask = api.getFirmNews({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(firmNewses => {
                dispatch({ type: "RECEIVE_FIRMNEWSES", requestTime: requestTime, firmNewses: firmNewses });
            }).catch(error => {
                console.log('Error: ' + error.message);
                dispatch(Notifications.error({ message: "Error getting news", title: "Error", position: "tc" }) as any);
                dispatch({ type: "RECEIVE_FIRMNEWSES", requestTime: requestTime, firmNewses: [] });
            });
        addTask(fetchTask);
        dispatch({ type: "REQUEST_FIRMNEWSES", requestTime: requestTime });
    },
    requestEditFirmNews: (requestTime: number, firmNews: Api.FirmNewsModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().firmNews.editState.requestTime)
            return Promise.resolve();

        let api = new Api.ModeratorApi();
        let fetchTask = api.editFirmNews({ model: firmNews },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(firmNewsRedult => {
                dispatch({ type: "RECEIVE_EDIT_FIRMNEWS", payload: { requestTime: requestTime, firmNews: firmNewsRedult } });
                if (firmNewsRedult)
                    dispatch(Notifications.success({ message: "Your news have been updated", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_EDIT_FIRMNEWS", payload: { requestTime: requestTime, firmNews: null }, error: error });
                dispatch(Notifications.error({ message: "Failed to edit news", title: "Error", position: "tc" }) as any);
                throw new SubmissionError({ _error: "Failed to edit" } as any)
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_EDIT_FIRMNEWS", payload: { requestTime: requestTime }});
        return fetchTask;
    },
    requestCreateFirmNews: (requestTime: number, firmNews: Api.FirmNewsModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().firmNews.editState.requestTime)
            return Promise.resolve();

        let api = new Api.ModeratorApi();
        let fetchTask = api.createFirmNews({ model: firmNews },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(firmNewsRedult => {
                dispatch({ type: "RECEIVE_CREATE_FIRMNEWS", payload: { requestTime: requestTime, firmNews: firmNewsRedult } });
                if (firmNewsRedult)
                    dispatch(Notifications.success({ message: "Your news have been created", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_CREATE_FIRMNEWS", payload: { requestTime: requestTime, firmNews: null }, error: error });
                dispatch(Notifications.error({ message: "Failed to create news", title: "Error", position: "tc" }) as any);
                throw new SubmissionError({ _error: "Failed to create" } as any)
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_CREATE_FIRMNEWS", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    requestDeleteFirmNews: (requestTime: number, firmNewsId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().firmNews.deleteStates[firmNewsId]
            && requestTime === getState().firmNews.deleteStates[firmNewsId].requestTime)
            return Promise.resolve();

        let api = new Api.ModeratorApi();
        let fetchTask = api.deleteFirmNews({ firmNewsId: firmNewsId },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(value => {
                dispatch({ type: "RECEIVE_DELETE_FIRMNEWS", payload: { requestTime: requestTime, firmNewsId: firmNewsId } });
            }).catch(error => {
                dispatch({ type: "RECEIVE_DELETE_FIRMNEWS", payload: { requestTime: requestTime, firmNewsId: firmNewsId }, error: error });
                dispatch(Notifications.success({ message: "Failed to delete news", title: "Success", position: "tc" }) as any);
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_DELETE_FIRMNEWS", payload: { requestTime: requestTime, firmNewsId: firmNewsId } });
        return fetchTask;
    },
    selectFirmNews: (firmNewsId: number) => <SelectFirmNews>{ type: "SELECT_FIRMNEWS", firmNewsId: firmNewsId },
    setFormAction: (value: FormCrudeActions) => <SetFirmNewsFormAction>{ type: "SET_FIRMNEWS_FORMACTION", value: value }
}

const unloadedState: FirmNewsState = {
    isLoading: false,
    requestTime: 0,
    firmNewses: [],
    editState: {
        isLoading: false,
        firmNewsId: null,
        requestTime: 0,
        formAction: FormCrudeActions.create
    },
    deleteStates: {}
};


export const reducer: Reducer<FirmNewsState> = (state: FirmNewsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_FIRMNEWSES":
            return {
                ...state,
                requestTime: action.requestTime,
                isLoading: true
            };
        case "RECEIVE_FIRMNEWSES":
            if (state.requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                firmNewses: action.firmNewses
            };
        case "SELECT_FIRMNEWS":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    firmNewsId: action.firmNewsId
                }
            };
        case "REQUEST_CREATE_FIRMNEWS":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_FIRMNEWS":
            if (action.payload.requestTime !== state.editState.requestTime)
                return state;

            return {
                ...state,
                firmNewses: action.error
                    ? state.firmNewses
                    : state.firmNewses.concat([action.payload.firmNews]),
                editState: {
                    ...state.editState,
                    isLoading: false,
                    firmNewsId: null
                }
            };
        case "REQUEST_EDIT_FIRMNEWS":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_EDIT_FIRMNEWS":
            return {
                ...state,
                firmNewses: action.error
                    ? state.firmNewses
                    : state.firmNewses.map(fi => fi.firmNewsId === action.payload.firmNews.firmNewsId
                    ? { ...fi, ...action.payload.firmNews } : fi),
                editState: {
                    ...state.editState,
                    //Only the last one will update
                    isLoading: action.payload.requestTime !== state.editState.requestTime
                        ? state.editState.isLoading : false
                }
            };
        case "REQUEST_DELETE_FIRMNEWS":
            return {
                ...state,
                deleteStates: {
                    ...state.deleteStates,
                    [action.payload.firmNewsId]: {
                        ...state.deleteStates[action.payload.firmNewsId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_DELETE_FIRMNEWS":
            if (state.deleteStates[action.payload.firmNewsId]
                && state.deleteStates[action.payload.firmNewsId].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                firmNewses: action.error
                    ? state.firmNewses
                    : state.firmNewses.filter(fi => fi.firmNewsId !== action.payload.firmNewsId),
                deleteStates: {
                    ...state.deleteStates,
                    [action.payload.firmNewsId]: {
                        ...state.deleteStates[action.payload.firmNewsId],
                        isLoading: false
                    }
                }
            };
        case "SET_FIRMNEWS_FORMACTION":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    formAction: action.value
                }
            };
        case "LOGOUT":
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
