import * as Api from '../api/api';
import * as React from 'react';
import DialogModal from "./DialogModal";
import Table, { Column } from "./Table";
import { quotationColumns } from './QuotationChrono';
import { getText } from '../utils/langTexts';

interface QuotationChronoDetailsDialogProps {
    carriers: { [id: number]: Api.CarrierModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    quotation: Api.QuotationModel;
    isOpen: boolean;
    onRequestClose: () => void;
    requestDownloadQuotation: (quotationId: number) => void;
}

export default class QuotationChronoDetailsDialog extends React.Component<QuotationChronoDetailsDialogProps, {}> {
    public render() {
        return (<DialogModal isOpen={this.props.isOpen}
            contentLabel={"Quotation details"}
            onRequestClose={() => this.props.onRequestClose()}>
            <div>
                <h3>{getText("GenPrevVersion")}</h3>
                <div>
                    {this.props.quotation && <Table
                        tableKey={"quoationChronoDetails"}
                        data={this.props.quotation.previousQuotations}
                        columns={quotationColumns(this.props).concat([{
                            id: "actions",
                            header: "",
                            accessor: (d) => d,
                            cell: row => {
                                let quotation = row as Api.QuotationModel;
                                let criteriaCode = quotation.criteria.code;
                                let chargeSetId = quotation.quotationOffers[0].carrierOffer.chargeSet
                                    ? quotation.quotationOffers[0].carrierOffer.chargeSet.chargeSetId
                                    : null;
                                let departId = quotation.quotationOffers[0].departId;

                                return <div>
                                    <a onClick={(e) => {
                                        this.props.requestDownloadQuotation(quotation.quotationId);
                                        e.preventDefault();
                                    }} style={{ cursor: "pointer" }}> download </a>
                                    {" "}
                                    <a href={"/quotation/" + criteriaCode + "/" + departId + "/" + chargeSetId + "/" + quotation.quotationId + "/Update"}>
                                        edit
                                        </a>
                                    {" "}
                                    <a href={"/quotation/" + criteriaCode + "/" + departId + "/" + chargeSetId + "/" + quotation.quotationId + "/Duplicate"}>
                                        duplicate
                                        </a>
                                </div>
                            }
                        }] as Array<Column<Api.QuotationModel>>)}
                        rowsPerPage={5}
                        noDataText=""
                        showPagination={true} />}
                </div>
            </div>
        </DialogModal>);
    }
}