import { getSubscription } from "../utils/utils";
import * as Api from '../api/api';

export const canUseSuggested = (subscription: Api.SubscriptionModel): boolean => {
    return subscription
        && subscription.agency
        && (subscription.agency.company.suggestedRates
            || subscription.suggestedRates);
};

export const canUseCarrierRates = (subscription: Api.SubscriptionModel): boolean => {
    return subscription
        && subscription.agency
        && (subscription.agency.company.carrierRates
            || subscription.agency.carrierRates
            || (subscription.agency.company.companyGroup && subscription.agency.company.companyGroup.carrierRates));
};

export const CanUseAskRates = (subscription: Api.SubscriptionModel): boolean => {
    return subscription
        && subscription.allowAskRates;
};

export const canModerateRates = (subscription: Api.SubscriptionModel): boolean => {
    return subscription
        && (subscription.allowRatesControl
        || subscription.allowRatesManagement);
};


export const canUploadRates = (subscription: Api.SubscriptionModel): boolean => {
    return subscription
        && (subscription.allowClientUploadRates);
};


export const canModerateInformations = (subscription: Api.SubscriptionModel): boolean => {
    return subscription
        && (subscription.allowInformUser);
};


export const canManageUsers = (subscription: Api.SubscriptionModel): boolean => {
    return subscription
        && (subscription.allowUserManagement);
};

export const canModerateAgents = (subscription: Api.SubscriptionModel): boolean => {
    return subscription
        && (subscription.allowAgentManagement);
};


export const canUseLcl = (subscription: Api.SubscriptionModel): boolean => {
    return subscription
        && subscription.agency
        && (subscription.agency.company.allowLcl);
};

export const canUseQuotation = (subscription: Api.SubscriptionModel): boolean => {
        return subscription
            && subscription.agency
            && subscription.agency.company.allowQuotation;
};

export const canUseInland = (subscription: Api.SubscriptionModel): boolean => {
        return subscription
            && subscription.agency
            && subscription.agency.company.allowInland;
};

export const canUseRatesExtraction = (subscription: Api.SubscriptionModel): boolean => {
    return subscription
        && subscription.agency
        && subscription.agency.company.allowRatesExtraction;
};