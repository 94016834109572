import * as React from 'react';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as RouteRatesPlatformDataStore from '../store/RouteRatesPlatformData';
import * as TabsStore from "../store/Tabs";

import { CreateRouteRatesPlatformDataForm, UpdateRouteRatesPlatformDatasForm } from "./RouteRatesPlatformDataForm";
import Loader from './Loader';
import Table, { Column } from './Table';
import { getText, upperCaseFirstLetter } from '../utils/langTexts';
import CenteredContentTabs from './CenteredContentTabs';
import { ApplicationState } from '../store';

type RouteRatesPlatformDatasProps = RouteRatesPlatformDatasOwnProps
    &   RouteRatesPlatformDataStore.RouteRatesPlatformDatasState
    & typeof RouteRatesPlatformDataStore.actionCreators
    & typeof TabsStore.actionCreators;

interface RouteRatesPlatformDatasOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
    carrierRatesPlatforms: { [id: number]: Api.CarrierRatesPlatformModel };
    subscriptions: Array<Api.SubscriptionModel>;
    firms: Api.FirmModel[];
    portsZones: { [id: number]: Api.PortsZoneModel };
    countries: { [id: number]: Api.LocationModel };
}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class RouteRatesPlatformDataSettings extends React.Component<RouteRatesPlatformDatasProps, {}> {

    componentWillMount() {
        this.props.requestRouteratesPlatformDatas(new Date().getTime());
    }

    componentWillReceiveProps(nextProps: RouteRatesPlatformDatasProps) {
    }

    public render() {
  
        return (
            <div>
                <h2>{getText("BlckListApiTittle")}</h2>
                <div>
                    <Table
                        tableKey={"apiBlackList"}
                        style={{ textAlign: "center" }}
                        columns={[
                            {
                                header: upperCaseFirstLetter(getText("GenFrom")),
                                id: 'from',
                                accessor: (d: Api.RouteRatesPlatformDataModel) => d.originPortsZoneId != null ?
                                    this.props.portsZones[d.originPortsZoneId].name : (d.originCountryId != null ? this.props.countries[d.originCountryId].name : d.originPort?.code),
                            },
                            {
                                header: upperCaseFirstLetter(getText("GenTo")),
                                id: 'to',
                                accessor: (d: Api.RouteRatesPlatformDataModel) => d.destinationPortsZoneId != null ?
                                    this.props.portsZones[d.destinationPortsZoneId].name : (d.destinationCountryId != null ? this.props.countries[d.destinationCountryId].name : d.destinationPort?.code),
                            },
                            {
                                header: "Carrier",
                                id: 'carrierId',
                                accessor: (d: Api.RouteRatesPlatformDataModel) => d.carrierId != null ?  this.props.carriers[d.carrierId].name : "",
                            },
                            {
                                id: "actions",
                                header: <div></div>,
                                accessor: (d: Api.RouteRatesPlatformDataModel) => d,
                                cell: row => {
                                    let routeDataPlatform = row as Api.RouteRatesPlatformDataModel;
                                    return (
                                        <div>
                                            <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                                this.props.updatRouteratesPlatformDataSelectedId(routeDataPlatform.routeRatesPlatformDataId);
                                                this.props.selectTab("abiBlackListKey", "edit");
                                            }}>{getText("GenEdit")}</a>
                                            <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                                if (window.confirm(getText("GenConfirmQuestion"))) {
                                                    this.props.requestDeleteRouteRatesPlatformData(new Date().getTime(), routeDataPlatform.routeRatesPlatformDataId);
                                                }
                                            }}>{ upperCaseFirstLetter( getText("GenDelete"))}</a>
                                        </div>

                                    )
                                }
                            },

                        ] as Array<Column<Api.RouteRatesPlatformDataModel>>}
                        data={this.props.entities }
                        rowsPerPage={8}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        showPagination={true}
                        noDataText="No results"
                    />
                </div>
                <div>
                    <CenteredContentTabs
                        tabKey={"abiBlackListKey"}
                        data={[
                            {
                                title: getText("GenCreate"),
                                tabKey: "create",
                                tabContent: <div style={{ padding: 10 }}>
                                    <h3>{getText("GenCreate")} {getText("BlckListApiRule")}</h3>
                                    <div style={{ maxWidth: 1000 }}>
                                        <CreateRouteRatesPlatformDataForm
                                            mode={"Create"}
                                            onSubmit={(values) => {
                                                return this.props.requestCreateRouteRatesPlatformDatas(new Date().getTime(), values) as any;
                                            }} />
                                    </div>
                                </div>
                            },
                            {
                                title: getText("GenEdit"),
                                tabKey: "edit",
                                tabContent: <div style={{ padding: 10 }}>
                                    <div style={{ maxWidth: 1000 }}>
                                        <h3>{getText("GenEdit")}  {getText("BlckListApiRule")}</h3>
                                        {this.props.selectedId
                                            && <div>
                                            <UpdateRouteRatesPlatformDatasForm
                                                    mode={"Update"}
                                                    onSubmit={(values) => {
                                                        return this.props.requestRouteRatesPlatformDatas(new Date().getTime(), values) as any;
                                                    }} />
                                            </div>}
                                    </div>
                                </div>
                            }
                        ]}
                    ></CenteredContentTabs>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.routeRatesPlatformDatas,
        carriers: state.seed.carriers,
        portsZones: state.seed.portsZones,
        countries: state.seed.countries,
    } as any),
    {
        ...RouteRatesPlatformDataStore.actionCreators,
        ...TabsStore.actionCreators
    })(RouteRatesPlatformDataSettings) as any