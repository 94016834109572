import * as React from 'react';

import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as CarrierRatesSettingsStore from '../store/CarrierRatesSettings';
import * as TabsStore from "../store/Tabs";
import Moment from 'moment';


import * as UserStore from '../store/Users';
import Loader from './Loader';

import { getText } from '../utils/langTexts';
import DialogModal from './DialogModal';
import Table, { Column } from './Table';
import ModeratorAgentSearchDetails from './ModeratorAgentSearchDetails';
import { revertColors } from '../styles/colors';
import { searchContainerStyle, searchFieldStyle, filterForm, formContainerStyle, inputStyleModerator, labelStyle, buttonStyle } from '../styles/moderatorStyles';
import { ApplicationState } from '../store';

type ModeratorUserProps = ModeratorUsersOwnProps
    & UserStore.UserState
    & typeof UserStore.actionCreators;


interface ModeratorUsersOwnProps {
    subscriptions: Array<Api.SubscriptionModel>;
    sizeTypes: { [id: number]: Api.SizeTypeModel }
    carriers: { [id: number]: Api.CarrierModel }
    chargeNames: { [id: number]: Api.ChargeNameModel }
    currencies: { [id: number]: Api.CurrencyModel }
}

export const getFirms = (subscriptions: Array<Api.SubscriptionModel>): Array<Api.FirmModel> => {
    return _.uniqBy(subscriptions
        .filter(su => su.subscriptionType === "Okargo")
        .filter(x => x.agency.company.companyGroup)
        .map(x => x.agency.company.companyGroup).concat(
        subscriptions.filter(so => so.subscriptionType === "Okargo")
        .map(so => so.agency)
        .concat(subscriptions.filter(so => so.subscriptionType === "Okargo")
            .map(so => so.agency.company))), x => x.firmId);
}

const partContainerStyle = {  };





class ModeratorUsers extends React.Component<ModeratorUserProps, {}> {



    componentWillMount() {
        this.props.requestUsers(new Date().getTime());
    }

    componentWillReceiveProps(nextProps: ModeratorUserProps) {
    }

    public render() {
        return (
            <div style={partContainerStyle}>
                <div>
                    <Table
                        tableKey={"modChargesAgents"}
                        data={this.props.entities}
                        columns={[
                            {
                                header: getText("GenCompanyGroup"),
                                id: 'group',
                                accessor: (d: Api.ClientModel) => d.subscriptionId ? d.subscriptions.find(x => x.subscriptionId == d.subscriptionId)?.agency?.company?.companyGroup?.name
                                    : (d.subscriptions[0] ?? null).agency?.company?.companyGroup?.name
                            },
                            {
                                header: getText("GenCompany"),
                                id: 'company',
                                accessor: (d: Api.ClientModel) => d.subscriptionId ? d.subscriptions.find(x => x.subscriptionId == d.subscriptionId)?.agency?.company?.name
                                    : (d.subscriptions[0] ?? null).agency?.company?.name
                            },
                            {
                                header: getText("GenAgency"),
                                id: 'agency',
                                accessor: (d: Api.ClientModel) => d.subscriptionId ? d.subscriptions.find(x => x.subscriptionId == d.subscriptionId)?.agency?.name
                                    : (d.subscriptions[0] ?? null)?.agency?.name
                            },
                            {
                                header: getText("QtnContFirstName"),
                                id: 'firstName',
                                accessor: (d: Api.ClientModel) => d.firstName
                            },

                            {
                                header: getText("QtnContLastName"),
                                id: 'lastName',
                                accessor: (d: Api.ClientModel) => d.lastName
                            },


                            {
                                header: getText("LogEmail"),
                                id: 'email',
                                accessor: (d: Api.ClientModel) => d.account.email
                            },
                            {
                                header: getText("MtdUsersCreated"),
                                id: 'creationDate',
                                accessor: (d: Api.ClientModel) => d.creationDate,
                                cell: (d: any) => <div>
                                    {Moment(d).format("DD/MM/YYYY")}
                                </div>
                            },
                            {
                                header: getText("MtdUsersExpired"),
                                id: 'expirationDate',
                                accessor: (d: Api.ClientModel) => d.account.expirationDate,
                                cell: (d: any) => <div>
                                    {d != null ? Moment(d).format("DD/MM/YYYY") : ""}
                                </div>
                            },
                            {
                                header: getText("MtdHasAcces"),
                                id: 'subscriptions',
                                accessor: (d: Api.ClientModel) => _.uniq(d.subscriptions.filter(s => s.subscriptionType == 'Okargo').map(x => x.agency?.name)).join(","),

                            },

                            {
                                header: "",
                                id: 'actions',
                                accessor: (d: Api.ClientModel) => d.clientId,
                                cell: d => {
                                    return <div>
                                        <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                            if (window.confirm(getText("GenConfirmQuestion"))) {
                                                this.props.requestDeactivateUser(new Date().getTime(), d);
                                            }
                                        }}>{getText("MtdDeactivate")}</a>
                                    </div>
                                }
                            },
                        ]}
                        rowsPerPage={10}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        noDataText=""
                        onThClick={() => { }}
                        autoSort={ true}
                    />
                </div>

            </div>
            );
    }
}



//selectChargeLocation: (location: Api.LocationModel, source: Source) => void;
//updateChargeLocationInputText: (value: string, source: Source) => void;
export default connect(
    (state: ApplicationState) => ({
        ...state.users,
        subscriptions: state.account.currentUser.clientModel
            ? state.account.currentUser.clientModel.subscriptions
            : [],
    } as any),
    UserStore.actionCreators)(ModeratorUsers) as any