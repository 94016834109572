import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders, getLocationName } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { SubmissionError } from 'redux-form';
import { Source } from './Criteria';
import { Logout } from './Account';
import Moment from 'moment';
import Download from "downloadjs";
import * as MimeTypes from "mime-types";
import { RequestState } from "../models/models";

export interface WorkDocumentsState {
    isLoading: boolean;
    requestTime: number;
    searchWorkDocumentsModel: Api.SearchWorkDocumentsModel;
    workDocumentses: Array<Api.WorkDocumentsModel>;
    uploadedWorkDocumentses: Array<Api.WorkDocumentsClientUploadDisplayModel>;
    uploadWorkdocument: Api.WorkDocumentsClientUploadModel;
    editState: EditWorkDocumentsState
    editEnabledStates: { [id: number]: EditWorkDocumentsEnabledState };
    originLocationState: LocationSelectState;
    destinationLocationState: LocationSelectState;
    downloadState: RequestState;
    uploadChargeNames: { [id: number]: Api.ChargeNameModel };
}

export interface EditWorkDocumentsState {
    workDocumentsId?: number;
    isLoading: boolean;
    requestTime: number;
}

export interface EditWorkDocumentsEnabledState {
    isLoading: boolean;
    requestTime: number;
}


interface LocationSelectState {
    requestTime: number;
    isLoading: boolean;
    inputText: string;
    locations: Array<Api.LocationModel>;
}

interface WorkDocumentsSelectTrafficType { type: "WORKDOCUMENTS_SELECT_TRAFFICTYPE"; value: Api.WorkDocumentsModelTrafficTypeEnum; }
interface WorkDocumentsSelectCarrier { type: "WORKDOCUMENTS_SELECT_CARRIER"; carrierId: number; }
interface WorkDocumentsUpdateReference { type: "WORKDOCUMENTS_UPDATE_REFERENCE"; value: string; }

interface RequestWorkDocumentses { type: "REQUEST_WORKDOCUMENTSES"; payload: { requestTime: number; } }
interface ReceiveWorkDocumentses { type: "RECEIVE_WORKDOCUMENTSES"; payload: { requestTime: number; workDocumentses: Array<Api.WorkDocumentsModel> }; error?: any }

interface SelectWorkDocuments { type: "SELECT_WORKDOCUMENTS"; workDocumentsId: number }

interface RequestEditWorkDocuments {
    type: "REQUEST_EDIT_WORKDOCUMENTS";
    payload: { requestTime: number; }
}
interface ReceiveEditWorkDocuments {
    type: "RECEIVE_EDIT_WORKDOCUMENTS";
    payload: {
        requestTime: number;
        offerInformation: Api.OfferInformationModel
    }; error?: any
}

interface RequestEditWorkDocumentsEnabed { type: "REQUEST_EDIT_WORKDOCUMENTS_ENABLED"; payload: { requestTime: number; workDocumentsId: number; value: boolean; } }
interface ReceiveEditWorkDocumentsEnabed { type: "RECEIVE_EDIT_WORKDOCUMENTS_ENABLED"; payload: { requestTime: number; workDocumentsId: number; value: boolean; }; error?: any }


interface RequestUploadedWorkdocuments { type: "REQUEST_UPLOAD_WORKDOCUMENTS"; payload: { requestTime: number; } }
interface ReceiveUploadedWorkdocuments { type: "RECEIVE_UPLOAD_WORKDOCUMENTS"; payload: { requestTime: number; workdocumentUploads: Array<Api.WorkDocumentsClientUploadDisplayModel> }; error?: any }

//interface RequestDeleteUploadedWorkdocument { type: "REQUEST_DELETE_WORKDOCUMENTS"; payload: { requestTime: number; workdocumentUploadId: number } }
//interface ReceiveDeleteWorkdocument { type: "RECEIVE_DELETE_WORKDOCUMENTS"; payload: { requestTime: number; workdocumentUploadId: number }; error?: any }

interface RequestCreateWorkdocument { type: "REQUEST_CREATE_WORKDOCUMENTS"; payload: { requestTime: number; } }
interface ReceiveCreateWorkdocument { type: "RECEIVE_CREATE_WORKDOCUMENTS"; payload: { requestTime: number; }; error?: any }

interface RequestWorkdocumentTemplate { type: "REQUEST_WORKDOCUMENTS_TEMPLATE"; requestTime: number }
interface ReceiveWorkdocumentTemplate { type: "RECEIVE_WORKDOCUMENTS_TEMPLATE"; requestTime: number, workdocumentUpload: Api.WorkDocumentsClientUploadModel }


interface RequestEditWorkDocumentsUploadEnabed { type: "REQUEST_EDIT_UPLOAD_WORKDOCUMENTS_ENABLED"; payload: { requestTime: number; workdocumentId: number; value: boolean; } }
interface ReceiveEditWorkDocumentUploadsEnabed { type: "RECEIVE_EDIT_UPLOAD_WORKDOCUMENTS_ENABLED"; payload: { requestTime: number; workdocumentId: number; value: boolean; }; error?: any }

interface RequestSeedChargeNames {
    type: 'REQUEST_SEED_CHARGENAMES'
    payload: { requestTime: number; }
}
interface ReceiveSeedChargeNames {
    type: 'RECEIVE_SEED_CHARGENAMES'
    payload: {
        requestTime: number;
        uploadChargeNames?: { [id: number]: Api.ChargeNameModel };
    };
    error?: any;
}


interface WorkDocumentsSelectLocation { type: "WORKDOCUMENTS_SELECT_LOCATION"; location: Api.LocationModel; source: Source }

type KnownAction = WorkDocumentsSelectCarrier | WorkDocumentsUpdateReference
    | RequestWorkDocumentses | ReceiveWorkDocumentses | SelectWorkDocuments
    | RequestEditWorkDocuments | ReceiveEditWorkDocuments
    | WorkDocumentsSelectLocation | WorkDocumentsSelectTrafficType
    | RequestEditWorkDocumentsEnabed | ReceiveEditWorkDocumentsEnabed
    | RequestCreateWorkdocument | ReceiveCreateWorkdocument | RequestUploadedWorkdocuments | ReceiveUploadedWorkdocuments | RequestEditWorkDocumentsUploadEnabed | ReceiveEditWorkDocumentUploadsEnabed
    /*RequestDeleteUploadedWorkdocument | ReceiveDeleteWorkdocument | RequestCreateWorkdocument | ReceiveCreateWorkdocument*/
    | RequestWorkdocumentTemplate | ReceiveWorkdocumentTemplate | RequestSeedChargeNames | ReceiveSeedChargeNames
    | Logout;


export const actionCreators = {
    requestWorkDocumentses: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().ratesOffer.requestTime)
            return;

        let api = new Api.ModeratorApi();
        var fetchTask = api.searchWorkDocuments({
            model: getState().workDocuments.searchWorkDocumentsModel
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(workDocuments => {
                dispatch({ type: "RECEIVE_WORKDOCUMENTSES", payload: { requestTime: requestTime, workDocumentses: workDocuments } });
            }).catch(error => {
                console.log('Error: ' + error.message);
                dispatch(Notifications.error({ message: "Error searching documents", title: "Error", position: "tc" }) as any);
                dispatch({ type: "RECEIVE_WORKDOCUMENTSES", payload: { requestTime: requestTime, workDocumentses: [] }, error: error });
            });
        addTask(fetchTask);
        dispatch({ type: "REQUEST_WORKDOCUMENTSES", payload: { requestTime: requestTime } });
    },
    requestEditDocumentInfo: (requestTime: number, offerInformation: Api.OfferInformationModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().workDocuments.editState.requestTime)
            return Promise.resolve();

        offerInformation.workDocumentsId = getState().workDocuments.editState.workDocumentsId;
        let api = new Api.ModeratorApi();
        let fetchTask = api.editDocumentInfo({ model: offerInformation },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(documentInfoResult => {
                dispatch({ type: "RECEIVE_EDIT_WORKDOCUMENTS", payload: { requestTime: requestTime, offerInformation: documentInfoResult } });
                if (documentInfoResult)
                    dispatch(Notifications.success({ message: "Your document information have been updated", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_EDIT_WORKDOCUMENTS", payload: { requestTime: requestTime, offerInformation: null }, error: error });
                throw new SubmissionError({ _error: "Failed to edit" } as any)
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_EDIT_WORKDOCUMENTS", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    requestDeleteDocumentInfo: (requestTime: number, offerInformation: Api.OfferInformationModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().workDocuments.editState.requestTime)
            return Promise.resolve();

        offerInformation.workDocumentsId = getState().workDocuments.editState.workDocumentsId;
        let api = new Api.ModeratorApi();
        let fetchTask = api.deleteDocumentInfo({ model: offerInformation },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(documentInfoResult => {
                dispatch({ type: "RECEIVE_EDIT_WORKDOCUMENTS", payload: { requestTime: requestTime, offerInformation: documentInfoResult } });
                if (documentInfoResult)
                    dispatch(Notifications.success({ message: "Your informations has been deleted", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_EDIT_WORKDOCUMENTS", payload: { requestTime: requestTime, offerInformation: null }, error: error });
                throw new SubmissionError({ _error: "Failed to delete" } as any)
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_EDIT_WORKDOCUMENTS", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    requestEditWorkDocumentsEnabled: (requestTime: number, workDocumentsId: number, value: boolean): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().workDocuments.editEnabledStates[workDocumentsId] &&
            requestTime === getState().workDocuments.editEnabledStates[workDocumentsId].requestTime)
            return Promise.resolve();

        let api = new Api.ModeratorApi();
        let fetchTask = api.editWorkDocumentsEnabled({ workDocumentsId: workDocumentsId, value: value },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(ratesOfferResult => {
                dispatch({ type: "RECEIVE_EDIT_WORKDOCUMENTS_ENABLED", payload: { requestTime: requestTime, workDocumentsId: workDocumentsId, value: value } });
                //dispatch(Notifications.success({ message: "Your document information have been updated", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_EDIT_WORKDOCUMENTS_ENABLED", payload: { requestTime: requestTime, workDocumentsId: workDocumentsId, value: !value }, error: error });
                dispatch(Notifications.error({ message: "Failed to edit document state", title: "Error", position: "tc" }) as any);
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_EDIT_WORKDOCUMENTS_ENABLED", payload: { requestTime: requestTime, workDocumentsId: workDocumentsId, value: value } });
        return fetchTask;
    },
    updateReference: (value: string) => <WorkDocumentsUpdateReference>{ type: "WORKDOCUMENTS_UPDATE_REFERENCE", value: value },
    selectCarrierId: (carrierId: number) => <WorkDocumentsSelectCarrier>{ type: "WORKDOCUMENTS_SELECT_CARRIER", carrierId: carrierId },
    selectTrafficType: (value: Api.WorkDocumentsModelTrafficTypeEnum) => <WorkDocumentsSelectTrafficType>{ type: "WORKDOCUMENTS_SELECT_TRAFFICTYPE", value: value },
    selectWorkDocuments: (workDocumentsId: number) => <SelectWorkDocuments>{ type: "SELECT_WORKDOCUMENTS", workDocumentsId: workDocumentsId },
    selectLocation: (location: Api.LocationModel, source: Source) => <WorkDocumentsSelectLocation>{ type: "WORKDOCUMENTS_SELECT_LOCATION", location: location, source: source },

    requestUploadWorkDocumentses: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().ratesOffer.requestTime)
            return;

        let api = new Api.WorkDocumentsUploadApi();
        var fetchTask = api.searchWorkDocuments({
            model: getState().workDocuments.searchWorkDocumentsModel
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(uploadedWorkdocuments => {
                dispatch({ type: "RECEIVE_UPLOAD_WORKDOCUMENTS", payload: { requestTime: requestTime, workdocumentUploads: uploadedWorkdocuments } });
            }).catch(error => {

                dispatch(Notifications.error({ message: "Error searching documents", title: "Error", position: "tc" }) as any);
                dispatch({ type: "RECEIVE_UPLOAD_WORKDOCUMENTS", payload: { requestTime: requestTime, workdocumentUploads: [] }, error: error });
            });
        addTask(fetchTask);
        dispatch({ type: "REQUEST_UPLOAD_WORKDOCUMENTS", payload: { requestTime: requestTime } });
    },


    requestWorkdocumentTemplate: (requestTime: number, document: Api.WorkDocumentsClientUploadModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {

        var state = getState();
        var firms =  state.account.currentUser.clientModel.subscriptions.filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency.company)
            .concat(state.account.currentUser.clientModel.subscriptions.filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company.companyGroup))
            .concat(state.account.currentUser.clientModel.agencies)
        .filter(x => x);

        let filename = '';

        let api = new Api.WorkDocumentsUploadApi();
        let fetchTask = api.downloadTemplate(
            {
                model: document

            },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => {
                const header = response.headers.get('Content-Disposition');
                const parts = header!.split(';');
                filename = parts[1].split('=')[1];
                return response.blob();
            } )
            .then(blob => {
                dispatch({ type: "RECEIVE_WORKDOCUMENTS_TEMPLATE", requestTime: requestTime, workdocumentUpload: document });
                console.log(filename);
                console.log(MimeTypes.lookup(filename));
                return Download(blob,
                    filename,
                    MimeTypes.lookup(filename) || "text/plain");
            })
            .catch(error => {
                dispatch({ type: "REQUEST_WORKDOCUMENTS_TEMPLATE", requestTime: requestTime });
                dispatch(Notifications.error({ message: "Error downloading preview", title: "Error", position: "tc" }) as any);
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_WORKDOCUMENTS_TEMPLATE", requestTime: requestTime });
    },

    requestUploadWorkdocument: (requestTime: number, documents: Api.WorkDocumentsClientUploadFilesModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {


        if (requestTime === getState().firmDocument.editState.requestTime)
            return Promise.resolve();


        let sourceFile = (documents as any).sourceFile;

        let file = (documents as any).file;
        let formData = new FormData();
        formData.append(file.name, file);
        formData.append(sourceFile.name, sourceFile);

        for (let key of Object.keys(documents)) {
            formData.append(key, documents[key]);
        }
        let fetchTask = (fetch("/api/WorkdocumentsUpload/UploadWorkdocument", {
            method: "POST",
            body: formData,
            credentials: "same-origin",
            headers: getDefaultHeaders(getState())
        }).then(firmDocumentResult => {
                dispatch({ type: "RECEIVE_CREATE_WORKDOCUMENTS", payload: { requestTime: requestTime } });
                dispatch(Notifications.success({ message: "Your document is processing. Check email for results!", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_CREATE_WORKDOCUMENTS", payload: { requestTime: requestTime, }, error: error });
                dispatch(Notifications.error({ message: "Failed to create document. Contact our support.", title: "Error", position: "tc" }) as any);
                throw new SubmissionError({ _error: "Failed to create" } as any)
            }) as any) as Promise<any>;

        addTask(fetchTask);
        dispatch({ type: "REQUEST_CREATE_WORKDOCUMENTS", payload: { requestTime: requestTime } });
        return fetchTask;

        return null;
    },
    requestDeactivateUploadedWorkdocument: (requestTime: number, id: number, value: boolean): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().firmDocument.deleteStates[id]
            && requestTime === getState().firmDocument.deleteStates[id].requestTime)
            return Promise.resolve();
        let api = new Api.ModeratorApi();
        let fetchTask = api.editWorkDocumentsEnabled({ workDocumentsId: id, value: value },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(resp => {
                dispatch({ type: "RECEIVE_EDIT_UPLOAD_WORKDOCUMENTS_ENABLED", payload: { requestTime: requestTime, workdocumentId: id, value: !value } });
            }).catch(error => {
                dispatch({ type: "RECEIVE_EDIT_UPLOAD_WORKDOCUMENTS_ENABLED", payload: { requestTime: requestTime, workdocumentId: id, value: value }, error: error });
                dispatch(Notifications.success({ message: "Failed to delete document", title: "Success", position: "tc" }) as any);
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_EDIT_UPLOAD_WORKDOCUMENTS_ENABLED", payload: { requestTime: requestTime, workdocumentId: id, value: value } });
        return fetchTask;
    },
    requestSeedChargeNames : (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let api = new Api.SeedApi();
        let fetchTask = api.getChargeNames().then(charges => {

            dispatch({
                type: "RECEIVE_SEED_CHARGENAMES",
                payload: {
                    requestTime: requestTime,
                    uploadChargeNames: charges
                }
            });
        }).catch(error => {
            dispatch({
                type: "RECEIVE_SEED_CHARGENAMES",
                payload: {
                    requestTime: requestTime,
                },
                error: error
            });
            console.log('Error fetching seed: ' + error.message);
        });

        dispatch({ type: "REQUEST_SEED_CHARGENAMES", payload: { requestTime: requestTime } });

        return fetchTask;


    }

}

const unloadedState: WorkDocumentsState = {
    isLoading: false,
    requestTime: 0,
    workDocumentses: [],
    uploadedWorkDocumentses: [],
    uploadWorkdocument: {
    },
    downloadState: {
        isLoading: false,
        requestTime: 0
    },
    searchWorkDocumentsModel: {

    },
    uploadChargeNames:[],
    editState: {
        isLoading: false,
        workDocumentsId: null,
        requestTime: 0
    },
    editEnabledStates: {},
    originLocationState: {
        inputText: "",
        isLoading: false,
        requestTime: 0,
        locations: []
    },
    destinationLocationState: {
        inputText: "",
        isLoading: false,
        requestTime: 0,
        locations: []
    }
};


export const reducer: Reducer<WorkDocumentsState> = (state: WorkDocumentsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_WORKDOCUMENTSES":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime,
                editState: {
                    ...state.editState,
                    //Don't wanna risk a null reference
                    workDocumentsId: null
                }
            };
        case "RECEIVE_WORKDOCUMENTSES":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                workDocumentses: action.payload.workDocumentses
            };
        case "REQUEST_CREATE_WORKDOCUMENTS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime,
            };
        case "RECEIVE_CREATE_WORKDOCUMENTS":
            if (action.payload.requestTime !== state.requestTime)
                return state;
            return {
                ...state,
                isLoading: false,

            };
        case "REQUEST_UPLOAD_WORKDOCUMENTS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime,
                editState: {
                    ...state.editState,
                    //Don't wanna risk a null reference
                    workDocumentsId: null
                }
            };
        case "RECEIVE_UPLOAD_WORKDOCUMENTS":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                uploadedWorkDocumentses: action.payload.workdocumentUploads
            };
        case "REQUEST_EDIT_UPLOAD_WORKDOCUMENTS_ENABLED":
            return {
                ...state,
                editEnabledStates: {
                    ...state.editEnabledStates,
                    [action.payload.workdocumentId]: {
                        ...state.editEnabledStates[action.payload.workdocumentId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_EDIT_UPLOAD_WORKDOCUMENTS_ENABLED":
            if (!state.editEnabledStates[action.payload.workdocumentId] ||
                state.editEnabledStates[action.payload.workdocumentId].requestTime !== action.payload.requestTime)   //in result we invalidate documents
                return state;

            return {
                ...state,
                uploadedWorkDocumentses: action.error
                    ? state.uploadedWorkDocumentses
                    : state.uploadedWorkDocumentses
                        .map(wd => wd.workdocumentId === action.payload.workdocumentId ? { ...wd, invalidated: action.payload.value } : wd),
                editEnabledStates: {
                    ...state.editEnabledStates,
                    [action.payload.workdocumentId]: {
                        ...state.editEnabledStates[action.payload.workdocumentId],
                        isLoading: false
                    }
                }
            };
        case "REQUEST_WORKDOCUMENTS_TEMPLATE":
            return {
                ...state,
                downloadState: {
                    ...state.downloadState,
                    requestTime: action.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_WORKDOCUMENTS_TEMPLATE":
            if (action.requestTime !== state.downloadState.requestTime)
                return state;

            return {
                ...state,
                downloadState: {
                    ...state.downloadState,
                    isLoading: false
                }
            };








        case "WORKDOCUMENTS_SELECT_CARRIER":
            return {
                ...state,
                searchWorkDocumentsModel: {
                    ...state.searchWorkDocumentsModel,
                    carrierId: action.carrierId
                }
            };
        case "WORKDOCUMENTS_SELECT_TRAFFICTYPE":
            return {
                ...state,
                searchWorkDocumentsModel: {
                    ...state.searchWorkDocumentsModel,
                    trafficType: action.value
                }
            };
        case "WORKDOCUMENTS_UPDATE_REFERENCE":
            return {
                ...state,
                searchWorkDocumentsModel: {
                    ...state.searchWorkDocumentsModel,
                    reference: action.value
                }
            };
        case "SELECT_WORKDOCUMENTS":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    workDocumentsId: action.workDocumentsId
                }
            };
        case "REQUEST_EDIT_WORKDOCUMENTS":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_EDIT_WORKDOCUMENTS":
            return {
                ...state,
                workDocumentses: action.error
                    ? state.workDocumentses
                    : state.workDocumentses
                        .map(wd => action.payload.offerInformation
                            && wd.workDocumentsId == action.payload.offerInformation.workDocumentsId
                            ? {
                                ...wd,
                                documentInfos: wd.offerInformations
                                    .filter(wd => wd.source == "Admin")
                                    .concat(action.payload.offerInformation)
                            }
                            : wd),
                editState: {
                    ...state.editState,
                    isLoading: action.payload.requestTime !== state.editState.requestTime
                }
            };
        case "WORKDOCUMENTS_SELECT_LOCATION":
            if (action.source === Source.Origin) {
                return {
                    ...state,
                    searchWorkDocumentsModel: {
                        ...state.searchWorkDocumentsModel,
                        origin: action.location,
                    },
                    originLocationState: {
                        ...state.originLocationState,
                        inputText: getLocationName(action.location)
                    }
                };
            } else {
                return {
                    ...state,
                    searchWorkDocumentsModel: {
                        ...state.searchWorkDocumentsModel,
                        destination: action.location
                    },
                    destinationLocationState: {
                        ...state.destinationLocationState,
                        inputText: getLocationName(action.location)
                    }
                };
            }
        case "REQUEST_EDIT_WORKDOCUMENTS_ENABLED":
            return {
                ...state,
                editEnabledStates: {
                    ...state.editEnabledStates,
                    [action.payload.workDocumentsId]: {
                        ...state.editEnabledStates[action.payload.workDocumentsId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_EDIT_WORKDOCUMENTS_ENABLED":
            if (!state.editEnabledStates[action.payload.workDocumentsId] ||
                state.editEnabledStates[action.payload.workDocumentsId].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                workDocumentses: action.error
                    ? state.workDocumentses
                    : state.workDocumentses
                        .map(wd => wd.workDocumentsId === action.payload.workDocumentsId ? { ...wd, invalidated: action.payload.value } : wd),
                editEnabledStates: {
                    ...state.editEnabledStates,
                    [action.payload.workDocumentsId]: {
                        ...state.editEnabledStates[action.payload.workDocumentsId],
                        isLoading: false
                    }
                }
            };
        case "REQUEST_SEED_CHARGENAMES":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_SEED_CHARGENAMES":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                uploadChargeNames: action.error
                    ? state.uploadChargeNames
                    : action.payload.uploadChargeNames
            };
        case "LOGOUT":
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
