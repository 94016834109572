import * as React from 'react';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as QuotationContactStore from '../store/QuotationContact';
import * as AccountStore from '../store/Account';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import AccountEditFrom from './AccountEditForm';
import AccountEditPasswordForm from './AccountEditPasswordForm';
import AccountEditQuotationClientSettingsForm from "./AccountEditQuotationClientSettingsForm";
import QuotationClientContactDialog from "./QuotationClientContactDialog";
import { getText } from '../utils/langTexts';

type AccountSettingsProps = AccountSettingsOwnProps
    & AccountStore.AccountState
    & typeof QuotationContactStore.actionCreators
    & typeof AccountStore.actionCreators
    & RouteComponentProps<AccountSettingsParams>;

interface AccountSettingsOwnProps {
    quotationContact: QuotationContactStore.QuotationContactState;
}

interface AccountSettingsParams {

}


interface AccountSettingsState {

}

class AccountSettings extends React.Component<AccountSettingsProps, AccountSettingsState> {
    componentWillMount() {
        this.props.requestQuotationContacts(new Date().getTime());
    }

    public render() {
        return (
            <div style={{ display: "flex", marginLeft: "auto", marginRight: "auto"}}>
                <div style={{ paddingLeft: 50, display: "flex", flexDirection: "row",  flexGrow: 1 }}>
                    <div style={{flexGrow: 1 }}>
                        <h2>{getText("StgEditProfile")}</h2>
                        <div>
                            <AccountEditFrom onSubmit={(values) =>
                                this.props.requestEditClient(values as Api.ClientModel, new Date().getTime())} />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, paddingRight: 100 }}>
                        <div >
                            <h2>{getText("StgChangePassword")}</h2>
                            <AccountEditPasswordForm
                                onSubmit={(values) =>
                                    this.props.requestEditPassword(values as Api.EditPasswordModel, new Date().getTime())} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <h2>{getText("StgEditQuotationClientSettings")}</h2>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                                <div style={{ marginRight: 10 }}>Contacts:</div> <QuotationClientContactDialog />
                            </div>
                            <AccountEditQuotationClientSettingsForm
                                onSubmit={(value) => this.props.requestEditQuotationClientSettings(new Date().getTime(), value)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => {
    return {
        ...state.account,
        quotationContact: state.quotationContact
    } as any
}, {
    ...AccountStore.actionCreators,
    ...QuotationContactStore.actionCreators
})(AccountSettings) as any;