import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as SelectionStore from '../store/Selection';
import * as CriteriaStore from '../store/Criteria';
import * as MultiPortStore from '../store/MultiPort';
import * as AskBookingStore from '../store/AskBooking';
import * as AskRatesStore from '../store/AskRates';
import * as TransferStore from '../store/Transfer';
import * as ContactBoxStore from '../store/ContactBox';
import * as SellingRatesStore from '../store/SellingRates';
import * as StatsStore from '../store/Stats';
import * as CarrierRatesPlatformStore from '../store/CarrierRatesPlatform';
import * as Api from '../api/api';
import OfferTable, { canAddToMultiPort } from './OfferTable';
import { getCarrierOfferKey, getSubscription } from '../utils/utils';
import * as selectionConnect from '../connects/selectionConnect';
import * as _ from 'lodash';
import { departPerPage } from "./SelectionOffers";
import Loader from './Loader';
import FirmNewses from "./FirmNewses";
import { getText } from "../utils/langTexts";
import * as Colors from "../styles/colors";
import { getOnlineOfferErrorText } from '../utils/errorMessageHandler';
import Popper from "./Popper";
import {getRouteConfig} from "../utils/routeUtils";
import DialogModal from './DialogModal';

// At runtime, Redux will merge together...
type SelectionOffersTableProps =
    SelectionOffersTableOwnProps
    & SelectionOffersTableExternalProps
    & typeof SelectionStore.actionCreators
    & typeof CriteriaStore.actionCreators
    & typeof MultiPortStore.actionCreators
    & typeof AskBookingStore.actionCreators
    & typeof AskRatesStore.actionCreators
    & typeof TransferStore.actionCreators
    & typeof ContactBoxStore.actionCreators
    & typeof SellingRatesStore.actionCreators
    & typeof StatsStore.actionCreators
    & typeof CarrierRatesPlatformStore.actionCreators
    ;

interface SelectionOffersTableOwnProps {
    isLoading: boolean;
    carrierRatesStates: { [key: string]: { isLoading: boolean; carrierRatesPlatformId: number; errors: Array<Api.RatesFetcherErrorModel> } };
    carrierRatesVerificationDiaglog: boolean;
    isSchedulesLoading: boolean;
    errorMessages: Array<string>;
    warningMessages: Array<string>;
    filterModel: SelectionStore.SelectionFilterModel;
    selection: Api.SelectionModel;
    sellingRates: Array<Api.SellingRatesModel>;
    sellingRatesIsLoading: boolean;
    carrierOffersGrouped: Array<Array<Api.CarrierOfferModel>>;
    carrierOfferStates: { [id: string]: SelectionStore.CarrierOfferState };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
    carriers: { [id: number]: Api.CarrierModel };
    markets: { [id: number]: Api.MarketModel };
    carrierRatesPlatforms: { [id: number]: Api.CarrierRatesPlatformModel };
    client: Api.ClientModel;
    criteriaLoaded: Api.CriteriaModel;
    criteria: CriteriaStore.CriteriaState;
    filterOptions: SelectionStore.SelectionFilterOptions;
    multiPortIsLoading: boolean;
    multiPortIsActive: boolean;
    multiPortOffers: Array<Api.MultiPortOfferModel>;
    subscription: Api.SubscriptionModel;
    firmNewsesState: SelectionStore.FirmNewsesState;
    isSchedulesActivated: boolean;
}

interface SelectionOffersTableExternalProps {
    criteriaType: Api.CriteriaModelTypeEnum;
}


interface SelectionOffersTableState {
    hoverState: boolean
}



const importantMessageStyle = {
    fontSize: 16,
    fontWeight: "bold" as any,
    textAlign: "center" as any
};

class SelectionOffersTable extends React.Component<SelectionOffersTableProps, SelectionOffersTableState> {
    constructor(props) {
        super(props);
        this.state = {
            hoverState: false
        }
    }
    Mouseover() {
        this.setState({ hoverState: true })
    }

    scrollToOfferDeparts(carrierOfferId: string) {
        this.props.selectPage(Math.floor(this.props.carrierOffersGrouped
            .reduce((a, b) => a.concat(b), [])
            .findIndex(co => getCarrierOfferKey(co) === carrierOfferId) / departPerPage) + 1);
        setTimeout(() => this.props.selectCarrierOffer(carrierOfferId), 0);
    }

    addOfferToMultiPort(carrierOffer: Api.CarrierOfferModel) {
        return this.props.requestCreateMultiPortOffers(new Date().getTime(), {
            multiPortCarrierOffers: [
                {
                    chargeSetId: carrierOffer.chargeSet.chargeSetId,
                    routeConfigId: getRouteConfig(carrierOffer, this.props.carrierOfferStates[getCarrierOfferKey(carrierOffer)])?.routeConfigId,
                    ddSetId: carrierOffer.ddSet?.ddSetId
                }
            ],
            currencyId: this.props.client.currencyId,
        });
    }

    addAllOffersToMultiPort() {
        return this.props.requestCreateMultiPortOffers(new Date().getTime(), {
            multiPortCarrierOffers: this.props.carrierOffersGrouped
                .reduce((a, b) => a.concat(b), [])
                .filter(x => x.chargeSet
                    && canAddToMultiPort(x, this.props.multiPortIsLoading, this.props.multiPortOffers))
                .map(x => ({
                    routeConfigId: getRouteConfig(x, this.props.carrierOfferStates[getCarrierOfferKey(x)])?.routeConfigId,
                    chargeSetId: x.chargeSet.chargeSetId,
                    ddSetId: x.ddSet?.ddSetId
                })),
            currencyId: this.props.client.currencyId,
        });
    }

    public render() {
        return (
            <div style={{ padding: "0px 0px 60px 0px", paddingBottom: 0 }}>
                <div style={{}}>
                    {this.props.selection.firmNewses.length !== 0 &&
                        <div style={{ marginBottom: 10, minHeight: 10 }}>
                            <FirmNewses
                                isOpen={this.props.firmNewsesState.isOpen}
                                closeDialog={() => this.props.closeFirmNews()}
                                openDialog={(firmNewsId) => this.props.openFirmNews(firmNewsId)}
                                firmNewses={this.props.selection.firmNewses}
                                selectedFirmNewsId={this.props.firmNewsesState.selectedFirmNewsId}
                            />
                        </div>}
                </div>
                {this.props.errorMessages
                    && this.props.errorMessages.length !== 0
                    && <div style={{
                        ...importantMessageStyle,
                        marginBottom: 10
                    }}>{this.props.errorMessages.map((x, index) => <div key={index}>{x}</div>)}</div>}
                {this.props.subscription.subscriptionType === "Agent" &&
                    <div style={{
                        ...importantMessageStyle,
                        marginBottom: 10
                    }}>{getText("SlcAgentRatesDisclaimer")}</div>}
                {(_.values(this.props.carrierRatesStates).length !== 0
                    || this.props.isSchedulesLoading)
                    && <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginBottom: 5 }}>
                        <div style={{ flex: 1, position: "relative" }}>
                            <Loader hideBackground isVisible={this.props.isSchedulesLoading} size={20} message={"Loading schedules..."} />
                        </div>
                        <div style={{}}>
                            {_.values(this.props.carrierRatesStates).some(x => x.isLoading)
                                && <div style={{}}>
                                    <Loader hideBackground isVisible={true} size={20}
                                        message={getText("SlcCarrierRatesLoading", { ocqList: _.values(this.props.carrierRatesStates).filter(x => x.isLoading).map(x => this.props.carrierRatesPlatforms[x.carrierRatesPlatformId].name).join(", ") })} />
                                </div>}
                            {!_.values(this.props.carrierRatesStates).some(x => x.isLoading)
                                && _.values(this.props.carrierRatesStates).some(x => x.errors.filter(e => e.type != "EmptyResults").length !== 0 )
                                && <div style={{

                                    color: Colors.baseColors.lightBlue,
                                    fontSize: 14
                                }}>
                                    <div onMouseOver={(e) => { this.Mouseover() }} className={!this.state.hoverState ? "blink" : ""}>{getText("SlcCarrierQuotesErrors1")}
                                        {" "}<Popper style={{ display: "inline-block" }}
                                            popperContent={<div style={{}}>
                                                {  _.values(this.props.carrierRatesStates)
                                                    .filter(x => x.errors.length !== 0 && x.errors.some(e => e.type != "EmptyResults") )
                                                    .map(x => <div>
                                                        { _.uniqBy(  x.errors.filter(e => e.type != "EmptyResults"),x=>x.message ).map((error, index) => <div key={index} style={{ marginBottom: 3 }}>{getOnlineOfferErrorText(error,
                                                            {
                                                                carrier: this.props.carrierRatesPlatforms[x.carrierRatesPlatformId].name,
                                                                carrierUrl: this.props.carrierRatesPlatforms[x.carrierRatesPlatformId].onlineQuoteLink,
                                                                ocqPlatformName: this.props.carrierRatesPlatforms[x.carrierRatesPlatformId].name
                                                            })}</div>)}
                                                        {/*<div style={{ textAlign: "center" }}>*/}
                                                        {/*    <span onClick={(e) => this.props.openCarrierRatesPlatformStatus()}*/}
                                                        {/*        style={{*/}
                                                        {/*            textDecoration: "underline",*/}
                                                        {/*            cursor: "pointer",*/}
                                                        {/*            color: Colors.baseColors.lightBlue*/}
                                                        {/*        }}>{getText("SlcCarrierQuotesViewStatus")}</span>*/}
                                                        {/*</div>*/}
                                                    </div>)}
                                            </div>}
                                            childContainerStyle={{ textDecoration: "underline" }}>{getText("SlcCarrierQuotesErrors2")}</Popper>
                                        {" "}{getText("SlcCarrierQuotesErrors3")}
                                    </div>
                                </div>}
                            {!_.values(this.props.carrierRatesStates).some(x => x.isLoading)
                                && !_.values(this.props.carrierRatesStates).some(x => x.errors.filter(e => e.type != "EmptyResults").length !== 0)
                                && <div style={{
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: Colors.baseColors.lightBlue,
                                    fontSize: 14
                                }}>
                                    <div>{getText("SlcCarrierQuotesSuccess")}</div>
                                </div>}
                        </div>
                        <div style={{ flex: 1 }}></div>
                    </div>}
                <div style={{ marginBottom: 15 }}>
                    <OfferTable
                        subscriptions={this.props.client.subscriptions}
                        carrierOffersGrouped={this.props.carrierOffersGrouped}
                        carrierOfferStates={this.props.carrierOfferStates}
                        byRatesOffer={this.props.filterModel.byRatesOffer}
                        subscriptionType={this.props.subscription.subscriptionType}
                        fitlerSortBy={this.props.filterModel.sortBy}
                        carriers={this.props.carriers}
                        markets={this.props.markets}
                        isLoading={this.props.isLoading || this.props.sellingRatesIsLoading}
                        sellingRates={this.props.sellingRates}
                        filterOptions={this.props.filterOptions}
                        criteriaModel={this.props.criteria.criteria}
                        criteriaLoaded={this.props.criteriaLoaded}
                        currencies={this.props.currencies}
                        sizeTypes={this.props.sizeTypes}
                        currencyId={this.props.client.currencyId}
                        carrierRatesPlatforms={this.props.carrierRatesPlatforms}
                        carrierRatings={this.props.selection.carrierRatings}
                        handleOfferClick={(id: string) => this.scrollToOfferDeparts(id)}
                        carrierOffersSelected={this.props.filterModel.carrierOffersSelected}
                        selectCarrierOffer={this.props.filterSelectCarrierOffer}
                        selectAllCarrierOffer={this.props.filterSelectAllCarrierOffer}
                        multiPortIsLoading={this.props.multiPortIsLoading}
                        multiPortIsActive={this.props.multiPortIsActive}
                        multiPortOffers={this.props.multiPortOffers}
                        requestAddOfferToMultiPort={(carrierOffer) => this.addOfferToMultiPort(carrierOffer)}
                        requestAddAllOffersToMultiPort={() => this.addAllOffersToMultiPort()}
                        selectSortBy={(value) => this.props.filterSelectFilterSort(value)}
                        openGroup={(id) => this.props.openCarrierOfferGroup(id)}
                        closeGroup={(id) => this.props.closeCarrierOfferGroup(id)}
                        selectRouteService={(key, routeServiceId) => this.props.selectCarrierOfferService(key, routeServiceId)}>
                    </OfferTable>
                </div>
                <div style={{}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        <div style={{
                            flex: "0 0 auto",
                            color: Colors.baseColors.lightBlue,
                            textDecoration: "underline",
                            cursor: "pointer"
                        }} onClick={(e) => this.props.openStatsPriceDialog()}>
                            {getText("FakStatistics")}
                        </div>
                        <div style={{ flex: 1 }}>

                        </div>
                        {this.props.isSchedulesActivated
                            && this.props.criteriaType === "Fcl"
                            && <div><a href={"https://www.bigschedules.com/routes"} target={"_blank"} style={{
                                flex: "0 0 auto",
                                display: "flex",
                                marginRight: 55,
                                flexDirection: "row",
                                alignItems: "center",
                                cursor: "pointer",
                                color: Colors.baseColors.lightBlue,
                                textDecoration: "underline"
                            }}>
                                <div style={{ marginRight: 15 }}>Powered by BigSchedules</div>
                            </a></div>}
                    </div>
                    {this.props.warningMessages
                        && this.props.warningMessages.length !== 0
                        && <div
                            style={{
                                ...importantMessageStyle,
                                marginBottom: 10,
                                marginTop: 10
                            }}>{this.props.warningMessages.map((x, index) => <div key={index}>{getText("SlcTestWarning")}</div>)}</div>}
                </div>
            </div>
        );
    }
}

//So its the same ref
const emptyArray = [];
//Filtering is done here, we'll use reselect for optimization later on, for now we don't care
export default connect(
    (state: ApplicationState) => {
        let carrierGroupedResult = selectionConnect.getGroupedCarrierOffers(state);
        let filterOptions = selectionConnect.getFilterOptions(state)
        let subscription = getSubscription(state.account.currentUser.clientModel)
            || { subscriptionType: null };

        return {
            isLoading: state.selection.isLoading,
            isCarrierRatesLoading: state.selection.isCarrierRatesLoading,
            isSchedulesLoading: state.selection.isSchedulesLoading,
            filterModel: state.selection.filterModel,
            selection: state.selection.selection,
            carrierOffersGrouped: carrierGroupedResult,
            carrierOfferStates: state.selection.carrierOfferStates,
            firmNewsesState: state.selection.firmNewsesState,
            criteria: state.criteria,
            criteriaLoaded: state.selection.criteriaLoaded,
            sizeTypes: state.seed.sizeTypes,
            currencies: state.seed.currencies,
            markets: state.seed.markets,
            carriers: state.seed.carriers,
            carrierGroups: state.seed.carrierGroups,
            client: state.account.currentUser.clientModel,
            filterOptions: filterOptions,
            multiPortIsLoading: state.multiPort.isLoading || state.multiPort.addOfferState.isLoading,
            multiPortIsActive: (state.multiPort.isVisible && state.multiPort.multiPort) ? true : false,
            multiPortOffers: state.multiPort.multiPort ? state.multiPort.multiPort.multiPortOffers : emptyArray,
            subscription: subscription,
            errorMessages: state.selection.errorMessages,
            warningMessages: state.selection.warningMessages,
            sellingRatesIsLoading: state.sellingRates.selectionSellingRatesState.isLoading,
            sellingRates: state.sellingRates.selectionSellingRates,
            isSchedulesActivated: state.seed.servicesOptions.schedulesApiEnabled,
            carrierRatesStates: state.selection.carrierRatesStates,
            carrierRatesPlatforms: state.seed.carrierRatesPlatforms
        } as any;
    },
    {
        ...SelectionStore.actionCreators,
        ...CriteriaStore.actionCreators,
        ...MultiPortStore.actionCreators,
        ...TransferStore.actionCreators,
        ...ContactBoxStore.actionCreators,
        ...AskRatesStore.actionCreators,
        ...AskBookingStore.actionCreators,
        ...SellingRatesStore.actionCreators,
        ...StatsStore.actionCreators,
        ...CarrierRatesPlatformStore.actionCreators
    })
    (SelectionOffersTable) as any as React.ComponentClass<SelectionOffersTableExternalProps>;