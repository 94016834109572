import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from "redux";
import * as Api from "../api/api";
import Moment from "moment";
import * as MultiPortStore from './MultiPort';
import { AppThunkAction } from "./";
import { getDefaultHeaders } from "../utils/utils";



export interface UserState {
    isLoading: boolean;
    requestTime?: number;
    entities: Array<Api.ClientModel>;
    updateState: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    }
}




interface ReceiveUserEntitties { type: "RECEIVE_USER_ENTITIES"; payload: { requestTime: number; entities?: Array<Api.ClientModel> }; error?: any;}
interface RequestUserEntitties { type: "REQUEST_USER_ENTITIES"; payload: { requestTime: number }; }


interface RequestUserDeactivate {   type: "REQUEST_USER_DEACTIVATE";    payload: { requestTime: number; id: number; };}
interface ReceiveUserDeactivate {   type: "RECEIVE_USER_DEACTIVATE"; payload: { requestTime: number; id: number; entity?: Api.ClientModel };  error?: any;
}


export type KnownAction = ReceiveUserEntitties | RequestUserEntitties | RequestUserDeactivate | ReceiveUserDeactivate
    ;


export const actionCreators = {
    requestUsers: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.UsersApi();
        let fetch = api.getEntities({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entities => {
                dispatch({
                    type: "RECEIVE_USER_ENTITIES",
                    payload: { requestTime: requestTime, entities: entities }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_USER_ENTITIES",
                    payload: { requestTime: requestTime, },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_USER_ENTITIES",
            payload: { requestTime: requestTime }
        });
        addTask(fetch);
        return fetch;
    },
    requestDeactivateUser: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.UsersApi();
        let fetch = api.disable({ id: id }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entity => {
                dispatch({
                    type: "RECEIVE_USER_DEACTIVATE",
                    payload: { requestTime: requestTime, id: id, entity : entity }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_USER_DEACTIVATE",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_USER_DEACTIVATE",
            payload: { requestTime: requestTime, id: id }
        });
        return fetch;
    },
}

const unloadedState: UserState = {
    isLoading: false,
    entities: [],
    updateState: {},
};


export const reducer: Reducer<UserState> = (state: UserState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;


  

    switch (action.type) {
        case "REQUEST_USER_ENTITIES":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_USER_ENTITIES":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                entities: action.error
                    ? state.entities
                    : action.payload.entities
            };
        case "REQUEST_USER_DEACTIVATE":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    [action.payload.id]: {
                        ...state.updateState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_USER_DEACTIVATE":       
            if (!state.updateState[action.payload.id]
                || action.payload.requestTime !== state.updateState[action.payload.id].requestTime)
                return state;

            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    [action.payload.id]: {
                        ...state.updateState[action.payload.id],
                        isLoading: false,
                        requestTime: action.payload.requestTime
                    }
                },
                entities: action.error
                    ? state.entities
                    : state.entities.map(x => x.clientId === action.payload.entity.clientId
                        ? {
                            ...x,
                            account: {
                             ...x.account,
                             expirationDate: action.payload.entity.account.expirationDate
                            }
                        }
                        : x),
            };
            
                   

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
