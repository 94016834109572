import * as React from "react";
import * as Api from "../api/api";
import * as QuotationContactStore from "../store/QuotationContact";
import * as TabsStore from "../store/Tabs";
import * as Colors from "../styles/colors";
import DialogModal from "./DialogModal";
import Button from "./Button";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { getText } from "../utils/langTexts";
import { CreateQuotationContactForm, UpdateQuotationContactForm } from "./QuotationContactForm";
import { getQuotationContactName, getSubscription } from '../utils/utils';
import { Edit } from "@material-ui/icons";
import Table, { Column } from './Table';
import CenteredContentTabs from './CenteredContentTabs';

type QuotationClientContactDialogProps = QuotationClientContactDialogOwnProps
    & QuotationContactStore.QuotationContactState
    & typeof TabsStore.actionCreators
    & typeof QuotationContactStore.actionCreators;

interface QuotationClientContactDialogOwnProps {
    client: Api.ClientModel;
}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

type ContactColumn = Column<Api.QuotationContactModel>;

class QuotationClientContactDialog extends React.Component<QuotationClientContactDialogProps, {}> {

    get subscriptionOkargo(): Api.SubscriptionModel {
        let subscription = getSubscription(this.props.client);
        return subscription.subscriptionType === "Okargo"
            ? subscription
            : this.props.client.subscriptions.find(x => x.subscriptionType === "Okargo");
    }

    get quotationContactToUpdate(): Api.QuotationContactModel {
        return this.props.quotationContacts
            .find(x => x.quotationContactId === this.props.quotationContactIdToUpdate);
    }

    public render() {
        return (
            <div>
                <Button
                    style={{
                        backgroundColor: Colors.baseColors.lightBlue,
                        color: "white",
                        height: 30,
                        maxWidth: 20,
                        borderRadius: "10px 10px 10px 10px",
                        border: "none",
                    }}
                    type={"button"}
                    onClick={(e) => this.props.quotationContactOpenClientDialog()}>
                    <Edit style={{ fontSize: 15 }} />
                </Button>
                <DialogModal
                    isOpen={this.props.clientIsOpen}
                    contentLabel={"contacts"}
                    maxWidth={"lg"}
                    onRequestClose={() => this.props.quotationContactCloseClientDialog()}>
                    <div>
                        <div style={{ marginBottom: 20 }}>
                            <Table
                                tableKey={"qtnClientContacts"}
                                columns={[
                                    {
                                        id: "company",
                                        header: getText("QtnContCompany"),
                                        accessor: (d) => d.company,
                                    },
                                    {
                                        id: "agency",
                                        header: getText("QtnContAgency"),
                                        accessor: (d) => d.agency,
                                    },
                                    {
                                        id: "firstName",
                                        header: getText("QtnContFirstName"),
                                        accessor: (d) => d.firstName,
                                    },
                                    {
                                        id: "lastName",
                                        header: getText("QtnContLastName"),
                                        accessor: (d) => d.lastName,
                                    },
                                    {
                                        id: "clientType",
                                        header: getText("QtnContTypeClient"),
                                        accessor: (d) => d.clientType,
                                    },
                                    {
                                        id: "edit",
                                        header: <div></div>,
                                        accessor: (d) => d.quotationContactId,
                                        cell: d => <div>
                                            <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                                this.props.quotationContactUpdateSelected(d);
                                                this.props.selectTab("clientContact", "edit");
                                            }}>{getText("GenEdit")}</a>
                                            <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                                this.props.quotationSelectContactClient(this.props.quotationContacts.find(x => x.quotationContactId === d));
                                                this.props.quotationContactCloseClientDialog();
                                            }}>{getText("GenSelect")}</a>
                                            <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                                this.props.requestDeleteQuotationContact(new Date().getTime(), d);
                                            }}>{getText("GenDelete")}</a>
                                        </div>
                                    }
                                ] as Array<ContactColumn>}
                                style={{
                                }}
                                showPagination={true}
                                showPageSizeOptions={false}
                                rowsPerPage={10}
                                data={this.props.quotationContacts.filter(x => x.type === "Client")} />
                        </div>
                        <CenteredContentTabs
                            tabKey={"clientContact"}
                            data={[
                                {
                                    title: getText("GenCreate"),
                                    tabKey: "create",
                                    tabContent: <div style={{ padding: 10 }}>
                                        <h3>{getText("QtnContactCreateTitle")}</h3>
                                        <div style={{ maxWidth: 1000 }}>
                                            <CreateQuotationContactForm
                                                actionName={getText("QtnContactCreateButton")}
                                                hideReset
                                                contactType={"Client"}
                                                onSubmit={(values) => {
                                                    return this.props.requestCreateQuotationContact(new Date().getTime(), values) as any;
                                                }} />
                                        </div>
                                    </div>
                                },
                                {
                                    title: getText("GenEdit"),
                                    tabKey: "edit",
                                    tabContent: <div style={{ padding: 10 }}>
                                        <div style={{ maxWidth: 1000 }}>
                                            <h3>{getText("GenEdit")}</h3>
                                            {this.quotationContactToUpdate
                                                && <div>
                                                    <h4>{getQuotationContactName(this.quotationContactToUpdate)}</h4>
                                                    <UpdateQuotationContactForm
                                                        actionName={getText("GenSaveChanges")}
                                                        quotationContactId={this.props.quotationContactIdToUpdate}
                                                        contactType={"Client"}
                                                        onSubmit={(values) => {
                                                            return this.props.requestUpdateQuotationContact(new Date().getTime(), values) as any;
                                                        }} />
                                                </div>}
                                        </div>
                                    </div>
                                },
                            ]} />
                    </div>
                </DialogModal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.quotationContact,
    client: state.account.currentUser.clientModel
} as QuotationClientContactDialogProps), {
    ...QuotationContactStore.actionCreators,
    ...TabsStore.actionCreators
})(QuotationClientContactDialog) as any