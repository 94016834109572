import * as React from 'react';
import Moment from 'moment';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as FirmNewsStore from '../store/FirmNews';
import * as TabsStore from "../store/Tabs";
import { ApplicationState } from '../store';
import { CreateFirmNewsForm, EditFirmNewsForm } from "./FirmNewsForm";
import Loader from './Loader';
import { Option } from "./Select";
import { getFirmName } from '../utils/utils';
import * as Colors from "../styles/colors";
import { getText } from '../utils/langTexts';
import Table, { Column } from "./Table";
import CenteredContentTabs from './CenteredContentTabs';

type ModeratorFirmNewsProps = ModeratorFirmNewsOwnProps
    & FirmNewsStore.FirmNewsState
    & typeof FirmNewsStore.actionCreators
    & typeof TabsStore.actionCreators;

interface ModeratorFirmNewsOwnProps {
    portsZones: { [id: number]: Api.PortsZoneModel };
    subscriptions: Array<Api.SubscriptionModel>;
}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

const getColumns = (props: ModeratorFirmNewsProps): Array<Column<Api.FirmNewsModel>> => {
    return [
        {
            header: getText("MdtNewsTitle"),
            id: 'title',
            accessor: (d: Api.FirmNewsModel) => d.title,
        },
        {
            header: getText("MdtNewsFromDate"),
            id: 'dateBegin',
            accessor: (d: Api.FirmNewsModel) => d.dateBegin,
            cell: (d: any) => <div>
                {Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: getText("MdtNewsToDate"),
            id: 'dateEnd',
            accessor: (d: Api.FirmNewsModel) => d.dateEnd,
            cell: (d: any) => <div>
                {Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: getText("MdtNewsFromZone"),
            id: 'originZoneId',
            accessor: (d: Api.FirmNewsModel) => d.originZoneId,
            cell: (d: any) => <div>
                {d ? props.portsZones[d].name : ""}
            </div>
        },
        {
            header: getText("MdtNewsToZone"),
            id: 'destinationZoneId',
            accessor: (d: Api.FirmNewsModel) => d.destinationZoneId,
            cell: (d: any) => <div>
                {d ? props.portsZones[d].name : ""}
            </div>
        },
        {
            header: getText("MdtNewsFor"),
            id: 'firm',
            accessor: (d: Api.FirmNewsModel) => d.firm,
            cell: (d: any) => {
                let firm = d as Api.FirmModel;
                return <div>
                    {getFirmName(firm)}
                </div>
            }
        },
        {
            header: getText("MdtNewsLevel"),
            id: 'firm',
            accessor: (d: Api.FirmNewsModel) => d.level,
            cell: (d: any) => {
                let level = d as Api.FirmNewsModelLevelEnum;
                return <div>
                    {level === "Low" && "Not important"}
                    {level === "Normal" && "Normal"}
                    {level === "Important" && "Important"}
                </div>
            }
        },
        {
            id: "edit",
            header: <div></div>,
            accessor: (d: Api.FirmNewsModel) => d.firmNewsId,
            cell: d => <div>
                <a style={{
                    cursor: "pointer",
                    margin: "0px 5px",
                    ...(props.editState.firmNewsId === d ? { color: Colors.baseColors.lightBlue } : {})
                }} onClick={(e) => {
                    props.selectFirmNews(d);
                    props.selectTab("moderatorFirmNews", "edit");
                }}>{getText("GenEdit")}</a>
                <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                    props.requestDeleteFirmNews(new Date().getTime(), d);
                }}>{getText("GenDelete")}</a>
            </div>
        }
    ];
}

const formContainerStyle = { maxWidth: 500, marginTop: 20 };
const trStyle = { cursor: "pointer" as any }
const selectedTrStyle = { ...trStyle, backgroundColor: Colors.colors.main7.backgroundColor }

class ModeratorFirmNews extends React.Component<ModeratorFirmNewsProps, {}> {

    onPropsUpdate(props: ModeratorFirmNewsProps) {
        if (new Date().getTime() - this.props.requestTime > 1000 * 60 * 10) {
            this.props.requestFirmNewses(new Date().getTime());
        }
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: ModeratorFirmNewsProps) {
        this.onPropsUpdate(nextProps);
    }

    get portsZonesOptions(): Array<Option> {
        return [{ label: "------", value: null }].concat(_.sortBy(_.values(this.props.portsZones), x => x.name)
            .map(pz => ({ label: pz.name, value: pz.portsZoneId })));
    }

    public render() {
        return (
            <div>
                <h2>{getText("MdtNews")}</h2>
                <div className='okargo-table'>
                    <Table
                        tableKey={"modFirmNews"}
                        data={this.props.firmNewses}
                        columns={getColumns(this.props)}
                        rowsPerPage={10}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPagination={true}
                        showPageSizeOptions={false}
                        noDataText={getText("MdtNoRatesInformation")}
                        onBodyTrClick={(firmNews: Api.FirmNewsModel) => {
                            if (firmNews) {
                                this.props.selectFirmNews(firmNews.firmNewsId);
                            }
                        }}
                    />
                </div>
                <div>
                    <CenteredContentTabs
                        tabKey={"moderatorFirmNews"}
                        data={[
                            {
                                title: getText("GenCreate"),
                                tabKey: "create",
                                tabContent: <div style={{ padding: 10 }}>
                                    <h3>{getText("GenCreateNews")}</h3>
                                    <div style={{ maxWidth: 1000 }}>
                                        <CreateFirmNewsForm
                                            actionName={getText("GenCreate")}
                                            hideReset
                                            onSubmit={(values) => {
                                                return this.props.requestCreateFirmNews(new Date().getTime(), values) as any;
                                            }} />
                                    </div>
                                </div>
                            },
                            {
                                title: getText("GenEdit"),
                                tabKey: "edit",
                                tabContent: <div style={{ padding: 10 }}>
                                    <div style={{ maxWidth: 1000 }}>
                                        <h3>Edit selected news</h3>
                                        {this.props.editState.firmNewsId
                                            && <div>
                                                <EditFirmNewsForm
                                                    actionName={getText("GenSaveChanges")}
                                                    onSubmit={(values) => {
                                                        return this.props.requestEditFirmNews(new Date().getTime(), values) as any;
                                                    }} />
                                            </div>}
                                    </div>
                                </div>
                            }
                        ]}
                    ></CenteredContentTabs>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.firmNews,
        portsZones: state.seed.portsZones,
        subscriptions: state.account.currentUser.clientModel.subscriptions
    } as any),
    {
        ...FirmNewsStore.actionCreators,
        ...TabsStore.actionCreators
    })(ModeratorFirmNews) as any