import * as React from 'react';
import * as Api from '../api/api';
import { getText } from '../utils/langTexts';
import Linkify from "react-linkify";
import { getFirmName } from '../utils/utils';
import * as Colors from "../styles/colors";
import { Warning } from '@material-ui/icons';
import Moment from 'moment';

interface OfferRatesOfferProps {
    ratesOffer: Api.RatesOfferModel;
    chargeSet: Api.ChargeSetModel;
    offerInformations: Array<Api.OfferInformationModel>;
    subscriptions: Array<Api.SubscriptionModel>;
    markets: { [id: number]: Api.MarketModel };
}

const fieldStyle: React.CSSProperties = { marginBottom: 10, marginTop: 10 };
const containerStyle: React.CSSProperties = {};
const innerStyle: React.CSSProperties = {};
const labelStyle: React.CSSProperties = { marginRight: 3 };

export default class OfferRatesOffer extends React.Component<OfferRatesOfferProps, {}> {
    constructor(props) {
        super(props);
    }

    get CompanyOwner(): Api.FirmModel {
        if (!this.props.chargeSet.firmId)
            return null;

        let subAgency = this.props.subscriptions
            .find(x => x.subscriptionType === "Okargo"
                && x.agencyId === this.props.chargeSet.firmId);
        if (subAgency)
            return subAgency.agency;

        let subCompany = this.props.subscriptions
            .find(x => x.subscriptionType === "Okargo"
                && x.agency.companyId === this.props.chargeSet.firmId)
        if (subCompany)
            return subCompany.agency.company;

        let subCompanyGroup = this.props.subscriptions
            .find(x => x.subscriptionType === "Okargo"
                && x.agency.company.companyGroupId === this.props.chargeSet.firmId)
        if (subCompanyGroup)
            return subCompanyGroup.agency.company.companyGroup;

        let agentFirm = this.props.subscriptions
            .find(x => x.subscriptionType === "Agent"
                && x.firmId === this.props.chargeSet.firmId);
        return agentFirm
            ? agentFirm.firm
            : null;
    }

    public render() {
        return <div style={containerStyle}>
            <div style={innerStyle}>
                {this.props.ratesOffer
                    && this.props.ratesOffer.shortName
                    && <div style={fieldStyle}>
                        <label style={labelStyle}>{getText("SlcOfferRatesShortName")}</label>
                        <span>{this.props.ratesOffer.shortName}</span>
                    </div>}
                <div style={fieldStyle}>
                    <label style={labelStyle}>{getText("SlcOfferRatesReference")}</label>
                    <span>{this.props.chargeSet.carrierReference
                        || (this.props.ratesOffer && this.props.ratesOffer.references.map(r => r.value).join(','))
                        || getText("GenNone")}</span>
                </div>
                {!this.props.chargeSet.ratesFetchedRecordId
                 && <div style={fieldStyle}>

                    <label style={labelStyle}>{getText("SlcOfferRatesUpload")}</label>
                    <span>{ Moment(this.props.chargeSet.creationDate).format("DD/MM/YYYY")}</span>
                </div>}
                {this.props.ratesOffer?.commodity && <div style={fieldStyle}>
                        <label style={labelStyle}>{getText("SlcOfferRatesCommodity")}</label>
                        <span>{this.props.ratesOffer?.commodity}</span>
                    </div>}
                {this.props.ratesOffer?.nac
                    && <div style={fieldStyle}>
                        <label style={labelStyle}>{getText("SlcOfferRatesNac")}</label>
                        <span>{this.props.ratesOffer.nac}</span>
                    </div>}
                {this.props.chargeSet.source
                    && <div style={fieldStyle}>
                        <label style={labelStyle}>{getText("SlcOfferRatesOcqSource")}</label>
                        <span>{this.props.chargeSet.source}</span>
                    </div>}
                {this.props.ratesOffer
                    && this.props.ratesOffer.owner
                    && <div style={fieldStyle}>
                        <label style={labelStyle}>Owner:</label>
                        <span>{this.props.ratesOffer.owner}</span>
                    </div>}
                {this.props.chargeSet.marketId
                    && <div style={fieldStyle}>
                        <label style={labelStyle}>{getText("SlcOfferRatesMarket")}</label>
                        <span>{this.props.markets[this.props.chargeSet.marketId].name}</span>
                    </div>}
                <div style={fieldStyle}>
                    <label style={labelStyle}>{getText("SlcOfferRatesCompany")}</label>
                    <span>{(this.CompanyOwner
                        ? getFirmName(this.CompanyOwner)
                        : getText("GenNone"))}</span>
                </div>
                {this.props.ratesOffer
                    && this.props.ratesOffer.description
                    && !/^\s*$/.test(this.props.ratesOffer.description)
                    && <div style={fieldStyle}>
                        <div>
                            {this.props.ratesOffer && this.props.ratesOffer.level === "High" &&
                                <Warning style={{ marginRight: 3, fontSize: 12, color: Colors.baseColors.lightBlue }} />
                            }
                            <label style={labelStyle}>{getText("SlcOfferRatesDescription")}</label>
                        </div>
                        <div style={{ whiteSpace: "pre-wrap" }}>
                            {this.props.ratesOffer && this.props.ratesOffer.description
                                ? <Linkify properties={{ target: "_blank" }}>{this.props.ratesOffer.description}</Linkify>
                                : <i>not defined</i>}
                        </div>
                    </div>}
                {this.props.offerInformations
                    .filter(x => x.chargeSetId)
                    .map(x =>
                        <div style={fieldStyle} key={x.offerInformationId}>
                            <div>
                                <label style={labelStyle}>
                                    {x.level === "High" && <Warning style={{ marginRight: 3, fontSize: 12, color: Colors.baseColors.lightBlue }} />}
                                    {getText("SlcOfferRatesSpecificInfoTitle")}
                                </label>
                            </div>
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                <Linkify properties={{ target: "_blank" }}>{x.content}</Linkify>
                            </div>
                        </div>)}
                {this.props.offerInformations
                    .filter(x => x.workDocumentsId)
                    .map(x =>
                        <div style={fieldStyle} key={x.offerInformationId}>
                            <div>
                                <label style={labelStyle}>
                                    {x.level === "High" && <Warning style={{ marginRight: 3, fontSize: 12, color: Colors.baseColors.lightBlue }} />}
                                    {getText("SlcOfferRatesInfoTitle")}
                                </label>
                            </div>
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                <Linkify properties={{ target: "_blank" }}>{x.content}</Linkify>
                            </div>
                        </div>)}
                {this.props.offerInformations
                    //General info like carrier rates info
                    .filter(x => !x.chargeSetId && !x.workDocumentsId)
                    .map(x =>
                        <div style={fieldStyle} key={x.offerInformationId}>
                            <div>
                                <label style={labelStyle}>
                                    {x.level === "High" && <Warning style={{ marginRight: 3, fontSize: 12, color: Colors.baseColors.lightBlue }} />}
                                    {getText("SlcOfferRatesSpecificInfoTitle")}
                                </label>
                            </div>
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                <Linkify properties={{ target: "_blank" }}>{x.content}</Linkify>
                            </div>
                        </div>)}
            </div>
        </div>
    }
}