import * as React from 'react';
import Moment from 'moment';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as WorkDocumentsStore from '../store/WorkDocuments';
import * as CriteriaStore from '../store/Criteria';
import { ApplicationState } from '../store';
import ModeratorDocumentInfoEditForm from "./ModeratorDocumentInfoEditForm";
import Loader from './Loader';
import LocationSelect from './LocationSelect';
import Select, { Option } from "./Select";
import * as Colors from "../styles/colors";
import { getText } from '../utils/langTexts';
import Tooltip from './Tooltip';
import Button from './Button';
import Table, { Column } from './Table';
import { searchContainerStyle, searchFieldStyle, labelStyle, inputStyleModerator, formContainerStyle, filterForm, buttonStyle } from "../styles/moderatorStyles";
import { PriceListStatus } from './PriceListStatus';

type ModeratorWorkDocumentsProps = ModeratorWorkDocumentsOwnProps
    & WorkDocumentsStore.WorkDocumentsState
    & typeof WorkDocumentsStore.actionCreators;

interface ModeratorWorkDocumentsOwnProps {
    carriers: { [id: number]: Api.CarrierModel }
    subscription: Api.SubscriptionModel;
}

const getColumns = (props: ModeratorWorkDocumentsProps): Array<Column<Api.WorkDocumentsModel>> => {
    return [
        {
            header: getText("MdtRatesDocumentsCarrier"),
            id: 'carrier',
            accessor: (d: Api.WorkDocumentsModel) => (d.carriers.map(c => props.carriers[c.carrierId].name).join(',')),
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsReference"),
            id: 'reference',
            accessor: (d: Api.WorkDocumentsModel) => d.carrierReference,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsTrafficType"),
            id: 'trafficType',
            accessor: (d: Api.WorkDocumentsModel) => d.trafficType,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsPortsZones"),
            id: 'portsZones',
            accessor: (d: Api.WorkDocumentsModel) => d.portsZones,
            cell: (d: any) => {
                let value = (d as Array<Api.PortsZoneModel>);
                return value.length !== 0
                    ? <Tooltip overlay={<div>{value.map(x => x.name).join(", ")}</div>}>
                        <div>
                            {value.map(x => x.code).join(", ")}
                        </div>
                    </Tooltip>
                    : <div></div>;
            }
        },
        {
            header: getText("MdtRatesDocumentsValidityStart"),
            id: 'validityStart',
            accessor: (d: Api.WorkDocumentsModel) => d.validityStart,
            cell: (d: any) => <div>
                {d && Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsValidityEnd"),
            id: 'validityEnd',
            accessor: (d: Api.WorkDocumentsModel) => d.validityEnd,
            cell: (d: any) => <div>
                {d && Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsReception"),
            id: 'reception',
            accessor: (d: Api.WorkDocumentsModel) => d.receptionDate,
            cell: (d: any) => <div>
                {d && Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsCompletion"),
            id: 'completion',
            accessor: (d: Api.WorkDocumentsModel) => d.doneDate,
            cell: (d: any) => <div>
                {d && Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: "Integrated by",
            id: 'owner',
            accessor: (d: Api.WorkDocumentsModel) => d.owner,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsInformation"),
            id: 'information',
            accessor: (d: Api.WorkDocumentsModel) => d.offerInformations,
            cell: (d: any) => {
                let documentInfos = d as Array<Api.OfferInformationModel>;
                //console.log(documentInfos[0].content)
                let userDocumentInfo = documentInfos.find(di => di.source === "Client");
                return (
                    <div>
                        {userDocumentInfo ?
                            <div>
                                {userDocumentInfo.level === "Low" && getText("GenRatesInfoLevelLow")}
                                {userDocumentInfo.level === "Normal" && getText("GenRatesInfoLevelMedium")}
                                {userDocumentInfo.level === "High" && getText("GenRatesInfoLevelHigh")}
                            </div>
                            : "None"
                        }
                    </div>
                );
            }

        },
        {
            header: '',
            id: 'action',
            accessor: (d: Api.WorkDocumentsModel) => d,
            cell: (d: any) => {
                let workDocuments = d as Api.WorkDocumentsModel;
                return (
                    props.subscription.allowRatesControl &&
                    <div style={{ position: "relative" }}>
                        <Loader
                            isVisible={props.editEnabledStates[workDocuments.workDocumentsId]
                                && props.editEnabledStates[workDocuments.workDocumentsId].isLoading}
                            size={16}
                        />
                        <span
                            onClick={(e) => {
                                props.requestEditWorkDocumentsEnabled(new Date().getTime(), workDocuments.workDocumentsId, true);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            style={{
                                cursor: "pointer",
                                ...(!workDocuments.invalidated ? Colors.colors.main3 : {})
                            }}>
                            {getText("GenEnabled")}
                        </span>
                        <span> | </span>
                        <span
                            onClick={(e) => {
                                props.requestEditWorkDocumentsEnabled(new Date().getTime(), workDocuments.workDocumentsId, false);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            style={{
                                cursor: "pointer",
                                ...(workDocuments.invalidated ? Colors.colors.main3 : {})
                            }}>
                            {getText("GenDisabled")}
                        </span>
                        <span
                            style={{
                                marginLeft: 20,
                                cursor: "pointer",
                                ...(workDocuments.workDocumentsId === props.editState.workDocumentsId ? Colors.colors.main3 : {})
                            }}>
                            {getText("GenEdit")}
                        </span>
                    </div>
                );
            }
        }
    ];
}





const OriginDestSize = { width: "15em" };

const trafficTypeOptions: Array<{ label: string; value: Api.WorkDocumentsModelTrafficTypeEnum }> = [
    { label: "------", value: null },
    { label: "Export", value: "Export" },
    { label: "Import", value: "Import" },
    { label: "Cross trade", value: "CrossTrade" }
];

class ModeratorWorkDocuments extends React.Component<ModeratorWorkDocumentsProps, {}> {

    get carriersOptions(): Array<Option> {
        return _.sortBy(_.values(this.props.carriers), x => x.name)
            .map(ca => ({ label: ca.name, value: ca.carrierId }));
    }

    get selectedWorkDocuments(): Api.WorkDocumentsModel {
        return this.props.workDocumentses
            .find(x => x.workDocumentsId === this.props.editState.workDocumentsId);
    }

    public render() {
        return (
            <div>
                <h2>{getText("MdtRatesDocument")}</h2>
                <PriceListStatus/>
                <div style={searchContainerStyle}>
                    <form className="form-inline" style={filterForm}
                        onSubmit={(e) => {
                            this.props.requestWorkDocumentses(new Date().getTime());
                            e.preventDefault();
                        }}>


                            <div className="form-group" style={searchFieldStyle}>
                                <label className="control-label">
                                    {getText("MdtRatesDocumentsFilterCarrier")}
                                </label>
                                <Select
                                    options={this.carriersOptions}
                                    value={this.props.searchWorkDocumentsModel.carrierId}
                                    onChange={(value) => this.props.selectCarrierId(value)}
                                    style={{ width: "7em", padding: "4px 4px 4px 8px" }}
                                    search={true}
                                />
                            </div>
                            <div className="form-group" style={searchFieldStyle}>
                                <label className="control-label" style={{ ...labelStyle }}>{getText("MdtRatesDocumentsFilterOrigin")}</label>
                                <div>
                                    <LocationSelect
                                        className="form-control"
                                        inputKey={"WORKDOCUMENTS_ORIGIN"}
                                        value={this.props.searchWorkDocumentsModel.origin}
                                        onChange={(location) => this.props.selectLocation(location, CriteriaStore.Source.Origin)}
                                    />
                                </div>
                            </div>
                            <div className="form-group" style={searchFieldStyle}>
                                <label className="control-label" style={{ ...labelStyle }}>{getText("MdtRatesDocumentsFilterDestination")}</label>
                                <div>
                                    <LocationSelect
                                        className="form-control"
                                        inputKey={"WORKDOCUMENTS_DESTINATION"}
                                        value={this.props.searchWorkDocumentsModel.destination}
                                        onChange={(location) => this.props.selectLocation(location, CriteriaStore.Source.Destination)}
                                        style={OriginDestSize}
                                    />
                                </div>
                            </div>
                            <div className="form-group" style={searchFieldStyle} >
                                <label className="control-label" style={{ paddingRight: "8px" }}>{getText("MdtRatesDocumentsFilterTrafficType")}</label>
                                <Select
                                    options={trafficTypeOptions}
                                    value={this.props.searchWorkDocumentsModel.trafficType}
                                    onChange={(value) => this.props.selectTrafficType(value)}
                                    style={{ width: "5em", padding: "4px 4px 4px 8px" }}
                                />
                            </div>
                            <div className="form-group" style={searchFieldStyle}>
                                <label className="control-label" style={{ ...labelStyle }}>{getText("MdtRatesDocumentsFilterReference")}</label>
                                <div>
                                    <input type="text" className="form-control" style={inputStyleModerator}
                                        value={this.props.searchWorkDocumentsModel.reference}
                                    />
                                    </div>
                                    </div>
                                    <Button
                                        type="submit"
                                         style={buttonStyle}>
                                        {getText("MdtRatesDocumentsSearch")}
                                    </Button>
                                    <Loader isVisible={this.props.isLoading} size={100} />
                           
                            </form>
                        </div>
                        <div className='okargo-table'>
                            <Table
                                tableKey={"mdWorkdocuments"}
                                data={this.props.workDocumentses}
                                columns={getColumns(this.props)}
                                rowsPerPage={8}
                                showPagination={true}
                                isLoading={this.props.isLoading}
                                loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                                showPageSizeOptions={false}
                                noDataText={getText("MdtNoRatesInformation")}
                                onBodyTrClick={(workDocuments: Api.WorkDocumentsModel) => {
                                    if (workDocuments) {
                                        this.props.selectWorkDocuments(workDocuments.workDocumentsId);
                                    }
                                }}
                            />
                        </div>
                        <div>
                            {this.props.editState.workDocumentsId && this.props.workDocumentses.filter(x => x.workDocumentsId == this.props.editState.workDocumentsId)[0].enabled
                                &&
                                <div style={formContainerStyle}>
                                    <div className="form-horizontal" style={{ marginBottom: 10, marginTop: 10 }}>
                                        <div className="row">
                                            <label className="control-label col-md-4">{getText("MdtRatesDocumentsCarrier")}</label>
                                            <div className="form-control-static col-md-6">
                                                {this.selectedWorkDocuments.carriers.map(c => this.props.carriers[c.carrierId].name).join(',')}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="control-label col-md-4">{getText("MdtRatesDocumentsReference")}</label>
                                            <div className="form-control-static col-md-6">
                                                {this.selectedWorkDocuments.carrierReference}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="control-label col-md-4">{getText("MdtRatesDocumentsMainPorts")}</label>
                                            <div className="form-control-static col-md-6">
                                                {this.selectedWorkDocuments.mainPorts
                                                    .map(x => x.name)
                                                    .join(", ")}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="control-label col-md-4">{getText("MdtRatesDocumentsPortsZones")}</label>
                                            <div className="form-control-static col-md-6">
                                                {this.selectedWorkDocuments.portsZones
                                                    .map(x => x.name)
                                                    .join(", ")}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ModeratorDocumentInfoEditForm
                                            onSubmit={(values) => {
                                                if (values.delete) {
                                                    this.props.requestDeleteDocumentInfo(new Date().getTime(), values);
                                                } else {
                                                    this.props.requestEditDocumentInfo(new Date().getTime(), values);
                                                }
                                                return values;
                                            }}
                                        />
                                    </div>
                                </div>}
                        </div>
                </div>
                );
    }
}

                export default connect(
    (state: ApplicationState) => ({
                    ...state.workDocuments,
                    carriers: state.seed.carriers,
                subscription: state.account.currentUser.clientModel
                ? _.sortBy(state.account.currentUser.clientModel.subscriptions,
                x => x.subscriptionId === state.account.currentUser.clientModel.subscriptionId)
                .reverse()[0]
                : { }
    } as any),
                WorkDocumentsStore.actionCreators)(ModeratorWorkDocuments) as any