import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as AccountState from '../store/Account';
import * as Api from '../api/api';
import { dictionnaryToArray, getSubscription } from '../utils/utils';
import { NavLink } from 'react-router-dom';
import { getText } from '../utils/langTexts';
import Button from './Button';
import * as Colors from '../styles/colors';

type CurrentUserProps =
    AccountState.AccountState
    & CurrentUserOtherProps
    & typeof AccountState.actionCreators;

type CurrentUserOtherProps = {
    currencies: { [id: number]: Api.CurrencyModel };
    languages: { [id: number]: Api.LanguageModel };
}

class CurrentUser extends React.Component<CurrentUserProps, {}> {

    componentWillMount() {
    }

    get firmLogoId(): number {
        let subscriptions = [getSubscription(this.props.currentUser.clientModel)]
            .concat(this.props.currentUser.clientModel.subscriptions).filter(x => x);

        let subscription = subscriptions.find(x => x.subscriptionType === "Okargo"
            || x.subscriptionType === "Agent");

        if (!subscription)
            return null;

        if (subscription.subscriptionType === "Okargo")
            return subscription.agencyId;
        if (subscription.subscriptionType === "Agent")
            return subscription.firmId;

        return null;
    }

    get selectedCurrency(): Api.CurrencyModel {
        return dictionnaryToArray<Api.CurrencyModel>(this.props.currencies)
            .find(cu => cu.currencyId === this.props.currentUser.clientModel.currencyId);
    }

    public render() {
        return <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "8em",
        }}>
            {this.props.currentUser && this.props.currentUser.clientModel
                && <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start"
                }}>
                <div style={{ flex: "0 0 auto", padding: "0px 18px" }}>
                        <img src={"/image/FirmLogo?firmId=" + this.firmLogoId}
                            style={{
                                objectFit: "cover",
                                maxWidth: "100%",
                                maxHeight: 65,
                            }}
                        />
                    </div>
                <div style={{ flex: "0 0 auto", padding: "0px 18px" }}>
                        <div>
                        <NavLink
                            to={"/settings"}
                            style={{ color: Colors.baseColors.lightBlue, textDecoration: "underline" } as React.CSSProperties}>
                            {this.props.currentUser.clientModel.firstName} {this.props.currentUser.clientModel.lastName}
                        </NavLink>
                    </div>
                    <div style={{ paddingTop: 12 }}>
                            <Button
                                style={{ height: 25 }}
                                onClick={(e) => {
                                    this.props.logout();
                                    e.preventDefault();
                                }}>
                                {getText("GenLogout")}
                            </Button>
                        </div>
                    </div>
                </div>}
        </div>
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            ...state.account,
            currencies: state.seed.currencies,
            languages: state.seed.languages,
        } as any
    },
    AccountState.actionCreators
)(CurrentUser) as any;