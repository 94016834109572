import * as Api from '../api/api';
import { getText, translateEnum } from './langTexts';


export const trafficTypeOptions: Array<{ label: string; value: Api.WorkDocumentsModelTrafficTypeEnum }> = [
    { label: "Export", value: "Export" },
    { label: "Import", value: "Import" },
    { label: "Cross trade", value: "CrossTrade" }
];

export const offerType: Array <{ label: string; value: Api.RatesOfferModelTypeEnum }> = [
    { label: "Fak", value: "Fak" },
    { label: "Special", value: "Special" }

];


export const containerType: Array <{ label: string; value: Api.ChargeSetModelContainerTypeEnum }> = [
    { value: "Dry", label: "Dry" },
    { value: "Rf", label: "Reefer" },
    { value: "Fl", label: "Flat track" },
    { value: "Ot", label: "Open top" },
    { value: "Tk", label: "Tank" },

];



