import * as _ from 'lodash';
import * as React from 'react';
import { RatesCalculator } from '../utils/ratesUtils';
import * as Api from '../api/api';
import Tooltip from "./Tooltip";
import { getText } from '../utils/langTexts';
import { getRatesStyle } from '../utils/utils';
import { CSSProperties } from 'react';
import { frtId } from '../utils/constants';
import { chargeAgentsToMoficiations } from '../utils/mapping';

interface OfferPriceProps {
    style?: CSSProperties;
    currencyId: number;
    subscriptionType: Api.SubscriptionModelSubscriptionTypeEnum;
    criteriaModel: Api.CriteriaModel;
    sellingRates: Array<Api.SellingRatesModel>;
    currencies: { [id: number]: Api.CurrencyModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    carrierOffer: Api.CarrierOfferModel;
    inline: boolean;
}

const getApplicationHiddenIncluded = (
    ratesCalculator: RatesCalculator,
    chargeSet: Api.ChargeSetModel): Api.ChargeModelApplicationEnum => {
    if (!chargeSet)
        return null;

    //To get the origin/dest charges
    let altRatesCalculator = new RatesCalculator(
        ratesCalculator.currencies,
        ratesCalculator.sizeTypes,
        {
            ...ratesCalculator.criteria,
            loadingCharge: true,
            unLoadingCharge: true
        });

    if (!ratesCalculator.criteria.loadingCharge
        && (ratesCalculator.criteria.criteriaSizeTypes.some(cst =>
        altRatesCalculator.findChargesToApply(chargeSet, cst, "Origin")
                .some(ch => ch.chargeType === "Source" && ch.type === "Incl"))
        || altRatesCalculator.findBlChargesToApply(chargeSet, "Origin")
                .some(ch => ch.chargeType === "Source" && ch.type === "Incl")))
        return "Origin";

    if (!ratesCalculator.criteria.unLoadingCharge
        && (ratesCalculator.criteria.criteriaSizeTypes.some(cst =>
        altRatesCalculator.findChargesToApply(chargeSet, cst, "Destination")
                .some(ch => ch.chargeType === "Source" && ch.type === "Incl"))
        || altRatesCalculator.findBlChargesToApply(chargeSet, "Destination")
                .some(ch => ch.chargeType === "Source" && ch.type === "Incl")))
        return "Destination";

    return null;
};

interface IncludedWarningProps {
    ratesCalculator: RatesCalculator;
    chargeSet: Api.ChargeSetModel;
    visible: boolean;
}

export class IncludedWarning extends React.Component<IncludedWarningProps, {}> {
    public render() {
        let applicationHidden = getApplicationHiddenIncluded(this.props.ratesCalculator, this.props.chargeSet);

        return (
            <div>
                <Tooltip
                    disableHoverListener={applicationHidden ? false : true}
                    overlay={<div>{applicationHidden
                        ? getText("SlcIncludedWarning", { application: applicationHidden.toString() })
                        : ""}</div>}>
                    <div style={{ display: "inline-flex", flexDirection: "row" }}>
                        <div style={{ order: 1, flex: "1" }}>
                            {this.props.children}
                        </div>
                        <div style={{ order: 2, flex: "0" }}>
                            {applicationHidden && this.props.visible ? "*" : ""}
                        </div>
                    </div>
                </Tooltip>
            </div>
            );
    }
}

export default class OfferPrice extends React.Component<OfferPriceProps, {}> {
    private ratesCalculator: RatesCalculator;

    constructor(props) {
        super(props);
    }

    onPropsUpdate(props: OfferPriceProps) {
        this.ratesCalculator = new RatesCalculator(props.currencies, props.sizeTypes, props.criteriaModel);
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: OfferPriceProps) {
        if (this.props.criteriaModel !== nextProps.criteriaModel
            || this.props.currencies !== nextProps.currencies)
            this.onPropsUpdate(nextProps);
    }

    calculateContainerPrice(sizeType: Api.CriteriaSizeTypeModel) {
        let chargesToApply = this.ratesCalculator.findChargesToApply(
            this.props.carrierOffer.chargeSet, sizeType);

        if (!chargesToApply.some(x => x.chargeNameId === frtId))
            return undefined;
        
        let chargesBl = this.ratesCalculator.findBlChargesToApply(this.props.carrierOffer.chargeSet);
        return this.ratesCalculator.calculatePrice(
            chargesToApply.concat(chargesBl), this.props.sizeTypes[sizeType.sizeTypeId].teu).totalUsd
    }

    get hasSellingRates(): boolean {
        return this.props.criteriaModel.includeSellingRates
            && this.props.sellingRates.length !== 0
            && (!this.props.carrierOffer.chargeSet.ratesOfferId
                || this.props.carrierOffer.ratesOffer.type === "Fak"
                || this.props.carrierOffer.chargeSet.ratesFetchedRecordId != undefined);
    }

    get sellingRatesAmountUsd(): number {
        return this.props.sellingRates
            ? _.sum(this.props.criteriaModel.criteriaSizeTypes
                .filter(x => x.number > 0)
                .map(x => _.sum(this.props.sellingRates[0].sellingRatesValues
                    .filter(y => {
                        let frt = this.props.carrierOffer.chargeSet.charges.find(y => y.chargeNameId === frtId);
                        return frt && y.currencyId === frt.currencyId && (!y.sizeTypeId || y.sizeTypeId == x.sizeTypeId);
                    })
                    .map(y => y.sellingMargin * this.props.currencies[y.currencyId].value)) * x.number))
            : 0;
    }

    public render() {
        let allIn = this.props.carrierOffer.chargeSet
        ? this.ratesCalculator.calculateAllIn(
            this.props.carrierOffer.chargeSet,
                chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents))
            : undefined;

        return <div style={{
            ...getRatesStyle(this.props.carrierOffer.chargeSet, this.props.criteriaModel),
            ...this.props.style
        }}>
            {this.props.carrierOffer.chargeSet && !this.props.carrierOffer.chargeSet.invalidated
                ?
                (this.props.criteriaModel.containerPrice
                    ?
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {this.props.criteriaModel.criteriaSizeTypes
                            .filter(st => st.number > 0)
                            .map((st, i) =>
                                <div key={st.sizeTypeId} style={{ marginRight: 10 }}>
                                    <IncludedWarning
                                        chargeSet={this.props.carrierOffer.chargeSet}
                                        ratesCalculator={this.ratesCalculator}
                                        visible={this.props.subscriptionType !== "Agent"}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            {i > 0 && this.props.inline && <span> |</span>}
                                            <div style={{ marginRight: 5 }}>
                                                <span>{this.props.sizeTypes[st.sizeTypeId].name}: </span>
                                            </div>
                                            <div style={{ fontWeight: 700 }}>
                                                <span style={{ fontWeight: 700 }}>{this.calculateContainerPrice(st)
                                                    ? Math.round(this.calculateContainerPrice(st) / this.props.currencies[this.props.currencyId].value)
                                                    : "_"}</span>
                                                <span> </span>
                                                <span>{this.calculateContainerPrice(st) && this.props.currencies[this.props.currencyId].code}</span>
                                            </div>
                                        </div>
                                    </IncludedWarning>
                                </div>)}
                    </div>
                    :
                    <div>
                        <IncludedWarning
                            chargeSet={this.props.carrierOffer.chargeSet}
                            ratesCalculator={this.ratesCalculator}
                            visible={this.props.subscriptionType !== "Agent"}>
                            <div style={{  }}>
                                {this.hasSellingRates
                                    && this.sellingRatesAmountUsd != 0
                                    && <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    alignSelf: "center"
                                }}>
                                    <div style={{ flex: "0 0 auto", marginRight: 3 }}>
                                        <Tooltip overlay={<div>Buying rates</div>}>
                                            <div>
                                                <span style={{ fontWeight: 700 }}>{Math.round(allIn.totalUsd / this.props.currencies[this.props.currencyId].value)}</span>
                                                <span> </span>
                                                <span>{this.props.currencies[this.props.currencyId].code}</span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div style={{ flex: "0 0 auto", marginRight: 3 }}>
                                        {" / "}
                                    </div>
                                    <div style={{ flex: "0 0 auto" }}>
                                        <Tooltip overlay={<div>Selling rates</div>}>
                                            <div>
                                                <span style={{ fontWeight: 700 }}>{Math.round((allIn.totalUsd + this.sellingRatesAmountUsd) / this.props.currencies[this.props.currencyId].value)}</span>
                                                <span> </span>
                                                <span>{this.props.currencies[this.props.currencyId].code}</span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    </div>}
                                {(!this.hasSellingRates
                                    || this.sellingRatesAmountUsd == 0)
                                    && <div>
                                    <span style={{ fontWeight: 700 }}>{Math.round(allIn.totalUsd / this.props.currencies[this.props.currencyId].value)}</span>
                                        <span> </span>
                                        <span>{this.props.currencies[this.props.currencyId].code}</span>
                                    </div>}
                            </div>
                        </IncludedWarning>
                    </div>)
                :
                <div>{!this.props.carrierOffer.chargeSet
                    ? "-"
                    : <Tooltip
                        overlay={<div>This rates have been disabled by a moderator</div>}><div>Disabled</div></Tooltip>}
                </div>}
        </div>
    }
}