import { ApplicationState } from '../store';
import { isInDateRange, getCarrierOfferKey } from "../utils/utils"
import * as SelectionStore from '../store/Selection';
import { createSelector } from 'reselect'
import { RatesCalculator } from '../utils/ratesUtils';
import { frtId } from '../utils/constants';
import * as Api from '../api/api';
import { chargeAgentsToMoficiations } from '../utils/mapping';
import * as _ from 'lodash';
import { SelectionFilterOptions } from '../store/Selection';
import { getRouteConfig } from '../utils/routeUtils';

export const filterCarrierOffers = (filterModel: SelectionStore.SelectionFilterModel
    , carrierOffers: Array<Api.CarrierOfferModel>
    , carrierOfferStates: { [key: string]: SelectionStore.CarrierOfferState }
    , ratesCalculator: RatesCalculator): Array<Api.CarrierOfferModel> => {
    
    
    return carrierOffers.filter(co => {
        let allInUsd = co.chargeSet
            ? ratesCalculator.calculateAllIn(co.chargeSet
                , chargeAgentsToMoficiations(co.chargeAgents)).totalUsd
            : 0;
        let routeConfig = getRouteConfig(co, carrierOfferStates[getCarrierOfferKey(co)]);
        let transitTime = SelectionStore.getTableTransitTime(co, carrierOfferStates);

        return filterModel.carriersSelected[co.carrierId] !== false
            && (co.chargeSet || !filterModel.hideNoRates)
            && (!co.chargeSet || (
                (allInUsd <= filterModel.maxAllIn)
                && ((co.chargeSet.dateBegin && co.chargeSet.dateEnd)
                    || (isInDateRange(co.chargeSet.dateBegin
                        ? new Date(co.chargeSet.dateBegin).getTime()
                        : undefined,
                        co.chargeSet.dateEnd
                            ? new Date(co.chargeSet.dateEnd).getTime()
                            : undefined,
                        filterModel.minRatesDate, filterModel.maxRatesDate || 8640000000000000))) //MaxJsDate
                && (filterModel.ratesType === "AllRates"
                    || ((co.ratesOffer != null ? co.ratesOffer.type === "Fak" : !co.ratesOffer)
                        && filterModel.ratesType === "Fak")
                    || (co.ratesOffer
                        && co.ratesOffer.type === filterModel.ratesType))

                && (!filterModel.ratesTypeName || (!co.ratesOffer
                    || (co.ratesOffer.shortName
                        && co.ratesOffer.shortName.toLocaleLowerCase().includes(filterModel.ratesTypeName?.toLocaleLowerCase())) //check the shortname
                    || (co.ratesOffer.commodity
                        && co.ratesOffer.commodity.toLocaleLowerCase().includes(filterModel.ratesTypeName?.toLocaleLowerCase()))
                    || (co.ratesOffer.nac
                        && co.ratesOffer.nac.toLocaleLowerCase().includes(filterModel.ratesTypeName?.toLocaleLowerCase()))
                    || (co.ratesOffer.references
                        && co.ratesOffer.references.some(r => r.value?.toLocaleLowerCase().includes(filterModel.ratesTypeName?.toLocaleLowerCase())))) //check inside ref list 
                    || (!co.chargeSet
                        || (co.chargeSet.reference
                            && co.chargeSet.reference.toLocaleLowerCase().includes(filterModel.ratesTypeName?.toLocaleLowerCase())))// check in chargset list ref
                )))
            && (!transitTime || transitTime <= filterModel.maxTransitTime)
            && (!routeConfig || routeConfig.transShipments.length <= filterModel.maxTransShipment)
            && ((!routeConfig || routeConfig.departs.some(dp => filterModel.minEtaDate <= new Date(dp.eta).getTime()
                && new Date(dp.eta).getTime() <= filterModel.maxEtaDate))
                || (co.chargeSet
                    && co.chargeSet.departs
                    && co.chargeSet.departs.length !== 0
                    && co.chargeSet.departs
                        .some(x => x.transitTime <= filterModel.maxTransitTime
                            && filterModel.minEtaDate <= new Date(x.eta).getTime()
                            && new Date(x.eta).getTime() <= filterModel.maxEtaDate)));
    });
}

//Reselect for filter

const getFilterModel = (state: ApplicationState) => state.selection.filterModel;
const getCarrierOffers = (state: ApplicationState) => state.selection.selection.carrierOffers;
const getCarrierOfferStates = (state: ApplicationState) => state.selection.carrierOfferStates;
const getCriteria = (state: ApplicationState) => state.criteria.criteria;
const getSizeTypes = (state: ApplicationState) => state.seed.sizeTypes;
const getCurrencies = (state: ApplicationState) => state.seed.currencies;
const getCarriers = (state: ApplicationState) => state.seed.carriers;


export const getCarrierOffersPreFiltered = createSelector(
    [getCarrierOffers, getCriteria, getCurrencies, getSizeTypes, getCarriers],
    (carrierOffers, criteria, currencies, sizeTypes, carriers) => {
        let ratesCalculator = new RatesCalculator(currencies, sizeTypes, criteria);
        return criteria.type === "Fcl"
            ? carrierOffers
                .filter(ca =>
                    !ca.chargeSet
                    || ((!criteria.criteriaSizeTypes.some(cst => cst.number > 0
                        && !ratesCalculator.findChargesToApply(ca.chargeSet, cst,
                            null, chargeAgentsToMoficiations(ca.chargeAgents))
                            .some(ch => ch.chargeNameId === frtId)) || criteria.containerPrice)
                        && criteria.criteriaSizeTypes.some(cst => cst.number > 0
                            && ratesCalculator.findChargesToApply(ca.chargeSet, cst,
                                null, chargeAgentsToMoficiations(ca.chargeAgents))
                                .some(ch => ch.chargeNameId === frtId))
                        && (criteria.includeCarrierRates
                            || !ca.chargeSet.ratesFetchedRecordId)))
            //we filter out FCL carriers client side as FCL rates can be attached to FCL routes
            : carrierOffers
                .filter(ca =>
                    (!ca.chargeSet
                        || ca.chargeSet.charges.some(x => x.chargeNameId === frtId))
                    && (ca.chargeSet
                        || (carriers[ca.carrierId].type === "FclNvocc" || carriers[ca.carrierId].type === "LclNvocc" || carriers[ca.carrierId].type === "None")));
    });

//Filter, sort and get the filter options
export const getCarrierOffersFiltered = createSelector(
    [getFilterModel, getCarrierOffersPreFiltered, getCriteria, getCurrencies, getCarriers, getSizeTypes, getCarrierOfferStates],
    (filter, carrierOffers, criteria, currencies, carriers, sizeTypes, carrierOfferStates) => {
        let ratesCalculator = new RatesCalculator(currencies, sizeTypes, criteria);
        //those with frts
        let carrierOffersFiltered = filterCarrierOffers(filter, carrierOffers, carrierOfferStates, ratesCalculator);

        //Filter out no-rates when offers are available 
        carrierOffersFiltered = carrierOffersFiltered
            .filter(x => x.chargeSet
                || !carrierOffersFiltered.some(y => y !== x
                    && y.routeConfigs[0]?.routeId === x.routeConfigs[0]?.routeId
                    && y.chargeSet
                    // && (y.chargeSet.ratesServiceRelation !== "Attached"
                    //     || !y.routeConfigs.some(z => z.routeServiceId === y.chargeSet.routeServiceId))
                ));

        carrierOffersFiltered =  carrierOffersFiltered.map(co => {
                let filteredRc = co.routeConfigs
                    ?.filter(x => co.chargeSet == null
                        || !co.chargeSet.routeServiceId
                        || co.chargeSet.routeServiceId === x.routeServiceId) || [];
                return {
                    ...co,
                    routeConfigs: _.sortBy((filteredRc.length !== 0
                        ? filteredRc
                        : co.routeConfigs)
                        .map(rc => {
                            return {
                                ...rc,
                                departs: rc.departs
                                    .filter(dp => filter.minEtaDate <= new Date(dp.eta).getTime()
                                        && new Date(dp.eta).getTime() <= filter.maxEtaDate)
                            }
                        })
                        .filter(x => x.departs.length !== 0
                            || (co.chargeSet
                                && co.chargeSet.departs
                                && co.chargeSet.departs.length !== 0)), x => x.transitTime)
                }
            });
        
        if(filter.onlyShowBestTt) {
            carrierOffersFiltered.map(co => {
                if(co.routeConfigs?.some(x => x.source !== "Manual" && x.routeServiceId)){
                    return {
                        ...co,
                        routeConfigs: [ _.sortBy(co.routeConfigs
                            .filter(x => x.source !== "Manual" && x.routeServiceId), y => y.transitTime)
                            [0] ]
                            
                    }
                } else {
                    return {
                        ...co,
                        routeConfigs: co.routeConfigs.length !== 0 
                            ? [ _.sortBy(co.routeConfigs, x => x.transitTime)[0] ]
                            : []
                    }
                }
            });
        }
        
        return carrierOffersFiltered;
    }

)

export const getCarrierOfferSorted = createSelector(getFilterModel, getSizeTypes, getCurrencies, getCriteria, getCarriers, getCarrierOffersFiltered, getCarrierOfferStates
    , (filterModel
        , sizeTypes
        , currencies
        , criteria
        , carriers
        , carrierOffers
        , carrierOfferStates) => {
        let ratesCalculator = new RatesCalculator(currencies, sizeTypes, criteria);

        switch (filterModel.sortBy) {
            case "AllIn":
                return _.sortBy(carrierOffers, x => x.chargeSet
                    ? ratesCalculator.calculateAllIn(x.chargeSet, chargeAgentsToMoficiations(x.chargeAgents)).totalUsd
                    : Number.MAX_SAFE_INTEGER);
            case "Pol":
                return _.sortBy(carrierOffers, co => co.originPort.name);
            case "Pod":
                return _.sortBy(carrierOffers, co => co.destinationPort.name);
            case "Carrier":
                return _.sortBy(carrierOffers, co => carriers[co.carrierId].name);
            case "TransitTime":
                return _.sortBy(carrierOffers, co => SelectionStore.getTableTransitTime(co, carrierOfferStates));
            case "TransShipments":
                return _.sortBy(carrierOffers, co => getRouteConfig(co, carrierOfferStates[getCarrierOfferKey(co)])?.transShipments?.length ?? 0);
            case "Validity":
                return _.sortBy(carrierOffers, co => {
                        if (co.chargeSet) {
                            return co.chargeSet.ratesPriceType === "Spot" && co.chargeSet.departs && co.chargeSet.departs.length > 0
                                ? (new Date(co.chargeSet.departs[0]?.etd).getTime() || 0)
                                : (new Date(co.chargeSet.dateEnd).getTime() + 1000000000000);
                        }
                        
                        return Number.MAX_SAFE_INTEGER;
                    });
            case "AllInCtr1":
                let st1 = sizeTypes[ratesCalculator.criteria.criteriaSizeTypes.find(y => sizeTypes[y.sizeTypeId].position === 1).sizeTypeId];
                var crt = ratesCalculator.criteria.criteriaSizeTypes.find(y => sizeTypes[y.sizeTypeId].position === 1);
                return _.sortBy(carrierOffers,
                    x => x.chargeSet
                        ? ratesCalculator.calculatePrice(
                            ratesCalculator.findChargesToApply(x.chargeSet,
                                crt).concat(ratesCalculator.findBlChargesToApply(x.chargeSet)), st1.teu).totalUsd
                        : Number.MAX_SAFE_INTEGER);
            case "AllInCtr2":
                let st2 = sizeTypes[ratesCalculator.criteria.criteriaSizeTypes.find(y => sizeTypes[y.sizeTypeId].position === 2).sizeTypeId];
                var crt = ratesCalculator.criteria.criteriaSizeTypes.find(y => sizeTypes[y.sizeTypeId].position === 2);
                return _.sortBy(carrierOffers,
                    x => x.chargeSet
                        ? ratesCalculator.calculatePrice(
                            ratesCalculator.findChargesToApply(x.chargeSet,
                                crt).concat(ratesCalculator.findBlChargesToApply(x.chargeSet)), st2.teu).totalUsd
                        : Number.MAX_SAFE_INTEGER);
            case "AllInCtr3":
                let st3 = sizeTypes[ratesCalculator.criteria.criteriaSizeTypes.find(y => sizeTypes[y.sizeTypeId].position === 3).sizeTypeId];
                var crt = ratesCalculator.criteria.criteriaSizeTypes.find(y => sizeTypes[y.sizeTypeId].position === 3);
                return _.sortBy(carrierOffers,
                    x => x.chargeSet
                        ? ratesCalculator.calculatePrice(
                            ratesCalculator.findChargesToApply(x.chargeSet,
                                crt).concat(ratesCalculator.findBlChargesToApply(x.chargeSet)), st3.teu).totalUsd
                        : Number.MAX_SAFE_INTEGER);
            case "AllInCtr4":
                let st4 = sizeTypes[ratesCalculator.criteria.criteriaSizeTypes.find(y => sizeTypes[y.sizeTypeId].position === 4).sizeTypeId];
                if (!st4) {
                    return carrierOffers;
                }
                var crt = ratesCalculator.criteria.criteriaSizeTypes.find(y => sizeTypes[y.sizeTypeId].position === 3);
                return _.sortBy(carrierOffers,
                    x => x.chargeSet
                        ? ratesCalculator.calculatePrice(
                            ratesCalculator.findChargesToApply(x.chargeSet,
                                crt).concat(ratesCalculator.findBlChargesToApply(x.chargeSet)), st4.teu).totalUsd
                        : Number.MAX_SAFE_INTEGER);
        }
        return carrierOffers;
    })

export const getFilterOptions = createSelector(
    [getCarrierOffersPreFiltered, getCriteria, getCurrencies, getSizeTypes],
    (carrierOffers, criteria, currencies, sizeTypes) => {
        let ratesCalculator = new RatesCalculator(currencies, sizeTypes, criteria);
        let minAllIn = 0
        let maxAllIn = 0;
        let minTransitTime = 0;
        let maxTransitTime = 0;
        let minEtaDate = 0;
        let maxEtaDate = 0;
        let minRatesDate = 0;
        let maxRatesDate = 0;
        let maxTransShipment = 0;

        if (carrierOffers.length !== 0) {
            let carrieroffersPriced = carrierOffers.filter(co => co.chargeSet);
            if (carrieroffersPriced.length !== 0) {
                let carrierOffersByPrice = _.sortBy(carrieroffersPriced
                    .map(co => ({
                        carrierOffer: co,
                        allIn: ratesCalculator.calculateAllIn(co.chargeSet, chargeAgentsToMoficiations(co.chargeAgents))
                    })),
                    x => x.allIn.totalUsd);
                minAllIn = Math.floor(carrierOffersByPrice[0].allIn.totalUsd);
                maxAllIn = Math.ceil(carrierOffersByPrice[carrierOffersByPrice.length - 1].allIn.totalUsd);

                let carrierOffersByValidity = _.sortBy(carrieroffersPriced
                    .map(co => new Date(co.chargeSet.dateEnd)), x => x.getTime());
                minRatesDate = carrierOffersByValidity[0].getTime();
                maxRatesDate = carrierOffersByValidity[carrierOffersByValidity.length - 1].getTime();
            }

            var routeconfigs = carrierOffers.map(x => x.routeConfigs).reduce((a, b) => a.concat(b), []);

            let transitTimes = routeconfigs
                .map(x => x.transitTime)
                .concat(routeconfigs.map(x => x.departs)
                    .reduce((a, b) => a.concat(b), []).map(x => x.transitTime))
                .concat(carrierOffers.map(x => x.chargeSet && x.chargeSet.departs ? x.chargeSet.departs : [])
                    .reduce((a, b) => a.concat(b), []).map(x => x.transitTime))
                .concat(carrierOffers.filter(x => x.chargeSet && x.chargeSet.transitTime).map(x => x.chargeSet.transitTime))
                ;

            minTransitTime = _.min(transitTimes);
            maxTransitTime = _.max(transitTimes);

            let transShipments = carrierOffers.map(x => x.routeConfigs)
                .reduce((a, b) => a.concat(b), []).map(x => x.transShipments.length);
            maxTransShipment = _.max(transShipments);

            let carrierOffersByEta = _.sortBy(carrierOffers
                .map(co => {
                    let departs = new Array<Api.DepartModel>();
                    departs = departs.concat(co.routeConfigs
                        .map(x => x.departs)
                        .reduce((a, b) => a.concat(b), []));
                    if (co.chargeSet && co.chargeSet.departs) {
                        departs = departs.concat(co.chargeSet.departs);
                    }
                    return departs;
                }).reduce((a, b) => a.concat(b), []), x => new Date(x.eta).getTime());
            minEtaDate = carrierOffersByEta[0] ? new Date(carrierOffersByEta[0].eta).getTime() : new Date().getTime();
            maxEtaDate = carrierOffersByEta[carrierOffersByEta.length - 1] ? new Date(carrierOffersByEta[carrierOffersByEta.length - 1].eta).getTime() : new Date().getTime();
        }

        return {
            minAllIn: minAllIn,
            maxAllIn: maxAllIn,
            minEtaDate: minEtaDate,
            maxEtaDate: maxEtaDate,
            minRatesDate: minRatesDate,
            maxRatesDate: maxRatesDate,
            minTransitTime: minTransitTime,
            maxTransitTime: maxTransitTime,
            maxTransShipment: maxTransShipment
        } as SelectionFilterOptions;
    });

export const getGroupedCarrierOffers = createSelector([getCarrierOfferSorted, getCarriers, getFilterModel], (carrierOffers, carriers, filter) => {
    return filter.byRatesOffer
        ? _.map(_.groupBy(carrierOffers,
            x => "C" + x.carrierId
                + "R" + (x.ratesOffer
                    ? x.ratesOffer.ratesOfferId
                    : "0")), x => x.map(y => y))
            .map(x => x)
        : carrierOffers.map(x => [x])
});