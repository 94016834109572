import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';

export interface TutorialState {
    isLoading: boolean;
    isLoaded: boolean;
    requestTime?: number;
    isEnabled: boolean;
    updateState: {
        requestTime?: number;
        isLoading: boolean;
    };
    tutorials: Array<Api.TutorialModel>;
    parts: {
        [key: string]: {
            isRunning: boolean;
            isEnabled: boolean;
            step: number;
        }
    }
}

interface TurorialRequestTutorials {
    type: 'TUTORIAL_REQUEST_TUTORIALS';
    payload: {
        requestTime: number;
    }
}
interface TurorialReceiveTutorials {
    type: 'TUTORIAL_RECEIVE_TUTORIALS';
    payload: {
        requestTime: number;
        tutorials?: Array<Api.TutorialModel>;
    }
    error?: any;
}
interface TurorialRequestUpdateTutorial {
    type: 'TUTORIAL_REQUEST_UPDATETUTORIAL';
    payload: {
        requestTime: number;
    }
}
interface TurorialReceiveUpdateTutorial {
    type: 'TUTORIAL_RECEIVE_UPDATETUTORIAL';
    payload: {
        requestTime: number;
        tutorial?: Api.TutorialModel;
    }
    error?: any;
}
interface TurorialStartPart { type: 'TUTORIAL_START_PART'; payload: { key: string } }
interface TurorialStopPart { type: 'TUTORIAL_STOP_PART'; payload: { key: string } }
interface TurorialNextStep { type: 'TUTORIAL_NEXT_STEP'; payload: { key: string, isLast: boolean } }

export type KnownAction = TurorialStartPart
    | TurorialStopPart
    | TurorialRequestTutorials
    | TurorialReceiveTutorials
    | TurorialNextStep
    | TurorialRequestUpdateTutorial
    | TurorialReceiveUpdateTutorial
    ;

export const requestTutorial = (requestTime: number, dispatch: (a: any) => void, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.TutorialApi();
    let task = api.tutorials({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(tutorials => {
            dispatch({
                type: "TUTORIAL_RECEIVE_TUTORIALS",
                payload: { requestTime: requestTime, tutorials: tutorials }
            });
        }).catch(err => {
            dispatch({
                type: "TUTORIAL_RECEIVE_TUTORIALS",
                payload: { requestTime: requestTime },
                error: err
            });
        });

    dispatch({
        type: "TUTORIAL_REQUEST_TUTORIALS",
        payload: { requestTime: requestTime },
    });
    return task;
    
}

export const actionCreators = {
    requestTutorials: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        return requestTutorial(requestTime, dispatch, getState);
    },
    requestUpdateTutorial: (requestTime: number, name: string, status: Api.TutorialModelStatusEnum): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let api = new Api.TutorialApi();
        let task = api.updateTutorial({ name: name, status: status }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(tutorial => {
                dispatch({
                    type: "TUTORIAL_RECEIVE_UPDATETUTORIAL",
                    payload: { requestTime: requestTime, tutorial: tutorial }
                });
            }).catch(err => {
                dispatch({
                    type: "TUTORIAL_RECEIVE_UPDATETUTORIAL",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "TUTORIAL_REQUEST_UPDATETUTORIAL",
            payload: { requestTime: requestTime },
        });
        return task;
    },
    startTurorial: (key: string) => <TurorialStartPart>{ type: "TUTORIAL_START_PART", payload: { key: key } },
    stopTurorial: (key: string) => <TurorialStopPart>{ type: "TUTORIAL_STOP_PART", payload: { key: key } },
    tutorialNextStep: (key: string, isLast: boolean): AppThunkAction<KnownAction, any> => (dispatch, getState) => {
        dispatch({ type: "TUTORIAL_NEXT_STEP", payload: { key: key, isLast: isLast } });
        if (isLast) {
            let requestTime = new Date().getTime();
            let api = new Api.TutorialApi();
            let task = api.updateTutorial({ name: key, status: "Done" }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(tutorial => {
                    dispatch({
                        type: "TUTORIAL_RECEIVE_UPDATETUTORIAL",
                        payload: { requestTime: requestTime, tutorial: tutorial }
                    });
                }).catch(err => {
                    dispatch({
                        type: "TUTORIAL_RECEIVE_UPDATETUTORIAL",
                        payload: { requestTime: requestTime },
                        error: err
                    });
                });

            dispatch({
                type: "TUTORIAL_REQUEST_UPDATETUTORIAL",
                payload: { requestTime: requestTime },
            });
            return task;
        }
    },
};

const unloadedState: TutorialState = {
    isLoading: false,
    isLoaded: false,
    parts: {},
    isEnabled: true,
    tutorials: [],
    updateState: {
        isLoading: false
    }
};

export const reducer: Reducer<TutorialState> = (state: TutorialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "TUTORIAL_REQUEST_TUTORIALS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "TUTORIAL_RECEIVE_TUTORIALS":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                tutorials: action.error
                    ? state.tutorials
                    : action.payload.tutorials
            };
        case "TUTORIAL_REQUEST_UPDATETUTORIAL":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "TUTORIAL_RECEIVE_UPDATETUTORIAL":
            if (state.updateState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: false
                },
                tutorials: action.error
                    ? state.tutorials
                    : (state.tutorials.some(x => x.tutorialId === action.payload.tutorial.tutorialId)
                        ? state.tutorials
                            .map(x => x.tutorialId === action.payload.tutorial.tutorialId ? action.payload.tutorial : x)
                        : state.tutorials.concat([action.payload.tutorial ]))
            };
        case "TUTORIAL_START_PART":
            if (state.parts[action.payload.key]?.isRunning)
                return state;

            return {
                ...state,
                parts: {
                    ...state.parts,
                    [action.payload.key]: {
                        ...state.parts[action.payload.key],
                        isEnabled: state.parts[action.payload.key]
                            ? state.parts[action.payload.key].isEnabled
                            : true,
                        isRunning: true,
                        step: 1
                    }
                }
            };
        case "TUTORIAL_STOP_PART":
            return {
                ...state,
                parts: {
                    ...state.parts,
                    [action.payload.key]: {
                        ...state.parts[action.payload.key],
                        isRunning: false
                    }
                }
            };
        case "TUTORIAL_NEXT_STEP":
            if (action.payload.isLast)
                return {
                    ...state,
                    parts: {
                        ...state.parts,
                        [action.payload.key]: {
                            ...state.parts[action.payload.key],
                            isRunning: false,
                            isEnabled: false
                        }
                    }
                };

            return {
                ...state,
                parts: {
                    ...state.parts,
                    [action.payload.key]: {
                        ...state.parts[action.payload.key],
                        step: (state.parts[action.payload.key]?.step || 0) + 1
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};