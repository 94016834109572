import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import Moment from 'moment';
import * as Api from '../api/api';
import * as Colors from '../styles/colors';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import {  getSubscription } from '../utils/utils';
import { getText } from '../utils/langTexts';
import CriteriaLocationSelect from "./CriteriaLocationSelect";
import { incotermOptions } from './QuotationInformationForm';
import Button from './Button';
import InputNumber from './InputNumber';

type CriteriaFormProps = ReduxForm.InjectedFormProps<Api.CriteriaModel, {}, string>
    & CriteriaFormOwnProps
    & CriteriaFormExternalProps;

export const containerTypeOptions = (): Array<{ label: string; value: Api.CriteriaModelContainerTypeEnum }> => [
    { label: getText("GenCtrDry"), value: "Dry" },
    { label: getText("GenCtrRefeer"), value: "Rf" },
    { label: getText("GenCtrFlat"), value: "Fl" },
    { label: getText("GenCtrOpenTop"), value: "Ot" },
    { label: getText("GenCtrTankTop"), value: "Tk" }
];

interface CriteriaFormOwnProps {
    criteriaSizeTypes: Array<Api.CriteriaSizeTypeModel>;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    containerType: Api.CriteriaModelContainerTypeEnum;
    subscription: Api.SubscriptionModel;
    dateBegin: Date;
    dateEnd: Date;
    priceByContainer: boolean;
    shipperName: string;
    consigneeName: string;
    isSpotOperation: boolean;
    cbm: number;
    ton: number;
}

interface CriteriaFormExternalProps {
    onSubmit: (values: Api.CriteriaModel) => void;
    resetCriteria: () => void;
    toggleAdvContainer: (value: boolean) => void;
    toggleAdvLocation: (value: boolean) => void;
    toggleAdvDate: (value: boolean) => void;
    advContainerOpened: boolean;
    advLocationOpened: boolean;
    advDateOpened: boolean;
}

const locationRequired = (value: Api.LocationModel) => (!value || !value.locationId) && getText("GenRequired");
const consigneeOrShipper = (value, allValues: Api.CriteriaModel) => (allValues.criteriaAgent
    && !allValues.criteriaAgent.consigneeName
    && !allValues.criteriaAgent.shipperName && getText("GenOneRequired"));
const commentField = FormField.textAreaField(4, 40);
const cargoDescriptionField = FormField.textAreaField(4, 40);
const lclUnitRequired = (value, allValues: Api.CriteriaModel) => (!allValues.cbm
    && !allValues.ton)
    && getText("GenRequired");

const originField = (fields) => <div>
    <CriteriaLocationSelect
    inputKey={"CrtLclOrigin"}
        value={fields.input.value}
        buttonStyle={{ height: 42, width: 42 }}
        inputStyle={{ height: 42 }}
        locationSearchType={"Normal"}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>

const destinationField = (fields) => <div>
    <CriteriaLocationSelect
        inputKey={"CrtLclDestination"}
        value={fields.input.value}
        buttonStyle={{ height: 42, width: 42 }}
        inputStyle={{ height: 42 }}
        locationSearchType={"Normal"}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>

const titleStyle: React.CSSProperties = {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 20
};

class CriteriaForm extends React.Component<CriteriaFormProps, {}> {
    public render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div style={{ marginBottom: 20 }}>
                    <div style={{ ...titleStyle }}>Size</div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{
                                flex: 1,
                                minWidth: 100,
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div style={{ flex: 1 }}>
                                        <ReduxForm.Field name="cbm"
                                            className="form-control"
                                            unit={"CBM"}
                                            unitStyle={{ fontSize: 16, fontWeight: 700 } as React.CSSProperties}
                                            style={{
                                                width: 100,
                                                flex: "0 0 auto",
                                                fontSize: 16
                                            } as React.CSSProperties}
                                            validate={[lclUnitRequired]}
                                            component={FormField.NumberField} />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <ReduxForm.Field name="ton"
                                            className="form-control"
                                            unit={"T"}
                                            unitStyle={{ fontSize: 16, fontWeight: 700 } as React.CSSProperties}
                                            style={{
                                                width: 100,
                                                flex: "0 0 auto",
                                                fontSize: 16
                                            } as React.CSSProperties}
                                            validate={[lclUnitRequired]}
                                            component={FormField.NumberField} />
                                    </div>
                                    <div style={{ fontWeight: 600, marginLeft: 6, marginRight: 6 }}>=</div>
                                    <div style={{ flex: 1 }}>
                                        <InputNumber
                                            className="form-control"
                                            value={Math.max(this.props.cbm, this.props.ton)}
                                            disabled={true}
                                            unit={"WM"}
                                            style={{
                                                width: 100,
                                                flex: "0 0 auto",
                                                fontSize: 16,
                                                backgroundColor: "lightgray"
                                            }}
                                            unitStyle={{ fontSize: 16, fontWeight: 700 }}
                                            onChange={(value) => { }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div style={{ ...titleStyle }}>{getText("CrtPorts")}</div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div>
                                <label>{getText("CrtOrigin")}</label>
                            </div>
                            <div>
                                <ReduxForm.Field name={"origin"}
                                    validate={[locationRequired]}
                                    component={originField} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div>
                                <label>{getText("CrtDestination")}</label>
                            </div>
                            <div>
                                <ReduxForm.Field name={"destination"}
                                    validate={[locationRequired]}
                                    component={destinationField} />
                            </div>
                        </div>
                    </div>
                </div>
                {(!this.props.subscription
                    || this.props.subscription.subscriptionType !== "Agent")
                    && <div style={{ marginBottom: 20 }}>
                    <div style={{ ...titleStyle }}>{getText("CrtAllInIncluding")}</div>
                    <div className="row">
                        <div className='col-md-6'>
                            <ReduxForm.Field name={"loadingCharge"}
                                validate={[]}
                                component={FormField.checkBoxField(getText("CrtLoadingCharges"))} />
                        </div>
                        <div className='col-md-6'>
                            <ReduxForm.Field name={"unLoadingCharge"}
                                validate={[]}
                                component={FormField.checkBoxField(getText("CrtUnLoadingCharges"))} />
                        </div>
                    </div>
                    </div>}
                <div style={{ marginBottom: 20 }}>
                    <div style={{ ...titleStyle }}>{getText("CrtDates")}</div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                            <div>{getText("CrtFromDate")}</div>
                            <div>
                                <ReduxForm.Field name={"dateBegin"}
                                    component={(fields) => <FormField.DatePickerField {...fields}
                                        maxDate={this.props.dateEnd} />} />
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div>{getText("CrtToDate")}</div>
                            <div>
                                <ReduxForm.Field name={"dateEnd"}
                                    component={(fields) => <FormField.DatePickerField {...fields}
                                        maxDate={Moment(this.props.dateBegin).add(93, "days").toDate()}
                                        minDate={this.props.dateBegin} />} />
                            </div>
                        </div>
                    </div>
                </div>
                {(this.props.subscription && this.props.subscription.subscriptionType === "Agent") &&
                    <div style={{  }}>
                        <div className="form-horizontal container-fluid">
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <label className="col-sm-4 control-label">{getText("CrtAgentShipper")}</label>
                                        <div className="col-sm-8">
                                        <ReduxForm.Field
                                                validate={[consigneeOrShipper]}
                                                name="criteriaAgent.shipperName"
                                                disabled={this.props.consigneeName ? true : false}
                                                component={FormField.InputTextField}
                                                type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <label className="col-sm-4 control-label">{getText("CrtAgentConsignee")}</label>
                                        <div className="col-sm-8">
                                        <ReduxForm.Field
                                                validate={[consigneeOrShipper]}
                                                name="criteriaAgent.consigneeName"
                                                disabled={this.props.shipperName ? true : false}
                                                component={FormField.InputTextField}
                                                type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 control-label">{getText("CrtAgentOperation")}</label>
                                <div className="col-sm-3">
                                <ReduxForm.Field
                                        style={{ width: 20, height: 20 }}
                                        validate={[]}
                                        name="criteriaAgent.isSpotOperation"
                                        component={FormField.renderField}
                                        type="radio"
                                        value="true" />
                                    <div style={{ display: "inline-block", verticalAlign: "super", marginLeft: 3 }}>
                                        {" " + getText("CrtAgentSpot")}
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                <ReduxForm.Field
                                        style={{ width: 20, height: 20 }}
                                        validate={[]}
                                        name="criteriaAgent.isSpotOperation"
                                        component={FormField.renderField}
                                        type="radio"
                                        value="false" />
                                    <div style={{ display: "inline-block", verticalAlign: "super", marginLeft: 3 }}>
                                        {" " + getText("CrtAgentRegular")}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 control-label">{getText("CrtAgentVolume")}</label>
                                <div className="col-sm-4">
                                <ReduxForm.Field
                                        validate={[FormField.required]}
                                        name="criteriaAgent.volume"
                                        component={FormField.InputTextField}
                                        type="text" />
                                </div>
                                <div className="col-sm-2" style={{ textAlign: "left", marginLeft: 3 }}>
       
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 control-label">{getText("CrtAgentIncoterm")}</label>
                                <div className="col-sm-4">
                                <ReduxForm.Field className="form-control"
                                        validate={[FormField.required]}
                                        name="criteriaAgent.incoterm"
                                        component={FormField.getSelectField(incotermOptions())} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 control-label">{getText("CrtAgentCargoDescription")}</label>
                                <div className="col-sm-8">
                                <ReduxForm.Field className="form-control"
                                        validate={[FormField.required]}
                                        name="criteriaAgent.CargoDescription"
                                        style={{ maxWidth: "100%" }}
                                        component={cargoDescriptionField} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 control-label">{getText("CrtAgentComment")}</label>
                                <div className="col-sm-8">
                                <ReduxForm.Field className="form-control"
                                        validate={[]}
                                        name="criteriaAgent.comment"
                                        style={{ maxWidth: "100%" }}
                                        component={commentField} />
                                </div>
                            </div>
                        </div>
                    </div>}
                <div style={{ marginTop: 50 }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ flex: "0 0 auto" }}>
                            <Button
                                onClick={(e) => {
                                    this.props.resetCriteria();
                                    if (e.preventDefault) {
                                        e.preventDefault();
                                    }
                                }}>
                                {getText("CrtReset")}
                            </Button>
                        </div>
                        <div style={{ flex: 1 }}>
                        </div>
                        <div style={{ flex: "0 0 auto" }}>
                            <Button
                                className="btn-lg"
                                type={"submit"}
                                style={{
                                    width: 165,
                                    height: 45,
                                    color: "white",
                                    backgroundColor: Colors.baseColors.lightBlue
                                }}
                                disabled={this.props.submitting}>
                                {getText("CrtGo")}
                            </Button>
                            <div className="text-danger" style={{ textAlign: "center" }}>
                                {!this.props.valid && this.props.anyTouched && <span>{getText("CrtGoValidationError")}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

let criteriaFormWrapped = ReduxForm.reduxForm({
    form: 'criteriaLcl',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(CriteriaForm) as any;
const selector = ReduxForm.formValueSelector('criteriaLcl')

export default connect((state: ApplicationState) => {
    return {
        initialValues: {
            ...state.criteria.criteria,
            type: "Lcl",
            lclUnitType: "Wm"
        } as Api.CriteriaModel,
        criteriaSizeTypes: selector(state, "criteriaSizeTypes") || [],
        containerType: selector(state, "containerType"),
        sizeTypes: state.seed.sizeTypes,
        subscription: getSubscription(state.account.currentUser.clientModel),
        dateBegin: selector(state, "dateBegin"),
        dateEnd: selector(state, "dateEnd"),
        priceByContainer: selector(state, "priceByContainer"),
        consigneeName: selector(state, 'criteriaAgent.consigneeName'),
        isSpotOperation: selector(state, 'criteriaAgent.isSpotOperation'),
        shipperName: selector(state, 'criteriaAgent.shipperName'),
        lclUnitType: selector(state, 'lclUnitType'),
        cbm: selector(state, 'cbm'),
        ton: selector(state, 'ton'),
    } as CriteriaFormOwnProps;
})(criteriaFormWrapped) as any as React.ComponentClass<CriteriaFormExternalProps>