import * as React from "react";
import { Guid } from "../utils/utils";
import Throttle from "../utils/throttleHandler";
import InputText from "./InputText";

interface InputNumberProps {
    style?: React.CSSProperties;
    className?: string;
    throttle?: number;
    integer?: boolean;
    disabled?: boolean;
    unit?: string;
    unitStyle?: React.CSSProperties;
    allowEmpty?: boolean;
    value: number;
    onChange: (value: number) => void;
}

interface InputNumberState {
    value: string;
}

export default class InputNumber extends React.Component<InputNumberProps, InputNumberState> {
    private guid: string;

    constructor(props: InputNumberProps) {
        super(props);
        this.state = {
            value: props.value
                ? props.value.toString()
                : "0"
        };
        this.guid = Guid.newGuid();
    }

    handleChange(value: string) {
        let delay = 0;
        if (parseInt(value) <= 0 || value.match(/[,|.]/)) {
           delay = 300
        }
        this.setState({ value: value });
        Throttle.throttle(this.guid, () => {
            this.applyChange(value);
        }, this.props.throttle || delay); //delay ignored if the user wants to put value that is below 0 or a "," / "."

    }

    applyChange(value: string) {
        let valueToUse = value
            ? value.replace(",", ".")
            : value;
        let parsed = this.props.integer
            ? parseInt(valueToUse)
            : parseFloat(valueToUse);
        if (parsed || parsed === 0) {
            this.props.onChange(parsed);
        } else {
            if (this.props.allowEmpty) {
                this.props.onChange(null);
            }
            else {
                this.props.onChange(0);
            }
        }
    }

    componentWillReceiveProps(nextProps: InputNumberProps) {
        let isEmpty = this.props.allowEmpty
            && !nextProps.value
            && nextProps.value !== 0;

        this.setState({
            value: isEmpty
                ? ""
                : (nextProps.value
                    ? nextProps.value.toString()
                        : "0")
        });
    }

    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <InputText
                    type="text"
                    disabled={this.props.disabled}
                    style={{
                        order: 1,
                        flex: 1,
                        minWidth: 0,
                        ...this.props.style
                    }}
                    inputProps={{ style: { ...this.props.style  } }}
                    className={this.props.className}
                    value={this.state.value}
                    onBlur={(e) => this.applyChange(this.state.value)}
                    onChange={(e) => this.handleChange(e.target.value)}
                />
                {this.props.unit &&
                    <div style={{
                        order: 2,
                        flex: "0 0 auto",
                        paddingLeft: 3,
                        paddingRight: 3,
                        ...this.props.unitStyle
                    }}>
                        {this.props.unit}
                    </div>}
            </div>
        );
    }
}