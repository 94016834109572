import * as React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import SendEmail from './SendEmail';
import OfferReminder from './OfferReminder';
import AskBookingByEmailForm from './AskBookingByEmailForm';
import Select from './Select';
import * as SelectionStore from '../store/Selection';
import * as AskBookingStore from '../store/AskBooking';
import { getCarrierOfferKey } from '../utils/utils';
import { getText } from '../utils/langTexts';
import { getSelectedDepart } from '../utils/routeUtils';
import * as Colors from '../styles/colors';


type AskBookingByEmailProps = AskBookingByEmailOwnProps
    & AskBookingStore.AskBookingState
    & typeof AskBookingStore.actionCreators;

interface AskBookingByEmailOwnProps {
    client: Api.ClientModel;
    criteria: Api.CriteriaModel;
    carrierOffer: Api.CarrierOfferModel;
    carrierOfferState: SelectionStore.CarrierOfferState;
    carriers: { [id: number]: Api.CarrierModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
}

const partStyle = { marginBottom: 20 };
const fieldStyle = { marginBottom: 10, marginTop: 10, paddingRight: 50, paddingLeft: 50 };
const innerFieldStyle = { marginBottom: 10, marginTop: 10, paddingRight: 200, paddingLeft: 100, };

const containerStyle = { display: "flex", flexDirection: "row", } as React.CSSProperties;
const innerStyle = { padding: 10 };
const descStyle = { ...fieldStyle, textAlign: "center" };
const inputStyle = { height: 30, backgroundColor: "white", marginBottom: 10, borderRadius: 5, borderColor: "black", borderWidth: 0.5, width: 250 };
const labelStyle = { width: 90, fontFamily: "Calibri", textAlign: "right", marginRight: 10, paddingTop: 7, color: "Colors.baseColors.darkGray" } as React.CSSProperties;
const shortLabelStyle = { ...labelStyle, minWidth: 60 };
const btnsStyle = { ...fieldStyle, textAlign: "center" };
const btnStyle = { backgroundColor: "white", color: Colors.baseColors.lightBlue, borderRadius: 10, borderWidth: 0.5, fontWeight: "bold", flex: 1, height: 30, } as React.CSSProperties;
const selectStyle = { borderRadius: 5, borderWidth: 0.5, borderColor: "black" } as React.CSSProperties;

const askBookingTypes = (): Array<{ label: string, value: AskBookingStore.AskBookingType }> => [
    { label: getText("SlcBookingTypeDirect"), value: "direct" },
    { label: getText("SlcBookingTypeAgent"), value: "byAgent" }
];

class AskBookingByEmail extends React.Component<AskBookingByEmailProps, {}> {
    constructor(props) {
        super(props);
    }

    get selectedDepart(): Api.DepartModel {
        return getSelectedDepart(this.props.carrierOffer, this.props.carrierOfferState);
    }

    handleSendingEmail() {
        ((this.props.requestSendEmail(new Date().getTime()) as any) as Promise<any>).then(value => {
            this.props.closeAskBooking();
        });
    }

    //TODO get carrier url
    public render() {
        return <div >
            <div style={innerStyle}>
                {this.props.step == 0 &&
                    (
                        <div style={fieldStyle}>
                            <OfferReminder
                                carrierOffer={this.props.carrierOffer}
                                carrierOfferState={this.props.carrierOfferState}
                                carriers={this.props.carriers} />
                        </div>
                    )
                }
                {this.props.step === 0 &&
                    (
                        <div>
                            <div style={innerFieldStyle} >
                            <h3 style={{
                                    fontFamily: "Calibri",
                                    color: Colors.baseColors.darkGray,
                                }}>
                                    {getText("SlcBookingOperationalInfo")}</h3>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    <div style={containerStyle}>
                                        <label style={labelStyle}
                                        >{getText("SlcBookingReference")}</label>
                                        <div >
                                            <input type="text"
                                                style={inputStyle}
                                                defaultValue={this.props.carrierOffer.chargeSet && this.props.carrierOffer.chargeSet.carrierReference} disabled />
                                        </div>
                                    </div>
                                    <div style={containerStyle}>
                                        <label style={labelStyle}
                                        >{getText("SlcBookingPol")}</label>
                                        <div >
                                            <input type="text"
                                                style={inputStyle}
                                                defaultValue={this.props.carrierOffer.originPort.name} disabled />
                                        </div>
                                    </div>
                                    <div style={containerStyle} >
                                        <label style={labelStyle}
                                        >{getText("SlcBookingPod")}</label>
                                        <div >
                                            <input type="text"
                                                style={inputStyle}
                                                defaultValue={this.props.carrierOffer.destinationPort.name} disabled />
                                        </div>
                                    </div>
                                    <div style={containerStyle} >
                                        <label style={labelStyle}
                                        >{getText("SlcBookingEtd")}</label>
                                        <div >
                                            <input type="text"
                                                style={inputStyle}
                                                   defaultValue={this.selectedDepart && Moment(this.selectedDepart.etd).format("DD/MM/YYYY")} disabled />
                                        </div>
                                    </div>
                                    <div style={containerStyle} >
                                        <label style={labelStyle}
                                        >{getText("SlcBookingVessel")}</label>
                                        <div>
                                            <input type="text"
                                                style={inputStyle}
                                                   defaultValue={this.selectedDepart && this.selectedDepart.vessel
                                                       ? this.selectedDepart.vessel.name
                                                       : ""} disabled />
                                        </div>
                                    </div>
                                    <div style={containerStyle} >
                                        <label style={labelStyle}
                                        >{getText("SlcBookingContainers")}</label>
                                        <div >
                                            <input type="text"
                                                style={inputStyle}
                                                defaultValue={this.props.criteria.criteriaSizeTypes
                                                    .filter(cst => cst.number > 0)
                                                    .map(cst => cst.number + "X" + this.props.sizeTypes[cst.sizeTypeId].name).join(", ")} disabled />
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 15, }}>
                                        <label style={{ fontFamily: "Calibri", }}>
                                            {getText("SlcBookingYourContact")}</label>
                                        <div style={{ marginTop: 15, }}>
                                            <div style={containerStyle} >
                                                <label style={shortLabelStyle}
                                                >{getText("SlcBookingContactName")}</label>
                                                <div >
                                                    <input type="text"
                                                        style={inputStyle}
                                                        defaultValue={this.props.client.firstName + " " + this.props.client.lastName} disabled />
                                                </div>
                                            </div>
                                            <div style={containerStyle} >
                                                <label style={shortLabelStyle}
                                                >{getText("SlcBookingContactEmail")} </label>
                                                <div >
                                                    <input type="text"
                                                        style={inputStyle}
                                                        defaultValue={this.props.client.account.email} disabled />
                                                </div>

                                            </div>
                                            <div style={containerStyle} >
                                                <label style={shortLabelStyle}
                                                >{getText("SlcBookingContactPhone")}</label>
                                                <div >
                                                    <input type="text"
                                                        style={inputStyle}
                                                        defaultValue={this.props.client.phone1 || this.props.client.phone2} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={innerFieldStyle} >
                                <h3 style={{
                                    fontFamily: "Calibri",
                                    color: Colors.baseColors.darkGray,
                                }}>
                                    {getText("SlcBookingBookingInfo")}</h3>
                                <div style={containerStyle}>
                                    <label style={{
                                        width: 90,
                                        fontFamily: "Calibri",
                                        textAlign: "right",
                                        marginRight: 10,
                                        paddingTop: 7,
                                        color: "Colors.baseColors.darkGray"
                                    }}
                                    >{getText("SlcBookingType")}</label>
                                    <div style={{ paddingTop: 5, borderRadius: 5, borderWidth: 0.5, border: "thin solid "}}>
                                    <Select
                                            options={askBookingTypes()}
                                            value={this.props.type}
                                            onChange={(value) => this.props.selectAskBookingType(value)} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginLeft: 50, marginTop: 20 }}>
                                <div style={{
                                    marginTop: 10,
                                    paddingLeft: 50,
                                }}>
                                <div style={{ float: "right", marginRight: 20, marginBottom: 10 }}>
                                        <button
                                            style={btnStyle}
                                            onClick={(e) => {
                                                this.props.nextStep();
                                                e.preventDefault();
                                            }}>
                                            {getText("GenNextStep")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {this.props.step === 1 &&
                    (
                        <AskBookingByEmailForm
                            onSubmit={(values) => {
                                let model = values as AskBookingStore.AskBookingByEmailModel;
                                this.props.setAskBookingByEmailModel(model);
                                this.props.initEmail();
                                this.props.nextStep();
                            }}
                            prevStep={() => this.props.prevStep()}
                            type={this.props.type} />
                    )
                }
                {this.props.step === 2 &&
                    (
                        <SendEmail
                            isLoading={this.props.isLoading}
                            backLabel={getText("GenPrevStep")}
                            to={this.props.to}
                            onToUpdate={this.props.updateTo}
                            cc={this.props.cc}
                            onCcUpdate={this.props.updateCc}
                            subject={this.props.subject}
                            onSubjectUpdate={this.props.updateSubject}
                            content={this.props.content}
                            onContenttUpdate={this.props.updateContent}
                            send={() => this.handleSendingEmail()}
                            goBack={() => this.props.prevStep()}
                        />
                    )
                }
            </div>
        </div>
    }
}

export default connect((state: ApplicationState) => ({
    ...state.askBooking,
    carriers: state.seed.carriers,
    sizeTypes: state.seed.sizeTypes,
    client: state.account.currentUser.clientModel,
    criteria: state.criteria.criteria,
    carrierOffer: state.selection.selection.carrierOffers
        .find(co => getCarrierOfferKey(co) === state.askBooking.carrierOfferId),
    carrierOfferState: state.selection.carrierOfferStates[state.askBooking.carrierOfferId]
} as any), AskBookingStore.actionCreators)(AskBookingByEmail) as any