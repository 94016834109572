import * as React from 'react';
import * as Api from '../api/api';
import { getText } from '../utils/langTexts';
import Tooltip from "./Tooltip";

interface ChargeSetAvailabiltyProps {
    carrierOffer: Api.CarrierOfferModel;
    criteria: Api.CriteriaModel;
    sizeTypes: {[id: number]: Api.SizeTypeModel};
}

type Availability = "None" | "NotAvailable" | "FewAvailable" | "Available";

export default class ChargeSetAvailabilty extends React.Component<ChargeSetAvailabiltyProps, {}> {

    get ratesAvailabiltys(): Array<Api.RatesAvailabilityModel> {
        return this.props.carrierOffer.ratesAvailabilitys
            .filter(x => x.status !== "Allocation"
                && this.props.criteria.criteriaSizeTypes
                    .some(y => y.number > 0
                        && (!x.sizeTypeId || y.sizeTypeId == x.sizeTypeId)));
    }

    get Availability(): Availability {
        if (this.ratesAvailabiltys.length === 0)
            return "None";

        if (this.ratesAvailabiltys.length !== 0
            && this.ratesAvailabiltys.some(x => x.status === "NotAvailable"))
            return "NotAvailable";

        if (this.ratesAvailabiltys.length !== 0
            && this.ratesAvailabiltys.some(x => x.status === "FewAvailable"))
            return "FewAvailable";

        return "Available";
    }
    
    get ratesAvailabilityJsx(): JSX.Element {
        return <div>
            {this.Availability == "None"
            && <span style={{ fontStyle: "italic" }}></span>}
            {this.Availability == "NotAvailable"
            && getText("GenRatesAvailabiltyNotAvailable")}
            {this.Availability == "FewAvailable"
            && getText("GenRatesAvailabiltyFewAvailable")}
            {this.Availability == "Available"
            && getText("GenRatesAvailabiltyAvailable")}
        </div>;
    }

    public render() {
        return (
            <div>
                {this.props.carrierOffer.ratesAvailabilitys
                && this.props.carrierOffer.ratesAvailabilitys
                    .some(x => x.containerLeft || x.containerLeft === 0)
                && <Tooltip placement={"right"} overlay={<div>
                    {this.props.carrierOffer.ratesAvailabilitys
                        .filter(x => x.sizeTypeId && (x.containerLeft || x.containerLeft === 0))
                        .map(ra => <div key={ra.ratesAvailabilityId}>
                            {this.props.sizeTypes[ra.sizeTypeId].name}: {ra.containerLeft}
                        </div>)}
                    {this.props.carrierOffer.ratesAvailabilitys
                            .filter(x => !x.sizeTypeId && (x.containerLeft || x.containerLeft === 0))
                            .map(ra => <div key={ra.ratesAvailabilityId}>
                                {getText("GenRatesAvailabiltyUnits")}: {ra.containerLeft}
                            </div>)}

                </div>}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {this.ratesAvailabilityJsx}<div>*</div>
                    </div>
                </Tooltip>}
                {this.props.carrierOffer.ratesAvailabilitys
                && !this.props.carrierOffer.ratesAvailabilitys
                    .some(x => x.containerLeft|| x.containerLeft === 0)
                && this.ratesAvailabilityJsx}
            </div>
        );
    }
}