import React from "react";
import {PriceListCompanyModel, useListPriceListByPeriodQuery} from "../api/rtkApi";
import Loader from "./Loader";
import Table, {Column} from "./Table";
import {getText} from "../utils/langTexts";
import {getFirmName} from "../utils/utils";
import * as Colors from "../styles/colors";

const getColumns = (): Array<Column<PriceListCompanyModel>> => {
    return [
        {
            header: "Price lists",
            id: 'company',
            accessor: (d: PriceListCompanyModel) => getFirmName(d.company),
        },
        {
            header: "Price lists",
            id: 'priceListCount',
            accessor: (d: PriceListCompanyModel) => d.priceListCount,
        },
        {
            header: "Price lists max",
            id: 'priceListMax',
            accessor: (d: PriceListCompanyModel) => d.priceListMax,
        }
    ];
}

export function PriceListStatus({}: {}) {

    const {
        data: priceList,
        isLoading: priceListIsLoading,
        isFetching: priceListIsFetching
    } = useListPriceListByPeriodQuery({ period: "Month" });
    
    if(priceListIsLoading){
        return (<Loader isVisible={true} />);
    }



    return (<div
        style=
        {
            {
                color: Colors.baseColors.lightBlue,
                padding:"10px"
            }
        }
        >
        {getText("MdtPlStateInformation", {
            currPl: priceList.priceListCount,
            contractedPl: priceList.priceListMax,

        }) }
   
    </div>);
}