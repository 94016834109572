import * as React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
const StyledCheckbox = styled(Checkbox)({
  
});

const StyledFormControlLabel = styled(FormControlLabel)({

});


interface CheckBoxProps {
    label?: string;
    value: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
    style?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    size?: "small" | "medium";
    labelPlacement?: "start" | "end";
}
interface CheckBoxState {
}

export default class CheckBox extends React.Component<CheckBoxProps, CheckBoxState> {

    constructor(props) {
        super(props)
    }

    public render() {
        if (this.props.label) {
            return (
                <StyledFormControlLabel
                    style={{ marginBottom: 0 }}
                    labelPlacement={this.props.labelPlacement || "start"}
                    label={<Typography onClick={(e) => {
                        e.stopPropagation();
                    }}
                        style={{ ...this.props.labelStyle }}>{this.props.label}</Typography>}
                    control={<StyledCheckbox
                        color={"primary"}
                        size={this.props.size || "medium"}
                        style={{ ...this.props.style }}
                        checked={this.props.value ? true : false}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={(e, checked) => {
                            this.props.onChange(checked);
                        }} />}>
                </StyledFormControlLabel>
            );
        } else {
            return (
                <StyledCheckbox
                    color={"primary"}
                    size={this.props.size || "medium"}
                    style={{ ...this.props.style }}
                    checked={this.props.value ? true : false}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onChange={(e, checked) => {
                        this.props.onChange(checked);
                        e.preventDefault();
                        e.stopPropagation();
                    }} />
            );
        }
    }
}