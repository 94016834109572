export const baseColors = {
    darkGray: "#6F6F6E",
    lightBlue: "#00AFD5",
    lightGray: "#D4D4D3",
    veryLightGray: "#F0F0F0",
    blueRaw: "#c4ecf5"
}

//TODO DELETE ALL OF THIS BELOW
export const frontColors = {
    color1: "#6F6F6E", //dark grey
    color2: "#00AFD5", //blue
    color3: "#66ABD8", //blue
    color4: "#4BB375", //green
    color5: "#DA1F27" //red
};


export const bgColors = {
    color1: "#FFFFFF",
    color2: "#6F6F6E", //dark gray
    color3: "#9D9D9B", //gray
    color4: "#DFDFDE", //light gray
    color5: "#0768A7", //dark blue
    color6: "#1D80C1", //blue
    color7: "#90C7EA" //light blue
}

export const quotColors = {
    titleColor: { backgroundColor: "#1F4E78", color: "#FFFFFF" },
    subTitleColor: { backgroundColor: "#9CC3E6", color: "#333333" },
    mainRowColor: { backgroundColor: "#BCD6EE", color: "#333333" },
    subRowColor: { backgroundColor: "#D7E7F5", color: "#333333" },
    contentRowColor: { backgroundColor: "#FFFFFF", color: "#333333" },
}

export const colors = {
    main1: { color: frontColors.color1, backgroundColor: bgColors.color1 }, //default
    main2: { color: frontColors.color2, backgroundColor: bgColors.color2 }, //header
    main3: { color: frontColors.color3, backgroundColor: bgColors.color1 }, //prices
    main4: { color: frontColors.color4, backgroundColor: bgColors.color1 }, //operational
    main5: { color: frontColors.color5, backgroundColor: bgColors.color1 }, //warnings
    main6: { color: frontColors.color2, backgroundColor: bgColors.color5 }, //Table head
    main7: { color: frontColors.color1, backgroundColor: bgColors.color4 }, //selection criteria
    main8: { color: frontColors.color3, backgroundColor: bgColors.color5 }, //Some icons
    main9: { color: frontColors.color2, backgroundColor: bgColors.color6 }, //Btn sizeTypes
}

export const revertColors = {
    main1: { color: colors.main1.backgroundColor, backgroundColor: colors.main1.color },
    main2: { color: colors.main2.backgroundColor, backgroundColor: colors.main2.color },
    main3: { color: colors.main3.backgroundColor, backgroundColor: colors.main3.color },
    main4: { color: colors.main4.backgroundColor, backgroundColor: colors.main4.color },
    main5: { color: colors.main5.backgroundColor, backgroundColor: colors.main5.color },
    main6: { color: colors.main6.backgroundColor, backgroundColor: colors.main6.color },
    main7: { color: colors.main7.backgroundColor, backgroundColor: colors.main7.color },
}

