import * as React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as HistoryStore from '../store/History';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import Loader from './Loader';
import DatePicker from './DatePicker';
import Table, { Column } from './Table';
import { getLocationName } from '../utils/utils';
import { NavLink } from 'react-router-dom';
import Button from './Button';

type HistorySearchCriteriaProps = HistorySearchCriteriaOwnProps
    & HistoryStore.SearchCriteriaState
    & typeof HistoryStore.actionCreators;

interface HistorySearchCriteriaOwnProps {
    sizeTypes: { [id: number]: Api.SizeTypeModel };
}

const getColumns = (props: HistorySearchCriteriaProps): Array<Column<Api.CriteriaModel>> => {
    return [
        {
            header: getText("HstSearchCriteriasDate"),
            id: 'creationDate',
            accessor: (d: Api.CriteriaModel) => d.creationDate,
            cell: (d: any) => <div>
                {Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: getText("HstSearchCriteriasOrigin"),
            id: 'origin',
            accessor: (d: Api.CriteriaModel) => getLocationName(d.origin),
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("HstSearchCriteriasDestination"),
            id: 'destination',
            accessor: (d: Api.CriteriaModel) => getLocationName(d.destination),
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("HstSearchCriteriasFromDate"),
            id: 'fromdate',
            accessor: (d: Api.CriteriaModel) => d.dateBegin,
            cell: (d: any) => <div>
                {Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: getText("HstSearchCriteriasToDate"),
            id: 'toDate',
            accessor: (d: Api.CriteriaModel) => d.dateEnd,
            cell: (d: any) => <div>
                {Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: getText("HstSearchCriteriasContainers"),
            id: 'containers',
            accessor: (d: Api.CriteriaModel) => d.criteriaSizeTypes
                .filter(cst => 0 < cst.number)
                .map(cst => cst.number + "x" + props.sizeTypes[cst.sizeTypeId].name).join(" "),
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: '',
            id: 'actions',
            accessor: (d: Api.CriteriaModel) => d.code,
            cell: (d: any) => {
                return <div>
                    <NavLink className={"navlink action"} to="" //"to" need to be empty only the style of the navlink is used here. The onlick handle the page switch
                        onClick={(e) => {
                            props.goToSelection(d);
                            e.preventDefault();
                        }}>
                        {getText("HstSearchCriteriasGoToSelection")}
                    </NavLink>
                </div>
            }
        },
    ];
}

const searchFieldStyle = { marginRight: 20 }
const searchContainerStyle = { marginBottom: 20 }

class HistorySearchCriteria extends React.Component<HistorySearchCriteriaProps, {}> {

    onPropsUpdate(props: HistorySearchCriteriaProps) {
        if (new Date().getTime() - this.props.requestTime > 1000 * 60 * 10) {
            this.props.requestSearchCriterias(new Date().getTime());
        }
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: HistorySearchCriteriaProps) {
        this.onPropsUpdate(nextProps);
    }

    public render() {
        return (
            <div>
                <h2>{getText("HstSearchCriteria")}</h2>
                <div style={searchContainerStyle}>
                    <form className="form-inline"
                        onSubmit={(e) => {
                            this.props.requestSearchCriterias(new Date().getTime());
                            e.preventDefault();
                        }}>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label">{getText("HstSearchCriteriaFilterFromDate")}</label>
                            <div style={{ display: "inline-block" }}>
                                <DatePicker
                                    value={this.props.searchCriteriaHistory.fromDate}
                                    onChange={(date) => this.props.updateSearchCriteriaDateFrom(date)}
                                />
                            </div>
                        </div>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <Button
                                style={{ ...searchFieldStyle, }}
                                type="submit"
                                className="btn">{getText("HstSearchCriteriaFilterSearch")}</Button>
                        </div>
                    </form>
                </div>
                <div>
                    <Table
                        tableKey={"historyCriteria"}
                        data={this.props.criterias}
                        columns={getColumns(this.props)}
                        rowsPerPage={10}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        showPagination={true}
                        noDataText="No data, please use the filter above"
                    />
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.history.searchCriteriaState,
    sizeTypes: state.seed.sizeTypes
} as HistorySearchCriteriaProps), HistoryStore.actionCreators)(HistorySearchCriteria) as any