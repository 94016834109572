import * as React from 'react';
import * as Api from '../api/api';
import Moment from 'moment';
import * as SelectionState from '../store/Selection';
import { getText } from '../utils/langTexts';
import { getSelectedDepart } from '../utils/routeUtils';
import * as Colors from '../styles/colors';


interface OfferReminderProps {
    style?: any;
    carrierOffer: Api.CarrierOfferModel;
    carrierOfferState: SelectionState.CarrierOfferState;
    carriers: { [id: number]: Api.CarrierModel };
}

interface OfferReminderState {

}

export default class OfferReminder extends React.Component<OfferReminderProps, OfferReminderState> {
    public render() {
        let selectedDepart = getSelectedDepart(this.props.carrierOffer, this.props.carrierOfferState);
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                ...this.props.style
            }}>

                <div style={{
                    textAlign: "center",
                    flex: "0 0 auto",
                    padding: "0px 15px",
                }}>
                    <img height='35'
                        src={'/images/carriers/' + this.props.carriers[this.props.carrierOffer.carrierId].code.toLowerCase() + '.png'} />
                </div>
                <div style={{
                    textAlign: "center",
                    flex: "0 0 auto",
                    padding: "0px 15px",
                    color: Colors.baseColors.darkGray,
                    fontFamily: "Calibri",
                    fontWeight: "bold",
                }}>
                    {this.props.carriers[this.props.carrierOffer.carrierId].name}
                </div>
                <div style={{
                    textAlign: "center",
                    color: Colors.baseColors.lightBlue,
                    fontWeight: "bold",
                    fontFamily: "Calibri",
                    flex: 1
                }}>
                    {getText("SlcOfferTitle", {
                        origin: this.props.carrierOffer.originPort.name,
                        etd: selectedDepart ? Moment(selectedDepart.etd).format("DD/MM/YYYY") : "N/A",
                        destination: this.props.carrierOffer.destinationPort.name,
                        eta: selectedDepart ? Moment(selectedDepart.eta).format("DD/MM/YYYY") : "N/A"
                    })}
                </div>
            </div>
        );
    }
}