import * as React from "react";
import * as Api from "../api/api";
import * as FormField from './FormField';
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import * as ReduxForm from 'redux-form';
import LocationSelect from "./LocationSelect";
import { getPort } from "../utils/utils";
import Button from "./Button";

type RequestStatsPriceFormProps = ReduxForm.InjectedFormProps<{}, {}, string>
    & RequestStatsPriceFormExternalProps
    & RequestStatsPriceFormOwnProps;

interface RequestStatsPriceFormExternalProps {
    onSubmit: (values: Api.RequestStatsPriceModel) => Promise<any>;
}

interface RequestStatsPriceFormOwnProps {
}

const originPortField = (fields) => <div>
    <LocationSelect
        inputKey={"StatsPriceOrigin"}
        value={fields.input.value}
        locationSearchType={"OnlyPorts"}
        inputStyle={{ height: 35 }}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>

const destinationPortField = (fields) => <div>
    <LocationSelect
        inputKey={"StatsPriceDestination"}
        value={fields.input.value}
        locationSearchType={"OnlyPorts"}
        inputStyle={{ height: 35 }}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>

class RequestStatsPriceForm extends React.Component<RequestStatsPriceFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} resetOnSubmit={false}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <label style={{ flex: "0 0 auto", marginRight: 3 }}>POL: </label>
                    <div style={{ flex: "1" }}>
                        <ReduxForm.Field name={"origin"}
                            validate={[ FormField.required ]}
                            component={originPortField} />
                    </div>
                    <label style={{ flex: "0 0 auto", marginRight: 3 }}>POD: </label>
                    <div style={{ flex: "1" }}>
                        <ReduxForm.Field name={"destination"}
                            validate={[FormField.required]}
                            component={destinationPortField} />
                    </div>
                    <Button
                        style={{ flex: "0 0 auto" }}
                        disabled={this.props.submitting}
                        type={"submit"}>
                        Search
                    </Button>
                </div>
            </FormField.BaseForm>
        );
    }
}

export const requestStatsPriceFormName = "requestStatsPriceForm";

let form = ReduxForm.reduxForm({
    form: requestStatsPriceFormName,
    destroyOnUnmount: false,
    enableReinitialize: false,
})(RequestStatsPriceForm) as any;
const selector = ReduxForm.formValueSelector(requestStatsPriceFormName)

export default connect((state: ApplicationState) => {
    let criteriaLoaded = state.selection.criteriaLoaded;
    let pol = criteriaLoaded
        ? getPort(state.selection.criteriaLoaded.origin)
        : undefined;
    let pod = criteriaLoaded
        ? getPort(state.selection.criteriaLoaded.destination)
        : undefined;

    return {
        initialValues: {
            origin: pol,
            destination: pod
        } as Api.RequestStatsPriceModel
    } as RequestStatsPriceFormOwnProps;
})(form) as any as React.ClassicComponentClass<RequestStatsPriceFormExternalProps>