import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getText } from '../utils/langTexts';
import * as Colors from '../styles/colors';
import Button from './Button';


type ModeratorDocumentInfoEditFormProps = ReduxForm.InjectedFormProps<{}, {}, string> & ModeratorDocumentInfoEditFormOwnProps;

interface ModeratorDocumentInfoEditFormOwnProps {
    initialValues: Api.OfferInformationModel;
    handleSubmit: (values: any) => void;
    storedValue: any;
    submitting: boolean;
}
interface ModeratorState {
    inputValue: string;
    zone: string;
    hideDelete: boolean;
}

export const infoLevelOptions: Array<{ label: string, value: Api.OfferInformationModelLevelEnum }> = [
    { label: "Low", value: "Low" }, { label: "Normal", value: "Normal" }, { label: "High", value: "High" }];
const textAreaField = FormField.textAreaField(4, 50);

class ModeratorDocumentInfoEditForm extends React.Component<ModeratorDocumentInfoEditFormProps, ModeratorState, {}> {
    constructor(props) {
        super(props);
        const construct = this.InputValue();
        this.props.array.insert("delete", 0, true);
        this.state = {
            inputValue: construct.inputVal,
            zone: construct.zone,
            hideDelete: construct.hideDelete
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeZone = this.handleChangeZone.bind(this);
    }
    InputValue() {
        if (this.props.initialValues.content) {
            return { inputVal: this.props.initialValues.content, zone: this.props.initialValues.level, hideDelete: false }
        } else if (this.props.storedValue.documentInfos) {
            if (this.props.storedValue.documentInfos.find(di => di.source == "Client").enabled) {
                Object.assign(this.props.initialValues, this.props.storedValue.documentInfos.find(di => di.source == "Client"))
                return { inputVal: this.props.storedValue.documentInfos.find(di => di.source == "Client").content, zone: this.props.storedValue.documentInfos.find(di => di.source == "Client").level, hideDelete: false }

            }
        }
        return { inputVal: "", zone: 0, hideDelete: true}
    }
    handlesub() {
        Object.assign(this.props.initialValues, { ...this.props.initialValues, delete: false })
        this.props.initialValues.content = this.state.inputValue
        if (infoLevelOptions.find(str => str.label == this.state.zone)) {
            this.props.initialValues.level = infoLevelOptions.find(str => str.label == this.state.zone).value
        }
        this.setState({ hideDelete: false })
        this.props.change("delete", false)
    }
    handleChange(event) {
        this.setState({
            inputValue: event
        })
    }
    handleChangeZone(event) {
        this.setState({
           zone: event
        })
    }
    handleDelete() {
        this.setState({ hideDelete: true });
        this.props.change("content", "")
        this.props.change("level", 0)
        this.props.change("delete", true)
    }
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-horizontal container-fluid">
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label"> </label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            validate={[FormField.requiredOrZero]}
                            name="level"
                            component={FormField.getSelectField(infoLevelOptions)}
                            onChange={this.handleChangeZone}
                            type="text"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">{getText("MdtRatesDocumentsEditContent")} </label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            validate={[]}
                            name="content"
                            component={textAreaField}
                            type="text"
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label" ></label>
                    <div className="col-sm-9">
                        <Button type="submit"
                            onClick={(e) => { this.handlesub() }}
                            style={{ color: "white", backgroundColor: Colors.baseColors.lightBlue, marginRight: "1em" }}>
                            {getText("GenSaveChanges")}
                        </Button>
                        <Button
                            type="submit"
                            onClick={(e) => { this.handleDelete() }}
                            style={{ color: Colors.baseColors.lightBlue }}
                            hidden={this.state.hideDelete}>
                            {getText("MdtNewsEditDelete")}
                        </Button>
                        
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let ModeratorDocumentInfoEditFormWrapped = ReduxForm.reduxForm({
    form: 'moderatorEditDocumentInfo',
    enableReinitialize: false
})(ModeratorDocumentInfoEditForm) as any;

export default connect((state: ApplicationState) =>
    ({
        //...state.workDocuments,
        initialValues: state.workDocuments.workDocumentses
            .find(ro => ro.workDocumentsId === state.workDocuments.editState.workDocumentsId)
            .offerInformations.find(di => di.source == "Client") ? state.workDocuments.workDocumentses
                .find(ro => ro.workDocumentsId === state.workDocuments.editState.workDocumentsId)
                .offerInformations.find(di => di.source == "Client") : {}, 
        storedValue: state.workDocuments.workDocumentses
            .find(ro => ro.workDocumentsId === state.workDocuments.editState.workDocumentsId)
    }))(ModeratorDocumentInfoEditFormWrapped) as any