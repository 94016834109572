import * as React from 'react';
import * as ReduxForm from 'redux-form';
import Select from './Select';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getQuotationContactName, getDefaultHeaders, scrollToAppTop } from '../utils/utils';
import { getText } from '../utils/langTexts';
import QuotationFfContactDialog from "./QuotationFfContactDialog";
import QuotationClientContactDialog from "./QuotationClientContactDialog";
import { QuotationMode } from '../store/Quotation';
import * as Colors from '../styles/colors';
import Tooltip from './Tooltip';
import Throttle from '../utils/throttleHandler';
import * as _ from 'lodash';
import { Language } from "@material-ui/icons";
import ExpansionPanel from "./ExpansionPanel";
import Autocomplete from "./Autocomplete";

type QuotationInformationFormProps = ReduxForm.InjectedFormProps<Api.QuotationModel, {}, string>
    & QuotationInformationFormOwnProps
    & QuotationInformationFormExternalProps;

interface QuotationInformationFormOwnProps {
    quotationContacts: Array<Api.QuotationContactModel>;
    clientMoreOptions: boolean;
    pol: string;
    pod: string;
    originInfo: string;
    destinationInfo: string;
    hasChargeSet: boolean;
}

interface QuotationInformationFormExternalProps {
    onSubmit: (values: Api.QuotationModel) => void;
    mode: QuotationMode;
    carrierScheduleState: Api.CarrierModelScheduleStateEnum;
    isDownloadingDocument: boolean;
    requestDownloadDocument: () => Promise<any>;
    openClientMoreOptions: () => void;
    closeClientMoreOptions: () => void;
    requestGoToCarrierWebSite: () => void;
    updateIncotermLocation: (value: string) => void;
    quotationSelectContactClient: (value: Api.QuotationContactModel) => void;
}

export const contactTypeOptions = () => [
    { label: getText("QtnClientTypeClient"), value: "Client" },
    { label: getText("QtnClientTypeProspect"), value: "Prospect" },
    { label: getText("QtnClientTypeAgent"), value: "Agent" },
    { label: getText("QtnClientTypeNone"), value: "None" },
];

const frequencyOptions = (): Array<{ label: string; value: Api.QuotationModelFrequencyEnum }> => [
    { label: getText("QtnFrequencyWeekly"), value: "Weekly" },
    { label: getText("QtnFrequencyTwiceAWeek"), value: "TwiceAWeek" },
    { label: getText("QtnFrequencyMonthly"), value: "Monthly" }];


export const incotermOptions = () => [
    { label: "------", value: null }
    , { label: "EXW", value: "EXW" }, { label: "FCA", value: "FCA" }, { label: "FAS", value: "FAS" }
    , { label: "FOB", value: "FOB" }, { label: "CFR", value: "CFR" }, { label: "CIF", value: "CIF" }
    , { label: "CPT", value: "CPT" }, { label: "CIP", value: "CIP" }, { label: "DAT", value: "DAT" }
    , { label: "DAP", value: "DAP" }, { label: "DDP", value: "DDP" }
];

type incotermLocation = "Pol" | "Pod"
    | "Origin" | "Destination";
export const incotermLocationLink: Array<{ incoterm: string; location: incotermLocation }> = [
    { incoterm: "EXW", location: "Origin" },
    { incoterm: "FOB", location: "Pol" },
    { incoterm: "CFR", location: "Pod" },
    { incoterm: "CIF", location: "Pod" },
    { incoterm: "DDP", location: "Destination" },
    { incoterm: "DAP", location: "Destination" },
]

const showCarrierField = FormField.checkBoxField("Show the carrier's name");


const labelStyle: React.CSSProperties = {
    minWidth: 160,
    marginRight: 10,
    textAlign: "right",
    marginTop: 10,
};

const labelBigStyle: React.CSSProperties = {
    minWidth: 200,
    marginRight: 10,
    textAlign: "right",
    marginTop: 8,
};

const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    marginBottom: 8,
    width: 450,
    height: 32,
};

const reduxFormFieldStyle: React.CSSProperties = {
    minWidth: 350,
    height: 30,
    borderRadius: 5,
    borderWidth: 0.5,
};

const containReduxFormStyle: React.CSSProperties = {
    borderRadius: 5,
    borderWidth: 0.5,
    width: 350,
};
class QuotationInformationForm extends React.Component<QuotationInformationFormProps, {}> {
    componentDidMount() {
        scrollToAppTop();
    }

    public render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <h3>{getText("QtnYourContact")}</h3>
                <div style={{
                    marginLeft: 200,
                    display: "flex",
                    flexDirection: "column",
                }}>

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <div style={{
                            borderColor: Colors.baseColors.lightGray,
                            borderRadius: 5,
                            border: "thin solid",
                            width: 320,
                            marginLeft: 10,
                        }} >
                            <ReduxForm.Field
                                name="ffContactId"
                                component={
                                    (field: any) => <Select
                                        style={{
                                            minWidth: 305,
                                            marginLeft: 10,
                                        }}
                                        standard={true}
                                        options={[{ label: "Okargo contact", value: 0 }]
                                            .concat(this.props.quotationContacts
                                                .filter(x => x.type === "FreightForwarder")
                                                .map(x => ({ label: getQuotationContactName(x), value: x.quotationContactId })))}
                                        value={field.input.value}
                                        onChange={(value) => field.input.onChange(value)}
                                    />
                                } />
                        </div>
                        <div>
                            <QuotationFfContactDialog />
                        </div>
                    </div>

                </div>
                <h3>{getText("QtnClientInfo")}</h3>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 40,
                }}>

                    <div style={containerStyle}>
                        <label style={labelStyle}>
                            {getText("QtnClientCompany")}
                        </label>
                        <div style={{
                            borderColor: Colors.baseColors.lightGray,
                            height: 32,
                            border: "thin",
                            minWidth: 350,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            <ReduxForm.Field
                                validate={[]}
                                name="clientContact.company"
                                style={{ minWidth: 320, borderBlockColor: "white", height: 30, }}
                                component={QuotationClientAutocomplete}
                                props={{
                                    onContactSelect: (value) => this.props.quotationSelectContactClient(value)
                                }} />
                            <QuotationClientContactDialog />
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={labelStyle}>{getText("QtnClientAgency")}</label>
                        <div style={{
                            borderColor: Colors.baseColors.lightGray,
                            borderRadius: 5,
                            border: "thin",
                            width: 358,
                        }}>
                            <ReduxForm.Field
                                validate={[]}
                                name="clientContact.agency"
                                style={{ minWidth: 350, borderBlockColor: "white", height: 30, borderRadius: 5, borderWidth: 0.5 }}
                                component={FormField.renderField}
                                type="text" />
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={labelStyle}>
                            {getText("QtnClientFirstName")}</label>
                        <div >
                            <ReduxForm.Field
                                validate={[]}
                                name="clientContact.firstName"
                                style={{
                                    minWidth: 350,
                                    height: 30,
                                    borderRadius: 5,
                                    borderWidth: 0.5,
                                }}
                                component={FormField.renderField}
                                type="text" />
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={labelStyle}>
                            {getText("QtnClientLastName")}</label>
                        <div >
                            <ReduxForm.Field
                                validate={[]}
                                name="clientContact.lastName"
                                style={{
                                    minWidth: 350,
                                    height: 30,
                                    borderRadius: 5,
                                    borderWidth: 0.5,
                                }}
                                component={FormField.renderField}
                                type="text" />
                        </div>
                    </div>

                    <div style={containerStyle}>
                        <label style={{
                            minWidth: 160,
                            marginRight: 10,
                            textAlign: "right",
                        }}>
                            {getText("QtnClientType")}</label>

                        <div style={{
                            borderColor: Colors.baseColors.lightGray,
                            borderRadius: 5,
                            border: "thin solid",
                            minWidth: 354,
                        }} >
                            <div style={{
                                marginLeft: 10,
                            }}>
                                <ReduxForm.Field
                                    validate={[FormField.required]}
                                    name="clientContact.clientType"
                                    style={{
                                        Width: 350,
                                        borderRadius: 5,
                                        borderWidth: 0.5,
                                    }}
                                    component={FormField.getSelectField(contactTypeOptions(), true)} />
                            </div>
                        </div>
                    </div>

                    <ExpansionPanel expanded={this.props.clientMoreOptions}
                        onChange={(v) => { }}
                        head={<div style={{ width: 358, marginLeft: 170 }}>
                            <div style={{ textAlign: "center", width: "100%", }}>
                                <button
                                    type="button"
                                    style={{
                                        backgroundColor: "white",
                                        color: Colors.baseColors.lightBlue,
                                        borderRadius: 10,
                                        borderWidth: 0.5,
                                        width: 70,
                                        height: 25,
                                        alignItems: "center",
                                        fontWeight: "bold",
                                    }}
                                    onClick={(e) => {
                                        if (this.props.clientMoreOptions)
                                            this.props.closeClientMoreOptions();
                                        else
                                            this.props.openClientMoreOptions();
                                    }}>
                                    <div style={{ textAlign: "center", width: "100%", fontWeight: "bold", fontFamily: "Calibri" }}> More </div>
                                    {!this.props.clientMoreOptions && <i ></i>}
                                    {this.props.clientMoreOptions && <i ></i>}
                                </button>
                            </div>
                        </div>}>
                        <div style={{
                            marginTop: 30,
                            marginLeft: 40,
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={{
                                marginTop: 10,
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: 8,
                                width: 450,
                            }}>
                                <label style={labelStyle}>{getText("QtnClientAddress")}</label>
                                <div >
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="clientContact.street1"
                                        style={reduxFormFieldStyle}
                                        display="block"
                                        component={FormField.renderField}
                                        type="text" />
                                </div>
                            </div>
                            <div style={containerStyle}>
                                <label style={labelStyle}></label>
                                <div>
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="clientContact.street2"
                                        style={reduxFormFieldStyle}
                                        display="block"
                                        component={FormField.renderField}
                                        type="text" />
                                </div>
                            </div>
                            <div style={containerStyle}>
                                <label style={labelStyle}></label>
                                <div >
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="clientContact.street3"
                                        style={reduxFormFieldStyle}
                                        display="block"
                                        component={FormField.renderField}
                                        type="text" />
                                </div>
                            </div>


                            <div style={containerStyle}>
                                <label style={labelStyle}>{getText("QtnClientZipCode")}</label>
                                <div >
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="clientContact.postalCode"
                                        style={{
                                            maxWidth: 100,
                                            height: 30,
                                            borderRadius: 5,
                                            borderWidth: 0.5,
                                        }}
                                        component={FormField.renderField}
                                        type="text" />
                                </div>
                                <label style={{
                                    minWidth: 60,
                                    marginRight: 10,
                                    textAlign: "right",
                                    marginTop: 10,
                                }}>{getText("QtnClientCity")}</label>
                                <div >
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="clientContact.city"
                                        style={{
                                            minWidth: 175,
                                            height: 30,
                                            borderRadius: 5,
                                            borderWidth: 0.5,
                                        }}
                                        component={FormField.renderField}
                                        type="text" />
                                </div>
                            </div>
                            <div style={containerStyle}>
                                <label style={labelStyle}>{getText("QtnClientCountry")}</label>
                                <div >
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="clientContact.country"
                                        style={reduxFormFieldStyle}
                                        component={FormField.renderField}
                                        type="text" />
                                </div>
                            </div>
                            <div style={containerStyle}>
                                <label style={labelStyle}>{getText("QtnClientPhone1")}</label>
                                <div >
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="clientContact.phone1"
                                        style={reduxFormFieldStyle}
                                        component={FormField.renderField}
                                        type="text" />
                                </div>
                            </div>
                            <div style={containerStyle}>
                                <label style={labelStyle}>{getText("QtnClientPhone2")}</label>
                                <div >
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="clientContact.phone2"
                                        style={reduxFormFieldStyle}
                                        component={FormField.renderField}
                                        type="text" />
                                </div>
                            </div>
                            <div style={containerStyle}>
                                <label style={labelStyle}>{getText("QtnClientEmail")}</label>
                                <div >
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="clientContact.email"
                                        style={reduxFormFieldStyle}
                                        component={FormField.renderField}
                                        type="text" />
                                </div>
                            </div>
                        </div>
                    </ExpansionPanel>
                </div>
                <h3>{getText("QtnOperationalInfo")}</h3>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <div style={containerStyle}>
                        <label style={labelBigStyle}>{getText("QtnReference")}</label>
                        <div>
                            <ReduxForm.Field
                                validate={[FormField.required]}
                                disabled={this.props.mode === "Update"}
                                name="reference"
                                style={reduxFormFieldStyle}
                                component={FormField.renderField}
                                type="text" />

                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={labelBigStyle}>{getText("QtnOriginInfo")}</label>
                        <div >
                            <ReduxForm.Field
                                validate={[]}
                                name="originInfo"
                                style={reduxFormFieldStyle}
                                component={FormField.renderField}
                                type="text" />
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={{
                            minWidth: 200,
                            marginRight: 10,
                            textAlign: "right",
                        }}>{getText("QtnDestinationInfo")}</label>
                        <div >
                            <ReduxForm.Field
                                validate={[]}
                                name="destinationInfo"
                                style={reduxFormFieldStyle}
                                component={FormField.renderField}
                                type="text" />
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 8,
                        marginTop: 10,
                        width: 450,
                        height: 70,
                    }}>
                        <label style={labelBigStyle}>{getText("QtnValidity")}</label>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                <label >{getText("GenDateFrom")}</label>
                                <div >
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="validityBegin"
                                        setDateButton={<Tooltip overlay={<div>{getText("GenSetToToday")}</div>}>
                                        </Tooltip>}
                                        component={(fields) => <FormField.DatePickerField {...fields} />} />
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: 7,
                            }}>
                                <label >{getText("GenDateTo")}</label>
                                <div >
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="validityEnd"
                                        component={(fields) => <FormField.DatePickerField {...fields} />} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={labelBigStyle}>{getText("QtnServiceName")}</label>
                        <div >
                            <ReduxForm.Field
                                validate={[]}
                                name="serviceName"
                                style={reduxFormFieldStyle}
                                component={FormField.renderField}
                                type="text" />
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={labelBigStyle}>{getText("QtnCargoDesc")}</label>
                        <div >
                            <ReduxForm.Field
                                validate={[]}
                                name="cargoDesc"
                                style={reduxFormFieldStyle}
                                component={FormField.renderField}
                                type="text" />
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={labelBigStyle}>{getText("QtnWeight")}</label>
                        <div >
                            <ReduxForm.Field
                                validate={[]}
                                name="weight"
                                style={{
                                    minWidth: 350,
                                    height: 30,
                                    borderRadius: 5,
                                    borderWidth: 0.5,
                                }}
                                component={FormField.renderField}
                                type="text" />
                        </div>
                    </div>
                    <div style={containerStyle} >
                        <label style={labelBigStyle}>{getText("QtnIncoterm")}</label>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{
                                flex: 1,
                                borderColor: Colors.baseColors.lightGray,
                                borderRadius: 5,
                                border: "thin solid",
                                minWidth: 354,
                            }}>
                                <div style={{
                                    marginLeft: 10,
                                }}>
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="incoterm"
                                        onChange={(value) => {
                                            let parsedValue = _.values(value).splice(0, 3).join("");
                                            let locationLink = incotermLocationLink
                                                .find(x => x.incoterm === parsedValue);
                                            if (locationLink && locationLink.location === "Origin") {
                                                this.props.updateIncotermLocation(this.props.originInfo)
                                            }
                                            if (locationLink && locationLink.location === "Destination") {
                                                this.props.updateIncotermLocation(this.props.destinationInfo)
                                            }
                                            if (locationLink && locationLink.location === "Pol") {
                                                this.props.updateIncotermLocation(this.props.pol)
                                            }
                                            if (locationLink && locationLink.location === "Pod") {
                                                this.props.updateIncotermLocation(this.props.pod)
                                            }
                                        }}
                                        component={FormField.getSelectField(incotermOptions(), true)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={containerStyle}>
                        <label style={labelBigStyle}>{getText("QtnIncotermLocation")}</label>
                        <div >
                            <ReduxForm.Field
                                validate={[]}
                                name="incotermLocation"
                                style={reduxFormFieldStyle}
                                type={"text"}
                                component={FormField.renderField} />
                        </div>
                    </div>
                </div>


                <div style={containerStyle}>
                    <div style={{
                        marginLeft: 194,
                    }}>
                        <ReduxForm.Field
                            validate={[]}
                            name="showCarrier"
                            style={{
                                minWidth: 350,
                                fontSize: 12,
                                fontFamily: "Calibri",
                            }}
                            component={showCarrierField} />
                    </div>
                </div>


                <div style={containerStyle}>
                    <label style={labelBigStyle}>{getText("QtnTransitTime")}</label>
                    <div
                        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field
                                validate={[]}
                                allowEmpty={true}
                                name="minTransitTime"
                                style={{
                                    border: "thin solid",
                                    borderRadius: 5,
                                    maxWidth: 140,
                                    height: 30,
                                }}
                                component={FormField.NumberField} />
                        </div>
                        <div style={{ flex: "1 0 auto", marginRight: 10, marginLeft: 10, }}> - </div>
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field
                                validate={[]}
                                allowEmpty={true}
                                name="maxTransitTime"
                                style={{
                                    border: "thin solid",
                                    maxWidth: 140,
                                    height: 30,
                                    borderRadius: 5,
                                }}
                                component={FormField.NumberField} />
                        </div>
                        <div style={{ flex: "0 0 auto", marginLeft: 10 }}>{getText("GenDays")}</div>
                    </div>
                </div>


                <div style={containerStyle}>
                    <label style={labelBigStyle}>{getText("QtnTransShipment")}</label>
                    <div >
                        <ReduxForm.Field
                            validate={[]}
                            name="transShipments"
                            style={reduxFormFieldStyle}
                            component={FormField.renderField}
                            type="text" />
                    </div>
                </div>


                <div style={containerStyle}>
                    <label style={labelBigStyle}>{getText("QtnFrequency")}</label>
                    <div style={{
                        borderColor: Colors.baseColors.lightGray,
                        borderRadius: 5,
                        border: "thin solid",
                        minWidth: 354,
                    }} >
                        <div style={{
                            marginLeft: 10,
                        }}>
                            <ReduxForm.Field
                                validate={[]}
                                name="frequency"
                                style={reduxFormFieldStyle}
                                component={FormField.getSelectField([{
                                    label: "------",
                                    value: null
                                }].concat(frequencyOptions()), true)} />
                        </div>
                    </div>
                </div>
                {this.props.hasChargeSet && <div >
                    <div ></div>
                    <div >
                        <div
                            style={{
                                paddingTop: 2,
                                color: Colors.baseColors.lightBlue,
                                textDecoration: "underline",
                                marginLeft: 210,
                                cursor: "pointer"
                            }}
                            onClick={(e) => {
                                if (!this.props.isDownloadingDocument) {
                                    this.props.requestDownloadDocument();
                                }
                                e.preventDefault();
                            }}>
                            <div >
                                <i ></i> {getText("SlcOfferRatesDownloadSheet")}
                            </div>
                        </div>
                        <div style={{
                            width: "100%"
                        }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                                onClick={(e) => {
                                    this.props.requestGoToCarrierWebSite();
                                    e.preventDefault();
                                }}>
                                <Language
                                    type={"button"}
                                    style={{
                                        marginTop: 10,
                                        color: Colors.baseColors.lightBlue,
                                        backgroundColor: "white",
                                        marginLeft: 210,
                                        cursor: "pointer"
                                    }}
                                    height={50}
                                >
                                </Language>
                                <div
                                    style={{ fontFamily: "Calibri", color: Colors.baseColors.lightBlue, marginTop: 12, marginLeft: 5, cursor: "pointer" }}
                                >Site du Carrier </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div style={{ textAlign: "center" }}>
                    {this.props.submitFailed
                        && <span>There are missing informations, please see above.</span>}
                </div>
                <div >
                    <div >
                        <button
                            type="submit"
                            disabled={this.props.submitting}
                            style={{
                                fontWeight: "bold",
                                marginTop: 10,
                                color: Colors.baseColors.lightBlue,
                                backgroundColor: "white",
                                marginLeft: 465,
                                cursor: "pointer",
                                borderWidth: 0.5,
                                border: "solid Colors.baseColors.lightGray",
                                borderRadius: 5,
                                height: 25,
                            }}>
                            {getText("GenNextStep")}
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

interface QuotationClientAutocompleteOwnProps {
    onContactSelect: (value: Api.QuotationContactModel) => void;
}

interface QuotationClientAutocompleteState {
    requestTime?: number;
    contacts: Array<Api.QuotationContactModel>;
    selected: Api.QuotationContactModel;
}

type QuotationClientAutocompleteProps = QuotationClientAutocompleteOwnProps & ReduxForm.WrappedFieldProps

class QuotationClientAutocomplete extends React.Component<QuotationClientAutocompleteProps, QuotationClientAutocompleteState> {
    constructor(props: QuotationClientAutocompleteProps) {
        super(props);
        this.state = {
            contacts: [],
            selected: null
        }
    }

    onSelect(contact: Api.QuotationContactModel) {
        if (contact == null) {
            contact = {
                company: this.props.input.value
            }
        }

        if (this.state.selected?.company == contact.company) {
            return;
        }

        this.setState({
            contacts: [], 
            selected : contact
        });
        this.props.onContactSelect(contact);
        this.props.input.onChange(contact?.company as any);
    }

    onChange(value: string) {
        Throttle.throttle("quotationClientContacts", () => {
            if (value.length >= 3) {
                let requestTime = new Date().getTime();
                this.setState({
                    requestTime: requestTime
                });
                let api = new Api.QuotationContactApi();
                api.searchContactsByCompanyName({
                    companyName: value
                }, { credentials: "same-origin", headers: { } })
                    .then(contacts => {
                        if (requestTime === this.state.requestTime) {
                            this.setState({
                                contacts: contacts
                            });
                        }
                    });
            }
        }, 300);
        this.props.input.onChange(value as any);
    }

    getContactDisplay(contact: Api.QuotationContactModel): string {
        return contact != null ? `${contact.company}-${contact.agency ?? ""}-${contact.firstName ?? ""} ${contact.lastName ?? ""}` : "";
    }

    public render() {
        return (
            <Autocomplete
                getItemValue={(item: Api.QuotationContactModel) => item?.quotationContactId}
                getItemLabel={(item: Api.QuotationContactModel) => item?.company}
                emptyItems={() => this.setState({ contacts: [] })}
                items={this.state.contacts}
                renderItem={(item: Api.QuotationContactModel, isHighlighted) =>
                    <div style={{ background: isHighlighted ? 'lightgray' : 'white', cursor: "pointer", padding: "4px 0px" }}>
                        <span>{this.getContactDisplay(item)}</span>
                    </div>}
                inputValue={this.props.input.value}
                onInputValueChange={(val) => {
                    this.onChange(val)
                }}
                value={this.props.input.value}
                onChange={(id) =>
                    this.onSelect(this.state.contacts.find(x => x.quotationContactId == id))

                } />
        );
    }
}

export const quotationInformation = "quotationInformation";

let quotationInformationForm = ReduxForm.reduxForm({
    form: quotationInformation,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
    asyncValidate: (values, dispatch, props) => {
        let quotation = values as Api.QuotationModel;
        let formProps = props as QuotationInformationFormProps;
        if (formProps.mode === "Update")
            return Promise.resolve();

        return new Promise<object | undefined | void>((resolve, reject) => {
            let api = new Api.QuotationApi();
            api.referenceIsValid({
                reference: quotation.reference
            }, { credentials: "same-origin", headers: getDefaultHeaders() }).then(isValid => {
                if (isValid.result) {
                    resolve();
                } else {
                    reject({ "reference": getText("QtnReferenceNotValid") });
                }
            }).catch(err => {
                reject({ "reference": "Failed to verify reference, network error" });
            });
        });
    },
    shouldAsyncValidate: (params) => {
        return params.trigger === "submit";
    }
})(QuotationInformationForm) as any;
const selector = ReduxForm.formValueSelector('quotationInformation')

export default connect((state: ApplicationState) => ({
    initialValues: state.quotation.quotation,
    hasChargeSet: state.quotation.carrierOffer
        && state.quotation.carrierOffer.chargeSet ? true : false,
    quotationContacts: state.quotationContact.quotationContacts,
    clientMoreOptions: state.quotation.clientMoreOptions,
    originInfo: selector(state, "originInfo"),
    destinationInfo: selector(state, "destinationInfo"),
    pol: state.quotation.criteria
        ? state.quotation.criteria.origin.name
        : "",
    pod: state.quotation.criteria
        ? state.quotation.criteria.destination.name
        : "",
} as QuotationInformationFormOwnProps))(quotationInformationForm) as any as React.ComponentClass<QuotationInformationFormExternalProps>