import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as SelectionStore from '../store/Selection';
import * as CriteriaStore from '../store/Criteria';
import * as MultiPortStore from '../store/MultiPort';
import * as AskBookingStore from '../store/AskBooking';
import * as AskRatesStore from '../store/AskRates';
import * as TransferStore from '../store/Transfer';
import * as SellingRatesStore from '../store/SellingRates';
import * as Api from '../api/api';
import CarrierOffer from './CarrierOffer';
import { getCarrierOfferKey, getSubscription } from '../utils/utils';
import { scrollToWhen } from 'react-redux-scroll';
import * as selectionConnect from '../connects/selectionConnect';
import { getText } from "../utils/langTexts";
import { getSelectedDepart } from '../utils/routeUtils';
import Button from './Button';
import Pagination from '@material-ui/lab/Pagination';
import ContactBox from './ContactBox';

// At runtime, Redux will merge together...
type SelectionOffersProps =
    SelectionOffersOwnProps
    & SelectionOffersExternalProps
    & typeof SelectionStore.actionCreators
    & typeof CriteriaStore.actionCreators
    & typeof MultiPortStore.actionCreators
    & typeof AskBookingStore.actionCreators
    & typeof AskRatesStore.actionCreators
    & typeof TransferStore.actionCreators
    & typeof SellingRatesStore.actionCreators
    & RouteComponentProps<{ code: string }>;

interface SelectionOffersOwnProps {
    isLoading: boolean;
    filterModel: SelectionStore.SelectionFilterModel;
    selection: Api.SelectionModel;
    sellingRates: Array<Api.SellingRatesModel>;
    carrierOffersGrouped: Array<Array<Api.CarrierOfferModel>>;
    carrierOfferStates: { [id: string]: SelectionStore.CarrierOfferState };
    carrierRatesPlatforms: { [id: string]: Api.CarrierRatesPlatformModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    carriers: { [id: number]: Api.CarrierModel };
    markets: { [id: number]: Api.MarketModel };
    client: Api.ClientModel;
    criteriaLoaded: Api.CriteriaModel;
    criteria: CriteriaStore.CriteriaState;
    currentPage: number;
    subscription: Api.SubscriptionModel;
    selectedCarrierOfferId: string;
}

interface SelectionOffersExternalProps {
    criteriaType: Api.CriteriaModelTypeEnum;
    match: any;
}


interface SelectionOffersState {

}

export const departPerPage = 6;

const isOfferDepartsSelected = (action, props): boolean => {
    if (action.type === "SELECT_CARRIEROFFER"
        && props.id === action.carrierOfferId) {
        return true;
    }

    return false;
}
const ScrollableOfferDeparts =
    scrollToWhen(isOfferDepartsSelected, null, null, ["id"])("div");

class SelectionOffers extends React.Component<SelectionOffersProps, SelectionOffersState> {
    constructor(props) {
        super(props);
    }

    getSelectedDepart(carrierOffer: Api.CarrierOfferModel): Api.DepartModel {
        return getSelectedDepart(carrierOffer, this.props.carrierOfferStates[getCarrierOfferKey(carrierOffer)]);
    };

    get carrierOffersSelected(): Array<Array<Api.CarrierOfferModel>> {
        return this.props.carrierOffersGrouped
            .filter(ca => this.props.filterModel.carrierOffersSelected[getCarrierOfferKey(ca[0])] !== false);
    }

    public render() {
        return (
            <div>
                {!this.props.isLoading
                    && this.props.subscription
                    && this.props.subscription.allowAskRates
                    && this.props.selection.carrierOffers.length !== 0
                    && !this.props.selection.carrierOffers.some(x => x.chargeSet ? true : false)
                    && <div style={{ paddingTop: 6, paddingBottom: 20, textAlign: "center" }}>
                    <Button
                            onClick={(e) => this.props.openEmailAskRates()}>
                            {getText("SlcAllOfferAskRatesBtn")}
                        </Button>
                    </div>}
                <div>
                    <div>
                        {this.carrierOffersSelected
                            .reduce((a, b) => a.concat(b), [])
                            .slice((this.props.currentPage - 1) * departPerPage, this.props.currentPage * departPerPage)
                            .map(co =>
                                <ScrollableOfferDeparts
                                    style={{ marginBottom: 56 }}
                                    id={getCarrierOfferKey(co)}
                                    key={getCarrierOfferKey(co)}>
                                    <CarrierOffer
                                        criteriaType={this.props.criteriaType}
                                        carrierRatesPlatforms={this.props.carrierRatesPlatforms}
                                        carriers={this.props.carriers}
                                        markets={this.props.markets}
                                        carrierOffer={co}
                                        carrierOfferFocused={this.props.selectedCarrierOfferId}
                                        carrierOfferState={this.props.carrierOfferStates[getCarrierOfferKey(co)] || SelectionStore.unloadedCarrierOfferState}
                                        criteriaModel={this.props.criteria.criteria}
                                        criteriaLoaded={this.props.criteriaLoaded}
                                        currencies={this.props.currencies}
                                        currencyId={this.props.client.currencyId}
                                        sizeTypes={this.props.sizeTypes}
                                        chargeNames={this.props.chargeNames}
                                        subscription={this.props.subscription}
                                        sellingRates={this.props.sellingRates}
                                        selectDepart={(departId: number) =>
                                            this.props.selectCarrierOfferDepart(getCarrierOfferKey(co), departId)}
                                        selectService={(routeServiceId) => this.props.selectCarrierOfferService(getCarrierOfferKey(co), routeServiceId)}
                                        toggleDetails={(value: boolean) =>
                                            this.props.toggleCarrierOfferDetails(getCarrierOfferKey(co), value)}
                                        toggleBlDetails={(value: boolean) =>
                                            this.props.toggleCarrierOfferBlDetails(getCarrierOfferKey(co), value)}
                                        toggleLclDetails={(value: boolean) =>
                                            this.props.toggleCarrierOfferLclDetails(getCarrierOfferKey(co), value)}
                                        toggleSizeTypeDetails={(value: boolean, sizeTypeId: number) =>
                                            this.props.toggleCarrierOfferSizeTypeDetails(getCarrierOfferKey(co), value, sizeTypeId)}
                                        toggleDocumentInfoDetails={(value: boolean, documentInfoId: number) =>
                                            this.props.toggleCarrierOfferDocumentInfoDetails(getCarrierOfferKey(co), value, documentInfoId)}
                                        toggleContacts={(value: boolean) =>
                                            this.props.toggleCarrierOfferContacts(getCarrierOfferKey(co), value)}
                                        toggleRatesDetails={(value: boolean) =>
                                            this.props.toggleCarrierOfferRatesDetails(getCarrierOfferKey(co), value)}
                                        toggleRatesOfferDescription={(value: boolean) =>
                                            this.props.toggleCarrierOfferRatesOfferDescription(getCarrierOfferKey(co), value)}
                                        openAskBooking={() =>
                                            this.props.openAskBooking(getCarrierOfferKey(co))}
                                        openAskRates={() =>
                                            this.props.openAskRates(getCarrierOfferKey(co))}
                                        openTransferData={() =>
                                            this.props.openTransferData(getCarrierOfferKey(co))}
                                        goToQuotation={() =>
                                            this.props.goToQuotation(this.getSelectedDepart(co)?.departId,
                                                co.chargeSet
                                                    ? co.chargeSet.chargeSetId
                                                    : null, co.routeId)}
                                        requestSendAgentQuotation={() =>
                                            this.props.requestSendAgentQuotation(
                                                new Date().getTime(),
                                                getCarrierOfferKey(co))}
                                        requestDownloadRatesDocument={() =>
                                            this.props.requestDownloadRatesDocument(
                                                new Date().getTime(),
                                                getCarrierOfferKey(co),
                                                co.chargeSet.chargeSetId)}
                                        requestGoToCarrierWebSite={() =>
                                            this.props.requestGoToCarrierWebSite(
                                                getCarrierOfferKey(co),
                                                new Date().getTime())} />
                                </ScrollableOfferDeparts>
                            )}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Pagination
                            style={{ maxWidth: 400 }}
                            page={this.props.currentPage}
                            count={Math.ceil(this.carrierOffersSelected
                                .reduce((a, b) => a.concat(b), []).length / departPerPage)}
                            onChange={(e, page: number) => this.props.selectPage(page)}/>
                    </div>
                </div>
                <ContactBox />
            </div>
            );
    }
}

//Filtering is done here, we'll use reselect for optimization later on, for now we don't care
export default connect(
    (state: ApplicationState) => {
        let carrierGroupedResult = selectionConnect.getGroupedCarrierOffers(state);
        let subscription = getSubscription(state.account.currentUser.clientModel)
            || { subscriptionType: null };

        return {
            isLoading: state.selection.isLoading,
            filterModel: state.selection.filterModel,
            selection: state.selection.selection,
            carrierOffersGrouped: carrierGroupedResult,
            carrierOfferStates: state.selection.carrierOfferStates,
            criteria: state.criteria,
            criteriaLoaded: state.selection.criteriaLoaded,
            sizeTypes: state.seed.sizeTypes,
            currencies: state.seed.currencies,
            markets: state.seed.markets,
            chargeNames: state.seed.chargeNames,
            carriers: state.seed.carriers,
            client: state.account.currentUser.clientModel,
            currentPage: state.selection.currentPage,
            subscription: subscription,
            sellingRates: state.sellingRates.selectionSellingRates,
            selectedCarrierOfferId: state.selection.selectedCarrierOfferId,
            carrierRatesPlatforms: state.seed.carrierRatesPlatforms
        } as any;
    },
    {
        ...SelectionStore.actionCreators,
        ...CriteriaStore.actionCreators,
        ...MultiPortStore.actionCreators,
        ...TransferStore.actionCreators,
        ...AskRatesStore.actionCreators,
        ...AskBookingStore.actionCreators,
        ...SellingRatesStore.actionCreators
    })
    (SelectionOffers) as any as React.ComponentClass<SelectionOffersExternalProps>;