import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getText } from "../utils/langTexts";
import { revertColors } from '../styles/colors';
import ExpansionPanel from "./ExpansionPanel";

type SelectionFeedbackFormProps = ReduxForm.InjectedFormProps<{}, {}, string> & SelectionFeedbackFormOwnProps;

interface SelectionFeedbackFormOwnProps {
    level: Api.SetSatisfiedModelLevelEnum;
}

const satisfiedField = (field, handleSubmit) => (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <div style={{ order: 0, flex: "0 0 50%", alignSelf: "center" }}>
            <div style={{ textAlign: "center" }}>
                <img style={{ cursor: "pointer" }}
                    onClick={(e) => field.input.onChange("Bad" as Api.SetSatisfiedModelLevelEnum)}
                    height={20} src={"/images/satisfiedNo.png"} />
            </div>
        </div>
        <div style={{ order: 1, flex: "0 0 50%", alignSelf: "center" }}>
            <div style={{ textAlign: "center" }}>
                <img style={{ cursor: "pointer" }}
                    onClick={(e) => {
                        field.input.onChange("Good" as Api.SetSatisfiedModelLevelEnum);
                        setTimeout(() => handleSubmit(), 0);
                    }}
                    height={20} src={"/images/satisfiedYes.png"} />
            </div>
        </div>
    </div>
)
const messageField = FormField.textAreaField(4, 40);

class SelectionFeedbackForm extends React.Component<SelectionFeedbackFormProps, {}> {
    form: HTMLFormElement;

    public render() {
        return (
            <form className="form-horizontal"
                ref={(formRef) => this.form = formRef}
                onSubmit={(e) => {
                if (e.preventDefault) {
                    e.preventDefault();
                }
                return this.props.handleSubmit(e);
            }}>
                <div>{getText("SlcFeedbackQuestion")}</div>
                <ExpansionPanel
                    onChange={(v) => { }}
                    head={<React.Fragment></React.Fragment>}
                    expanded={this.props.level === "Bad"}>
                    <div style={{ marginTop: 10 }}>
                        <ReduxForm.Field
                            name="reason"
                            placeholder={getText("SlcFeedbackMessagePlaceholder")}
                            component={messageField} />
                    </div>
                </ExpansionPanel>
                {this.props.level === "Bad" && <div style={{ width: "100%", marginTop: 10 }}>
                    <button style={{
                        width: "100%",
                        ...revertColors.main3
                    }} className="btn btn-sm">{getText("SlcFeedbackSend")}</button>
                </div>}
                {!this.props.level && <div>
                    <ReduxForm.Field
                            name="level"
                            component={(field) => satisfiedField(field, this.props.handleSubmit)} />
                </div>}    
            </form>
        );
    }
}

let form = ReduxForm.reduxForm({ form: 'sendSelectionFeedback' })(SelectionFeedbackForm) as any;
const selector = ReduxForm.formValueSelector('sendSelectionFeedback')

export default connect((state: ApplicationState) => {
    const level = selector(state, 'level')
    return {
        level: level
    };
})(form) as any