import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as MultiPortStore from '../store/MultiPort';
import * as Api from '../api/api';
import DialogModel from './DialogModal';
import MultiPortOffers from './MultiPortOffers';
import Button from './Button';
import * as Colors from '../styles/colors';
import { ShoppingCart } from '@material-ui/icons';

type MultiPortBoxProps = MultiPortBoxOwnProps
    & MultiPortStore.MultiPortState
    & typeof MultiPortStore.actionCreators;

interface MultiPortBoxOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
    currencies: { [id: number]: Api.CurrencyModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
}

interface MultiPortBoxState {
    name: string;
}

class MultiPortBox extends React.Component<MultiPortBoxProps, MultiPortBoxState> {

    constructor(props) {
        super(props);
        this.state = {
            name: "My multi port"
        };
    }

    downloadMultiPort(multiPortInstanceId: number): Promise<any> {
        if (this.props.multiPort && this.props.newName !== this.props.multiPort.name) {
            return ((this.props.requestUpdateMultiPort(new Date().getTime(), multiPortInstanceId) as any) as Promise<any>)
                .then(() => {
                    return this.props.requestDownloadMultiPort(new Date().getTime(), multiPortInstanceId);
                });
        } else {
            return this.props.requestDownloadMultiPort(new Date().getTime(), multiPortInstanceId) as any;
        }
    }

    public render() {

        if (!this.props.isVisible) {
            return null;
        } else {

        }
        return (
            <div style={{}}>
                <Button
                    style={{
                        backgroundColor: Colors.baseColors.lightBlue,
                        color: "white",
                        height: 45
                    }}
                    onClick={(e) => {
                        this.props.toggleMultiPortOpened(true);
                    }}>
                    <ShoppingCart /> {this.props.multiPort ? this.props.multiPort.multiPortOffers.length : 0} rates
                </Button>
                {this.props.multiPort
                    ?
                    <DialogModel
                        contentLabel="Multi port"
                        maxWidth={"lg"}
                        isOpen={this.props.isOpen}
                        onRequestClose={() => this.props.toggleMultiPortOpened(false)}
                        contentStyle={{ }}>
                        <MultiPortOffers
                            isLoading={this.props.isLoading
                                || this.props.addOfferState.isLoading
                                || this.props.deleteOfferState.isLoading}
                            isClearing={this.props.deleteMultiPortState.isLoading}
                            isDownloading={this.props.downloadingOffersState.isLoading}
                            requestCurrentMultiPort={() =>
                                this.props.requestCurrentMultiPort(new Date().getTime()) as any}
                            requestDowloadMultiPort={(multiPortInstanceId) =>
                                this.downloadMultiPort(multiPortInstanceId)}
                            requesDeleteMultiPortOffer={(multiPortOfferId) =>
                                this.props.requestDeleteMultiPortOffer(new Date().getTime(), multiPortOfferId)}
                            requesUpdateMultiPortOffer={(multiPortOfferId) => this.props.requestUpdateMultiPort(new Date().getTime(), multiPortOfferId) as any}
                            requestDeleteMultiPortInstance={() =>
                                (this.props.requestDeleteMultiPortInstance(new Date().getTime(), this.props.multiPort?.multiPortInstanceId) as any as Promise<any>)
                                    .then(() => this.props.requestCurrentMultiPort(new Date().getTime()))}
                            carriers={this.props.carriers}
                            currencies={this.props.currencies}
                            sizeTypes={this.props.sizeTypes}
                            multiPort={this.props.multiPort}
                            newName={this.props.newName}
                            updateMultiPortName={(value) => this.props.updateMultiPortName(value)}
                        />
                    </DialogModel>
                    : null}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.multiPort,
        carriers: state.seed.carriers,
        currencies: state.seed.currencies,
        sizeTypes: state.seed.sizeTypes
    } as MultiPortBoxProps),
    MultiPortStore.actionCreators)(MultiPortBox) as any