import * as React from 'react';
import { connect } from 'react-redux';
import * as ErrorStore from "../store/Error";
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import Button from "./Button";

type ErrorProps = ErrorOwnProps
    & ErrorStore.ErrorState
    & typeof ErrorStore.actionCreators;

interface ErrorOwnProps {

}

class Error extends React.Component<ErrorProps, {}> {
    componentDidMount() {
        
    }

    public render() {
        return (<div style={{
            height: "100%",
            display: "grid",
            gridTemplateRows: "1fr auto 1fr",
            gridTemplateColumns: "1fr auto 1fr"
        }}>
            <div style={{
                gridRow: "2/3",
                gridColumn: "2/3",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: 20
            }}>
                <img src={"/images/logo.png"}
                     height={70}
                     style={{}} />
                <div>{getText("GenErrorTitle")}</div>
                <div style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>{getText("GenErrorMessage")}</div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 40 }}>
                    <Button style={{ cursor: "pointer" }} onClick={(e) => { window.location.reload() }}>Reload</Button>
                    <Button style={{ cursor: "pointer" }} 
                            disabled={this.props.isLoading || this.props.reportSent}
                            onClick={(e) => { this.props.requestReportError(new Date().getTime()) }}>
                        {this.props.reportSent
                        ? getText("GenErrorReportSent")
                        : getText("GenErrorSendReport")}
                    </Button>
                </div>
            </div>
        </div>);
    }
}

export default connect(
    (state: ApplicationState) => state.error,
    ErrorStore.actionCreators)(Error) as any