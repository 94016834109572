import * as Api from '../api/api';
import { ApplicationState } from "../store/index"
import { CSSProperties } from 'react';
import { QuotationState } from '../store/Quotation';
import _ from 'lodash';

export const getDefaultHeaders = (state?: ApplicationState): any => {
    return {};
};

export const isInDateRange = (start: number, end: number, minDate: number, maxDate: number): boolean => {
    return (!start && !end)
        || (!start && minDate <= end)
        || (!end && start <= maxDate)
        || (minDate <= start && start <= maxDate) ||
        (minDate <= end && end <= maxDate) ||
        (start <= minDate && minDate <= end);
};

export const dictionnaryToArray = <T>(entities: { [id: number]: T }): Array<T> => {
    let array = [];
    for (let id of Object.keys(entities)) {
        array.push(entities[Number.parseInt(id)]);
    }
    return array;
}

export const dictionnaryStringToArray = <T>(entities: { [id: string]: T }): Array<T> => {
    let array = [];
    for (let id of Object.keys(entities)) {
        array.push(entities[id]);
    }
    return array;
}

export const getCarrierOfferKey = (carrierOffer: Api.CarrierOfferModel): string => {
    return `CA${carrierOffer.carrierId}`
        + `R${carrierOffer.routeConfigs[0]?.routeId || carrierOffer.chargeSet?.routeId}`
        + `C${carrierOffer.chargeSet?.chargeSetId}`
        + `${_.sortBy(carrierOffer.routeConfigs, x => x.routeConfigId)
            .map(x => 'RC' + x.routeConfigId)
            .join("")}`;
}

export const getLocationName = (location: Api.LocationModel): string => {
    if (!location) return "";

    switch (location.locationType) {
        case "Port":
            return location.name
                + (location.country ? ", " + location.country.name : "");
        case "Terminal":
            return location.name
                + (location.port ? ", " + location.port.name : "");
        case "InlandPlace":
            return (location.inlandPlaceZipCodes
                && location.inlandPlaceZipCodes.length
                    ? location.inlandPlaceZipCodes[0] + " " : "")
                + location.name
                + (location.country ? ", " + location.country.name : "");
        case "InlandLocationGroup":
            return location.name
                + (location.country ? ", " + location.country.name : "");
        case "PortGroup":
            return location.name;
    }

    return location.name;
}

export const getPort = (location: Api.LocationModel): Api.LocationModel => {
    if (!location) return null;

    switch (location.locationType) {
        case "Port":
            return location;
        case "Terminal":
            return location.port;
        case "PortGroup":
            return location.portsInGroup[0];
    }

    return location;
}

export const getSubscriptionName = (subscription: Api.SubscriptionModel): string => {
    if (!subscription) return "";

    switch (subscription.subscriptionType) {
        case "Okargo":
            return "Okargo: " + subscription.agency.name + ", " + subscription.agency.company.name;
        case "Carrier":
            return "Carrier: " + subscription.carrier.name;
        case "Agent":
            return "Agent: " + getFirmName(subscription.firm);
    }

}

export const getQuotationContactName = (quotationContact: Api.QuotationContactModel): string => {
    if (!quotationContact) return "";

    switch (quotationContact.type) {
        case "Client":
            return (quotationContact.company || "")
                + ": " + (quotationContact.firstName || "")
                + " " + (quotationContact.lastName || "");
        case "FreightForwarder":
            return (quotationContact.company || "")
                + ": " + (quotationContact.firstName || "")
                + " " + (quotationContact.lastName || "");
        case "Okargo":
            return "OKARGO Contact: " + quotationContact.email;
    }


}

export function getSubscription(client: Api.ClientModel): Api.SubscriptionModel {
    return client.subscriptions
        .sort((a, b) => b.subscriptionId === client.subscriptionId ? 1 : -1)[0]
        || null;
}

export const getFirmName = (firm: Api.FirmModel): string => {

    if (!firm) return "";

    switch (firm && firm.firmType) {
        case "Agency":
            return firm.name + ", " + firm.company.name;
        case "Company":
            return firm.name ;
    }

    return firm.name;
}

export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

class Group<T> {
    key: string;
    members: T[] = [];
    constructor(key: string) {
        this.key = key;
    }
}

export function groupBy<T>(list: T[], func: (x: T) => string): Group<T>[] {
    let res: Group<T>[] = [];
    list.forEach((o) => {
        let groupName = func(o);
        let group = res.find(gp => gp.key === groupName);
        if (!group) {
            group = new Group<T>(groupName);
            res.push(group);
        }
        group.members.push(o)
    });
    return res
}

export function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] ?
            String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
            true
    );
}

export function getChargeAmount(charge: Api.ChargeModel, unitNumber: number): number {

    unitNumber = Math.max(((charge.minUnit != null && charge.minUnit > 0) ? charge.minUnit : 0),unitNumber);

    return charge.amount
        * (charge.unit === "Teu" ? unitNumber : 1)
        * (charge.unit === "Ton" ? unitNumber : 1)
        * (charge.unit === "Wm" ? unitNumber : 1)
        * (charge.unit === "Cbm" ? unitNumber : 1);

}

export function serializeToQuery(obj: any): string {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export function goToFormModel(formModel: Api.FormModel): void {
    if (formModel.method.toLowerCase() === "get") {
        window.open(formModel.action
            + (formModel.parameters.length !== 0
                ? ("?" + formModel.parameters
                .map(p => encodeURIComponent(p.key) + "=" + encodeURIComponent(p.value))
                .reduce((a,b) => a + "&" + b, "")) : ""), "_blank");
    } else {
        window.open(formModel.action);
    }
}

export const getRatesStyle = (chargeSet: Api.ChargeSetModel, criteria?: Api.CriteriaModel): CSSProperties => {
    if (chargeSet
        && criteria
        && criteria.lastRates 
        && chargeSet.dateEnd < criteria.dateBegin)
        return {
        };

    if (chargeSet && chargeSet.routeSuggestedCalcId)
        return {
        };

    if (chargeSet && chargeSet.firmId)
        return {
        };

    if (chargeSet && chargeSet.ratesFetchedRecordId)
        return {
        };

    if (chargeSet && !chargeSet.firmId)
        return {
        };

    return {
    };
};

export const offerIsCarrierRating = (offer: Api.CarrierOfferModel, carrierRating: Api.CarrierRatingModel): boolean => {
    return offer.chargeSet
        && carrierRating
        && carrierRating.carrierCarrierRatings
            .some(x => x.carrierId === offer.carrierId
                && ((!x.ratesType && !x.ratesOfferId)
                    || (x.ratesType === "Fak"
                        && (!offer.ratesOffer
                            || offer.ratesOffer.type === "Fak"))
                    || (x.ratesType === "Special"
                        && offer.ratesOffer
                        && offer.ratesOffer.type === "Special")
                    || (x.ratesOfferId
                        && offer.ratesOffer
                        && (x.ratesOfferId === offer.ratesOffer.ratesOfferId
                                || offer.ratesOffer.references.some(r => x.ratesOffer.references.some(xr => xr.value === r.value))
                            )
                    )
                )
            );
};

export const getQuotationLink = (quotation: QuotationState): string => {
    return "/quotation/"
        + quotation.criteria.code
        + "/" + quotation.departId
        + "/" + (quotation.carrierOffer.chargeSet
            ? quotation.carrierOffer.chargeSet.chargeSetId
            : "null")
        + (quotation.mode === "Duplicate"
            ? ("/" + quotation.quotation.previousVersionId
                + "/" + quotation.mode)
            : "")
        + (quotation.mode === "Update"
            ? ("/" + quotation.quotation.quotationId
                + "/" + quotation.mode)
            : "")
}

export const isClient = (): boolean => {
    return (typeof window !== 'undefined' &&
        window.document
        && window.document.createElement) ? true : false;
}

export const getScreenSize = (): { width: number, height: number } => {
    if (isClient()) {
        return {
            width: window.screen.width,
            height: window.screen.height,
        };
    }

    return {
        height: 1024,
        width: 1280
    };
};

export const scrollToAppTop = (): void => {
    setTimeout(() => {
        if (document.getElementById("scroll-container")) {
            document.getElementById("scroll-container").scrollTo({ top: 0 })
        }
    }, 1);
}


export const removeFirstOccurence = ( src:string[],element): string[] => {
    const index = src.indexOf(element);
    if (index === -1) return src;
    return [...src.slice(0, index), ...src.slice(index + 1)];
}