import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import * as CriteriaState from '../store/Criteria';
import * as SelectionStore from '../store/Selection';
import CriteriaForm from '../components/CriteriaForm';
import { getText } from "../utils/langTexts";
import * as Colors from "../styles/colors";
import { getSubscription } from '../utils/utils';
import _ from 'lodash';

// At runtime, Redux will merge together...
type CriteriaProps =
    CriteriaState.CriteriaState        // ... state we've requested from the Redux store
    & CriteriaOwnProps
    & typeof CriteriaState.actionCreators      // ... plus action creators we've requested
    & typeof SelectionStore.actionCreators
    & RouteComponentProps<{ code: string }>; // ... plus incoming routing parameters   

interface CriteriaOwnProps {
    client: Api.ClientModel;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    subscription: Api.SubscriptionModel;
}


export const containerTypeOptions = (): Array<{ label: string; value: Api.CriteriaModelContainerTypeEnum }> => [
    { label: getText("GenCtrDry"), value: "Dry" },
    { label: getText("GenCtrRefeer"), value: "Rf" },
    { label: getText("GenCtrFlat"), value: "Fl" },
    { label: getText("GenCtrOpenTop"), value: "Ot" },
    { label: getText("GenCtrTankTop"), value: "Tk" }
];

const isSameFavCriteria = (a: Api.CriteriaModel, b: Api.CriteriaModel) => {
    return a && b && a.origin && b.origin
        && a.criteriaSizeTypes
        && b.criteriaSizeTypes
        && a.loadingCharge === b.loadingCharge
        && a.unLoadingCharge === b.unLoadingCharge
        && a.origin.locationId === b.origin.locationId
        && a.destination.locationId === b.destination.locationId
        && _.sortBy(a.criteriaSizeTypes, x => x.sizeTypeId)
            .filter(x => x.number > 0)
            .map(x => `${x.sizeTypeId}|${x.number}`)
            .join("|") === _.sortBy(b.criteriaSizeTypes, x => x.sizeTypeId)
                .filter(x => x.number > 0)
                .map(x => `${x.sizeTypeId}|${x.number}`)
                .join("|")
}

class Criteria extends React.Component<CriteriaProps, {}> {
    constructor(props) {
        super(props);
    }

    onPropsUpdate(props: CriteriaProps) {
        let code = this.props.match ? this.props.match.params.code : '';
        if (code)
            this.props.requestCriteria(code, new Date().getTime());
    }

    componentDidMount() {
        // This method runs when the component is first added to the page
        this.onPropsUpdate(this.props);
        this.props.requestRecentCriteria(new Date().getTime());
    }

    componentWillReceiveProps(nextProps: CriteriaProps) {
        // This method runs when incoming props (e.g., route params) change
        if (this.props.match.params.code !== nextProps.match.params.code)
            this.onPropsUpdate(nextProps);
    }

    criteriaGo(): Promise<any> {
        this.props.toggleAdvCriteria(false); //Close adv options on selection page
        let promise = (this.props.requestCreateCriteria(new Date().getTime()) as any) as Promise<Api.CriteriaModel>;
        return promise.then((criteria) => {
            this.props.setCriteria(criteria);
            return this.props.criteriaGo();
        });
    }

    doWithoutPropagation(e: any, action: () => void): void {
        action();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    public render() {
        return <div style={{
            height: "100%",
            boxSizing: "border-box",
            padding: "0px 150px 60px 150px",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch"
        }}>
            <div style={{ flex: 1, maxHeight: 60 }}></div>
            <div style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
            }}>
                <div style={{
                    flex: 2,
                    minWidth: 300,
                    maxWidth: 390
                }}>
                    <div style={{
                        //width: 390,
                        border: `1px solid ${Colors.baseColors.darkGray}`,
                        borderRadius: 4
                    }}>
                        <div style={{
                            padding: "25px 16px"
                        }}>
                            <div style={{ fontSize: 18, marginBottom: 20 }}>Favorite search</div>
                            {this.props.recentCriteriaState.criterias.map(criteria => {
                                return <div key={criteria.criteriaId}
                                    style={{
                                        marginBottom: 30,
                                        minHeight: 30,
                                    }}>
                                    {<div style={{
                                            cursor: "pointer",
                                        ...(isSameFavCriteria(criteria, this.props.criteria)
                                                ? { color: Colors.baseColors.lightBlue }
                                                : {})
                                        }}
                                        onClick={(e) => {
                                            this.props.setCriteriaRecent(criteria);
                                        }}>
                                        <div>{criteria.origin.name} &gt; {criteria.destination.name}</div>
                                            <div>
                                            {criteria.criteriaSizeTypes
                                                    .filter(x => x.number > 0)
                                                    .map(x => `${x.number}x ${this.props.sizeTypes[x.sizeTypeId].name}`).join(' + ')}
                                            </div>
                                        <div>
                                            {(criteria.loadingCharge || criteria.unLoadingCharge) && "+ "}
                                            {criteria.loadingCharge && "Origin "}
                                            {criteria.loadingCharge && criteria.unLoadingCharge && "and "}
                                            {criteria.unLoadingCharge && "Destination "}
                                            {(criteria.loadingCharge || criteria.unLoadingCharge) && "charges"}
                                        </div>
                                        </div>}
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div style={{ flex: 2 }}></div>
                <div style={{
                    flex: "0 0 auto",
                    height: "100%",
                    maxHeight: 1000,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    {(this.props.subscription && this.props.subscription.subscriptionType === "Agent") && <div
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "red",
                            fontSize: 18,
                            flex: "0 0 auto"
                        }}>
                        BETA
                    </div>}
                    <CriteriaForm
                        style={{ paddingBottom: 40 }}
                        onSubmit={(values) => {
                            if(this.props.isLoading) {
                                return Promise.resolve();
                            }
                            this.props.setCriteria(values);
                            return this.criteriaGo();
                        }}
                        resetCriteria={() => this.props.resetCriteria()}
                        advContainerOpened={this.props.advContainerOpened}
                        advDateOpened={this.props.advDateOpened}
                        advLocationOpened={this.props.advLocationOpened}
                        toggleAdvContainer={(value) => this.props.toggleAdvContainer(value)}
                        toggleAdvDate={(value) => this.props.toggleAdvDate(value)}
                        toggleAdvLocation={(value) => this.props.toggleAdvLocation(value)} />
                </div>
                <div style={{ flex: 3 }}></div>
            </div>
        </div>;
    }
    }

            export default connect(
    (state: ApplicationState) => {
        return {
            ...state.criteria,
            client: state.account.currentUser.clientModel,
            sizeTypes: state.seed.sizeTypes,
            subscription: getSubscription(state.account.currentUser.clientModel)
                || { subscriptionType: null }
        } as any
    },
                {
                    ...SelectionStore.actionCreators,
                    ...CriteriaState.actionCreators
                })
    (Criteria) as any