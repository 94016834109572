import * as React from 'react';
import Moment from 'moment';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as AgentPortalStore from '../store/AgentPortal';
import { ApplicationState } from '../store';
import Loader from './Loader';
import DatePicker from './DatePicker';
import ModeratorChargeAgentForm from './ModeratorChargeAgentForm';
import ModeratorContactAgentForm from './ModeratorContactAgentForm';
import { getLocationName, groupBy, getFirmName, filterCaseInsensitive } from '../utils/utils';
import { Source } from '../store/Criteria';
import LocationSelect from './LocationSelect';
import { getText } from '../utils/langTexts';
import DialogModal from './DialogModal';
import Table, { Column } from './Table';
import ModeratorAgentSearchDetails from './ModeratorAgentSearchDetails';
import { searchContainerStyle, searchFieldStyle, filterForm, formContainerStyle, inputStyleModerator, labelStyle, buttonStyle } from '../styles/moderatorStyles';

type ModeratorAgentPortalProps = ModeratorAgentPortalOwnProps
    & AgentPortalStore.AgentPortalState
    & typeof AgentPortalStore.actionCreators;

interface ModeratorAgentPortalOwnProps {
    subscriptions: Array<Api.SubscriptionModel>;
    sizeTypes: { [id: number]: Api.SizeTypeModel }
    carriers: { [id: number]: Api.CarrierModel }
    chargeNames: { [id: number]: Api.ChargeNameModel }
    currencies: { [id: number]: Api.CurrencyModel }
}

export const getFirms = (subscriptions: Array<Api.SubscriptionModel>): Array<Api.FirmModel> => {
    return _.uniqBy(subscriptions
        .filter(su => su.subscriptionType === "Okargo")
        .filter(x => x.agency.company.companyGroup)
        .map(x => x.agency.company.companyGroup).concat(
        subscriptions.filter(so => so.subscriptionType === "Okargo")
        .map(so => so.agency)
        .concat(subscriptions.filter(so => so.subscriptionType === "Okargo")
            .map(so => so.agency.company))), x => x.firmId);
}

const partContainerStyle = {  };

class ModeratorAgentPortal extends React.Component<ModeratorAgentPortalProps, {}> {

    public render() {
        return (
            <div>
                <h2>{getText("MdtAgents")}</h2>
                <div style={{ marginBottom: 20 }}>
                    <AgentPortalSearchs
                        requestAgentSearchs={(reqTime) => this.props.requestAgentSearchs(reqTime)}
                        openSearchCriteriaDetails={(clientActionId) => this.props.openSearchCriteriaDetails(clientActionId)}
                        closeSearchCriteriaDetails={() => this.props.closeSearchCriteriaDetails()}
                        updateAgentSearchsName={(value) => this.props.updateAgentSearchName(value)}
                        updateAgentSearchFromDate={(value) => this.props.updateAgentSearchFromDate(value)}
                        selectLocation={(location, source) => this.props.selectLocation(location, source)}
                        sizeTypes={this.props.sizeTypes}
                        {...this.props.agentSearchsState}
                    />
                </div>
                <div style={{ marginBottom: 20 }}>
                    <AgentPortalCharges
                        requestChargeAgents={(requesTime) => this.props.requestChargeAgents(requesTime)}
                        requestCreateCharge={(requestTime, chargeAgent) => (this.props.requestCreateCharge(requestTime, chargeAgent) as any) as Promise<any>}
                        requestDeleteCharge={(requestTime, chargeAgentId) => this.props.requestDeleteCharge(requestTime, chargeAgentId)}
                        selectChargeLocation={(location, source) => this.props.selectChargeLocation(location, source)}
                        subscriptions={this.props.subscriptions}
                        sizeTypes={this.props.sizeTypes}
                        currencies={this.props.currencies}
                        chargeNames={this.props.chargeNames}
                        {...this.props.chargeAgentsState}
                    />
                </div>
                <div style={{ marginBottom: 20 }}>
                    <AgentPortalContacts
                        requestContactAgents={(requesTime) => this.props.requestContactAgents(requesTime)}
                        requestCreateContact={(requestTime, contact) => (this.props.requestCreateContact(requestTime, contact) as any) as Promise<any>}
                        requestDeleteContact={(requestTime, contactId) => this.props.requestDeleteContact(requestTime, contactId)}
                        subscriptions={this.props.subscriptions}
                        {...this.props.contactAgentsState}
                    />
                </div>
            </div>
        );
    }
}

type AgentPortalSearchsProps = AgentPortalStore.AgentSearchsState
    & AgentPortalSearchsOwnProps;

interface AgentPortalSearchsOwnProps {
    sizeTypes: { [id: number]: Api.SizeTypeModel }
    requestAgentSearchs: (requestTime: number) => void;
    updateAgentSearchsName: (name: string) => void;
    selectLocation: (location: Api.LocationModel, source: Source) => void;
    updateAgentSearchFromDate: (value: Date) => void;
    openSearchCriteriaDetails: (clientActionId: number) => void;
    closeSearchCriteriaDetails: () => void;
}

const getAgentSearchsColumns = (props: AgentPortalSearchsProps): Array<Column<Api.AgentSearchModel>> => {
    return [
        {
            header: getText("MdtAgentsUser"),
            id: 'user',
            accessor: (d: Api.AgentSearchModel) => d.agentEmail
        },
        {
            header: getText("MdtAgentsPol"),
            id: 'pol',
            accessor: (d: Api.AgentSearchModel) => getLocationName(d.criteria.origin)
        },
        {
            header: getText("MdtAgentsPod"),
            id: 'pod',
            accessor: (d: Api.AgentSearchModel) => getLocationName(d.criteria.destination)
        },
        {
            header: getText("MdtAgentsFromDate"),
            id: 'dateBegin',
            accessor: (d: Api.AgentSearchModel) => new Date(d.criteria.dateBegin).getTime(),
            cell: d => <div>{Moment(d).format("DD/MM/YYYY")}</div>
        },
        {
            header: getText("MdtAgentsToDate"),
            id: 'dateEnd',
            accessor: (d: Api.AgentSearchModel) => new Date(d.criteria.dateEnd).getTime(),
            cell: d => <div>{Moment(d).format("DD/MM/YYYY")}</div>
        },
        {
            header: getText("MdtAgentsContainers"),
            id: 'containers',
            accessor: (d: Api.AgentSearchModel) => d.criteria.criteriaSizeTypes.filter(cs => cs.number > 0)
                .map(cs => cs.number + "x" + props.sizeTypes[cs.sizeTypeId].name).join(", ")
        },
        {
            header: "",
            id: 'actions',
            accessor: (d: Api.AgentSearchModel) => d.clientActionId,
            cell: d => {
                return <div>
                    <span
                        className="action text-blue"
                        onClick={(e) => {
                            props.openSearchCriteriaDetails(d);
                            e.preventDefault();
                        }}
                    >{getText("MstAgentsDetails")}</span>
                </div>
            }
        },
    ];
}

class AgentPortalSearchs extends React.Component<AgentPortalSearchsProps, {}> {

    public render() {
        return (
            <div style={partContainerStyle}>
                <h3>Your agents searchs</h3>
                <div style={searchContainerStyle}>
                    <form className="form-inline" style={filterForm}
                        onSubmit={(e) => {
                            this.props.requestAgentSearchs(new Date().getTime());
                            e.preventDefault();
                        }}>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label" style={{ ...labelStyle }}>{getText("MdtAgentsFilterName")} </label>
                            <div style={{ display: "inline-block" }}>
                                <input type="text" className="form-control" style={inputStyleModerator}
                                    value={this.props.searchAgentSearchs.name}
                                    onChange={(e) => this.props.updateAgentSearchsName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label" style={{ ...labelStyle }}>{getText("MdtAgentsFilterFromDate")}</label>
                            <div style={{ display: "inline-block" }}>
                                <DatePicker
                                    value={this.props.searchAgentSearchs.fromDate}
                                    onChange={(date) => this.props.updateAgentSearchFromDate(date)}
                                />
                            </div>
                        </div>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label" style={{ ...labelStyle }}>{getText("MdtAgentsFilterOrigin")}</label>
                            <div style={{ display: "inline-block" }}>
                                <LocationSelect
                                    className="form-control"
                                    inputKey={"AGENT_SEARCH_ORIGIN"}
                                    value={this.props.searchAgentSearchs.origin}
                                    onChange={(location) => this.props.selectLocation(location, Source.Origin)}
                                />
                            </div>
                        </div>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label">{getText("MdtAgentsFilterDestination")}</label>
                            <div style={{ display: "inline-block" }}>
                                <LocationSelect
                                    className="form-control"
                                    inputKey={"AGENT_SEARCH_DESTINATION"}
                                    value={this.props.searchAgentSearchs.destination}
                                    onChange={(location) => this.props.selectLocation(location, Source.Destination)}
                                />
                            </div>
                        </div>
                        <button
                            style={buttonStyle}
                            type="submit" className="btn btn-sm">{getText("MdtAgentsFilterGo")}</button>
                    </form>
                </div>
                <div>
                    <Table
                        tableKey={"modChargesAgents"}
                        data={this.props.agentSearchs}
                        columns={getAgentSearchsColumns(this.props)}
                        rowsPerPage={10}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        noDataText="No data, please use the filter above"
                    />
                </div>
                <DialogModal
                    maxWidth={"md"}
                    contentLabel="Transfer data"
                    onRequestClose={() => this.props.closeSearchCriteriaDetails()}
                    isOpen={this.props.detailsOpened}
                >
                    <ModeratorAgentSearchDetails
                        agentSearch={this.props.agentSearchs.find(as => as.clientActionId === this.props.clientActionId)}
                    />
                </DialogModal>
            </div>
            );
    }
}

type AgentPortalChargesProps = AgentPortalStore.ChargeAgentsState
    & AgentPortalChargesOwnProps;

interface AgentPortalChargesOwnProps {
    subscriptions: Array<Api.SubscriptionModel>;
    sizeTypes: { [id: number]: Api.SizeTypeModel }
    currencies: { [id: number]: Api.CurrencyModel }
    chargeNames: { [id: number]: Api.ChargeNameModel }
    requestCreateCharge: (requestTime: number, chargeAgent: Api.ChargeAgentModel) => Promise<any>;
    requestChargeAgents: (requestTime: number) => void;
    requestDeleteCharge: (requestTime: number, chargeAgentId: number) => void;
    selectChargeLocation: (location: Api.LocationModel, source: Source) => void;
}

const getChargesColumns = (props: AgentPortalChargesProps): Array<Column<Api.ChargeAgentModel>> => {
    let firms = getFirms(props.subscriptions);

    return [
        {
            header: 'Type',
            id: 'type',
            accessor: (d: Api.ChargeAgentModel) => d.chargeAgentType === "Modification"
            ? "Modification" : "Charge added"
        },
        {
            header: 'Name',
            id: 'name',
            accessor: (d: Api.ChargeAgentModel) => d.chargeAgentType === "Modification"
                ? props.chargeNames[d.chargeNameId].shortName
                : d.name
        },
        {
            header: 'Amount',
            id: 'amount',
            accessor: (d: Api.ChargeAgentModel) => d.amount
        },
        {
            header: 'Modification',
            id: 'modification',
            accessor: (d: Api.ChargeAgentModel) => {
                if (d.modificationType === "AddAmount")
                    return "Add amount";

                if (d.modificationType === "ReplaceAmount")
                    return "Replace amount";

                if (d.modificationType === "Exclude")
                    return "Exclude";
            }
        },
        {
            header: 'Origin',
            id: 'origin',
            accessor: (d: Api.ChargeAgentModel) => d.origin
                ? d.origin.name : ""
        },
        {
            header: 'Destination',
            id: 'destination',
            accessor: (d: Api.ChargeAgentModel) => d.destination
                ? d.destination.name : ""
        },
        {
            header: 'Agency/Company',
            id: 'firm',
            accessor: (d: Api.ChargeAgentModel) => firms.some(fi => fi.firmId === d.firmId)
                ? getFirmName(firms.find(fi => fi.firmId === d.firmId))
                : ""
        },
        {
            header: ' ',
            id: 'action',
            accessor: (d: Api.ChargeAgentModel) => d.chargeAgentId,
            cell: id => {
                return <div>
                    <span
                        className="text-blue action"
                        onClick={(e) => {
                            props.requestDeleteCharge(new Date().getTime(), id);
                            e.preventDefault();
                        }}
                    >
                        <i className="glyphicon glyphicon-remove" />
                    </span>
                </div>
            }
        },
    ];
}
class AgentPortalCharges extends React.Component<AgentPortalChargesProps, {}> {
    constructor(props: AgentPortalChargesProps) {
        super(props);
    }

    componentWillMount() {
        if (!this.props.isLoaded && !this.props.isLoading) {
            this.props.requestChargeAgents(new Date().getTime());
        }
    }

    private createForm: any;

    public render() {
        return (
            <div style={partContainerStyle}>
                <h3>Charges for agents</h3>
                <div>
                    <Table
                        tableKey={"modChargesAgents"}
                        data={this.props.chargeAgents}
                        columns={getChargesColumns(this.props)}
                        rowsPerPage={10}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        noDataText="No charges found"
                    />
                </div>
                <div>
                    <div style={{ ...formContainerStyle, margin: "auto", paddingTop: 20 }}>
                        <ModeratorChargeAgentForm
                            onSubmit={(values) => {
                                return this.props.requestCreateCharge(new Date().getTime(), values)
                                    .then(() => this.createForm.reset());
                            }}
                            ref={(form) => this.createForm = form}
                        />
                    </div>
                </div>
            </div>
            );
    }
}

type AgentPortalContactsProps = AgentPortalStore.ContactAgentsState
    & AgentPortalContactsOwnProps;

interface AgentPortalContactsOwnProps {
    subscriptions: Array<Api.SubscriptionModel>;
    requestCreateContact: (requestTime: number, contact: Api.ContactModel) => Promise<any>;
    requestContactAgents: (requestTime: number) => void;
    requestDeleteContact: (requestTime: number, contactId: number) => void;
    
}

const getContactsColumns = (props: AgentPortalContactsProps): Array<any> => {
    let firms = getFirms(props.subscriptions);

    return [
        {
            header: 'Firstname',
            id: 'firstName',
            accessor: (d: Api.ContactModel) => d.firstName
        },
        {
            header: 'Lastname',
            id: 'lastName',
            accessor: (d: Api.ContactModel) => d.lastName
        },
        {
            header: 'Email',
            id: 'email',
            accessor: (d: Api.ContactModel) => d.email1
        },
        {
            header: 'Phone 1',
            id: 'phone1',
            accessor: (d: Api.ContactModel) => d.phone1
        },
        {
            header: 'Phone 2',
            id: 'phone2',
            accessor: (d: Api.ContactModel) => d.phone2
        },
        {
            header: ' ',
            id: 'action',
            accessor: (d: Api.ContactModel) => d.contactId,
            cell: id => {
                return <div>
                    <span
                        className="text-blue action"
                        onClick={(e) => {
                            props.requestDeleteContact(new Date().getTime(), id);
                            e.preventDefault();
                        }}
                    >
                        <i className="glyphicon glyphicon-remove" />
                    </span>
                </div>
            }
        },
    ];
}
class AgentPortalContacts extends React.Component<AgentPortalContactsProps, {}> {

    constructor(props: AgentPortalContactsProps) {
        super(props);
    }

    componentWillMount() {
        if (!this.props.isLoaded && !this.props.isLoading) {
            this.props.requestContactAgents(new Date().getTime());
        }
    }

    private createForm: any;

    public render() {
        return (
            <div style={partContainerStyle}>
                <h3>Contacts for agents</h3>
                <div>
                    <Table
                        tableKey={"modContactAgents"}
                        data={this.props.contacts}
                        columns={getContactsColumns(this.props)}
                        rowsPerPage={10}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        showPagination={true}
                        noDataText="No contacts found"
                    />
                </div>
                <div>
                    <div style={{ ...formContainerStyle, margin: "auto", paddingTop: 20 }}>
                        <ModeratorContactAgentForm
                            onSubmit={(values) => {
                                return this.props.requestCreateContact(new Date().getTime(), values)
                                    .then(() => this.createForm.reset());
                            }}
                            ref={(form) => this.createForm = form}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
//selectChargeLocation: (location: Api.LocationModel, source: Source) => void;
//updateChargeLocationInputText: (value: string, source: Source) => void;
export default connect(
    (state: ApplicationState) => ({
        ...state.agentPortal,
        subscriptions: state.account.currentUser.clientModel
            ? state.account.currentUser.clientModel.subscriptions
            : [],
        sizeTypes: state.seed.sizeTypes,
        carriers: state.seed.carriers,
        chargeNames: state.seed.chargeNames,
        currencies: state.seed.currencies
    } as any),
    AgentPortalStore.actionCreators)(ModeratorAgentPortal) as any