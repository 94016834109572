import * as React from 'react';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as HistoryStore from '../store/History';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import { getLocationName } from '../utils/utils';
import Loader from './Loader';
import DatePicker from './DatePicker';
import Table, { Column } from './Table';
import Button from './Button';

type HistoryOfferMemoProps = HistoryOfferMemoOwnProps
    & HistoryStore.OfferMemoState
    & RouteComponentProps<{}>
    & typeof HistoryStore.actionCreators;

interface HistoryOfferMemoOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
}

const getColumns = (props: HistoryOfferMemoProps): Array<Column<Api.OfferMemoModel>> => {
    return [
        {
            header: getText("HstOfferMemoCarrier"),
            id: 'carrier',
            accessor: (d: Api.OfferMemoModel) => props.carriers[d.carrierId].name,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("HstOfferMemoOrigin"),
            id: 'origin',
            accessor: (d: Api.OfferMemoModel) => getLocationName(d.originTerminal)
                || getLocationName(d.originPort),
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("HstOfferMemoDestination"),
            id: 'destination',
            accessor: (d: Api.OfferMemoModel) => getLocationName(d.destinationTerminal)
                || getLocationName(d.destinationPort),
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("HstOfferMemoReference"),
            id: 'reference',
            accessor: (d: Api.OfferMemoModel) => d.chargeSet
                ? d.chargeSet.carrierReference
                : getText("SlcOfferRatesNoRates"),
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("HstOfferMemoContainers"),
            id: 'containers',
            accessor: (d: Api.OfferMemoModel) => d.criteria.criteriaSizeTypes
                .filter(cst => 0 < cst.number)
                .map(cst => cst.number + "x" + props.sizeTypes[cst.sizeTypeId].name).join(" "),
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: '',
            id: 'actions',
            accessor: (d: Api.OfferMemoModel) => d.offerMemoId,
            cell: (d: any) => {
                return <div>
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            if (!props.downloadStates[d.value] || !props.downloadStates[d].isLoading) {
                                props.requestDownloadOfferMemo(new Date().getTime(), d);
                            }
                            e.preventDefault();
                        }}
                    >
                        {getText("HstOfferMemoDownload")}
                    </span>
                </div>
            }
        },
    ];
}

const searchFieldStyle = { marginRight: 20 }
const searchContainerStyle = { marginBottom: 20 }

class HistoryOfferMemo extends React.Component<HistoryOfferMemoProps, {}> {

    onPropsUpdate(props: HistoryOfferMemoProps) {
        if (new Date().getTime() - this.props.requestTime > 1000 * 60 * 10) {
            this.props.requestOfferMemos(new Date().getTime());
        }
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: HistoryOfferMemoProps) {
        this.onPropsUpdate(nextProps);
    }

    public render() {
        return (
            <div>
                <div style={searchContainerStyle}>
                    <form className="form-inline"
                        onSubmit={(e) => {
                            this.props.requestOfferMemos(new Date().getTime());
                            e.preventDefault();
                        }}>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label">{getText("HstOfferMemoFilterFromDate")}</label>
                            <div style={{ display: "inline-block" }}>
                                <DatePicker
                                    value={this.props.searchOfferMemoHistory.fromDate}
                                    onChange={(date) => this.props.updateSearchOfferMemoDateFrom(date)}
                                />
                            </div>
                        </div>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <Button
                                style={searchFieldStyle}
                                type="submit"
                                className="btn btn-orange">{getText("HstOfferMemoFilterSearch")}</Button>
                        </div>
                    </form>
                </div>
                <h3>Memo history</h3>
                <div>
                    <Table
                        tableKey={"historyMemo"}
                        data={this.props.offerMemos}
                        columns={getColumns(this.props)}
                        rowsPerPage={10}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPagination={true}
                        showPageSizeOptions={false}
                        noDataText="No data, please use the filter above"
                    />
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.history.offerMemoState,
    carriers: state.seed.carriers,
    sizeTypes: state.seed.sizeTypes
} as HistoryOfferMemoProps), HistoryStore.actionCreators)(HistoryOfferMemo) as any;