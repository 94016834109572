import * as React from 'react';
import { baseColors } from '../styles/colors';

export const formContainerStyle = { maxWidth: 500, marginTop: 20 };
export const searchFieldStyle = { marginRight: "1em", display: "flex", flexDirection: "row", alignItems: "center" } as React.CSSProperties;
export const inputStyleModerator = { height: 30, backgroundColor: "white", marginBottom: 10, borderRadius: 5, borderColor: "black", borderWidth: 0.5, width: 250 };
export const labelStyle = { marginRight: "8px" }
export const searchContainerStyle = { marginBottom: 20 }
export const filterForm =  {   display: "flex", flexDirection: "row", alignItems: "flex-start" } as React.CSSProperties;
export const buttonStyle =   { color: "white", backgroundColor: baseColors.lightBlue, marginRight: "1em" } as React.CSSProperties;
export const selectContainerStyle = { minWidth: 200, display: "inline-block" }
