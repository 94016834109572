import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import * as _ from 'lodash';
const levenshtein = require('js-levenshtein') as (x: string, y: string) => number;

export type LocationState = { [key: string]: LocationSelectState };

export interface LocationSelectState {
    locationDistances: Array<Api.LocationDistanceModel>;
    isLoading: boolean;
    requestTime: number;
    address: string;
    addressFound: string;
    isOpen: boolean;
    locations: Array<Api.LocationModel>;
    inputText: string;
    region?: string;
    center: { lat: number; lng: number };
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface LocationInitSelectState { type: 'LOCATION_INIT_SELECTSTATE', payload: { key: string } }
interface ToggleSearchLocation { type: 'TOGGLE_SEARCH_LOCATION', payload: { key: string, value: boolean } }
interface RequestSearchByAddress { type: 'REQUEST_SEARCH_BY_ADDRESS', payload: { key: string, address: string, requestTime: number } }
interface ReceiveSearchByAddress { type: 'RECEIVE_SEARCH_BY_ADDRESS', payload: { key: string, result: Api.SearchByAddressResult, requestTime: number }}
interface UpdateSearchAddress { type: 'UPDATE_SEARCH_ADDRESS', payload: { key: string, value: string } }

interface RequestLocations { type: 'REQUEST_LOCATIONS', payload: { key: string, requestTime: number } }
interface ReceiveLocations { type: 'RECEIVE_LOCATIONS', payload: { key: string, locations: Array<Api.LocationModel>, requestTime: number } }
interface UpdateLocationInputText { type: 'UPDATE_LOCATION_INPUT_TEXT', payload: { key: string, value: string } }
interface LocationResetSelect { type: 'LOCATION_RESET_SELECT', payload: { key: string } }
interface LocationEmptyOptions { type: 'LOCATION_EMPTY_OPTIONS', payload: { key: string } }
interface LocationUpdateRegion { type: 'LOCATION_UPDATE_REGION', payload: { key: string; value: string; } }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = 
    RequestSearchByAddress
    | ReceiveSearchByAddress
    | ToggleSearchLocation
    | UpdateSearchAddress
    | RequestLocations
    | ReceiveLocations
    | UpdateLocationInputText
    | LocationResetSelect
    | LocationInitSelectState
    | LocationEmptyOptions
    | LocationUpdateRegion
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestSearchByAddress: (key: string, requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let state = getState().location[key];
        if (!state || requestTime === state.requestTime)
            return;

        let address = state.address;

        let api = new Api.LocationApi();
        let fetchTask = api.searchLocationsByAddress({ model: { address: address } }, { headers: getDefaultHeaders(getState()) })
            .then(data => {
                dispatch({
                    type: 'RECEIVE_SEARCH_BY_ADDRESS',
                    payload: { key: key, result: data, requestTime: requestTime }
                });
                return data;
            }).catch(error => {
                console.log('Error searching by address: ' + error.message);
                dispatch({ type: 'RECEIVE_SEARCH_BY_ADDRESS', payload: { key: key, result: { locationDistances: [] }, requestTime: requestTime } });
                dispatch(Notifications.error({ message: "Error searching locations by address", title: "Error", position: "tc" }) as any);
            });

        addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
        dispatch({ type: 'REQUEST_SEARCH_BY_ADDRESS', payload: { key: key, address: address, requestTime: requestTime } });
    },
    initSelectState: (key: string): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: "LOCATION_INIT_SELECTSTATE", payload: { key: key } });
    },
    toggleSearchLocationOpened: (key: string, value: boolean) => <ToggleSearchLocation>{ type: "TOGGLE_SEARCH_LOCATION", payload: { value: value, key: key }},
    updateSearchAddress: (key: string, value: string) => <UpdateSearchAddress>{ type: "UPDATE_SEARCH_ADDRESS", payload: { value: value, key: key }},
    requestLocationSearch: (key: string, type: string, region: string, requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let state = getState().location[key];

        if (!state || requestTime === state.requestTime)
            return;

        let term = state.inputText;
        let api = new Api.LocationApi();
        let fetchTask = api.searchLocation({ term: term, type: type, region: region }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(locations => {
                dispatch({
                    type: 'RECEIVE_LOCATIONS', payload: {
                        key: key,
                        locations: _.sortBy(locations.map((x, xi) => ({ location: x, index: xi })),
                            x => Math.min(levenshtein(x.location.code, term), levenshtein(x.location.name, term)) <= 1
                                ? -1
                                : x.index).map(x => x.location),
                        requestTime: requestTime
                    }
                });
            }).catch(error => {
                console.log('Error searching locations: ' + error.message);
                dispatch({ type: 'RECEIVE_LOCATIONS', payload: { key: key, locations: [], requestTime: requestTime } });
                dispatch(Notifications.error({ message: "Error searching locations", title: "Error", position: "tc" }) as any);
            });

        addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
        dispatch({ type: 'REQUEST_LOCATIONS', payload: { key: key, requestTime: requestTime }});
    },
    updateLocationInputText: (key: string, value: string) => <UpdateLocationInputText>{ type: "UPDATE_LOCATION_INPUT_TEXT", payload: { key: key, value: value } },
    locationEmptyOptions: (key: string) => <LocationEmptyOptions>{
        type: "LOCATION_EMPTY_OPTIONS",
        payload: { key: key }
    },
    locationUpdateRegion: (key: string, value: string) => <LocationUpdateRegion>{
        type: "LOCATION_UPDATE_REGION",
        payload: { key: key, value: value }
    },
};
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unLoadedLocationSelectState: LocationSelectState = {
    center: { lat: 48.8566, lng: 2.3522 },
    isLoading: false,
    isOpen: false,
    addressFound: null,
    locationDistances: [],
    requestTime: 0,
    address: "",
    inputText: "",
    locations: []
}

const unloadedState: LocationState = {};

export const reducer: Reducer<LocationState> = (state: LocationState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "LOCATION_INIT_SELECTSTATE":
            return {
                ...state,
                [action.payload.key]: {
                    ...unLoadedLocationSelectState,
                    ...state[action.payload.key]
                }
            };
        case 'REQUEST_LOCATIONS':
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    isLoading: true,
                    locations: [],
                    requestTime: action.payload.requestTime
                }
            };
        case 'RECEIVE_LOCATIONS':
            if (state[action.payload.key]
                && state[action.payload.key].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    isLoading: false,
                    locations: action.payload.locations
                }
            };
        case "UPDATE_LOCATION_INPUT_TEXT":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    inputText: action.payload.value,
                }
            };
        case "REQUEST_SEARCH_BY_ADDRESS":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    isLoading: true,
                    locationDistances: [],
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_SEARCH_BY_ADDRESS":
            if (state[action.payload.key].requestTime
                && action.payload.requestTime !== state[action.payload.key].requestTime)
                return state;

            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    isLoading: false,
                    locationDistances: action.payload.result.locationDistances || [],
                    addressFound: action.payload.result.addressFound || "",
                    center: {
                        lat: action.payload.result.lat,
                        lng: action.payload.result.lng
                    }
                }
            };
        case "TOGGLE_SEARCH_LOCATION":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    isOpen: action.payload.value
                }
            };
        case "UPDATE_SEARCH_ADDRESS":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    address: action.payload.value
                }
            };
        case "LOCATION_RESET_SELECT":
            return {
                ...state,
                [action.payload.key]: unLoadedLocationSelectState
            };
        case "LOCATION_EMPTY_OPTIONS":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    locations: []
                }
            };
        case "LOCATION_UPDATE_REGION":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    region: action.payload.value
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
