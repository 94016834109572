import * as React from 'react';
import * as _ from 'lodash';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {ApplicationState} from "./store";
import * as Api from './api/api';
import * as AccountStore from './store/Account';
import * as SeedStore from './store/Seed';
import * as MultiPortStore from './store/MultiPort';
import {initDataTypes} from "./utils/datatypeUtils";
import {loadTexts} from "./utils/langTexts";
import * as Cookies from "js-cookie";
import {languageCookie} from "./utils/constants";
import {history, store} from './store/configureStore';
import {getDefaultHeaders} from "./utils/utils";
import {flushActions, sendActions} from "./middlewares/actionLogger";

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const initData = (requestTime: number,
                  getState: () => ApplicationState,
                  dispatch: (action: SeedStore.KnownAction
                      | AccountStore.KnownAction
                      | MultiPortStore.KnownAction) => void): Promise<any> => {

    let requestAccountTask = AccountStore.requestCurrentUser(requestTime, dispatch, getState);
    let requestSeedTask = SeedStore.requestSeed(requestTime, dispatch, getState);
    let requestOptionsTask = SeedStore.requestServicesOptions(requestTime, dispatch, getState);

    return Promise.all([new Promise<void>((resolve, reject) => {
        requestAccountTask.then(() => {
            let currentUser = getState().account.currentUser;
            if (currentUser
                && currentUser.clientModel
                && currentUser.clientModel.defaultMultiPorts
                && currentUser.clientModel.subscriptions
                && currentUser.clientModel.subscriptions.some(x => x.subscriptionType === "Okargo")) {
                MultiPortStore.getCurrentMultiPort(new Date().getTime(), dispatch, getState).then(() => {
                    resolve();
                }, reject);
            }
            resolve();
        }, reject);
    }), requestSeedTask
        , requestOptionsTask]);
}

const fetchTexts = (languageCode: string): Promise<{ [key: string]: string }> => {
    let seedApi = new Api.SeedApi();
    return seedApi.getTexts({ langCode: languageCode }, { rejectUnauthorized: false });
};


initDataTypes();

initData(new Date().getTime(), store.getState, store.dispatch).then(() => {
    let languages = _.values(store.getState().seed.languages);
    let languageCode = navigator.language;
    let langCookie = Cookies.get(languageCookie);
    if(langCookie){
        //c={langCode}|uic={uiLangCode}
        languageCode = langCookie.split("|")[0].split('=')[1];
    }
    store.dispatch({
        type: "SELECT_APPLICATION_LANGUAGE",
        languageId: languages.length == 0
            ? 0
            : languages
                .sort((a, b) => a.code.toLowerCase() === languageCode.toLowerCase().substring(0, 2) ? -1 : 1)[0].languageId
    } as AccountStore.SelectApplicationLanguage);
    fetchTexts(languageCode).then(texts => {
        //Load languages texts in memory
        loadTexts(texts);

        ReactDOM.render(
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>,
            document.getElementById('root'));

        registerServiceWorker();
    }).catch(err => {
        console.error("Error while loading texts");
        console.error(err);
    });

    console.log("Action logs: ", store.getState().seed.servicesOptions.actionsLogEnabled);
}).catch(err => {
    console.error("Error while loading data");
    console.error(err);
});

const logBeets = 60;
const logInterval = setInterval(() => {
    if (store.getState().seed.servicesOptions.actionsLogEnabled) {
        sendActions().then(() => {
            flushActions();
        }).catch(() => {
            flushActions();
        });
    } else {
        flushActions();
    }
}, 1000 * logBeets);