import * as React from 'react';
import * as Api from '../api/api';
import * as SellingRatesStore from "../store/SellingRates";
import * as TabsStore from "../store/Tabs";
import Table, { Column } from './Table';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getLocationName, getFirmName } from '../utils/utils';
import { getText } from '../utils/langTexts';
import { CreateSellingRatesForm, UpdateSellingRatesForm } from "./SellingRatesForm";
import * as Colors from "../styles/colors";
import CenteredContentTabs from './CenteredContentTabs';

type ModeratorSellingRatesProps = ModeratorSellingRatesOwnProps
    & SellingRatesStore.SellingRatesState
    & typeof SellingRatesStore.actionCreators
    & typeof TabsStore.actionCreators;

interface ModeratorSellingRatesOwnProps {
    subscriptions: Array<Api.SubscriptionModel>;
    portsZones: { [id: number]: Api.PortsZoneModel };
    currencies: { [id: number]: Api.CurrencyModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

type SellingRatesColumn = Column<Api.SellingRatesModel>;

class ModeratorSellingRates extends React.Component<ModeratorSellingRatesProps, {}> {

    componentWillMount() {
        this.props.requestSellingRates(new Date().getTime());
    }

    findFirmById(id: number): Api.FirmModel {
        return this.props.subscriptions
            .filter(su => su.subscriptionType === "Okargo")
            .filter(x => x.agency.company.companyGroup)
            .map(x => x.agency.company.companyGroup).
            concat(
                this.props.subscriptions.filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency)
            .concat(this.props.subscriptions
                .filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company)))
            .find(x => x.firmId === id);
    }

    public render() {
        return (
            <div>
                <h2>{getText("MdtSellingRates")}</h2>
                <div>
                    <div style={{ marginBottom: 20 }}>
                        <Table
                            tableKey={"modSellingRates"}
                            columns={[
                            {
                                id: "author",
                                header: "Author",
                                accessor: (d) => d.author,
                            },
                            {
                                id: "sellingMargin",
                                header: "Selling margin",
                                accessor: (d) => d.sellingRatesValues
                                    .map(x => (Math.round(x.sellingMargin * 100) / 100).toFixed(2) + ' '
                                        + this.props.currencies[x.currencyId].code
                                        + (x.sizeTypeId ? ` ${this.props.sizeTypes[x.sizeTypeId].name}` : ''))
                                    .join(', '),
                            },
                            {
                                id: "origin",
                                feader: "Origin",
                                accessor: (d) => d.originPort
                                    ? getLocationName(d.originPort)
                                    : d.originPortsZoneId
                                        ? this.props.portsZones[d.originPortsZoneId].name
                                        : "*",
                            },
                            {
                                id: "destination",
                                header: "Destination",
                                accessor: (d) => d.destinationPort
                                    ? getLocationName(d.destinationPort)
                                    : d.destinationPortsZoneId
                                        ? this.props.portsZones[d.destinationPortsZoneId].name
                                        : "*",
                            },
                            {
                                id: "firm",
                                header: "Selling margin",
                                accessor: (d) => getFirmName(this.findFirmById(d.firmId)),
                            },
                            {
                                id: "edit",
                                header: <div></div>,
                                accessor: (d) => d.sellingRatesId,
                                cell: d => <div>
                                    <a style={{
                                        cursor: "pointer",
                                        margin: "0px 5px",
                                        ...(this.props.selectedSellingRatesId === d ? { color: Colors.baseColors.lightBlue } : {})
                                    }} onClick={(e) => {
                                        this.props.sellingRatesUpdateSelected(d);
                                        this.props.selectTab("moderatorSellingRates", "edit");
                                    }}>{getText("GenEdit")}</a>
                                    <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                        this.props.requestDeleteSellingRates(new Date().getTime(), d);
                                    }}>{getText("GenDelete")}</a>
                                </div>
                            }
                        ] as Array<SellingRatesColumn>}
                            style={{
                            }}
                            showPagination={true}
                            showPageSizeOptions={false}
                            rowsPerPage={10}
                            data={this.props.sellingRates} />
                    </div>
                    <div >
                        <CenteredContentTabs
                            tabKey={"moderatorSellingRates"}
                        data={[
                            {
                                title: getText("GenCreate"),
                                tabKey: "create",
                                tabContent: <div style={{ padding: 10 }}>
                                    <h3>{getText("GenCreateNewSellingRates")}</h3>
                                    <div style={{ maxWidth: 1000 }}>
                                        <CreateSellingRatesForm
                                            actionName={getText("GenCreate")}
                                            hideReset
                                            onSubmit={(values) => {
                                                return this.props.requestCreateSellingRates(new Date().getTime(), values) as any;
                                            }} />
                                    </div>
                                </div>
                            },
                            {
                                title: getText("GenEdit"),
                                tabKey: "edit",
                                tabContent: <div style={{ padding: 10 }}>
                                    <div style={{ maxWidth: 1000 }}>
                                        <h3>Edit selling selected rates</h3>
                                        {this.props.selectedSellingRatesId
                                            && <div>
                                                <UpdateSellingRatesForm
                                                    actionName={getText("GenSaveChanges")}
                                                    sellingRatesId={this.props.selectedSellingRatesId}
                                                    onSubmit={(values) => {
                                                        return this.props.requestUpdateSellingRates(new Date().getTime(), values) as any;
                                                    }} />
                                            </div>}
                                    </div>
                                </div>
                            }
                            ]} />
                    </div>
                </div>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.sellingRates,
    portsZones: state.seed.portsZones,
    currencies: state.seed.currencies,
    sizeTypes: state.seed.sizeTypes,
    subscriptions: state.account.currentUser.clientModel.subscriptions
} as any), {
    ...SellingRatesStore.actionCreators,
    ...TabsStore.actionCreators
})(ModeratorSellingRates) as any;