import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';

import {getDefaultHeaders, isClient} from '../utils/utils';
import _ from "lodash";

export interface ErrorState {
    isLoading: boolean;
    requestTime: number;
    isOpen: boolean;
    reportSent: boolean;
    lastAction: Action;
    error: any;
}

//I hope i'm not going to break th website doing this
interface TriggerError {
    type: 'TRIGGER_ERROR';
    payload: {     
        action: Action;
        error: any
    }
}

interface RequestReportError { type: 'REQUEST_REPORT_ERROR'; requestTime: number; }
interface ReceiveReportError { type: 'RECEIVE_REPORT_ERROR'; requestTime: number; }

export type KnownAction = TriggerError
    | RequestReportError | ReceiveReportError;

export const actionCreators = {
    requestReportError: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().error.requestTime)
            return;

        let api = new Api.LogApi();
        let error = getState().error.error || "unknow";
        api.logError({
            model: {
                lastAction: getState().error.lastAction,
                message: error,
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({ type: "RECEIVE_REPORT_ERROR", requestTime: requestTime });
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_REPORT_ERROR", requestTime: requestTime });
                console.error("Could not send error report: " + error.message);
            });

        dispatch({ type: "REQUEST_REPORT_ERROR", requestTime: requestTime });
    },
    triggerError: (error: any, errorInfo: any) => {
        console.error(error);
        console.error(errorInfo);

        let action = null;
        let errorParsed = error.message
            ? error.message
            : (typeof error === "string" ? error : JSON.stringify(error));
        if(isClient()){
            action = _.sortBy(((window as any).actionLog?.getLog().actions || [] as Array<{
                action: {
                    type: string
                },
                timestamp: number
            }>), x => -x.timestamp)[0];
        }
        
        return {
            type: "TRIGGER_ERROR",
            payload: { action: action?.action, error: errorParsed }
        } as TriggerError
    }
};

const unloadedState: ErrorState = {
    isLoading: false,
    requestTime: 0,
    isOpen: false,
    reportSent: false,
    lastAction: null,
    error: null
};

export const reducer: Reducer<ErrorState> = (state: ErrorState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "TRIGGER_ERROR":
            return {
                ...state,
                reportSent: false,
                isOpen: true,
                lastAction: action.payload.action,
                error: action.payload.error
            };
        case "REQUEST_REPORT_ERROR":
            return {
                ...state,
                isLoading: true,
                requestTime: action.requestTime
            };
        case "RECEIVE_REPORT_ERROR":
            if (action.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                reportSent: true
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};