import * as React from 'react';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import CurrentUser from './CurrentUser';
import GcuDialog from './GcuDialog';
import Notificator from './Notificator';
import Documents from './Documents';
import DialogModal from './DialogModal';
import * as Colors from "../styles/colors";
import { scrollableArea } from 'react-redux-scroll';
import * as AccountStore from '../store/Account';
import * as DocumentStore from '../store/Document';
import * as ScrollStore from '../store/Scroll';
import * as ErrorStore from '../store/Error';
import * as CarrierRatesPlatformStore from '../store/CarrierRatesPlatform';
import * as SelectionStore from '../store/Selection';
import * as _ from 'lodash';
import { isAuthenticated } from '../security/UserIsAuthenticated';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../styles/materialTheme';
import { styled } from '@material-ui/core/styles';
import { Menu } from "@material-ui/icons";
import { NavLink } from 'react-router-dom';
import NavigationMenu from "./NavigationMenu";
import PopperButton from './PopperButton';
import Tutorial from './Tutorial';
import CarrierRatesPlatformStatus from './CarrierRatesPlatformStatus';
import ErrorDialog from "./ErrorDialog";

const ScrollableDiv = scrollableArea('div');


type LayoutProps = AccountStore.AccountState
    & LayoutOwnProps
    & typeof AccountStore.actionCreators
    & typeof DocumentStore.actionCreators
    & typeof ScrollStore.actionCreators
    & typeof CarrierRatesPlatformStore.actionCreators
    & typeof SelectionStore.actionCreators
    & typeof ErrorStore.actionCreators
    ;

interface LayoutOwnProps {
    languages: { [id: number]: Api.LanguageModel };
    currencies: { [id: number]: Api.CurrencyModel };
    isAuthenticated: boolean;
    pathname: string;
    currentUser: Api.CurrentUserModel;
    isDocumentOpen: boolean;
    scrollPosition: number;
    carrierRatesPlaformStatusIsOpen: boolean;
    advContainerOpened: boolean;
    errorIsOpen: boolean;
}
const OkSelect = (props) => <option {...props}></option>;

const iconStyle = {
    height: 20,
    margin: "0px 3px"
} as React.CSSProperties;

const Styledoption = styled(OkSelect)({

    paddingRight: "1.5em",
    '&:hover': {
        color: Colors.baseColors.lightBlue,
        fontWeight: "bold"
    }
});
const LangAndCurrency = {
    fontWeight: "bold",
    flex: "0 0 auto",
    textAlign: "right"
} as React.CSSProperties;
const BoldAndPointer = {
    fontWeight: "bold",
    cursor: "pointer"
} as React.CSSProperties;
const Dropdown = {
    position: "absolute",
    background: "white",
    textAlign: "left",
} as React.CSSProperties;
interface LayoutState {
    langIsOpen: boolean;
    currencyIsOpen: boolean;
}

class Layout extends React.Component<LayoutProps, LayoutState> {
    constructor(props: LayoutProps) {
        super(props);
        this.state = {
            langIsOpen: false,
            currencyIsOpen: false
        };
    }
    // static getDerivedStateFromError(error) {
    //     // Update state so the next render will show the fallback UI.
    //     return { hasError: true };
    // }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.props.triggerError(error, errorInfo);
    }
    
    get isInland(): boolean {
        return this.props.pathname
        && this.props.pathname.startsWith("/inland");
    }
    
    public render() {
        if(this.props.errorIsOpen){
            return (
                <ErrorDialog />
            );
        }
        
        return <ThemeProvider theme={theme}>
                <div id="app-container"
                onClick={(e) => {
  
                        if (this.props.isMenuOpen)
                        {
                            this.props.closeNavigationMenu()
                        }
                        if (this.state.langIsOpen || this.state.currencyIsOpen)
                        {
                            this.setState({ langIsOpen: false, currencyIsOpen: false })
                        }
                    }}
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        fontFamily: "Calibri",
                        minWidth: 1000,
                        color: Colors.baseColors.darkGray,
                    }}
                >
                    <div className="" style={{
                        height: 110,
                        color: Colors.baseColors.darkGray,
                        order: 0,
                        flex: "0 0 auto",
                        borderBottom: this.props.scrollPosition !== 0
                            ? `1px solid ${Colors.baseColors.lightGray}`
                            : 'none',
                    }}>
                        <div style={{ display: "flex", flexDirection: "row", padding: "20px 60px" }}>
                            <div style={{ flex: "0 0 auto" }}>
                                {this.props.isAuthenticated
                                    && <NavLink to={"/"} style={{ cursor: "pointer" }}>
                                         <img src={"/images/logo.png"}
                                                height={70}
                                                style={{}} />
                                    </NavLink>}
                            </div>
                            <div style={{ flex: 1 }}>
                                <CurrentUser />
                            </div>
                            <div style={LangAndCurrency}>
                                <div style={{ display: "flex", flexDirection: "row-reverse", paddingRight: "4em" }}>
                                    <div>
                                        <div className="hover-blue"
                                            onClick={(e) => { this.setState({ langIsOpen: !this.state.langIsOpen }) }}
                                            style={BoldAndPointer}>
                                                {this.props.languages[this.props.languageId].code.toUpperCase()}<img style={iconStyle} src={"/images/arrowDropDown.svg"} />
                                        </div>
                                        <div hidden={!this.state.langIsOpen}
                                            style={ Dropdown }>
                                            {_.values(this.props.languages)
                                                .filter(x => x.supported && x.languageId != this.props.languageId)
                                                .map(cu => {
                                                    return {
                                                        label: cu.code.toUpperCase(),
                                                        value: cu.languageId
                                                    };
                                                }).map(opt => 
                                                    <Styledoption className="hover-blue"
                                                        style={{ cursor:"pointer" }}
                                                        key={`${opt.label}|${opt.value}`}
                                                        value={opt.value}
                                                        onClick={(e) => this.props.selectApplicationLanguage(opt.value)}>
                                                            {opt.label}
                                                    </Styledoption>
                                                )}
                                            </div>

                                    </div>
                                    {this.props.isAuthenticated
                                        && <div style={{ ...LangAndCurrency, paddingRight: "1em"}}>
                                        <div className="hover-blue"
                                            onClick={(e) => { this.setState({ currencyIsOpen: !this.state.currencyIsOpen }) }}
                                            style={BoldAndPointer}>
                                                {this.props.currencies[this.isInland 
                                                    ? this.props.currentUser.clientModel.inlandCurrencyId 
                                                    : this.props.currentUser.clientModel.currencyId].code.toUpperCase()}<img style={iconStyle} src={"/images/arrowDropDown.svg"} />
                                        </div>
                                        <div hidden={!this.state.currencyIsOpen} style={Dropdown}>
                                            {_.sortBy(_.values(this.props.currencies))
                                                .filter(x => x.primary && x.code && x.currencyId != (this.isInland
                                                    ? this.props.currentUser.clientModel.inlandCurrencyId
                                                    : this.props.currentUser.clientModel.currencyId))
                                                    .map(cu => {
                                                        return {
                                                            label: cu.code.toUpperCase(),
                                                            value: cu.currencyId
                                                        };
                                                    }).map(opt =>
                                                        <Styledoption className="hover-blue"
                                                            style={{ cursor: "pointer" }}
                                                            
                                                            key={`${opt.label}|${opt.value}`}
                                                            value={opt.value}
                                                            onClick={(e) => {
                                                                if(this.isInland){
                                                                    this.props.selectInlandCurrency(opt.value);
                                                                } else {
                                                                    this.props.selectCurrency(opt.value);
                                                                }
                                                            }}>
                                                                {opt.label}
                                                        </Styledoption>
                                                    )}
                                            </div>
                                        </div>}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", paddingTop: 3 }}>
                                    {this.props.isAuthenticated && <PopperButton buttonStyle={{
                                        height: 35,
                                        width: 35,
                                        backgroundColor: Colors.baseColors.lightBlue,
                                        color: "white",
                                        cursor: "pointer",
                                        borderRadius: 5,
                                        fontSize: 25,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        minWidth: 35,
                                        padding: 0,
                                        marginRight: 8,
                                    }}
                                        windowContainer={true}
                                        open={this.props.isMenuOpen}
                                        popperStyle={{
                                            marginTop: 20,
                                            width: 350,
                                            zIndex: 9999
                                        }}
                                        paperStyle={{
                                            padding: "30px 30px 30px 50px",
                                            position: "relative",
                                            right: 44
                                        }}
                                        buttonContent={<div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}><Menu /></div>}
                                        onChange={(value) => {
                                            if (value) {
                                                this.props.openNavigationMenu();
                                            }
                                            else {
                                                this.props.closeNavigationMenu();
                                            }
                                        }}>
                                        <NavigationMenu onNavigation={() => this.props.closeNavigationMenu()} />
                                    </PopperButton>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollableDiv id="scroll-container"
                        onScroll={(e) => {
                            if (e.target
                                && e.target.id !== "scroll-container") {
                                return;
                            }
                            let h = e.target,
                                b = e.target,
                                st = 'scrollTop',
                                sh = 'scrollHeight';
                            let scrollValue = Math.ceil((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 4);
                            this.props.updateScrollPosition(scrollValue);
                        }}
                        style={{
                            order: 1,
                            flex: 1,
                            display: "block",
                            overflowY: "auto"
                        }}>
                        {this.props.children}
                    </ScrollableDiv>
                    <Notificator />
                    <GcuDialog />
                {/*    <Tutorial />*/}
                    <DialogModal contentLabel={"Documents"}
                        maxWidth={"lg"}
                        isOpen={this.props.isDocumentOpen}
                        onRequestClose={() => this.props.closeFirmDocument()}>
                        <Documents />
                    </DialogModal>
                    <DialogModal
                        maxWidth={"md"}
                        contentLabel="Carrier rates platforms status"
                        onRequestClose={() => this.props.closeCarrierRatesPlatformStatus()}
                        isOpen={this.props.carrierRatesPlaformStatusIsOpen}>
                        <CarrierRatesPlatformStatus />
                    </DialogModal>
                </div>
                </ThemeProvider>;
    }
}

export default connect((state: ApplicationState) => ({
    ...state.account,
    languages: state.seed.languages,
    isAuthenticated: isAuthenticated(state),
    currenUser: state.account.currentUser,
    currencies: state.seed.currencies,
    isDocumentOpen: state.document.isOpen,
    scrollPosition: state.scroll.scrollPosition,
    carrierRatesPlaformStatusIsOpen: state.carrierRatesPlatform.status.isOpen,
    advContainerOpened: state.selection.advContainerOpened,
    errorIsOpen: state.error.isOpen,
    pathname: state.router.location.pathname
} as any), {
        ...AccountStore.actionCreators,
        ...DocumentStore.actionCreators,
        ...ScrollStore.actionCreators,
        ...CarrierRatesPlatformStore.actionCreators,
        ...SelectionStore.actionCreators,
        ...ErrorStore.actionCreators
})(Layout) as any;
