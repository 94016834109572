import * as React from "react";
import * as _ from "lodash";
import DialogModal from "./DialogModal";
import {getText} from "../utils/langTexts";
import {connect} from "react-redux";
import {ApplicationState} from "../store";
import * as RatesFetcher from "../store/RatesFetcher";
import {colors} from "../styles/colors";
import * as Api from '../api/api';
import * as Colors from "../styles/colors";
import Button from "./Button";

type MfaPopupProps = MfaPopupOwnProps
    & RatesFetcher.RatesFetcherState
    & typeof RatesFetcher.actionCreators;

interface MfaPopupOwnProps {
    carrierRatesPlatforms: {[id: number]: Api.CarrierRatesPlatformModel }
    carriers: {[id: number]: Api.CarrierModel }
}

const inputStyle = { minWidth: 200, height: 25, borderRadius: 5, borderColor: Colors.baseColors.darkGray, borderWidth: 0.5, } as React.CSSProperties;

class MfaPopup extends React.Component<MfaPopupProps, {}> {
    constructor(props: MfaPopupProps) {
        super(props);
    }

    public render() {
        console.log(_.keys(this.props.mfaStates));
        return (
            <DialogModal isOpen={this.props.isMfaOpened}
                         maxWidth={"lg"}
                         onRequestClose={() => this.props.closeMfaDialog()}
                         contentLabel={"Mfa popup"}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch", padding: "10px 30px 30px 30px" }}>
                    <div style={{ flex: "0 0 auto", fontWeight: 'bold', fontSize: 20, marginBottom: 30, color: colors.main2.color }}>{getText("OcqMfaBoxTitle")}</div>
                    {_.map(_.keys(this.props.mfaStates), platformCode => {
                        let platform = _.values(this.props.carrierRatesPlatforms).find(x => x.code === platformCode);
                        console.log(platform);
                        let carrierCode = this.props.carriers[platform?.carrierId]?.code;
                        console.log(carrierCode);
                        if(!carrierCode)
                            return null;
                        
                        return <div key={platformCode}>
                            <div style={{ flex: "0 0 auto", fontWeight: 'bold', fontSize: 18, marginBottom: 20, color: colors.main2.color }}>
                                {carrierCode}
                            </div>
                            <div style={{ color: colors.main1.color }}>
                                {getText("OcqMfa" + platformCode as any) ? getText("OcqMfa" + platformCode as any) : getText("OcqMfaDefault")}
                            </div>
                            {["CCL", "ONE"].some(x => x === platform.code) 
                                && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 10, color: colors.main1.color, paddingTop: 10 }}>
                                    <input
                                        style={inputStyle}
                                        value={this.props.mfaStates[platformCode].value}
                                        onChange={(e) => this.props.updateMfaValue(platformCode, e.target.value)} />
                                    <Button
                                        style={{
                                            minWidth: 100,
                                            height: 30,
                                        }}
                                        onClick={(e) => {
                                            this.props.requestProvideMfa(platformCode, this.props.mfaStates[platformCode].value, new Date().getTime());
                                            e.preventDefault();
                                        }}
                                        disabled={this.props.mfaStates[platformCode].isLoading}>
                                        {getText("OcqMfaSend")}
                                    </Button>
                                </div>}
                        </div>
                    })}
                </div>
            </DialogModal>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.ratesFetcher,
    carrierRatesPlatforms: state.seed.carrierRatesPlatforms,
    carriers: state.seed.carriers
} as MfaPopupProps), { ...RatesFetcher.actionCreators })(MfaPopup) as any;