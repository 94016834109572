import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import { AppThunkAction } from './';
import { getDefaultHeaders, getCarrierOfferKey, getChargeAmount, goToFormModel } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { getSelectedDepart } from '../utils/routeUtils';
import { getText } from '../utils/langTexts';
import { RatesCalculator } from '../utils/ratesUtils';
import * as CriteriaUtils from '../utils/criteriaUtils';

export interface AskBookingState {
    isLoading: boolean;
    requestTime: number;
    isOpen: boolean;
    carrierOfferId: string;
    isAskByEmailOpen: boolean;
    step: number;
    type: AskBookingType;
    askBookingByEmailModel: AskBookingByEmailModel;
    to: string;
    cc: string;
    subject: string;
    content: string;
    goToBookingState: RequestState;
    processDeepLink: {
        isLoading: boolean;
        requestTime?: number;
    }
}

export interface AskBookingByEmailModel {
    shipperName: string;
    consigneeName: string;
    poNumber: string;
    incoterm: string;
    isHazardous: boolean;
    madDate: Date;
    madLocation: string;
    deadline: Date;
    cargoWeight: string;
    cargoDescription: string;
    routingInstruction: string;
}

export interface RequestState {
    isLoading: boolean;
    requestTime: number;
}

export type AskBookingType = "direct" | "byAgent";

interface OpenAskBooking { type: "OPEN_ASKBOOKING"; carrierOfferId: string }
interface CloseAskBooking { type: "CLOSE_ASKBOOKING"; }
interface ToggleAskBookingByEmailOpen { type: "TOGGLE_ASKBOOKING_BYEMAIL_OPEN"; value: boolean }
interface AskBookingNextStep { type: "ASKBOOKING_NEXT_STEP" }
interface AskBookingPrevStep { type: "ASKBOOKING_PREV_STEP" }
interface AskBookingInitEmail { type: "ASKBOOKING_INIT_EMAIL", to: string, cc: string, subject: string, content: string }
interface AskBookingSelectType { type: "ASKBOOKING_SELECT_TYPE", value: AskBookingType }
interface SetAskBookingByEmailModel { type: "SET_ASKBOOKING_BYEMAIL_MODEL", value: AskBookingByEmailModel }
interface AskBookingUpdateTo { type: "ASKBOOKING_UPDATE_TO", value: string }
interface AskBookingUpdateCc { type: "ASKBOOKING_UPDATE_CC", value: string }
interface AskBookingUpdateSubject { type: "ASKBOOKING_UPDATE_SUBJECT", value: string }
interface AskBookingUpdateContent { type: "ASKBOOKING_UPDATE_CONTENT", value: string }
interface RequestAskBookingSendEmail { type: "REQUEST_ASKBOOKING_SEND_EMAIL"; payload: { requestTime: number } }
interface ReceiveAskBookingSendEmail { type: "RECEIVE_ASKBOOKING_SEND_EMAIL"; payload: { requestTime: number }; error?: any }

interface RequestGoCarrierBooking { type: 'REQUEST_GO_CARRIER_BOOKING'; payload: { requestTime: number } }
interface ReceiveGoCarrierBooking { type: 'RECEIVE_GO_CARRIER_BOOKING'; payload: { requestTime: number }; error?: any }

interface RequestProcessDeepLink { type: 'REQUEST_PROCESS_DEEPLINK'; payload: { requestTime: number } }
interface ReceiveProcessDeepLink { type: 'RECEIVE_PROCESS_DEEPLINK'; payload: { requestTime: number; deepLink?: Api.UriResult; }; error?: any }

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = OpenAskBooking
    | CloseAskBooking | ToggleAskBookingByEmailOpen
    | AskBookingNextStep | AskBookingPrevStep
    | AskBookingSelectType | SetAskBookingByEmailModel
    | AskBookingUpdateTo | AskBookingUpdateCc
    | AskBookingUpdateSubject | AskBookingUpdateContent
    | RequestAskBookingSendEmail
    | ReceiveAskBookingSendEmail | AskBookingInitEmail
    | RequestGoCarrierBooking | ReceiveGoCarrierBooking
    | RequestProcessDeepLink | ReceiveProcessDeepLink;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    selectAskBookingType: (value: AskBookingType) => <AskBookingSelectType>{ type: "ASKBOOKING_SELECT_TYPE", value: value },
    openAskBooking: (carrierOfferId: string) => <OpenAskBooking>{ type: "OPEN_ASKBOOKING", carrierOfferId: carrierOfferId },
    closeAskBooking: () => <CloseAskBooking>{ type: "CLOSE_ASKBOOKING" },
    toggleAskBookingByEmailOpen: (value: boolean) => <ToggleAskBookingByEmailOpen>{ type: "TOGGLE_ASKBOOKING_BYEMAIL_OPEN", value: value },
    nextStep: () => <AskBookingNextStep>{ type: "ASKBOOKING_NEXT_STEP" },
    prevStep: () => <AskBookingPrevStep>{ type: "ASKBOOKING_PREV_STEP" },
    setAskBookingByEmailModel: (value: AskBookingByEmailModel) => <SetAskBookingByEmailModel>{ type: "SET_ASKBOOKING_BYEMAIL_MODEL", value: value },
    updateTo: (value: string) => <AskBookingUpdateTo>{ type: "ASKBOOKING_UPDATE_TO", value: value },
    updateCc: (value: string) => <AskBookingUpdateCc>{ type: "ASKBOOKING_UPDATE_CC", value: value },
    updateSubject: (value: string) => <AskBookingUpdateSubject>{ type: "ASKBOOKING_UPDATE_SUBJECT", value: value },
    updateContent: (value: string) => <AskBookingUpdateContent>{ type: "ASKBOOKING_UPDATE_CONTENT", value: value },
    requestSendEmail: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().askBooking.requestTime)
            return Promise.reject("Already did.");

        let carrierOffer = getState().selection.selection.carrierOffers
            .find(co => getCarrierOfferKey(co) === getState().askBooking.carrierOfferId);
        let depart = getSelectedDepart(carrierOffer, getState().selection.carrierOfferStates[getCarrierOfferKey(carrierOffer)]);
        let askBookingState = getState().askBooking;
        let api = new Api.SelectionApi();
        let fetchTask = api.sendAskBooking({
            model: {
                subject: askBookingState.subject,
                body: askBookingState.content,
                to: askBookingState.to,
                chargeSetId: carrierOffer.chargeSet ? carrierOffer.chargeSet.chargeSetId : null,
                departId: depart.departId
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }).then(value => {
            dispatch(Notifications.success({ message: "Your email has been sent", title: "Success", position: "tc" }) as any);
            dispatch({ type: "RECEIVE_ASKBOOKING_SEND_EMAIL", payload: { requestTime: requestTime } });
        }).catch(error => {
            dispatch(Notifications.error({ message: "Error sending your email", title: "Error", position: "tc" }) as any);
            dispatch({ type: "RECEIVE_ASKBOOKING_SEND_EMAIL", payload: { requestTime: requestTime }, error: error });
        });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_ASKBOOKING_SEND_EMAIL", payload: { requestTime: requestTime }});
        return fetchTask;
    },
    initEmail: (): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let carrierOffer = getState().selection.selection.carrierOffers
            .find(co => getCarrierOfferKey(co) === getState().askBooking.carrierOfferId);
        let subject = getText("SlcBookingSubject", {
            companyName: getState().account.currentUser.clientModel.subscriptions
                .sort((a, b) => a.subscriptionId === getState().account.currentUser.clientModel.subscriptionId ? 0 : 1)[0].agency.company.name,
            pol: carrierOffer.originPort.name,
            pod: carrierOffer.destinationPort.name
        });
        let currentUser = getState().account.currentUser;
        let chargeNames = getState().seed.chargeNames;
        let currencies = getState().seed.currencies;
        let sizeTypes = getState().seed.sizeTypes;
        let criteria = getState().criteria.criteria;
        let depart = getSelectedDepart(carrierOffer, getState()
            .selection.carrierOfferStates[getCarrierOfferKey(carrierOffer)]);
        let askBookingModel = getState().askBooking.askBookingByEmailModel;
        let ratesCalculator = new RatesCalculator(
            getState().seed.currencies,
            getState().seed.sizeTypes,
            criteria);
        let bodyParams = {
            shipperName: askBookingModel.shipperName,
            consigneeName: askBookingModel.consigneeName,
            poNumber: askBookingModel.poNumber,
            incoterm: askBookingModel.incoterm,
            carrierReference: carrierOffer.chargeSet.carrierReference,
            pol: carrierOffer.originPort.name,
            pod: carrierOffer.destinationPort.name,
            etd: Moment(depart.etd).format("DD/MM/YYYY"),
            vessel: depart.vessel,
            containers: criteria.criteriaSizeTypes
                .filter(cst => 0 < cst.number)
                .map(cst => cst.number + "x" + sizeTypes[cst.sizeTypeId].name)
                .reduce((a, b) => a + ", " + b, ""),
            hazardous: getText("GenHazardous")
                + ": " + (askBookingModel.isHazardous ? getText("GenYes") : getText("GenNo")),
            cargoWeight: askBookingModel.cargoWeight,
            cargoDescription: askBookingModel.cargoDescription,
            madDate: Moment(askBookingModel.madDate).format("DD/MM/YYYY"),
            madLocation: askBookingModel.madLocation,
            deadline: Moment(askBookingModel.deadline).format("DD/MM/YYYY"),
            routingInstruction: askBookingModel.routingInstruction,
            chargesList: criteria.criteriaSizeTypes
                .filter(cst => 0 < cst.number)
                .map(cst => sizeTypes[cst.sizeTypeId].name
                    + "\n" + ratesCalculator.findChargesToApply(carrierOffer.chargeSet, cst)
                    .map(ch => {
                        if (ch.chargeType === "Value")
                            return chargeNames[ch.chargeNameId].shortName
                                + ": " + getChargeAmount(ch, sizeTypes[cst.sizeTypeId].teu)
                                + " " + currencies[ch.currencyId].code;
                        else if (ch.type === "Incl")
                            return chargeNames[ch.chargeNameId].shortName + ": " + getText("GenIncluded", { value: chargeNames[ch.sourceChargeNameId].shortName });
                        else if (ch.type === "Percentage")
                                return chargeNames[ch.chargeNameId].shortName + ": " + (ch.amount * 100) + "% of " + chargeNames[ch.sourceChargeNameId].shortName;
                    }).join("\n")).join("\n"),
            firstName: currentUser.clientModel.firstName,
            lastName: currentUser.clientModel.lastName,
            companyName: currentUser.clientModel.subscriptions
                .sort((a, b) => b.subscriptionId === currentUser.clientModel.subscriptionId ? 1 : -1)[0].agency.company.name,
            email: currentUser.clientModel.account.email,
            phone1: currentUser.clientModel.phone1,
            phone2: currentUser.clientModel.phone2
        };
        let bodyContent = getState().askBooking.type === "byAgent"
            ? getText("SlcBookingAgentBody", bodyParams)
            : getText("SlcBookingDirectBody", bodyParams);
        dispatch({
            type: "ASKBOOKING_INIT_EMAIL",
            to: carrierOffer.contacts && carrierOffer.contacts.length !== 0
                ? carrierOffer.contacts.filter(co => co.type === "Booking" || co.type === "Both")
                .map(co => co.email1 || co.email2)
                    .reduce((a, b) => a + "; " + b, "")
                : "",
            cc: getState().account.currentUser.clientModel.account.email,
            subject: subject,
            content: bodyContent
        });
    },
    requestGoToCarrierBooking: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().askBooking.goToBookingState.requestTime === requestTime)
            return;

        let api = new Api.SelectionApi();
        let id = getState().askBooking.carrierOfferId;
        let carrierOffer = getState().selection.selection.carrierOffers
            .find(x => getCarrierOfferKey(x) === id);

        api.getBookingForm({
            routeId: carrierOffer.chargeSet?.routeId || carrierOffer.routeConfigs[0]?.routeId,
            from: new Date(),
            to: Moment().add(7, 'days').toDate()
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(form => {
                if (form) {
                    if (form.result) {
                        goToFormModel(form.result);
                    }
                }
                dispatch({ type: "RECEIVE_GO_CARRIER_BOOKING", payload: { requestTime: requestTime }});
            })
            .catch(error => {
                dispatch(Notifications.error({ message: "Error going to carrier booking", title: "Error", position: "tc" }) as any);
                console.log(error.message);
                dispatch({ type: "RECEIVE_GO_CARRIER_BOOKING", payload: { requestTime: requestTime }, error: error });
            });

        dispatch({ type: "REQUEST_GO_CARRIER_BOOKING", payload: { requestTime: requestTime } });
    },
    requestProcessDeepLink: (requestTime: number, chargeSet: Api.ChargeSetModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let fetchTask = CriteriaUtils.getCriteriaCode(getState, getState().criteria.criteria, getState().selection.criteriaLoaded).then(code => {
            dispatch({ type: "REQUEST_PROCESS_DEEPLINK", payload: { requestTime: requestTime } })
            let api = new Api.RatesFetcherApi();
            let task = api.processDeepLink({
                chargeSetId: chargeSet.chargeSetId,
                deepLink: chargeSet.deepLink,
                criteriaCode: code
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(uriResult => {
                    dispatch({ type: "RECEIVE_PROCESS_DEEPLINK", payload: { requestTime: requestTime, deepLink: uriResult } });
                    window.open(uriResult.uri, "_blank");
                })
                .catch(err => {
                    dispatch({ type: "RECEIVE_PROCESS_DEEPLINK", payload: { requestTime: requestTime }, error: err })
                });
            return task;
        }).catch(error => {
            dispatch(Notifications.error({ message: "Error getting deeplink from carrier", title: "Error", position: "tc" }) as any);
        });
        return fetchTask;
    }
};
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AskBookingState = {
    isLoading: false,
    requestTime: 0,
    carrierOfferId: null,
    isOpen: false,
    isAskByEmailOpen: false,
    type: "direct",
    step: 0,
    askBookingByEmailModel: {
        consigneeName: "",
        incoterm: "",
        poNumber: "",
        shipperName: "",
        cargoDescription: "",
        cargoWeight: "",
        deadline: new Date(),
        isHazardous: false,
        madDate: new Date(),
        madLocation: "",
        routingInstruction: ""
    },
    to: "",
    cc: "",
    content: "",
    subject: "",
    goToBookingState: {
        isLoading: false,
        requestTime: 0
    },
    processDeepLink: {
        isLoading: false
    }
};

export const reducer: Reducer<AskBookingState> = (state: AskBookingState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "ASKBOOKING_NEXT_STEP":
            return {
                ...state,
                step: state.step + 1
            };
        case "ASKBOOKING_PREV_STEP":
            return {
                ...state,
                step: state.step - 1
            };
        case "ASKBOOKING_SELECT_TYPE":
            return {
                ...state,
                type: action.value
            };
        case "TOGGLE_ASKBOOKING_BYEMAIL_OPEN":
            return {
                ...state,
                isAskByEmailOpen: action.value,
                isOpen: false
            };
        case "OPEN_ASKBOOKING":
            return {
                ...unloadedState,
                isOpen: true,
                carrierOfferId: action.carrierOfferId
            };
        case "CLOSE_ASKBOOKING":
            return {
                ...state,
                isOpen: false
            };
        case "SET_ASKBOOKING_BYEMAIL_MODEL":
            return {
                ...state,
                askBookingByEmailModel: action.value
            };
        case "ASKBOOKING_UPDATE_TO":
            return {
                ...state,
                to: action.value
            };
        case "ASKBOOKING_UPDATE_CC":
            return {
                ...state,
                cc: action.value
            };
        case "ASKBOOKING_UPDATE_SUBJECT":
            return {
                ...state,
                subject: action.value
            };
        case "ASKBOOKING_UPDATE_CONTENT":
            return {
                ...state,
                content: action.value
            };
        case "REQUEST_ASKBOOKING_SEND_EMAIL":
            return {
                ...state,
                requestTime: action.payload.requestTime,
                isLoading: true
            };
        case "RECEIVE_ASKBOOKING_SEND_EMAIL":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                isAskByEmailOpen: action.error
                    ? state.isAskByEmailOpen
                    : false
            };
        case "ASKBOOKING_INIT_EMAIL":
            return {
                ...state,
                to: action.to,
                cc: action.cc,
                subject: action.subject,
                content: action.content
            };
        case "REQUEST_GO_CARRIER_BOOKING":
            return {
                ...state,
                goToBookingState: {
                    ...state.goToBookingState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_GO_CARRIER_BOOKING":
            if (action.payload.requestTime !== state.goToBookingState.requestTime)
                return state;

            return {
                ...state,
                goToBookingState: {
                    ...state.goToBookingState,
                    isLoading: false
                }
            };
        case "REQUEST_PROCESS_DEEPLINK":
            return {
                ...state,
                processDeepLink: {
                    ...state.processDeepLink,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_PROCESS_DEEPLINK":
            if (action.payload.requestTime !== state.processDeepLink.requestTime)
                return state;

            return {
                ...state,
                processDeepLink: {
                    ...state.processDeepLink,
                    isLoading: false,
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
