import * as React from 'react';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as CarrierRatesSettingsStore from '../store/CarrierRatesSettings';
import * as TabsStore from "../store/Tabs";
import { ApplicationState } from '../store';
import { CreateCarrierRatesSettingsForm, UpdateCarrierRatesSettingsForm } from "./CarrierRatesSettingsForm";
import Loader from './Loader';
import Table, { Column } from './Table';
import { getFirmName } from '../utils/utils';
import { getText } from '../utils/langTexts';
import CenteredContentTabs from './CenteredContentTabs';

type CarrierRatesSettingsProps = CarrierRatesSettingsOwnProps
    & CarrierRatesSettingsStore.CarrierRatesSettingsState
    & typeof CarrierRatesSettingsStore.actionCreators
    & typeof TabsStore.actionCreators;

interface CarrierRatesSettingsOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
    carrierRatesPlatforms: { [id: number]: Api.CarrierRatesPlatformModel };
    subscriptions: Array<Api.SubscriptionModel>;
    firms: Api.FirmModel[];
}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class CarrierRatesSettings extends React.Component<CarrierRatesSettingsProps, {}> {

    componentWillMount() {
        this.props.requestCarrierRatesSettings(new Date().getTime());
    }

    componentWillReceiveProps(nextProps: CarrierRatesSettingsProps) {
    }

    public render() {
        return (
            <div>
                <h2>{getText("GenCarrierQuotesSettings")}</h2>
                <div>
                    <Table
                        tableKey={"carrierQuotesSettings"}
                        style={{ textAlign: "center" }}
                        data={this.props.entities.filter(x => this.props.carrierRatesPlatforms[x.carrierRatesPlatformId]
                            && this.props.carrierRatesPlatforms[x.carrierRatesPlatformId].carrierId && this.props.carrierRatesPlatforms[x.carrierRatesPlatformId].status !== "BetaTest")
                            .filter(x => this.props.subscriptions.some(y => y.allowCarrierRatesManagement || y.subscriptionId == x.subOkargoId
                                || x.firmId === y.agencyId 
                                || x.firmId === y.agency.companyId
                                || x.firmId === y.agency.company.companyGroupId))}
                        columns={[{
                            header:  getText("SlcCarrierQuotesSettingsPlatform") ,
                            id: 'platformId',
                            accessor: (d: Api.CarrierRatesSettingsModel) => <span data-id={d.carrierRatesSettingsId}>{this.props.carrierRatesPlatforms[d.carrierRatesPlatformId].name}</span>
                        },
                        {
                            header: getText("SlcCarrierQuotesSettingsSetUpFor"),
                            id: "setupFo",
                            accessor: (d: Api.CarrierRatesSettingsModel) => {
                                let sub = this.props.subscriptions.find(x => x.subscriptionId === d.subOkargoId);
                                let fi = this.props.firms
                                    .find(x => x && x.firmId === d.firmId);
                                return sub ? getText("SlcCarrierQuotesSettingsOnlyMe") : (fi ? getFirmName(fi) : "");
                            }
                            },
          
                        {
                            id: "actions",
                            header: <div></div>,
                            accessor: (d: Api.CarrierRatesSettingsModel) => d,
                            cell: row => {
                                let carrierRatesSettings = row as Api.CarrierRatesSettingsModel;
                                return (
                                    carrierRatesSettings.source === "Client"
                                        && this.props.carrierRatesPlatforms[carrierRatesSettings.carrierRatesPlatformId].code !== "CMA2"
                                    ? <div>
                                        <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                            this.props.updateCarrierRatesSettingsSelectedId(carrierRatesSettings.carrierRatesSettingsId);
                                            this.props.selectTab("carrierRatesSettings", "edit");
                                        }}>{getText("GenEdit")}</a>
                                        <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                            if (window.confirm(getText("GenConfirmQuestion"))) {
                                                this.props.requestDeleteCarrierRatesSettings(new Date().getTime(), carrierRatesSettings.carrierRatesSettingsId);
                                            }
                                            }}>{getText("GenDeleteUpper")}</a>
                                        </div>
                                        : <div>Managed by administrators</div>
                                    );
                                }
                            }] as Array<Column<Api.CarrierRatesSettingsModel>>}
                        rowsPerPage={8}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        showPagination={true}
                        noDataText="No results"
                    />
                </div>
                <div>
                    <CenteredContentTabs
                        tabKey={"carrierRatesSettings"}
                        data={[
                            {
                                title: getText("GenCreate"),
                                tabKey: "create",
                                tabContent: <div style={{ padding: 10 }}>
                                    <h3>{getText("GenCreateNewQuotesSettings")}</h3>
                                    <div style={{ maxWidth: 1000 }}>
                                        <CreateCarrierRatesSettingsForm
                                            mode={"Create"}
                                            onSubmit={(values) => {
                                                return this.props.requestCreateCarrierRatesSettings(new Date().getTime(), values) as any;
                                            }} />
                                    </div>
                                </div>
                            },
                            {
                                title: getText("GenEdit"),
                                tabKey: "edit",
                                tabContent: <div style={{ padding: 10 }}>
                                    <div style={{ maxWidth: 1000 }}>
                                        <h3>{getText("GenEditCarrierQuotesSettings")}</h3>
                                        {this.props.selectedId
                                            && <div>
                                                <UpdateCarrierRatesSettingsForm
                                                    mode={"Update"}
                                                    onSubmit={(values) => {
                                                        return this.props.requestUpdateCarrierRatesSettings(new Date().getTime(), values) as any;
                                                    }} />
                                            </div>}
                                    </div>
                                </div>
                            }
                        ]}
                    ></CenteredContentTabs>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.carrierRatesSettings,
        carriers: state.seed.carriers,
        subscriptions: state.account.currentUser.clientModel.subscriptions,
        firms: state.account.currentUser.clientModel.subscriptions.filter(x=>x.subscriptionType === "Okargo")
            .map(x => x.agency.company)
            .concat(state.account.currentUser.clientModel.subscriptions.filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company.companyGroup))
            .concat(state.account.currentUser.clientModel.agencies)
            .filter(x => x),
        carrierRatesPlatforms: state.seed.carrierRatesPlatforms
    } as any),
    {
        ...CarrierRatesSettingsStore.actionCreators,
        ...TabsStore.actionCreators
    })(CarrierRatesSettings) as any