import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders, getCarrierOfferKey } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { getSelectedDepart } from '../utils/routeUtils';
import { getText } from '../utils/langTexts';


export interface AskRatesState {
    isLoading: boolean;
    requestTime: number;
    isOpen: boolean;
    emailIsOpen: boolean;
    carrierOfferId?: string;
    to: string;
    cc: string;
    subject: string;
    content: string;
    carriersSelected: { [id: number]: boolean };
}

interface OpenAskRates { type: "OPEN_ASKRATES"; carrierOfferId?: string }
interface CloseAskRates { type: "CLOSE_ASKRATES"; }
interface OpenEmailAskRates { type: "OPEN_EMAIL_ASKRATES"; }
interface CloseEmailAskRates { type: "CLOSE_EMAIL_ASKRATES"; }
interface ToggleAskRatesByEmailOpen { type: "TOGGLE_ASKRATES_BYEMAIL_OPEN"; value: boolean }
interface AskRatesUpdateTo { type: "ASKRATES_UPDATE_TO", value: string }
interface AskRatesUpdateCc { type: "ASKRATES_UPDATE_CC", value: string }
interface AskRatesUpdateSubject { type: "ASKRATES_UPDATE_SUBJECT", value: string }
interface AskRatesUpdateContent { type: "ASKRATES_UPDATE_CONTENT", value: string }
interface AskRatesInitEmail { type: "ASKRATES_INIT_EMAIL", to: string, cc: string, subject: string, content: string }
interface RequestAskRatesSendEmail { type: "REQUEST_ASKRATES_SEND_EMAIL"; payload: { requestTime: number } }
interface ReceiveAskRatesSendEmail { type: "RECEIVE_ASKRATES_SEND_EMAIL"; payload: { requestTime: number }; error?: any }
interface AskRatesSetCarrierSelected { type: "ASKRATES_SET_CARRIER_SELECT", payload: { value: boolean; carrierId: number; to: string }  }

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = OpenAskRates | CloseAskRates | ToggleAskRatesByEmailOpen
    | AskRatesUpdateTo | AskRatesUpdateCc | RequestAskRatesSendEmail
    | AskRatesUpdateSubject | AskRatesUpdateContent 
    | ReceiveAskRatesSendEmail | AskRatesInitEmail
    | OpenEmailAskRates | CloseEmailAskRates | AskRatesSetCarrierSelected;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const getEmailTo = (contacts: Array<Api.ContactModel>): string => {
    return contacts.filter(co => co.type === "Sales" || co.type === "Both")
        .map(co => co.email1 || co.email2)
        .reduce((a, b) => a + "; " + b, "");
};

export const actionCreators = {
    openEmailAskRates: (): AppThunkAction<KnownAction, any> => (dispatch, getState) => {
        let client = getState().account.currentUser.clientModel;
        let criteria = getState().criteria.criteria;
        let sizeTypes = getState().seed.sizeTypes;
        let carrierSelected = getState().askRates.carriersSelected;
        let subscription = client.subscriptions
            .sort((a, b) => a.subscriptionId === client.subscriptionId ? 0 : 1)[0];
        let carrierOffer = getState().selection.selection.carrierOffers
            .find(co => getCarrierOfferKey(co) === getState().askRates.carrierOfferId);

        dispatch({ type: "OPEN_EMAIL_ASKRATES" });

        let contacts = carrierOffer
            ? (carrierOffer.contacts || [])
            : getState().selection.selection.carrierOffers
                .filter(x => carrierSelected[x.carrierId] !== false)
                .map(x => x.contacts)
                .reduce((a, b) => a.concat(b), []);

        let pol = criteria.origin.locationType === "Port"
            ? criteria.origin.name
            : criteria.origin.port.name;

        let pod = criteria.destination.locationType === "Port"
            ? criteria.destination.name
            : criteria.destination.port.name;

        let subject = getText("SlcAskRatesSubject", {
            companyName: subscription.agency.company.name,
            pol: pol,
            pod: pod
        });
        let bodyContent = getText("SlcAskRatesBody", {
            containers: criteria.criteriaSizeTypes
                .filter(cst => 0 < cst.number)
                .map(cst => cst.number + "x" + sizeTypes[cst.sizeTypeId].name)
                .join(", "),
            pol: pol,
            pod: pod,
            firstName: client.firstName,
            lastName: client.lastName,
            companyName: subscription.agency.company.name,
            email: client.account.email,
            phone1: client.phone1 ? client.phone1 : "",
            phone2: client.phone2 ? client.phone2 : ""
        });
        dispatch({
            type: "ASKRATES_INIT_EMAIL",
            to: getEmailTo(contacts),
            cc: getState().account.currentUser.clientModel.account.email,
            subject: subject,
            content: bodyContent
        });
    },
    closeEmailAskRates: () => <CloseEmailAskRates>{ type: "CLOSE_EMAIL_ASKRATES" },
    openAskRates: (carrierOfferId?: string) => <OpenAskRates>{ type: "OPEN_ASKRATES", carrierOfferId: carrierOfferId },
    closeAskRates: () => <CloseAskRates>{ type: "CLOSE_ASKRATES" },
    toggleAskRatesByEmailOpen: (value: boolean) => <ToggleAskRatesByEmailOpen>{ type: "TOGGLE_ASKRATES_BYEMAIL_OPEN", value: value },
    updateTo: (value: string) => <AskRatesUpdateTo>{ type: "ASKRATES_UPDATE_TO", value: value },
    updateCc: (value: string) => <AskRatesUpdateCc>{ type: "ASKRATES_UPDATE_CC", value: value },
    updateSubject: (value: string) => <AskRatesUpdateSubject>{ type: "ASKRATES_UPDATE_SUBJECT", value: value },
    updateContent: (value: string) => <AskRatesUpdateContent>{ type: "ASKRATES_UPDATE_CONTENT", value: value },
    setCarrierSelected: (carrierId: number, value: boolean): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let carrierSelected = getState().askRates.carriersSelected;
        let contacts = getState().selection.selection.carrierOffers
            .filter(x => x.carrierId === carrierId
                ? value
                : carrierSelected[x.carrierId] !== false)
            .map(x => x.contacts)
            .reduce((a, b) => a.concat(b), []);

        dispatch({
            type: "ASKRATES_SET_CARRIER_SELECT",
            payload: {
                value: value,
                carrierId: carrierId,
                to: getEmailTo(contacts)
            }
        });
    },
    requestSendEmail: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().askRates.requestTime)
            return Promise.resolve();

        let carrierOffer = getState().selection.selection.carrierOffers
            .find(co => getCarrierOfferKey(co) === getState().askRates.carrierOfferId);
        let depart = carrierOffer
            ? getSelectedDepart(carrierOffer, getState().selection.carrierOfferStates[getCarrierOfferKey(carrierOffer)])
            : undefined;
        let askRatesState = getState().askRates;
        let api = new Api.SelectionApi();
        let fetchTask = api.sendAskRates({
            model: {
                subject: askRatesState.subject,
                body: askRatesState.content,
                to: askRatesState.to,
                departId: depart
                    ? depart.departId
                    : null
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }).then(value => {
            dispatch(Notifications.success({ message: "Your email has been sent", title: "Success", position: "tc" }) as any);
            dispatch({ type: "RECEIVE_ASKRATES_SEND_EMAIL", payload: { requestTime: requestTime } });
        }).catch(error => {
            dispatch(Notifications.error({ message: "Error sending your email", title: "Error", position: "tc" }) as any);
            dispatch({ type: "RECEIVE_ASKRATES_SEND_EMAIL", payload: { requestTime: requestTime }, error: error });
        });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_ASKRATES_SEND_EMAIL", payload: { requestTime: requestTime } });
        return fetchTask;
    }
};
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AskRatesState = {
    isLoading: false,
    emailIsOpen: false,
    requestTime: 0,
    carrierOfferId: null,
    isOpen: false,
    to: "",
    cc: "",
    content: "",
    subject: "",
    carriersSelected: {}
};

export const reducer: Reducer<AskRatesState> = (state: AskRatesState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "TOGGLE_ASKRATES_BYEMAIL_OPEN":
            return {
                ...state,
                carriersSelected: {},
                isAskByEmailOpen: action.value,
                isOpen: false
            };
        case "OPEN_ASKRATES":
            return {
                ...state,
                isOpen: true,
                carrierOfferId: action.carrierOfferId
            };
        case "CLOSE_ASKRATES":
            return {
                ...state,
                isOpen: false
            };
        case "OPEN_EMAIL_ASKRATES":
            return {
                ...state,
                isOpen: false,
                emailIsOpen: true
            };
        case "CLOSE_EMAIL_ASKRATES":
            return {
                ...state,
                emailIsOpen: false
            };
        case "ASKRATES_UPDATE_TO":
            return {
                ...state,
                to: action.value
            };
        case "ASKRATES_UPDATE_CC":
            return {
                ...state,
                cc: action.value
            };
        case "ASKRATES_UPDATE_SUBJECT":
            return {
                ...state,
                subject: action.value
            };
        case "ASKRATES_UPDATE_CONTENT":
            return {
                ...state,
                content: action.value
            };
        case "REQUEST_ASKRATES_SEND_EMAIL":
            return {
                ...state,
                requestTime: action.payload.requestTime,
                isLoading: true
            };
        case "RECEIVE_ASKRATES_SEND_EMAIL":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false
            };
        case "ASKRATES_INIT_EMAIL":
            return {
                ...state,
                to: action.to,
                cc: action.cc,
                subject: action.subject,
                content: action.content
            };
        case "ASKRATES_SET_CARRIER_SELECT":
            return {
                ...state,
                carriersSelected: {
                    ...state.carriersSelected,
                    [action.payload.carrierId]: action.payload.value,
                },
                to: action.payload.to
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
