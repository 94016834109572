import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import * as Colors from '../styles/colors';

const OkTextField = (props: any) => <TextField inputProps={{ ...({ style: { height: 42, color: Colors.baseColors.darkGray} })}} {...props} variant="outlined"></TextField>;

export default styled(OkTextField)({
    border: 0,
    '& input': {
        padding: "0px 6px",
        textTransform: "none"
    },
    '& .MuiInputBase-root': {
        borderRadius: 8,
        border: `1px solid ${Colors.baseColors.darkGray}`,
    },
    '& .MuiAutocomplete-input': {
        padding: "0px 6px !important"
    },
    '& :not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        borderWidth: "0px !important",
    },
    color: Colors.baseColors.darkGray,
});