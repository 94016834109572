import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as InlandCriteriaStore from '../store/InlandCriteria';
import * as InlandSelectionStore from '../store/InlandSelection';
import * as Api from '../api/api';
import { getText } from "../utils/langTexts";
import DatePicker from './DatePicker';
import Tooltip from './Tooltip';
import Button from './Button';
import { ArrowBack } from '@material-ui/icons';
import CriteriaLocationSelect from "./CriteriaLocationSelect";
import * as Colors from '../styles/colors';

type InlandSelectionCriteriaProps = InlandSelectionCriteriaOwnProps
    & typeof InlandCriteriaStore.actionCreators
    & typeof InlandSelectionStore.actionCreators;

interface InlandSelectionCriteriaOwnProps {
    isLoading: boolean;
    inlandCriteria: Api.InlandCriteriaModel;
    inlandCriteriaLoaded: Api.InlandCriteriaModel;
}

const formFieldStyle: React.CSSProperties = {
    marginRight: 5,
    flex: "0 0 auto",
    //height: "45px",
}

const datePickerStyle: React.CSSProperties = {
    height: "34px",
}

const datePickerContainerStyle: React.CSSProperties = {
    ...formFieldStyle,
    maxWidth: 250,
    flex: 1,
}

class InlandSelectionCriteria extends React.Component<InlandSelectionCriteriaProps, {}> {
    get needReload(): boolean {
        if (!this.props.inlandCriteria.origin
            || this.props.inlandCriteria.destination) {
            return false;
        }

        return (this.props.inlandCriteria.origin && this.props.inlandCriteria.origin.locationId !== this.props.inlandCriteriaLoaded.origin.locationId)
            || (this.props.inlandCriteria.destination && this.props.inlandCriteria.destination.locationId !== this.props.inlandCriteriaLoaded.destination.locationId)
            || this.props.inlandCriteria.dateBegin !== this.props.inlandCriteriaLoaded.dateBegin
            || this.props.inlandCriteria.dateEnd !== this.props.inlandCriteriaLoaded.dateEnd;
    }

    search() {
        if (!this.props.inlandCriteria.origin
            || !this.props.inlandCriteria.destination)
            return;

        let promise = (this.props.requestCreateInlandCriteria(new Date().getTime(), this.props.inlandCriteria) as any) as Promise<Api.InlandCriteriaModel>;
        return promise.then((inlandCriteria) => {
            this.props.goToSelection(inlandCriteria.code);
        });
    }

    public render() {
        return (
            <div >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{ flex: "0 0 auto", marginRight: 10 }}>
                        <ArrowBack
                            onClick={() => this.props.goToInlandCriteria()}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                    <div style={datePickerContainerStyle}>
                        <DatePicker value={this.props.inlandCriteria.dateBegin}
                            inputContainerStyle={{ width: 160 }}
                            iconContainerStyle={{ height: 34, width: 34 }}
                            style={datePickerStyle}
                            minDate={null}
                            maxDate={this.props.inlandCriteria.dateEnd}
                            onChange={(date) => this.props.updateInlandCriteriaDateBegin(date)}></DatePicker>
                    </div>
                    <div style={datePickerContainerStyle}>
                        <DatePicker value={this.props.inlandCriteria.dateEnd}
                            inputContainerStyle={{width: 160}}
                            iconContainerStyle={{ height: 34, width: 34 }}
                            style={datePickerStyle}
                            minDate={this.props.inlandCriteria.dateBegin}
                            maxDate={null}
                            
                            onChange={(date) => this.props.updateInlandCriteriaDateEnd(date)}></DatePicker>
                    </div>
                    <div style={{ ...formFieldStyle, marginRight: 50, maxWidth: 280, flex:1}}>
                        <CriteriaLocationSelect
                            inputKey={"CrtSlcInlandOrigin"}
                            defaultRegion={this.props.inlandCriteria.origin
                                && this.props.inlandCriteria.origin.country
                                && this.props.inlandCriteria.origin.country.code}
                            value={this.props.inlandCriteria.origin}
                            inputStyle={{ height: 36}}
                            buttonStyle={{height: 34, width: 34 }}
                            locationSearchType={"Inland"}
                            onChange={(value) => this.props.updateInlandCriteriaOrigin(value)} />
                    </div>
                    <div style={{ ...formFieldStyle, marginRight: 20, maxWidth: 280, flex:1}}>
                        <CriteriaLocationSelect
                            inputKey={"CrtSlcInlandDestination"}
                            defaultRegion={this.props.inlandCriteria.destination
                                && this.props.inlandCriteria.destination.country
                                && this.props.inlandCriteria.destination.country.code}
                            value={this.props.inlandCriteria.destination}
                            inputStyle={{ height: 36}}
                            buttonStyle={{ height: 34, width: 34}}
                            locationSearchType={"Inland"}
                            onChange={(value) => this.props.updateInlandCriteriaDestination(value)} />
                    </div>
                    <div style={formFieldStyle}>
                        <Tooltip
                            trigger={[]}
                            visible={this.needReload && !this.props.isLoading}
                            placement="top"
                            overlay={<span>{getText("SlcCrtReloadRequired")}</span>}>
                            <Button
                                style={{ fontWeight: "bold", paddingLeft: 20, paddingRight: 20, marginLeft: 10, backgroundColor: `${Colors.baseColors.lightBlue}`, color: "white" }}
                                disabled={this.props.isLoading}
                                onClick={() => {
                                    this.search();
                                }}>{getText("SlcCrtSearch")}</Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    inlandCriteria: state.inlandCriteria.inlandCriteria,
    inlandCriteriaLoaded: state.inlandSelection.inlandCriteriaLoaded,
    isLoading: state.inlandSelection.isLoading
} as any), {
        ...InlandCriteriaStore.actionCreators,
        ...InlandSelectionStore.actionCreators,
})(InlandSelectionCriteria) as any;