import * as _ from 'lodash';
import * as Api from '../api/api';
import * as React from 'react';
import * as Colors from '../styles/colors';
import * as FormField from './FormField';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import { getFirmName } from '../utils/utils';
import { infoLevelOptions } from './ModeratorDocumentInfoEditForm';
import Button from './Button';
import {vFormStyle} from "../styles/baseStyles";

type CarrierRatesOfferInformationFormProps = ReduxForm.InjectedFormProps<Api.CarrierRatesOfferInformationModel, {}, string>
    & CarrierRatesOfferInformationFormOwnProps
    & CarrierRatesOfferInformationFormExternalProps;

interface CarrierRatesOfferInformationFormOwnProps {
    initialValues: Api.CarrierRatesOfferInformationModel;
    carriers: { [id: number]: Api.CarrierModel };
    subscriptions: Array<Api.SubscriptionModel>;
}

interface CarrierRatesOfferInformationFormExternalProps {
    onSubmit: (values: Api.CarrierRatesOfferInformationModel) => Promise<any>;
    mode: "Create" | "Update";
}

const textAreaField = FormField.textAreaField(6, 35);

class CarrierRatesOfferInformationForm extends React.Component<CarrierRatesOfferInformationFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-horizontal container-fluid">
                <div style={{ ...vFormStyle, alignItems: "stretch" }}>
                    <div className="text-danger" style={{ textAlign: "center", marginTop: "1em" }}>
                        {this.props.error && <span>{this.props.error}</span>}
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label" style={{ marginTop: "4px" }}>{getText("MdtCarrierQuotesRemarksCompany")}: </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             name="firmId"
                                             validate={[FormField.required]}
                                             style={{ width: "100%", padding: "4px 4px 4px 8px"}}
                                             component={FormField.getSelectField(this.props.subscriptions
                                                 .filter(su => su.subscriptionType === "Okargo"
                                                     && su.allowInformUser)
                                                 .filter(x => x.agency.company.companyGroup)
                                                 .map(su => ({
                                                     label: getFirmName(su.agency.company.companyGroup),
                                                     value: su.agency.company.companyGroupId
                                                 })).concat(this.props.subscriptions
                                                     .filter(su => su.subscriptionType === "Okargo")
                                                     .map(su => ({
                                                         label: getFirmName(su.agency.company),
                                                         value: su.agency.companyId
                                                     })).concat(
                                                         this.props.subscriptions
                                                             .filter(su => su.subscriptionType === "Okargo")
                                                             .map(su => ({
                                                                 label: getFirmName(su.agency),
                                                                 value: su.agencyId
                                                             }))
                                                     )))}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label" style={{ marginTop: "4px" }}>{getText("MdtCarrierQuotesRemarksCarrier")}: </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             name="carrierId"
                                             style={{ width: "100%" }}
                                             validate={[FormField.required]}
                                             search={true}
                                             component={FormField.getSelectField(_.sortBy(_.values(this.props.carriers)
                                                 .map(x => ({ label: x.name, value: x.carrierId }))))} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label" style={{ marginTop: "4px" }}>{getText("MdtCarrierQuotesRemarksLevel")}: </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             name="level"
                                             style={{ width: "100%", padding: "4px 4px 4px 8px"}}
                                             validate={[FormField.required]}
                                             component={FormField.getSelectField(infoLevelOptions)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label">{getText("MdtCarrierQuotesRemarksContent")}: </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             name="content"
                                             validate={[FormField.required]}
                                             component={textAreaField} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label"></label>
                        <div className="col-sm-9">
                            {(this.props.submitting || this.props.pristine) ? (
                                    <Button type="submit"
                                            style={{ color: "white", backgroundColor: Colors.baseColors.darkGray, marginRight: "1em" }}
                                            disabled={this.props.submitting || this.props.pristine}>
                                        {this.props.mode === "Create" && getText("GenCreate")}
                                        {this.props.mode === "Update" && getText("GenSaveChanges")}
                                    </Button>) :

                                (<Button type="submit"
                                         style={{ color: "white", backgroundColor: Colors.baseColors.lightBlue, marginRight: "1em" }}>
                                    {this.props.mode === "Create" && getText("GenCreate")}
                                    {this.props.mode === "Update" && getText("GenSaveChanges")}
                                </Button>)
                            }


                        </div>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createCarrierRatesOfferInformation',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(CarrierRatesOfferInformationForm) as any;
const createSelector = ReduxForm.formValueSelector('createCarrierRatesOfferInformation')

let updateForm = ReduxForm.reduxForm({
    form: 'updateCarrierRatesOfferInformation',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(CarrierRatesOfferInformationForm) as any;
const updateSelector = ReduxForm.formValueSelector('updateCarrierRatesOfferInformation')

export const CreateCarrierRatesOfferInformationForm = connect((state: ApplicationState) => {
    return {
        initialValues: {},
        carriers: state.seed.carriers,
        subscriptions: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo")
    } as CarrierRatesOfferInformationFormOwnProps;
})(createForm) as any as React.ComponentClass<CarrierRatesOfferInformationFormExternalProps>

export const UpdateCarrierRatesOfferInformationForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.carrierRatesOfferInformation.entities
            .find(x => x.carrierRatesOfferInformationId === state.carrierRatesOfferInformation.selectedId) || {},
        carriers: state.seed.carriers,
        subscriptions: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo")
    } as CarrierRatesOfferInformationFormOwnProps;
})(updateForm) as any as React.ComponentClass<CarrierRatesOfferInformationFormExternalProps>