import { Action, Reducer } from 'redux';
import {AppThunkAction} from "./index";
import * as Notifications from "react-notification-system-redux";
import * as _ from "lodash";

export interface RatesFetcherState {
    mfaStates: {
        [platformCode: string]: {
            value: string;
            isLoading: boolean;
            isSent: boolean;
            requestTime?: number;
        }
    },
    isMfaOpened: boolean
}

export interface MfaRequested {
    type: "MFA_REQUESTED";
    payload: { requestTime: number; platformCode: string; };
}

interface RequestProvideMfa {
    type: "REQUEST_PROVIDE_MFA";
    payload: { requestTime: number; platformCode: string; value: string; };
}

interface ReceiveProvideMfa {
    type: "RECEIVE_PROVIDE_MFA";
    payload: { requestTime: number; platformCode: string; };
    error?: any;
}

interface UpdateMfaValue {
    type: "UPDATE_MFA_VALUE";
    payload: { value: string, platformCode: string; }
}

interface CloseMfaDialog {
    type: "CLOSE_MFA_DIALOG";
}

type KnownAction = MfaRequested
    | RequestProvideMfa 
    | ReceiveProvideMfa
    | CloseMfaDialog
    | UpdateMfaValue
    ;

export const actionCreators = {
    requestProvideMfa: (platformCode: string, value: string, requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: "REQUEST_PROVIDE_MFA",
            payload: {
                requestTime: requestTime,
                platformCode: platformCode,
                value: value
            }
        });
        return fetch('/api/RatesFetcher/ProvideMfa', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ platformCode: platformCode, mfa: value })
        })
            .then(resp => {
                dispatch({
                    type: "RECEIVE_PROVIDE_MFA",
                    payload: { requestTime: requestTime, platformCode: platformCode }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch(Notifications.error({ 
                    message: "Error providing mfa, try again later", 
                    title: "Error", 
                    position: "tc" 
                }) as any);
                dispatch({ 
                    type: "RECEIVE_PROVIDE_MFA", 
                    payload: { requestTime: requestTime, platformCode: platformCode }, 
                    error: err 
                });
            });
    },
    closeMfaDialog: () => ({ type: "CLOSE_MFA_DIALOG" }),
    updateMfaValue: (platformCode: string, value: string) => ({ 
        type: "UPDATE_MFA_VALUE", 
        payload: { platformCode: platformCode, value: value } 
    }),
};

const unloadedState: RatesFetcherState = {
    mfaStates: {},
    isMfaOpened: false
};

export const reducer: Reducer<RatesFetcherState> = (state: RatesFetcherState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "MFA_REQUESTED":
            return {
                ...state,
                mfaStates: {
                    ...state.mfaStates,
                    [action.payload.platformCode]: {
                        value: "",
                        isLoading: false,
                        isSent: false
                    }
                },
                isMfaOpened: true
            };
        case "REQUEST_PROVIDE_MFA":
            return {
                ...state,
                mfaStates: {
                    ...state.mfaStates,
                    [action.payload.platformCode]: {
                        ...state.mfaStates[action.payload.platformCode],
                        isLoading: true,
                        requestTime: action.payload.requestTime,
                        isSent: false
                    }
                },
                isMfaOpened: true
            };
        case "RECEIVE_PROVIDE_MFA":
            if(action.payload.requestTime !== state.mfaStates[action.payload.platformCode]?.requestTime)
                return state;
            
            return {
                ...state,
                mfaStates: {
                    ...state.mfaStates,
                    [action.payload.platformCode]: {
                        ...state.mfaStates[action.payload.platformCode],
                        isLoading: false,
                        requestTime: action.payload.requestTime,
                        isSent: true
                    }
                },
                isMfaOpened: _.keys(state.mfaStates)
                    .some(x => x !== action.payload.platformCode
                        && !state.mfaStates[action.payload.platformCode].isSent)
            };
        case "CLOSE_MFA_DIALOG":
            return {
                ...state,
                isMfaOpened: false
            };
        case "UPDATE_MFA_VALUE":
            return {
                ...state,
                mfaStates: {
                    ...state.mfaStates,
                    [action.payload.platformCode]: {
                        ...state.mfaStates[action.payload.platformCode],
                        value: action.payload.value
                    }
                }
            };
         default:
        //     // The following line guarantees that every action in the KnownAction union has been covered by a case above
             const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
