import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import OfferReminder from './OfferReminder';
import * as SelectionStore from '../store/Selection';
import * as AskBookingStore from '../store/AskBooking';
import { getCarrierOfferKey } from '../utils/utils';
import { getText } from '../utils/langTexts';
import * as Colors from '../styles/colors';
import * as SelectionConnect from '../connects/selectionConnect';


type AskBookingProps = AskBookingOwnProps
    & typeof AskBookingStore.actionCreators;

interface AskBookingOwnProps {
    carrierOffer: Api.CarrierOfferModel;
    carrierOfferState: SelectionStore.CarrierOfferState;
    carriers: { [id: number]: Api.CarrierModel };
    goToBookingState: AskBookingStore.RequestState;
    servicesOptions: Api.ServicesOptionsModel;
    processDeepLinkIsLoading: boolean;
}

const fieldStyle = { marginBottom: 10, marginTop: 10 } as React.CSSProperties;
const innerStyle = { padding: 10 } as React.CSSProperties;
const btnStyle = { backgroundColor: "white", color: Colors.baseColors.lightBlue, borderRadius: 10, borderWidth: 0.5, fontWeight: "bold", flex: 1, height: 30,} as React.CSSProperties;
const descStyle = { ...fieldStyle, textAlign: "center" } as React.CSSProperties;

class AskBooking extends React.Component<AskBookingProps, {}> {
    constructor(props) {
        super(props);
    }
    
    public render() {
        return <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ marginBottom: 40, fontSize: 20 }}>
                Booking
            </div>
            <div>
                <OfferReminder
                    carrierOffer={this.props.carrierOffer}
                    carrierOfferState={this.props.carrierOfferState}
                    carriers={this.props.carriers} />
            </div>
            <div style={{
                fontFamily: "Calibri",
                marginBottom: 20,
                paddingRight: 70,
                paddingLeft: 70,
                textAlign: "center",
            }}>
                {getText("SlcOfferBookingDesc")}
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
            }}>
                {this.props.carrierOffer.chargeSet && (this.props.carrierOffer.chargeSet.deepLink == null
                    || this.props.carrierOffer.chargeSet.deepLink == "")
                    && this.props.carriers[this.props.carrierOffer.carrierId].bookingState !== "Hide" &&
                    <div style={{
                        flex: 1,
                        marginBottom: 20,
                        textAlign: "center",
                    }}>
                    <button style={btnStyle}
                            disabled={this.props.carriers[this.props.carrierOffer.carrierId].bookingState === "Hide"
                                || this.props.goToBookingState.isLoading}
                            onClick={(e) => {
                                this.props.requestGoToCarrierBooking(new Date().getTime());
                                e.preventDefault();
                            }}>
                            {getText("SlcOfferBookingGo")}
                        </button>
                    </div>}
                {this.props.carrierOffer.chargeSet && (this.props.carrierOffer.chargeSet.deepLink == null
                    || this.props.carrierOffer.chargeSet.deepLink == "")
                    && this.props.carriers[this.props.carrierOffer.carrierId].intra &&
                    <div style={{
                        flex: 1,
                        marginBottom: 20,
                        textAlign: "center",
                    }}>
                        <button
                        style={btnStyle}
                            onClick={(e) => {
                                window.open(this.props.servicesOptions.intraUrl, "_blank");
                                e.preventDefault();
                            }}>
                            {getText("SlcOfferBookingGoIntra")}
                        </button>
                    </div>}
                {(!this.props.carrierOffer.chargeSet || (this.props.carrierOffer.chargeSet.deepLink == null
                    || this.props.carrierOffer.chargeSet.deepLink == ""))
                    &&
                    <div style={{
                        flex: 1,
                        marginBottom: 20,
                        textAlign: "center",
                    }}>
                    <button style={btnStyle}
                            disabled={(!this.props.carrierOffer || !this.props.carrierOffer.chargeSet) ? true : false}
                            onClick={(e) => {
                                this.props.toggleAskBookingByEmailOpen(true);
                                e.preventDefault();
                            }}>
                            {getText("SlcOfferBookingGoByEmail")}
                        </button>
                    </div>}
                {this.props.carrierOffer.chargeSet && this.props.carrierOffer.chargeSet.deepLink 
                    &&
                    <div style={{
                        flex: 1,
                        marginBottom: 20,
                        textAlign: "center",
                    }}>
                    <button style={btnStyle}
                            disabled={!this.props.carrierOffer.chargeSet 
                            || this.props.carrierOffer.chargeSet.deepLink == null
                                || this.props.carrierOffer.chargeSet.deepLink == ""
                            || this.props.processDeepLinkIsLoading}
                        onClick={(e) => {
                                this.props.requestProcessDeepLink(new Date().getTime(), this.props.carrierOffer.chargeSet);
                                e.stopPropagation();
                            }}>
                            {getText("SlcOfferBookingGoByDeepLink")}
                        </button>
                    </div>}
            </div>
        </div>
    }
}

export default connect((state: ApplicationState) => {
    let carrierGroupedResult = SelectionConnect.getGroupedCarrierOffers(state).reduce((a, b) => a.concat(b), []) as Array<Api.CarrierOfferModel>;
    return {
        carrierOffer: carrierGroupedResult.find(co => getCarrierOfferKey(co) === state.askBooking.carrierOfferId),
        carrierOfferState: state.selection.carrierOfferStates[state.askBooking.carrierOfferId],
        goToBookingState: state.askBooking.goToBookingState,
        carriers: state.seed.carriers,
        servicesOptions: state.seed.servicesOptions,
        processDeepLinkIsLoading: state.askBooking.processDeepLink.isLoading
    } as AskBookingProps;
}, AskBookingStore.actionCreators)(AskBooking) as any