import * as React from 'react';
import * as ReduxForm from 'redux-form';
import Select from './Select';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getSubscriptionName, getSubscription } from '../utils/utils';
import { getText } from "../utils/langTexts";
import { canUseSuggested, canUseCarrierRates } from '../security/userRights';
import * as Colors from "../styles/colors";
import Input from './InputText';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';


type EditAccountFormProps = ReduxForm.InjectedFormProps<{}, {}, string> & EditAccountFormOwnProps;

interface EditAccountFormOwnProps {
    initialValues: Api.ClientModel;
    subscription: Api.SubscriptionModel;
    handleSubmit: (values: any) => void;
    reset: () => void;
    pristine: boolean;
    submitting: boolean;
}

const defaultLoadingField = () => FormField.checkBoxField(getText("StgAccountDefLoading"));
const defaultUnLoadingField = () => FormField.checkBoxField(getText("StgAccountDefUnLoading"));
const defaultMultiPortsField = () => FormField.checkBoxField(getText("StgAccountDefMultiPort"));
const defaultSuggestedField = () => FormField.checkBoxField(getText("StgAccountDefSuggested"));
const defaultContainerPriceField = () => FormField.checkBoxField(getText("StgAccountDefPriceByCtr"));
const defaultAllMarketsField = () => FormField.checkBoxField(getText("StgAccountDefAllMarkets"));
const defaultCarrierRates = () => FormField.checkBoxField(getText("StgAccountDefCarrierRates"));
const defaultFreeTime= () => FormField.checkBoxField(getText("StgAccountDefFreetime"));

const inputStyle = {
    width: "400px",
} as React.CSSProperties;

const inputFormStyle = {
    width: "398px",
    height: "42px",
    border: `1px solid ${Colors.baseColors.darkGray}`,
    borderRadius: "8px",
    color: Colors.baseColors.darkGray,
} as React.CSSProperties;

const containerStyle = {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 15,
} as React.CSSProperties;

const labelStyle = {
    width: "200px",
    paddingRight: "30px",
    paddingLeft: "30px",
    textAlign: "right",
    marginTop: "auto",
    marginBottom: "auto",
} as React.CSSProperties;


const undoButtonStyle = {
    border: "none",
    backgroundColor: "white",
} as React.CSSProperties;

const saveButtonStyle = {
    borderRadius: "8px",
    color: Colors.baseColors.lightBlue,
    fontWeight: "bold",
    border: `2px solid ${Colors.baseColors.darkGray}`,
    backgroundColor: "white",
} as React.CSSProperties;


//My search settings

const labelOnCaseStyle = {
    width: "190px",
} as React.CSSProperties;

const containerCaseStyle = {
    marginLeft: "auto",
    marginRight: "100px",
    height: 65,
    textAlign: "right",
} as React.CSSProperties;

class AccountEditForm extends React.Component<EditAccountFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div>
                    <div>
                        <div style={containerStyle}>
                            <label style={labelStyle}>{getText("StgAccountEmail")}</label>
                            <div >
                                <Input type="text" disabled style={inputStyle} defaultValue={this.props.initialValues.account.email} />
                            </div>
                        </div>
                        <div style={containerStyle}>
                            <label style={labelStyle}>{getText("StgAccountFirstName")} </label>
                            <div >
                                <ReduxForm.Field
                                    style={inputFormStyle}
                                    validate={[FormField.required]}
                                    name="firstName"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={containerStyle}>
                            <label style={labelStyle}>{getText("StgAccountLastName")}</label>
                            <div >
                                <ReduxForm.Field
                                    style={inputFormStyle}
                                    validate={[FormField.required]}
                                    name="lastName"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={containerStyle}>
                            <label style={labelStyle}>{getText("StgAccountPhone1")}</label>
                            <div >
                                <ReduxForm.Field
                                    style={inputFormStyle}
                                    validate={[FormField.phoneValid]}
                                    name="phone1"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div style={containerStyle}>
                            <label style={labelStyle}>{getText("StgAccountPhone2")}</label>
                            <div >
                                <ReduxForm.Field
                                    style={inputFormStyle}
                                    validate={[FormField.phoneValid]}
                                    name="phone2"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>

                        <div style={containerStyle}>
                            <label style={labelStyle}>{getText("StgAccountSubscription")} </label>
                            <div >
                                <ReduxForm.Field
                                    name="subscriptionId"
                                    component={(field: any) => (
                                        <div>
                                            <Select
                                                style={inputFormStyle}
                                                options={this.props.initialValues.subscriptions
                                                    .map(su => ({
                                                        label: getSubscriptionName(su),
                                                        value: su.subscriptionId
                                                    }))}
                                                value={field.input.value}
                                                onChange={(value) => field.input.onChange(value)}
                                            />
                                        </div>
                                    )}
                                    type="text" />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        maxWidth: 695,
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={containerCaseStyle}>
                                <label></label>
                                <div >
                                    <ReduxForm.Field
                                        style={labelOnCaseStyle}
                                        name="defaultLoading"
                                        component={defaultLoadingField()} />
                                </div>
                            </div>
                            <div style={containerCaseStyle}>
                                <label ></label>
                                <div >
                                    <ReduxForm.Field
                                        style={labelOnCaseStyle}
                                        name="defaultUnLoading"
                                        component={defaultUnLoadingField()} />
                                </div>
                            </div>
                            <div style={containerCaseStyle}>
                                <label ></label>
                                <div >
                                    <ReduxForm.Field
                                        style={labelOnCaseStyle}
                                        name="defaultMultiPorts"
                                        component={defaultMultiPortsField()} />
                                </div>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={containerCaseStyle}>
                                <label></label>
                                <div >
                                    <ReduxForm.Field
                                        style={labelOnCaseStyle}
                                        name="defaultContainerPrice"
                                        component={defaultContainerPriceField()} />
                                </div>
                            </div>
                            <div style={containerCaseStyle}>
                                <label></label>
                                <div >
                                    <ReduxForm.Field
                                        style={labelOnCaseStyle}
                                        name="defaultAllMarkets"
                                        component={defaultAllMarketsField()} />
                                </div>
                            </div>
                            <div style={containerCaseStyle}>
                                <label></label>
                                <div >
                                    <ReduxForm.Field
                                        style={labelOnCaseStyle}
                                        name="defaultFreetime"
                                        component={defaultFreeTime()} />
                                </div>
                            </div>
                            {canUseSuggested(this.props.subscription)
                                && <div style={containerCaseStyle}>
                                    <label ></label>
                                    <div >
                                        <ReduxForm.Field
                                            style={labelOnCaseStyle}
                                            name="defaultSuggested"
                                            component={defaultSuggestedField()} />
                                    </div>
                                </div>}
                            {canUseCarrierRates(this.props.subscription)
                                && <div style={containerCaseStyle}>
                                    <label ></label>
                                    <div >
                                        <ReduxForm.Field
                                            style={labelOnCaseStyle}
                                            name="defaultCarrierRates"
                                        component={defaultCarrierRates()} />
                                    </div>
                                </div>}
                            <div style={{ paddingTop: 35, 
                                marginLeft: "auto", 
                                marginRight: 100, 
                                height: 50, 
                                textAlign: "right",
                                }}>
                                <div >
                                    <button
                                        style={undoButtonStyle}
                                        type="button"
                                        onClick={(e) => { this.props.reset(); e.preventDefault(); }}
                                        disabled={this.props.pristine || this.props.submitting}>
                                        <ReplayRoundedIcon style={{
                                            color: Colors.baseColors.lightBlue,
                                        }} />
                                    </button>
                                    <button
                                        style={saveButtonStyle}
                                        type="submit"
                                        disabled={this.props.pristine || this.props.submitting}
                                    >{getText("GenSaveChanges")}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </FormField.BaseForm>
        );
    }
}

let accountEditForm = ReduxForm.reduxForm({
    form: 'editAccount',
    enableReinitialize: true
})(AccountEditForm);

export default connect((state: ApplicationState) =>
    ({
        initialValues: state.account.currentUser.clientModel,
        subscription: getSubscription(state.account.currentUser.clientModel)
    }))(accountEditForm) as any