import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import { AppThunkAction } from './';
import { push } from 'connected-react-router';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { Logout } from './Account';
import Download from "downloadjs";
import * as MimeTypes from "mime-types";

export interface HistoryState {
    searchCriteriaState: SearchCriteriaState;
    offerMemoState: OfferMemoState;
}

export interface SearchCriteriaState {
    requestTime: number;
    isLoading: boolean;
    searchCriteriaHistory: Api.SearchCriteriaHistoryModel;
    criterias: Array<Api.CriteriaModel>;
}

export interface OfferMemoState {
    requestTime: number;
    isLoading: boolean;
    searchOfferMemoHistory: Api.SearchOfferMemoHistoryModel
    offerMemos: Array<Api.OfferMemoModel>;
    downloadStates: { [id: number]: RequestState };
}

interface RequestState {
    requestTime: number;
    isLoading: boolean;
}

interface RequestSearchCriterias { type: 'REQUEST_SEARCH_CRITERIAS', requestTime: number }
interface ReceiveSearchCriterias { type: 'RECEIVE_SEARCH_CRITERIAS', requestTime: number, criterias: Array<Api.CriteriaModel> }

interface RequestOfferMemos { type: 'REQUEST_OFFER_MEMOS', requestTime: number }
interface ReceiveOfferMemos { type: 'RECEIVE_OFFER_MEMOS', requestTime: number, offerMemos: Array<Api.OfferMemoModel> }

interface RequestDownloadOfferMemo { type: 'REQUEST_DOWNLOAD_OFFER_MEMO', requestTime: number, offerMemoId: number }
interface ReceiveDownloadOfferMemo { type: 'RECEIVE_DOWNLOAD_OFFER_MEMO', requestTime: number, offerMemoId: number }

interface UpdateSearchCriteriaDateFrom { type: 'UPDATE_SEARCH_CRITERIA_DATEFROM', value: Date }
interface UpdateSearchOfferMemoDateFrom { type: 'UPDATE_SEARCH_OFFER_MEMO_DATEFROM', value: Date }

interface HistoryCriteriasGoToSelection { type: 'HISTORY_CRITERIAS_GO_TO_SELECTION', code: string }

type KnownAction = RequestSearchCriterias | ReceiveSearchCriterias
    | RequestOfferMemos | ReceiveOfferMemos
    | UpdateSearchCriteriaDateFrom | UpdateSearchOfferMemoDateFrom
    | RequestDownloadOfferMemo | ReceiveDownloadOfferMemo
    | HistoryCriteriasGoToSelection | Logout;

export const actionCreators = {
    requestSearchCriterias: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().history.searchCriteriaState.requestTime)
            return;

        let api = new Api.HistoryApi();
        let fetchTask = api.searchCriteriaHistory({
            model: getState().history.searchCriteriaState.searchCriteriaHistory
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }).then(criterias => {
            dispatch({ type: "RECEIVE_SEARCH_CRITERIAS", requestTime: requestTime, criterias: criterias });
        }).catch(error => {
            dispatch({ type: "RECEIVE_SEARCH_CRITERIAS", requestTime: requestTime, criterias: [] });
            dispatch(Notifications.error({ message: "Failed to fetch criterias history", title: "Error", position: "tc" }) as any);
            console.log(error.message || error);
        });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_SEARCH_CRITERIAS", requestTime: requestTime });
    },
    requestOfferMemos: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().history.offerMemoState.requestTime)
            return;

        let api = new Api.HistoryApi();
        let fetchTask = api.searchOfferMemoHistory({
            model: getState().history.offerMemoState.searchOfferMemoHistory
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }).then(offerMemos => {
            dispatch({ type: "RECEIVE_OFFER_MEMOS", requestTime: requestTime, offerMemos: offerMemos });
        }).catch(error => {
            dispatch({ type: "RECEIVE_OFFER_MEMOS", requestTime: requestTime, offerMemos: [] });
            dispatch(Notifications.error({ message: "Failed to fetch memos history", title: "Error", position: "tc" }) as any);
            console.log(error.message || error);
        });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_OFFER_MEMOS", requestTime: requestTime });
    },
    requestDownloadOfferMemo: (requestTime: number, offerMemoId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let state = getState().history.offerMemoState.downloadStates[offerMemoId];
        if (state && requestTime === state.requestTime)
            return;

        let offerMemo = getState().history.offerMemoState.offerMemos.find(om => om.offerMemoId === offerMemoId);
        let api = new Api.HistoryApi();
        let fetchTask = api.downloadMemo({ offerMemoId: offerMemoId },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                let fileName = "Okargo memo: " + getState().seed.carriers[offerMemo.carrierId].name
                    + "_" + offerMemo.originPort.name
                    + "_" + offerMemo.destinationPort.name
                    + ".xlsx";
                dispatch({ type: "RECEIVE_DOWNLOAD_OFFER_MEMO", requestTime: requestTime, offerMemoId: offerMemoId });
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain")
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_DOWNLOAD_OFFER_MEMO", requestTime: requestTime, offerMemoId: offerMemoId });
                dispatch(Notifications.error({ message: "Error downloading your memo", title: "Error", position: "tc" }) as any);
                console.log(error.message || error);
            });

        dispatch({ type: "REQUEST_DOWNLOAD_OFFER_MEMO", requestTime: requestTime, offerMemoId: offerMemoId });
    },
    goToSelection: (code: string): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: "HISTORY_CRITERIAS_GO_TO_SELECTION", code: code });
        dispatch(push("/selection/" + code) as any);
    },
    updateSearchCriteriaDateFrom: (value: Date) => <UpdateSearchCriteriaDateFrom>{ type: "UPDATE_SEARCH_CRITERIA_DATEFROM", value: value },
    updateSearchOfferMemoDateFrom: (value: Date) => <UpdateSearchOfferMemoDateFrom>{ type: "UPDATE_SEARCH_OFFER_MEMO_DATEFROM", value: value },
}

const unloadedState: HistoryState = {
    offerMemoState: {
        isLoading: false,
        requestTime: 0,
        offerMemos: [],
        searchOfferMemoHistory: {
            fromDate: Moment().add("month", -1).toDate()
        },
        downloadStates: {}
    },
    searchCriteriaState: {
        isLoading: false,
        requestTime: 0,
        criterias: [],
        searchCriteriaHistory: {
            fromDate: Moment().add("month", -1).toDate()
        }
    }
};


export const reducer: Reducer<HistoryState> = (state: HistoryState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_SEARCH_CRITERIAS":
            return {
                ...state,
                searchCriteriaState: {
                    ...state.searchCriteriaState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_SEARCH_CRITERIAS":
            if (action.requestTime !== state.searchCriteriaState.requestTime)
                return state;

            return {
                ...state,
                searchCriteriaState: {
                    ...state.searchCriteriaState,
                    isLoading: false,
                    criterias: action.criterias
                }
            };
        case "REQUEST_OFFER_MEMOS":
            return {
                ...state,
                offerMemoState: {
                    ...state.offerMemoState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_OFFER_MEMOS":
            if (action.requestTime !== state.offerMemoState.requestTime)
                return state;

            return {
                ...state,
                offerMemoState: {
                    ...state.offerMemoState,
                    isLoading: false,
                    offerMemos: action.offerMemos
                }
            };
        case "UPDATE_SEARCH_CRITERIA_DATEFROM":
            return {
                ...state,
                searchCriteriaState: {
                    ...state.searchCriteriaState,
                    searchCriteriaHistory: {
                        ...state.searchCriteriaState.searchCriteriaHistory,
                        fromDate: action.value
                    }
                }
            };
        case "UPDATE_SEARCH_OFFER_MEMO_DATEFROM":
            return {
                ...state,
                offerMemoState: {
                    ...state.offerMemoState,
                    searchOfferMemoHistory: {
                        ...state.offerMemoState.searchOfferMemoHistory,
                        fromDate: action.value
                    }
                }
            };
        case "REQUEST_DOWNLOAD_OFFER_MEMO":
            return {
                ...state,
                offerMemoState: {
                    ...state.offerMemoState,
                    downloadStates: {
                        ...state.offerMemoState.downloadStates,
                        [action.offerMemoId]: {
                            ...state.offerMemoState.downloadStates[action.offerMemoId],
                            isLoading: true,
                            requestTime: action.requestTime
                        }
                    }
                }
            };
        case "RECEIVE_DOWNLOAD_OFFER_MEMO":
            if (!state.offerMemoState.downloadStates[action.offerMemoId]
                || state.offerMemoState.downloadStates[action.offerMemoId].requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                offerMemoState: {
                    ...state.offerMemoState,
                    downloadStates: {
                        ...state.offerMemoState.downloadStates,
                        [action.offerMemoId]: {
                            ...state.offerMemoState.downloadStates[action.offerMemoId],
                            isLoading: false
                        }
                    }
                }
            };
        case "HISTORY_CRITERIAS_GO_TO_SELECTION":
            return state;
        case "LOGOUT":
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
