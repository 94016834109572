import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import { AppThunkAction } from './';
import * as CriteriaUtils from '../utils/criteriaUtils';
import {
    getCarrierOfferKey, getDefaultHeaders
} from "../utils/utils"
import * as Criteria from './Criteria';
import { Logout } from './Account';
import * as Notifications from 'react-notification-system-redux';
import Download from "downloadjs";
import * as MimeTypes from "mime-types";
import { getSelectedDepart } from '../utils/routeUtils';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface TransferState {
    carrierOfferId: string;
    isOpen: boolean;
    createMemoState: RequestState;
    exportOfferState: RequestState;
    exportOfferToTmsState: RequestState;
    identification: string;
    additionalData1: string;
    additionalData2: string;
}

interface RequestState {
    isLoading: boolean;
    requestTime: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
interface OpenTransferData { type: 'OPEN_TRANSFER_DATA', carrierOfferId: string }
interface CloseTransferData { type: 'CLOSE_TRANSFER_DATA' }

interface RequestSendMemo { type: 'REQUEST_SEND_MEMO', requestTime: number, offerSelection: Api.OfferSelectionModel }
interface ReceiveSendMemo { type: 'RECEIVE_SEND_MEMO', requestTime: number }

interface RequestDownloadMemo { type: 'REQUEST_DOWNLOAD_MEMO', requestTime: number, offerSelection: Api.OfferSelectionModel }
interface ReceiveDownloadMemo { type: 'RECEIVE_DOWNLOAD_MEMO', requestTime: number }

interface RequestExportOffer { type: 'REQUEST_EXPORT_OFFER', requestTime: number }
interface ReceiveExportOffer { type: 'RECEIVE_EXPORT_OFFER', requestTime: number }

interface RequestExportOfferToTms {
    type: 'REQUEST_EXPORT_OFFER_TO_TMS',
    payload: { requestTime: number }
}
interface ReceiveExportOfferToTms {
    type: 'RECEIVE_EXPORT_OFFER_TO_TMS',
    payload: { requestTime: number },
    error?: any
}

interface TransferUpdateIdentitifaction { type: 'TRANSFER_UPDATE_IDENTIFICATION'; value: string }
interface TransferUpdateAdditionalInfo1 { type: 'TRANSFER_UPDATE_ADDITIONALINFO1'; value: string }
interface TransferUpdateAdditionalInfo2 { type: 'TRANSFER_UPDATE_ADDITIONALINFO2'; value: string }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    | OpenTransferData | CloseTransferData
    | RequestDownloadMemo | ReceiveDownloadMemo
    | RequestSendMemo | ReceiveSendMemo
    | RequestExportOffer | ReceiveExportOffer
    | TransferUpdateIdentitifaction
    | TransferUpdateAdditionalInfo1
    | TransferUpdateAdditionalInfo2
    | RequestExportOfferToTms
    | ReceiveExportOfferToTms
    | Logout;
type KnownCriteriaAction = Criteria.RequestCriteriaAction | Criteria.ReceiveCriteriaAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    transferUpdateIdentitifcation: (value: string) => <TransferUpdateIdentitifaction>{ type: "TRANSFER_UPDATE_IDENTIFICATION", value: value },
    transferUpdateAdditionalInfo1: (value: string) => <TransferUpdateAdditionalInfo1>{ type: "TRANSFER_UPDATE_ADDITIONALINFO1", value: value },
    transferUpdateAdditionalInfo2: (value: string) => <TransferUpdateAdditionalInfo2>{ type: "TRANSFER_UPDATE_ADDITIONALINFO2", value: value },
    requestSendMemo: (requestTime: number, offerSelection: Api.OfferSelectionModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().transfer.createMemoState.requestTime)
            return;

        var task = CriteriaUtils.getCriteriaCode(getState, getState().criteria.criteria, getState().selection.criteriaLoaded).then(code => {
            let api = new Api.SelectionApi();
            offerSelection.criteriaCode = code;
            api.sendMemo({ model: offerSelection }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }).then(value => {
                dispatch({ type: "RECEIVE_SEND_MEMO", requestTime: requestTime });
                dispatch(Notifications.success({ message: "Your memo has been sent by email", title: "Success", position: "tc" }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_SEND_MEMO", requestTime: requestTime });
                dispatch(Notifications.error({ message: "Error sending memo", title: "Error", position: "tc" }) as any);
            });
        }).catch(error => {
            dispatch(Notifications.error({ message: "Error creating criteria", title: "Error", position: "tc" }) as any);
        });
        dispatch({ type: "REQUEST_SEND_MEMO", requestTime: requestTime, offerSelection: offerSelection });
    },
    requestDownloadMemo: (requestTime: number, offerSelection: Api.OfferSelectionModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().transfer.createMemoState.requestTime)
            return;

        var task = CriteriaUtils.getCriteriaCode(getState, getState().criteria.criteria, getState().selection.criteriaLoaded).then(code => {
            let api = new Api.SelectionApi();
            offerSelection.criteriaCode = code;
            api.downloadMemo({ model: offerSelection }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(response => response.blob())
                .then(blob => {
                    dispatch({ type: "RECEIVE_DOWNLOAD_MEMO", requestTime: requestTime });
                    let fileName = "Okargo_Memo_" + Moment().format("YYYY_MM_DD") + ".xlsx";
                    return Download(blob,
                        fileName,
                        MimeTypes.lookup(fileName) || "text/plain");
                })
                .catch(error => {
                    dispatch({ type: "RECEIVE_DOWNLOAD_MEMO", requestTime: requestTime });
                    dispatch(Notifications.error({ message: "Error downloading your memo", title: "Error", position: "tc" }) as any);
                });
        }).catch(error => {
            dispatch(Notifications.error({ message: "Error creating criteria", title: "Error", position: "tc" }) as any);
        });
        dispatch({ type: "REQUEST_DOWNLOAD_MEMO", requestTime: requestTime, offerSelection: offerSelection });
    },
    requestExportOffer: (requestTime: number, offer: Api.OfferSelectionModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        CriteriaUtils.getCriteriaCode(getState, getState().criteria.criteria, getState().selection.criteriaLoaded).then(code => {
            let api = new Api.ExportApi();


            api.transferOffer({
                model: {
                    identification: getState().transfer.identification,
                    additionalData1: getState().transfer.additionalData1,
                    additionalData2: getState().transfer.additionalData2,
                    chargeSetId: offer.chargeSetId ? offer.chargeSetId : null,
                    departId: offer.departId ? offer.departId : null,
                    criteriaCode: code,
                    currencyId: getState().account.currentUser.clientModel.currencyId
                }
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }
            ).then(result => {
                if (result.success) {
                    dispatch(Notifications.success({
                        message: "Your offer has been transfered: " + result.message,
                        title: "Success",
                        autoDismiss: 0,
                        position: "tc"
                    }) as any);
                } else {
                    dispatch(Notifications.error({
                        message: "Error transfering your offer: " + result.message,
                        autoDismiss: 0,
                        title: "Error",
                        position: "tc"
                    }) as any);
                }
                dispatch({ type: "RECEIVE_EXPORT_OFFER", requestTime: requestTime });
            }).catch(error => {
                dispatch({ type: "RECEIVE_EXPORT_OFFER", requestTime: requestTime });
                dispatch(Notifications.error({ message: "Error transfering your offer", title: "Error", position: "tc" }) as any);
            });
        }).catch(error => {
            dispatch({ type: "RECEIVE_EXPORT_OFFER", requestTime: requestTime });
            dispatch(Notifications.error({ message: "Error transfering your offer", title: "Error", position: "tc" }) as any);
        });

        dispatch({ type: "REQUEST_EXPORT_OFFER", requestTime: requestTime });
    },
    requestExportOfferToTms: (requestTime: number, offer:Api.OfferSelectionModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        CriteriaUtils.getCriteriaCode(getState, getState().criteria.criteria, getState().selection.criteriaLoaded).then(code => {
            let api = new Api.ExportApi();


            api.exportToTms({
                model: {
                    chargeSetId: offer.chargeSetId ? offer.chargeSetId : null,
                    departId: offer.departId ? offer.departId : null,
                    criteriaCode: code,
                    currencyId: getState().account.currentUser.clientModel.currencyId
                }
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }
            ).then(result => {
                if (result.success) {
                    dispatch(Notifications.success({
                        message: "Your offer has been transfered " + result.message,
                        title: "Success",
                        position: "tc"
                    }) as any);
                } else {
                    dispatch(Notifications.error({
                        message: "Error transfering your offer: " + result.message,
                        autoDismiss: 0,
                        title: "Error",
                        position: "tc"
                    }) as any);
                }
                dispatch({ type: "RECEIVE_EXPORT_OFFER_TO_TMS", payload: { requestTime: requestTime } });
            }).catch(error => {
                dispatch({
                    type: "RECEIVE_EXPORT_OFFER_TO_TMS",
                    payload: { requestTime: requestTime },
                    error: error
                });
                dispatch(Notifications.error({ message: "Error transfering your offer", title: "Error", position: "tc" }) as any);
            });
        }).catch(error => {
            dispatch({ type: "RECEIVE_EXPORT_OFFER_TO_TMS", payload: { requestTime: requestTime } });
            dispatch(Notifications.error({ message: "Error transfering your offer", title: "Error", position: "tc" }) as any);
        });

        dispatch({ type: "REQUEST_EXPORT_OFFER", requestTime: requestTime });
    },
    openTransferData: (carrierOfferId: string) => <OpenTransferData>{ type: "OPEN_TRANSFER_DATA", carrierOfferId: carrierOfferId },
    closeTransferData: () => <CloseTransferData>{ type: "CLOSE_TRANSFER_DATA" },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: TransferState = {
    carrierOfferId: null,
    isOpen: false,
    createMemoState: {
        isLoading: false,
        requestTime: 0
    },
    exportOfferState: {
        isLoading: false,
        requestTime: 0
    },
    exportOfferToTmsState: {
        isLoading: false,
        requestTime: 0
    },
    additionalData1: "",
    additionalData2: "",
    identification: ""
};

export const reducer: Reducer<TransferState> = (state: TransferState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "OPEN_TRANSFER_DATA":
            let openTransferState = {
                ...state,
                isOpen: true,
                carrierOfferId: action.carrierOfferId
            };
            if (action.carrierOfferId !== state.carrierOfferId) {

                openTransferState.additionalData1 = "";
                openTransferState.additionalData2 = "";
                openTransferState.identification = "";
            }

            return openTransferState;
        case "CLOSE_TRANSFER_DATA":
            return {
                ...state,
                isOpen: false,
                offerSelection: {}
            };
        case "REQUEST_DOWNLOAD_MEMO":
            return {
                ...state,
                createMemoState: {
                    ...state.createMemoState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_DOWNLOAD_MEMO":
            if (action.requestTime !== state.createMemoState.requestTime)
                return state;

            return {
                ...state,
                createMemoState: {
                    ...state.createMemoState,
                    isLoading: false
                }
            };
        case "REQUEST_SEND_MEMO":
            return {
                ...state,
                createMemoState: {
                    ...state.createMemoState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_SEND_MEMO":
            if (action.requestTime !== state.createMemoState.requestTime)
                return state;

            return {
                ...state,
                createMemoState: {
                    ...state.createMemoState,
                    isLoading: false
                }
            };
        case "REQUEST_EXPORT_OFFER":
            return {
                ...state,
                exportOfferState: {
                    ...state.exportOfferState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_EXPORT_OFFER":
            if (action.requestTime !== state.exportOfferState.requestTime)
                return state;

            return {
                ...state,
                exportOfferState: {
                    ...state.exportOfferState,
                    isLoading: false
                }
            };
        case "REQUEST_EXPORT_OFFER_TO_TMS":
            return {
                ...state,
                exportOfferToTmsState: {
                    ...state.exportOfferToTmsState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_EXPORT_OFFER_TO_TMS":
            if (action.payload.requestTime !== state.exportOfferToTmsState.requestTime)
                return state;

            return {
                ...state,
                exportOfferToTmsState: {
                    ...state.exportOfferToTmsState,
                    isLoading: false
                }
            };
        case "TRANSFER_UPDATE_IDENTIFICATION":
            return {
                ...state,
                identification: action.value
            };
        case "TRANSFER_UPDATE_ADDITIONALINFO1":
            return {
                ...state,
                additionalData1: action.value
            };
        case "TRANSFER_UPDATE_ADDITIONALINFO2":
            return {
                ...state,
                additionalData2: action.value
            };
        case "LOGOUT":
            return unloadedState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
