import * as _ from 'lodash';
import * as Api from '../api/api';
import * as React from 'react';
import * as Colors from '../styles/colors';
import * as FormField from './FormField';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import { getFirmName } from '../utils/utils';
import RatesOfferSelect from './RatesOfferSelect';
import { Edit } from '@material-ui/icons';
import Button from './Button';

type CarrierCarrierRatingFormProps = ReduxForm.InjectedFormProps<Api.CarrierCarrierRatingModel, {}, string>
    & CarrierCarrierRatingFormOwnProps
    & CarrierCarrierRatingFormExternalProps;

interface CarrierCarrierRatingFormOwnProps {
    initialValues: Api.CarrierCarrierRatingModel;
    carrierRatings: Array<Api.CarrierRatingModel>;
    carriers: { [id: number]: Api.CarrierModel };
    portsZones: { [id: number]: Api.PortsZoneModel };
    firms: Array<Api.FirmModel>;
    carrierId: number;
    ratesFilter: RatesFilterOption;
}

interface CarrierCarrierRatingFormExternalProps {
    onSubmit: (values: Api.CarrierCarrierRatingModel) => Promise<any>;
    mode: "Create" | "Update";
    openCarrierRatings: () => void;
}

const getRatesOfferInput = (fields) => {
    return <div>
        <RatesOfferSelect
            inputKey={"CarrierCarrierRatings"}
            style={{ width: "100%" }}
            value={fields.input.value}
            carrierId={fields.carrierId}
            onChange={(value) => fields.input.onChange(value)} />
        <FormField.FieldErrors {...fields.meta} />
    </div>
}

type RatesFilterOption = "All" | "Type" | "Specific";
export const getRatesFilterOptions = (): Array<{ label: string, value: RatesFilterOption }> => [
    { label: getText("GenAll"), value: "All" }
    , { label: getText("RatesOfferByType"), value: "Type" }
    , { label: getText("RatesOfferSpecific"), value: "Specific" }
];
export const getRatesTypeOptions = (): Array<{ label: string, value: Api.RatesOfferModelTypeEnum }> => [
    { label: getText("GenFak"), value: "Fak" }
    , { label: getText("GenNac"), value: "Special" }
];

const largeStyle = { width: "100%", padding: "4px 4px 4px 8px" }
const labelStyle = {paddingTop: "8px"}

class CarrierCarrierRatingForm extends React.Component<CarrierCarrierRatingFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-horizontal container-fluid">
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label" style={{ ...labelStyle }}>Company/branch: </label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            name="firmId"
                            style={{ ...largeStyle }}
                            validate={[FormField.required]}
                            component={FormField.getSelectField(this.props.firms
                                .map(x => ({
                                    label: getFirmName(x),
                                    value: x.firmId
                                })))} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label" style={{ ...labelStyle }}>Carrier: </label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            name="carrierId"
                            style={{ width: "100%" }}
                            search={true}
                            validate={[FormField.required]}
                            component={FormField.getSelectField(_.sortBy(_.values(this.props.carriers), x => x.name)
                                .map(x => ({ label: x.name, value: x.carrierId })))} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label" style={{ ...labelStyle }}>{getText("CarrierCarrierRatingsRatesFilter")}</label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            name="ratesFilter"
                            style={{ ...largeStyle }}
                            validate={[FormField.required]}
                            component={FormField.getSelectField(getRatesFilterOptions())} />
                    </div>
                </div>
                {this.props.ratesFilter === "Type"
                    && <div className="form-group row">
                    <label className="col-sm-3 control-label" style={{ ...labelStyle }}>Rates type: </label>
                        <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            name="ratesType"
                            style={{ ...largeStyle }}
                            validate={[FormField.required]}
                            component={FormField.getSelectField(getRatesTypeOptions())} />
                        </div>
                    </div>}
                {this.props.ratesFilter === "Specific"
                    && this.props.carrierId
                    && <div className="form-group row">
                    <label className="col-sm-3 control-label" style={{ ...labelStyle }}>Rates reference/name: </label>
                        <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                                name="ratesOffer"
                                carrierId={this.props.carrierId}
                                validate={[FormField.required]}
                                component={getRatesOfferInput} />
                        </div>
                    </div>}
                <div className="form-group row">
                    <label className="col-sm-3 control-label" style={{ ...labelStyle }}>Rating: </label>
                    <div className="col-sm-6">
                        <ReduxForm.Field className="form-control"
                            name="carrierRatingId"
                            style={{ ...largeStyle }}
                            validate={[FormField.required]}
                            component={FormField.getSelectField(this.props.carrierRatings
                                .map(x => ({ label: x.label, value: x.carrierRatingId })))} />
                    </div>
                    <div className="col-sm-3" style={{ paddingTop: "4px" }}>
                        <button className="btn btn-primary btn-sm" type="button"
                            onClick={(e) => { this.props.openCarrierRatings(); }}>
                            <Edit style={{ height: 18, width: 18, paddingBottom:"2px" }} />
                        </button>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label" style={{ ...labelStyle }}>From zone: </label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            name="fromPortsZoneId"
                            style={{ width: "100%" }}
                            search={true}
                            validate={[]}
                            component={FormField.getSelectField(
                               _.sortBy(_.values(this.props.portsZones), x => x.name)
                                    .map(x => ({ label: x.name, value: x.portsZoneId })))}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label">To zone: </label>
                    <div className="col-sm-9">
                        <ReduxForm.Field className="form-control"
                            name="toPortsZoneId"
                            style={{ width: "100%" }}
                            search={true}
                            validate={[]}
                            component={FormField.getSelectField(
                                _.sortBy(_.values(this.props.portsZones), x => x.name)
                                    .map(x => ({ label: x.name, value: x.portsZoneId })))}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 control-label"></label>
                    <div className="col-sm-9">
                        {(this.props.submitting || this.props.pristine) ? (
                            <Button type="submit"
                                style={{ color: "white", backgroundColor: Colors.baseColors.darkGray, marginRight: "1em" }}
                                disabled={this.props.submitting || this.props.pristine}>
                                {this.props.mode === "Create" && getText("GenCreate")}
                                {this.props.mode === "Update" && getText("GenSaveChanges")}
                            </Button>) :

                            (<Button type="submit"
                                style={{ color: "white", backgroundColor: Colors.baseColors.lightBlue, marginRight: "1em" }}>
                                {this.props.mode === "Create" && getText("GenCreate")}
                                {this.props.mode === "Update" && getText("GenSaveChanges")}
                            </Button>)
                        }
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createCarrierCarrierRating',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(CarrierCarrierRatingForm) as any;
const createSelector = ReduxForm.formValueSelector('createCarrierCarrierRating')

let updateForm = ReduxForm.reduxForm({
    form: 'updateCarrierCarrierRating',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(CarrierCarrierRatingForm) as any;
const updateSelector = ReduxForm.formValueSelector('updateCarrierCarrierRating')

export const CreateCarrierCarrierRatingForm = connect((state: ApplicationState) => {
    return {
        initialValues: {},
        carrierRatings: state.carrierRating.carrierRatings,
        carriers: state.seed.carriers,
        portsZones: state.seed.portsZones,
        carrierId: createSelector(state, "carrierId"),
        ratesFilter: createSelector(state, "ratesFilter"),
        firms: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency)
            .concat(state.account.currentUser.clientModel.subscriptions
                .filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company))
            .concat(state.account.currentUser.clientModel.subscriptions
                .filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company.companyGroup))
            .filter(x => x)
    } as CarrierCarrierRatingFormOwnProps;
})(createForm) as any as React.ComponentClass<CarrierCarrierRatingFormExternalProps>

export const UpdateCarrierCarrierRatingForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.carrierCarrierRating.carrierCarrierRatings.find(x => x.carrierCarrierRatingId === state.carrierCarrierRating.selectedCarrierCarrierRatingId),
        carrierRatings: state.carrierRating.carrierRatings,
        carriers: state.seed.carriers,
        portsZones: state.seed.portsZones,
        carrierId: updateSelector(state, "carrierId"),
        ratesFilter: updateSelector(state, "ratesFilter"),
        firms: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency)
            .concat(state.account.currentUser.clientModel.subscriptions
                .filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company))
            .concat(state.account.currentUser.clientModel.subscriptions
                .filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company.companyGroup))
            .filter(x => x)
    } as CarrierCarrierRatingFormOwnProps;
})(updateForm) as any as React.ComponentClass<CarrierCarrierRatingFormExternalProps>