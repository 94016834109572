import * as React from 'react';
import * as Api from '../api/api';
import * as AccountState from '../store/Account';
import * as DocumentStore from '../store/Document';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import LoginForm from './LoginForm';
import { getText } from '../utils/langTexts';

type LoginProps =
    LoginOwnProps
     & AccountState.AccountState
    & typeof AccountState.actionCreators
    & typeof DocumentStore.actionCreators
    & RouteComponentProps<{ redirect: string }>;

interface LoginOwnProps {
    languages: { [id: number]: Api.LanguageModel };
}

interface LoginState {
}

class Login extends React.Component<LoginProps, LoginState> {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps: LoginProps) {

    }

    public render() {
        return <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}>
            <div>
                <img src={"/images/logo.png"}
                    height={170}
                    style={{ }} />
            </div>
            <div style={{
                paddingTop: 100,
                marginBottom: 30
            }}>
                <LoginForm onSubmit={(values) => this.props.requestLogin(
                    values as Api.LoginModel,
                    this.props.match.params.redirect,
                    new Date().getTime())} />
            </div>
            <div>
                <div style={{ cursor: "pointer" }} onClick={(e) => {
                    this.props.downloadTos(new Date().getTime(),
                        this.props.languages[this.props.languageId]
                            ? this.props.languages[this.props.languageId].code
                            : "en")
                }}>{getText("LogTermsAndConditions") + " | Copyright"}</div>
            </div>
        </div> 
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.account,
        languages: state.seed.languages
    } as any),
    { ...AccountState.actionCreators, ...DocumentStore.actionCreators }
)(Login) as any;