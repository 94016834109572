import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import * as Notifications from 'react-notification-system-redux';
import * as _ from 'lodash';
import Download from "downloadjs";
import * as MimeTypes from "mime-types";
import { AppThunkAction, ApplicationState } from './';
import { push } from 'connected-react-router';
import { getDefaultHeaders } from '../utils/utils';

export interface InlandSelectionState {
    requestTime?: number;
    isLoading: boolean;
    inlandSelection?: Api.InlandSelectionModel;
    inlandCarrierRatesOffersCache: Array<Api.InlandCarrierOfferModel>;
    inlandCriteriaLoaded?: Api.CriteriaModel;
    inlandCarrierOffersState: { [key: string]: InlandCarrierOfferState };
    filter: InlandSelectionFilter;
    page: number;
    errorMessage?: string;
    warningMessage?: string;
    selectedOffer?: string;
    useInQuotationIsOpen: boolean;
    advContainerOpened: boolean;
    carrierRatesStates: {
        [code: string]: {
            requestTime?: number;
            isLoading: boolean;
            inlandCarrierId: number;
            errors: Array<Api.RatesFetcherErrorModel>
        }
    }
}

export interface InlandSelectionFilter {
    sortBy: InlandSelectionSortBy;
    inlandCarriersSelected: { [id: number]: boolean };
}

export interface InlandCarrierOfferState {
    isLoading: boolean;
    detailsOpened: boolean;
    ratesDetailsOpened: boolean;
    ratesOfferCommentOpened: boolean;
    sizeTypesOpened: { [id: number]: boolean };
    documentInfoDetailsOpened: { [id: number]: boolean };
    downloadDocumentState: RequestState;
}
interface RequestState {
    isLoading: boolean;
    requestTime?: number;
}

type InlandSelectionSortBy = "AllIn"
    | "Carrier";

interface RequestInlandSelection {
    type: "REQUEST_INLAND_SELECTION";
    payload: { requestTime: number };
}
interface ReceiveInlandSelection {
    type: "RECEIVE_INLAND_SELECTION";
    payload: {
        requestTime: number;
        inlandSelection?: Api.InlandSelectionModel;
        inlandCriteria?: Api.InlandCriteriaModel;
    };
    error?: any;
}

interface RequestInlandCarrierRates {
    type: "REQUEST_INLAND_CARRIERRATES";
    payload: {
        requestTime: number;
        carrierRatesPlatform: Api.CarrierRatesPlatformModel;
    };
}
interface ReceiveInlandCarrierRates {
    type: "RECEIVE_INLAND_CARRIERRATES";
    payload: {
        carrierRatesPlatform: Api.CarrierRatesPlatformModel;
        requestTime: number;
        inlandCarrierOffers?: Array<Api.InlandCarrierOfferModel>;
        fetcherErrors?: Array<Api.RatesFetcherErrorModel>;
        error?: string;
    };
    error?: any;
}

interface RequestDownloadInlandDocument {
    type: "REQUEST_DOWNLOAD_INLAND_DOCUMENT";
    payload: { requestTime: number; key: string };
}
interface ReceiveDownloadInlandDocument {
    type: "RECEIVE_DOWNLOAD_INLAND_DOCUMENT";
    payload: { requestTime: number; key: string };
}

interface SelectInlandSelectionPage {
    type: "SELECT_INLAND_SELECTION_PAGE";
    payload: { value: number };
}

interface SelectInlandCarrierOffer {
    type: "SELECT_INLAND_CARRIEROFFER";
    payload: { key: string };
}

interface UpdateInlandCarrierSelected {
    type: "UPDATE_INLAND_CARRIER_SELECTED";
    payload: { inlandCarrierId: number; value: boolean; };
}
interface UpdateInlandAllCarrierSelected {
    type: "UPDATE_INLAND_ALL_CARRIER_SELECTED";
    payload: { inlandCarrierIds: Array<number>, value: boolean; };
}

interface OpenInlandCarrierOfferDetails {
    type: "OPEN_INLAND_CARRIEROFFER_DETAILS";
    payload: { key: string };
}
interface CloseInlandCarrierOfferDetails {
    type: "CLOSE_INLAND_CARRIEROFFER_DETAILS";
    payload: { key: string };
}

interface OpenInlandCarrierOfferRatesDetails {
    type: "OPEN_INLAND_CARRIEROFFER_RATES_DETAILS";
    payload: { key: string };
}
interface CloseInlandCarrierOfferRatesDetails {
    type: "CLOSE_INLAND_CARRIEROFFER_RATES_DETAILS";
    payload: { key: string };
}

interface OpenInlandCarrierOfferRatesComment {
    type: "OPEN_INLAND_CARRIEROFFER_RATES_COMMENT";
    payload: { key: string };
}
interface CloseInlandCarrierOfferRatesComment {
    type: "CLOSE_INLAND_CARRIEROFFER_RATES_COMMENT";
    payload: { key: string };
}

interface OpenInlandCarrierOfferContainerDetails {
    type: "OPEN_INLAND_CARRIEROFFER_CONTAINER_DETAILS";
    payload: { sizeTypeId: number; key: string }
}
interface CloseInlandCarrierOfferContainerDetails {
    type: "CLOSE_INLAND_CARRIEROFFER_CONTAINER_DETAILS";
    payload: { sizeTypeId: number; key: string }
}

interface InlandSelectionUpdateSortBy {
    type: "INLAND_SELECTION_UPDATE_SORTBY";
    payload: { value: InlandSelectionSortBy }
}

interface InlandSelectionOpenUseInQuotation {
    type: "INLAND_SELECTION_OPEN_USE_IN_QUOTATION";
    payload: { id: string }
}
interface InlandSelectionCloseUseInQuotation {
    type: "INLAND_SELECTION_CLOSE_USE_IN_QUOTATION";
}

interface InlandSelectionToggleDocumentDetails {
    type: "INLAND_SELECTION_TOGGLE_DOCUMENTDETAILS";
    payload: { key: string;  id: number; value: boolean }
}
interface InlandSelectionUpdateAdvContainer {
    type: "INLAND_SELECTION_UPDATE_ADVCONTAINER";
    payload: { value: boolean };
}

type KnownAction = RequestInlandSelection
    | InlandSelectionUpdateSortBy
    | ReceiveInlandSelection
    | RequestDownloadInlandDocument
    | ReceiveDownloadInlandDocument
    | SelectInlandSelectionPage
    | SelectInlandCarrierOffer
    | UpdateInlandAllCarrierSelected
    | UpdateInlandCarrierSelected
    | OpenInlandCarrierOfferDetails
    | CloseInlandCarrierOfferDetails
    | OpenInlandCarrierOfferRatesDetails
    | CloseInlandCarrierOfferRatesDetails
    | OpenInlandCarrierOfferRatesComment
    | CloseInlandCarrierOfferRatesComment
    | OpenInlandCarrierOfferContainerDetails
    | CloseInlandCarrierOfferContainerDetails
    | InlandSelectionOpenUseInQuotation
    | InlandSelectionCloseUseInQuotation
    | RequestInlandCarrierRates
    | ReceiveInlandCarrierRates
    | InlandSelectionToggleDocumentDetails
    | InlandSelectionUpdateAdvContainer
    ;

export const getInlandOfferKey = (offer: Api.InlandCarrierOfferModel): string => {
    let chargeSetId = offer.inlandChargeSet ? offer.inlandChargeSet.inlandChargeSetId : 0;
    return `R${offer.inlandRoute.inlandRouteId}C${chargeSetId}`;
}

const criteriaSelector = (state: ApplicationState) => state.inlandCriteria.inlandCriteria;
const offersSelector = (state: ApplicationState) => state.inlandSelection.inlandSelection.inlandCarrierOffers;
const currenciesSelector = (state: ApplicationState) => state.seed.currencies;
const sizeTypesSelector = (state: ApplicationState) => state.seed.sizeTypes;

export const inlandCriteriaToCriteria = (inlandCriteria: Api.InlandCriteriaModel): Api.CriteriaModel => ({
    type: "Fcl",
    chargeCriterias: inlandCriteria.chargeCriterias,
    loadingCharge: true,
    unLoadingCharge: true,
    criteriaSizeTypes: inlandCriteria
        .inlandCriteriaSizeTypes.map(x => ({
            number: x.number,
            sizeTypeId: x.sizeTypeId
        } as Api.CriteriaSizeTypeModel))
});

export const actionCreators = {
    requestInlandSelection: (requestTime: number, inlandCriteria: Api.InlandCriteriaModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.InlandSelectionApi();
        let task = api.getSelectionFromCode({
            code: inlandCriteria.code
        }, { cridentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(inlandSelection => {

                if (inlandCriteria.includeCarrierRates) {
                    let ratesFetcherApi = new Api.RatesFetcherApi();
                    ratesFetcherApi.getAvailableRatesFetchers({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                        .then(carrierRatesPlatforms => {
                            carrierRatesPlatforms
                                .filter(x => x.inlandCarrierId)
                                .forEach(carrierRatesPlatform => {
                                    new Promise(resolve => setTimeout(resolve, 50)).then(() => {
                                        let reqTime = new Date().getTime();
                                        dispatch({
                                            type: "REQUEST_INLAND_CARRIERRATES",
                                            payload: {
                                                carrierRatesPlatform: carrierRatesPlatform,
                                                requestTime: reqTime
                                            }
                                        });
                                        return ratesFetcherApi.getOnlineInlandCarrierOffers({
                                            model: {
                                                inlandCriteria: inlandCriteria,
                                                ratesFetchers: [carrierRatesPlatform.code]
                                            }
                                        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }).then(cwe => {
                                            dispatch({
                                                type: "RECEIVE_INLAND_CARRIERRATES",
                                                payload: {
                                                    carrierRatesPlatform: carrierRatesPlatform,
                                                    requestTime: reqTime,
                                                    inlandCarrierOffers: cwe.inlandCarrierOffers,
                                                    fetcherErrors: cwe.errorByFetcherCode[carrierRatesPlatform.code]
                                                }
                                            });
                                        })
                                            .catch(err => {
                                                dispatch({
                                                    type: "RECEIVE_INLAND_CARRIERRATES",
                                                    payload: {
                                                        carrierRatesPlatform: carrierRatesPlatform,
                                                        requestTime: reqTime,
                                                    },
                                                    error: err
                                                });
                                            });
                                    });
                                });
                        });
                }
                dispatch({
                    type: "RECEIVE_INLAND_SELECTION",
                    payload: {
                        requestTime: requestTime,
                        inlandSelection: inlandSelection,
                        inlandCriteria: inlandCriteria
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_INLAND_SELECTION",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
                dispatch(Notifications.error({ message: "Error searching selection", title: "Error", position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_INLAND_SELECTION",
            payload: { requestTime: requestTime }
        });
        addTask(task);
        return task;
    },
    requestDownloadRatesDocument: (requestTime: number, key: string): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let carrierOfferState = getState().inlandSelection.inlandCarrierOffersState[key];
        if (carrierOfferState && carrierOfferState.isLoading)
            return;

        let carrierOffer = getState().inlandSelection.inlandSelection.inlandCarrierOffers
            .find(x => getInlandOfferKey(x) === key);

        let api = new Api.InlandSelectionApi();
        let task = api.downloadWorkDocuments({
            inlandChargeSetId: carrierOffer.inlandChargeSet.inlandChargeSetId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_DOWNLOAD_INLAND_DOCUMENT", payload: { key: key, requestTime: requestTime } })
                let fileName = "Okargo_Inland_Documents_" + Moment().format("YYYY_MM_DD") + ".zip";
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_DOWNLOAD_INLAND_DOCUMENT", payload: { key: key, requestTime: requestTime } })
                dispatch(Notifications.error({ message: "Failed to download your document", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "REQUEST_DOWNLOAD_INLAND_DOCUMENT", payload: { key: key, requestTime: requestTime } })
        return task;
    },
    selectPage: (value: number) => <SelectInlandSelectionPage>{
        type: "SELECT_INLAND_SELECTION_PAGE",
        payload: { value: value }
    },
    selectInlandCarrierOffer: (inlandOfferId: string) => <SelectInlandCarrierOffer>{
        type: "SELECT_INLAND_CARRIEROFFER",
        payload: { key: inlandOfferId }
    },
    updateInlandCarrierSelected: (inlandCarrierId: number, value: boolean) => <UpdateInlandCarrierSelected>{
        type: "UPDATE_INLAND_CARRIER_SELECTED",
        payload: { inlandCarrierId: inlandCarrierId, value: value }
    },
    updateInlandAllCarrierSelected: (value: boolean): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: "UPDATE_INLAND_ALL_CARRIER_SELECTED",
            payload: { inlandCarrierIds: _.values(getState().seed.carriers).map(x => x.carrierId), value: value }
        });
    },
    openInlandOfferDetails: (key: string) => <OpenInlandCarrierOfferDetails>{
        type: "OPEN_INLAND_CARRIEROFFER_DETAILS",
        payload: { key: key }
    },
    closeInlandOfferDetails: (key: string) => <CloseInlandCarrierOfferDetails>{
        type: "CLOSE_INLAND_CARRIEROFFER_DETAILS",
        payload: { key: key }
    },
    openInlandOfferContainerDetails: (key: string, sizetypeId: number) => <OpenInlandCarrierOfferContainerDetails>{
        type: "OPEN_INLAND_CARRIEROFFER_CONTAINER_DETAILS",
        payload: { key: key, sizeTypeId: sizetypeId }
    },
    closeInlandOfferContainerDetails: (key: string, sizetypeId: number) => <CloseInlandCarrierOfferContainerDetails>{
        type: "CLOSE_INLAND_CARRIEROFFER_CONTAINER_DETAILS",
        payload: { key: key, sizeTypeId: sizetypeId }
    },
    openInlandOfferRatesDetails: (key: string) => <OpenInlandCarrierOfferRatesDetails>{
        type: "OPEN_INLAND_CARRIEROFFER_RATES_DETAILS",
        payload: { key: key }
    },
    closeInlandOfferRatesDetails: (key: string) => <CloseInlandCarrierOfferRatesDetails>{
        type: "CLOSE_INLAND_CARRIEROFFER_RATES_DETAILS",
        payload: { key: key }
    },
    openInlandOfferRatesComment: (key: string) => <OpenInlandCarrierOfferRatesComment>{
        type: "OPEN_INLAND_CARRIEROFFER_RATES_COMMENT",
        payload: { key: key }
    },
    closeInlandOfferRatesComment: (key: string) => <CloseInlandCarrierOfferRatesComment>{
        type: "CLOSE_INLAND_CARRIEROFFER_RATES_COMMENT",
        payload: { key: key }
    },
    goToInlandCriteria: (): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch(push(`/inland/criteria/${getState().inlandSelection.inlandCriteriaLoaded.code}`) as any);
    },
    openUseInQuotation: (id: string) => <InlandSelectionOpenUseInQuotation>{
        type: "INLAND_SELECTION_OPEN_USE_IN_QUOTATION",
        payload: { id: id }
    },
    closenUseInQuotation: () => <InlandSelectionCloseUseInQuotation>{
        type: "INLAND_SELECTION_CLOSE_USE_IN_QUOTATION",
    },
    toggleDocumentInfoDetails: (key: string, id: number, value: boolean) => <InlandSelectionToggleDocumentDetails>{
        type: "INLAND_SELECTION_TOGGLE_DOCUMENTDETAILS",
        payload: { key: key, id: id, value: value }
    },
    updateAdvContainer: (value: boolean) => <InlandSelectionUpdateAdvContainer>{
        type: "INLAND_SELECTION_UPDATE_ADVCONTAINER",
        payload: { value: value }
    }
};

export const unloadedInlandCarrierOfferState: InlandCarrierOfferState = {
    detailsOpened: false,
    ratesDetailsOpened: false,
    sizeTypesOpened: {},
    ratesOfferCommentOpened: false,
    isLoading: false,
    downloadDocumentState: {
        isLoading: false,
    },
    documentInfoDetailsOpened: {}
};

const unloadedState: InlandSelectionState = {
    isLoading: false,
    page: 1,
    filter: {
        inlandCarriersSelected: {},
        sortBy: "AllIn"
    },
    inlandCarrierOffersState: {},
    useInQuotationIsOpen: false,
    carrierRatesStates: {},
    inlandCarrierRatesOffersCache: [],
    advContainerOpened: false
};

export const reducer: Reducer<InlandSelectionState> = (state: InlandSelectionState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_INLAND_SELECTION":
            return {
                ...state,
                requestTime: action.payload.requestTime,
                isLoading: true,
                carrierRatesOffersCache: [],
            };
        case "RECEIVE_INLAND_SELECTION":
            if (action.error) {
                return {
                    ...state,
                    isLoading: false
                };
            }

            return {
                ...state,
                requestTime: action.payload.requestTime,
                isLoading: false,
                inlandSelection: {
                    ...action.payload.inlandSelection,
                    inlandCarrierOffers: action.payload.inlandSelection.inlandCarrierOffers
                        .concat(state.inlandCarrierRatesOffersCache)
                },
                inlandCriteriaLoaded: action.payload.inlandCriteria,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    },
                filter: unloadedState.filter,
                page: 1,
                carrierRatesStates: action.payload.inlandCriteria.includeCarrierRates
                    ? state.carrierRatesStates
                    : {}
            };
        case "REQUEST_DOWNLOAD_INLAND_DOCUMENT":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        isLoading: true
                    }
                }
            };
        case "RECEIVE_DOWNLOAD_INLAND_DOCUMENT":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        isLoading: false
                    }
                }
            };
        case "SELECT_INLAND_SELECTION_PAGE":
            return {
                ...state,
                page: action.payload.value
            };
        case "UPDATE_INLAND_CARRIER_SELECTED":
            return {
                ...state,
                filter: {
                    ...state.filter,
                    inlandCarriersSelected: {
                        ...state.filter.inlandCarriersSelected,
                        [action.payload.inlandCarrierId]: action.payload.value
                    }
                }
            };
        case "UPDATE_INLAND_ALL_CARRIER_SELECTED":
            let facNewCarriersSelected = {};
            action.payload.inlandCarrierIds
                .forEach(x => {
                    facNewCarriersSelected[x] = action.payload.value;
                });
            return {
                ...state,
                filter: {
                    ...state.filter,
                    inlandCarriersSelected: facNewCarriersSelected
                }
            };
        case "OPEN_INLAND_CARRIEROFFER_DETAILS":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        detailsOpened: true
                    }
                }
            };
        case "CLOSE_INLAND_CARRIEROFFER_DETAILS":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        detailsOpened: false
                    }
                }
            };
        case "OPEN_INLAND_CARRIEROFFER_RATES_DETAILS":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        ratesDetailsOpened: true
                    }
                }
            };
        case "CLOSE_INLAND_CARRIEROFFER_RATES_DETAILS":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        ratesDetailsOpened: false
                    }
                }
            };
        case "OPEN_INLAND_CARRIEROFFER_RATES_COMMENT":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        ratesOfferCommentOpened: true
                    }
                }
            };
        case "CLOSE_INLAND_CARRIEROFFER_RATES_COMMENT":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        ratesOfferCommentOpened: false
                    }
                }
            };
        case "OPEN_INLAND_CARRIEROFFER_CONTAINER_DETAILS":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        sizeTypesOpened: {
                            ...(state.inlandCarrierOffersState[action.payload.key]
                                ? state.inlandCarrierOffersState[action.payload.key].sizeTypesOpened
                                : unloadedInlandCarrierOfferState.sizeTypesOpened),
                            [action.payload.sizeTypeId]: true
                        }
                    }
                }
            };
        case "CLOSE_INLAND_CARRIEROFFER_CONTAINER_DETAILS":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        sizeTypesOpened: {
                            ...(state.inlandCarrierOffersState[action.payload.key]
                                ? state.inlandCarrierOffersState[action.payload.key].sizeTypesOpened
                                : unloadedInlandCarrierOfferState.sizeTypesOpened),
                            [action.payload.sizeTypeId]: false
                        }
                    }
                }
            };
        case "INLAND_SELECTION_UPDATE_SORTBY":
            return {
                ...state,
                filter: {
                    ...state.filter,
                    sortBy: action.payload.value
                }
            };
        case "SELECT_INLAND_CARRIEROFFER":
            return state;
        case "INLAND_SELECTION_OPEN_USE_IN_QUOTATION":
            return {
                ...state,
                useInQuotationIsOpen: true,
                selectedOffer: action.payload.id
            };
        case "INLAND_SELECTION_CLOSE_USE_IN_QUOTATION":
            return {
                ...state,
                useInQuotationIsOpen: false,
            };
        case "REQUEST_INLAND_CARRIERRATES":
            return {
                ...state,
                carrierRatesStates: {
                    ...state.carrierRatesStates,
                    [action.payload.carrierRatesPlatform.code]: {
                        ...state.carrierRatesStates[action.payload.carrierRatesPlatform.code],
                        isLoading: true,
                        requestTime: action.payload.requestTime,
                        inlandCarrierId: action.payload.carrierRatesPlatform.inlandCarrierId,
                        errors: []
                    }
                }
            };
        case "RECEIVE_INLAND_CARRIERRATES":
            if (!state.carrierRatesStates[action.payload.carrierRatesPlatform.code]
                || state.carrierRatesStates[action.payload.carrierRatesPlatform.code].requestTime !== action.payload.requestTime)
                return state;

            if (action.error) {
                return {
                    ...state,
                    carrierRatesStates: {
                        ...state.carrierRatesStates,
                        [action.payload.carrierRatesPlatform.code]: {
                            ...state.carrierRatesStates[action.payload.carrierRatesPlatform.code],
                            isLoading: false,
                            inlandCarrierId: action.payload.carrierRatesPlatform.inlandCarrierId,
                            errors: [action.error]
                        }
                    }
                };
            }

            return {
                ...state,
                carrierRatesOffersCache: state.isLoading
                    ? state.inlandCarrierRatesOffersCache.concat(action.payload.inlandCarrierOffers)
                    : state.inlandCarrierRatesOffersCache,
                inlandSelection: state.isLoading
                    ? state.inlandSelection
                    : {
                        ...state.inlandSelection,
                        inlandCarrierOffers: state.inlandSelection.inlandCarrierOffers
                            .concat(action.payload.inlandCarrierOffers)
                    },
                carrierRatesStates: {
                    ...state.carrierRatesStates,
                    [action.payload.carrierRatesPlatform.code]: {
                        ...state.carrierRatesStates[action.payload.carrierRatesPlatform.code],
                        isLoading: false,
                        carrierId: action.payload.carrierRatesPlatform.carrierId,
                        errors: action.payload.fetcherErrors
                            ? (state.carrierRatesStates[action.payload.carrierRatesPlatform.code].errors
                                ? state.carrierRatesStates[action.payload.carrierRatesPlatform.code].errors.concat(action.payload.fetcherErrors)
                                : action.payload.fetcherErrors)
                            : state.carrierRatesStates[action.payload.carrierRatesPlatform.code].errors
                    }
                }
            };
        case "INLAND_SELECTION_TOGGLE_DOCUMENTDETAILS":
            return {
                ...state,
                inlandCarrierOffersState: {
                    ...state.inlandCarrierOffersState,
                    [action.payload.key]: {
                        ...state.inlandCarrierOffersState[action.payload.key] || unloadedInlandCarrierOfferState,
                        documentInfoDetailsOpened: {
                            ...(state.inlandCarrierOffersState[action.payload.key]
                                ? state.inlandCarrierOffersState[action.payload.key].documentInfoDetailsOpened
                                : unloadedInlandCarrierOfferState.documentInfoDetailsOpened),
                            [action.payload.id]: action.payload.value
                        }
                    }
                }
            };
        case "INLAND_SELECTION_UPDATE_ADVCONTAINER":
            return {
                ...state,
                advContainerOpened: action.payload.value
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
