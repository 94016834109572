import {ApplicationState, reducers} from '.';
import { createScrollMiddleware } from 'react-redux-scroll';
import { createReduxHistoryContext, reachify } from "redux-first-history";
import { createBrowserHistory } from 'history';
import { createWouterHook } from "redux-first-history/wouter";
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {actionLogger} from "../middlewares/actionLogger";
import {okargoApi} from "../api/rtkApi";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
    //other options if needed 
});
const scrollMiddleware = createScrollMiddleware();

export const store = configureStore({
    reducer: {
        ...reducers,
        router: routerReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([routerMiddleware, okargoApi.middleware, scrollMiddleware, actionLogger])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ApplicationState; //ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>>;

export const history = createReduxHistory(store);
//if you use @reach/router 
export const reachHistory = reachify(history);
//if you use wouter
export const wouterUseLocation = createWouterHook(history);

// export default function configureStore(history: any, initialState?: ApplicationState): Store<ApplicationState> {
//     //Scroll handler
//     const scrollMiddleware = createScrollMiddleware();
//     // const actionLog = createActionLog({ limit: 200 });
//     //
//     const middleware = [
//         thunk,
//         routerMiddleware(history),
//         scrollMiddleware
//     ];
//
//     const rootReducer = combineReducers({
//         ...reducers,
//         router: connectRouter(history)
//     });
//
//     const enhancers = [];
//     const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
//     if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
//         enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
//     }
//    
//     let store = createStore(
//         rootReducer,
//         initialState as any,
//         compose(applyMiddleware(...middleware), ...enhancers)
//     );
//
//     if (windowIfDefined) {
//         //debug utilities
//         windowIfDefined.store = store;
//     }
//    
//     return store as any;
// }