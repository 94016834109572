import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as InlandRatesOfferStore from '../store/InlandRatesOffer';
import { ApplicationState } from '../store';
import InlandRatesOfferForm from "./InlandRatesOfferForm";
import Loader from './Loader';
import Select, { Option } from "./Select";
import Table, { Column } from "./Table";
import * as Colors from "../styles/colors";
import { getText } from '../utils/langTexts';
import Button from './Button';
import { searchContainerStyle, searchFieldStyle, formContainerStyle, buttonStyle, filterForm, inputStyleModerator, selectContainerStyle } from '../styles/moderatorStyles';

type ModeratorInlandRatesOfferProps = ModeratorInlandRatesOfferOwnProps
    & InlandRatesOfferStore.InlandRatesOfferState
    & typeof InlandRatesOfferStore.actionCreators;

interface ModeratorInlandRatesOfferOwnProps {
    inlandCarriers: { [id: number]: Api.InlandCarrierModel }
}

const getColumns = (props: ModeratorInlandRatesOfferProps): Array<Column<Api.InlandRatesOfferModel>> => {
    return [
        {
            header: getText("MdtRatesInfoCarrier"),
            id: 'carrier',
            accessor: (d: Api.InlandRatesOfferModel) => props.inlandCarriers[d.inlandCarrierId].name,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesInfoReference"),
            id: 'reference',
            accessor: (d: Api.InlandRatesOfferModel) => d.reference,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesInfoShortname"),
            id: 'shortName',
            accessor: (d: Api.InlandRatesOfferModel) => d.shortName,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: "",
            id: 'action',
                    accessor: (d: Api.InlandRatesOfferModel) => d.inlandRatesOfferId,
                    cell: (d: any) => <div style={{ ...(props.editState.inlandRatesOfferId === d ? { color: Colors.baseColors.lightBlue } : {}) }}>
                        {getText("GenEdit")}
                     </div>
        }
    ];
}



const trStyle = { cursor: "pointer" as any }
const selectedTrStyle = { ...trStyle, backgroundColor: Colors.colors.main7.backgroundColor }

class ModeratorInlandRatesOffer extends React.Component<ModeratorInlandRatesOfferProps, {}> {

    get carriersOptions(): Array<Option> {
        return _.sortBy(_.values(this.props.inlandCarriers), x => x.name)
            .map(ca => ({ label: ca.name, value: ca.inlandCarrierId }));
    }

    public render() {
        return (
            <div>
                <h2>{getText("MdtRatesInfo")}</h2>
                <div style={searchContainerStyle}>
                    <form className="form-inline" style={filterForm}
                        onSubmit={(e) => {
                            this.props.requestInlandRatesOffers(new Date().getTime());
                            e.preventDefault();
                        }}>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label" style={{ paddingRight:"8px" }}>{getText("MdtRatesInfoCarrierFilter")}</label>
                            <div style={selectContainerStyle}>
                                <Select
                                    options={this.carriersOptions}
                                    value={this.props.searchInlandRatesOffers.inlandCarrierId}
                                    onChange={(value) => this.props.selectInlandCarrierId(value)}
                                    style={{ width: "15em" }}
                                    search={true}
                                />
                            </div>
                        </div>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label" style={{ paddingRight: "8px" }}>{getText("MdtRatesInfoNameFilter")}</label>
                            <div style={selectContainerStyle}>
                                <input type="text" className="form-control" style={inputStyleModerator}
                                    value={this.props.searchInlandRatesOffers.term}
                                    onChange={(e) => this.props.updateSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <Button type="submit"
                            style={buttonStyle}>
                            {getText("MdtRatesInfoSearch")}
                        </Button>
                    </form>
                </div>
                <div>
                    <Table
                        tableKey={"modInlandRatesOffer"}
                        data={this.props.inlandRatesOffers}
                        columns={getColumns(this.props)}
                        rowsPerPage={12}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPagination={true}
                        showPageSizeOptions={false}
                        noDataText={getText("MdtNoRatesInformation")}
                        onBodyTrClick={(ratesOffer: Api.InlandRatesOfferModel) => {
                            if (ratesOffer) {
                                this.props.selectInlandRatesOffer(ratesOffer.inlandRatesOfferId);
                            }
                        }}
                    />
                </div>
                <div>
                    {this.props.editState.inlandRatesOfferId
                        && <div style={formContainerStyle}>
                        <InlandRatesOfferForm
                            onSubmit={(values) => {
                                return this.props.requestEditInlandRatesOffer(new Date().getTime(), values);
                            }}
                        />
                        </div>}
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.inlandRatesOffer,
        inlandCarriers: state.seed.inlandCarriers
    } as any),
    InlandRatesOfferStore.actionCreators)(ModeratorInlandRatesOffer) as any