import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { ApplicationState } from '../store/index';

export const isAuthenticated = (state: ApplicationState): boolean => {
    return state.account.currentUser
        && state.account.currentUser.expiration
        //Need to check that to block redirect before login is complete, and its necessary anyway
        && state.account.currentUser.clientModel
        && new Date() < new Date(state.account.currentUser.expiration);
}

export default connectedRouterRedirect({
    // The url to redirect user to if they fail
    redirectPath: '/login',
    authenticatedSelector: (state: ApplicationState) => isAuthenticated(state),
    wrapperDisplayName: 'UserIsAuthenticated'
})