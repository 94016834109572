import * as React from 'react';
import * as Api from '../api/api';
import DialogModal from './DialogModal';
import { CreateTemplateForm } from './QuotationTemplateForm';
import * as QuotationStore from '../store/Quotation';

interface QuotationSaveTemplateDialogProps {
    isOpen: boolean;
    isLoading: boolean;
    templates: Array<Api.QuotationModel>;
    requestCreateQuotationTemplate: (values: QuotationStore.QuotationCreateTemplateModel) => Promise<any>;
    onRequestClose: () => void;
}

export default class QuotationSaveTemplateDialog extends React.Component<QuotationSaveTemplateDialogProps, {}> {
    public render() {
        return (
            <DialogModal
                isOpen={this.props.isOpen}
                contentLabel={"Template"}
                maxWidth={"lg"}
                onRequestClose={() => this.props.onRequestClose()}>
                <div>
                    <CreateTemplateForm
                        templates={this.props.templates}
                        onSubmit={(values) => this.props.requestCreateQuotationTemplate(values)} />
                </div>
            </DialogModal>
        );
    } 
}