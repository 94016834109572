import * as React from 'react';
import * as _ from 'lodash';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getFirmName } from '../utils/utils';
import { Option } from "./Select";
import { getFirms } from './ModeratorAgentPortal';
import { revertColors } from '../styles/colors';

type ModeratorChargeAgentFormProps = ReduxForm.InjectedFormProps<{}, {}, string>
    & ModeratorChargeAgentFormOwnProps
    & ModeratorChargeAgentFormExternalProps;

interface ModeratorChargeAgentFormOwnProps {
    initialValues: Api.ChargeAgentModel;
    chargeAgentType: Api.ChargeAgentModelChargeAgentTypeEnum;
    chargeunit: Api.ChargeAgentModelUnitEnum;
    modificationType: Api.ChargeAgentModelModificationTypeEnum;
    handleSubmit: (values: Api.ChargeAgentModel) => void;
    currencies: { [id: number]: Api.CurrencyModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    subscriptions: Array<Api.SubscriptionModel>;
    submitting: boolean;

}

interface ModeratorChargeAgentFormExternalProps {
}

interface ChargeTypeOtion { label: string; value: Api.ChargeAgentModelChargeAgentTypeEnum }
const chargeTypeOptions: Array<ChargeTypeOtion> = [
    { label: "Add a charge", value: "Value" },
    { label: "Modify an existing charge", value: "Modification" }];
const chargeTypeSelect = FormField.getSelectField(chargeTypeOptions);

interface ChargeUnitOtion { label: string; value: Api.ChargeAgentModelUnitEnum }
const chargeUnitOptions: Array<ChargeUnitOtion> = [
    { label: "Container", value: "Ctr" },
    { label: "TEU", value: "Teu" },
    { label: "BL", value: "Bl" },
    { label: "Specific to a Ctr", value: "Specific" }
];
const chargeUnitSelect = FormField.getSelectField(chargeUnitOptions);

interface ChargeApplicationOption { label: string; value: Api.ChargeAgentModelApplicationEnum }
const chargeApplicationOptions: Array<ChargeApplicationOption> = [
    { label: "Origin", value: "Origin" },
    { label: "Freight", value: "Freight" },
    { label: "Destination", value: "Destination" }
];
const chargeApplicationSelect = FormField.getSelectField(chargeApplicationOptions);

interface ChargeModificationOption { label: string; value: Api.ChargeAgentModelModificationTypeEnum }
const chargeModificationOptions: Array<ChargeModificationOption> = [
    { label: "Add an amount", value: "AddAmount" },
    { label: "Exlude the charge", value: "Exclude" },
    { label: "Replace with the amount", value: "ReplaceAmount" }
];
const chargeModificationSelect = FormField.getSelectField(chargeModificationOptions);

const textAreaField = FormField.textAreaField(4, 50);
const currencyIfHasAmount = (value, allValues) => ((!allValues.amount && !allValues.currencyId) && "Currency required when there is an amount");

const originLocationField = FormField.getLocationField("MODERATOR_CHARGE_ORIGIN");
const destinationLocationField = FormField.getLocationField("MODERATOR_CHARGE_DESTINATION");

class ModeratorChargeAgentForm extends React.Component<ModeratorChargeAgentFormProps, {}> {

    get currencyOptions(): Array<Option> {
        return [{ label: "------", value: null }].concat(_.values(this.props.currencies)
            .map(cu => ({ label: cu.name, value: cu.currencyId })));
    }

    get firmOptions(): Array<Option> {
        return getFirms(this.props.subscriptions).map(fi => ({
            label: getFirmName(fi),
            value: fi.firmId
        }));
    }

    get sizeTypesOptions(): Array<Option> {
        return _.values(this.props.sizeTypes).map(st => ({
            label: st.name,
            value: st.sizeTypeId
        }));
    }

    get chargeNamesOptions(): Array<Option> {
        return _.values(this.props.chargeNames)
            .sort((a, b) => a.shortName > b.shortName ? 1 : -1)
            .map(cn => ({
                label: cn.shortName,
                value: cn.chargeNameId
        }));
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-horizontal container-fluid">
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 control-label">Type: </label>
                    <div className="col-sm-8">
                        <ReduxForm.Field className="form-control"
                            validate={[FormField.required]}
                            name="chargeAgentType"
                            component={chargeTypeSelect}
                        />
                    </div>
                </div>
                {this.props.chargeAgentType === "Value" &&
                    <div className="form-group">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label">Name: </label>
                            <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                    validate={[FormField.required]}
                                    name="name"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 control-label">Unit: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                validate={[FormField.required]}
                                name="unit"
                                component={chargeUnitSelect}
                                type="text" />
                        </div>
                    </div>
                    {this.props.chargeunit === "Specific" &&
                        <div className="form-group row">
                            <label className="col-sm-4 control-label">Container: </label>
                            <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                    validate={[FormField.required]}
                                    name="unit"
                                    component={chargeUnitSelect}
                                    type="text" />
                            </div>
                        </div>
                    }
                    <div className="form-group row">
                        <label className="col-sm-4 control-label">Application: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                validate={[FormField.required]}
                                name="application"
                                component={chargeApplicationSelect}
                                type="text" />
                        </div>
                    </div>
                    </div>}

                {this.props.chargeAgentType === "Modification" &&
                    <div className="form-group row">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label">Modification type: </label>
                            <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                    validate={[FormField.required]}
                                    name="modificationType"
                                    component={chargeModificationSelect}
                                    type="text" />
                            </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 control-label">Charge name: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                validate={[FormField.required]}
                                name="chargeNameId"
                                component={FormField.getSelectField(this.chargeNamesOptions)} />
                        </div>
                    </div>
                </div>
                }

                {this.props.modificationType !== "Exclude" && 
                    <div className="form-group">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label">Amount: </label>
                            <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                    validate={[]}
                                    name="amount"
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                        {(this.props.chargeAgentType === "Value" || this.props.modificationType === "ReplaceAmount") &&
                            <div className="form-group row">
                                <label className="col-sm-4 control-label">Currency: </label>
                                <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                        validate={[currencyIfHasAmount]}
                                        name="currencyId"
                                        component={FormField.getSelectField(this.currencyOptions)} />
                                </div>
                            </div>
                        }

                    </div>
                }

                <div className="form-group">
                    <div className="form-group row">
                        <label className="col-sm-4 control-label">Origin: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                validate={[]}
                                name="origin"
                                component={originLocationField} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 control-label">Destination: </label>
                        <div className="col-sm-8">
                            <ReduxForm.Field className="form-control"
                                validate={[]}
                                name="destination"
                                component={destinationLocationField} />
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 control-label">Agency/Company: </label>
                    <div className="col-sm-8">
                        <ReduxForm.Field className="form-control"
                            validate={[FormField.required]}
                            name="firmId"
                            component={FormField.getSelectField(this.firmOptions)} />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 control-label"></label>
                    <div className="col-sm-8">
                        <button type="submit"
                            style={{ ...revertColors.main3 }}
                            className="btn">Create charge</button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let moderatorChargeAgentFormWrapped = ReduxForm.reduxForm({
    form: 'moderatorChargeAgent',
    enableReinitialize: true
})(ModeratorChargeAgentForm) as any;
const selector = ReduxForm.formValueSelector('moderatorChargeAgent')

export default connect((state: ApplicationState) => {
    const chargeAgentType = selector(state, 'chargeAgentType')
    const chargeunit = selector(state, 'unit')
    const modificationType = selector(state, 'modificationType')
    return ({
        chargeAgentType: chargeAgentType,
        chargeunit: chargeunit,
        modificationType: modificationType,
        currencies: state.seed.currencies,
        sizeTypes: state.seed.sizeTypes,
        chargeNames: state.seed.chargeNames,
        subscriptions: state.account.currentUser.clientModel
            ? state.account.currentUser.clientModel.subscriptions
            : [],
        initialValues: {
            firmId: state.account.currentUser.clientModel
                ? state.account.currentUser.clientModel.subscriptions[0].agencyId
                : 0
        }
    });
})(moderatorChargeAgentFormWrapped) as any