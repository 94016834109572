import * as React from 'react';
import * as Api from '../api/api';
import * as QuotationStore from '../store/Quotation';
import QuotationCharge from './QuotationCharge';
import QuotationFee from './QuotationFee';
import { RatesCalculator } from '../utils/ratesUtils';
import { rowStyle, borderStyle } from "../styles/quotationStyles";
import { quotColors } from '../styles/colors';
import { getText } from '../utils/langTexts';


type QuotationApplicationChargesProps = QuotationApplicationChargesOwnProps;

interface QuotationApplicationChargesOwnProps {
    title: string;
    application: Api.ChargeNameModelApplicationEnum;
    usableCurrencies: Array<Api.CurrencyModel>;
    feeType: Api.QuotationFeeModelTypeEnum;
    carrierOffer: Api.CarrierOfferModel;
    quotation: Api.QuotationModel;
    criteria: Api.CriteriaModel;
    perBl: boolean;
    ratesCalculator: RatesCalculator;
    feeStates: { [index: number]: QuotationStore.QuotationFeeState };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    currencies: { [id: number]: Api.CurrencyModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    findChargeNamesFromApplication: (application: Api.ChargeNameModelApplicationEnum, perBl: boolean) => Array<Api.QuotationChargeNameLevels>;
    quotationSelectCharge: (chargeNameId: number, application: Api.QuotationChargeModelApplicationEnum, value: boolean, level:number) => void;
    quotationUpdateChargeAmount: (chargeNameId: number, application: Api.QuotationChargeModelApplicationEnum, value: number, lvl: number,originalChargeId :number, sizeTypeId: number) => void;
    quotationRemoveFee: (index: number) => void;
    quotationUpdateFeeBaseAmount: (index: number, value: number, sizeTypeId: number) => void;
    quotationUpdateFeeAmount: (index: number, value: number, sizeTypeId: number) => void;
    quotationSelectFeeCurrency: (index: number, value: number, sizeTypeId: number) => void;
    quotationUpdateFeeLabel: (index: number, value: string, sizeTypeId: number) => void;
    quotationUpdateFeeMode: (index: number, value: QuotationStore.QuotationFeeMode, sizeTypeId: number) => void;
    quotationUpdateFeeName: (index: number, value: string) => void;
    quotationUpdateFeeUnit: (index: number, value: string) => void;
    quotationSelectFee: (index: number, value: boolean) => void;
    quotationAddFee: (feeMode: Api.QuotationFeeModelTypeEnum) => void;
    quotationAddQuotationFeeLabel: (index: number) => void;
    quotationUpdateQuotationFeeLabel: (feeIndex: number, index: number, value: string) => void;
    quotationRemoveQuotationFeeLabel: (feeIndex: number, index: number) => void;
}

export default class QuotationApplicationCharges extends React.Component<QuotationApplicationChargesProps, {}> {
    public render() {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch"
            }}>
                {this.props.title
                    && <div style={{
                        ...rowStyle, ...quotColors.subRowColor,
                        backgroundColor: "rgb(240,240,240)",
                        fontWeight: 500, borderLeft: borderStyle,
                        borderRight: borderStyle,
                        alignItems: "center"
                    }}>
                        <div style={{ paddingLeft: 40 }}>{this.props.title}</div>
                    </div>}
                {this.props.carrierOffer.chargeSet
                    && this.props.findChargeNamesFromApplication(this.props.application, this.props.perBl).map(cn =>
                        Array(cn.levels).fill(0).map( (x,lvl)=>                 
                        <QuotationCharge
                            key={`${cn.chargeName.chargeNameId}|${this.props.application}|${lvl}`}
                            chargeName={cn.chargeName}
                            level={lvl}
                            currencyId={ cn.currencyId}
                            application={this.props.application}
                            chargeNames={this.props.chargeNames}
                            currencies={this.props.currencies}
                            chargeSet={this.props.carrierOffer.chargeSet}
                            criteriaSizeTypes={this.props.criteria.criteriaSizeTypes}
                            quotationCharge={this.props.quotation.quotationCharges
                                .find(qc => qc.chargeNameId === cn.chargeName.chargeNameId &&   (!qc.level  || qc.level == lvl)
                                    && (!qc.application || qc.application === this.props.application))}
                            sizeTypes={this.props.sizeTypes}
                            ratesCalculator={this.props.ratesCalculator}
                            perBl={this.props.perBl}
                            onSelect={(value) => this.props.quotationSelectCharge(cn.chargeName.chargeNameId, this.props.application, value,lvl)}
                            updateAmount={(value, lvl,originalChargeId, sizeTypeId) => this.props.quotationUpdateChargeAmount(cn.chargeName.chargeNameId, this.props.application, value, lvl,originalChargeId, sizeTypeId)}
                        />
                    ))}
                {this.props.quotation.quotationFees.filter(qf => qf.type === this.props.feeType).map(qf =>
                    <QuotationFee
                        key={this.props.quotation.quotationFees.indexOf(qf)}
                        quotationFee={qf}
                        criteriaSizeTypes={this.props.criteria.criteriaSizeTypes}
                        sizeTypes={this.props.sizeTypes}
                        currencies={this.props.usableCurrencies}
                        perBl={this.props.perBl}
                        remove={() => this.props.quotationRemoveFee(this.props.quotation.quotationFees.indexOf(qf))}
                        updateBaseAmount={(value, sizeTypeId) =>
                            this.props.quotationUpdateFeeBaseAmount(this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                        updateAmount={(value, sizeTypeId) =>
                            this.props.quotationUpdateFeeAmount(this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                        selectCurrency={(currencyId, sizeTypeId) =>
                            this.props.quotationSelectFeeCurrency(this.props.quotation.quotationFees.indexOf(qf), currencyId, sizeTypeId)}
                        updateName={(value) =>
                            this.props.quotationUpdateFeeName(this.props.quotation.quotationFees.indexOf(qf), value)}
                        updateUnit={(value) =>
                            this.props.quotationUpdateFeeUnit(this.props.quotation.quotationFees.indexOf(qf), value)}
                        onSelect={(value) =>
                            this.props.quotationSelectFee(this.props.quotation.quotationFees.indexOf(qf), value)}
                        quotationFeeState={this.props.feeStates[this.props.quotation.quotationFees.indexOf(qf)]
                            || QuotationStore.unloadedFeeState}
                        updateLabel={(value, sizeTypeId) => this.props.quotationUpdateFeeLabel(this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                        updateMode={(value, sizeTypeId) => this.props.quotationUpdateFeeMode(this.props.quotation.quotationFees.indexOf(qf), value, sizeTypeId)}
                        addQuotationFeeLabel={() => this.props.quotationAddQuotationFeeLabel(
                            this.props.quotation.quotationFees.indexOf(qf))}
                        removeQuotationFeeLabel={(index) => this.props.quotationRemoveQuotationFeeLabel(
                            this.props.quotation.quotationFees.indexOf(qf), index)}
                        updateQuotationFeeLabel={(index, value) => this.props.quotationUpdateQuotationFeeLabel(
                            this.props.quotation.quotationFees.indexOf(qf), index, value)}
                    />
                )}
                <div style={{
                    ...rowStyle,
                    borderLeft: borderStyle,
                    borderRight: borderStyle,
                }}>
                    <div style={{ flex: 2, borderRight: borderStyle }}>
                        <div style={{ cursor: "pointer", fontWeight: 200, textAlign: "center" }}
                            onClick={(e) => {
                                this.props.quotationAddFee(this.props.feeType);
                                e.stopPropagation();
                            }}>
                            {this.props.perBl
                                ? getText("QtnAddBlCharge")
                                : getText("QtnAddCharge")}
                        </div>
                    </div>
                    <div style={{
                        flex: 4,
                        marginTop: 10,
                        borderTop: "1px dotted"
                    }}>
                    </div>
                </div>
            </div>
        );
    }
}