import * as React from 'react';
import * as Api from '../api/api';
import CheckBox from './CheckBox'
import InputNumber from "./InputNumber";
import { RatesCalculator } from '../utils/ratesUtils';
import { inputNumberStyle, rowStyle, borderStyle } from "../styles/quotationStyles";
import { getText } from '../utils/langTexts';
import { getChargeAmount } from '../utils/utils';
import { quotColors } from '../styles/colors';
import * as _ from 'lodash';
import Tooltip from "./Tooltip";

const oneToThree = [1, 2, 3, 4];

interface QuotationChargeProps {
    ratesCalculator: RatesCalculator;
    chargeSet: Api.ChargeSetModel;
    chargeName: Api.ChargeNameModel;
    criteriaSizeTypes: Array<Api.CriteriaSizeTypeModel>;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    quotationCharge: Api.QuotationChargeModel;
    application: Api.ChargeModelApplicationEnum;
    perBl: boolean;
    level: number,
    currencyId: number,
    onSelect: (value: boolean) => void;
    updateAmount: (value: number, lvl: number, originalChargeId: number, sizeTypeId?: number) => void;
}

export default class QuotationCharge extends React.Component<QuotationChargeProps, {}> {

    getSizeTypeCriteria(position: number): Api.CriteriaSizeTypeModel {
        let cst = this.props.criteriaSizeTypes
            .find(st => this.props.sizeTypes[st.sizeTypeId].position === position && st.number > 0);
        return cst ? cst : null;
    }

    findCharge(nb: number,level: number): Api.ChargeModel {

        var chargesReal = this.props.ratesCalculator.findChargesToApply(this.props.chargeSet, this.getSizeTypeCriteria(nb))
            .filter(ch => ch.chargeNameId === this.props.chargeName.chargeNameId && ch.application === this.props.application && this.props.currencyId  == ch.currencyId)

        var ret = (chargesReal)[level];


        return ret;
    }

    get firstCharge(): Api.ChargeModel {
        return _.sortBy(this.props.chargeSet.charges
            .filter(ch => ch.chargeNameId === this.props.chargeName.chargeNameId)
            , x => x.application !== this.props.application)[0];
    }

    firstChargeSource(sizeTypeId?: number): Api.ChargeModel {
        return (sizeTypeId
            ? this.props.ratesCalculator.findChargesToApply(this.props.chargeSet, null, null, [])
            : this.props.ratesCalculator.findBlChargesToApply(this.props.chargeSet, null, []))
            .find(x => x.chargeNameId === this.firstCharge.sourceChargeNameId);
    }

    public render() {
        return (
            <div style={{
                ...rowStyle,
                ...quotColors.contentRowColor,
                borderLeft: borderStyle
            }}>
                <div style={{
                    flex: 2, display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRight: borderStyle,
                    //paddingRight: !this.props.perBl ? 2 : 0
                }}>
                    <div style={{
                        flex: "0 0 auto",
                    }}>
                        <CheckBox
                            size={"small"}
                            value={!this.props.quotationCharge
                                || this.props.quotationCharge.visible}
                            onChange={(value) => this.props.onSelect(value)} />
                    </div>
                    <div style={{
                        flex: 1, display: "flex",
                        flexDirection: "row", alignItems: "center"
                    }}>
                        <div>
                            {this.props.chargeName.shortName} {this.firstCharge.chargeType === "Source"
                                && this.firstCharge.type === "Percentage"
                                ? `(${Math.round(this.firstCharge.amount * 100 * 100) / 100}% of ${this.props.chargeNames[this.firstCharge.sourceChargeNameId].shortName})`
                                : ""}
                        </div>
                        <div>
                            {(this.firstCharge.paymentOption) &&
                                <Tooltip overlay={<div>{this.firstCharge.paymentOption}</div>}>
                                    {(this.firstCharge.paymentOption.toString() === "Freight") &&
                                        <svg style={{ cursor: "pointer", height: 16, width: "2em" }} viewBox="0 0 24 24">
                                            <path fill="#00afd5" d="M9,7H15V9H11V11H14V13H11V17H9V7M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M5,5V19H19V5H5Z" />
                                        </svg>
                                    }
                                    {(this.firstCharge.paymentOption.toString() === "Prepaid") &&
                                        <svg style={{ cursor: "pointer", height: 16, width: "2em" }} viewBox="0 0 24 24">
                                            <path fill="#00afd5" d="M9,7H13A2,2 0 0,1 15,9V11A2,2 0 0,1 13,13H11V17H9V7M11,9V11H13V9H11M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M5,5V19H19V5H5Z" />
                                        </svg>
                                    }
                                    {(this.firstCharge.paymentOption.toString() === "Collect") &&
                                        <svg style={{ cursor: "pointer", height: 16, width: "2em" }} viewBox="0 0 24 24">
                                            <path fill="#00afd5" d="M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M5,5V19H19V5H5M11,7H13A2,2 0 0,1 15,9V10H13V9H11V15H13V14H15V15A2,2 0 0,1 13,17H11A2,2 0 0,1 9,15V9A2,2 0 0,1 11,7Z" />
                                        </svg>
                                    }
                                </Tooltip>
                            }
                        </div>
                    </div>
                    <div style={{
                        flex: "0 0 auto", minWidth: 50, marginRight: 6
                    }}>
                        {this.firstCharge.chargeType === "Source"
                            ? (this.firstChargeSource() ? this.props.currencies[this.firstChargeSource().currencyId].code : "")
                            : this.props.currencies[this.props.currencyId].code}
                    </div>
                </div>

                <div style={{ flex: 4, display: "flex", flexDirection: "row", alignItems: "stretch" }}>
                    {!this.props.perBl
                        && oneToThree.map(nb =>             
                            <div style={{
                                flex: 1, borderRight: borderStyle,
                                display: "flex", flexDirection: "row",
                                alignItems: "center"
                            }} key={nb}>
                                {this.getSizeTypeCriteria(nb)
                                    && this.findCharge(nb, this.props.level)
                                    && <div style={{ width: "100%" }}>
                                        <QuotationChargeAmount
                                            charge={this.findCharge(nb, this.props.level)}
                                            ratesCalculator={this.props.ratesCalculator}
                                            chargeSet={this.props.chargeSet}
                                            quotationChargeAmount={this.props.quotationCharge
                                                ? this.props.quotationCharge.quotationChargeAmounts
                                                    .find(qc => qc.originalChargeId == this.findCharge(nb, this.props.level).chargeId && qc.sizeTypeId === this.getSizeTypeCriteria(nb).sizeTypeId)
                                                : null}
                                            sizeType={this.props.sizeTypes[this.getSizeTypeCriteria(nb).sizeTypeId]}
                                            chargeNames={this.props.chargeNames}
                                            currencies={this.props.currencies}
                                            updateAmount={(value, originalChargeId) =>
                                                this.props.updateAmount(value, this.props.level, originalChargeId, this.getSizeTypeCriteria(nb).sizeTypeId)} />
                                    </div>}
                            </div>)}
                    {this.props.perBl
                        && <div style={{ flex: 3, borderRight: borderStyle }}>
                            <QuotationChargeAmount
                                charge={_.sortBy(this.props.ratesCalculator.findBlChargesToApply(this.props.chargeSet)
                                    .filter(ch => ch.chargeNameId === this.props.chargeName.chargeNameId), x => x.application !== this.props.application)[0]}
                                ratesCalculator={this.props.ratesCalculator}
                                chargeSet={this.props.chargeSet}
                                quotationChargeAmount={this.props.quotationCharge
                                    ? this.props.quotationCharge.quotationChargeAmounts[0]
                                    : null}
                                chargeNames={this.props.chargeNames}
                                currencies={this.props.currencies}
                                updateAmount={(value, originalChargeId) => this.props.updateAmount(value, this.props.level, originalChargeId)}
                            />
                        </div>}

                </div>
            </div>
        );
    }
}

interface QuotationChargeAmountProps {
    ratesCalculator: RatesCalculator;
    chargeSet: Api.ChargeSetModel;
    currencies: { [id: number]: Api.CurrencyModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    sizeType?: Api.SizeTypeModel;
    charge: Api.ChargeModel;
    quotationChargeAmount: Api.QuotationChargeAmountModel;
    updateAmount: (value: number, originalChargeId: number) => void;
}

class QuotationChargeAmount extends React.Component<QuotationChargeAmountProps, {}> {

    get chargeSource(): Api.ChargeModel {
        return (this.props.sizeType
            ? this.props.ratesCalculator.findChargesToApplybySizeId(this.props.chargeSet, this.props.sizeType.sizeTypeId, null, [])
            : this.props.ratesCalculator.findBlChargesToApply(this.props.chargeSet, null, []))
            .find(x => x.chargeNameId === this.props.charge.sourceChargeNameId);
    }

    get chargeAmount(): number {
        if (this.props.charge.chargeType === "Source"
            && this.props.charge.type === "Percentage") {
            let source = this.chargeSource;

            if (!source)
                return 0;

            return Math.round(source.amount * this.props.charge.amount * 100) / 100;
        }

        return getChargeAmount(this.props.charge, this.props.sizeType
            ? this.props.sizeType.teu
            : 1);
    }

    public render() {
        return (
            <div style={{ textAlign: "center", height: "100%", padding: "3px 6px" }}>
                {(this.props.charge.chargeType === "Value"
                    || (this.props.charge.chargeType === "Source" && this.props.charge.type === "Percentage"))
                    && <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center" }}>
                        <input style={{ ...inputNumberStyle, flex: "1", minWidth: 50 }}
                            disabled
                            defaultValue={this.chargeAmount.toString()} />
                        <div style={{ flex: "0 0 auto", margin: "0px 5px" }}> / </div>
                        <div style={{ flex: "1", minWidth: 50 }}>
                            <InputNumber
                                style={{ ...inputNumberStyle }}
                                value={this.props.quotationChargeAmount
                                    ? this.props.quotationChargeAmount.amount
                                    : this.chargeAmount}
                                onChange={(value) => this.props.updateAmount(value, this.props.charge.chargeId)} />
                        </div>
                        <div style={{ flex: "0 0 auto", width: 25 }}></div>
                    </div>}
                {this.props.charge.chargeType === "Source"
                    && <div>
                        {this.props.charge.type === "Incl"
                            && <div style={{ textAlign: "center" }}>
                                {getText("GenIncluded", { value: this.props.chargeNames[this.props.charge.sourceChargeNameId].code })}
                            </div>}
                    </div>}
            </div>
        );
    }
}