import * as React from 'react';
import * as Colors from '../styles/colors';
import DialogModal from "./DialogModal";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import * as AccountStore from '../store/Account';
import { RequestState } from "../models/models";

type GcuDialogProps = GcuDialogOwnProps
    & RequestState
    & typeof AccountStore.actionCreators;

interface GcuDialogOwnProps {
    canOpen: boolean;
    gcuAccepted: boolean;
    langId: number;
    languages: { [id: number]: Api.LanguageModel };
}

class GcuDialog extends React.Component<GcuDialogProps, {}> {
    public render() {

        const isFr = this.props.languages[this.props.langId] && this.props.languages[this.props.langId].code.toLowerCase() === "fr" ;

        return (<DialogModal
            isOpen={(this.props.canOpen && !this.props.gcuAccepted) ? true : false}
            contentLabel={"Gcu"}
            hideClose
            contentStyle={{ top: "5%", bottom: "5%", left: "10%", right: "10%" }}
            onRequestClose={() => { }}>
            <div>
                {isFr &&
                    <div>
                    <h2 className="geog-bold">CONDITIONS GENERALES D'UTILISATION</h2>
                        <p>Conditions Générales d'Utilisation applicables à partir du 1er avril 2014.</p>
                        <h3 className="geog-bold">Important: </h3>
                        <p className="geog-bold">
                            Si vous utilisez un compte test, les prix affichés pour une compagnie maritime sont factices et sont employés uniquement à titre d'exemple. Ceci ne peut en aucun cas être considéré comme les prix pratiqués par la compagnie maritime en question qui peuvent être inférieurs ou supérieurs en réalité. En revanche, les informations opérationnelles sont réelles. De même, vous êtes soumis à l'intégralité des présentes Conditions Générales d'Utilisation y compris aux dispositions de propriété intellectuelle et de divulgation des données.
                    </p>
                        <p className="geog-bold">
                            Okargo n'est ni mandataire, ni agent, ni courtier d'affrètement. Le service proposé n'a en aucun cas pour effet de représenter l'Utilisateur auprès d'une compagnie de transport maritime.
                    </p>
                        <p className="geog-bold">
                            Okargo s'adresse à des Utilisateurs intervenant à titre professionnel, personnes physiques ou morales. En utilisant le service, vous acceptez de vous soumettre à l'ensemble des Conditions Générales d'Utilisation. Si tel n'était pas cas, ou si vous refusiez une modification ultérieure des Conditions Générales d'Utilisation des Applications web ou mobile, vous devez cesser d'utiliser le service Okargo.
                    </p>
                        <h3 className="geog-bold">DEFINITIONS: </h3>
                        <div>
                            <span className="geo-bold">Société: </span>La société <span className="geo-bold">EUREKA SAS</span> est une société par actions simplifiée au capital social de 109 960 euros, dont le siège social est situé 32 avenue André Roussin 13016 à MARSEILLE, immatriculée au Registre du commerce et des sociétés de MARSEILLE sous le numéro 793 300 419, représentée par Georges Chapus, Président.
                    </div>
                        <div>
                            Okargo: désigne l'ensemble du service de comparaison de taux de fret et comprend l'Application web ainsi que l'Application mobile.
                    </div>
                        <div>
                            <span className="geo-bold">Utilisateur ou Client:</span> désigne la personne physique ou morale utilisant le service Okargo.
                    </div>
                        <div>
                            <span className="geo-bold">Application web: </span>désigne le site internet www.okargo.com accessible depuis un navigateur.
                    </div>
                        <div>
                            <span className="geo-bold">Application mobile: </span>l'application mobile iPhone développée par la Société disponible sur l'AppStore, et l'application Android OKARGO à venir disponible sur Google Play.
                    </div>
                        <div>
                            <span className="geo-bold">Compagnie maritime, compagnie de transport, compagnie:</span> la personne morale offrant le service de transport via son site internet.
                    </div>
                        <div>
                            <span className="geo-bold">Compte test:</span> version basée sur des prix factices permettant à l'utilisateur de tester l'Application web et/ou mobile gratuitement afin d'estimer la potentialité du service.
                    </div>
                        <div>
                            <span className="geo-bold">Version complète:</span> désigne le service complet Okargo comprenant les Applications web et mobile et fournissant la comparaison de taux de fret sur la base des grilles de prix transmises par l'utilisateur, pour une période indéterminée et soumise à abonnement.
                    </div>
                        <div>
                            <span className="geo-bold">Phase d'essai: </span>période d'essai gratuite du service Okargo s'étalant sur une durée définie dans les Conditions partiuclières et précédant la version complète.
                    </div>
                        <h3 className="geo-bold">DESCRIPTION DES PRESTATIONS: </h3>
                        <p>
                            Les Conditions Générales d'Utilisation gouvernent l'accès et l'usage du service Okargo, ainsi que toutes autres marques Okargo, ou section de site web incluant page Facebook ou compte Twitter.
                    </p>
                        <p>
                            Okargo est un facilitateur exceptionnel de recherche de taux de fret conteneurisé. Il vous permet en quelques clics de comparer, pour un même voyage, le taux de fret entre plusieurs compagnies de transport maritime. Une fois le choix effectué par le Client, ce dernier peut recevoir un récapitulatif de son trajet sur son adresse mail et accède aux informations nécessaires pour effectuer sa réservation.
                    </p>
                        <p>
                            Les liens hypertextes présents sur l'Application web et mobile intitulés « site de la compagnie »,  renvoient aux pages de consultation des horaires des compagnies de transport et vous font quitter le service Okargo.
                    </p>
                        <p>
                            Pour l'utilisation du service Okargo, en phase d'essai ou en version complète, le Client transmet ses grilles de prix à la Société qui l'intègre dans une base de données sécurisée et accessible uniquement  par ce Client. Les résultats de recherche du Client ne tiendront compte que des prix pratiqués par la compagnie maritime pour ce Client. La Société s'engage à garder ces grilles de prix confidentielles et à ne les transmettre à aucun tiers, nonobstant accord de sous-traitance comportant une clause de confidentialité. L'Utilisateur, une fois sélectionnée son offre de transport, pourra effectuer la réservation du transport en fonction de sa propre grille de prix.
                    </p>
                        <p>
                            En outre, dans la version complète, le client peut avoir accès aux taux fournis par les compagnies maritimes directement à Okargo et publiés sur le site internet après accord de celles-ci. Ces taux s'afficheront dans les résultats de recherche du Client au même titre que les taux issus des grilles du Client en question. La Société et le Client s'engagent à garder ces données confidentielles et à ne les transmettre à aucun tiers, nonobstant accord de sous-traitance comportant une clause de confidentialité.
                    </p>
                        <div className="geog-bold">
                            <span className="text-big">IMPORTANT: </span>Si vous utilisez un compte test, les prix affichés sont factices et sont employés uniquement à titre d'exemple. Ceci ne peut en aucun cas être considéré comme les prix pratiqués par la compagnie maritime en question qui peuvent être inférieurs ou supérieurs en réalité. En revanche, les informations opérationnelles sont réelles.
                    </div>
                        <div className="geog-bold">
                            De même, vous êtes soumis à l'intégralité des présentes Conditions Générales d'Utilisation y compris aux dispositions des clauses de « propriété intellectuelle » et « responsabilité de l'utilisateur ».
                    </div>
                        <h3 className="geo-bold">ACCES AU SITE: </h3>
                        <div>
                            Okargo a pour objectif de fournir des informations à jour et fiables aux Utilisateurs. Ceci implique que des informations pourront devenir obsolètes et que des modifications pourront intervenir à tout moment, les compagnies de transport n'étant pas liées par la recherche effectuée sur Okargo. Au moment d'effectuer sa réservation, ou en consultant le site web de la compagnie, l'Utilisateur peut ainsi voir des informations différentes ou contradictoires que celles fournies, par Okargo. <span className="geog-bold">En conséquence, les informations fournies par Okargo n'ont qu'un caractère indicatif et non contractuel.</span>
                        </div>
                        <div>
                            Nous conseillons à l'Utilisateur souhaitant effectuer une réservation auprès d'une compagnie via Okargo de vérifier lesdites informations au moment de sa réservation.
                    </div>
                        <p>
                            De même, dans le but d'assurer la meilleure prestation possible, la Société se réserve le droit, sans préavis, de limiter et de suspendre l'accès au service Okargo, de modifier le contenu des Applications web et mobile, ainsi que les présentes Conditions Générales d'Utilisation.
                    </p>
                        <h3 className="geo-bold">RESPONSABILITE DE OKARGO: </h3>
                        <p>
                            La Société ne possède ni ne contrôle aucune offre de transport maritime accessible par le service Okargo. Le service fournit se limite à la comparaison des taux de fret conteneurisé pour un voyage déterminé entre deux ports à une date donnée. Okargo ne permet pas la réservation directe du transport. Il donne accès au Client à des informations nécessaires pour passer une réservation auprès de la Compagnie Maritime.
                    </p>
                        <p>
                            La Société n'est pas solidaire de la compagnie de transport et n'est donc pas responsable du préjudice subi par le Client en cas de dysfonctionnement, erreurs, ou modifications effectués sur le site internet de la compagnie qui n'aurait pas encore été pris en compte par le service Okargo.
                    </p>
                        <p>
                            A l'exclusion du compte test, en cas d'erreur de saisie entrainant un préjudice pour le Client, la Société et le Client s'engagent à trouver une solution amiable en suivant les dispositions de l'article intitulé <span className="text-big">« LOI APPLICABLE ET REGLEMENT DES LITIGES »</span> ci-après.
                    </p>
                        <p>
                            La Société décline toute responsabilité en cas de perte, dommage, ou virus affectant l'ordinateur de l'Utilisateur ou son contenu, en raison de l'accès à Okargo.
                    </p>
                        <p>
                            De même, dans le cas de l'utilisation d'un compte test, la société décline toute responsabilité vis-à-vis d'un préjudice résultant (CITER L'ARTICLE DU CODE CIVIL SUR LA TURPITUDE).
                    </p>
                        <h3 className="geog-bold">RESPONSABILITE DE L'UTILISATEUR: </h3>
                        <div>
                            Le Client doit s'enregistrer sur le site internet et ouvrir un compte pour bénéficier du service Okargo.
                    </div>
                        <div>
                            Il existe deux types de compte:
                    </div>
                        <ul>
                            <li>Un compte test gratuit, permettant de découvrir le service sur la base de données de prix factices, afin que l'Utilisateur se fasse une idée du fonctionnement du service Okargo.</li>
                            <li>Un compte normal accessible par abonnement, pour lequel l'Utilisateur devra fournir ses grilles de prix. Ce compte peut-être utilisé gratuitement pendant une phase d'essai.</li>
                        </ul>
                        <p>
                            Ces comptes sont protégés par un nom d'Utilisateur et un mot de passe qui sont strictement personnels au Client et confidentiels. Celui-ci ne peut en aucun cas les transmettre ou les communiquer à un tiers. Si tel était le cas, le Client pourrait se voir refuser l'accès au service Okargo.
                    </p>
                        <p className="geog-bold">
                            De même, il est formellement interdit au client de transférer son login et mot de passe à une compagnie de transport maritime. Ceci sera considéré comme une faute grave dans l'exécution de ses obligations contractuelles, et pourra être considéré comme une violation du droit de la Société en vertu des articles L341-1 à L342-5 du Code de la Propriété intellectuelle ainsi qu'un acte de concurrence déloyale en vertu de l'article 1382 du Code civil.
                    </p>
                        <div className="geog-bold">
                            Le Client qui ne respecterait pas ces interdictions s'exposerait à des sanctions et des poursuites en vertu de ces mêmes codes.
                    </div>
                        <div className="geog-bold">
                            Est notamment puni de trois ans d'emprisonnement et de 300 000 euros d'amende le fait de porter atteinte aux droits du producteur d'une base de données tels que définis à l'article L. 342-1.
                    </div>
                        <p>
                            L'utilisation de nos services par le biais de son compte sera considérée comme étant effectuée par lui. Si le Client pense que l'intégrité de son compte a été compromise, il doit en informer le plus rapidement possible la société à l'adresse emilie@okargo.com.
                    </p>
                        <p>
                            Le Client s'engage à ne transmettre à Okargo que des grilles de prix libres de tout droit et caractère confidentiel, le Client fait son affaire des autorisations nécessaires à la transmission de ces informations à Okargo. Le Client garantit donc Okargo contre tout recours fondé sur un acte de concurrence déloyale, de parasitisme, de dénigrement et d'une manière générale sur tout acte de violation de droits de propriété intellectuelle.
                    </p>
                        <p>
                            Si le Client utilise l'Application mobile, celui-ci est entièrement responsable de l'installation des mises à jour de l'application, ainsi que de la mise à jour de son adresse mail et ne pourra tenir Okargo pour responsable d'un préjudice résultant d'un manquement dans ces obligations.
                    </p>
                        <h3 className="geog-bold">PROPRIETE INTELLECTUELLE: </h3>
                        <p>
                            La structure générale et le contenu du service Okargo (données, structuration et contenu des bases de données, codes sources, textes, logos, logiciels, images, photographies, charte graphique, design, algorithmes, etc) sont la propriété exclusive de la Société. <span className="geog-bold">L'adhésion aux présentes Conditions Générales d'Utilisation, la consultation des Applications web et mobile, de même que l'attribution d'identifiants (nom d'Utilisateur et mot de passe), ne constituent en aucun cas une cession de droits ou une licence de réutilisation ou de reproduction.</span>
                        </p>
                        <p className="geog-bold">
                            Tout exploitation, extraction, réutilisation, reproduction, partielle ou totale, de l'Application web ou mobile, et/ou de la base de données, est en conséquence interdite sans l'autorisation écrite préalable de la Société et est passible de poursuites en vertu des articles L341-1 à L342-5 du Code de la Propriété intellectuelle. Est notamment puni de trois ans d'emprisonnement et de 300 000 euros d'amende le fait de porter atteinte aux droits du producteur d'une base de données tels que définis à l'article L. 342-1.
                    </p>
                        <p>
                            Les logos, marques, services, et Sociétés présents sur les Applications web et mobile, sous une dénomination différente de « Okargo » appartiennent aux compagnies de transport et ne sont mentionnés qu'aux fins d'identification. De même, l'utilisation des Applications web et mobile ne vous octroie pas de licence de réutilisation ou de reproduction sur ces informations.
                    </p>
                        <h3 className="geog-bold">LOI APPLICABLE ET REGLEMENT DES LITIGES: </h3>
                        <p>
                            En cas de nullité d'une stipulation des présentes Conditions Générales d'Utilisation en application d'une loi ou d'un règlement, ou si un tribunal la juge non exécutoire ou sans effet légal en tout ou en partie, les autres stipulations des Conditions Générales d'Utilisation conservent leur pleine force obligatoire.
                    </p>
                        <p>
                            En cas de difficulté résultant de l'utilisation du service Okargo, des Applications web ou mobile, ou de l'interprétation des présentes Conditions Générales d'Utilisation, les parties s'engagent à adresser à l'autre une lettre RAR de demande de réunion de conciliation. Dans un délai de 15 jours après réception de ladite lettre les parties devront se réunir. Les décisions, si arrêtées d'un commun accord, auront valeur contractuelle et seront constatées par écrit.
                    </p>
                        <p>
                            A défaut d’avoir trouvé une solution amiable dans un délai de trente (30) jours suivant la réception de la lettre de demande de réunion de conciliation, le différend sera soumis à la médiation conformément au règlement de médiation du Centre de Médiation et d’Arbitrage de Paris (CMAP - 39, avenue F. D. Roosevelt – 75008 PARIS), règlement auquel les Parties déclarent adhérer. Sauf accord contraire entre les Parties, la durée de la médiation sera fixée au maximum à deux mois suivant la lettre recommandée de l’une des Parties déclenchant l’application du présent paragraphe. A l’issue de la médiation CMAP, si les Parties n’ont pas trouvé de solution ou d’accord sur le différend soumis, les Parties pourront agir en justice dans les conditions suivantes:
                    </p>
                        <div>
                            Les présentes Conditions Générales d'Utilisation sont soumises à la loi française.
                    </div>
                        <p>
                            <span className="text-big">A DEFAUT D’ACCORD AMIABLE DANS LE DELAI PREVU PRECEDEMMENT COMPETENCE EXPRESSE EST ATTRIBUEE AU TRIBUNAL DE COMMERCE DE AIX-EN-PROVENCE (SAUF COMPETENCE EXCLUSIVE RECONNUE A UNE JURIDICTION DIFFERENTE), NONOBSTANT PLURALITE DE DEFENDEURS OU APPEL EN GARANTIE, MEME POUR LES PROCEDURES D'URGENCE OU LES PROCEDURES CONSERVATOIRES EN REFERE OU PAR REQUETE</span>, et ce, nonobstant toute clause contraire pouvant figurer sur les imprimés ou autres documents du Client.
                    </p>
                    </div>
                }
                { !isFr &&
                    <div>
                        <h2 className="geog-bold">TERMS OF SERVICE</h2>
                        <h3 className="geog-bold">WARNING: </h3>
                        <p className="geog-bold">
                            If you are using the free version of the website, please be aware that prices are not real and used only for the purpose of example.</p>
                        <h3 className="geog-bold">DEFINITIONS: </h3>
                        <div>
                            <span className="geo-bold">User or client:</span> legal entity or natural person using the website or/and the applications, freely or against payment.
                    </div>
                        <div>
                            <span className="geo-bold">Okargo: </span>: the web site www.okargo.com, free or paid version, and any other Okargo branded or controlled website or section of a website, or subsidiary company.
                    </div>
                        <div>
                            <span className="geo-bold">Application: </span>the branded iPhone application available on the App Store and the branded Android application available on Google Play.
                    </div>
                        <div>
                            <span className="geo-bold">Shipping Company or company:</span> the legal entity providing the transportation service through her own website.
                    </div>
                        <h3 className="geo-bold">SERVICE: </h3>
                        <p>
                            These terms and conditions govern your access and use of this website and any other Okargo branded and controlled website or section of a website including an Okargo Facebook page or Twitter account.
                    </p>
                        <p>
                            Okargo is a global price comparison website serving professional users in shipping. It provides online comparison for costs and time between many shipping companies. When you find a deal you want, we connect you to the relevant shipping company to make a booking directly with that third party. We do not take booking ourselves or hold any details of booking or payments.
                    </p>
                        <p>
                            Les liens hypertextes présents sur l'Application web et mobile intitulés « site de la compagnie »,  renvoient aux pages de consultation des horaires des compagnies de transport et vous font quitter le service Okargo.
                    </p>
                        <p>
                            The hypertext links on our website and application connect you directly on the shipping company's online booking page. You acknowledge that clicking on the hypertext link make you leave our website or applications.
                    </p>
                        <p>
                            If the client use the paying version of Okargo, he agrees to pass his prices grid to Okargo in order to be put in our database. Considering that prices are severally negotiated between our clients and the shipping companies, the search results are only based on the prices grid provided by the client. We warrant that prices grids will stay confidential and will not be communicated to third parties, notwithstanding subcontractors. Once the deal chosen by the user, we will connect him to the shipping company's booking page where he will be able to complete his booking.
                    </p>
                        <div className="geog-bold">
                            <span className="text-big">WARNING: </span>If you are using the free version of the website, please be aware that prices are not real and used only for the purpose of example.
                    </div>
                        <div className="geog-bold">
                            By accessing or using the website and the application, you are confirming that you have read and understood these terms and conditions and have agreed to be bound by them. Okargo may amend this terms and conditions at any time at its discretion and without warning. If you do not agree to comply with these terms and conditions or any amendments to them, please stop using the website and application immediately.
                    </div>
                        <h3 className="geo-bold">ACCESS: </h3>
                        <div>
                            Okargo will endeavor to ensure that the content of the website and application is up-to-date and accurate. However, considering that the shipping companies are not bound by the service provided by Okargo, you acknowledge that the search results on the company's booking page may be different than the search you made on Okargo. <span className="geog-bold">Therefore, all information provided by Okargo is only indicative and can not be subject to contractual liability.</span>
                        </div>
                        <div>
                            We advice the user to check the results on the booking page in order to be sure that it matches the results he found on Okargo.
                    </div>
                        <p>
                            In order to ensure the best service as possible, Okargo may modify the terms of use, restrict or suspend access to the website or the application at any time.
                    </p>
                        <h3 className="geo-bold">DISCLAIMER OF LIABILITY: </h3>
                        <p>
                            Okargo does not own or control offers made by the shipping companies. The service provided by Okargo is limited to the prices and time comparison. Okargo does not take bookings itself or hold any details of bookings or payment, it connects the client to the shipping company's booking page.
                    </p>
                        <p>
                            Okargo is not jointly liable with the shipping company. Therefore, Okargo is not responsible for any malfunction, errors, or modifications, occurring on the company's website, and not yet taken into account by the service.
                    </p>
                        <p>
                            In case of a data-capture error causing damage to the client, Okargo warrants to offer a free subscription during the month following the occurrence of damage / or full reimbursement of one-month subscription.
                    </p>
                        <p>
                            Okargo has no responsibility whatsoever for any loss, damage, virus, or any disruptive computer program affecting the user's computer or its contents, while accessing to the service.
                    </p>
                        <h3 className="geog-bold">RESPONSABILITE DE L'UTILISATEUR: </h3>
                        <div>
                            In order to benefit from the service, the client must register an account.
                    </div>
                        <p>
                            This account is protected by a login and a password which are strictly personal and confidential. It is strictly forbidden to communicate them to a third party. If such obligation is breached, the client will face a restriction of access to the service.
                    </p>
                        <p className="geog-bold">
                            Additionally, it is absolutely prohibited to pass or communicate your login or password to a shipping company. Such violation will be considered as a serious contractual breach, and could be prosecuted according to French law (articles L341-1 to L342-5 Code de la propriété intellectuelle and article 1382 Code civil).
                    </p>
                        <div className="geog-bold">
                            Such acts risk serious penalties, including 3 years in prison and 300 000 euros fine.
                    </div>
                        <p>
                            Every use of our service via the client's account will be considered as such. However, if the client thinks his account has been hacked, he must inform immediately the publishing director.
                    </p>
                        <p>
                            The user commits to passing prices grids free of copyrights and confidentiality agreements. He takes full responsibility for obtaining rights to transfer such information to Okargo. Concerning prices grids, the user warrants that Okargo will be released from any liability, such as unfair competition law, free riding, denigration, or any liability based on competition law or intellectual property rights.
                    </p>
                        <p>
                            If the client uses the application, he is responsible for installing any updates, and mail address. Okargo can not be held responsible for damage arising from the client's lack of compliance.
                    </p>
                        <h3 className="geog-bold">OKARGO'S PROPERTY: </h3>
                        <p>
                            The general structure and every contents of the website and the application including data, data base, data structure, source codes, texts, logos, software, programs, pictures, images, corporate identity and style guide, design, algorithms, etc, are Eureka's exclusive property.<span className="geog-bold">Accepting these terms and conditions, accessing and using the website and the application, or possessing a login and password, do not imply a transferable licence for reuse and reproduction.</span>
                        </p>
                        <p>
                            Every utilization, extraction, partial or total reproduction, of the website, the application, or the database, is strictly forbidden without our prior written consent.
                    </p>
                        <p className="geo-bold">
                            Such acts could be considered as a violation of database owner's rights and could be prosecuted according to french law (articles L341-1 to L342-5 Code de la Propriété intellectuelle and article 1382 Code civil). Such acts risk serious penalties, including 3 years in prison and 300 000 euros fine.
                    </p>
                        <p>
                            Logos, brands, services, and other companies visible on our website and applications and appearing under a different name, belong to shipping companies and are only mentioned for identification purposes.
                    </p>
                        <h3 className="geog-bold">MISCELLANEOUS: </h3>
                        <p>
                            Should all, or part of a clause, of the present terms of uses is declared null and void, the remaining provisions shall remain in full force and effect.
                    </p>
                        <p>
                            These terms of service are subject to French law.
                    </p>
                        <p>
                            In case of a difficulty arising from the use of website and the application, or arising from the interpretation of the present terms and conditions, both parties agree to reach an amicable agreement within 15 days after notification by registered letter with acknowledgement of receipt.
                    </p>
                        <div>
                            If not possible, the French commercial court of Marseille (Tribunal de commerce de Marseille) will be exclusively competent, notwithstanding multiple proceedings, defendants,  incidental claims, introduction of third parties, or provisional order.
                    </div>
                    </div>
                }
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <button
                        className="btn"
                        disabled={this.props.isLoading}
                        style={{ ...Colors.revertColors.main3 }}
                        onClick={(e) => this.props.requestAcceptGcu(new Date().getTime())}>
                        {this.props.langId == 2 &&
                            <span>Accepter</span>
                        }{this.props.langId != 2 &&
                            <span>Accept</span>
                        }
                    </button>
                </div>
            </div>
        </DialogModal>);
    }
}

export default connect((state: ApplicationState) => ({
    ...state.account.acceptGcuState,
    canOpen: !!(state.account.currentUser
        && state.account.currentUser.clientModel),
    gcuAccepted: state.account.currentUser
        && state.account.currentUser.clientModel
        && state.account.currentUser.clientModel.cgu,
    langId: state.account.languageId,
    languages: state.seed.languages
} as GcuDialogProps), AccountStore.actionCreators)(GcuDialog) as any;