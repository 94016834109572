import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import Moment from 'moment';
import { AppThunkAction } from './';
import { push } from "connected-react-router";
import * as CriteriaUtils from '../utils/criteriaUtils';
import { getDefaultHeaders, getSubscription } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { ReceiveCurrentUser, ReceiveEditClient } from "./Account";
import { formValueSelector } from "redux-form";
import * as _ from 'lodash';
import { ResetTodayDate } from './Seed';
import { canUseSuggested } from '../security/userRights';
import { SelectionResetOptions } from "./Selection";

export interface CriteriaState {
    code: string;
    criteriaHash: string;
    criteria: Api.CriteriaModel;
    advContainerOpened: boolean;
    advLocationOpened: boolean;
    advDateOpened: boolean;
    isLoading: boolean;
    requestTime: number;
    recentCriteriaState: {
        isLoading: boolean;
        requestTime?: number;
        criterias: Api.CriteriaModel[];
    }
}

export enum Source {
    Origin = "Origin",
    Destination = "Destination"
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestCreateCriteriaAction { type: 'REQUEST_CREATE_CRITERIA', requestTime: number }
//These actions are used in locations
export interface RequestCriteriaAction { type: 'REQUEST_CRITERIA', code: string, requestTime: number }
export interface ReceiveCriteriaAction { type: 'RECEIVE_CRITERIA', requestTime: number, criteria: Api.CriteriaModel }
export interface SetCriteria { type: 'SET_CRITERIA', criteria: Api.CriteriaModel }
export interface SetCriteriaRecent { type: 'SET_CRITERIA_RECENT'; payload: { criteria: Api.CriteriaModel; sizeTypes: Array<Api.SizeTypeModel> } }
export interface ResetCriteria { type: 'RESET_CRITERIA' }
interface SetDefaultCriteria { type: 'SET_DEFAULT_CRITERIA' }
interface UpdateCriteriaSizeTypeNumber { type: 'UPDATE_CRITERIA_SIZETYPE_NUMBER'; sizeTypeId: number; value: number }
interface UpdateContainerCriteria { type: 'UPDATE_CONTAINER_CRITERIA'; criteriaForSize: Api.CriteriaSizeTypeModel }

interface SelectLoadingCharges { type: 'SELECT_LOADING_CHARGES', payload: { value: boolean } }
interface SelectUnLoadingCharges { type: 'SELECT_UNLOADING_CHARGES', payload: { value: boolean } }

interface SelectPrepaidCharges { type: 'SELECT_PREPAID_CHARGES', payload: { value: boolean } }
interface SelectCollectCharges { type: 'SELECT_COLLECT_CHARGES', payload: { value: boolean } }

interface SelectDateBegin { type: 'SELECT_DATEBEGIN', date: Date }
interface SelectDateEnd { type: 'SELECT_DATEEND', date: Date }

interface ToggleAdvContainer { type: 'TOGGLE_ADV_CONTAINER', value: boolean }
interface SelectContainerType {
    type: 'SELECT_CONTAINER_TYPE',
    payload: { value: Api.CriteriaModelContainerTypeEnum; sizeTypes: Array<Api.SizeTypeModel>; criteriaSizeTypes: Array<Api.CriteriaSizeTypeModel> }
}
interface SelectPriceByContainer { type: 'SELECT_PRICE_BY_CONTAINER', value: boolean }
interface UpdateChargeCriteria { type: 'UPDATE_CHARGE_CRITERIA', value: Array<Api.ChargeModelCriteriaEnum> }

interface ToggleAdvLocation { type: 'TOGGLE_ADV_LOCATION', value: boolean }
interface SelectSurroundingOrigin { type: 'SELECT_SURROUNDING_ORIGIN', value: boolean }
interface SelectSurroundingDestination { type: 'SELECT_SURROUNDING_DESTINATION', value: boolean }

interface ToggleAdvDate { type: 'TOGGLE_ADV_DATE', value: boolean }
interface SelectLastRates { type: 'SELECT_LAST_RATES', value: boolean }
interface SelectSuggested { type: 'SELECT_SUGGESTED', value: boolean }
interface SelectAllMarket { type: 'SELECT_ALL_MARKET', value: boolean }
interface UpdateIncludeSellingRates { type: 'UPDATE_INCLUDE_SELLINGRATES', payload: { value: boolean } }
interface CriteriaUpdateShowFreeTime { type: 'CRITERIA_UPDATE_SHOWFREETIME', payload: { value: boolean } }

interface CriteriaUpdateTon { type: 'CRITERIA_UPDATE_TON', payload: { value: number } }
interface CriteriaUpdateCbm { type: 'CRITERIA_UPDATE_CBM', payload: { value: number } }
interface CriteriaSelectCarrierRates { type: "CRITERIA_SELECT_CARRIERRATES", payload: { value: boolean } }

interface RequestRecentCriteria { type: 'REQUEST_RECENT_CRITERIA', payload: { requestTime: number } }
interface ReceiveRecentCriteria { type: 'RECEIVE_RECENT_CRITERIA', payload: { requestTime: number; value?: Api.CriteriaModel[] }; error?: any }
interface CriteriaSelectLocation { type: 'CRITERIA_SELECTION_LOCATION', source: Source, value: Api.LocationModel }
interface UpdateOriginShippingType { type: "UPDATE_ORIGINSHIPPINGTYPE", value: Api.CriteriaModelLocationShippingTypeEnum }
interface UpdateDestinationShippingType { type: "UPDATE_DESTINATIONSHIPPINGTYPE", value: Api.CriteriaModelLocationShippingTypeEnum }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestCreateCriteriaAction
    | RequestCriteriaAction | ReceiveCriteriaAction
    | UpdateCriteriaSizeTypeNumber
    | UpdateContainerCriteria
    | SelectLoadingCharges
    | SelectUnLoadingCharges
    | SelectDateBegin | SelectDateEnd
    | ToggleAdvContainer | SelectContainerType
    | SelectPriceByContainer
    | UpdateChargeCriteria
    | ToggleAdvLocation | SelectSurroundingOrigin
    | SelectSurroundingDestination
    | ToggleAdvDate | SelectLastRates
    | SelectSuggested | SelectAllMarket
    | SetDefaultCriteria | SetCriteria
    | ResetCriteria
    | ReceiveCurrentUser | ReceiveEditClient
    | CriteriaUpdateShowFreeTime
    | ResetTodayDate
    | UpdateIncludeSellingRates
    | SetCriteriaRecent
    | CriteriaUpdateTon
    | CriteriaUpdateCbm
    | CriteriaSelectCarrierRates
    | SelectionResetOptions
    | SelectPrepaidCharges
    | SelectCollectCharges
    | RequestRecentCriteria
    | ReceiveRecentCriteria
    | CriteriaSelectLocation
    | UpdateOriginShippingType
    | UpdateDestinationShippingType
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const formSelector = formValueSelector('criteria')
export const actionCreators = {
    requestCriteria: (code: string, requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (requestTime === getState().criteria.requestTime)
            return;

        let api = new Api.CriteriaApi();
        let fetchTask = api.getCriteria({ code: code }, { headers: getDefaultHeaders(getState()) })
            .then(data => {
                dispatch({ type: 'RECEIVE_CRITERIA', requestTime: requestTime, criteria: data });
            }).catch(error => {
                console.log('Error receiving criteria: ' + error.message);
                dispatch({ type: 'RECEIVE_CRITERIA', requestTime: requestTime, criteria: unloadedState.criteria });
                dispatch(Notifications.error({ message: "Error loading criteria", title: "Error", position: "tc" }) as any);
            });
        dispatch({ type: 'REQUEST_CRITERIA', code: code, requestTime: requestTime });
    },
    requestRecentCriteria: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let api = new Api.CriteriaApi();
        let fetchTask = api.recentCriteria({ }, { headers: getDefaultHeaders(getState()) })
            .then(data => {
                dispatch({ type: 'RECEIVE_RECENT_CRITERIA', payload: {  requestTime: requestTime, value: data } });
            }).catch(error => {
                console.log('Error receiving recent criteria: ' + error?.message);
                dispatch({ type: 'RECEIVE_RECENT_CRITERIA', payload: {  requestTime: requestTime }, error: error });
            });
        dispatch({ type: 'REQUEST_RECENT_CRITERIA', payload: { requestTime: requestTime } });
        return fetchTask;
    },
    setCriteria: (criteria: Api.CriteriaModel) => <SetCriteria>{ type: "SET_CRITERIA", criteria: criteria },
    selectLocation: (source: Source, value: Api.LocationModel) => <CriteriaSelectLocation>{ type: 'CRITERIA_SELECTION_LOCATION', source: source, value: value },
    updateOriginShippingType: (value: Api.CriteriaModelLocationShippingTypeEnum) => <UpdateOriginShippingType>{ type: "UPDATE_ORIGINSHIPPINGTYPE", value: value },
    updateDestinationShippingType: (value: Api.CriteriaModelLocationShippingTypeEnum) => <UpdateDestinationShippingType>{ type: "UPDATE_DESTINATIONSHIPPINGTYPE", value: value },
    requestCreateCriteria: (requestTime: number): AppThunkAction<KnownAction, Promise<Api.CriteriaModel>> => (dispatch, getState) => {
        if (requestTime === getState().criteria.requestTime)
            return Promise.resolve<Api.CriteriaModel>(getState().criteria.criteria);

        let api = new Api.CriteriaApi();
        let fetchTask = api.createCriteria({ model: getState().criteria.criteria },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) });

        fetchTask.then(data => {

        }).catch(error => {
            console.log('Error creating criteria: ' + error.message);
            dispatch(Notifications.error({ message: "Error loading criteria", title: "Error", position: "tc" }) as any);
        });

        dispatch({ type: 'REQUEST_CREATE_CRITERIA', requestTime: requestTime });
        return fetchTask;
    },
    updateSizeTypeNumber: (sizeTypeId: number, value: number) => <UpdateCriteriaSizeTypeNumber>{ type: 'UPDATE_CRITERIA_SIZETYPE_NUMBER', sizeTypeId: sizeTypeId, value: value },
    updateContainerCriteria: (criteriaForSize: Api.CriteriaSizeTypeModel) => <UpdateContainerCriteria>{ type: 'UPDATE_CONTAINER_CRITERIA', criteriaForSize: criteriaForSize },
    selectLoadingcharge: (value: boolean) => <SelectLoadingCharges>{ type: 'SELECT_LOADING_CHARGES', payload: { value: value } },
    selectUnLoadingcharge: (value: boolean) => <SelectUnLoadingCharges>{ type: 'SELECT_UNLOADING_CHARGES', payload: { value: value } },
    selectPrepaidcharge: (value: boolean) => <SelectPrepaidCharges>{
        type: 'SELECT_PREPAID_CHARGES', payload: { value: value }
    },
    selectCollectcharge: (value: boolean) => <SelectCollectCharges>{ type: 'SELECT_COLLECT_CHARGES', payload: { value: value } },
    selectDateBegin: (date: Date) => <SelectDateBegin>{ type: 'SELECT_DATEBEGIN', date: date },
    selectDateEnd: (date: Date) => <SelectDateEnd>{ type: 'SELECT_DATEEND', date: date },
    toggleAdvContainer: (value: boolean) => <ToggleAdvContainer>{ type: 'TOGGLE_ADV_CONTAINER', value: value },
    selectPriceByContainer: (value: boolean) => <SelectPriceByContainer>{ type: 'SELECT_PRICE_BY_CONTAINER', value: value },
    selectContainerType: (value: Api.CriteriaModelContainerTypeEnum): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: 'SELECT_CONTAINER_TYPE',
            payload: {
                value: value,
                //We send the new sizetypes the form has to display here
                //Because we dont have it in the criteria part of the store tree
                //Using the middleware to gather wanted part of the tree is ok, keep it simple though
                sizeTypes: _.values(getState().seed.sizeTypes)
                    .filter(st => st.type === value && (st.position <= 4)),
                criteriaSizeTypes: formSelector(getState(), "criteriaSizeTypes")
                    || getState().criteria.criteria.criteriaSizeTypes
            }
        });
    },
    updateChargeCriteria: (value: Array<Api.ChargeModelCriteriaEnum>) => <UpdateChargeCriteria>{ type: 'UPDATE_CHARGE_CRITERIA', value: value },
    toggleAdvLocation: (value: boolean) => <ToggleAdvLocation>{ type: 'TOGGLE_ADV_LOCATION', value: value },
    selectSurroundingOrigin: (value: boolean) => <SelectSurroundingOrigin>({ type: 'SELECT_SURROUNDING_ORIGIN', value: value }),
    selectSurroundingDestination: (value: boolean) => <SelectSurroundingDestination>({ type: 'SELECT_SURROUNDING_DESTINATION', value: value }),
    toggleAdvDate: (value: boolean) => <ToggleAdvDate>{ type: 'TOGGLE_ADV_DATE', value: value },
    selectLastRates: (value: boolean) => <SelectLastRates>{ type: 'SELECT_LAST_RATES', value: value },
    selectAllMarket: (value: boolean) => <SelectAllMarket>{ type: 'SELECT_ALL_MARKET', value: value },
    selectSuggested: (value: boolean) => <SelectSuggested>{ type: 'SELECT_SUGGESTED', value: value },
    selectCarrierRates: (value: boolean) => <CriteriaSelectCarrierRates>{
        type: 'CRITERIA_SELECT_CARRIERRATES',
        payload: { value: value }
    },
    updateShowFreeTime: (value: boolean) => <CriteriaUpdateShowFreeTime>{
        type: 'CRITERIA_UPDATE_SHOWFREETIME', payload: { value: value }
    },
    setDefaultCriteria: (): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: 'SET_DEFAULT_CRITERIA' });
    },
    resetCriteria: () => <ResetCriteria>{ type: "RESET_CRITERIA" },
    setCriteriaRecent: (criteria: Api.CriteriaModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: "SET_CRITERIA_RECENT",
            payload: {
                criteria: criteria,
                sizeTypes: _.values(getState().seed.sizeTypes)
                    .filter(st => st.type === criteria.containerType && (st.position <= 4))
            }
        });
    },
    criteriaGo: (): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let criteria = getState().criteria.criteria;
        if (criteria.type === "Fcl")
            dispatch(push('/selection/' + criteria.code) as any);
        else
            dispatch(push('/lcl/selection/' + criteria.code) as any);
    },
    updateIncludeSellingRates: (value: boolean) => <UpdateIncludeSellingRates>{
        type: "UPDATE_INCLUDE_SELLINGRATES",
        payload: { value: value }
    },
    updateTon: (value: number) => <CriteriaUpdateTon>{
        type: "CRITERIA_UPDATE_TON",
        payload: { value: value }
    },
    updateCbm: (value: number) => <CriteriaUpdateCbm>{
        type: "CRITERIA_UPDATE_CBM",
        payload: { value: value }
    }

};
export const getDefaultCriteria = (currentUser: Api.CurrentUserModel): Api.CriteriaModel => {
    let rcuSubscription = getSubscription(currentUser.clientModel);
    let isAgent = rcuSubscription
        && rcuSubscription.subscriptionType === "Agent"

    return {
        loadingCharge: isAgent ? false : currentUser.clientModel.defaultLoading,
        unLoadingCharge: isAgent ? false : currentUser.clientModel.defaultUnLoading,
        suggested: canUseSuggested(rcuSubscription)
            && currentUser.clientModel.defaultSuggested,
        lastRates: currentUser.clientModel.defaultLastRates,
        containerPrice: currentUser.clientModel.defaultContainerPrice,
        showFreeTime: currentUser.clientModel.defaultFreetime,
        criteriaSubscriptions: rcuSubscription
            ? [{ subscriptionId: rcuSubscription.subscriptionId }]
            : [],
        allMarkets: currentUser.clientModel.defaultAllMarkets,
        includeCarrierRates: currentUser.clientModel.defaultCarrierRates,
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CriteriaState = {
    code: null,
    criteriaHash: '',
    criteria: {
        containerType: "Dry",
        originShippingType: "Cy",
        destinationShippingType: "Cy",
        origin: null,
        destination: null,
        criteriaSizeTypes: [
            { sizeTypeId: 1, number: 0, weight:15000, weightUnit:"Kg"},
            { sizeTypeId: 2, number: 0, weight:15000, weightUnit:"Kg" },
            { sizeTypeId: 3, number: 0, weight:15000, weightUnit:"Kg" },
            { sizeTypeId: 7, number: 0, weight:15000, weightUnit:"Kg" }
        ],
        dateBegin: Moment().utc().add(0, 'days').startOf('day').toDate(),
        dateEnd: Moment().utc().add(1, 'months').startOf('day').toDate(),
        loadingCharge: true,
        unLoadingCharge: true,
        prepaidCharge: true,
        collectCharge: true,
        chargeCriterias: []
    },
    advContainerOpened: false,
    advDateOpened: false,
    advLocationOpened: false,
    isLoading: false,
    requestTime: 0,
    recentCriteriaState: {
        isLoading: false,
        criterias: []
    }
};

export const reducer: Reducer<CriteriaState> = (state: CriteriaState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'REQUEST_CRITERIA':
            return {
                ...state,
                code: action.code,
                isLoading: true,
                requestTime: action.requestTime
            };
        case 'RECEIVE_CRITERIA':
            //Anti out of order
            if (action.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                code: action.criteria.code,
                criteriaHash: CriteriaUtils.generateHash(action.criteria),
                criteria: {
                    ...action.criteria,
                    dateBegin: Moment.utc(action.criteria.dateBegin).local().toDate(),
                    dateEnd: Moment.utc(action.criteria.dateEnd).local().toDate(),
                },
                advContainerOpened: action.criteria.containerType !== "Dry" ||
                    action.criteria.containerPrice ||
                    (action.criteria.chargeCriterias && action.criteria.chargeCriterias.length !== 0),
                advLocationOpened: false,
                advDateOpened: action.criteria.lastRates || action.criteria.allMarkets || action.criteria.suggested,
                isLoading: false
            };
        case 'REQUEST_CREATE_CRITERIA':
            return {
                ...state,
                code: null,
                isLoading: true,
                requestTime: action.requestTime
            };
        case 'UPDATE_CRITERIA_SIZETYPE_NUMBER':
            var state2 = {
                ...state,
                criteria: {
                    ...state.criteria,
                    criteriaSizeTypes: state.criteria.criteriaSizeTypes.map(st => {
                        return {
                            ...st,
                            number: st.sizeTypeId == action.sizeTypeId
                                ? action.value
                                : st.number
                        };
                    })
                }
            };
            return state2;
        case 'UPDATE_CONTAINER_CRITERIA':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    criteriaSizeTypes: state.criteria.criteriaSizeTypes
                        .map(cst => {
                            return cst.sizeTypeId === action.criteriaForSize.sizeTypeId 
                                ? {
                                    ...cst,
                                    weight: action.criteriaForSize.weight,
                                    weightUnit: action.criteriaForSize.weightUnit
                                }
                                : cst
                    })
                }
            };
        case 'SELECT_LOADING_CHARGES':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    loadingCharge: action.payload.value
                }
            }
        case 'SELECT_UNLOADING_CHARGES':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    unLoadingCharge: action.payload.value
                }
            }
        case 'SELECT_PREPAID_CHARGES':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    prepaidCharge: action.payload.value
                }
            }
        case 'SELECT_COLLECT_CHARGES':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    collectCharge: action.payload.value
                }
            }
        case 'SELECT_DATEBEGIN':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    dateBegin: action.date
                }
            };
        case 'SELECT_DATEEND':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    dateEnd: action.date
                }
            };
        case 'TOGGLE_ADV_CONTAINER':
            return {
                ...state,
                advContainerOpened: action.value
            };
        case 'SELECT_CONTAINER_TYPE':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    containerType: action.payload.value,
                    criteriaSizeTypes: action.payload.sizeTypes.map((st, i) => {
                        return {
                            sizeTypeId: st.sizeTypeId,
                            number: action.payload.criteriaSizeTypes[i]
                                ? action.payload.criteriaSizeTypes[i].number
                                : 0,
                            weight: 15000,
                            weightUnit: "Kg",
                            criteriaSizeTypeId: 0
                        }
                    })
                }
            };
        case 'UPDATE_CHARGE_CRITERIA':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    chargeCriterias: action.value
                }
            };
        case 'SELECT_PRICE_BY_CONTAINER':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    containerPrice: action.value,
                    criteriaSizeTypes: state.criteria.criteriaSizeTypes.map((st, i) => {
                        return {
                            sizeTypeId: st.sizeTypeId,
                            number: Math.min(1, state.criteria.criteriaSizeTypes[i]
                                ? state.criteria.criteriaSizeTypes[i].number
                                : 0),
                            weight: state.criteria.criteriaSizeTypes[i]
                                ? state.criteria.criteriaSizeTypes[i].weight
                                : 15000,
                            weightUnit:  state.criteria.criteriaSizeTypes[i]
                                ? state.criteria.criteriaSizeTypes[i].weightUnit
                                : "Kg",
                            criteriaSizeTypeId: 0
                        }
                    })
                }
            };
        case 'TOGGLE_ADV_LOCATION':
            return {
                ...state,
                advLocationOpened: action.value
            };
        case 'SELECT_SURROUNDING_ORIGIN':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    originNearby: action.value
                }
            };
        case 'SELECT_SURROUNDING_DESTINATION':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    destinationNearby: action.value
                }
            };
        case 'TOGGLE_ADV_DATE':
            return {
                ...state,
                advDateOpened: action.value
            };
        case 'SELECT_LAST_RATES':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    lastRates: action.value
                }
            }
        case 'SELECT_SUGGESTED':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    suggested: action.value
                }
            };
        case "CRITERIA_SELECT_CARRIERRATES":
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    includeCarrierRates: action.payload.value
                }
            };
        case 'SELECT_ALL_MARKET':
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    allMarkets: action.value
                }
            };
        case "UPDATE_INCLUDE_SELLINGRATES":
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    includeSellingRates: action.payload.value
                }
            };
        case "SET_DEFAULT_CRITERIA":
            return {
                ...state,
                criteria: unloadedState.criteria
            };
        case "SET_CRITERIA":
            return {
                ...state,
                criteria: action.criteria,
                code: action.criteria.code
            };
        case "RESET_CRITERIA":
            return unloadedState;
        case "RECEIVE_CURRENT_USER":
            if (action.error)
                return state;

            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    ...(getDefaultCriteria(action.payload.currentUser))
                },
            };
        case "RECEIVE_EDIT_CLIENT":
            let ecSubscription = getSubscription(action.model);
            let ecIsAgent = ecSubscription
                && ecSubscription.subscriptionType === "Agent";
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    criteriaSubscriptions: [{
                        subscriptionId: action.model.subscriptionId
                    }],
                    loadingCharge: ecIsAgent
                        ? false
                        : state.criteria.loadingCharge,
                    unLoadingCharge: ecIsAgent
                        ? false
                        : state.criteria.unLoadingCharge
                }
            };
        case "RESET_TODAY_DATE":
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    dateBegin: Moment(action.payload.today).utc().startOf("day").toDate(),
                    dateEnd: Moment(action.payload.today).utc().add(1, "months").startOf("day").toDate()
                }
            };
        case "SET_CRITERIA_RECENT":
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    containerType: action.payload.criteria.containerType,
                    criteriaSizeTypes: action.payload.criteria.criteriaSizeTypes
                        .concat(action.payload.sizeTypes
                            .filter(x => !action.payload.criteria.criteriaSizeTypes.some(y => y.sizeTypeId === x.sizeTypeId))
                            .map(x => ({ sizeTypeId: x.sizeTypeId, number: 0 }))),
                    origin: action.payload.criteria.origin,
                    destination: action.payload.criteria.destination,
                    loadingCharge: action.payload.criteria.loadingCharge,
                    unLoadingCharge: action.payload.criteria.unLoadingCharge,
                }
            };
        case "CRITERIA_UPDATE_SHOWFREETIME":
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    showFreeTime: action.payload.value
                }
            };
        case "CRITERIA_UPDATE_CBM":
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    cbm: action.payload.value
                }
            };
        case "CRITERIA_UPDATE_TON":
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    ton: action.payload.value
                }
            };
        case "SELECTION_RESET_OPTIONS":
            return {
                ...state,
                criteria: action.payload.criteria
            }
        case "REQUEST_RECENT_CRITERIA":
            return {
                ...state,
                recentCriteriaState: {
                    ...state.recentCriteriaState,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_RECENT_CRITERIA":
            if(action.payload.requestTime !== state.recentCriteriaState.requestTime)
                return state;
            
            return {
                ...state,
                recentCriteriaState: {
                    ...state.recentCriteriaState,
                    isLoading: false,
                    criterias: action.error ? state.recentCriteriaState.criterias : action.payload.value
                }
            };
        case 'CRITERIA_SELECTION_LOCATION':
            if (action.source === Source.Origin) {
                return {
                    ...state,
                    criteria: {
                        ...state.criteria,
                        origin: action.value
                    }
                };
            } else {
                return {
                    ...state,
                    criteria: {
                        ...state.criteria,
                        destination: action.value
                    }
                };
            };
        case "UPDATE_ORIGINSHIPPINGTYPE":
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    originShippingType: action.value
                }
            };
        case "UPDATE_DESTINATIONSHIPPINGTYPE":
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    destinationShippingType: action.value
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
