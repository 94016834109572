import * as SignalR from "@aspnet/signalr";
import * as OkargoHubStore from "../store/OkargoHub";
import { ApplicationState } from "../store";
import { push } from "connected-react-router";
import {
    SelectionCarrierRatesLoadingStarted
    , SelectionCarrierRatesLoadingEnded
    , SelectionSchedulesLoadingStarted
    , SelectionSchedulesLoadingEnded
} from "../store/Selection";
import {MfaRequested} from "../store/RatesFetcher";

let connection: SignalR.HubConnection = null;

type KnownAction = OkargoHubStore.KnownAction
    | SelectionCarrierRatesLoadingStarted
    | SelectionCarrierRatesLoadingEnded
    | SelectionSchedulesLoadingStarted
    | SelectionSchedulesLoadingEnded
    | MfaRequested
    ;

export const connectUserHub = (dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<void> => {
    connection = new SignalR.HubConnectionBuilder()
        .withUrl("/okargoHub", SignalR.HttpTransportType.WebSockets)
        .build();

    connection.onclose((err) => {
        dispatch({ type: "USERHUB_CONNECTION_CLOSED" });
    });

    // connection.on("SchedulesLoadingStarted", () => {
    //     dispatch({ type: "SELECTION_SCHEDULES_LOADING_STARTED" });
    // });
    // connection.on("SchedulesLoadingEnded", () => {
    //     dispatch({ type: "SELECTION_SCHEDULES_LOADING_ENDED" });
    // });
    // connection.on("CarrierRatesLoadingStarted", () => {
    //     dispatch({ type: "SELECTION_CARRIERRATES_LOADING_STARTED" });
    // });
    // connection.on("CarrierRatesLoadingEnded", () => {
    //     dispatch({ type: "SELECTION_CARRIERRATES_LOADING_ENDED" });
    // });

    connection.on("MfaRequested", (platformCode: string) => {
        dispatch({ type: "MFA_REQUESTED", payload: { requestTime: new Date().getTime(), platformCode: platformCode } });
    });

    return connection.start().then(() => {
        connection.send("RegisterUser", getState().account.currentUser?.clientModel?.account?.email);
        dispatch({ type: "USERHUB_CONNECTION_OPENED" });
    });
};