import * as React from 'react';

export const rowStyle: React.CSSProperties = {
    minHeight: 34,
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
};
export const inputNumberStyle: React.CSSProperties = { width: 50 };
export const borderStyle: string = "1px solid black"
