import * as React from "react";
import * as Api from "../api/api";
import * as FormField from './FormField';
import * as _ from "lodash";
import { connect } from "react-redux";
import { getText } from "../utils/langTexts";
import { ApplicationState } from "../store";
import * as ReduxForm from 'redux-form';
import CriteriaLocationSelect from "./CriteriaLocationSelect";
import { getFirmName } from "../utils/utils";
import { getFirms } from "./ModeratorAgentPortal";
import { Close } from "@material-ui/icons";
import * as Colors from '../styles/colors';
import Button from './Button';

type SellingRatesFormProps = ReduxForm.InjectedFormProps<{}, {}, string>
    & SellingRatesFormExternalProps
    & SellingRatesFormOwnProps;

interface SellingRatesFormExternalProps {
    sellingRatesId?: number;
    actionName: string;
    hideReset?: boolean;
    onSubmit: (values: Api.SellingRatesModel) => Promise<any>;
}

interface SellingRatesFormOwnProps {
    portsZones: { [id: number]: Api.PortsZoneModel };
    currencies: { [id: number]: Api.CurrencyModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    firms: Array<Api.FirmModel>;
}

const originPortField = (fields) => <div>
    <CriteriaLocationSelect
        inputKey={"SellingRatesOriginPort"}
        value={fields.input.value}
        locationSearchType={"OnlyPorts"}
        inputStyle={{ height: 47 }}
        buttonStyle={{ height: 47 }}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>

const destinationPortField = (fields) => <div>
    <CriteriaLocationSelect
        inputKey={"SellingRatesDestinationPort"}
        value={fields.input.value}
        inputStyle={{ height: 47 }}
        buttonStyle={{ height: 47 }}
        locationSearchType={"OnlyPorts"}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>

const originRequired = (value: any, values: Api.SellingRatesModel) => !values.originPortsZoneId
    && !values.originPort
    ? "Origin is required" : undefined;
const destinationRequired = (value: any, values: Api.SellingRatesModel) => !values.destinationPortsZoneId
    && !values.destinationPort
    ? "Destination is required" : undefined;

class SellingRatesForm extends React.Component<SellingRatesFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} resetOnSubmit={this.props.hideReset}>
                <div style={{ fontSize: 18, fontWeight: "bold", margin: "10px 0px", paddingTop: "4px" }}>Origin</div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <label className="col-md-4 control-label">Port: </label>
                            <div className="col-md-8">
                                <ReduxForm.Field name={"originPort"}
                                    validate={[originRequired]}
                                    component={originPortField} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <label className="col-md-4 control-label" style={{ paddingTop:"4px" }}>Zone: </label>
                            <div className="col-md-8">
                                <ReduxForm.Field name={"originPortsZoneId"}
                                    validate={[originRequired]}
                                    style={{ width: "100%" }}
                                    search={true}
                                    component={FormField.getSelectField(
                                        _.sortBy(_.values(this.props.portsZones), x => x.name)
                                            .map(x => ({ label: x.name, value: x.portsZoneId })))} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 18, fontWeight: "bold", margin: "10px 0px"}}>Destination</div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <label className="col-md-4 control-label">Port: </label>
                            <div className="col-md-8">
                                <ReduxForm.Field name={"destinationPort"}
                                    validate={[destinationRequired]}
                                    component={destinationPortField} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <label className="col-md-4 control-label" style={{ paddingTop: "4px" }}>Zone: </label>
                            <div className="col-md-8">
                                <ReduxForm.Field name={"destinationPortsZoneId"}
                                    validate={[destinationRequired]}
                                    style={{ width: "100%" }}
                                    search={true}
                                    component={FormField.getSelectField(
                                        _.sortBy(_.values(this.props.portsZones), x => x.name)
                                            .map(x => ({ label: x.name, value: x.portsZoneId })))} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ margin: "10px 0px"}}>
                    <label className="col-md-4 control-label" style={{ paddingTop: "8px" }}>Company/branch: </label>
                    <div className="col-md-8">
                        <ReduxForm.Field className="form-control"
                            name="firmId"
                            validate={[FormField.required]}
                            style={{ width: "100%", padding: "4px 4px 4px 8px"  }}
                            component={FormField.getSelectField(this.props.firms
                                .map(x => ({
                                    label: getFirmName(x),
                                    value: x.firmId
                                })))} />
                    </div>
                </div>
                <div className="row" style={{ margin: "10px 0px" }}>
                    <label className="col-md-4 control-label">Marges: </label>
                    <div className="col-md-8">
                        <ReduxForm.FieldArray
                            name="sellingRatesValues"
                            component={SellingRatesValues}
                            validate={FormField.requiredOne}
                            props={{
                                currencies: this.props.currencies,
                                sizeTypes: this.props.sizeTypes
                            } as SellingRatesValuesOwnProps} />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse", margin: "10px 0px" }}>
                    {(this.props.submitting || this.props.pristine) ? (
                        <Button type="submit"
                            style={{ color: "white", backgroundColor: Colors.baseColors.darkGray, marginRight: "1em" }}
                            disabled={this.props.submitting || this.props.pristine}>
                            {this.props.actionName}
                        </Button>) :

                        (<Button type="submit"
                            style={{ color: "white", backgroundColor: Colors.baseColors.lightBlue, marginRight: "1em" }}>
                            {this.props.actionName}
                        </Button>)
                    }
                    {!this.props.hideReset
                        && <Button
                            style={{ marginRight: 10 }}
                            disabled={this.props.pristine || this.props.submitting}
                            type={"button"}
                            onClick={(e) => this.props.reset()}>
                            {getText("GenReset")}
                        </Button>
                    }
                </div>
            </FormField.BaseForm>
        );
    }
}

type SellingRatesValuesProps = SellingRatesValuesOwnProps
    & ReduxForm.WrappedFieldArrayProps;

export interface SellingRatesValuesOwnProps {
    currencies: { [id: number]: Api.CurrencyModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
}

export class SellingRatesValues extends React.Component<SellingRatesValuesProps, {}> {
    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => <div key={index}
                    style={{ marginBottom: 5, padding: 5, border: "1px solid black", borderRadius: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: 5 }}>
                        
                    </div>
                    <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <label className="control-label" style={{ flex: '0 0 auto' }}>Margin: </label>
                        <div style={{ flex: 1, marginRight: 5 }}>
                            <ReduxForm.Field className="form-control"
                                validate={[FormField.required]}
                                name={`${member}.sellingMargin`}
                                component={FormField.NumberField} />
                        </div>
                        <label className="control-label" style={{ flex: '0 0 auto' }}>Currency: </label>
                        <div style={{ flex: 1, marginRight: 5 }}>
                            <ReduxForm.Field className="form-control"
                                validate={[FormField.required]}
                                name={`${member}.currencyId`}
                                component={FormField.getSelectField(_.values(this.props.currencies)
                                    .map(x => ({ label: x.code, value: x.currencyId })))} />
                        </div>
                        <label className="control-label" style={{ flex: '0 0 auto' }}>Conteneur: </label>
                        <div style={{ flex: 1, marginRight: 5 }}>
                            <ReduxForm.Field className="form-control"
                                validate={[]}
                                name={`${member}.sizeTypeId`}
                                component={FormField.getSelectField([
                                    { label: '------', value: null }
                                ].concat(_.values(this.props.sizeTypes)
                                    .map(x => ({ label: x.name, value: x.sizeTypeId }))))} />
                        </div>
                        <button
                            type="button"
                            style={{ flex: '0 0 auto' }}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <Close style={{ height: 18 }} />
                        </button>
                    </div>
                </div>)}
                <FormField.FieldErrors {...this.props.meta} />
                <div style={{ textAlign: "center" }}>
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={() => this.props.fields.push({
                        })}>
                        <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                    </button>
                </div>
            </div>
        );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createSellingRates',
    destroyOnUnmount: false,
    enableReinitialize: true,
})(SellingRatesForm);
const createSelector = ReduxForm.formValueSelector('createSellingRates')

let updateForm = ReduxForm.reduxForm({
    form: 'updateSellingRates',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SellingRatesForm);
const updateSelector = ReduxForm.formValueSelector('updateSellingRates')

export const CreateSellingRatesForm = connect((state: ApplicationState) => {
    return {
        initialValues: {

        } as Api.SellingRatesModel,
        firms: getFirms(state.account.currentUser.clientModel.subscriptions),
        portsZones: state.seed.portsZones,
        currencies: state.seed.currencies,
        sizeTypes: state.seed.sizeTypes,
    } as SellingRatesFormOwnProps;
})(createForm) as any as React.ClassicComponentClass<SellingRatesFormExternalProps>

export const UpdateSellingRatesForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.sellingRates.sellingRates
            .find(x => x.sellingRatesId === state.sellingRates.selectedSellingRatesId),
        firms: getFirms(state.account.currentUser.clientModel.subscriptions),
        portsZones: state.seed.portsZones,
        currencies: state.seed.currencies,
        sizeTypes: state.seed.sizeTypes,
    } as SellingRatesFormOwnProps;
})(updateForm) as any as React.ClassicComponentClass<SellingRatesFormExternalProps>