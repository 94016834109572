import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction, ApplicationState } from './';

export interface SeedState {
    isLoading: boolean;
    requestTime?: number;
    isCarriersLoading: boolean;
    carriersRequestTime?: number;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
    languages: { [id: number]: Api.LanguageModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    carriers: { [id: number]: Api.CarrierModel };
    carrierGroups: { [id: number]: Api.CarrierGroupModel };
    portsZones: { [id: number]: Api.PortsZoneModel };
    markets: { [id: number]: Api.MarketModel };
    inlandCarriers: { [id: number]: Api.InlandCarrierModel };
    carrierRatesPlatforms: { [id: number]: Api.CarrierRatesPlatformModel };
    countries: { [id: number]: Api.LocationModel };
    servicesOptions: Api.ServicesOptionsModel;
    optionsIsLoading: boolean;
    optionsRequestTime: number;
}

export interface ResetTodayDate { type: 'RESET_TODAY_DATE', payload: { today: Date } }

interface RequestSeed {
    type: 'REQUEST_SEED';
    payload: {
        requestTime: number;
    }
}
interface ReceiveSeed {
    type: 'RECEIVE_SEED';
    payload: {
        requestTime: number;
        sizeTypes: { [id: number]: Api.SizeTypeModel };
        currencies: { [id: number]: Api.CurrencyModel };
        languages: { [id: number]: Api.LanguageModel };
        chargeNames: { [id: number]: Api.ChargeNameModel };
        carriers: { [id: number]: Api.CarrierModel };
        carrierGroups: { [id: number]: Api.CarrierGroupModel };
        portsZones: { [id: number]: Api.PortsZoneModel };
        markets: { [id: number]: Api.MarketModel };
        inlandCarriers: { [id: number]: Api.InlandCarrierModel };
        carrierRatesPlatforms: { [id: number]: Api.CarrierRatesPlatformModel };
        countries: { [id: number]: Api.LocationModel };
    }
}

interface RequestSeedCarriers {
    type: 'REQUEST_SEED_CARRIERS'
    payload: { requestTime: number;  }
}
interface ReceiveSeedCarriers {
    type: 'RECEIVE_SEED_CARRIERS'
    payload: {
        requestTime: number;
        carriers?: { [id: number]: Api.CarrierModel };
    };
    error?: any;
}

interface RequestServicesOptions {
    type: 'REQUEST_SERVICESOPTIONS';
    payload: {
        requestTime: number;
    }
}
interface ReceiveServicesOptions {
    type: 'RECEIVE_SERVICESOPTIONS';
    payload: {
        requestTime: number;
        servicesOptions?: Api.ServicesOptionsModel;
    };
    error?: any;
}

export type KnownAction = RequestSeed
    | ReceiveSeed
    | RequestServicesOptions
    | ReceiveServicesOptions
    | RequestSeedCarriers
    | ReceiveSeedCarriers;

export const requestSeed = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    if (requestTime === getState().seed.requestTime)
        return Promise.resolve();

    let api = new Api.SeedApi();
    let fetchTask = api.getSeed().then(seed => {
      
        dispatch({
            type: "RECEIVE_SEED",
            payload: {
                requestTime: requestTime,
                sizeTypes: seed.sizeTypes,
                currencies: seed.currencies,
                languages: seed.languages,
                chargeNames: seed.chargeNames,
                carriers: seed.carriers,
                carrierGroups: seed.carrierGroups,
                portsZones: seed.portsZones,
                markets: seed.markets,
                inlandCarriers: seed.inlandCarriers,
                carrierRatesPlatforms: seed.carrierRatesPlatforms,
                countries: seed.countries
            }
        });
    }).catch(error => {
        console.log('Error fetching seed: ' + error.message);
    });

    dispatch({ type: "REQUEST_SEED", payload: { requestTime: requestTime }});
    addTask(fetchTask);

    return fetchTask;
}

export const requestSeedCarriers = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<void> => {
    let api = new Api.SeedApi();
    let fetchTask = api.getCarriers().then(carriers => {

        dispatch({
            type: "RECEIVE_SEED_CARRIERS",
            payload: {
                requestTime: requestTime,
                carriers: carriers
            }
        });
    }).catch(error => {
        dispatch({
            type: "RECEIVE_SEED_CARRIERS",
            payload: {
                requestTime: requestTime,
            },
            error: error
        });
        console.log('Error fetching seed: ' + error.message);
    });

    dispatch({ type: "REQUEST_SEED_CARRIERS", payload: { requestTime: requestTime } });

    return fetchTask;
}

export const requestServicesOptions = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.SeedApi();
    let fetchTask = api.getOptions().then(options => {
        dispatch({
            type: "RECEIVE_SERVICESOPTIONS",
            payload: {
                requestTime: requestTime,
                servicesOptions: options
            }
        });
    }).catch(error => {
        console.error('Error fetching options: ' + error.message);
        dispatch({
            type: "RECEIVE_SERVICESOPTIONS",
            payload: {
                requestTime: requestTime,
            },
            error: error
        });
    });

    dispatch({ type: "REQUEST_SERVICESOPTIONS", payload: { requestTime: requestTime } });
    addTask(fetchTask);

    return fetchTask;
}

export const actionCreators = {
    requestSeed: (requestTime: number): AppThunkAction<KnownAction, void> => (dispath, getState) => {
        requestSeed(requestTime, dispath, getState);
    },
    requestServicesOptions: (requestTime: number): AppThunkAction<KnownAction, void> => (dispath, getState) => {
        requestSeed(requestTime, dispath, getState);
    },
};

const unloadedState: SeedState = {
    currencies: {},
    languages: {},
    sizeTypes: {},
    chargeNames: {},
    carriers: {},
    carrierGroups: {},
    portsZones: {},
    markets: {},
    inlandCarriers: {},
    carrierRatesPlatforms: {},
    countries: {},
    requestTime: 0,
    isLoading: false,
    servicesOptions: {},
    optionsIsLoading: false,
    optionsRequestTime: 0,
    isCarriersLoading: false
};

export const reducer: Reducer<SeedState> = (state: SeedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SEED':
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case 'RECEIVE_SEED':
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                currencies: action.payload.currencies,
                sizeTypes: action.payload.sizeTypes,
                languages: action.payload.languages,
                chargeNames: action.payload.chargeNames,
                carriers: action.payload.carriers,
                carrierGroups: action.payload.carrierGroups,
                portsZones: action.payload.portsZones,
                markets: action.payload.markets,
                inlandCarriers: action.payload.inlandCarriers,
                carrierRatesPlatforms: action.payload.carrierRatesPlatforms,
                countries: action.payload.countries
            };
        case "REQUEST_SERVICESOPTIONS":
            return {
                ...state,
                optionsIsLoading: true,
                optionsRequestTime: action.payload.requestTime
            };
        case "RECEIVE_SERVICESOPTIONS":
            if (action.payload.requestTime !== state.optionsRequestTime)
                return state;

            return {
                ...state,
                optionsIsLoading: false,
                servicesOptions: action.payload.servicesOptions
            };
        case "REQUEST_SEED_CARRIERS":
            return {
                ...state,
                isCarriersLoading: true,
                carriersRequestTime: action.payload.requestTime
            };
        case "RECEIVE_SEED_CARRIERS":
            if (action.payload.requestTime !== state.carriersRequestTime)
                return state;

            return {
                ...state,
                isCarriersLoading: false,
                carriers: action.error
                    ? state.carriers
                    : action.payload.carriers
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};