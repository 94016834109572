import { Action, Reducer } from 'redux';
import { AppThunkAction } from "./";

export type ScrollState = {
    scrollPosition: number;
};

interface UpdateScrollPosition { type: "UPDATE_SCROLL_POSITION"; payload: { value: number } }

type KnownAction = UpdateScrollPosition;

export const actionCreators = {
    updateScrollPosition: (value: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().scroll.scrollPosition !== value) {
            dispatch({
                type: "UPDATE_SCROLL_POSITION", payload: { value: value }
            });
        }
    }
}

const unloadedState: ScrollState = {
    scrollPosition: 0
};

export const reducer: Reducer<ScrollState> = (state: ScrollState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "UPDATE_SCROLL_POSITION":
            return {
                ...state,
                scrollPosition: action.payload.value
            };
    }

    return state || unloadedState;
};
