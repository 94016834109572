import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as Colors from '../styles/colors';

export default styled(Button)({
    background: 'none',
    border: `1px solid ${Colors.baseColors.darkGray}`,
    borderRadius: 25,
    boxShadow: 'none',
    color: Colors.baseColors.lightBlue,
    height: 35,
    padding: '0px 25px',
    textTransform: "none",
    fontWeight: 700,
    MozBoxShadow: "none",
    WebkitBoxShadow: "none",
});