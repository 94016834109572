import * as React from 'react';
import * as Api from '../api/api';
import * as LocationStore from '../store/Location';
import LocationSelect, { LocationSearchType } from './LocationSelect';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import FloatingButton from './FloatingButton';
import { ZoomIn } from '@material-ui/icons';
import { getLocationName } from '../utils/utils';
import LocationSelectSearch from './LocationSelectSearch';

type CriteriaLocationSelectProps = CriteriaLocationSelectExternalProps
    & LocationStore.LocationSelectState
    & CriteriaLocationSelectOwnProps
    & typeof LocationStore.actionCreators;

interface CriteriaLocationSelectExternalProps {
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    inputKey: string;
    disabled?: boolean;
    locationSource?: "Origin" | "Destination";
    defaultRegion?: string;
    value: Api.LocationModel;
    locationSearchType: LocationSearchType;
    onChange: (value: Api.LocationModel) => void;
}

interface CriteriaLocationSelectOwnProps {
    recentInlandCriterias: Array<Api.InlandCriteriaModel>;
}

class CriteriaLocationSelect extends React.Component<CriteriaLocationSelectProps, {}> {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.initSelectState(this.props.inputKey);
    }

    componentWillReceiveProps(nextProps: CriteriaLocationSelectProps) {
    }

    get defaultRegion(): string {
        if (this.props.defaultRegion)
            return this.props.defaultRegion;

        //if (!this.props.locationSource
        //    || this.props.locationSearchType !== "Inland"
        //    || this.props.recentInlandCriterias.length === 0)
        //    return undefined;

        //return this.props.locationSource === "Origin"
        //    ? this.props.recentInlandCriterias[0].origin.country
        //    && this.props.recentInlandCriterias[0].origin.country.code
        //    : this.props.recentInlandCriterias[0].destination.country
        //    && this.props.recentInlandCriterias[0].destination.country.code
    }

    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <LocationSelect
                    style={{
                        flex: 1,
                        ...this.props.style
                    }}
                    inputStyle={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        ...this.props.inputStyle,
                    }}
                    defaultRegion={this.defaultRegion}
                    disabled={this.props.disabled}
                    inputKey={this.props.inputKey}
                    value={this.props.value}
                    locationSearchType={this.props.locationSearchType}
                    onChange={(location) => this.props.onChange(location)} />
                <FloatingButton
                    style={{
                        flex: "0 0 auto",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        height: 45,
                        ...this.props.buttonStyle,
                        ...(this.props.locationSearchType === "Inland" ? { alignSelf: "flex-end" } : {})
                    }}
                    disabled={this.props.disabled}
                    onClick={(e) => {
                        this.props.toggleSearchLocationOpened(this.props.inputKey, true);
                    }}>
                    <ZoomIn style={{  }} />
                </FloatingButton>
                <LocationSelectSearch
                    isLoading={this.props.isLoading}
                    addressFound={this.props.addressFound}
                    locationDistances={this.props.locationDistances}
                    requestSearchByAddress={(requestTime) => this.props.requestSearchByAddress(this.props.inputKey, requestTime)}
                    selectLocation={(location) => {
                        this.props.updateLocationInputText(this.props.inputKey, getLocationName(location));
                        this.props.onChange(location);
                    }}
                    address={this.props.address}
                    isOpen={this.props.isOpen}
                    center={this.props.center}
                    requestClose={() => this.props.toggleSearchLocationOpened(this.props.inputKey, false)}
                    requestTime={this.props.requestTime}
                    updateSearchAddress={(value) => this.props.updateSearchAddress(this.props.inputKey, value)}
                />
            </div>
        );
    }
}



export default connect(
    (state: ApplicationState, props: CriteriaLocationSelectProps) => ({
        ...(state.location[props.inputKey]
            || LocationStore.unLoadedLocationSelectState),
        recentInlandCriterias: [], //state.account.currentUser.clientModel.recentInlandCriterias
    }), LocationStore.actionCreators
)(CriteriaLocationSelect) as any as React.ComponentClass<CriteriaLocationSelectExternalProps>;