import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as ContactBoxStore from '../store/ContactBox';
import SelectionFeedbackForm from "./SelectionFeedbackForm"
import { getText } from '../utils/langTexts';

type SelectionFeedBackProps = SelectionFeedBackOwnProps
    & ContactBoxStore.FeedbackState
    & typeof ContactBoxStore.actionCreators;

interface SelectionFeedBackOwnProps {

}

interface SelectionFeedBackState {
}

class SelectionFeedback extends React.Component<SelectionFeedBackProps, SelectionFeedBackState> {

    constructor(props: SelectionFeedBackProps) {
        super(props);
        this.state = {
        }
    }

    public render() {
        return (this.props.isOpen
            ? <div style={{ marginTop: 5 }}>
                <div>
                    {!this.props.isLoading && <div>
                        {this.props.sentLevel === "Bad" && <div style={{ textAlign: "center" }}>
                            {getText("SlcFeedbackThanksBad")}
                        </div>}
                        {this.props.sentLevel === "Good" && <div style={{ textAlign: "center" }}>
                            {getText("SlcFeedbackThanksGood")}
                        </div>}
                        {!this.props.sentLevel && <SelectionFeedbackForm
                            onSubmit={(values) => this.props.requestSetFeedback(new Date().getTime(), values)}
                        />}
                    </div>}
                    {this.props.isLoading && <div style={{ textAlign: "center" }}>
                        <img src={"/images/loader.svg"} height={32} />
                    </div>}
                </div>
        </div> : <div></div>);
    }
}

export default connect((state: ApplicationState) => ({
    ...state.contactBox.feedback
} as any), ContactBoxStore.actionCreators)(SelectionFeedback) as any as React.ComponentClass<{}>;