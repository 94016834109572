import * as React from "react";
import * as Api from "../api/api";
import * as FormField from './FormField';
import { connect } from "react-redux";
import { getText } from "../utils/langTexts";
import { ApplicationState } from "../store";
import * as ReduxForm from 'redux-form';
import { revertColors } from "../styles/colors";

type QuotationSettingsFormProps = ReduxForm.InjectedFormProps<{}, {}, string>
    & QuotationSettingsFormExternalProps
    & QuotationSettingsFormOwnProps;

interface QuotationSettingsFormExternalProps {
    onSubmit: (values: Api.QuotationSettingsModel) => Promise<any>;
}

interface QuotationSettingsFormOwnProps {

}

let textAreaField = FormField.textAreaField(5, 80);
const fieldStyle: React.CSSProperties= { display: "flex", flexDirection: "row", alignItems: "center", columnGap: 6 };
const labelStyle: React.CSSProperties= { display: "block", minWidth: 150 };
class QuotationSettingsForm extends React.Component<QuotationSettingsFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} style={{ maxWidth: 800 }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", rowGap: 15 }}>
                    <div style={fieldStyle}>
                        <label style={labelStyle} className="control-label">{getText("GenAutomaticallyFailsQuotationAfter")}:</label>
                        <ReduxForm.Field className="form-control"
                                         validate={[]}
                                         name="quotationExpirationDays"
                                         style={{
                                             width: 90,
                                             backgroundColor: "none"
                                         }}
                                         component={FormField.NumberField} />
                        <div>{getText("GenAutomaticallyFailsQuotationAfter2")}</div>
                    </div>
                    <div style={fieldStyle}>
                        <label style={labelStyle} className="control-label">{getText("GenDefaultAdditionalInfo")}:</label>
                        <ReduxForm.Field className="form-control"
                                         validate={[]}
                                         name="defaultAdditionalInfo"
                                         component={textAreaField} />
                    </div>
                    <div style={fieldStyle}>
                        <label style={labelStyle} className="control-label ">{getText("GenDefaultTerms")}:</label>
                        <ReduxForm.Field className="form-control"
                                         validate={[]}
                                         name="defaultTerms"
                                         component={textAreaField} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                        <button className="btn"
                                style={{ ...revertColors.main3 }}
                                disabled={this.props.pristine || this.props.submitting}
                                type={"submit"}>
                            {getText("GenSaveChanges")}
                        </button>
                        <button className="btn btn-gray"
                                style={{ marginRight: 10 }}
                                disabled={this.props.pristine || this.props.submitting}
                                type={"button"}
                                onClick={(e) => this.props.reset()}>
                            {getText("GenReset")}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
            );
    }
}

let updateForm = ReduxForm.reduxForm({
    form: 'updateQuotationSettings',
    destroyOnUnmount: false,
    enableReinitialize: true
})(QuotationSettingsForm) as any;
const updateSelector = ReduxForm.formValueSelector('updateQuotationSettings')

export default connect((state: ApplicationState, props: QuotationSettingsFormProps) => {
    return {
        initialValues: state.quotationSettings.quotationSettings
    } as QuotationSettingsFormOwnProps;
})(updateForm) as any as React.ClassicComponentClass<QuotationSettingsFormExternalProps>
