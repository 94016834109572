import * as Api from '../api/api';
import * as React from 'react';
import * as Colors from '../styles/colors';
import DialogModal from "./DialogModal";
import { getText } from '../utils/langTexts';

interface QuotationChronoCommentDialogProps {
    quotation: Api.QuotationModel;
    isOpen: boolean;
    isLoading: boolean;
    commentValue: string;
    onRequestClose: () => void;
    updateComment: (value: string) => void;
    requestUpdateQuotationComment: () => void;
}

export default class QuotationChronoCommentDialog extends React.Component<QuotationChronoCommentDialogProps, {}> {
    public render() {
        return (
            <DialogModal isOpen={this.props.isOpen}
                maxWidth={"md"}
                contentLabel={"Quotation remark"}
                onRequestClose={() => this.props.onRequestClose()}>
                <div style={{ padding: 10 }}>
                    <div>
                        <textarea rows={6} cols={52}
                            value={this.props.commentValue}
                            onChange={(e) => this.props.updateComment(e.target.value)}></textarea>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        alignItems: "center"
                    }}>
                        <button className="btn btn-primary"
                            disabled={this.props.isLoading
                                || (this.props.quotation
                                    && this.props.commentValue === this.props.quotation.comment)}
                            onClick={(e) => this.props.requestUpdateQuotationComment()}
                            style={{ ...Colors.revertColors.main3 }}>{getText("GenSaveChanges")}</button>
                    </div>
                </div>
            </DialogModal>
        );
    }
}