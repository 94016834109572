import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';

export interface QuotationSettingsState {
    requestTime?: number;
    isLoading: boolean;
    quotationSettings?: Api.QuotationSettingsModel;
    updateState: {
        requestTime?: number;
        isLoading: boolean;
    }
}

interface RequestQuotationSettings {
    type: "REQUEST_QUOTATIONSETTINGS";
    payload: {
        requestTime: number;
    }
}

export interface ReceiveQuotationSettings {
    type: "RECEIVE_QUOTATIONSETTINGS";
    payload: {
        requestTime: number;
        quotationSettings?: Api.QuotationSettingsModel;
    };
    error?: any;
}

interface RequestUpdateQuotationSettings {
    type: "REQUEST_UPDATE_QUOTATIONSETTINGS";
    payload: {
        requestTime: number;
    }
}

interface ReceiveUpdateQuotationSettings {
    type: "RECEIVE_UPDATE_QUOTATIONSETTINGS";
    payload: {
        requestTime: number;
        quotationSettings?: Api.QuotationSettingsModel;
    };
    error?: any;
}

type KnownAction = RequestQuotationSettings
    | ReceiveQuotationSettings
    | RequestUpdateQuotationSettings
    | ReceiveUpdateQuotationSettings;

export const actionCreators = {
    requestQuotationSettings: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.QuotationSettingsApi();
        let fetchTask = api.getQuotationSettings({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(quotSettings => {
                dispatch({
                    type: "RECEIVE_QUOTATIONSETTINGS",
                    payload: {
                        requestTime: requestTime,
                        quotationSettings: quotSettings
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_QUOTATIONSETTINGS",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });
        dispatch({
            type: "REQUEST_QUOTATIONSETTINGS",
            payload: {
                requestTime: requestTime
            }
        });
        return fetchTask;
    },
    requestUpdateQuotationSettings: (requestTime: number, model: Api.QuotationSettingsModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.QuotationSettingsApi();
        let fetchTask = api.update({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(quotSettings => {
                dispatch({
                    type: "RECEIVE_UPDATE_QUOTATIONSETTINGS",
                    payload: {
                        requestTime: requestTime,
                        quotationSettings: quotSettings
                    }
                });
                dispatch(Notifications.success({ message: "You changes have been saved", title: "Success", position: "tc" }) as any);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_QUOTATIONSETTINGS",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });
        dispatch({
            type: "REQUEST_UPDATE_QUOTATIONSETTINGS",
            payload: {
                requestTime: requestTime
            }
        });
        return fetchTask;
    },
};

const unloadedState: QuotationSettingsState = {
    isLoading: false,
    updateState: {
        isLoading: false,
    }
};

export const reducer: Reducer<QuotationSettingsState> = (state: QuotationSettingsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_QUOTATIONSETTINGS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_QUOTATIONSETTINGS":
            if (action.payload.requestTime != state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                quotationSettings: action.payload.quotationSettings
            };
        case "REQUEST_UPDATE_QUOTATIONSETTINGS":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_QUOTATIONSETTINGS":
            if (action.payload.requestTime != state.updateState.requestTime)
                return state;

            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: false,
                },
                quotationSettings: action.payload.quotationSettings
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};

