import * as React from 'react';
import * as Api from '../api/api';
import CheckBox from './CheckBox'

interface ChargeCriteriaInputProps {
    chargeCriteria: Api.ChargeModelCriteriaEnum;
    value: Array<Api.ChargeModelCriteriaEnum>;
    onChange: (value: Array<Api.ChargeModelCriteriaEnum>) => void;
    label?: string;
    labelStyle?: React.CSSProperties;
    labelPlacement?: "end" | "start"
}

export default class ChargeCriteriaInput extends React.Component < ChargeCriteriaInputProps, {} > {
    public render() {
        return (
            <CheckBox
                label={this.props.label}
                labelPlacement={this.props.labelPlacement}
                size={"small"}
                labelStyle={{ fontSize: 14, ...this.props.labelStyle }}
                value={this.props.value
                    && this.props.value.some(cr => cr === this.props.chargeCriteria)}
                onChange={(value) => {
                    if (value) {
                        this.props.onChange((this.props.value || []).concat([this.props.chargeCriteria]));
                    } else {
                        this.props.onChange((this.props.value || []).filter(x => x !== this.props.chargeCriteria));
                    }
                }} />
            );
    }
}

