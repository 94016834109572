import * as React from 'react';
import * as Api from "../api/api";
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import * as QuotationStore from "../store/Quotation";
import * as DocumentStore from "../store/Document";
import * as CarrierRatesPlatformStore from "../store/CarrierRatesPlatform";
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemText, Divider, Collapse } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { getText } from '../utils/langTexts';
import { getQuotationLink, getSubscription } from '../utils/utils';
import {
    canUseCarrierRates,
    canUseLcl,
    canUseQuotation,
    canUseInland,
    canUseRatesExtraction
} from '../security/userRights';

type NavigationMenuProps = NavigationMenuOwnProps
    & typeof DocumentStore.actionCreators
    & typeof CarrierRatesPlatformStore.actionCreators
    ;

interface NavigationMenuOwnProps {
    criteria: Api.CriteriaModel;
    inlandCriteria: Api.InlandCriteriaModel;
    quotation: QuotationStore.QuotationState;
    client: Api.ClientModel;
    onNavigation?: () => void;
}

interface NavigationMenuState {
    isFclOpen: boolean;
    isLclOpen: boolean;
    isInlandOpen: boolean;
}
const linkStyle: React.CSSProperties = {
    outline: "none",
    textDecoration: "none",
};

class NavigationMenu extends React.Component<NavigationMenuProps, NavigationMenuState> {
    constructor(props: NavigationMenuProps) {
        super(props);
        this.state = {
            isFclOpen: true,
            isLclOpen: true,
            isInlandOpen: true
        };
    }

    get hasFclSearch(): boolean {
        return this.props.criteria.code
            && this.props.criteria.type === "Fcl";
    }

    get hasLclSearch(): boolean {
        return this.props.criteria.code
            && this.props.criteria.type === "Lcl";
    }

    get hasInlandSearch(): boolean {
        return this.props.inlandCriteria.code ? true : false;
    }


    get enabledLcl(): boolean {
        return this.props.client
            && this.props.client.subscriptions.some(so => canUseLcl(so))
    }

    get enabledQuotation(): boolean {
        return this.props.client
            && this.props.client.subscriptions.some(so => canUseQuotation(so))
    }


    get enabledInland(): boolean {
        return this.props.client
            && this.props.client.subscriptions.some(so => canUseInland(so))
    }

    
    get enableRatesExtraction(): boolean {
        return this.props.client
            && this.props.client.subscriptions.some(so => canUseRatesExtraction(so));
    }

    public render() {
        return (
            <List style={{ width: 245 }}>
                <NavLink className={"navlink"} onClick={() => this.props.onNavigation()}
                    style={linkStyle}
                    to={this.props.criteria.code ? "/criteria/" + this.props.criteria.code : "/criteria/"}>
                    <ListItem button>
                        <ListItemText>
                            FCL
                        </ListItemText>
                        {this.hasFclSearch
                            && <div onClick={(e) => this.setState({ isFclOpen: !this.state.isFclOpen })}>
                                {this.state.isFclOpen ? <ExpandLess /> : <ExpandMore />}
                            </div>}
                    </ListItem>
                </NavLink>
                {this.hasFclSearch && <Collapse in={this.state.isFclOpen} timeout="auto" unmountOnExit>
                    <NavLink className={"navlink"} onClick={() => this.props.onNavigation()}
                             to={"/selection/" + this.props.criteria.code}
                             style={{ ...linkStyle, paddingLeft: 20 }}>
                    <List component="div" disablePadding>
                            <ListItem button>
                                <ListItemText>
                                    {getText("GenSelection", {
                                        pol: this.props.criteria.origin ? this.props.criteria.origin.name : "",
                                        pod: this.props.criteria.destination ? this.props.criteria.destination.name : ""
                                    })}
                                </ListItemText>
                            </ListItem>
                    </List>
                    </NavLink>
                </Collapse>}
                <NavLink className={"navlink"} onClick={(e) => {
                    if(!this.enabledLcl) {
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        this.props.onNavigation();
                    }
                    }}
                         style={linkStyle}
                         to="/lcl/criteria">
                    <ListItem button disabled={!this.enabledLcl} >
                        <ListItemText>
                            LCL
                        </ListItemText>
                        {this.hasLclSearch
                        && <div onClick={(e) => this.setState({ isLclOpen: !this.state.isLclOpen })}>
                            {this.state.isLclOpen ? <ExpandLess /> : <ExpandMore />}
                        </div>}
                    </ListItem>
                </NavLink>
                {/*this.hasLclSearch && <Collapse in={this.state.isLclOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button>
                            <ListItemText>
                                <NavLink className={"navlink"} onClick={() => this.props.onNavigation()}
                                    style={{ ...linkStyle, paddingLeft: 20 }}
                                    to={"/lcl/selection/" + this.props.criteria.code}>
                                    {getText("GenSelection", {
                                        pol: this.props.criteria.origin ? this.props.criteria.origin.name : "",
                                        pod: this.props.criteria.destination ? this.props.criteria.destination.name : ""
                                    })}
                                </NavLink>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Collapse>*/}
                <NavLink className={"navlink"} onClick={(e) => {
                    if(!this.enabledInland) {
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        this.props.onNavigation();
                    }
                }}
                         style={linkStyle}
                         to="/inland/criteria">
                    <ListItem button disabled={!this.enabledInland}>
                        <ListItemText>
    
                            Inland
                        </ListItemText>
                        {this.hasInlandSearch
                            && <div onClick={(e) => this.setState({ isInlandOpen: !this.state.isInlandOpen })}>
                                {this.state.isInlandOpen ? <ExpandLess /> : <ExpandMore />}
                            </div>}
                    </ListItem>
                </NavLink>
                {this.hasInlandSearch && <Collapse in={this.state.isInlandOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <NavLink className={"navlink"} onClick={() => this.props.onNavigation()}
                                 style={{ ...linkStyle, paddingLeft: 20 }}
                                 to={"/inland/selection/" + this.props.inlandCriteria.code}>

                        <ListItem button>
                            <ListItemText>
                                {getText("GenInlandSelection", {
                                    pickup: this.props.inlandCriteria.origin
                                        ? this.props.inlandCriteria.origin.name
                                        : "",
                                    dropoff: this.props.inlandCriteria.destination
                                        ? this.props.inlandCriteria.destination.name
                                        : ""
                                })}
                            </ListItemText>
                        </ListItem>
                        </NavLink>
                    </List>
                </Collapse>}
                {this.props.quotation.isLoaded &&
                <NavLink to={getQuotationLink(this.props.quotation)}
                         className={"navlink"} onClick={(e) => {
                    if(!this.enabledQuotation) {
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        this.props.onNavigation();
                    }
                }}
                         style={linkStyle}>
                    <ListItem button disabled={!this.enabledQuotation} >
                        <ListItemText>
                            {getText("GenCurrentQuotation")}

                                
                        </ListItemText>
                    </ListItem>
                </NavLink>}
                {this.enableRatesExtraction
                 && <NavLink to="/ratesExtraction"
                                         className={"navlink"} onClick={() => this.props.onNavigation()}
                                         style={linkStyle}>
                        <ListItem button>
                            <ListItemText>
                                {"Rates extraction"}
                            </ListItemText>
                        </ListItem>
                    </NavLink>}
                <NavLink to="/history"
                         className={"navlink"} onClick={() => this.props.onNavigation()}
                         style={linkStyle}>
                <ListItem button>
                    <ListItemText>
                        {getText("GenMyHistory")}

                    </ListItemText>
                </ListItem>
                </NavLink>

                <NavLink to="/quotations"
                         className={"navlink"} onClick={(e) => {
                    if(!this.enabledQuotation) {
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        this.props.onNavigation();
                    }
                }}
                         style={linkStyle}>
                <ListItem button disabled={!this.enabledQuotation}>
                    <ListItemText>
                        {getText("GenQuotations")}
                    </ListItemText>
                </ListItem>
                </NavLink>
                <div className={"navlink"}
                     id={"menu-1"}
                     onClick={() => {
                         this.props.onNavigation();
                         this.props.openFirmDocument();
                     }}
                     style={linkStyle}>
                <ListItem button>
                    <ListItemText>
     
                            {getText("GenMyDocuments")}
                    </ListItemText>
                </ListItem>
                </div>

                <NavLink to="/settings"
                    className={"navlink"} onClick={() => this.props.onNavigation()}
                    style={linkStyle}>
                    <ListItem button>
                        <ListItemText>
                            {getText("GenSettings")}
                        </ListItemText>
                    </ListItem>
                </NavLink>
                {/*{canUseCarrierRates(getSubscription(this.props.client))*/}
                {/*    && <ListItem button>*/}
                {/*        <ListItemText>*/}
                {/*            <div className={"navlink"}*/}
                {/*                onClick={() => {*/}
                {/*                    this.props.onNavigation();*/}
                {/*                    this.props.openCarrierRatesPlatformStatus();*/}
                {/*                }}*/}
                {/*                style={linkStyle}>*/}
                {/*                {getText("GenCarrierQuotesStatus")}*/}
                {/*            </div>*/}
                {/*        </ListItemText>*/}
                {/*    </ListItem>}*/}
                {canUseCarrierRates(getSubscription(this.props.client))
                    && <NavLink to="/carrierRatesSettings"
                        className={"navlink"} onClick={() => this.props.onNavigation()}
                        style={linkStyle}>
                        <ListItem button>
                            <ListItemText>
                                {getText("GenCarrierQuotesSettings")}
                            </ListItemText>
                        </ListItem>
                    </NavLink>}
                {this.props.client
                    && this.props.client.subscriptions.some(so => so.allowApiBlacklistManagement)
                    && <NavLink to="/routeRatesDataSettings"
                        className={"navlink"} onClick={() => this.props.onNavigation()}
                        style={linkStyle}>
                        <ListItem button>
                            <ListItemText>
                                {getText("BlckListApiTittle")}
                            </ListItemText>
                        </ListItem>
                    </NavLink>}
                {this.props.client
                    && this.props.client.subscriptions.some(so => so.allowInformUser
                        || so.allowRatesControl
                        || so.allowQuotationManagement
                        || so.allowAgentManagement
                        || so.allowRatesManagement)
                    && <NavLink to="/moderator"
                        className={"navlink"} onClick={() => this.props.onNavigation()}
                        style={linkStyle}>
                        <ListItem button>
                            <ListItemText>
                                {getText("GenModerator")}
                            </ListItemText>
                        </ListItem>
                    </NavLink>}
            </List>
        );
    }
}

export default connect((state: ApplicationState) => ({
    criteria: state.criteria.criteria,
    inlandCriteria: state.inlandCriteria.inlandCriteria,
    quotation: state.quotation,
    client: state.account.currentUser ? state.account.currentUser.clientModel : null
} as any), {
    ...DocumentStore.actionCreators,
    ...CarrierRatesPlatformStore.actionCreators
})(NavigationMenu) as any