import * as React from 'react';
import * as _ from 'lodash';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getText } from "../utils/langTexts";
import Button from "./Button";

type QuotationChronoFilterFormProps = ReduxForm.InjectedFormProps<Api.QuotationFilterModel, {}, string> & QuotationChronoFilterFormOwnProps;

interface QuotationChronoFilterFormOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
}
interface QuotationChronoFilterFormExternalProps {
    onSubmit: (values: Api.QuotationFilterModel) => Promise<any>;
}

const searchFieldStyle: React.CSSProperties = {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
}
const labelFieldStyle: React.CSSProperties = {
    marginRight: "0.5em",
    marginTop: "4px"
}
const trafficTypeOptions = (): Array<{ label: string; value: Api.QuotationFilterModelTrafficTypeEnum }> => [
    { label: getText("GenTrafficTypeImport"), value: "Import" },
    { label: getText("GenTrafficTypeExport"), value: "Export" },
    { label: getText("GenTrafficTypeCrossTrade"), value: "CrossTrade" }
];

const originLocationField = FormField.getLocationField("CHRONO_FILTER_ORIGIN");
const destinationLocationField = FormField.getLocationField("CHRONO_FILTER_DESTINATION");

class QuotationChronoFilterForm extends React.Component<QuotationChronoFilterFormProps, {}> {
    public render() {
        return (
            <div style={{ padding: "0px 20px" }}>
                <FormField.BaseForm {...this.props}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        columnGap: 10, rowGap: 10
                    }}>
                        <div style={searchFieldStyle}>
                            <label style={labelFieldStyle} >{getText("QtnChronoLbReference")}</label>
                            <div style={{ display: "inline-block"}}>
                                <ReduxForm.Field
                                    validate={[]}
                                    name="reference"
                                    component={FormField.InputTextField} />
                            </div>
                        </div>
                        <div style={searchFieldStyle}>
                            <label style={labelFieldStyle}>{getText("QtnChronoLbDateBegin")}</label>
                            <div style={{ display: "inline-block" }}>
                                <ReduxForm.Field
                                    validate={[]}
                                    name="dateBegin"
                                    nullable={true}
                                    component={FormField.DatePickerField} />
                            </div>
                        </div>
                        <div style={searchFieldStyle}>
                            <label style = {labelFieldStyle} >{getText("QtnChronoLbDateEnd")}</label>
                            <div style={{ display: "inline-block" }}>
                                <ReduxForm.Field
                                    validate={[]}
                                    name="dateEnd"
                                    nullable={true}
                                    component={FormField.DatePickerField} />
                            </div>
                        </div>
                        <div style={searchFieldStyle}>
                            <label style={labelFieldStyle}>{getText("QtnChronoLbClient")}</label>
                            <div style={{ display: "inline-block" }}>
                                <ReduxForm.Field
                                    validate={[]}
                                    name="client"
                                    component={FormField.InputTextField} />
                            </div>
                        </div>
                        <div style={searchFieldStyle}>
                            <label style={labelFieldStyle}>{getText("QtnChronoLbFreightForwarder")}</label>
                            <div style={{ display: "inline-block" }}>
                                <ReduxForm.Field
                                    validate={[]}
                                    name="freightForwarder"
                                    component={FormField.InputTextField} />
                            </div>
                        </div>
                        <div style={searchFieldStyle}>
                            <label style={labelFieldStyle}>{getText("QtnChronoLbTrafficType")}</label>
                            <div>
                                <ReduxForm.Field
                                    validate={[]}
                                    style={{ width: 175, height: 45}}
                                    name="trafficType"
                                    component={FormField.getSelectField(trafficTypeOptions())} />
                            </div>
                        </div>
                        <div style={searchFieldStyle}>
                            <label style={labelFieldStyle}>{getText("QtnChronoLbCarrier")}</label>
                            <div>
                                <ReduxForm.Field
                                    validate={[]}
                                    style={{ width: 175 }}
                                    name="carrierId"
                                    search={true}
                                    component={FormField.getSelectField(_.values(this.props.carriers).map(x => ({
                                        label: x.name,
                                        value: x.carrierId
                                    })))} />
                            </div>
                        </div>
                        <div style={searchFieldStyle}>
                            <label style={labelFieldStyle}>{getText("QtnChronoLbOrigin")}</label>
                            <div style={{ display: "inline-block" }}>
                                <ReduxForm.Field
                                    validate={[]}
                                    name="origin"
                                    component={originLocationField} />
                            </div>
                        </div>
                        <div style={searchFieldStyle}>
                            <label style={labelFieldStyle}>{getText("QtnChronoLbDestination")}</label>
                            <div style={{ display: "inline-block" }}>
                                <ReduxForm.Field
                                    validate={[]}
                                    name="destination"
                                    component={destinationLocationField} />
                            </div>
                        </div>
                        <div style={searchFieldStyle}>
                            <Button
                                style={{}}
                                type={"submit"}>
                                {getText("QtnChronoSearch")}
                            </Button>
                        </div>
                    </div>
                </FormField.BaseForm>
            </div>
            );
    }
}

const form = ReduxForm.reduxForm({
    form: 'quotationChronoFilter',
    destroyOnUnmount: false,
    enableReinitialize: true
})(QuotationChronoFilterForm) as any;
const selector = ReduxForm.formValueSelector('quotationChronoFilter')

export default connect((state: ApplicationState) => {
    return {
        initialValues: state.quotationChrono.loadedFilter,
        carriers: state.seed.carriers
    } as QuotationChronoFilterFormOwnProps;
})(form) as any as React.ComponentClass<QuotationChronoFilterFormExternalProps>