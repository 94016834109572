import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import CriteriaSizeType from './CriteriaSizeType';
import {  getSubscription } from '../utils/utils';
import { getText } from '../utils/langTexts';
import CheckBox from './CheckBox'
import CriteriaLocationSelect from "./CriteriaLocationSelect";
import * as _ from 'lodash';
import { CSSProperties } from 'react';
import Button from './Button';
import * as Colors from '../styles/colors';
import ChargeCriteriaInput from "./ChargeCriteriaInput";

type InlandCriteriaFormProps = ReduxForm.InjectedFormProps<Api.InlandCriteriaModel, {}, string>
    & InlandCriteriaFormOwnProps
    & InlandCriteriaFormExternalProps;

interface InlandCriteriaFormOwnProps {
    inlandCriteriaSizeTypes: Array<Api.InlandCriteriaSizeTypeModel>;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    containerType: Api.CriteriaModelContainerTypeEnum;
    priceDisplayMode: Api.InlandCriteriaModelPriceDisplayModeEnum;
    subscription: Api.SubscriptionModel;
    dateBegin: Date;
    dateEnd: Date;
}

interface InlandCriteriaFormExternalProps {
    onSubmit: (values: Api.InlandCriteriaModel) => void;
    resetInlandCriteria: () => void;
}

const containerRequired = (value: Array<Api.InlandCriteriaSizeTypeModel>) => (!value || !value.some(x => x.number > 0)) && getText("CrtContainerRequired");
const locationRequired = (value: Api.LocationModel) => (!value || (!value.locationId && !value.code)) && getText("GenRequired");

const titleStyle: React.CSSProperties = {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 20
};

const originField = (fields) => <div>
    <CriteriaLocationSelect
        inputKey={"CrtInlOrigin"}
        locationSource={"Origin"}
        value={fields.input.value}
        locationSearchType={"Inland"}
        buttonStyle={{ height: 42, width: 42 }}
        inputStyle={{ height: 42 }}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>

const destinationField = (fields) => <div>
    <CriteriaLocationSelect
        inputKey={"CrtInlDestination"}
        locationSource={"Destination"}
        value={fields.input.value}
        locationSearchType={"Inland"}
        buttonStyle={{ height: 42, width: 42 }}
        inputStyle={{ height: 42 }}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>

const formPartStyle: CSSProperties = {
};
const formPartBigStyle: CSSProperties = {
    ...formPartStyle,
};
const formPartSmallStyle: CSSProperties = {
    ...formPartStyle,
};
const advBtnContainer: CSSProperties = {
    display: "flex", flexDirection: "row-reverse",
    padding: "10px 0px"
}
const additionalLabelStyle: React.CSSProperties = { fontSize: 18, fontWeight: 500 };

export const inlandContainerTypeOptions = (): Array<{ label: string; value: Api.CriteriaModelContainerTypeEnum }> => [
    { label: getText("GenCtrDry"), value: "Dry" },
    { label: getText("GenCtrRefeer"), value: "Rf" },
];

class InlandCriteriaForm extends React.Component<InlandCriteriaFormProps, {}> {
    public render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div style={{ marginBottom: 20 }}>
                    <div style={{ ...titleStyle }}>Containers</div>
                    <div>
                        <ReduxForm.FieldArray
                            name="inlandCriteriaSizeTypes"
                            component={InlandCriteriaSizeTypes}
                            validate={[containerRequired]}
                            props={{
                                sizeTypes: this.props.sizeTypes,
                                inlandCriteriaSizeTypes: this.props.inlandCriteriaSizeTypes,
                                containerType: this.props.containerType,
                                min: 0,
                                max: 9
                            } as InlandCriteriaSizeTypesOwnProps} />
                    </div>
                    <div style={{ marginTop: 25, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ flex: "0 0 auto", minWidth: 150, marginRight: 12 }}>
                                    <div style={{ ...additionalLabelStyle }}>{getText("CrtContainerType")}:</div>
                                </div>
                                <div style={{ flex: "0 0 auto" }}>
                                    <ReduxForm.Field name={"containerType"}
                                                     validate={FormField.required}
                                                     component={FormField.getSelectField(inlandContainerTypeOptions())} />
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field name={"priceDisplayMode"}
                                 validate={[]}
                                 component={(field) => <CheckBox label={"Price by container"}
                                                                 value={this.props.priceDisplayMode === "ByCtr"}
                                                                 onChange={(value) => {
                                                                     if (value) {
                                                                         field.onChange("ByCtr");
                                                                     } else {
                                                                         field.onChange("AllIn");
                                                                     }
                                                                 }} />} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ flex: "0 0 auto", minWidth: 150 }}>
                                    <div style={{ ...additionalLabelStyle }}>{getText("CrtSurchargesType")}</div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <ReduxForm.Field name="chargeCriterias"
                                                     component={(fields) => <div>
                                                         <div>
                                                             <ChargeCriteriaInput
                                                                 label={getText("GenHazardous")}
                                                                 labelStyle={{ minWidth: 90 }}
                                                                 chargeCriteria={"Hazardous"}
                                                                 value={fields.input.value}
                                                                 onChange={(value) => fields.input.onChange(value)} />
                                                         </div>
                                                     </div>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div style={{ ...titleStyle }}>{getText("CrtPorts")}</div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div>
                                <label>{getText("CrtOrigin")}</label>
                            </div>
                           <div>
                                <ReduxForm.Field name={"origin"}
                                    validate={[locationRequired]}
                                    component={originField} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div>
                                <label>{getText("CrtDestination")}</label>
                            </div>
                            <div>
                                <ReduxForm.Field name={"destination"}
                                    validate={[locationRequired]}
                                    component={destinationField} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: 10 }}>
                    <div style={{ ...titleStyle }}>{getText("CrtDates")}</div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                            <div style={{}}>{getText("CrtFromDate")}</div>
                                <div style={{  }}>
                                    <ReduxForm.Field name={"dateBegin"}
                                        component={(fields) => <FormField.DatePickerField {...fields} maxDate={this.props.dateEnd} />} />
                                </div>
                            </div>
                        <div style={{ flex: 1 }}>
                            <div style={{}}>{getText("CrtToDate")}</div>
                                <div style={{  }}>
                                    <ReduxForm.Field name={"dateEnd"}
                                        component={(fields) => <FormField.DatePickerField {...fields} minDate={this.props.dateBegin} />} />
                                </div>
                            </div>
                        </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div>
                        <ReduxForm.Field name={"includeCarrierRates"}
                            validate={[]}
                            labelStyle={{ fontSize: 14, minWidth: 170 }}
                            component={FormField.checkBoxField(getText("CrtIncludeCarrierRates"))} />
                    </div>
                </div>
                <div style={{ margin: "50px 0px 30px 0px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ flex: "0 0 auto" }}>
                            <Button
                                onClick={(e) => {
                                    this.props.resetInlandCriteria();
                                    if (e.preventDefault) {
                                        e.preventDefault();
                                    }
                                }}>
                                {getText("CrtReset")}
                            </Button>
                        </div>
                        <div style={{ flex: 1 }}>
                        </div>
                        <div style={{ flex: "0 0 auto" }}>
                            <Button
                                type={"submit"}
                                style={{
                                    width: 165,
                                    height: 45,
                                    color: "white",
                                    backgroundColor: Colors.baseColors.lightBlue
                                }}
                                disabled={this.props.submitting}>
                                {getText("CrtGo")}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

type InlandCriteriaSizeTypesProps = InlandCriteriaSizeTypesOwnProps
    & ReduxForm.WrappedFieldArrayProps;

export interface InlandCriteriaSizeTypesOwnProps {
    inlandCriteriaSizeTypes: Array<Api.InlandCriteriaSizeTypeModel>;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    containerType: Api.InlandCriteriaModelContainerTypeEnum;
    min: number;
    max: number;
}

class InlandCriteriaSizeTypes extends React.Component<InlandCriteriaSizeTypesProps, {}> {

    componentWillReceiveProps(nextProps: InlandCriteriaSizeTypesProps) {
        console.log(nextProps.containerType);
        console.log(nextProps.inlandCriteriaSizeTypes);
        if (this.props.containerType !== nextProps.containerType) {
            let sizeTypes = _.sortBy(_.values(nextProps.sizeTypes)
                .filter(x => x.type === nextProps.containerType
                    && x.position < 4), x => x.position);

            let values = nextProps.inlandCriteriaSizeTypes.map(x => x.number);
            nextProps.fields.removeAll();
            console.log(sizeTypes);
            sizeTypes.forEach((st, si) => {
                nextProps.fields.insert(si, {
                    sizeTypeId: st.sizeTypeId,
                    number: values[si] || 0
                });
            });
        }
    }

    get sizeTypesList(): Array<Api.SizeTypeModel> {
        return _.sortBy(_.values(this.props.sizeTypes)
            .filter(x => x.type === this.props.containerType
                && x.position < 4), x => x.position);
    }

    public render() {
        return <div>
            <div className="row">
                {this.sizeTypesList
                    .map((st, index) => <div key={index} className="col-md-3">
                        <ReduxForm.Field name={`inlandCriteriaSizeTypes[${index}].number`}
                            component={(fields) => <CriteriaSizeType
                                sizeTypes={this.props.sizeTypes}
                                sizeTypeId={st.sizeTypeId}
                                min={this.props.min}
                                max={this.props.max}
                                value={fields.input.value || 0}                   
                                onChange={(value) => fields.input.onChange(value)} />}
                        />
                        <ReduxForm.Field name={`inlandCriteriaSizeTypes[${index}].sizeTypeId`}
                            component={(fields) => <input
                                type="hidden"
                                value={fields.input.value} />} />
                    </div>)}
            </div>
            <FormField.FieldErrors {...this.props.meta} />
        </div>
    }
}

let criteriaFormWrapped = ReduxForm.reduxForm({
    form: 'inlandCriteria',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(InlandCriteriaForm) as any;
const selector = ReduxForm.formValueSelector('inlandCriteria')

export default connect((state: ApplicationState) => {
    return {
        initialValues: state.inlandCriteria.inlandCriteria,
        inlandCriteriaSizeTypes: selector(state, "inlandCriteriaSizeTypes") || [],
        sizeTypes: state.seed.sizeTypes,
        containerType: selector(state, "containerType"),
        subscription: getSubscription(state.account.currentUser.clientModel),
        dateBegin: selector(state, "dateBegin"),
        dateEnd: selector(state, "dateEnd"),
        priceDisplayMode: selector(state, "priceDisplayMode"),
    } as InlandCriteriaFormOwnProps;
})(criteriaFormWrapped) as any as React.ComponentClass<InlandCriteriaFormExternalProps>