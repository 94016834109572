import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from "redux";
import * as Api from "../api/api";
import { AppThunkAction } from "./";
import { getDefaultHeaders } from "../utils/utils";

export interface ContactBoxState {
    isOpen: boolean;
    feedback: FeedbackState;
}

export interface FeedbackState {
    isOpen: boolean;
    isLoading: boolean;
    isCollapsed: boolean;
    sentLevel: Api.SetSatisfiedModelLevelEnum;
    requestTime: number;
    clientActionId: number;
}

interface ContactBoxOpen { type: 'CONTACTBOX_OPEN'; }
interface ContactBoxClose { type: 'CONTACTBOX_CLOSE'; }
interface ContactBoxOpenFeedback { type: 'CONTACTBOX_OPEN_FEEDBACK'; clientActionId: number }
interface ContactBoxCloseFeedback { type: 'CONTACTBOX_CLOSE_FEEDBACK' }
interface ContactBoxUncollapseFeedback { type: 'CONTACTBOX_UNCOLLAPSE_FEEDBACK'; }
interface ContactBoxRequestSetFeedback { type: 'CONTACTBOX_REQUEST_SET_FEEDBACK'; payload: { requestTime: number } }
interface ContactBoxReceiveSetFeedback {
    type: 'CONTACTBOX_RECEIVE_SET_FEEDBACK';
    payload: { requestTime: number; success: boolean; level: Api.SetSatisfiedModelLevelEnum };
    error?: any
}

type KnownAction = ContactBoxOpen | ContactBoxClose
    | ContactBoxOpenFeedback | ContactBoxUncollapseFeedback
    | ContactBoxRequestSetFeedback | ContactBoxReceiveSetFeedback
    | ContactBoxCloseFeedback;


export const actionCreators = {
    openContactBox: () => <ContactBoxOpen>{ type: "CONTACTBOX_OPEN" },
    closeContactBox: () => <ContactBoxClose>{ type: "CONTACTBOX_CLOSE" },
    closeFeeback: () => <ContactBoxCloseFeedback>{ type: "CONTACTBOX_CLOSE_FEEDBACK" },
    openFeedback: (clienActionId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().selection.selection.clientActionId === clienActionId) {
            dispatch({ type: "CONTACTBOX_OPEN_FEEDBACK", clientActionId: clienActionId });
        }
    },
    uncollapseFeedback: () => <ContactBoxUncollapseFeedback>{ type: "CONTACTBOX_UNCOLLAPSE_FEEDBACK" },
    requestSetFeedback: (requestTime: number, model: Api.SetSatisfiedModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().contactBox.feedback.requestTime)
            return Promise.reject("already did");

        model.clientActionId = getState().contactBox.feedback.clientActionId;
        const postMessageDelay = 5000;
        let api = new Api.SelectionApi();
        let task = api.setSatisfied({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: 'CONTACTBOX_RECEIVE_SET_FEEDBACK',
                    payload: { requestTime: requestTime, success: true, level: model.level }
                });
                setTimeout(() => {
                    dispatch({ type: "CONTACTBOX_CLOSE_FEEDBACK" });
                }, postMessageDelay);
            })
            .catch(error => {
                dispatch({
                    type: 'CONTACTBOX_RECEIVE_SET_FEEDBACK',
                    payload: { requestTime: requestTime, success: false, level: model.level },
                    error: error
                });
                setTimeout(() => {
                    dispatch({ type: "CONTACTBOX_CLOSE_FEEDBACK" });
                }, postMessageDelay);
            });

        dispatch({ type: 'CONTACTBOX_REQUEST_SET_FEEDBACK', payload: { requestTime: requestTime } });
        addTask(task);
        return task;
    }
}

const unloadedState: ContactBoxState = {
    isOpen: false,
    feedback: {
        requestTime: 0,
        isLoading: false,
        isOpen: false,
        sentLevel: null,
        isCollapsed: true,
        clientActionId: null
    },
}

export const reducer: Reducer<ContactBoxState> = (state: ContactBoxState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "CONTACTBOX_OPEN":
            return {
                ...state,
                isOpen: true
            };
        case "CONTACTBOX_CLOSE":
            return {
                ...state,
                isOpen: false
            };
        case "CONTACTBOX_CLOSE_FEEDBACK":
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    isOpen: false
                }
            };
        case "CONTACTBOX_REQUEST_SET_FEEDBACK":
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "CONTACTBOX_RECEIVE_SET_FEEDBACK":
            if (action.payload.requestTime !== state.feedback.requestTime)
                return state;

            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    isLoading: false,
                    sentLevel: action.payload.level
                }
            };
        case "CONTACTBOX_UNCOLLAPSE_FEEDBACK":
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    isCollapsed: false
                }
            };
        case "CONTACTBOX_OPEN_FEEDBACK":
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    isOpen: true,
                    isCollapsed: true,
                    sentLevel: null,
                    clientActionId: action.clientActionId
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};




