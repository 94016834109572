import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { getText } from '../utils/langTexts';
import { QuotationSelectContactClient } from "./Quotation";

export interface QuotationContactState {
    isLoaded: boolean;
    isLoading: boolean;
    requestTime?: number;
    ffIsOpen: boolean;
    clientIsOpen: boolean;
    quotationContacts: Array<Api.QuotationContactModel>;
    quotationContactIdToUpdate?: number;
    createState: { requestTime?: number; isLoading: boolean };
    editState: { [id: number]: { requestTime?: number; isLoading: boolean } };
    deleteState: { [id: number]: { requestTime?: number; isLoading: boolean } };
}

interface QuotationContactOpenFfDialog { type: "QUOTATION_CONTACT_OPEN_FF_DIALOG"; }
interface QuotationContactCloseFfDialog { type: "QUOTATION_CONTACT_CLOSE_FF_DIALOG"; }

interface QuotationContactOpenClientDialog { type: "QUOTATION_CONTACT_OPEN_CLIENT_DIALOG"; }
interface QuotationContactCloseClientDialog { type: "QUOTATION_CONTACT_CLOSE_CLIENT_DIALOG"; }

interface QuotationContactRequestEntities {
    type: "QUOTATION_CONTACT_REQUEST_ENTITIES";
    payload: { requestTime?: number; }
}
interface QuotationContactReceiveEntities {
    type: "QUOTATION_CONTACT_RECEIVE_ENTITIES";
    payload: { requestTime?: number; quotationContacts: Array<Api.QuotationContactModel> }; error?: any
}

interface QuotationContactRequestCreate {
    type: "QUOTATION_CONTACT_REQUEST_CREATE";
    payload: { requestTime?: number }
}
interface QuotationContactReceiveCreate {
    type: "QUOTATION_CONTACT_RECEIVE_CREATE";
    payload: { requestTime?: number; quotationContact: Api.QuotationContactModel }; error?: any
}

interface QuotationContactRequestUpdate {
    type: "QUOTATION_CONTACT_REQUEST_UPDATE";
    payload: { requestTime?: number; id: number }
}
interface QuotationContactReceiveUpdate {
    type: "QUOTATION_CONTACT_RECEIVE_UPDATE";
    payload: {
        requestTime?: number; id: number;
        quotationContact: Api.QuotationContactModel
    }; error?: any
}

interface QuotationContactRequestDelete {
    type: "QUOTATION_CONTACT_REQUEST_DELETE";
    payload: { requestTime?: number; id: number }
}
interface QuotationContactReceiveDelete {
    type: "QUOTATION_CONTACT_RECEIVE_DELETE";
    payload: { requestTime?: number; id: number }; error?: any
}

interface QuotationContactUpdateSelected {
    type: "QUOTATION_CONTACT_UPDATE_SELECTED";
    payload: { id: number };
}

type KnownAction = QuotationContactRequestEntities
    | QuotationContactReceiveEntities
    | QuotationContactRequestCreate
    | QuotationContactReceiveCreate
    | QuotationContactRequestUpdate
    | QuotationContactReceiveUpdate
    | QuotationContactRequestDelete
    | QuotationContactReceiveDelete
    | QuotationContactOpenFfDialog
    | QuotationContactCloseFfDialog
    | QuotationContactOpenClientDialog
    | QuotationContactCloseClientDialog
    | QuotationContactUpdateSelected;

export const actionCreators = {
    quotationContactOpenFfDialog: () => <QuotationContactOpenFfDialog>{ type: "QUOTATION_CONTACT_OPEN_FF_DIALOG" },
    quotationContactCloseFfDialog: () => <QuotationContactCloseFfDialog>{ type: "QUOTATION_CONTACT_CLOSE_FF_DIALOG" },
    quotationContactOpenClientDialog: () => <QuotationContactOpenClientDialog>{ type: "QUOTATION_CONTACT_OPEN_CLIENT_DIALOG" },
    quotationContactCloseClientDialog: () => <QuotationContactCloseClientDialog>{ type: "QUOTATION_CONTACT_CLOSE_CLIENT_DIALOG" },
    quotationContactUpdateSelected: (id: number) => <QuotationContactUpdateSelected>{
        type: "QUOTATION_CONTACT_UPDATE_SELECTED",
        payload: { id: id }
    },
    requestQuotationContacts: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().quotationContact.requestTime)
            return Promise.reject("Already did");

        let api = new Api.QuotationContactApi();
        let task = api.getEntities(
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(quotationContacts => {
                dispatch({
                    type: "QUOTATION_CONTACT_RECEIVE_ENTITIES",
                    payload: { requestTime: requestTime, quotationContacts: quotationContacts }
                });
            })
            .catch(error => {
                dispatch({
                    type: "QUOTATION_CONTACT_RECEIVE_ENTITIES",
                    payload: { requestTime: requestTime, quotationContacts: [] },
                    error: error
                });
                dispatch(Notifications.error({ message: "Failed to load contacts", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "QUOTATION_CONTACT_REQUEST_ENTITIES", payload: { requestTime: requestTime } });
        addTask(task);
        return task;
    },
    requestCreateQuotationContact: (requestTime: number, model: Api.QuotationContactModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().quotationContact.createState.requestTime)
            return Promise.reject("Already did");

        let api = new Api.QuotationContactApi();
        let task = api.create({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(quotationContact => {
                dispatch({
                    type: "QUOTATION_CONTACT_RECEIVE_CREATE",
                    payload: { requestTime: requestTime, quotationContact: quotationContact }
                });
                dispatch(Notifications.success({ message: getText("QtnContactCreated"), title: "Success", position: "tc" }) as any);
            })
            .catch(error => {
                dispatch({
                    type: "QUOTATION_CONTACT_RECEIVE_CREATE",
                    payload: { requestTime: requestTime, quotationContact: null },
                    error: error
                });
                dispatch(Notifications.error({ message: "Failed to create contact", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "QUOTATION_CONTACT_REQUEST_CREATE", payload: { requestTime: requestTime } });
        addTask(task);
        return task;
    },
    requestUpdateQuotationContact: (requestTime: number, model: Api.QuotationContactModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().quotationContact.editState[model.quotationContactId]
            && requestTime === getState().quotationContact.editState[model.quotationContactId].requestTime)
            return Promise.reject("Already did");

        let api = new Api.QuotationContactApi();
        let task = api.update({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(quotationContact => {
                dispatch({
                    type: "QUOTATION_CONTACT_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, id: model.quotationContactId, quotationContact: quotationContact }
                });
            })
            .catch(error => {
                dispatch({
                    type: "QUOTATION_CONTACT_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, id: model.quotationContactId, quotationContact: null },
                    error: error
                });
                dispatch(Notifications.error({ message: "Failed to update contact", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "QUOTATION_CONTACT_REQUEST_UPDATE", payload: { requestTime: requestTime, id: model.quotationContactId } });
        addTask(task);
        return task;
    },
    requestDeleteQuotationContact: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().quotationContact.deleteState[id]
            && requestTime === getState().quotationContact.deleteState[id].requestTime)
            return Promise.reject("Already did");

        let api = new Api.QuotationContactApi();
        let task = api.disableContact({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "QUOTATION_CONTACT_RECEIVE_DELETE",
                    payload: { requestTime: requestTime, id: id }
                });
            })
            .catch(error => {
                dispatch({
                    type: "QUOTATION_CONTACT_RECEIVE_DELETE",
                    payload: { requestTime: requestTime, id: id },
                    error: error
                });
                dispatch(Notifications.error({ message: "Failed to update contact", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "QUOTATION_CONTACT_REQUEST_DELETE", payload: { requestTime: requestTime, id: id } });
        addTask(task);
        return task;
    },
    quotationSelectContactClient: (value: Api.QuotationContactModel) => <QuotationSelectContactClient>{
        type: "QUOTATION_SELECT_CONTACT_CLIENT",
        payload: { value: value }
    }
}

const unloadedState: QuotationContactState = {
    clientIsOpen: false,
    ffIsOpen: false,
    isLoaded: false,
    createState: { isLoading: false },
    deleteState: {},
    editState: {},
    isLoading: false,
    quotationContacts: [],
}

export const reducer: Reducer<QuotationContactState> = (state: QuotationContactState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "QUOTATION_CONTACT_REQUEST_ENTITIES":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "QUOTATION_CONTACT_RECEIVE_ENTITIES":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoaded: action.error
                    ? state.isLoaded
                    : true,
                isLoading: false,
                quotationContacts: action.error
                    ? state.quotationContacts
                    : action.payload.quotationContacts
            };
        case "QUOTATION_CONTACT_REQUEST_CREATE":
            return {
                ...state,
                createState: {
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "QUOTATION_CONTACT_RECEIVE_CREATE":
            return {
                ...state,
                quotationContacts: action.error
                    ? state.quotationContacts
                    : state.quotationContacts.concat([action.payload.quotationContact]),
                createState: {
                    isLoading: action.payload.requestTime === state.createState.requestTime
                        ? false
                        : state.createState.isLoading
                }
            };
        case "QUOTATION_CONTACT_REQUEST_UPDATE":
            return {
                ...state,
                editState: {
                    ...state.editState,
                    [action.payload.id]: {
                        ...state.editState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "QUOTATION_CONTACT_RECEIVE_UPDATE":
            if (state.editState[action.payload.id]
                && state.editState[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                quotationContacts: action.error
                    ? state.quotationContacts
                    : state.quotationContacts.map(x => x.quotationContactId === action.payload.id
                        ? action.payload.quotationContact : x),
                editState: {
                    ...state.editState,
                    [action.payload.id]: {
                        ...state.editState[action.payload.id],
                        isLoading: false,
                    }
                }
            };
        case "QUOTATION_CONTACT_REQUEST_DELETE":
            return {
                ...state,
                deleteState: {
                    ...state.deleteState,
                    [action.payload.id]: {
                        ...state.deleteState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "QUOTATION_CONTACT_RECEIVE_DELETE":
            if (state.deleteState[action.payload.id]
                && state.deleteState[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                quotationContacts: action.error
                    ? state.quotationContacts
                    : state.quotationContacts.filter(x => x.quotationContactId !== action.payload.id),
                deleteState: {
                    ...state.deleteState,
                    [action.payload.id]: {
                        ...state.deleteState[action.payload.id],
                        isLoading: false
                    }
                }
            };
        case "QUOTATION_CONTACT_OPEN_FF_DIALOG":
            return {
                ...state,
                ffIsOpen: true
            };
        case "QUOTATION_CONTACT_CLOSE_FF_DIALOG":
            return {
                ...state,
                ffIsOpen: false
            };
        case "QUOTATION_CONTACT_OPEN_CLIENT_DIALOG":
            return {
                ...state,
                clientIsOpen: true
            };
        case "QUOTATION_CONTACT_CLOSE_CLIENT_DIALOG":
            return {
                ...state,
                clientIsOpen: false
            };
        case "QUOTATION_CONTACT_UPDATE_SELECTED":
            return {
                ...state,
                quotationContactIdToUpdate: action.payload.id
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};