import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import { getText } from '../utils/langTexts';
import * as Colors from "../styles/colors";
import Button from './Button';
import {hFieldStyle, vFormStyle} from "../styles/baseStyles";

type ModeratorRatesOfferEditFormProps = ReduxForm.InjectedFormProps<{}, {}, string>
    & ModeratorRatesOfferEditFormOwnProps
    & ModeratorRatesOfferEditFormExternalProps;

interface ModeratorRatesOfferEditFormOwnProps {
    initialValues: Api.RatesOfferModel;
    handleSubmit: (values: Api.RatesOfferModel) => void;
    carriers: { [id: number]: Api.CarrierModel };
    submitting: boolean;
    disabled?: boolean;
}
interface ModeratorRatesOfferEditFormExternalProps {
    disabled?: boolean;
    onSubmit: (values: Api.RatesOfferModel) => Promise<any>
}

const textAreaField = FormField.textAreaField(4, 50);


export const infoLevelOptions: Array<{ label: string, value: Api.OfferInformationModelLevelEnum }> = [
    { label: "Low", value: "Low" }, { label: "Normal", value: "Normal" }, { label: "High", value: "High" }];

const labelStyle: React.CSSProperties = { display: "block", minWidth: 90 }

class ModeratorRatesOfferEditForm extends React.Component<ModeratorRatesOfferEditFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div style={{ ...vFormStyle }}>
                    <div className="text-danger" style={{ textAlign: "center" }}>
                        {this.props.error && <span>{this.props.error}</span>}
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={labelStyle} className="col-sm-3 control-label">{getText("MdtRatesInfoEditCarrier")}</label>
                        <div className="col-sm-9">
                            <input className="form-control"
                                   disabled
                                   value={this.props.initialValues.carriers
                                       ? (this.props.initialValues.carriers.map(c => this.props.carriers[c.carrierId].name).join(','))
                                       : ""}
                                   onChange={(e) => { }} />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={labelStyle} className="col-sm-3 control-label">{getText("MdtRatesInfoEditReference")}</label>
                        <div className="col-sm-9">
                            <input className="form-control"
                                   disabled
                                   value={this.props.initialValues.references ? this.props.initialValues.references.map(r => r.value).join(',') :""}
                                   onChange={(e) => { }}/>
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={labelStyle} className="col-sm-3 control-label">{getText("MdtRatesInfoEditShortname")}</label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             validate={[]}
                                             disabled={this.props.disabled}
                                             name="shortName"
                                             component={FormField.renderField}
                                             type="text" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={labelStyle} className="col-sm-3 control-label">{getText("MdtRatesInfoEditDescription")}</label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             validate={[]}
                                             disabled={this.props.disabled}
                                             name="description"
                                             component={textAreaField} />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={labelStyle} className="col-sm-3 control-label"> </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.requiredOrZero]}
                                             name="level"
                                             component={FormField.getSelectField(infoLevelOptions)}
                                             type="text"
                            />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }} className="form-group row">
                        <label style={labelStyle} className="col-sm-3 control-label"></label>
                        <div className="col-sm-9">
                            {this.props.disabled ? (
                                    <Button type="submit"
                                            style={{ color: "white", backgroundColor: Colors.baseColors.darkGray, marginRight: "1em" }}
                                            disabled={this.props.disabled}>
                                        {getText("GenSaveChanges")}
                                    </Button>) :
                                (<Button type="submit"
                                         style={{ color: "white", backgroundColor: Colors.baseColors.lightBlue, marginRight: "1em" }}>
                                    {getText("GenSaveChanges")}
                                </Button>)
                            }
                        </div>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let ModeratorRatesOfferEditFormWrapped = ReduxForm.reduxForm({
    form: 'moderatorEditRatesOffers',
    enableReinitialize: true
})(ModeratorRatesOfferEditForm) as any;

export default connect((state: ApplicationState) =>
    ({
        carriers: state.seed.carriers,
        initialValues: state.ratesOffer.editState.ratesOfferId
            ? state.ratesOffer.ratesOffers
                .find(ro => ro.ratesOfferId === state.ratesOffer.editState.ratesOfferId)
            : {}
    }))(ModeratorRatesOfferEditFormWrapped) as any as React.ComponentClass<ModeratorRatesOfferEditFormExternalProps>