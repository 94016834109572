import * as React from 'react';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as CarrierRatesOfferInformationStore from '../store/CarrierRatesOfferInformation';
import * as TabsStore from "../store/Tabs";
import { ApplicationState } from '../store';
import { CreateCarrierRatesOfferInformationForm, UpdateCarrierRatesOfferInformationForm } from "./CarrierRatesOfferInformationForm";
import Loader from './Loader';
import Table, { Column } from './Table';
import { getFirmName } from '../utils/utils';
import { getText } from '../utils/langTexts';
import * as Colors from "../styles/colors";
import CenteredContentTabs from './CenteredContentTabs';

type CarrierRatesOfferInformationProps = CarrierRatesOfferInformationOwnProps
    & CarrierRatesOfferInformationStore.CarrierRatesOfferInformationState
    & typeof CarrierRatesOfferInformationStore.actionCreators
    & typeof TabsStore.actionCreators;

interface CarrierRatesOfferInformationOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
    subscriptions: Array<Api.SubscriptionModel>;
}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class CarrierRatesOfferInformation extends React.Component<CarrierRatesOfferInformationProps, {}> {

    componentWillMount() {
        this.props.requestCarrierRatesOfferInformation(new Date().getTime());
    }

    componentWillReceiveProps(nextProps: CarrierRatesOfferInformationProps) {
    }

    public render() {

        var companySubscriptions = this.props.subscriptions.filter(x => x.subscriptionType == 'Okargo');
        return (
            <div>
                <h2>{getText("MdtCarrierQuotesRemarks")}</h2>
                <div>
                    <Table
                        tableKey={"carrierRatesOfferInformation"}
                        style={{ textAlign: "center" }}
                        data={this.props.entities}
                        columns={[{
                            header: getText("MdtCarrierQuotesRemarksCarrier"),
                            id: 'carrierId',
                            accessor: (d: Api.CarrierRatesOfferInformationModel) => this.props.carriers[d.carrierId].name,
                        },
                        {
                            header: getText("MdtCarrierQuotesRemarksCompany"),
                            id: "firmId",
                            accessor: (d: Api.CarrierRatesOfferInformationModel) => {
                                let fi = companySubscriptions
                                    .map(x => x.agency)
                                    .concat(companySubscriptions
                                        .map(x => x.agency.company))
                                    .concat(companySubscriptions
                                        .map(x => x.agency.company.companyGroup))
                                    .find(x => x && x.firmId === d.firmId);
                                return fi ? getFirmName(fi) : "";
                            }
                        },
                        {
                            id: "actions",
                            header: <div></div>,
                            accessor: (d: Api.CarrierRatesOfferInformationModel) => d,
                            cell: d => {
                                let carrierRatesOfferInformation = d as Api.CarrierRatesOfferInformationModel;
                                return (<div>
                                    <a style={{
                                        cursor: "pointer",
                                        margin: "0px 5px",
                                        ...(this.props.selectedId === carrierRatesOfferInformation.carrierRatesOfferInformationId
                                            ? { color: Colors.baseColors.lightBlue }
                                            : {})
                                    }} onClick={(e) => {
                                                this.props.updateCarrierRatesOfferInformationSelectedId(carrierRatesOfferInformation.carrierRatesOfferInformationId);
                                                this.props.selectTab("carrierRatesOfferInformation", "edit");
                                            }}>{getText("GenEdit")}</a>
                                            <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                                if (window.confirm(getText("GenConfirmQuestion"))) {
                                                    this.props.requestDeleteCarrierRatesOfferInformation(new Date().getTime(), carrierRatesOfferInformation.carrierRatesOfferInformationId);
                                                }
                                            }}>{getText("MdtNewsEditDelete")}</a>
                                        </div>);
                            }
                            }] as Array<Column<Api.CarrierRatesOfferInformationModel>>}
                        rowsPerPage={10}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPagination={true}
                        showPageSizeOptions={false}
                        noDataText="No results"
                    />
                </div>
                <div style={{ marginTop: 20 }}>
                    <CenteredContentTabs
                        tabKey={"carrierRatesOfferInformation"}
                        data={[
                            {
                                title: getText("GenCreate"),
                                tabKey: "create",
                                tabContent: <div style={{ padding: 10 }}>
                                    <h3>{getText("GenCreateNewCarrierQuotesRemarks")}</h3>
                                    <div style={{ maxWidth: 1000 }}>
                                        <CreateCarrierRatesOfferInformationForm
                                            mode={"Create"}
                                            onSubmit={(values) => {
                                                return this.props.requestCreateCarrierRatesOfferInformation(new Date().getTime(), values) as any;
                                            }} />
                                    </div>
                                </div>
                            },
                            {
                                title: getText("GenEdit"),
                                tabKey: "edit",
                                tabContent: <div style={{ padding: 10 }}>
                                    <div style={{ maxWidth: 1000 }}>
                                        <h3>{getText("GenEditCarrierQuotesRemarks")}</h3>
                                        {this.props.selectedId
                                            && <div>
                                                <UpdateCarrierRatesOfferInformationForm
                                                    mode={"Update"}
                                                    onSubmit={(values) => {
                                                        return this.props.requestUpdateCarrierRatesOfferInformation(new Date().getTime(), values) as any;
                                                    }} />
                                            </div>}
                                    </div>
                                </div>
                            }
                        ]}/>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.carrierRatesOfferInformation,
        carriers: state.seed.carriers,
        subscriptions: state.account.currentUser.clientModel.subscriptions,
    } as any),
    {
        ...CarrierRatesOfferInformationStore.actionCreators,
        ...TabsStore.actionCreators
    })(CarrierRatesOfferInformation) as any