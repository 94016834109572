import * as React from 'react';
import * as Api from '../api/api';
import * as _ from 'lodash';
import Moment from 'moment';
import Tooltip from './Tooltip';
import { RatesCalculator } from '../utils/ratesUtils';
import * as Colors from "../styles/colors";
import { getText } from '../utils/langTexts';
import Table, { Column } from './Table';
import { CSSProperties } from 'react';
import { getInlandOfferKey } from '../store/InlandSelection';
import { roadTransportId } from '../utils/constants';
import InlandOfferRatesOffer from './InlandOfferRatesOffer';
import { Warning } from '@material-ui/icons';
import Loader from './Loader';
import { getOnlineOfferErrorText } from '../utils/errorMessageHandler';
import { getLocationName } from "../utils/utils";

interface InlandOfferTableProps {
    ratesCalculator: RatesCalculator;
    isLoading: boolean;
    inlandSelection?: Api.InlandSelectionModel;
    inlandCriteria: Api.InlandCriteriaModel;
    inlandCarrierOffers: Array<Api.InlandCarrierOfferModel>;
    currencies: { [id: number]: Api.CurrencyModel };
    inlandCarriers: { [id: number]: Api.InlandCarrierModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencyId: number;
    onOfferClick: (key: string) => void;
    //handleOfferClick: (id: string) => void;
    carrierRatesStates: { [key: string]: { isLoading: boolean; inlandCarrierId: number; errors: Array<Api.RatesFetcherErrorModel> } };
}

type OfferColumn = Column<Api.InlandCarrierOfferModel>;

interface SizeTypeApplication {
    sizeTypeId: number;
    minWeight?: number;
    maxWeight?: number;
}

const getSizeTypeApplications = (inlandChargeSet: Api.InlandChargeSetModel, inlandCriteria: Api.InlandCriteriaModel): Array<SizeTypeApplication> => {
    return inlandCriteria.inlandCriteriaSizeTypes
        .filter(x => inlandChargeSet
            .charges.some(y => (y.sizeTypeId === x.sizeTypeId
                || y.unit === "Ctr"
                || y.unit === "Teu")
                && y.chargeNameId === roadTransportId))
        .map(x => ({
            sizeTypeId: x.sizeTypeId,
            minWeight: _.max(inlandChargeSet.charges
                .filter(y => (y.sizeTypeId === x.sizeTypeId
                    || y.unit === "Ctr"
                    || y.unit === "Teu")
                    && y.minWeight)
                .map(y => y.minWeight)),
            maxWeight: _.min(inlandChargeSet.charges
                .filter(y => (y.sizeTypeId === x.sizeTypeId
                    || y.unit === "Ctr"
                    || y.unit === "Teu")
                    && y.maxWeight)
                .map(y => y.maxWeight)),
        } as SizeTypeApplication));
};

export const getAllpliesTo = (inlandChargeSet: Api.InlandChargeSetModel, inlandCriteria: Api.InlandCriteriaModel, sizeTypes: { [id: number]: Api.SizeTypeModel }): string => {
    if (!inlandChargeSet)
        return "";

    let stApplications = getSizeTypeApplications(inlandChargeSet, inlandCriteria)
        .filter(x => inlandCriteria.inlandCriteriaSizeTypes
            .some(y => x.sizeTypeId === y.sizeTypeId && y.number > 0));

    if (!inlandCriteria.inlandCriteriaSizeTypes
        .some(x => !stApplications.some(y => y.sizeTypeId === x.sizeTypeId))
        && !stApplications.some(x => (x.maxWeight || x.minWeight) ? true : false)) {
        return "All";
    }

    let applications = _.map(_.groupBy(stApplications, x => `IW${x.minWeight}AW${x.maxWeight}`), x => {
        let minText = `> ${x[0].minWeight}`;
        let maxText = `< ${x[0].maxWeight}`;
        let weightText = (x[0].minWeight && x[0].maxWeight)
            ? `${minText} & ${maxText}`
            : x[0].minWeight
                ? minText
                : x[0].maxWeight
                    ? maxText
                    : "";

        if (!inlandCriteria.inlandCriteriaSizeTypes.some(y => !x.some(z => z.sizeTypeId === y.sizeTypeId))) {
            return `All ${weightText}`;
        }

        return `${x.map(y => sizeTypes[y.sizeTypeId].name).join(", ")} ${weightText}`;
    });


    return applications.join("\n");
}

const ratesOfferTooltipStyle: CSSProperties = { opacity: 1, width: 500 };

export default class InlandOfferTable extends React.Component<InlandOfferTableProps, {}> {
    constructor(props: InlandOfferTableProps) {
        super(props);
    }

    public render() {
        return <div>
            {(_.values(this.props.carrierRatesStates).some(x => x.isLoading
                || x.errors.length !== 0))
                && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
                    {_.values(this.props.carrierRatesStates).map((x, xi) => <div key={xi} style={{ flex: 1, position: "relative", minWidth: 350 }}>
                        <Loader hideBackground
                            isVisible={x.isLoading}
                            size={20}
                            message={`Loading ${this.props.inlandCarriers[x.inlandCarrierId].name} carrier quotes...`} />
                        {x.errors.length !== 0 && <div style={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color: Colors.baseColors.lightBlue,
                            fontSize: 14
                        }}>
                            <div>
                                {x.errors.map(error => getOnlineOfferErrorText(error, { carrier: this.props.inlandCarriers[x.inlandCarrierId].name, carrierUrl: "" }))}
                            </div>
                        </div>}
                    </div>)}
                </div>}
            <Table tableKey={"inlSelectionOffers"}
                columns={[
                    {
                        header: <div
                            style={{ fontWeight: "bold" }}>
                            {getText("InlCarrier")}
                        </div>,
                        style: { paddingLeft: '10px !important' },
                        id: 'carrrier',
                        accessor: (d) => this.props.inlandCarriers[d.inlandCarrierId].name,
                        cell: (d: any) => <div style={{ fontWeight: "bold", paddingLeft: 10, textAlign: "left" }}>{d}</div>
                    },
                    {
                        header: <div style={{ fontWeight: "bold" }}>{getText("SlcTableRates")}</div>,
                        id: 'rates',
                        accessor: (d) => d,
                        cell: (d: any) => {
                            let carrierOffer = d as Api.InlandCarrierOfferModel;
                            //Tooltip children have to implement hovering etc ...
                            //So wrap the content within a div
                            let hasWarning = (carrierOffer.offerInformations
                                && carrierOffer.offerInformations.some(x => x.level === "High"));
                            return (
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    {carrierOffer.inlandChargeSet
                                        ?
                                        <div>
                                            <Tooltip
                                                style={ratesOfferTooltipStyle}
                                                trigger={["hover", "click"]}
                                                showDelay={0.5}
                                                overlay={<div>
                                                    <InlandOfferRatesOffer
                                                        inlandRatesOffer={carrierOffer.inlandRatesOffer}
                                                        inlandChargeSet={carrierOffer.inlandChargeSet}
                                                        offerInformations={carrierOffer.offerInformations}
                                                    />
                                                </div>}
                                                placement="right"
                                                hideDelay={0.4}>
                                                <div>
                                                    {carrierOffer.inlandRatesOffer
                                                        ? carrierOffer.inlandRatesOffer.shortName || carrierOffer.inlandChargeSet.reference
                                                        : (carrierOffer.inlandChargeSet && carrierOffer.inlandChargeSet.reference)}
                                                    {hasWarning && <Warning style={{ marginLeft: 6, fontSize: 14, color: Colors.baseColors.lightBlue }} />}
                                                </div>
                                            </Tooltip>
                                        </div>
                                        : null}
                                </div>
                            );
                        }
                    },
                    {
                        header: <div
                            style={{ fontWeight: "bold" }}>
                            {this.props.inlandCriteria.origin
                                && this.props.inlandCriteria.origin.locationType === "Port"
                                ? getText("SlcInlandOfferTablePlaceOfDischarge")
                                : getText("SlcInlandOfferTablePlaceOfLoading")}
                        </div>,
                        id: 'routeType',
                        align: "center",
                        accessor: (d) => d,
                        cell: (d: any) => {
                            let route = d.inlandRoute as Api.InlandRouteModel;
                            return <div style={{ textAlign: "center" }}>
                                {this.props.inlandCriteria.origin
                                    && this.props.inlandCriteria.origin.locationType === "Port"
                                    && (d.dropOffFound || getLocationName(route.dropOffLocation))}
                                {this.props.inlandCriteria.origin
                                    && this.props.inlandCriteria.origin.locationType !== "Port"
                                    && (d.pickUpFound || getLocationName(route.pickupLocation))}
                            </div>
                        }
                    },
                    {
                        header: <div
                            style={{ fontWeight: "bold" }}>
                            Route type
                        </div>,
                        id: 'routeType',
                        align: "center",
                        accessor: (d) => d.inlandRoute.type,
                        cell: (d: any) => {
                            let type = d as Api.InlandRouteModelTypeEnum;
                            return <div style={{ textAlign: "center" }}>
                                {type === "TruckRoundTrip" && "Truck round trip"}
                                {type === "TruckOneWay" && "Truck one way"}
                                {type === "BargeTruckOneWay" && "Barge truck one way"}
                                {type === "RailTruckOneWay" && "Rail truck one way"}
                            </div>
                        }
                    },
                ].concat(
                    this.props.inlandCriteria.priceDisplayMode === "AllIn"
                        ? [
                            {
                                header: <div
                                    style={{ fontWeight: "bold", cursor: "pointer", minWidth: 100 }}
                                    onClick={(e) => {
                                    }}>
                                    {getText("SlcTableTotalFrom")}
                                </div>,
                                id: 'totalFrom',
                                accessor: (d) => d,
                                cell: (d: any) => {
                                    let offer = d as Api.InlandCarrierOfferModel;
                                    return (
                                        offer.inlandChargeSet &&
                                        <div style={{ color: Colors.colors.main3.color }}>
                                            <div style={{ fontWeight: "bold" }}>
                                                <span>
                                                    {Math.round(
                                                        this.props.ratesCalculator.calculateAllIn(offer.inlandChargeSet).totalUsd
                                                        / this.props.currencies[this.props.currencyId].value)}
                                                </span>
                                                <span> </span>
                                                <span>{this.props.currencies[this.props.currencyId].code}</span>
                                            </div>
                                        </div>
                                    );
                                }
                            },
                        ]
                        : this.props.inlandCriteria.inlandCriteriaSizeTypes
                            .filter(cst => cst.number > 0)
                            .map(cst =>
                            ({
                                header: <div
                                    style={{ fontWeight: "bold", cursor: "pointer", minWidth: 100 }}
                                    onClick={(e) => {
                                    }}>
                                    Total {this.props.sizeTypes[cst.sizeTypeId].name}
                                </div>,
                                id: 'totalFrom-' + cst.sizeTypeId,
                                accessor: (d) => d,
                                cell: (d: any) => {
                                    let offer = d as Api.InlandCarrierOfferModel;
                                    return (
                                        offer.inlandChargeSet &&
                                        <div style={{ color: Colors.colors.main3.color }}>
                                            <div style={{ fontWeight: "bold" }}>
                                                <span>
                                                    {Math.round(
                                                        this.props.ratesCalculator.calculatePrice(
                                                            this.props.ratesCalculator.findChargesToApply(offer.inlandChargeSet, cst, undefined),
                                                            this.props.sizeTypes[cst.sizeTypeId].teu).totalUsd
                                                        / this.props.currencies[this.props.currencyId].value)}
                                                </span>
                                                <span> </span>
                                                <span>{this.props.currencies[this.props.currencyId].code}</span>
                                            </div>
                                        </div>
                                    );
                                }
                            })),
                )
                    .concat(
                        [{
                            header: <div
                                style={{ fontWeight: "bold" }}>
                                Applies to
                            </div>,
                            id: 'appliesto',
                            accessor: (d) => d.inlandChargeSet,
                            cell: (d: any) => {
                                let inlandChargeSet = d as Api.InlandChargeSetModel;
                                return (
                                    <div style={{ whiteSpace: "pre-wrap" }}>
                                        {getAllpliesTo(inlandChargeSet, this.props.inlandCriteria, this.props.sizeTypes)}
                                    </div>
                                );
                            }
                        },
                        {
                            header: <div
                                style={{ fontWeight: "bold" }}>
                                {getText("SlcInlandOfferPickUpEmpty")}
                            </div>,
                            id: 'pickupEmptyName',
                            align: "center",
                            accessor: (d) => d.inlandChargeSet,
                            cell: (d: any) => {
                                let inlandChargeSet = d as Api.InlandChargeSetModel;
                                return (
                                    <div>
                                        {inlandChargeSet && inlandChargeSet.pickupEmptyName
                                            ? inlandChargeSet.pickupEmptyName
                                            : ""}
                                    </div>
                                );
                            }
                        },
                        {
                            header: <div
                                style={{ fontWeight: "bold" }}>
                                Validity
                            </div>,
                            id: 'validity',
                            accessor: (d) => d.inlandChargeSet,
                            cell: (d: any) => {
                                let inlandChargeSet = d as Api.InlandChargeSetModel;
                                return (
                                    <div style={{}}>
                                        {inlandChargeSet
                                            && <span>
                                                from{" "}
                                                <span style={{ fontWeight: "bold" }}>{Moment(inlandChargeSet.dateBegin).format("DD/MM/YYYY")}</span>
                                                to{" "}
                                                <span style={{ fontWeight: "bold" }}>{Moment(inlandChargeSet.dateEnd).format("DD/MM/YYYY")}</span>
                                            </span>}
                                    </div>
                                );
                            }
                        },
                        ].filter(x => x)) as Array<OfferColumn>}
                showPagination={false}
                onBodyTrClick={(carrierOffer: Api.CarrierOfferModel) => {
                    if (carrierOffer) {
                        //this.props.handleOfferClick(getInlandOfferKey(carrierOffer));
                        this.props.onOfferClick(getInlandOfferKey(carrierOffer))
                    }
                }}
                data={this.props.inlandCarrierOffers} />
        </div>
    }
}