import * as React from 'react';

interface LoaderProps {
    isVisible: boolean;
    top?: number;
    size?: number;
    style?: React.CSSProperties;
    messageStyle?: React.CSSProperties;
    hideBackground?: boolean;
    message?: string;
}
interface LoaderState {
}

export default class Loader extends React.Component<LoaderProps, LoaderState> {
    constructor(props) {
        super(props)
    }

    get svgStyle(): React.CSSProperties {
        return {
            marginTop: this.props.top || 0,
            width: this.size,
            height: this.size,
            flex: "0 0 auto",
            ...this.props.style
        };
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: LoaderProps) {
    }

    get visibleProps(): React.CSSProperties {
        return this.props.hideBackground
            ? {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }
            : {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

                backgroundColor: "rgba(113, 112, 110, 0.5)",
                zIndex: 999999
        }
    }

    get hiddenProps(): React.CSSProperties {
        return {
            display: 'none'
        }
    }

    get size(): number {
        return this.props.size || 120;
    }

    render() {
        return <div style={this.props.isVisible
            ? this.visibleProps
            : this.hiddenProps}>
            {this.props.isVisible
                && <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        <img style={this.svgStyle} src={"/images/loader.svg"} />
                        {this.props.message
                            && <div style={{
                                flex: "0 0 auto",
                                marginLeft: 6,
                                ...this.props.messageStyle
                            }}>{this.props.message}</div>}
                </div>
                {this.props.children}
                </div>}
        </div>
    }
}