import * as React from 'react';
import Tooltip from "./Tooltip";
import { getText } from '../utils/langTexts';
import * as Api from '../api/api';
import { Warning } from '@material-ui/icons';
import * as Colors from '../styles/colors';

interface OfferRatesNameProps {
    ratesOffer: Api.RatesOfferModel;
    chargeSet: Api.ChargeSetModel;
    criteria: Api.CriteriaModel;
    offerInformations: Array<Api.OfferInformationModel>;
    subscriptionType: Api.SubscriptionModelSubscriptionTypeEnum;
    hideWarning?: boolean;
    iconStyle?: React.CSSProperties;
    focused: boolean;
}

interface InformationWarningProps {
    offerInformations: Array<Api.OfferInformationModel>;
    visible: boolean;
    iconStyle?: React.CSSProperties;
}

class InformationWarning extends React.Component<InformationWarningProps, {}> {
    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: "0 0 auto", padding: "0px 6px" }}>
                    {this.props.visible
                        && this.props.offerInformations.some(x => x.level === "High")
                        && <Tooltip
                            trigger={this.props.visible && this.props.offerInformations.some(x => x.level === "High") ? ["hover"] : []}
                            overlay={<div>{this.props.offerInformations.some(x => x.level === "High")
                                ? getText("SlcOfferRatesInfoWarning")
                                : ""}</div>}>
                            <div style={{ order: 2, flex: "0" }}>
                                <Warning style={{
                                height: 18,
                                color: Colors.baseColors.lightBlue,
                                ...this.props.iconStyle
                                }} />
                            </div>
                        </Tooltip>}
                    </div>
                <div style={{ flex: 2, textAlign: "left", marginLeft: 6 }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const ratesNameMaxLength = 20;

export default class OfferRatesName extends React.Component<OfferRatesNameProps, {}> {
    constructor(props) {
        super(props);
    }

    get ratesName(): string {
        let chargeSetRef = this.props.chargeSet?.carrierReference;
        let isFak = (!this.props.ratesOffer || (this.props.ratesOffer.type === "Fak" && !this.props.ratesOffer.nac));
        let ratesName = isFak
                ? getText("GenFak")
                : (this.props.ratesOffer?.shortName 
                || chargeSetRef 
                || (this.props.ratesOffer?.references 
                    ? this.props.ratesOffer?.references.map(r => r.value).join(',')
                    : "") || "");

        return ratesName.substring(0, ratesNameMaxLength)
            + (ratesName.length > ratesNameMaxLength ? "..." : "");
    }

    public render() {

        let isDoor = this.props.chargeSet
            && (this.props.chargeSet.flags.some(x => x == "PodDoor" || x == "PolDoor"));

        let iconPath = !this.props.focused ? "/images/iconDoor.png" : "/images/iconDoorWhite.png";

        return <div style={{  }}>
            <InformationWarning
                iconStyle={this.props.iconStyle}
                offerInformations={this.props.offerInformations}
                visible={this.props.subscriptionType !== "Agent" && !this.props.hideWarning}>
                <div style={{ fontWeight: 500 }}>
                    {isDoor && <img src={iconPath} height={14} />} {this.ratesName}
                </div>
            </InformationWarning>
        </div>
    }
}