import { fetch } from 'domain-task';
import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import { reset, change, SubmissionError } from 'redux-form';
import * as Api from '../api/api';
import Moment from 'moment';
import { AppThunkAction, ApplicationState } from './';
import { push } from 'connected-react-router';
import * as CriteriaUtils from '../utils/criteriaUtils';
import { getDefaultHeaders, getSubscription, getPort, getQuotationLink } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { ReceiveCurrentUser, ReceiveEditQuotationClientSettings } from './Account';
import Download from "downloadjs";
import * as MimeTypes from "mime-types";
import { getText } from '../utils/langTexts';
import { SelectionGoToQuotation } from './Selection';
import * as _ from 'lodash';
import { quotationInformation } from '../components/QuotationInformationForm';
import { RatesCalculator } from '../utils/ratesUtils';
import { ReceiveQuotationSettings } from './QuotationSettings';

const feeTypeBl: Array<Api.QuotationFeeModelTypeEnum> = [
    "Bl", "BlDestination", "BlFreight",
    "BlOrigin", "BlPostTransport", "BlPreTransport"
];

export interface QuotationState {
    step: number;
    requestTime: number;
    isLoading: boolean;
    isLoaded: boolean;
    enterSelectionTime?: number;
    loadedSelectionTime?: number;
    templateState: QuotationTemplateState;
    criteria: Api.CriteriaModel;
    criteriaLoaded?: Api.CriteriaModel;
    carrierOffer: Api.CarrierOfferModel;
    departId: number;
    mode: QuotationMode;
    downloadDocumentState: RequestState;
    referenceState: RequestState;
    createState: RequestState;
    downloadState: RequestState;
    uploadDocumentsState: RequestState;
    quotation: Api.QuotationModel;
    quotationTemplates: Array<Api.QuotationModel>;
    loadedTemplateId?: number;
    clientMoreOptions: boolean;
    exchangeRatesDate?: Date;
    marginCurrencyId: number;
    feeStates: { [index: number]: QuotationFeeState };
    transferState: {
        isLoading: boolean;
        requestTime?: number;
    }
}

export interface QuotationFeeState {
    mode: QuotationFeeMode,
    sizeTypesMode: { [id: number]: QuotationFeeMode }
}

export interface RequestState {
    isLoading: boolean;
    requestTime?: number;
}

export interface QuotationInformationModel {
    name: string;
    ffContactId: number;
    clientContact: Api.QuotationContactModel;
    reference: string;
    originInfo: string;
    destinationInfo: string;
    validityBegin: Date;
    validityEnd: Date;
    cargoDesc: string;
    weight: string;
    incoterm: string;
    showCarrier: boolean;
    minTransitTime: number;
    maxTransitTime: number;
    transShipments: string;
    frequency: Api.QuotationModelFrequencyEnum;
    incotermLocation: string;
    serviceName: string;
}

interface QuotationRatesModel {
    name: string;
    additionalInfo: string;
    terms: string;
    detailsLevel: Api.QuotationModelDetailsLevelEnum;
}

interface QuotationDataModel {
    criteria?: Api.CriteriaModel;
    quotation?: Api.QuotationModel;
    carrierOffer?: Api.CarrierOfferModel;
    departId?: number;
    quotationTemplates: Array<Api.QuotationModel>;
}

interface QuotationTemplateState {
    isOpen: boolean;
    previewIsOpen: boolean;
    previewQuotationId?: number;
    listingIsOpen: boolean;
    isLoading: boolean;
    requestTime?: number;
    templateStates: { [id: number]: RequestState };
}

export interface QuotationCreateTemplateModel {
    name: string;
    mode: "Create" | "Update";
    quotationFees: { [index: number]: boolean };
    quotationCharges: { [index: number]: boolean };
    quotationTemplateSettings: Api.QuotationTemplateSettingsModel;
}

export type QuotationMode = "Normal" | "Duplicate" | "Update";
export type QuotationFeeMode = "Normal" | "Label";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface QuotationNextStep { type: "QUOTATION_NEXT_STEP" }
interface QuotationPrevStep { type: "QUOTATION_PREV_STEP" }

interface SetQuotationInformation {
    type: "SET_QUOTATION_INFORMATION",
    payload: {
        value: QuotationInformationModel;
        pol: Api.LocationModel;
        pod: Api.LocationModel;
    }
}
interface QuotationLoadTemplate {
    type: "QUOTATION_LOAD_TEMPLATE",
    payload: {
        quotationId: number;
        sizeTypes: { [id: number]: Api.SizeTypeModel };
    }
}

interface RequestNewQuotationReference { type: 'REQUEST_NEW_QUOTATION_REFERENCE'; requestTime: number }
interface ReceiveNewQuotationReference { type: 'RECEIVE_NEW_QUOTATION_REFERENCE'; requestTime: number; reference: string; carrierName: string }

interface RequestCreateQuotation { type: 'REQUEST_CREATE_QUOTATION'; requestTime: number }
interface ReceiveCreateQuotation { type: 'RECEIVE_CREATE_QUOTATION'; requestTime: number; quotation: Api.QuotationModel }

interface RequestCreateQuotationTemplate { type: 'REQUEST_CREATE_QUOTATION_TEMPLATE'; payload: { requestTime: number } }
interface ReceiveCreateQuotationTemplate { type: 'RECEIVE_CREATE_QUOTATION_TEMPLATE'; payload: { requestTime: number; quotation: Api.QuotationModel }; error?: any }

interface RequestTransferQuotation { type: 'REQUEST_TRANSFER_QUOTATION'; payload: { requestTime: number } }
interface ReceiveTransferQuotation { type: 'RECEIVE_TRANSFER_QUOTATION'; payload: { requestTime: number }; error?: any }

interface RequestDownloadQuotation { type: 'REQUEST_DOWNLOAD_QUOTATION'; requestTime: number }
interface ReceiveDownloaQuotation { type: 'RECEIVE_DOWNLOAD_QUOTATION'; requestTime: number }

interface RequestQuotationData {
    type: 'QUOTATION_REQUEST_QUOTATION_DATA'; payload: {
        requestTime: number;
        mode: QuotationMode;
    }
}
interface ReceiveQuotationData {
    type: 'QUOTATION_RECEIVE_QUOTATION_DATA';
    payload: {
        requestTime: number;
        quotationData: QuotationDataModel;
        quotationCharges: Array<Api.QuotationChargeModel>;
        quotationFees: Array<Api.QuotationFeeModel>;
        quotationCurrencies: Array<Api.QuotationCurrencyModel>;
    },
    error?: any;
}

interface QuotationAddFee { type: "QUOTATION_ADD_FEE"; feeType: Api.QuotationFeeModelTypeEnum }
interface QuotationRemoveFee { type: "QUOTATION_REMOVE_FEE"; index: number }
interface QuotationUpdateFeeName { type: "QUOTATION_UPDATE_FEE_NAME"; index: number; value: string }
interface QuotationUpdateFeeUnit { type: "QUOTATION_UPDATE_FEE_UNIT"; index: number; value: string }

interface QuotationUpdateFeeMode { type: "QUOTATION_UPDATE_FEE_MODE"; payload: { index: number; value: QuotationFeeMode; sizeTypeId?: number } }
interface QuotationUpdateFeeLabel { type: "QUOTATION_UPDATE_FEE_LABEL"; payload: { index: number; value: string; sizeTypeId?: number } }

interface QuotationUpdateChargeAmount {
    type: "QUOTATION_UPDATE_CHARGE_AMOUNT";
    payload: {
        chargeNameId: number;
        application?: Api.ChargeModelApplicationEnum;
        sizeTypeId?: number;
        value: number,
        originalChargeId: number,
        level: number
    }
}

interface QuotationUpdateFeeBaseAmount { type: "QUOTATION_UPDATE_FEE_BASEAMOUNT"; index: number; value: number; sizeTypeId?: number }
interface QuotationUpdateFeeAmount { type: "QUOTATION_UPDATE_FEE_AMOUNT"; index: number; value: number; sizeTypeId?: number }
interface QuotationSelectFeeCurrency { type: "QUOTATION_UPDATE_FEE_CURRENCY"; index: number; currencyId: number; sizeTypeId?: number }

interface QuotationSelectFee {
    type: "QUOTATION_SELECT_FEE";
    payload: { index: number; selected: boolean }
}
interface QuotationSelectCharge {
    type: "QUOTATION_SELECT_CHARGE";
    payload: {
        chargeNameId: number;
        application?: Api.ChargeModelApplicationEnum;
        selected: boolean;
        level: number;
        unit: Api.ChargeModelUnitEnum
    }
}

interface QuotationSelectLanguage { type: "QUOTATION_SELECT_LANGUAGE"; languageId: number }
interface QuotationSelectTotalType { type: "QUOTATION_SELECT_TOTALTYPE"; value: Api.QuotationModelTotalTypeEnum }
interface QuotationSelectAllInCurrency { type: "QUOTATION_SELECT_ALLIN_CURRENCY"; currencyId: number }
interface QuotationSelectDetailsLevel { type: "QUOTATION_SELECT_DETAILS_LEVEL"; value: Api.QuotationModelDetailsLevelEnum }
interface QuotationUpdateShowExchangeRates { type: "QUOTATION_UPDATE_SHOWEXCHANGERATES"; payload: { value: boolean } }
interface QuotationUpdateAdditionalInfo { type: "QUOTATION_UPDATE_ADDITIONAL_INFO"; value: string }
interface QuotationUpdateTerm { type: "QUOTATION_UPDATE_TERM"; value: string }
interface QuotationUpdateCurrencyValue { type: "QUOTATION_UPDATE_CURRENCY_VALUE"; payload: { index: number; value: number } }
interface QuotationOpenTemplateDialog { type: "QUOTATION_OPEN_TEMPLATE_DIALOG"; }
interface QuotationCloseTemplateDialog { type: "QUOTATION_CLOSE_TEMPLATE_DIALOG"; }
interface QuotationOpenClientMoreOptions { type: "QUOTATION_OPEN_CLIENT_MORE_OPTIONS"; }
interface QuotationCloseClientMoreOptions { type: "QUOTATION_CLOSE_CLIENT_MORE_OPTIONS"; }

interface QuotationOpenTemplateListing { type: "QUOTATION_OPEN_TEMPLATE_LISTING"; }
interface QuotationCloseTemplateListing { type: "QUOTATION_CLOSE_TEMPLATE_LISTING"; }

interface QuotationRequestDownloadDocument { type: "QUOTATION_REQUEST_DOWNLOAD_DOCUMENT"; payload: { requestTime: number; } }
interface QuotationReceiveDownloadDocument { type: "QUOTATION_RECEIVE_DOWNLOAD_DOCUMENT"; payload: { requestTime: number; } }

interface QuotationRequestDeleteTemplate { type: "QUOTATION_REQUEST_DELETE_TEMPLATE"; payload: { requestTime: number; id: number; } }
interface QuotationReceiveDeleteTemplate { type: "QUOTATION_RECEIVE_DELETE_TEMPLATE"; payload: { requestTime: number; id: number; }; error?: any }

interface QuotationUpdateCriteriaSizeType { type: "QUOTATION_UPDATE_CRITERIA_SIZETYPE"; payload: { value: number; sizeTypeId: number; } }
interface QuotationUpdateCriteria { type: "QUOTATION_UPDATE_CRITERIA"; payload: { value: Api.CriteriaModel; } }

interface QuotationUpdateName { type: "QUOTATION_UPDATE_NAME"; payload: { value: string } }
export interface QuotationSelectContactClient { type: "QUOTATION_SELECT_CONTACT_CLIENT"; payload: { value: Api.QuotationContactModel } }
interface QuotationLoadDuplicate { type: "QUOTATION_LOAD_DUPLICATE"; payload: { quotation: Api.QuotationModel } };

interface QuotationSelectTemplatePreview { type: "QUOTATION_SELECT_TEMPLATE_PREVIEW", payload: { id: number; } }
interface QuotationCloseTemplatePreview { type: "QUOTATION_CLOSE_TEMPLATE_PREVIEW" }
interface QuotationUpdateByContainer { type: "QUOTATION_UPDATE_BY_CONTAINER"; payload: { value: boolean; } }
interface QuotationUpdateCurrencyDisplayType {
    type: "QUOTATION_UPDATE_CURRENCY_DISPLAYTYPE";
    payload: { index: number; value: Api.QuotationCurrencyModelDisplayTypeEnum; }
}
interface QuotationUpdateFobOrigin { type: "QUOTATION_UPDATE_FOBORIGIN", payload: { value: string } };
interface QuotationUpdateFobDestination { type: "QUOTATION_UPDATE_FOBDESTINATION", payload: { value: string } };
interface QuotationUpdateFrtOrigin { type: "QUOTATION_UPDATE_FRTORIGIN", payload: { value: string } };
interface QuotationUpdateFrtDestination { type: "QUOTATION_UPDATE_FRTDESTINATION", payload: { value: string } };
interface QuotationUpdateDestinationOrigin { type: "QUOTATION_UPDATE_DESTINATIONORIGIN", payload: { value: string } };
interface QuotationUpdateDestinationDestination { type: "QUOTATION_UPDATE_DESTINATIONDESTINATION", payload: { value: string } };
interface QuotationUseInlandChargeSet {
    type: "QUOTATION_USE_INLAND_CHARGESET",
    payload: {
        value: Api.ChargeSetModel,
        chargeNames: { [id: number]: Api.ChargeNameModel },
        sizeTypes: { [id: number]: Api.SizeTypeModel },
        currencies: { [id: number]: Api.CurrencyModel },
        application: Api.ChargeModelApplicationEnum;
    }
};
interface QuotationRequestUpdateDocuments {
    type: "QUOTATION_REQUEST_UPDATE_DOCUMENTS";
    payload: { requestTime: number };
};
interface QuotationReceiveUpdateDocuments {
    type: "QUOTATION_RECEIVE_UPDATE_DOCUMENTS";
    payload: {
        requestTime: number;
        quotationDocuments: Array<Api.QuotationDocumentModel>
    };
    error?: any;
};
interface QuotationUpdateMarginCurrencyId { type: "QUOTATION_UPDATE_MARGIN_CURRENCYID", payload: { value: number } };

interface QuotationAddQuotationFeeLabel {
    type: "QUOTATION_ADD_QUOTATIONFEELABEL";
    payload: {
        index: number;
    };
};
interface QuotationRemoveQuotationFeeLabel {
    type: "QUOTATION_REMOVE_QUOTATIONFEELABEL";
    payload: {
        index: number;
        feeIndex: number;
    };
};
interface QuotationUpdateQuotationFeeLabel {
    type: "QUOTATION_UPDATE_QUOTATIONFEELABEL";
    payload: {
        index: number;
        value: string;
        feeIndex: number;
    };
};

type KnownAction = ReceiveCurrentUser
    | RequestNewQuotationReference | ReceiveNewQuotationReference
    | RequestCreateQuotation | ReceiveCreateQuotation
    | QuotationNextStep | QuotationPrevStep
    | SetQuotationInformation | RequestQuotationData
    | ReceiveQuotationData | QuotationAddFee
    | QuotationRemoveFee
    | QuotationUpdateChargeAmount
    | QuotationUpdateFeeBaseAmount
    | QuotationUpdateFeeAmount
    | QuotationSelectFeeCurrency
    | QuotationSelectFee
    | QuotationSelectCharge
    | QuotationUpdateFeeName
    | QuotationSelectLanguage
    | QuotationSelectTotalType
    | QuotationSelectAllInCurrency
    | QuotationSelectDetailsLevel
    | QuotationUpdateAdditionalInfo
    | QuotationUpdateTerm
    | RequestDownloadQuotation
    | ReceiveDownloaQuotation
    | RequestCreateQuotationTemplate
    | ReceiveCreateQuotationTemplate
    | QuotationLoadTemplate
    | QuotationUpdateCurrencyValue
    | QuotationOpenTemplateDialog
    | QuotationCloseTemplateDialog
    | QuotationOpenClientMoreOptions
    | QuotationCloseClientMoreOptions
    | SelectionGoToQuotation
    | QuotationOpenTemplateListing
    | QuotationCloseTemplateListing
    | QuotationRequestDeleteTemplate
    | QuotationReceiveDeleteTemplate
    | QuotationUpdateName
    | QuotationUpdateFeeUnit
    | QuotationUpdateShowExchangeRates
    | QuotationRequestDownloadDocument
    | QuotationReceiveDownloadDocument
    | QuotationUpdateCriteriaSizeType
    | QuotationUpdateFeeMode
    | QuotationUpdateFeeLabel
    | QuotationUpdateCriteria
    | QuotationSelectContactClient
    | QuotationLoadDuplicate
    | QuotationSelectTemplatePreview
    | QuotationCloseTemplatePreview
    | ReceiveEditQuotationClientSettings
    | ReceiveCurrentUser
    | QuotationUpdateByContainer
    | QuotationUpdateCurrencyDisplayType
    | QuotationUpdateFobOrigin
    | QuotationUpdateFobDestination
    | QuotationUpdateFrtOrigin
    | QuotationUpdateFrtDestination
    | QuotationUpdateDestinationOrigin
    | QuotationUpdateDestinationDestination
    | QuotationUseInlandChargeSet
    | QuotationRequestUpdateDocuments
    | QuotationReceiveUpdateDocuments
    | QuotationUpdateMarginCurrencyId
    | ReceiveQuotationSettings
    | QuotationAddQuotationFeeLabel
    | QuotationUpdateQuotationFeeLabel
    | QuotationRemoveQuotationFeeLabel
    | RequestTransferQuotation
    | ReceiveTransferQuotation
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const downloadQuotationTask = (requestTime: number, quotationId: number, dispatch: (action: any) => void, getState: () => ApplicationState) => {
    let api = new Api.QuotationApi();
    let quotation = getState().quotationChrono.quotations.find(x => x.quotationId === quotationId);
    let fetchTask = api.download(
        {
            quotationId: quotationId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(response => response.blob())
        .then(blob => {
            dispatch({
                type: "QUOTATIONS_RECEIVE_DOWNLOAD",
                payload: { requestTime: requestTime, quotationId: quotationId }
            });
            let fileName = (quotation ? quotation.name : "Quotation") + ".zip";
            return Download(blob,
                fileName,
                MimeTypes.lookup(fileName) || "text/plain");
        })
        .catch(error => {
            dispatch({ type: "QUOTATIONS_RECEIVE_DOWNLOAD", payload: { requestTime: requestTime, quotationId: quotationId }, error: error });
            dispatch(Notifications.error({ message: "Error downloading your quotation", title: "Error", position: "tc" }) as any);
        });

    dispatch({ type: "QUOTATIONS_REQUEST_DOWNLOAD", payload: { quotationId: quotationId, requestTime: requestTime } });
    return fetchTask;
}

export const actionCreators = {
    requestQuotationData: (requestTime: number, criteriaCode: string, chargeSetId: number, departId: number, quotationId?: number, routeId?: number, mode?: QuotationMode)
        : AppThunkAction<KnownAction, Promise<boolean>> => (dispatch, getState) => {
            if (getState().quotation.requestTime === requestTime)
                return Promise.resolve<boolean>(false);

            let quotationSettings = getState().account.currentUser.clientModel.subscriptions
                .filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.quotationSettingses
                    .concat(x.agency.company.quotationSettingses)
                    .concat(x.agency.company.companyGroup
                        ? x.agency.company.companyGroup.quotationSettingses
                        : []))
                .reduce((a, b) => a.concat(b))[0];

            let quotationData: QuotationDataModel = {
                departId: departId,
                quotation: quotationSettings
                    ? {
                        addInfo: quotationSettings.defaultAdditionalInfo,
                        terms: quotationSettings.defaultTerms
                    } : {},
                quotationTemplates: []
            };

            let criteriaApi = new Api.CriteriaApi();
            let fecthCriteriaTask = criteriaApi.getCriteria({ code: criteriaCode },
                { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(criteria => {
                    quotationData.criteria = criteria;
                }).catch(error => {
                    dispatch(Notifications.error({ message: "Error loading criteria for quotation", title: "Error", position: "tc" }) as any);
                });

            let selectionApi = new Api.SelectionApi();
            let fetchOfferTask = selectionApi.getCarrierOffer({ departId: departId, chargeSetId: chargeSetId, criteriaCode: criteriaCode, routeId: routeId },
                { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(carrierOffer => {
                    quotationData.carrierOffer = carrierOffer;
                }).catch(error => {
                    dispatch(Notifications.error({ message: "Error loading carrier offer for quotation", title: "Error", position: "tc" }) as any);
                });

            let quotationApi = new Api.QuotationApi();
            let fetchQuotationTask = quotationId
                ? quotationApi.getQuotation({
                    quotationId: quotationId
                }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                    .then(quotation => {
                        quotationData.quotation = {
                            ...quotation,
                            previousVersionId: mode === "Update"
                                ? quotationId : undefined
                        };
                    })
                    .catch(error => {
                        dispatch(Notifications.error({ message: "Error loading quotation data", title: "Error", position: "tc" }) as any);
                    })
                : Promise.resolve();

            let fetchTemplatesTask = quotationApi.getQuotationTemplates(
                { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(quotationTemplates => {
                    quotationData.quotationTemplates = quotationTemplates;
                })
                .catch(error => {
                    dispatch(Notifications.error({ message: "Error loading templates", title: "Error", position: "tc" }) as any);
                });

            let fetchTask = Promise.all([fecthCriteriaTask, fetchOfferTask, fetchQuotationTask, fetchTemplatesTask]).then(() => {
                //Unselect included if by default
                if (!quotationId) {
                    let quotationSettings = getState().account.currentUser.clientModel.quotationClientSettings;
                    quotationData.quotation = {
                        ...quotationData.quotation,
                        quotationCharges: quotationData.carrierOffer.chargeSet
                            ? quotationData.carrierOffer.chargeSet.charges
                                .filter(x => x.chargeType === "Source" && x.type === "Incl")
                                .map(x => ({
                                    chargeNameId: x.chargeNameId,
                                    application: x.application,
                                    visible: quotationSettings
                                        && quotationSettings.hideIncluded
                                        ? false : true,
                                    quotationChargeAmounts: []
                                } as Api.QuotationChargeModel))
                            : []
                    };
                }

                dispatch(reset(quotationInformation) as any);
                let ratesCalculator = new RatesCalculator(getState().seed.currencies, getState().seed.sizeTypes, quotationData.criteria);
                dispatch({
                    type: "QUOTATION_RECEIVE_QUOTATION_DATA",
                    payload: {
                        quotationData: quotationData,
                        requestTime: requestTime,
                        quotationCharges: !quotationData.carrierOffer.chargeSet
                            ? []
                            : quotationData.carrierOffer.chargeSet.charges
                                .filter(x => x.chargeType === "Source" && x.type === "Percentage")
                                .map(x => ({
                                    ...unloadedQuotationCharge,
                                    application: x.application,
                                    chargeNameId: x.chargeNameId,
                                    quotationChargeAmounts: quotationData.criteria.criteriaSizeTypes
                                        .map(y => {
                                            let source = ratesCalculator.findChargesToApply(
                                                quotationData.carrierOffer.chargeSet,
                                                y,
                                                null).find(z => z.chargeNameId === x.sourceChargeNameId);
                                            return {
                                                sizeTypeId: y.sizeTypeId,
                                                amount: source
                                                    ? Math.round(source.amount * x.amount * 100) / 100
                                                    : 0,
                                                currencyId: source?.currencyId,
                                                level: 0,
                                            } as Api.QuotationChargeAmountModel
                                        })
                                } as Api.QuotationChargeModel)),
                        quotationFees: [],
                        quotationCurrencies: _.uniq((quotationData.carrierOffer.chargeSet
                            ? quotationData.carrierOffer.chargeSet.charges
                                .filter(x => x.chargeType === "Value")
                                .map(x => x.currencyId)
                            : [])
                            .concat([
                                getPort(quotationData.criteria.origin).country.currencyId,
                                getPort(quotationData.criteria.destination).country.currencyId,
                            ])
                            .filter(x => !getState().seed.currencies[x].primary))
                            .map(x => ({
                                currencyId: x,
                                value: getState().seed.currencies[x].value
                            } as Api.QuotationCurrencyModel))
                            .concat(_.values(getState().seed.currencies)
                                .filter(x => x.primary && x.code !== "USD")
                                .map(x => ({
                                    currencyId: x.currencyId,
                                    value: x.value,
                                    displayType: "ToUsd"
                                } as Api.QuotationCurrencyModel)))
                    }
                });
                return true;
            }).catch(error => {
                dispatch({
                    type: "QUOTATION_RECEIVE_QUOTATION_DATA",
                    payload: {
                        quotationData: null,
                        requestTime: requestTime,
                        quotationCurrencies: [],
                        quotationCharges: [],
                        quotationFees: []
                    },
                    error: error
                });
                dispatch(Notifications.error({ message: "Failed to load quotation, please contact our support", title: "Error", position: "tc" }) as any);
                return false;
            });

            dispatch({ type: "QUOTATION_REQUEST_QUOTATION_DATA", payload: { requestTime: requestTime, mode: mode || "Normal" } });
            addTask(fetchTask);
            return fetchTask;
        },
    requestCreateQuotation: (requestTime: number, mode: "Send" | "DownloadExcel" | "DownloadPdf"): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let quotationApi = new Api.QuotationApi();
        let fetchTask = CriteriaUtils.getCriteria(getState, getState().quotation.criteria, getState().quotation.criteriaLoaded)
            .then(criteria => {
                if (getState().quotation.criteriaLoaded.code !== criteria.code) {
                    dispatch({ type: "QUOTATION_UPDATE_CRITERIA", payload: { value: criteria } });
                    let chargeSet = getState().quotation.carrierOffer.chargeSet;
                    dispatch(push('/quotation/'
                        + criteria.code + '/'
                        + getState().quotation.departId + '/'
                        + (chargeSet ? chargeSet.chargeSetId : "null") + '/'
                        + getState().quotation.mode) as any);
                }

                return quotationApi.createQuotation({
                    model: {
                        ...getState().quotation.quotation,
                        subscriptionId: getState().account.currentUser.clientModel.subscriptionId,
                        quotationOffers: [{
                            carrierOffer: getState().quotation.carrierOffer,
                            departId: getState().quotation.departId,
                            routeId: getState().quotation.carrierOffer.routeId
                        }]
                    },
                    criteriaCode: criteria.code
                }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                    .then(quotation => {
                        let reqTime = new Date().getTime();
                        dispatch({ type: "REQUEST_TRANSFER_QUOTATION", payload: { requestTime: reqTime } })
                        switch (mode) {
                            case "Send":
                                return quotationApi.sendQuotationEmail({ quotationId: quotation.quotationId },
                                    { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                                    .then(() => {
                                        dispatch({ type: "RECEIVE_CREATE_QUOTATION", requestTime: requestTime, quotation: quotation });
                                        dispatch({ type: "RECEIVE_TRANSFER_QUOTATION", payload: { requestTime: reqTime } });
                                        dispatch(Notifications.success({
                                            message: "Your quotation has been created, you will receive it by email shortly",
                                            title: "Success", position: "tc"
                                        }) as any);
                                        dispatch(push("/") as any);
                                    })
                                    .catch(err => {
                                        dispatch({ type: "RECEIVE_CREATE_QUOTATION", requestTime: requestTime, quotation: quotation });
                                        dispatch({ type: "RECEIVE_TRANSFER_QUOTATION", payload: { requestTime: reqTime }, error: err });
                                        dispatch(Notifications.error({
                                            message: "Your quotation could not be sent, you can download the files fron the quotation chrono page",
                                            title: "Error", position: "tc"
                                        }) as any);
                                        dispatch(push("/") as any);
                                    });
                            case "DownloadExcel":
                                return quotationApi.downloadQuotationExcel({ quotationId: quotation.quotationId },
                                    { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                                    .then(response => response.blob())
                                    .then(blob => {
                                        dispatch({ type: "RECEIVE_CREATE_QUOTATION", requestTime: requestTime, quotation: quotation });
                                        dispatch({
                                            type: "RECEIVE_TRANSFER_QUOTATION",
                                            payload: { requestTime: requestTime }
                                        });
                                        let fileName = (quotation ? quotation.name : "Quotation") + ".xlsx";
                                        Download(blob,
                                            fileName,
                                            MimeTypes.lookup(fileName) || "text/plain");
                                        dispatch(push("/") as any);
                                    })
                                    .catch(err => {
                                        dispatch({ type: "RECEIVE_CREATE_QUOTATION", requestTime: requestTime, quotation: quotation });
                                        dispatch({
                                            type: "RECEIVE_TRANSFER_QUOTATION",
                                            payload: { requestTime: requestTime },
                                            error: err
                                        });
                                        dispatch(Notifications.error({ message: "Error downloading your quotation", title: "Error", position: "tc" }) as any);
                                        dispatch(push("/") as any);
                                    });
                            case "DownloadPdf":
                                return quotationApi.downloadQuotationPdf({ quotationId: quotation.quotationId },
                                    { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                                    .then(response => response.blob())
                                    .then(blob => {
                                        dispatch({ type: "RECEIVE_CREATE_QUOTATION", requestTime: requestTime, quotation: quotation });
                                        dispatch({
                                            type: "RECEIVE_TRANSFER_QUOTATION",
                                            payload: { requestTime: requestTime }
                                        });
                                        let fileName = (quotation ? quotation.name : "Quotation") + ".xlsx";
                                        Download(blob,
                                            fileName,
                                            MimeTypes.lookup(fileName) || "text/plain");
                                        dispatch(push("/") as any);
                                    })
                                    .catch(err => {
                                        dispatch({ type: "RECEIVE_CREATE_QUOTATION", requestTime: requestTime, quotation: quotation });
                                        dispatch({
                                            type: "RECEIVE_TRANSFER_QUOTATION",
                                            payload: { requestTime: requestTime },
                                            error: err
                                        });
                                        dispatch(Notifications.error({ message: "Error downloading your quotation", title: "Error", position: "tc" }) as any);
                                        dispatch(push("/") as any);
                                    });
                        }
                    })
                    .catch(err => {
                        throw err;
                    });
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_CREATE_QUOTATION", requestTime: requestTime, quotation: null });
                dispatch(Notifications.error({
                    message: "Failed to create new quotation, if the problem persist contact our support",
                    title: "Error", position: "tc"
                }) as any);
                console.log(error);
            });

        dispatch({ type: "REQUEST_CREATE_QUOTATION", requestTime: requestTime });
        addTask(fetchTask);
    },
    requestCreateQuotationTemplate: (requestTime: number, model: QuotationCreateTemplateModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().quotation.templateState.requestTime)
            return;

        let quotationApi = new Api.QuotationApi();
        let quotation = getState().quotation.quotation;
        let requestParams = {
            model: {
                quotationCharges: quotation.quotationCharges.filter((x, xi) => model.quotationCharges[xi]),
                quotationFees: quotation.quotationFees.filter((x, xi) => model.quotationFees[xi]),
                quotationCurrencies: model.quotationTemplateSettings.includeExchangeRates ? quotation.quotationCurrencies : [],
                addInfo: model.quotationTemplateSettings.includeAdditionalInfo ? quotation.addInfo : undefined,
                terms: model.quotationTemplateSettings.includeTerms ? quotation.terms : undefined,
                detailsLevel: model.quotationTemplateSettings.includeDetailsLevel ? quotation.detailsLevel : undefined,
                totalType: model.quotationTemplateSettings.includeTotalType ? quotation.totalType : undefined,
                languageId: quotation.languageId,
                name: model.name,
                subscriptionId: getState().account.currentUser.clientModel.subscriptionId,
                currencyId: quotation.currencyId,
                clientContact: quotation.clientContact,
                showExchangeRates: model.quotationTemplateSettings.includeShowExchangeRates
                    ? quotation.showExchangeRates
                    : undefined,
                quotationTemplateSettings: model.quotationTemplateSettings
            },
            criteriaCode: getState().quotation.criteria.code
        };
        let fetchTask = (model.mode === "Create"
            ? quotationApi.createTemplate(requestParams, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            : quotationApi.updateTemplate(requestParams, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }))
            .then(templateResult => {
                dispatch({ type: "RECEIVE_CREATE_QUOTATION_TEMPLATE", payload: { requestTime: requestTime, quotation: templateResult } });
                dispatch(Notifications.success({
                    message: getText("QtnTemplateCreateSuccess"),
                    title: "Success", position: "tc", autoDismiss: 0
                }) as any);
            }).catch(error => {
                dispatch({ type: "RECEIVE_CREATE_QUOTATION_TEMPLATE", payload: { requestTime: requestTime, quotation: null }, error: error });
                dispatch(Notifications.error({
                    message: "Failed to save your template",
                    title: "Error", position: "tc", autoDismiss: 0
                }) as any);
            });

        dispatch({ type: "REQUEST_CREATE_QUOTATION_TEMPLATE", payload: { requestTime: requestTime } });
        addTask(fetchTask);
        return fetchTask;
    },
    requestDownloadQuotation: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let fetchTask = CriteriaUtils.getCriteria(getState, getState().quotation.criteria, getState().quotation.criteriaLoaded)
            .then(criteria => {
                if (getState().quotation.criteriaLoaded.code !== criteria.code) {
                    dispatch({ type: "QUOTATION_UPDATE_CRITERIA", payload: { value: criteria } });
                    let chargeSet = getState().quotation.carrierOffer.chargeSet;
                    dispatch(push('/quotation/'
                        + criteria.code + '/'
                        + getState().quotation.departId + '/'
                        + (chargeSet ? chargeSet.chargeSetId : "null") + '/'
                        + getState().quotation.mode) as any);
                }

                let api = new Api.QuotationApi();
                return api.downloadPreview(
                    {
                        model: {
                            ...getState().quotation.quotation,
                            subscriptionId: getState().account.currentUser.clientModel.subscriptionId,
                            quotationOffers: [{
                                carrierOffer: getState().quotation.carrierOffer,
                                departId: getState().quotation.departId,
                                routeId: getState().quotation.carrierOffer.routeId,
                            }]
                        },
                        criteriaCode: criteria.code
                    },
                    { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                    .then(response => response.blob())
                    .then(blob => {
                        dispatch({ type: "RECEIVE_DOWNLOAD_QUOTATION", requestTime: requestTime });
                        let fileName = "Quotation_Preview_" + Moment().format("YYYY_MM_DD") + ".xlsx";
                        return Download(blob,
                            fileName,
                            MimeTypes.lookup(fileName) || "text/plain");
                    })
                    .catch(error => {
                        dispatch({ type: "RECEIVE_DOWNLOAD_QUOTATION", requestTime: requestTime });
                        dispatch(Notifications.error({ message: "Error downloading your quotation", title: "Error", position: "tc" }) as any);
                    });
            })
            .catch(err => {
                dispatch({ type: "RECEIVE_DOWNLOAD_QUOTATION", requestTime: requestTime });
                dispatch(Notifications.error({ message: "Error downloading your quotation", title: "Error", position: "tc" }) as any);
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_DOWNLOAD_QUOTATION", requestTime: requestTime });
    },
    requestDeleteTemplate: (requestTime: number, id: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let api = new Api.QuotationApi();
        let fetchTask = api.deleteTemplate(
            {
                quotationId: id
            },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({ type: "QUOTATION_RECEIVE_DELETE_TEMPLATE", payload: { requestTime: requestTime, id: id } });
            })
            .catch(error => {
                dispatch({
                    type: "QUOTATION_RECEIVE_DELETE_TEMPLATE",
                    payload: { requestTime: requestTime, id: id },
                    error: error
                });
                dispatch(Notifications.error({ message: "Error downloading your quotation", title: "Error", position: "tc" }) as any);
            });

        addTask(fetchTask);
        dispatch({ type: "QUOTATION_REQUEST_DELETE_TEMPLATE", payload: { requestTime: requestTime, id: id } });
        return fetchTask;
    },
    requestNewQuotationReference: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().quotation.referenceState.requestTime)
            return;

        let carrierName = getState().seed.carriers[getState().quotation.carrierOffer.carrierId].name;
        let quotationApi = new Api.QuotationApi();
        let fetchTask = quotationApi.getNewReference(
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(ref => {
                dispatch({
                    type: "RECEIVE_NEW_QUOTATION_REFERENCE",
                    requestTime: requestTime,
                    reference: ref.result,
                    carrierName: carrierName
                });
            }).catch(error => {
                dispatch({
                    type: "RECEIVE_NEW_QUOTATION_REFERENCE", requestTime: requestTime,
                    reference: "", carrierName: carrierName
                });
                console.log(error);
                dispatch(Notifications.error({ message: "Failed to generate new reference", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "REQUEST_NEW_QUOTATION_REFERENCE", requestTime: requestTime });
        addTask(fetchTask);
    },
    requestDownloadRatesDocument: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (getState().quotation.downloadDocumentState.requestTime === requestTime)
            return Promise.reject("Already did");

        let api = new Api.SelectionApi();
        let fecthTask = api.downloadWorkDocuments({
            chargeSetId: getState().quotation.carrierOffer.chargeSet.chargeSetId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "QUOTATION_RECEIVE_DOWNLOAD_DOCUMENT", payload: { requestTime: requestTime } })
                let fileName = "Okargo_Documents_" + Moment().format("YYYY_MM_DD") + ".zip";
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            })
            .catch(error => {
                dispatch({ type: "QUOTATION_RECEIVE_DOWNLOAD_DOCUMENT", payload: { requestTime: requestTime } })
                dispatch(Notifications.error({ message: "Failed to download your document", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "QUOTATION_REQUEST_DOWNLOAD_DOCUMENT", payload: { requestTime: requestTime } })
        return fecthTask;
    },
    setQuotationInformation: (value: QuotationInformationModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: "SET_QUOTATION_INFORMATION",
            payload: {
                value: value,
                pol: getState().quotation.criteria.origin,
                pod: getState().quotation.criteria.destination,
            }
        });
    },
    quotationNextStep: () => <QuotationNextStep>{ type: "QUOTATION_NEXT_STEP" },
    quotationPrevStep: () => <QuotationPrevStep>{ type: "QUOTATION_PREV_STEP" },
    quotationAddFee: (feeType: Api.QuotationFeeModelTypeEnum) => <QuotationAddFee>{ type: "QUOTATION_ADD_FEE", feeType: feeType },
    quotationRemoveFee: (index: number) => <QuotationRemoveFee>{ type: "QUOTATION_REMOVE_FEE", index: index },
    quotationUpdateMarginCurrencyId: (value: number) => <QuotationUpdateMarginCurrencyId>{
        type: "QUOTATION_UPDATE_MARGIN_CURRENCYID", payload: { value: value }
    },
    quotationLoadTemplate: (quotationId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: "QUOTATION_LOAD_TEMPLATE",
            payload: {
                quotationId: quotationId,
                sizeTypes: getState().seed.sizeTypes
            }
        });
    },
    quotationUpdateFeeName: (index: number, value: string) => <QuotationUpdateFeeName>{
        type: "QUOTATION_UPDATE_FEE_NAME", index: index, value: value
    },
    quotationUpdateFeeUnit: (index: number, value: string) => <QuotationUpdateFeeUnit>{
        type: "QUOTATION_UPDATE_FEE_UNIT", index: index, value: value
    },
    quotationUpdateChargeAmount: (chargeNameId: number, application: Api.ChargeModelApplicationEnum, value: number, lvl: number, originalChargeId: number, sizeTypeId?: number) => <QuotationUpdateChargeAmount>{
        type: "QUOTATION_UPDATE_CHARGE_AMOUNT",
        payload: {
            value: value,
            originalChargeId: originalChargeId,
            chargeNameId: chargeNameId,
            sizeTypeId: sizeTypeId,
            application: application,
            level: lvl
        }
    },
    quotationUpdateFeeBaseAmount: (index: number, value: number, sizeTypeId?: number) => <QuotationUpdateFeeBaseAmount>{
        type: "QUOTATION_UPDATE_FEE_BASEAMOUNT", value: value,
        index: index, sizeTypeId: sizeTypeId
    },
    quotationUpdateFeeAmount: (index: number, value: number, sizeTypeId?: number) => <QuotationUpdateFeeAmount>{
        type: "QUOTATION_UPDATE_FEE_AMOUNT", value: value,
        index: index, sizeTypeId: sizeTypeId
    },
    quotationSelectFeeCurrency: (index: number, currencyId: number, sizeTypeId?: number) => <QuotationSelectFeeCurrency>{
        type: "QUOTATION_UPDATE_FEE_CURRENCY",
        currencyId: currencyId,
        index: index,
        sizeTypeId: sizeTypeId
    },
    quotationUpdateFeeLabel: (index: number, value: string, sizeTypeId?: number) => <QuotationUpdateFeeLabel>{
        type: "QUOTATION_UPDATE_FEE_LABEL",
        payload: {
            value: value,
            index: index, sizeTypeId: sizeTypeId
        }
    },
    quotationUpdateFeeMode: (index: number, value: QuotationFeeMode, sizeTypeId?: number) => <QuotationUpdateFeeMode>{
        type: "QUOTATION_UPDATE_FEE_MODE",
        payload: {
            value: value,
            index: index, sizeTypeId: sizeTypeId
        }
    },
    quotationSelectFee: (index: number, selected: boolean) => <QuotationSelectFee>{
        type: "QUOTATION_SELECT_FEE",
        payload: {
            index: index,
            selected: selected
        }
    },
    quotationSelectCharge: (chargeNameId: number, application: Api.ChargeModelApplicationEnum, selected: boolean, lvl: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {

        dispatch({
            type: "QUOTATION_SELECT_CHARGE",
            payload: {
                chargeNameId: chargeNameId, selected: selected,
                unit: getState().quotation.carrierOffer.chargeSet.charges
                    .find(x => x.chargeNameId === chargeNameId).unit,
                application: application,
                level: lvl
            }
        });
    },
    quotationRequestUploadDocuments: (requestTime: number, inputFile: HTMLInputElement): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let formData = new FormData();
        for (let i = 0; i < inputFile.files.length; i++) {
            formData.append(inputFile.name, inputFile.files[i]);
        }
        let fetchTask = (fetch("/api/Quotation/UploadQuotationDocuments", {
            method: "POST",
            body: formData,
            credentials: "same-origin",
            headers: getDefaultHeaders(getState())
        }).then(response => response.json() as Array<Api.QuotationDocumentModel>)
            .then(quotationDocuments => {
                dispatch({
                    type: "QUOTATION_RECEIVE_UPDATE_DOCUMENTS",
                    payload: {
                        requestTime: requestTime,
                        quotationDocuments: quotationDocuments
                    }
                });
                if (quotationDocuments)
                    dispatch(Notifications.success({
                        message: "Your documents have been uploaded",
                        title: "Success",
                        position: "tc"
                    }) as any);
            }).catch(error => {
                dispatch({
                    type: "QUOTATION_RECEIVE_UPDATE_DOCUMENTS",
                    payload: {
                        requestTime: requestTime,
                        quotationDocuments: null
                    },
                    error: error
                });
                dispatch(Notifications.error({
                    message: "Failed to create document",
                    title: "Error",
                    position: "tc"
                }) as any);
                throw new SubmissionError({ _error: "Failed to create" } as any)
            }) as any) as Promise<any>;

        dispatch({
            type: "QUOTATION_REQUEST_UPDATE_DOCUMENTS",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    quotationSelectLanguage: (languageId: number) => <QuotationSelectLanguage>{
        type: "QUOTATION_SELECT_LANGUAGE", languageId: languageId
    },
    quotationSelectTotalType: (value: Api.QuotationModelTotalTypeEnum) => <QuotationSelectTotalType>{
        type: "QUOTATION_SELECT_TOTALTYPE", value: value
    },
    quotationSelectAllInCurrency: (currencyId: number) => <QuotationSelectAllInCurrency>{
        type: "QUOTATION_SELECT_ALLIN_CURRENCY", currencyId: currencyId
    },
    quotationSelectDetailsLevel: (value: Api.QuotationModelDetailsLevelEnum) => <QuotationSelectDetailsLevel>{
        type: "QUOTATION_SELECT_DETAILS_LEVEL", value: value
    },
    quotationUpdateAdditionalInfo: (value: string) => <QuotationUpdateAdditionalInfo>{
        type: "QUOTATION_UPDATE_ADDITIONAL_INFO", value: value
    },
    quotationUpdateTerms: (value: string) => <QuotationUpdateTerm>{
        type: "QUOTATION_UPDATE_TERM", value: value
    },
    quotationBackToSelection: (): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch(push("/selection/" + getState().quotation.criteria.code) as any);
    },
    quotationUpdateCurrencyValue: (index: number, value: number) => <QuotationUpdateCurrencyValue>{
        type: "QUOTATION_UPDATE_CURRENCY_VALUE",
        payload: { index: index, value: value }
    },
    quotationOpenTemplateDialog: () => <QuotationOpenTemplateDialog>{
        type: "QUOTATION_OPEN_TEMPLATE_DIALOG",
    },
    quotationCloseTemplateDialog: () => <QuotationCloseTemplateDialog>{
        type: "QUOTATION_CLOSE_TEMPLATE_DIALOG",
    },
    quotationOpenClientMoreOptions: () => <QuotationOpenClientMoreOptions>{
        type: "QUOTATION_OPEN_CLIENT_MORE_OPTIONS"
    },
    quotationCloseClientMoreOptions: () => <QuotationCloseClientMoreOptions>{
        type: "QUOTATION_CLOSE_CLIENT_MORE_OPTIONS"
    },
    quotationOpenTemplateListing: () => <QuotationOpenTemplateListing>{
        type: "QUOTATION_OPEN_TEMPLATE_LISTING"
    },
    quotationCloseTemplateListing: () => <QuotationCloseTemplateListing>{
        type: "QUOTATION_CLOSE_TEMPLATE_LISTING"
    },
    quotationUpdateName: (value: string) => <QuotationUpdateName>{
        type: "QUOTATION_UPDATE_NAME",
        payload: { value: value }
    },
    quotationUpdateCriteriaSizeType: (value: number, sizeTypeId: number) => <QuotationUpdateCriteriaSizeType>{
        type: "QUOTATION_UPDATE_CRITERIA_SIZETYPE",
        payload: { value: value, sizeTypeId: sizeTypeId }
    },
    quotationUpdateShowExchangeRates: (value: boolean) => <QuotationUpdateShowExchangeRates>{
        type: "QUOTATION_UPDATE_SHOWEXCHANGERATES",
        payload: { value: value }
    },
    quotationSelectContactClient: (value: Api.QuotationContactModel) => <QuotationSelectContactClient>{
        type: "QUOTATION_SELECT_CONTACT_CLIENT",
        payload: { value: value }
    },
    quotationLoadDuplicate: (): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: "QUOTATION_LOAD_DUPLICATE",
            payload: { quotation: getState().quotationChrono.duplicateQuotation }
        });
    },
    quotationSelectTemplatePreview: (id: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: "QUOTATION_SELECT_TEMPLATE_PREVIEW",
            payload: { id: id }
        });
    },
    quotationCloseTemplatePreview: () => <QuotationCloseTemplatePreview>{ type: "QUOTATION_CLOSE_TEMPLATE_PREVIEW" },
    quotationUpdateByContainer: (value: boolean) => <QuotationUpdateByContainer>{
        type: "QUOTATION_UPDATE_BY_CONTAINER",
        payload: { value: value }
    },
    quotationUpdateCurrencyDisplayType: (index: number, value: Api.QuotationCurrencyModelDisplayTypeEnum) => <QuotationUpdateCurrencyDisplayType>{
        type: "QUOTATION_UPDATE_CURRENCY_DISPLAYTYPE",
        payload: { index: index, value: value }
    },
    quotationUpdateIncotermLocation: (value: string): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch(change(quotationInformation, "incotermLocation", value) as any);
    },
    quotationUpdateFobOrigin: (value: string) => <QuotationUpdateFobOrigin>{ type: "QUOTATION_UPDATE_FOBORIGIN", payload: { value: value } },
    quotationUpdateFobDestination: (value: string) => <QuotationUpdateFobDestination>{ type: "QUOTATION_UPDATE_FOBDESTINATION", payload: { value: value } },
    quotationUpdateFrtOrigin: (value: string) => <QuotationUpdateFrtOrigin>{ type: "QUOTATION_UPDATE_FRTORIGIN", payload: { value: value } },
    quotationUpdateFrtDestination: (value: string) => <QuotationUpdateFrtDestination>{ type: "QUOTATION_UPDATE_FRTDESTINATION", payload: { value: value } },
    quotationUpdateDestinationOrigin: (value: string) => <QuotationUpdateDestinationOrigin>{ type: "QUOTATION_UPDATE_DESTINATIONORIGIN", payload: { value: value } },
    quotationUpdateDestinationDestination: (value: string) => <QuotationUpdateDestinationDestination>{ type: "QUOTATION_UPDATE_DESTINATIONDESTINATION", payload: { value: value } },
    quotationUseInlandChargeSet: (value: Api.InlandChargeSetModel, application: Api.ChargeModelApplicationEnum): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({
            type: "QUOTATION_USE_INLAND_CHARGESET",
            payload: {
                value: value,
                application: application,
                chargeNames: getState().seed.chargeNames,
                sizeTypes: getState().seed.sizeTypes,
                currencies: getState().seed.currencies,
            }
        });
        dispatch(push(getQuotationLink(getState().quotation)) as any);
    },
    quotationAddQuotationFeeLabel: (index: number) => <QuotationAddQuotationFeeLabel>{
        type: "QUOTATION_ADD_QUOTATIONFEELABEL",
        payload: {
            index: index
        }
    },
    quotationRemoveQuotationFeeLabel: (feeIndex: number, index: number) => <QuotationRemoveQuotationFeeLabel>{
        type: "QUOTATION_REMOVE_QUOTATIONFEELABEL",
        payload: {
            index: index,
            feeIndex: feeIndex
        }
    },
    quotationUpdateQuotationFeeLabel: (feeIndex: number, index: number, value: string) => <QuotationUpdateQuotationFeeLabel>{
        type: "QUOTATION_UPDATE_QUOTATIONFEELABEL",
        payload: {
            index: index,
            feeIndex: feeIndex,
            value: value
        }
    },
};
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const getUnloadedQuotationFee = (criteria: Api.CriteriaModel, bl: boolean): Api.QuotationFeeModel => ({
    visible: true,
    quotationFeeAmounts: bl
        ? [{
            baseAmount: 0,
            amount: 0,
            sizeTypeId: null,
            currencyId: 1
        }]
        : criteria.criteriaSizeTypes.map(cst => ({
            baseAmount: 0,
            amount: 0,
            sizeTypeId: cst.sizeTypeId,
            currencyId: 1,
        })),
    unit: bl
        ? "BL" : null,
    quotationFeeLabels: []
});

const unloadedQuotationCharge: Api.QuotationChargeModel = {
    quotationChargeAmounts: [],
    visible: true,
    level: 0,
}

export const unloadedFeeState: QuotationFeeState = {
    mode: "Normal",
    sizeTypesMode: {}
}

const unloadedState: QuotationState = {
    clientMoreOptions: false,
    isLoading: false,
    isLoaded: false,
    requestTime: 0,
    carrierOffer: null,
    criteria: null,
    departId: null,
    mode: "Normal",
    marginCurrencyId: 2,
    downloadDocumentState: {
        isLoading: false,
    },
    referenceState: {
        isLoading: false,
        requestTime: 0,
    },
    createState: {
        isLoading: false,
        requestTime: 0,
    },
    templateState: {
        isLoading: false,
        isOpen: false,
        previewIsOpen: false,
        listingIsOpen: false,
        templateStates: {}
    },
    downloadState: {
        isLoading: false,
        requestTime: 0,
    },
    uploadDocumentsState: {
        isLoading: false,
        requestTime: 0,
    },
    step: 0,
    transferState: {
        isLoading: false
    },
    quotation: {
        showCarrier: true,
        quotationFees: [],
        quotationCharges: [],
        quotationCurrencies: [],
        quotationOffers: [],
        validityBegin: Moment().startOf("day").toDate(),
        validityEnd: Moment().startOf("day").toDate(),
        showExchangeRates: true,
        incoterm: null,
        detailsLevel: "Full",
        totalType: "AllIn",
        clientContact: {
            type: "Client",
            clientType: "None"
        }
    },
    quotationTemplates: [],
    feeStates: {},
};

const getQuotationName = (quotation: QuotationInformationModel, criteria: Api.CriteriaModel): string => {
    let clientName = ((quotation.clientContact.company || "") + "_")
        + ((quotation.clientContact.firstName || "") + "_")
        + ((quotation.clientContact.lastName || "") + "_");
    return clientName
        + (quotation.originInfo || criteria.origin.code)
        + "_" + (quotation.destinationInfo || criteria.destination.code)
        + "_" + quotation.reference;
}

export const reducer: Reducer<QuotationState> = (state: QuotationState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "RECEIVE_CURRENT_USER":
            if (action.error)
                return state;

            let subscription = getSubscription(action.payload.currentUser.clientModel);
            let quotationClientSettings = action.payload.currentUser.clientModel.quotationClientSettings;
            return {
                ...state,
                marginCurrencyId: action.payload.currentUser.clientModel.currencyId,
                quotation: {
                    ...state.quotation,
                    subscriptionId: subscription ? subscription.subscriptionId : 0,
                    languageId: action.payload.currentUser.clientModel.languageId || 1,
                    currencyId: action.payload.currentUser.clientModel.currencyId,
                    ffContactId: action.payload.currentUser.clientModel.defaultQuotationContactId || 0,
                    clientContact: {
                        ...state.quotation.clientContact,
                        clientType: quotationClientSettings
                            ? quotationClientSettings.clientType
                            : "None",
                    },
                    showCarrier: quotationClientSettings
                        ? quotationClientSettings.showCarrier
                        : false,
                    incoterm: quotationClientSettings
                        ? quotationClientSettings.incoterm
                        : ""
                }
            };
        case "RECEIVE_EDIT_QUOTATION_CLIENT_SETTINGS":
            if (action.error)
                return state;

            let receivedQuotationClientSettings = action.payload.model;
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    clientContact: {
                        ...state.quotation.clientContact,
                        clientType: receivedQuotationClientSettings
                            ? receivedQuotationClientSettings.clientType
                            : "None",
                    },
                    showCarrier: receivedQuotationClientSettings
                        ? receivedQuotationClientSettings.showCarrier
                        : false
                }
            };
        case "REQUEST_NEW_QUOTATION_REFERENCE":
            return {
                ...state,
                step: 0,
                referenceState: {
                    ...state.referenceState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_NEW_QUOTATION_REFERENCE":
            if (state.referenceState.requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                referenceState: {
                    ...state.referenceState,
                    isLoading: false
                },
                quotation: {
                    ...state.quotation,
                    reference: action.reference,
                    name: action.carrierName
                        + "_" + state.carrierOffer.originPort.code
                        + "_" + state.carrierOffer.destinationPort.code
                        + "_" + action.reference
                }
            };
        case "REQUEST_CREATE_QUOTATION":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.requestTime
                }
            };
        case "RECEIVE_CREATE_QUOTATION":
            if (state.createState.requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                isLoaded: false,
                createState: {
                    ...state.createState,
                    isLoading: false,
                }
            };
        case "REQUEST_CREATE_QUOTATION_TEMPLATE":
            return {
                ...state,
                templateState: {
                    ...state.templateState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_QUOTATION_TEMPLATE":
            if (state.templateState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                templateState: {
                    ...state.templateState,
                    isLoading: false,
                    isOpen: false
                },
                quotationTemplates: action.error ? state.quotationTemplates : state.quotationTemplates
                    .concat([action.payload.quotation])
            };
        case "REQUEST_DOWNLOAD_QUOTATION":
            return {
                ...state,
                downloadState: {
                    ...state.downloadState,
                    requestTime: action.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_DOWNLOAD_QUOTATION":
            if (action.requestTime !== state.downloadState.requestTime)
                return state;

            return {
                ...state,
                downloadState: {
                    ...state.downloadState,
                    isLoading: false
                }
            };
        case "QUOTATION_PREV_STEP":
            return {
                ...state,
                step: Math.max(0, state.step - 1)
            };
        case "QUOTATION_NEXT_STEP":
            return {
                ...state,
                step: state.step + 1
            };
        case "SET_QUOTATION_INFORMATION":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    name: getQuotationName(action.payload.value, state.criteria),
                    ffContactId: action.payload.value.ffContactId,
                    clientContact: action.payload.value.clientContact,
                    reference: action.payload.value.reference,
                    originInfo: action.payload.value.originInfo,
                    destinationInfo: action.payload.value.destinationInfo,
                    fobOrigin: action.payload.value.originInfo,
                    fobDestination: action.payload.pol.name,
                    frtOrigin: action.payload.pol.name,
                    frtDestination: action.payload.pod.name,
                    destinationOrigin: action.payload.pod.name,
                    destinationDestination: action.payload.value.destinationInfo,
                    validityBegin: action.payload.value.validityBegin,
                    validityEnd: action.payload.value.validityEnd,
                    cargoDesc: action.payload.value.cargoDesc,
                    weight: action.payload.value.weight,
                    incoterm: action.payload.value.incoterm,
                    showCarrier: action.payload.value.showCarrier,
                    minTransitTime: action.payload.value.minTransitTime,
                    maxTransitTime: action.payload.value.maxTransitTime,
                    transShipments: action.payload.value.transShipments,
                    frequency: action.payload.value.frequency,
                    serviceName: action.payload.value.serviceName,
                    incotermLocation: action.payload.value.incotermLocation
                }
            };
        case "QUOTATION_REQUEST_QUOTATION_DATA":
            return {
                ...state,
                requestTime: action.payload.requestTime,
                isLoading: true,
                mode: action.payload.mode
            };
        case "QUOTATION_RECEIVE_QUOTATION_DATA":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            if (action.error)
                return {
                    ...state,
                    isLoading: false
                };
            let toLoadQuotation = {
                ...unloadedState.quotation,
                ...state.quotation,
                quotationCharges: action.payload.quotationCharges,
                quotationFees: action.payload.quotationFees,
                quotationCurrencies: action.payload.quotationCurrencies,
                validityBegin: action.payload.quotationData.carrierOffer.chargeSet
                && action.payload.quotationData.carrierOffer.chargeSet.dateBegin
                    ? Moment.utc(action.payload.quotationData.carrierOffer.chargeSet.dateBegin).local().toDate()
                    : Moment().startOf("day").toDate(),
                validityEnd: action.payload.quotationData.carrierOffer.chargeSet
                && action.payload.quotationData.carrierOffer.chargeSet.dateEnd
                    ? Moment.utc(action.payload.quotationData.carrierOffer.chargeSet.dateEnd).local().toDate()
                    : Moment().startOf("day").toDate(),
                minTransitTime: action.payload.quotationData.carrierOffer.routeConfigs[0]?.transitTime,
                transShipments: action.payload.quotationData.carrierOffer.routeConfigs[0]?.transShipments.length === 0
                    ? "Direct"
                    : action.payload.quotationData.carrierOffer.routeConfigs[0]?.transShipments
                        .map(ts => ts.port?.name).reduce((a, b) => a + ", " + b),
                frequency: action.payload.quotationData.carrierOffer.routeConfigs[0]?.frequency
                    ? (action.payload.quotationData.carrierOffer.routeConfigs[0]
                        .frequency.code.toString().startsWith("1")
                        ? "Weekly"
                        : (action.payload.quotationData.carrierOffer.routeConfigs[0]
                            .frequency.code.toString().startsWith("2")
                            ? "TwiceAWeek"
                            : null))
                    : null,
                ...{
                    ...action.payload.quotationData.quotation,
                    quotationCharges: action.payload.quotationData.quotation.quotationCharges
                        ? action.payload.quotationData.quotation.quotationCharges
                            .concat(action.payload.quotationCharges)
                        : action.payload.quotationCharges,
                    quotationFees: action.payload.quotationData.quotation.quotationFees
                        ? action.payload.quotationData.quotation.quotationFees
                            .concat(action.payload.quotationFees)
                        : action.payload.quotationFees,
                }
            } as Api.QuotationModel;
            
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                loadedSelectionTime: state.enterSelectionTime,
                criteria: action.payload.quotationData.criteria,
                criteriaLoaded: action.payload.quotationData.criteria,
                carrierOffer: action.payload.quotationData.carrierOffer,
                departId: action.payload.quotationData.departId,
                quotationTemplates: action.payload.quotationData.quotationTemplates,
                loadedTemplateId: null,
                exchangeRatesDate: action.payload.quotationData.quotation
                    ? action.payload.quotationData.quotation.creationDate
                    : null,
                quotation: toLoadQuotation,
                feeStates: toLoadQuotation.quotationFees
                    ? (toLoadQuotation.quotationFees.map((x, xIndex) => ({
                        sizeTypesMode: x.quotationFeeAmounts
                            ? _.mapValues(_.keyBy(x.quotationFeeAmounts
                                , y => y.sizeTypeId), y => y.label
                                ? "Label"
                                : "Normal" as QuotationFeeMode)
                            : {},
                        mode: x.quotationFeeAmounts && x.quotationFeeAmounts.length
                            ? x.quotationFeeAmounts[0].label ? "Label" : "Normal"
                            : "Normal"
                    }) as QuotationFeeState))
                    : {}
            };
        case "QUOTATION_ADD_FEE":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.concat([{
                        ...getUnloadedQuotationFee(state.criteria,
                            feeTypeBl.some(x => x === action.feeType)),
                        type: action.feeType
                    }])
                }
            };
        case "QUOTATION_REMOVE_FEE":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.filter((qf, qi) => qi !== action.index)
                }
            };
        case "QUOTATION_SELECT_CHARGE":
            if (state.quotation.quotationCharges
                .some(qc => qc.chargeNameId === action.payload.chargeNameId
                    && (!qc.application
                        || qc.application === action.payload.application))) {
                return {
                    ...state,
                    quotation: {
                        ...state.quotation,
                        quotationCharges: state.quotation.quotationCharges
                            .map(qc => qc.chargeNameId === action.payload.chargeNameId
                                && (!action.payload.application
                                    || qc.application === action.payload.application)
                                ? {
                                    ...qc,
                                    visible: action.payload.selected,
                                    quotationChargeAmounts: qc.quotationChargeAmounts.filter(x => x.level == action.payload.level).map(x => ({
                                        ...x,
                                        amount: !action.payload.selected ? 0 : x.amount,
                                        level: action.payload.level,
                                    }))
                                } : qc)
                    }
                };
            } else {
                let isBl = action.payload.unit === "Bl";
                return {
                    ...state,
                    quotation: {
                        ...state.quotation,
                        quotationCharges: state.quotation.quotationCharges.concat([{
                            ...unloadedQuotationCharge,
                            application: action.payload.application,
                            chargeNameId: action.payload.chargeNameId,
                            visible: action.payload.selected,
                            quotationChargeAmounts: !action.payload.selected
                                ? (isBl
                                    ? [{
                                        amount: 0,
                                        level: action.payload.level,
                                    }]
                                    : state.criteria.criteriaSizeTypes.map(x => ({
                                        sizeTypeId: x.sizeTypeId,
                                        amount: 0,
                                        level: action.payload.level,
                                    })))
                                : []
                        }])
                    }
                };
            }

        case "QUOTATION_UPDATE_CHARGE_AMOUNT":


            let quotationCharges = state.quotation.quotationCharges.concat([]);
            if (!quotationCharges.some(qc => qc.chargeNameId === action.payload.chargeNameId && qc.level == action.payload.level
                && (!qc.application || action.payload.application === qc.application))) {

                quotationCharges = quotationCharges.concat([{
                    ...unloadedQuotationCharge,
                    chargeNameId: action.payload.chargeNameId,
                    visible: true,
                    application: action.payload.application,
                    level: action.payload.level,
                }]);
            }
            let x = {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationCharges: quotationCharges.map(qc =>
                        qc.chargeNameId === action.payload.chargeNameId && qc.level == action.payload.level
                            && (!qc.application || qc.application === action.payload.application)
                            ? {
                                ...qc,
                                quotationChargeAmounts: qc.quotationChargeAmounts
                                    .filter(qa => qa.originalChargeId != action.payload.originalChargeId || (qa.originalChargeId == action.payload.originalChargeId && qa.sizeTypeId != action.payload.sizeTypeId)).concat([{
                                        amount: action.payload.value,
                                        sizeTypeId: action.payload.sizeTypeId,
                                        level: action.payload.level,
                                        originalChargeId: action.payload.originalChargeId

                                    }])
                            } : qc)
                }
            };
            return x;
        case "QUOTATION_SELECT_FEE":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.map((qf, qi) => qi === action.payload.index
                        ? {
                            ...qf,
                            visible: action.payload.selected,
                            quotationFeeAmounts: qf.quotationFeeAmounts.map(x => ({
                                ...x,
                                amount: !action.payload.selected ? 0 : x.amount
                            }))
                        } : qf)
                }
            };
        case "QUOTATION_SELECT_DETAILS_LEVEL":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    detailsLevel: action.value
                }
            };
        case "QUOTATION_SELECT_TOTALTYPE":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    totalType: action.value
                }
            };
        case "QUOTATION_SELECT_ALLIN_CURRENCY":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    currencyId: action.currencyId
                }
            };
        case "QUOTATION_SELECT_LANGUAGE":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    languageId: action.languageId
                }
            };
        case "QUOTATION_UPDATE_ADDITIONAL_INFO":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    addInfo: action.value
                }
            };
        case "QUOTATION_UPDATE_TERM":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    terms: action.value
                }
            };
        case "QUOTATION_UPDATE_FEE_AMOUNT":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.map((qf, qi) => qi === action.index
                        ? {
                            ...qf,
                            quotationFeeAmounts: qf.quotationFeeAmounts
                                .map(qfa => qfa.sizeTypeId == action.sizeTypeId ? { ...qfa, amount: action.value } : qfa)
                        } : qf)
                }
            };
        case "QUOTATION_UPDATE_FEE_BASEAMOUNT":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.map((qf, qi) => qi === action.index
                        ? {
                            ...qf,
                            quotationFeeAmounts: !qf.quotationFeeAmounts.length
                                ? [{ amount: 0, baseAmount: action.value, currencyId: 2, sizeTypeId: action.sizeTypeId }]
                                : qf.quotationFeeAmounts
                                    .map(qfa => qfa.sizeTypeId == action.sizeTypeId ? { ...qfa, baseAmount: action.value } : qfa)
                        } : qf)
                }
            };
        case "QUOTATION_UPDATE_FEE_CURRENCY":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.map((qf, qi) => qi === action.index
                        ? {
                            ...qf,
                            quotationFeeAmounts: qf.quotationFeeAmounts
                                .map(qfa => qfa.sizeTypeId == action.sizeTypeId
                                    ? {
                                        ...qfa,
                                        currencyId: action.currencyId
                                    } : qfa)
                        } : qf)
                }
            };
        case "QUOTATION_UPDATE_FEE_MODE":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.map((qf, qi) => qi === action.payload.index
                        ? {
                            ...qf,
                            quotationFeeAmounts: qf.quotationFeeAmounts
                                .map(qfa => qfa.sizeTypeId == action.payload.sizeTypeId
                                    ? {
                                        ...qfa,
                                        label: "",
                                        amount: 0,
                                        baseAmount: 0
                                    }
                                    : qfa)
                        } : qf)
                },
                feeStates: {
                    ...state.feeStates,
                    [action.payload.index]: {
                        ...state.feeStates[action.payload.index] || unloadedFeeState,
                        mode: action.payload.sizeTypeId
                            ? (state.feeStates[action.payload.index] || unloadedFeeState).mode
                            : action.payload.value,
                        sizeTypesMode: action.payload.sizeTypeId
                            ? {
                                ...(state.feeStates[action.payload.index] || unloadedFeeState).sizeTypesMode,
                                [action.payload.sizeTypeId]: action.payload.value
                            }
                            : (state.feeStates[action.payload.index] || unloadedFeeState).sizeTypesMode
                    }
                }
            };
        case "QUOTATION_UPDATE_FEE_LABEL":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.map((qf, qi) => qi === action.payload.index
                        ? {
                            ...qf,
                            quotationFeeAmounts: qf.quotationFeeAmounts
                                .map(qfa => qfa.sizeTypeId == action.payload.sizeTypeId
                                    ? {
                                        ...qfa,
                                        label: action.payload.value
                                    }
                                    : qfa)
                        } : qf)
                }
            };
        case "QUOTATION_UPDATE_FEE_NAME":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees
                        .map((qf, qi) => qi === action.index
                            ? { ...qf, name: action.value } : qf)
                }
            };
        case "QUOTATION_UPDATE_FEE_UNIT":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees
                        .map((qf, qi) => qi === action.index
                            ? { ...qf, unit: action.value } : qf)
                }
            };
        case "QUOTATION_LOAD_TEMPLATE":
            let qltTemplate = state.quotationTemplates
                .find(x => x.quotationId === action.payload.quotationId);

            return {
                ...state,
                exchangeRatesDate: qltTemplate
                    && qltTemplate.quotationCurrencies.length !== 0
                    ? qltTemplate.creationDate
                    : null,
                loadedTemplateId: action.payload.quotationId,
                feeStates: qltTemplate
                    ? (qltTemplate.quotationFees.map((x, xIndex) => ({
                        sizeTypesMode: x.quotationFeeAmounts
                            ? _.mapValues(_.keyBy(x.quotationFeeAmounts
                                , y => y.sizeTypeId), y => y.label
                                    ? "Label"
                                    : "Normal" as QuotationFeeMode)
                            : {},
                        mode: x.quotationFeeAmounts && x.quotationFeeAmounts.length
                            ? x.quotationFeeAmounts[0].label ? "Label" : "Normal"
                            : "Normal"
                    }) as QuotationFeeState))
                    : {},
                quotation: qltTemplate
                    ? {
                        ...state.quotation,
                        addInfo: qltTemplate.addInfo,
                        terms: qltTemplate.terms,
                        detailsLevel: qltTemplate.detailsLevel,
                        totalType: qltTemplate.totalType,
                        languageId: qltTemplate.languageId,
                        showExchangeRates: qltTemplate.showExchangeRates,
                        quotationCurrencies: state.quotation.quotationCurrencies
                            .map(x => qltTemplate.quotationCurrencies.some(y => y.currencyId === x.currencyId)
                                ? { ...x, value: qltTemplate.quotationCurrencies.find(y => y.currencyId === x.currencyId).value }
                                : x),
                        quotationCharges: qltTemplate.quotationCharges.map(x => ({
                            ...x,
                            quotationChargeAmounts: x.quotationChargeAmounts.map(y => {
                                if (!y.sizeTypeId)
                                    return {
                                        ...y,
                                        amount: y.amount,
                                    };

                                let criteriaSizeType = state.criteria.criteriaSizeTypes
                                    .find(z => action.payload.sizeTypes[z.sizeTypeId].position === action.payload.sizeTypes[y.sizeTypeId].position);
                                return {
                                    ...y,
                                    amount: y.amount,
                                    sizeTypeId: criteriaSizeType
                                        ? criteriaSizeType.sizeTypeId
                                        : y.sizeTypeId
                                };
                            })
                        })),
                        quotationFees: qltTemplate.quotationFees.map((x, xIndex) => ({
                            ...x,
                            quotationFeeAmounts: x.quotationFeeAmounts.map(y => {
                                if (!y.sizeTypeId)
                                    return {
                                        ...y,
                                        amount: y.amount,
                                        baseAmount: y.baseAmount
                                    };

                                let criteriaSizeType = state.criteria.criteriaSizeTypes
                                    .find(z => action.payload.sizeTypes[z.sizeTypeId].position === action.payload.sizeTypes[y.sizeTypeId].position);
                                return {
                                    ...y,
                                    amount: y.amount,
                                    baseAmount: y.baseAmount,
                                    sizeTypeId: criteriaSizeType
                                        ? criteriaSizeType.sizeTypeId
                                        : y.sizeTypeId
                                };
                            })
                        }))
                    } : {
                        ...state.quotation,
                        quotationFees: [],
                        quotationCharges: [],
                        addInfo: "",
                        terms: "",
                        totalType: "AllIn",
                        detailsLevel: "Full",
                        showExchangeRates: true
                    }
            };
        case "QUOTATION_UPDATE_CURRENCY_VALUE":
            return {
                ...state,
                exchangeRatesDate: null,
                quotation: {
                    ...state.quotation,
                    quotationCurrencies: state.quotation.quotationCurrencies
                        .map((x, xi) => xi === action.payload.index
                            ? { ...x, value: action.payload.value }
                            : x)
                }
            };
        case "QUOTATION_UPDATE_CURRENCY_DISPLAYTYPE":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationCurrencies: state.quotation.quotationCurrencies
                        .map((x, xi) => xi === action.payload.index
                            ? {
                                ...x,
                                displayType: action.payload.value,
                                value: 1 / x.value
                            }
                            : x)
                }
            };
        case "QUOTATION_OPEN_TEMPLATE_DIALOG":
            return {
                ...state,
                templateState: {
                    ...state.templateState,
                    isOpen: true
                }
            };
        case "QUOTATION_CLOSE_TEMPLATE_DIALOG":
            return {
                ...state,
                templateState: {
                    ...state.templateState,
                    isOpen: false
                }
            };
        case "QUOTATION_OPEN_CLIENT_MORE_OPTIONS":
            return {
                ...state,
                clientMoreOptions: true
            };
        case "QUOTATION_CLOSE_CLIENT_MORE_OPTIONS":
            return {
                ...state,
                clientMoreOptions: false
            };
        case "SELECTION_GO_TO_QUOTATION":
            return {
                ...state,
                enterSelectionTime: action.payload.time,
                isLoaded: false,
                quotation: {
                    ...unloadedState.quotation,
                    ffContactId: state.quotation.ffContactId,
                    subscriptionId: state.quotation.subscriptionId,
                    languageId: state.quotation.languageId,
                    currencyId: state.quotation.currencyId
                }
            };
        case "QUOTATION_OPEN_TEMPLATE_LISTING":
            return {
                ...state,
                templateState: {
                    ...state.templateState,
                    listingIsOpen: true
                }
            };
        case "QUOTATION_CLOSE_TEMPLATE_LISTING":
            return {
                ...state,
                templateState: {
                    ...state.templateState,
                    listingIsOpen: false
                }
            };
        case "QUOTATION_REQUEST_DELETE_TEMPLATE":
            return {
                ...state,
                templateState: {
                    ...state.templateState,
                    templateStates: {
                        ...state.templateState.templateStates,
                        [action.payload.id]: {
                            ...state.templateState.templateStates[action.payload.id],
                            isLoading: true,
                            requestTime: action.payload.requestTime
                        }
                    }
                }
            };
        case "QUOTATION_RECEIVE_DELETE_TEMPLATE":
            if (state.templateState.templateStates[action.payload.id]
                && state.templateState.templateStates[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                templateState: {
                    ...state.templateState,
                    templateStates: {
                        ...state.templateState.templateStates,
                        [action.payload.id]: {
                            ...state.templateState.templateStates[action.payload.id],
                            isLoading: false
                        }
                    }
                },
                quotationTemplates: action.error
                    ? state.quotationTemplates
                    : state.quotationTemplates.filter(x => x.quotationId !== action.payload.id)
            };
        case "QUOTATION_UPDATE_NAME":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    name: action.payload.value
                }
            };
        case "QUOTATION_REQUEST_DOWNLOAD_DOCUMENT":
            return {
                ...state,
                downloadDocumentState: {
                    ...state.downloadDocumentState,
                    isLoading: false,
                    requestTime: action.payload.requestTime
                }
            };
        case "QUOTATION_RECEIVE_DOWNLOAD_DOCUMENT":
            if (state.downloadDocumentState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                downloadDocumentState: {
                    ...state.downloadDocumentState,
                    isLoading: false
                }
            };
        case "QUOTATION_UPDATE_SHOWEXCHANGERATES":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    showExchangeRates: action.payload.value
                }
            };
        case "QUOTATION_UPDATE_CRITERIA_SIZETYPE":
            return {
                ...state,
                criteria: {
                    ...state.criteria,
                    criteriaSizeTypes: state.criteria.criteriaSizeTypes.map(x =>
                        x.sizeTypeId === action.payload.sizeTypeId
                            ? { ...x, number: action.payload.value }
                            : x)
                }
            };
        case "QUOTATION_UPDATE_CRITERIA":
            return {
                ...state,
                criteria: action.payload.value,
                criteriaLoaded: action.payload.value
            };
        case "QUOTATION_SELECT_CONTACT_CLIENT":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    clientContact: action.payload.value
                }
            };
        case "QUOTATION_LOAD_DUPLICATE":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    ...((({
                        ffContactId,
                        clientContact,
                        cargoDesc,
                        destinationInfo,
                        originInfo,
                        incoterm,
                        showCarrier,
                        weight,
                        incotermLocation,
                        serviceName
                    }: Api.QuotationModel) => ({
                        ffContactId,
                        clientContact,
                        cargoDesc,
                        destinationInfo,
                        originInfo,
                        incoterm,
                        showCarrier,
                        weight,
                        incotermLocation,
                        serviceName
                    }))(action.payload.quotation))

                }
            };
        case "QUOTATION_CLOSE_TEMPLATE_PREVIEW":
            return {
                ...state,
                templateState: {
                    ...state.templateState,
                    previewIsOpen: false,
                }
            };
        case "QUOTATION_SELECT_TEMPLATE_PREVIEW":
            return {
                ...state,
                templateState: {
                    ...state.templateState,
                    previewIsOpen: true,
                    previewQuotationId: action.payload.id
                }
            };
        case "QUOTATION_UPDATE_BY_CONTAINER":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    byContainer: action.payload.value,
                    totalType: "None"
                }
            };
        case "QUOTATION_UPDATE_FOBORIGIN":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    fobOrigin: action.payload.value
                }
            };
        case "QUOTATION_UPDATE_FOBDESTINATION":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    fobDestination: action.payload.value
                }
            };
        case "QUOTATION_UPDATE_FRTORIGIN":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    frtOrigin: action.payload.value
                }
            };
        case "QUOTATION_UPDATE_FRTDESTINATION":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    frtDestination: action.payload.value
                }
            };
        case "QUOTATION_UPDATE_DESTINATIONORIGIN":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    destinationOrigin: action.payload.value ?? ""
                }
            };
        case "QUOTATION_UPDATE_DESTINATIONDESTINATION":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    destinationDestination: action.payload.value ?? ""
                }
            };
        case "QUOTATION_USE_INLAND_CHARGESET":
            let ratesCalculator = new RatesCalculator(
                action.payload.currencies,
                action.payload.sizeTypes,
                state.criteria);
            let stateWithFees = {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees
                        .concat(_.map(_.groupBy(action.payload.value.charges, x => x.chargeNameId),
                            xs => {
                                let isBl = xs[0].unit === "Bl";
                                let unloaded = getUnloadedQuotationFee(state.criteria, isBl);
                                return {
                                    ...unloaded,
                                    name: `INLAND ${action.payload.chargeNames[xs[0].chargeNameId].shortName}`,
                                    type: (action.payload.application === "Origin" ? "PreTransport" : "PostTransport") as any,
                                    quotationFeeAmounts: unloaded.quotationFeeAmounts
                                        .map(x => {
                                            let charge = xs
                                                .find(y => !y.sizeTypeId
                                                    || y.sizeTypeId === x.sizeTypeId);

                                            if (!charge)
                                                return x;

                                            if (charge.chargeType === "Value") {
                                                return {
                                                    ...x,
                                                    baseAmount: Math.round(charge.amount * 100) / 100,
                                                    amount: Math.round(charge.amount * 100) / 100,
                                                    currencyId: charge.currencyId,
                                                    sizeTypeId: x.sizeTypeId
                                                };
                                            }

                                            let source = ratesCalculator.findChargesToApplybySizeId(action.payload.value, x.sizeTypeId)
                                                .concat(ratesCalculator.findBlChargesToApply(action.payload.value))
                                                .find(y => y.chargeNameId === charge.sourceChargeNameId)
                                            if (!source)
                                                return undefined;

                                            return charge.type === "Incl"
                                                ? {
                                                    ...x,
                                                    label: `INCLUDED IN ${action.payload.chargeNames[source.chargeNameId].shortName}`,
                                                    sizeTypeId: x.sizeTypeId
                                                }
                                                : {
                                                    ...x,
                                                    amount: Math.round(charge.amount * source.amount * 100) / 100,
                                                    baseAmount: Math.round(charge.amount * source.amount * 100) / 100,
                                                    currencyId: source.currencyId,
                                                    sizeTypeId: x.sizeTypeId
                                                };
                                        }).filter(x => x),
                                };
                            }))
                }
            };
            return {
                ...stateWithFees,
                feeStates: _.merge(stateWithFees.feeStates, _.map(_.keyBy(stateWithFees.quotation.quotationFees
                    .map((x, xi) => ({ xi: xi, x: x })), x => x.xi),
                    x => ({
                        mode: x.x.quotationFeeAmounts.some(y => y.label) ? "Label" : "Normal",
                        sizeTypesMode: _.map(_.keyBy(state.criteria.criteriaSizeTypes, y => y.sizeTypeId),
                            y => x.x.quotationFeeAmounts.some(z => z.label) ? "Label" : "Normal")
                    } as QuotationFeeState)))
            };
        case "QUOTATION_REQUEST_UPDATE_DOCUMENTS":
            return {
                ...state,
                uploadDocumentsState: {
                    ...state.uploadDocumentsState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                },
            };
        case "QUOTATION_RECEIVE_UPDATE_DOCUMENTS":
            if (action.payload.requestTime !== state.uploadDocumentsState.requestTime)
                return state;

            return {
                ...state,
                uploadDocumentsState: {
                    ...state.uploadDocumentsState,
                    isLoading: false,
                },
                quotation: {
                    ...state.quotation,
                    quotationDocuments: action.payload.quotationDocuments
                }
            };
        case "QUOTATION_UPDATE_MARGIN_CURRENCYID":
            return {
                ...state,
                marginCurrencyId: action.payload.value
            };
        case "RECEIVE_QUOTATIONSETTINGS":
            return {
                ...state,
                quotation: action.payload.quotationSettings
                    ? {
                        ...state.quotation,
                        terms: action.payload.quotationSettings.defaultTerms,
                        addInfo: action.payload.quotationSettings.defaultAdditionalInfo,
                    } : state.quotation
            };
        case "QUOTATION_ADD_QUOTATIONFEELABEL":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.map((x, xi) =>
                        xi === action.payload.index
                            ? {
                                ...x,
                                quotationFeeLabels: x.quotationFeeLabels
                                    .concat({})
                            }
                            : x)
                }
            };
        case "QUOTATION_REMOVE_QUOTATIONFEELABEL":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.map((x, xi) =>
                        xi === action.payload.feeIndex
                            ? {
                                ...x,
                                quotationFeeLabels: x.quotationFeeLabels
                                    .filter((y, yi) => yi !== action.payload.index)
                            }
                            : x)
                }
            };
        case "QUOTATION_UPDATE_QUOTATIONFEELABEL":
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    quotationFees: state.quotation.quotationFees.map((x, xi) =>
                        xi === action.payload.feeIndex
                            ? {
                                ...x,
                                quotationFeeLabels: x.quotationFeeLabels
                                    .map((y, yi) => yi === action.payload.index
                                        ? {
                                            content: action.payload.value
                                        }
                                        : y)
                            }
                            : x)
                }
            };
        case "REQUEST_TRANSFER_QUOTATION":
            return {
                ...state,
                transferState: {
                    ...state.transferState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_TRANSFER_QUOTATION":
            if (state.transferState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                transferState: {
                    ...state.transferState,
                    isLoading: false,
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
