import { Action, Reducer } from "redux";
import * as Api from "../api/api";
import { AppThunkAction } from "./";
import { push } from "connected-react-router";
import * as Notifications from 'react-notification-system-redux';

export interface ForgotPasswordState {
    isLoading: boolean;
    requestTime: number;
    tokenSent: boolean;
    resetPasswordState: ResetPasswordState;
}

export interface ResetPasswordState {
    isLoading: boolean;
    requestTime: number;
}

interface RequestRequestResetPassword { type: "REQUEST_REQUEST_RESET_PASSWORD"; payload: { requestTime: number } }
interface ReceiveRequestResetPassword { type: "RECEIVE_REQUEST_RESET_PASSWORD"; payload: { requestTime: number }; error?: any }

interface RequestResetPassword { type: "REQUEST_RESET_PASSWORD"; payload: { requestTime: number } }
interface ReceiveResetPassword { type: "RECEIVE_RESET_PASSWORD"; payload: { requestTime: number }; error?: any }

export type KnownAction = RequestRequestResetPassword
    | ReceiveRequestResetPassword
    | RequestResetPassword | ReceiveResetPassword;

export const actionCreators = {
    requestRequestResetPassword: (requestTime: number, model: Api.RequestResetPasswordModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().forgotPassword.requestTime)
            return Promise.reject("Already did");

        let api = new Api.AccountApi();
        let fetchTask = api.requestResetPassword({ model: model })
            .then(() => {
                dispatch({ type: "RECEIVE_REQUEST_RESET_PASSWORD", payload: { requestTime: requestTime } });
                dispatch(Notifications.success({
                    message: "An email has been sent to reset your password",
                    title: "Success",
                    position: "tc"
                }) as any);
                dispatch(push("/") as any);
            })
            .catch((err) => {
                dispatch({ type: "RECEIVE_REQUEST_RESET_PASSWORD", payload: { requestTime: requestTime }, error: err });
                dispatch(Notifications.error({ message: "Error sending email", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "REQUEST_REQUEST_RESET_PASSWORD", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    requestResetPassword: (requestTime: number, model: Api.ResetPasswordModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().forgotPassword.resetPasswordState.requestTime)
            return Promise.reject("Already did");

        let api = new Api.AccountApi();
        let fetchTask = api.resetPassword({ model: model })
            .then(() => {
                dispatch({ type: "RECEIVE_RESET_PASSWORD", payload: { requestTime: requestTime } });
                dispatch(Notifications.success({
                    message: "Your password has been reset",
                    title: "Success",
                    position: "tc"
                }) as any);
                dispatch(push("/") as any);
            })
            .catch((err) => {
                dispatch({ type: "RECEIVE_RESET_PASSWORD", payload: { requestTime: requestTime }, error: err });
                dispatch(Notifications.error({ message: "Error reseting your password", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "REQUEST_RESET_PASSWORD", payload: { requestTime: requestTime } });
        return fetchTask;
    }
};


const unloadedState: ForgotPasswordState = {
    isLoading: false,
    requestTime: 0,
    tokenSent: false,
    resetPasswordState: {
        isLoading: false,
        requestTime: 0,
    }
};

export const reducer: Reducer<ForgotPasswordState> = (state: ForgotPasswordState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_REQUEST_RESET_PASSWORD":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_REQUEST_RESET_PASSWORD":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                tokenSent: action.error
                    ? state.tokenSent
                    : true
            };
        case "REQUEST_RESET_PASSWORD":
            return {
                ...state,
                resetPasswordState: {
                    ...state.resetPasswordState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_RESET_PASSWORD":
            if (action.payload.requestTime !== state.resetPasswordState.requestTime)
                return state;

            return {
                ...state,
                resetPasswordState: {
                    ...state.resetPasswordState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};