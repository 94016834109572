import * as Api from "../api/api";
import {getDefaultHeaders} from "../utils/utils";
import {store} from "../store/configureStore";
import * as Notifications from "react-notification-system-redux";

const actionBlackList: Array<RegExp> = [ /.*@@redux-form.*/, /.*RECEIVE_.*/ ];
const actionWhiteList: Array<RegExp> = [ /.*@@redux-form\/CHANGE.*/ ];
let actions: Array<{ timeStamp: number; action: ActionLog }> = []

interface Action { type: string; payload?: any; meta?: { form?: string; field?: string } };
interface ActionLog { type: string; payload?: any; meta?: string };

const toActionLog = (action: Action): ActionLog => {
    return {
        type: action.type,
        payload: action.payload,
        meta: action.meta ? `${action.meta?.form}.${action.meta?.field}` : null
    };
}

export const actionLogger = store => next => action => {
    let result = next(action);
    if(!actionBlackList.some(x => x.test(action?.type))
    || actionWhiteList.some(x => x.test(action?.type))) {
        let actionLog = toActionLog(action);
        if(actions[actions.length - 1]?.action?.type === action?.type
            && (actionLog.meta === actions[actions.length - 1]?.action?.meta)) {
            actions[actions.length - 1].action = actionLog;
        } else {
            actions.push({ 
                timeStamp: new Date().getTime(), 
                action: actionLog
            });
        }
    } else {
    }
    return result;
}


export const flushActions = () => {
    actions = [];
}

export const sendActions = () => {
    try{
        if(actions.length == 0){
            return Promise.resolve();
        }
        return fetch('/api/Log/LogActions', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                actions: actions.map(x => ({
                    timestamp: x.timeStamp,
                    type: x.action.type,
                    payload: JSON.stringify(x.action?.payload || {})
                        .substring(0, 100),
                    meta: x.action.meta
                })).filter(x => x.type)
            })
        })
            .then(resp => {
            })
            .catch(err => {
                console.error("Failed to log actions", err);
            });
    } catch(err){
        console.error(err);
    }
};