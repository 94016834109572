import * as React from 'react';
import * as Api from '../api/api';
import * as ForgotPasswordStore from '../store/ForgotPassword';
import * as FormField from './FormField';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import { NavLink } from 'react-router-dom';
import * as Colors from '../styles/colors';

type ForgotPasswordProps =
    ForgotPasswordStore.ForgotPasswordState
    & typeof ForgotPasswordStore.actionCreators;

const Button = {
    background: 'none',
    border: `1px solid ${Colors.baseColors.darkGray}`,
    borderRadius: 25,
    boxShadow: 'none',
    color: Colors.baseColors.lightBlue,
    height: 35,
    padding: '0px 25px',
    fontWeight: 700,
    MozBoxShadow: "none",
    WebkitBoxShadow: "none",
}
class ForgotPasswordForm extends React.Component<ReduxForm.InjectedFormProps<{}, {}, string>, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <label style={{ flex: "0 0 auto", minWidth: 100, marginRight: 5 }}>{getText("LogEmail")}</label>
                    <div style={{}}>
                        <ReduxForm.Field name="email"
                            validate={[FormField.required, FormField.emailValid]} 
                             disabled={this.props.submitting}
                            style={{ minWidth: 250 }}
                            component={FormField.InputTextField} />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 20 }}>
                    <button className="btn"
                        style={Button}
                        disabled={this.props.submitting || this.props.pristine}>
                        Reset my password
                    </button>

                    <NavLink to={"/login"} className="btn" style={{...Button, marginRight: "0.5em"}} >
                        <div style={{ marginTop: "5px" }}>
                            {getText("GenGoBack")}
                        </div>
                    </NavLink>
                </div>
            </FormField.BaseForm>
        );
    }
}

const WrappedForgotPasswordForm = ReduxForm.reduxForm({ form: 'forgotPassword' })(ForgotPasswordForm) as React.ComponentClass<{
    onSubmit: (values: Api.RequestResetPasswordModel) => void
}>;


class ForgotPassword extends React.Component<ForgotPasswordProps, {}> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps: ForgotPasswordProps) {

    }

    public render() {
        return <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                <div style={{ padding: "20px 40px 20px 40px" }}>
                    <WrappedForgotPasswordForm onSubmit={(values) =>
                        this.props.requestRequestResetPassword(new Date().getTime(), values)} />
                </div>
            </div>
        </div>
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.forgotPassword
    } as any)
    , ForgotPasswordStore.actionCreators
)(ForgotPassword) as any as React.ComponentClass<{ onSubmit: (values: { email: string }) => Promise<any> }>;