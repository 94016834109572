import * as React from 'react';
import { Switch } from '@material-ui/core';
import * as Colors from '../styles/colors';
import { styled } from '@material-ui/core/styles';

const StyledSwitch = styled(Switch)({
    "& .MuiButtonBase-root": {
        color: "white",
    },
    "& .Mui-checked": {
        color: Colors.baseColors.lightBlue
    },
    "& .MuiSwitch-track": {
        backgroundColor: `${Colors.baseColors.darkGray} !important`
    },
    "& .MuiSwitch-thumb": {
        border: `1px solid ${Colors.baseColors.darkGray}`
    }
});


interface OkSwitchProps {
    value: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
    style?: React.CSSProperties;
    size?: "small" | "medium";
}

export default class OkSwitch extends React.Component<OkSwitchProps, {}> {

    constructor(props) {
        super(props)
    }

    public render() {
        return (
            <StyledSwitch
                disabled={this.props.disabled}
                size={this.props.size || "medium"}
                style={{ ...this.props.style }}
                value={this.props.value ? true : false}
                onChange={(e, val) => this.props.onChange(val)} />
            );
    }
}