import * as React from 'react';
import * as Api from '../api/api';
import * as ForgotPasswordStore from '../store/ForgotPassword';
import * as FormField from './FormField';
import * as ReduxForm from 'redux-form';
import * as Colors from "../styles/colors";
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';

type ResetPasswordProps =
    ForgotPasswordStore.ResetPasswordState
    & typeof ForgotPasswordStore.actionCreators
    & RouteComponentProps<{ token: string }>;

const Button = {
    background: 'none',
    border: `1px solid ${Colors.baseColors.darkGray}`,
    borderRadius: 25,
    boxShadow: 'none',
    color: Colors.baseColors.lightBlue,
    backgroundColor: "white",
    height: 35,
    padding: '0px 25px',
    fontWeight: 700,
    MozBoxShadow: "none",
    WebkitBoxShadow: "none",
}

const confirmationValid = (value, allValues: Api.ResetPasswordModel, props) => {
    if (allValues.password !== allValues.passwordConfirmation)
        return "Password and confirmation must match";

    return undefined;
};

class ResetPasswordForm extends React.Component<ReduxForm.InjectedFormProps<{}, {}, string>, {}> {
    public render() {
        return (
            <FormField.BaseForm className="form-horizontal" {...this.props}>
                <div className="form-part row">
                    <label className="control-label col-md-4">{getText("StgPasswordNewPassword")}</label>
                    <div className="col-md-8">
                        <ReduxForm.Field name="password"
                            validate={[FormField.passwordValid]}
                            type="password"
                            style={{ minWidth: 250 }}
                            component={FormField.InputTextField} />
                    </div>
                </div>
                <div className="form-part row" style={{ marginTop: 20 }}>
                    <label className="control-label col-md-4">{getText("StgPasswordPasswordConf")}</label>
                    <div className="col-md-8">
                        <ReduxForm.Field name="passwordConfirmation"
                            validate={[FormField.passwordValid, confirmationValid]}
                            type="password"
                             style={{ minWidth: 250 }}
                             component={FormField.InputTextField} />
                    </div>
                </div>
                <div className="form-part" style={{ display: "flex", flexDirection: "row-reverse", marginTop: 20 }}>
                    <button className="btn"
                        style={Button}
                        disabled={this.props.submitting || this.props.pristine}>
                        Reset my password
                    </button>
                </div>
            </FormField.BaseForm>
        );
    }
}

const WrappedResetPasswordForm = ReduxForm.reduxForm({ form: 'resetPassword' })(ResetPasswordForm) as React.ComponentClass<{
    onSubmit: (values: Api.ResetPasswordModel) => void
}>;


class ResetPassword extends React.Component<ResetPasswordProps, {}> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps: ResetPasswordProps) {

    }

    public render() {
        return <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ padding: "20px 40px 20px 40px" }}>
                    <WrappedResetPasswordForm onSubmit={(values) => this.props.requestResetPassword(new Date().getTime(), {
                        ...values,
                        token: this.props.match.params.token
                    })} />
                </div>
            </div>
        </div>
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.forgotPassword.resetPasswordState
    } as any),
    ForgotPasswordStore.actionCreators
)(ResetPassword) as any;