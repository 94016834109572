import * as React from 'react';
import * as ReduxForm from 'redux-form';
import * as Api from '../api/api';
import * as FormField from './FormField';
import * as Colors from "../styles/colors";
import { getText } from "../utils/langTexts";
import PasswordToggle from './PasswordToggle';

type EditAccountFormProps = ReduxForm.InjectedFormProps<{}, {}, string> & EditAccountFormOwnProps;

interface EditAccountFormOwnProps {
    handleSubmit: (values: any) => void;
    submitting: boolean;
}

const passwordLength = 6;
const minPasswordLength = FormField.minLength(passwordLength);
const passwordConfMatch = (value, allValues) => {
    return (allValues as Api.EditPasswordModel).newPassword !== value
        ? "Password and confirmation must match"
        : null;
};
const labelStyle = {
    width: "200px",
    paddingTop: "8px",
    paddingLeft: "6px",
} as React.CSSProperties;

const containerStyle = {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "20px",
} as React.CSSProperties;

const inputFormStyle = {
    width: "400px",
    height: "42px",
    border: `1px solid ${Colors.baseColors.darkGray}`,
    borderRadius: "8px",
    color: Colors.baseColors.darkGray,
} as React.CSSProperties;

const saveButtonStyle = {
    borderRadius: "8px",
    color: Colors.baseColors.lightBlue,
    fontWeight: "bold",
    border: `2px solid ${Colors.baseColors.darkGray}`,
    backgroundColor: "white",
    position: "absolute",
    right: 0,
    top: 5,
} as React.CSSProperties;



interface IPasswordToggle {
    OldisVisible: boolean;
    NewisVisible: boolean;
    ConfirmationisVisible: boolean;
}

class AccountEditPasswordForm extends React.Component<EditAccountFormProps, IPasswordToggle> {
    constructor(props: EditAccountFormProps) {
        super(props);
        this.state = {
            OldisVisible: false,
            NewisVisible: false,
            ConfirmationisVisible: false
        };
    }
    public render() {
        return (
            <FormField.BaseForm {...this.props}  style={{ maxWidth: 650 }}>
                <div  style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div style={containerStyle}>
                    <label style={labelStyle}>{getText("StgPasswordOldPassword")}</label>
                        <ReduxForm.Field 
                            style={inputFormStyle}
                            validate={FormField.required}
                            name="oldPassword"
                        component={FormField.renderField}
                        type={this.state.OldisVisible ? "text" : "password"}
                    />
                    <span onClick={(e) => { this.setState({ OldisVisible: !this.state.OldisVisible }) }}>
                        <PasswordToggle IsVisible={this.state.OldisVisible}/>
                    </span>
                    
                </div>
                <div style={containerStyle}>
                    <label style={labelStyle}> {getText("StgPasswordNewPassword")}</label>
                    <ReduxForm.Field
                        style={inputFormStyle}
                        validate={[FormField.required, FormField.passwordValid]}
                        name="newPassword"
                        component={FormField.renderField}
                        type={this.state.NewisVisible ? "text" : "password"}
                    />
                    <div onClick={(e) => { this.setState({ NewisVisible: !this.state.NewisVisible }) }}>
                        <PasswordToggle IsVisible={this.state.NewisVisible} />
                    </div>
                </div>
                <div style={containerStyle}>
                    <label style={labelStyle}>{getText("StgPasswordPasswordConf")}</label>
                        <ReduxForm.Field 
                            style={inputFormStyle}
                            validate={[passwordConfMatch]}
                            name="newPasswordConf"
                            component={FormField.renderField}
                            type={this.state.ConfirmationisVisible ? "text" : "password"}
                        />
                        <div onClick={(e) => { this.setState({ ConfirmationisVisible: !this.state.ConfirmationisVisible }) }}>
                            <PasswordToggle IsVisible={this.state.ConfirmationisVisible} />
                        </div>
                </div>
                <div >
                    <div style={{ position: "relative"  }}>
                        <button
                            type="submit"
                            disabled={this.props.submitting}
                            style={saveButtonStyle}
                            onClick={(e) => {
                                this.setState({
                                    OldisVisible: false,
                                    NewisVisible: false,
                                    ConfirmationisVisible: false
                                })
                            }}>
                            {getText("StgChangePasswordGo")}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

export default ReduxForm.reduxForm({ form: 'editPassword' })(AccountEditPasswordForm) as any;
