import * as React from 'react';
import Moment from 'moment';
import * as Colors from '../styles/colors';
import Tooltip from './Tooltip';
import * as Api from '../api/api';
import { getText } from '../utils/langTexts';
import { getRatesStyle } from '../utils/utils';
import { RssFeed, Public, EmojiObjects, InsertDriveFile, Person } from "@material-ui/icons";

interface OfferScopeProps {
    carrierOffer: Api.CarrierOfferModel;
    criteria: Api.CriteriaModel;
    iconStyle?: React.CSSProperties;
    carrierRatesPlatforms: { [id: number]: Api.CarrierRatesPlatformModel };
}

interface ScopeDisplayProps {
    isNew?: boolean;
    iconStyle?: React.CSSProperties;
}

export class OfferScropeUploaded extends React.Component<ScopeDisplayProps, {}> {
    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{}}>
                    <Person style={{ height: 14, color: Colors.baseColors.lightBlue, ...this.props.iconStyle }} />
                </div>
                {this.props.isNew && <div> ({getText("SlcOfferNewUpdate")}) </div>}
            </div>
        );
    }
}
export class OfferScropePrivate extends React.Component<ScopeDisplayProps, {}> {
    public render() {

        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{}}>
                    <InsertDriveFile style={{ height: 14, color: Colors.baseColors.lightBlue, ...this.props.iconStyle }} />
                </div>
                {this.props.isNew && <div> ({getText("SlcOfferNewUpdate")}) </div>}
            </div>
        );
    }
}

export class OfferScropePublic extends React.Component<ScopeDisplayProps, {}> {
    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ }}>
                    <Public style={{ height: 14, color: Colors.baseColors.lightBlue, ...this.props.iconStyle }} />
                </div>
                {this.props.isNew && <div> ({getText("SlcOfferNewUpdate")}) </div>}
            </div>
        );
    }
}

export class OfferScropeOnline extends React.Component<ScopeDisplayProps, {}> {
    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{  }}>
                    <RssFeed style={{ height: 14, color: Colors.baseColors.lightBlue, ...this.props.iconStyle }} />
                </div>
            </div>
        );
    }
}

export class OfferScropeSuggested extends React.Component<ScopeDisplayProps, {}> {
    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ }}>
                    <EmojiObjects style={{ height: 14, color: Colors.baseColors.lightBlue, ...this.props.iconStyle }} />
                </div>
            </div>
        );
    }
}


export class OfferScope extends React.Component<OfferScopeProps, {}> {
    constructor(props) {
        super(props);
    }

    get description(): string {

        if (this.props.carrierOffer.chargeSet.ratesFetchedRecordId) {
            return getText("GenRatesOnlineDesc",
                {
                    carrier: this.props.carrierRatesPlatforms[this.props.carrierOffer.carrierRatesPlatformId].name,
                    carrierUrl: this.props.carrierRatesPlatforms[this.props.carrierOffer.carrierRatesPlatformId].onlineQuoteLink,
                    ocqPlatformName: this.props.carrierRatesPlatforms[this.props.carrierOffer.carrierRatesPlatformId].name
                }
            );
        }
        
        if (this.props.carrierOffer.chargeSet.routeSuggestedCalcId)
            return getText("GenRatesSuggestedDesc");

        if (!this.props.carrierOffer.chargeSet.firmId)
            return getText("GenRatesPublicDesc");

        if (this.props.carrierOffer.workDocumentClientUploadId)
            return getText("GenRatesUploadedDesc");

        return getText("GenRatesPrivateDesc");
    }

    get isNew(): boolean {
        return this.props.carrierOffer.chargeSet
            && !this.props.carrierOffer.chargeSet.ratesFetchedRecordId
            && ((this.props.carrierOffer.chargeSet.receptionDate
                && Moment().diff(Moment(this.props.carrierOffer.chargeSet.receptionDate)) / (1000 * 60 * 60) < 72)
            || (this.props.carrierOffer.chargeSet.creationDate
            && Moment().diff(Moment(this.props.carrierOffer.chargeSet.creationDate)) / (1000 * 60 * 60) < 72));
    }

    public render() {
        return <div>
            {this.props.carrierOffer.chargeSet
                ? <div style={getRatesStyle(this.props.carrierOffer.chargeSet, this.props.criteria)}>
                    <Tooltip
                        placement="right"
                        trigger={["hover"]}
                        overlay={<div>{this.description}</div>}>
                        <div>
                            {this.props.carrierOffer.chargeSet.routeSuggestedCalcId
                                && (
                                    <OfferScropeSuggested isNew={this.isNew} iconStyle={this.props.iconStyle} />
                                )}
                            {this.props.carrierOffer.chargeSet.ratesFetchedRecordId
                                && (
                                <OfferScropeOnline isNew={false} iconStyle={this.props.iconStyle} />
                                )}
                            {!this.props.carrierOffer.chargeSet.routeSuggestedCalcId
                                && !this.props.carrierOffer.chargeSet.ratesFetchedRecordId
                                && this.props.carrierOffer.chargeSet.firmId
                                && this.props.carrierOffer.workDocumentClientUploadId
                                && (
                                    <OfferScropeUploaded isNew={this.isNew} iconStyle={this.props.iconStyle} />
                                )}
                            {!this.props.carrierOffer.chargeSet.routeSuggestedCalcId
                                && !this.props.carrierOffer.chargeSet.ratesFetchedRecordId
                                && this.props.carrierOffer.chargeSet.firmId
                                && !this.props.carrierOffer.workDocumentClientUploadId
                                && (
                                <OfferScropePrivate isNew={this.isNew} iconStyle={this.props.iconStyle} />
                                )}
                            {!this.props.carrierOffer.chargeSet.routeSuggestedCalcId
                                && !this.props.carrierOffer.chargeSet.ratesFetchedRecordId
                                && !this.props.carrierOffer.chargeSet.firmId
                                && !this.props.carrierOffer.workDocumentClientUploadId
                                && (
                                <OfferScropePublic isNew={this.isNew} iconStyle={this.props.iconStyle} />
                                )}
                        </div>
                    </Tooltip>
                </div> : ''}
        </div>
    }
}