import * as React from 'react';
import OfferPrice, { IncludedWarning } from './OfferPrice';
import { OfferScope } from './OfferScope';
import OfferRatesName from './OfferRatesName';
import OfferRatesOffer from './OfferRatesOffer';
import DDSetTable from './DDSetTable';
import * as SelectionStore from '../store/Selection';
import * as Api from '../api/api';
import * as _ from 'lodash';
import Moment from 'moment';
import { getCarrierOfferKey, getRatesStyle, offerIsCarrierRating } from '../utils/utils';
import Tooltip from './Tooltip';
import { RatesCalculator } from '../utils/ratesUtils';
import * as Colors from "../styles/colors";
import { getText } from '../utils/langTexts';
import OperationalAccuracy from './OperationalAccuracy';
import Table, { Column } from './Table';
import { frtId } from '../utils/constants';
import keyboard from '../utils/keyboard';
import { getRouteConfig } from '../utils/routeUtils';
import { AddShoppingCart, CheckCircle, Warning, Info } from "@material-ui/icons";
import Freetime from './Freetime';
import ChargeSetAvailabilty from "./ChargeSetAvailabilty";
import { getTableTransitTime } from '../store/Selection';

interface OfferTableProps {
    subscriptions: Array<Api.SubscriptionModel>;
    carrierOffersGrouped: Array<Array<Api.CarrierOfferModel>>;
    carrierRatings: { [id: number]: Api.CarrierRatingModel };
    carrierOfferStates: { [id: string]: SelectionStore.CarrierOfferState };
    subscriptionType: Api.SubscriptionModelSubscriptionTypeEnum;
    isLoading: boolean;
    multiPortIsLoading: boolean;
    multiPortIsActive: boolean;
    multiPortOffers: Array<Api.MultiPortOfferModel>; //I try to keep the store part to a minimum
    sellingRates: Array<Api.SellingRatesModel>;
    criteriaModel: Api.CriteriaModel;
    criteriaLoaded: Api.CriteriaModel;
    currencies: { [id: number]: Api.CurrencyModel };
    carriers: { [id: number]: Api.CarrierModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    markets: { [id: number]: Api.MarketModel };
    currencyId: number;
    handleOfferClick: (id: string) => void;
    selectSortBy: (value: SelectionStore.CarrierOfferSort) => void;
    openGroup: (id: string) => void;
    closeGroup: (id: string) => void;
    selectCarrierOffer: (id: string, value: boolean) => void;
    requestAddOfferToMultiPort: (carrierOffer: Api.CarrierOfferModel) => void;
    requestAddAllOffersToMultiPort: () => void;
    selectRouteService: (key: string, routeServiceId: number) => void;
    selectAllCarrierOffer: (value: boolean) => void;
    filterOptions: SelectionStore.SelectionFilterOptions;
    fitlerSortBy: SelectionStore.CarrierOfferSort;
    byRatesOffer: boolean;
    carrierOffersSelected: { [id: string]: boolean },
    carrierRatesPlatforms: { [id: number]: Api.CarrierRatesPlatformModel };
}

export const canAddToMultiPort = (carrierOffer: Api.CarrierOfferModel,
    multiPortIsLoading: boolean,
    multiPortOffers: Array<Api.MultiPortOfferModel>): boolean => {
    return !multiPortIsLoading
        && multiPortOffers
        && !multiPortOffers.some(mo => mo.chargeSet.chargeSetId === carrierOffer.chargeSet.chargeSetId);
};

type OfferColumn = Column<Api.CarrierOfferModel>
    & { carrierOfferSort?: SelectionStore.CarrierOfferSort }

const containerPriceColumn = (props: OfferTableProps, criteriaSize: Api.CriteriaSizeTypeModel, ratesCalculator: RatesCalculator): OfferColumn => {
    return {
        header: <div
            style={{ cursor: "pointer", fontWeight: "bold", paddingLeft: 18 }}
            onClick={(e) => {
                props.selectSortBy(("AllInCtr" + props.sizeTypes[criteriaSize.sizeTypeId].position) as any);
                e.preventDefault();
            }}>
            Total {props.sizeTypes[criteriaSize.sizeTypeId].name}</div>,
        id: 'total' + props.sizeTypes[criteriaSize.sizeTypeId].sizeTypeId,
        carrierOfferSort: `AllInCtr${props.sizeTypes[criteriaSize.sizeTypeId].position}`,
        sorted: () => props.fitlerSortBy === `AllInCtr${props.sizeTypes[criteriaSize.sizeTypeId].position}` ? "desc" : undefined,
        align: "center",
        accessor: (d) => d,
        cell: (d) => {
            let carrierOffer = d;
            let relevantCharges = carrierOffer.chargeSet
                ? ratesCalculator.findChargesToApply(
                    carrierOffer.chargeSet, criteriaSize).concat(ratesCalculator.findBlChargesToApply(carrierOffer.chargeSet))
                : [];
            return (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {carrierOffer.chargeSet && !carrierOffer.chargeSet.invalidated
                        ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <IncludedWarning chargeSet={carrierOffer.chargeSet}
                                ratesCalculator={ratesCalculator}
                                visible={props.subscriptionType !== "Agent"}>
                                <div style={{ minWidth: 57, ...getRatesStyle(carrierOffer.chargeSet, props.criteriaModel) }}>
                                    <span style={{ fontWeight: 700 }}>{relevantCharges.some(x => x.chargeNameId === frtId)
                                        ? Math.round((ratesCalculator.calculatePrice(
                                            relevantCharges, props.sizeTypes[criteriaSize.sizeTypeId].teu).totalUsd)
                                            / props.currencies[props.currencyId].value)
                                        : "_"}</span>
                                    <span> </span>
                                    <span>{relevantCharges.some(x => x.chargeNameId === frtId)
                                        && props.currencies[props.currencyId].code}</span>
                                </div>
                            </IncludedWarning>
                            <OfferScope carrierOffer={d as Api.CarrierOfferModel}
                                criteria={props.criteriaModel}
                                carrierRatesPlatforms={props.carrierRatesPlatforms}
                            ></OfferScope>
                        </div>
                        : <div>
                            {!carrierOffer.chargeSet
                                ? "-"
                                : <Tooltip
                                    overlay={<div>This rates have been disabled by a moderator</div>}>
                                    <div>Disabled</div>
                                </Tooltip>}
                        </div>}
                </div>
            );
        }
    } as OfferColumn
};

const getColumns = (props: OfferTableProps, ratesCalculator: RatesCalculator): Array<OfferColumn> => {
    let hasAltPol = props.criteriaLoaded && props.carrierOffersGrouped
        .reduce((a, b) => a.concat(b), [])
        .some(y => y.originPort.locationId !== props.criteriaLoaded.origin.locationId
            && (!y.originTerminal
                || y.originTerminal.locationId !== props.criteriaLoaded.origin.locationId));
    let hasAltPod = props.criteriaLoaded && props.carrierOffersGrouped
        .reduce((a, b) => a.concat(b), [])
        .some(y => y.destinationPort.locationId !== props.criteriaLoaded.destination.locationId
            && (!y.destinationTerminal
                || y.destinationTerminal.locationId !== props.criteriaLoaded.origin.locationId));

    return ([
        {
            header: <div
                style={{ fontWeight: 600, paddingLeft: 10 }}
                onClick={(e) => {
                    props.selectSortBy("Carrier");
                    e.preventDefault();
                }}>
                {getText("SlcTableCarrier")}</div>,
            style: { paddingLeft: '10px !important' },
            id: 'carrrier',
            align: "center",
            headerAlign: "left",
            carrierOfferSort: "Carrier",
            sorted: () => props.fitlerSortBy === "Carrier" ? "desc" : undefined,
            accessor: (d) => d,
            cell: (d: any) => {
                let carrierOffer = d as Api.CarrierOfferModel;
                return (
                    <div style={{
                        fontWeight: "bold",
                        paddingLeft: 10,
                        minWidth: 150,
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        <div style={{ flex: "0 0 auto", color: "black" }}>{props.carriers[carrierOffer.carrierId].name}</div>
                        {carrierOffer.chargeSet
                            && props.carrierRatings
                            && offerIsCarrierRating(carrierOffer, props.carrierRatings[carrierOffer.carrierId])
                            && <span style={{ marginLeft: 5 }}>
                                <Tooltip overlay={<div>{props.carrierRatings[carrierOffer.carrierId].label}</div>}>
                                    <span>
                                        {" "}<img src={"/images/carrierRating/" + props.carrierRatings[carrierOffer.carrierId].icon}
                                            height={14} />
                                    </span>
                                </Tooltip>
                            </span>}
                    </div>
                );
            }
        },
        {
            header: <div style={{ fontWeight: 600, paddingLeft: 20 }}>{getText("SlcTableRates")}</div>,
            id: 'rates',
            align: "center",
            headerAlign: "left",
            sortingDisabled: true,
            accessor: (d) => d,
            cell: (d: any) => {
                let carrierOffer = d as Api.CarrierOfferModel;
                let hasToolTip = carrierOffer.chargeSet
                    && ((carrierOffer.ratesOffer
                        && (carrierOffer.ratesOffer.description != null && carrierOffer.ratesOffer.description.length > 0))
                        || (carrierOffer.offerInformations
                            && carrierOffer.offerInformations.length !== 0)
                        || carrierOffer.chargeSet.marketId);
                let hasWarning = ((carrierOffer.offerInformations
                    && carrierOffer.offerInformations.some(x => x.level === "High")) || (carrierOffer.ratesOffer && carrierOffer.ratesOffer.level === "High"));


                //Tooltip children have to implement hovering etc ...
                //So wrap the content within a div
                return (
                    <div style={getRatesStyle(carrierOffer.chargeSet, props.criteriaModel)}>
                        {carrierOffer.chargeSet
                            ?
                            <div style={{ display: "flex", flexDirection: "row", paddingRight: 20, minWidth: 100 }}>
                                <Tooltip
                                    style={{ width: 600, opacity: 1 }}
                                    trigger={props.subscriptionType !== "Agent"
                                        ? ["hover", "click"]
                                        : []}
                                    showDelay={0.7}
                                    overlay={<div style={{}}>
                                        <OfferRatesOffer
                                            ratesOffer={carrierOffer.ratesOffer}
                                            subscriptions={props.subscriptions}
                                            chargeSet={carrierOffer.chargeSet}
                                            offerInformations={carrierOffer.offerInformations}
                                            markets={props.markets} />
                                        {carrierOffer.ddSet && <DDSetTable
                                            ddSet={carrierOffer.ddSet}
                                            currencies={props.currencies}
                                            sizeTypes={props.sizeTypes}

                                        />}
                                    </div>}
                                    placement="right"
                                    hideDelay={0.4}>
                                    <div data-id={carrierOffer.chargeSet?.chargeSetId} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div>
                                            <OfferRatesName
                                                criteria={props.criteriaModel}
                                                focused={false}
                                                subscriptionType={props.subscriptionType}
                                                ratesOffer={carrierOffer.ratesOffer}
                                                chargeSet={carrierOffer.chargeSet}
                                                hideWarning
                                                offerInformations={carrierOffer.offerInformations} />
                                        </div>
                                        {hasToolTip && !hasWarning && <Info style={{ marginLeft: 6, fontSize: 14, color: Colors.baseColors.lightBlue }} />}
                                        {hasWarning && <Warning style={{ marginLeft: 6, fontSize: 14, color: Colors.baseColors.lightBlue }} />}
                                    </div>
                                </Tooltip>
                            </div>
                            : null}
                    </div>
                );
            }
        }
    ] as Array<OfferColumn>).concat(
        (props.criteriaModel.containerPrice
            ?
            props.criteriaModel.criteriaSizeTypes.filter(cst => cst.number > 0)
                .map(cst => containerPriceColumn(props, cst, ratesCalculator))
            :
            [
                {
                    header: <div
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={(e) => {
                            props.selectSortBy("AllIn");
                            e.preventDefault();
                        }}>
                        {getText("SlcTableTotalFrom")}</div>,
                    id: 'totalFrom',
                    align: "center",
                    carrierOfferSort: `AllIn`,
                    sorted: () => props.fitlerSortBy === "AllIn" ? "desc" : undefined,
                    accessor: (d) => d,
                    cell: (d: any) => <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <OfferPrice
                                inline={true}
                                subscriptionType={props.subscriptionType}
                                carrierOffer={d as Api.CarrierOfferModel}
                                sellingRates={props.sellingRates}
                                criteriaModel={props.criteriaModel}
                                currencies={props.currencies}
                                sizeTypes={props.sizeTypes}
                                currencyId={props.currencyId} />
                            <OfferScope carrierOffer={d as Api.CarrierOfferModel}
                                criteria={props.criteriaModel}
                                carrierRatesPlatforms={props.carrierRatesPlatforms}
                            ></OfferScope>
                        </div>
                    </div>
                }
            ]) as Array<OfferColumn>)
        .concat((props.criteriaLoaded && props.criteriaLoaded.includeCarrierRates)
            || props.carrierOffersGrouped.some(x => x.some(y => y.ratesAvailabilitys.length !== 0))
            ? [
                {
                    header: <div
                        style={{ fontWeight: "bold" }}>
                        {getText("SlcTableSpaceAvailabilty")}</div>,
                    id: 'ratesAvailabilty',
                    align: "center",
                    accessor: (d) => d,
                    cell: (d: any) => <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <ChargeSetAvailabilty
                                carrierOffer={d}
                                criteria={props.criteriaModel}
                                sizeTypes={props.sizeTypes}
                            />
                        </div>
                    </div>
                }
            ] : [])
        .concat((props.criteriaLoaded
            && props.criteriaLoaded.type === "Lcl") ? [
                (props.criteriaLoaded
                    && props.criteriaLoaded.origin.type === "Water"
                    && props.carrierOffersGrouped
                        .some(xs => xs.some(x => (x.routeLclConfig
                            && x.routeLclConfig.originCfs) ? true : false))
                    ? {
                        header: <div style={{ fontWeight: "bold" }}>{getText("SlcOfferCfso")}</div>,
                        id: 'cfso',
                        align: "center",
                        className: '',
                        accessor: (d) => d,
                        sortingDisabled: true,
                        cell: (d: any) => {
                            let carrierOffer = d as Api.CarrierOfferModel;
                            return <div style={{ fontWeight: 500, textAlign: "center" }}>
                                {carrierOffer.routeLclConfig
                                    && carrierOffer.routeLclConfig.originCfs
                                    && carrierOffer.routeLclConfig.originCfs.name}
                            </div>
                        }
                    } : null),
                (props.criteriaLoaded
                    && props.criteriaLoaded.destination.type === "Water"
                    && props.carrierOffersGrouped
                        .some(xs => xs.some(x => (x.routeLclConfig
                            && x.routeLclConfig.destinationCfs) ? true : false))
                    ? {
                        header: <div style={{ fontWeight: "bold" }}>{getText("SlcOfferCfsd")}</div>,
                        id: 'cfsd',
                        align: "center",
                        className: '',
                        accessor: (d) => d,
                        sortingDisabled: true,
                        cell: (d: any) => {
                            let carrierOffer = d as Api.CarrierOfferModel;
                            return <div style={{ fontWeight: 500, textAlign: "center" }}>
                                {carrierOffer.routeLclConfig
                                    && carrierOffer.routeLclConfig.destinationCfs
                                    && carrierOffer.routeLclConfig.destinationCfs.name}
                            </div>
                        }
                    } : null),
                (props.criteriaLoaded
                    && props.criteriaLoaded.origin.type === "Dry"
                    && props.carrierOffersGrouped
                        .some(xs => xs.some(x => x.originPort ? true : false))
                    ? {
                        header: <div style={{ fontWeight: "bold" }}>{getText("SlcOfferLclOrigin")}</div>,
                        id: 'lclOrigin',
                        align: "center",
                        className: '',
                        accessor: (d) => d,
                        sortingDisabled: true,
                        cell: (d: any) => {
                            let carrierOffer = d as Api.CarrierOfferModel;
                            return <div style={{ fontWeight: 500, textAlign: "center" }}>
                                {carrierOffer.originPort.name}
                            </div>
                        }
                    } : null),
                (props.criteriaLoaded
                    && props.criteriaLoaded.destination.type === "Dry"
                    && props.carrierOffersGrouped
                        .some(xs => xs.some(x => x.originPort ? true : false))
                    ? {
                        header: <div style={{ fontWeight: "bold", paddingLeft: 18 }}>{getText("SlcOfferLclDestination")}</div>,
                        id: 'lclDestination',
                        align: "center",
                        className: '',
                        accessor: (d) => d,
                        sortingDisabled: true,
                        cell: (d: any) => {
                            let carrierOffer = d as Api.CarrierOfferModel;
                            return <div style={{ fontWeight: 500, textAlign: "center" }}>
                                {carrierOffer.destinationPort.name}
                            </div>
                        }
                    } : null)
            ].filter(x => x) as any : [])
        .concat(
            [
                {
                    header: <div style={{ cursor: "pointer", fontWeight: "bold", paddingLeft: 18 }}
                        onClick={(e) => {
                            props.selectSortBy("Validity");
                            e.preventDefault();
                        }}>
                        {getText("SlcValidity")}
                    </div>,
                    id: 'validity',
                    align: "center",
                    carrierOfferSort: `Validity`,
                    sorted: () => props.fitlerSortBy === "Validity" ? "desc" : undefined,
                    accessor: (d) => d,
                    cell: (d: any) => {
                        let carrierOffer = d as Api.CarrierOfferModel;
                        if (carrierOffer.chargeSet) {
                            if (carrierOffer.chargeSet.ratesPriceType === "Spot") {
                                let dateEtd = carrierOffer.chargeSet.departs
                                    && carrierOffer.chargeSet.departs.length
                                    ? (carrierOffer.chargeSet.departs.length > 1
                                        ? "multiple dates"
                                        : Moment(carrierOffer.chargeSet.departs[0].etd).format('DD/MM/YYYY'))
                                    : "no dates";
                                return (
                                    <div>
                                        <span style={{ fontWeight: "bold" }}>{getText("CrtAgentSpot")}</span>
                                        {" "}{getText("SlcOfferForEtd")}
                                        {" "}<span className={""}
                                            style={{ fontWeight: "bold" }}>{dateEtd}</span>
                                    </div>
                                );
                            }
                            else {
                                let QuoteValidity = carrierOffer.chargeSet.quotValidity ? true : false;
                                if (Moment(carrierOffer.chargeSet.dateEnd).add(1, "days").toDate().getTime() < new Date(props.criteriaModel.dateBegin).getTime()) {
                                    return (
                                        <div style={{ color: "#6F6F6E" }}>
                                            {getText("GenRatesExpired")}
                                        </div>
                                    );
                                }
                                return (
                                    <div>
                                        <span>
                                            <Tooltip
                                                disableHoverListener={!QuoteValidity}
                                                style={{ opacity: 1 }}
                                                trigger={["hover"]}
                                                overlay={QuoteValidity &&
                                                    <div>
                                                        Quote validity : <span style={{ fontWeight: "bold" }}>{Moment(carrierOffer.chargeSet.quotValidity).format('DD/MM/YYYY')}</span>
                                                    </div>}
                                                placement="right">
                                                <span style={{ fontWeight: "bold" }}>{Moment(carrierOffer.chargeSet.dateBegin).format('DD/MM/YYYY')}</span>
                                                {" > "}
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                        ...(Moment(new Date(props.filterOptions.maxRatesDate)).format("DD/MM/YYYY") === Moment(carrierOffer.chargeSet.dateEnd).format("DD/MM/YYYY")
                                                            ? { color: Colors.baseColors.lightBlue }
                                                            : {})
                                                    }}>
                                                    {Moment(carrierOffer.chargeSet.dateEnd).format('DD/MM/YYYY')}
                                                    {QuoteValidity && <Warning style={{ marginLeft: 10, fontSize: 14, color: Colors.baseColors.lightBlue }} />}
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </div>
                                );
                            }
                        }
                        return null;
                    }
                },
                props.criteriaModel.showFreeTime
                    ? {
                        id: "freeTimeOrigin",
                        align: "center",
                        header: <div style={{ paddingLeft: 18, fontWeight: "bold" }}>
                            <div>Freetime</div>
                            <div style={{ fontSize: 12 }}>origin</div>
                        </div>,
                        accessor: d => d.ddSet,
                        sortingDisabled: true,
                        cell: d => {
                            let ddSet = d as Api.DdSetModel;
                            return <Freetime criteria={props.criteriaModel}
                                ddCharges={ddSet ? ddSet.charges.filter(x => x.application === "Origin") : []} />;
                        }
                    }
                    : null,
                props.criteriaModel.showFreeTime
                    ? {
                        id: "freeTimeDestination",
                        align: "center",
                        header: <div style={{ paddingLeft: 18, fontWeight: "bold" }}>
                            <div>Freetime</div>
                            <div style={{ fontSize: 12 }}>destination</div>
                        </div>,
                        accessor: d => d.ddSet,
                        sortingDisabled: true,
                        cell: d => {
                            let ddSet = d as Api.DdSetModel;
                            return <Freetime criteria={props.criteriaModel}
                                ddCharges={ddSet ? ddSet.charges.filter(x => x.application === "Destination") : []} />;
                        }
                    }
                    : null,
                (props.criteriaLoaded
                    && props.criteriaLoaded.allMarkets
                    ? {
                        header: <div style={{ paddingLeft: 18, fontWeight: "bold" }}>{getText("SlcMarket")}</div>,
                        id: 'market',
                        align: "center",
                        sortingDisabled: true,
                        accessor: (d) => (d.chargeSet
                            && d.chargeSet.marketId)
                            ? props.markets[d.chargeSet.marketId].name
                            : ""
                    }
                    : null) as OfferColumn,
                (hasAltPol
                    ? {
                        header: <div style={{ paddingLeft: 18, fontWeight: "bold", cursor: "pointer", }}>{getText("CrtOrigin")}</div>,
                        id: 'altPol',
                        align: "center",
                        carrierOfferSort: `Pol`,
                        sorted: () => props.fitlerSortBy === "Pol" ? "desc" : undefined,
                        accessor: (d) => d.originPort.name,
                        cell: d => <div style={{ textAlign: "center" }}>{d}</div>
                    }
                    : null) as OfferColumn,
                (hasAltPod
                    ? {
                        header: <div style={{ paddingLeft: 18, fontWeight: "bold", cursor: "pointer", }}>{getText("CrtDestination")}</div>,
                        id: 'altPod',
                        accessor: (d) => d.destinationPort.name,
                        carrierOfferSort: `Pod`,
                        sorted: () => props.fitlerSortBy === "Pod" ? "desc" : undefined,
                        cell: d => <div style={{ textAlign: "center" }}>{d}</div>
                    }
                    : null) as OfferColumn,
                {
                    header: <div style={{ fontWeight: "bold", paddingLeft: 18 }}>{getText("SlcService")}</div>,
                    id: 'service',
                    align: "center",
                    accessor: (d) => d,
                    sortingDisabled: true,
                    cell: (d: any) => {
                        let carrierOffer = d as Api.CarrierOfferModel;
                        let carrierOfferState = props.carrierOfferStates[getCarrierOfferKey(carrierOffer)];
                        let routeConfig = getRouteConfig(carrierOffer, carrierOfferState);

                        let linkedService = carrierOffer.chargeSet?.routeServiceId > 0;
                        if (linkedService == true) {
                            let hasDepartsFromDiffServices = routeConfig?.departs != null ? (routeConfig.departs.map(x => x.service).concat(carrierOffer.chargeSet?.departs?.map(d => d.service))) : carrierOffer.chargeSet?.departs?.map(d => d.service) ;
                            const uniqueArray = _.uniqBy(hasDepartsFromDiffServices.filter(x=>x != null), x => x);
                            linkedService =  uniqueArray.length == 1;
                        }

                        return (<div style={{}}>
                            <Tooltip placement={"top"}
                                disableHoverListener={!linkedService}
                                overlay={<div>This rate only applies for this service</div>}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{ width: 40, textAlign: "right" }}>
                                        {linkedService && <img src={"/images/iconLink.png"} height={12} />}
                                        {carrierOffer.chargeSet?.routeService != null
                                            ? carrierOffer.chargeSet.routeService.code
                                            : routeConfig?.routeService && routeConfig.routeService.code}
                                    </div>
                                </div>
                            </Tooltip>

                        </div>)
                    }
                },
                {
                    header: <div
                        style={{ fontWeight: "bold", paddingLeft: 18 }}
                        onClick={(e) => {
                            props.selectSortBy("TransShipments");
                            e.preventDefault();
                        }}>
                        {getText("SlcTransShipments")} {props.fitlerSortBy === "TransShipments"
                            && <i className="glyphicon glyphicon-chevron-down"></i>}
                        <OperationalAccuracy />
                    </div>,
                    id: 'transshipments',
                    align: "center",
                    carrierOfferSort: `TransShipments`,
                    sorted: () => props.fitlerSortBy === "TransShipments" ? "desc" : undefined,
                    accessor: (d) => d,
                    cell: (d: any) => {
                        let carrierOffer = d as Api.CarrierOfferModel;
                        let routeConfig = getRouteConfig(d, props.carrierOfferStates[getCarrierOfferKey(d)]);

                        var hasChargesetTr = carrierOffer.chargeSet?.transShipments != null && carrierOffer.chargeSet.ratesFetchedRecordId != null; //if field is !=null and empty, we consider like direct for ocq, for integrated we choose Rcs

                        let locations = hasChargesetTr
                            ? carrierOffer.chargeSet?.transShipments
                            : routeConfig?.transShipments;

                        let lastUpdate;
                        let source = getText("SlcOfferOperationalSourceManual");
                        if (carrierOffer.chargeSet?.ratesFetchedRecordId
                            && carrierOffer.chargeSet?.transShipments) {
                            source = getText("SlcOfferOperationalSourceOcq");
                            lastUpdate = carrierOffer.chargeSet.creationDate;
                        }
                        else if (!carrierOffer.chargeSet?.ratesFetchedRecordId
                            && carrierOffer.chargeSet?.transShipments) {
                            source = getText("SlcOfferOperationalSourcePl");
                            lastUpdate = carrierOffer.chargeSet.creationDate;
                        }
                        else if (routeConfig) {
                            if (routeConfig.source === "ScheduleSystem") {
                                source = getText("SlcOfferOperationalSourceApi");
                            }
                            if (routeConfig.source === "CarrierRates") {
                                source = getText("SlcOfferOperationalSourceOcq");
                            }
                            lastUpdate = routeConfig.lastUpdate;
                        }

                        if (!locations
                            && carrierOffer.chargeSet?.transitTime
                            && !carrierOffer.chargeSet.ratesFetchedRecordId) {
                            return <div style={{}}>
                                <div data-rc={routeConfig?.routeConfigId} style={{ display: "inline-block" }}>
                                    <Tooltip
                                        trigger={['hover']}
                                        overlay={<span>{getText("SlcOfferOperationalSource")}{" "}{source}</span>}>
                                        <span style={{}}>N/A</span>
                                    </Tooltip>
                                </div>
                            </div>;
                        }
                        if (!locations)
                            return <div style={{}}></div>;
                        if (!hasChargesetTr && carrierOffer.chargeSet?.routeServiceId && routeConfig.routeServiceId !== carrierOffer.chargeSet.routeServiceId) {
                            return <div style={{}}></div>;
                        }

                        return <div style={{}}>
                            {locations.length === 0
                                ? <span style={{
                                    fontWeight: 700,
                                    color: Colors.baseColors.lightBlue
                                }}>Direct</span>
                                : <div data-rc={routeConfig?.routeConfigId} style={{ display: "inline-block" }}>
                                    <Tooltip
                                        trigger={['hover']}
                                        overlay={<span>{locations.map(lo => lo && lo.port?.name ? lo.port.name : getText("GenUnknown")).join(", ")}</span>}>
                                        <span style={{ fontWeight: "bold" }}>{locations.length}</span>
                                    </Tooltip>
                                </div>}
                        </div>;
                    }
                },
                {
                    header: <div
                        style={{ fontWeight: "bold", paddingLeft: 18 }}
                        onClick={(e) => {
                            props.selectSortBy("TransitTime");
                            e.preventDefault();
                        }}>
                        {getText("GenTransitTime")} {props.fitlerSortBy === "TransitTime"
                            && <i className="glyphicon glyphicon-chevron-down"></i>}
                        <OperationalAccuracy />
                    </div>,
                    id: 'transitTime',
                    align: "center",
                    carrierOfferSort: `TransitTime`,
                    sorted: () => props.fitlerSortBy === "TransitTime" ? "desc" : undefined,
                    accessor: (d) => d,
                    cell: (d: any) => {
                        let carrierOffer = d as Api.CarrierOfferModel;
                        let routeConfig = getRouteConfig(carrierOffer, props.carrierOfferStates[getCarrierOfferKey(carrierOffer)]);
                        // let besTransitTime = carrierOffer.chargeSet && carrierOffer.chargeSet.transitTime
                        //     ? carrierOffer.chargeSet.transitTime
                        //     : routeConfig?.bestTransitTime;
                        let transitTime = getTableTransitTime(carrierOffer, props.carrierOfferStates);

                        let lastUpdate = routeConfig && routeConfig.lastUpdate;
                        let source = getText("SlcOfferOperationalSourceManual");
                        if (carrierOffer.chargeSet?.ratesFetchedRecordId) {
                            source = getText("SlcOfferOperationalSourceOcq");
                        }
                        if (carrierOffer.chargeSet?.ratesFetchedRecordId
                            && carrierOffer.chargeSet?.transitTime) {
                            source = getText("SlcOfferOperationalSourceOcq");
                            lastUpdate = carrierOffer.chargeSet.creationDate;
                        }
                        else if ((!carrierOffer.chargeSet?.ratesFetchedRecordId
                            && carrierOffer.chargeSet?.transitTime)
                            || (!carrierOffer.chargeSet?.ratesFetchedRecordId
                                && carrierOffer.chargeSet?.transShipments
                                && carrierOffer.chargeSet?.transShipments.length !== 0)) {
                            source = getText("SlcOfferOperationalSourcePl");
                            lastUpdate = carrierOffer.chargeSet.creationDate;
                        }
                        else if (routeConfig) {
                            if (routeConfig.source === "ScheduleSystem") {
                                source = getText("SlcOfferOperationalSourceApi");
                            }
                            if (routeConfig.source === "CarrierRates") {
                                source = getText("SlcOfferOperationalSourceOcq");
                            }
                            lastUpdate = routeConfig.lastUpdate;
                        }
      

                        return <Tooltip
                            trigger={['hover']}
                            overlay={<div>
                                <div>{getText("SlcOfferLastUpdate")}{lastUpdate && Moment(lastUpdate).format("DD/MM/YYYY")}</div>
                                <div>
                                    {getText("SlcOfferOperationalSource")}{" "}{source}
                                </div>
                            </div>}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div style={{
                                    ...(props.filterOptions.minTransitTime === transitTime
                                        ? { fontWeight: 700, color: Colors.baseColors.lightBlue }
                                        : { fontWeight: 500 }),
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}>
                                    <div data-rc={routeConfig?.routeConfigId} style={{ flex: "0 0 auto", minWidth: 16 }}>{transitTime || 'N/A'}</div>
                                </div>
                            </div>
                        </Tooltip>;
                    }
                },
                {
                    header: props.multiPortIsActive
                        ? <div style={{ fontWeight: "bold", paddingLeft: 18 }}>
                            {props.carrierOffersGrouped
                                .reduce((a, b) => a.concat(b), [])
                                .some(x => x.chargeSet
                                    && canAddToMultiPort(x, props.multiPortIsLoading, props.multiPortOffers))
                                ?
                                <AddShoppingCart onClick={(e) => {
                                    props.requestAddAllOffersToMultiPort();
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                    style={{ color: "white", cursor: "pointer" }} />
                                : <AddShoppingCart style={{ color: "white", cursor: "pointer" }} />}
                        </div>
                        : ' ',
                    id: 'actions',
                    align: "center",
                    accessor: (d) => d,
                    sortingDisabled: true,
                    cell: (d: any) => {
                        let carrierOffer = d as Api.CarrierOfferModel;
                        return <div style={{ textAlign: "center" }}>
                            {props.multiPortIsActive && carrierOffer.chargeSet
                                ?
                                canAddToMultiPort(carrierOffer, props.multiPortIsLoading, props.multiPortOffers)
                                    ?
                                    <AddShoppingCart style={{ color: Colors.baseColors.lightBlue }} onClick={(e) => {
                                        props.requestAddOfferToMultiPort(carrierOffer);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }} />
                                    :
                                    <AddShoppingCart style={{ color: Colors.baseColors.lightGray }} />
                                : null}
                        </div>;
                    }
                }
            ] as Array<OfferColumn>).filter(x => x); //Want to remove column when null
};

export default class OfferTable extends React.Component<OfferTableProps, {}> {
    private ratesCalculator: RatesCalculator;

    onPropsUpdate(props: OfferTableProps) {
        this.ratesCalculator = new RatesCalculator(props.currencies,
            props.sizeTypes,
            props.criteriaModel);
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: OfferTableProps) {
        if (this.props.criteriaModel !== nextProps.criteriaModel
            || this.props.currencies !== nextProps.currencies)
            this.onPropsUpdate(nextProps);
    }

    public render() {
        return <Table
            tableKey={"selectionOffers"}
            size={"small"}
            data={this.props.byRatesOffer
                ? this.props.carrierOffersGrouped.map(x => ({ ...x[0], subRows: x.slice(1, x.length) }))
                : this.props.carrierOffersGrouped.reduce((a, b) => a.concat(b), [])}
            columns={getColumns(this.props, this.ratesCalculator)}
            showPagination={false}
            headTrStyle={{ height: 50 }}
            bodyTrStyle={(d: Api.CarrierOfferModel) => ({
                height: 30,
                ...(d.chargeSet && d.chargeSet.routeSuggestedCalcId
                    ? { fontStyle: "italic" }
                    : {})
            })}
            onThClick={(col: OfferColumn) => {
                if (col.carrierOfferSort) {
                    this.props.selectSortBy(col.carrierOfferSort);
                }
            }}
            onBodyTrClick={(carrierOffer: Api.CarrierOfferModel) => {
                if (carrierOffer) {
                    this.props.handleOfferClick(getCarrierOfferKey(carrierOffer));
                    if (keyboard.isKeyPressed(17)) {
                        //do not comment this, its useful for debug
                        console.log(JSON.stringify(carrierOffer));
                    }
                }
            }}
        />
    }
}