import * as Api from "../api/api";
import { ChargeModification } from "./ratesUtils";

export const chargeAgentsToMoficiations = (chargeAgents: Array<Api.ChargeAgentModel>)
    : Array<ChargeModification> => {
    if (!chargeAgents)
        return [];

    return chargeAgents.map(ca => {
        if (ca.chargeAgentType === "Value")
            return {
                amount: ca.amount,
                currencyId: ca.currencyId,
                name: ca.name,
                modificationType: "Add",
                sizeTypeId: ca.sizeTypeId,
                application: ca.application,
                unit: ca.unit,
                criteria: ca.criteria,
            } as ChargeModification;
        else if (ca.chargeAgentType === "Modification") {
            return {
                amount: ca.amount,
                currencyId: ca.currencyId,
                modificationType: ca.modificationType,
                sizeTypeId: ca.sizeTypeId,
                chargeNameId: ca.chargeNameId,
            } as ChargeModification;
        }
    });
}
