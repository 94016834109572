import * as React from 'react';
import * as InlandSelectionStore from '../store/InlandSelection';
import * as Api from '../api/api';
import * as Colors from "../styles/colors";
import { OfferScropePrivate, OfferScropePublic } from './OfferScope';
import { RatesCalculator } from '../utils/ratesUtils';
import {getChargeAmount, getLocationName} from '../utils/utils';
import { getText } from "../utils/langTexts";
import { getInlandOfferKey } from '../store/InlandSelection';
import { getAllpliesTo } from './InlandOfferTable';
import ExpensionPanel from './ExpansionPanel';
import { roadTransportId } from '../utils/constants';
import { ArrowDropDown, ArrowDropUp, Warning } from '@material-ui/icons';
import Linkify from "react-linkify";


interface InlandCarrierOfferProps {
    ratesCalculator: RatesCalculator;
    quotationIsLoaded: boolean;
    client: Api.ClientModel;
    inlandCarriers: { [id: number]: Api.InlandCarrierModel };
    currencies: { [id: number]: Api.CurrencyModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    inlandCriteria: Api.InlandCriteriaModel;
    inlandCarrierOffer: Api.InlandCarrierOfferModel;
    inlandCarrierOfferState: InlandSelectionStore.InlandCarrierOfferState;
    requestDownloadDocument: () => void;

    openDetails: () => void;
    closeDetails: () => void;

    openRatesDetails: () => void;
    closeRatesDetails: () => void;

    openRatesComment: () => void;
    loadQuotationInlandChargeSet: () => void;

    openContainerDetails: (sizeTypeId: number) => void;
    closeContainerDetails: (sizeTypeId: number) => void;
    toggleDocumentInfoDetails: (id: number, value: boolean) => void;
}

interface RequestState {
    isLoading: boolean;
    requestTime?: number;
}

interface RequestState {
    isLoading: boolean;
    requestTime?: number;
}

const priceStyle: React.CSSProperties = {
    textAlign: "center",
    fontWeight: "bold"
};

const titleStyle: React.CSSProperties = {
    fontSize: 20,
    marginBottom: 10,
    fontWeight: "bold",
    color: Colors.baseColors.lightBlue
};

const fieldStyle: React.CSSProperties = {
    margin: "8px 0px",
    display: "flex",
    flexDirection: "row",

};
const fieldLabelStyle: React.CSSProperties = {
    fontWeight: "bold",
    flex: "0 0 200px",
    textAlign: "right",
    marginRight: 5
};
const fieldValueStyle: React.CSSProperties = {
    flex: 2
};

const chargeFieldStyle: React.CSSProperties = {
    margin: "4px 0px",
    display: "flex",
    minWidth: "375px",
    flexDirection: "row",

};
const chargeFieldLabelStyle: React.CSSProperties = {
    fontWeight: "bold",
    flex: "0 0 200px",
    textAlign: "right",
    marginRight: 5
};
const chargeFieldValueStyle: React.CSSProperties = {
    flex: 2
};
const detailsStyleButton: React.CSSProperties = {
    cursor: "pointer",
    border: "1px solid",
    borderRadius: 20,
    borderColor: `${Colors.baseColors.darkGray}`,
    backgroundColor: "white",
    color: `${Colors.baseColors.lightBlue}`,
    fontWeight: "bold",
    flex: "0 0 auto",
    marginRight: 10,
    height: 36,
    width: 100,
    minWidth: 76,
    padding: "0 5px 5px 0px",
    fontSize: 15,
    paddingTop: 2,
    position: "relative",
    bottom: 16,
};

const useQuotationStyleButton: React.CSSProperties = {
    cursor: "pointer",
    border: "1px solid",
    borderRadius: 20,
    borderColor: `${Colors.baseColors.darkGray}`,
    backgroundColor: `${Colors.baseColors.lightBlue}`,
    color: "white",
    fontWeight: "bold",
    flex: "0 0 auto",
    marginLeft: 60,
    marginRight: 10,
    height: 36,
    minWidth: 160,
    padding: "0 5px 5px 0px",
    position: "relative",
    bottom: 16,
    fontSize: 15,
    paddingTop: 2,
};

const labelStyle: React.CSSProperties = {
    minWidth: 200
    , textAlign: "right"
    , marginRight: 5
    , fontWeight: 700
}

const ratesTitleStyle: React.CSSProperties = {
    fontWeight: 700
}

const ratesDetailsField: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 16
}

const ratesDetailsLabel: React.CSSProperties = {
    textAlign: "right",
    marginRight: 6,
    fontWeight: 700,
    minWidth: 200
}

const ratesDetailsValue: React.CSSProperties = {
    marginLeft: 30
}

interface InlandCarrierOfferState {
    isLogoVisible: boolean;
    isLoading: boolean;
    downloadDocumentState: RequestState;
}

export default class InlandCarrierOffer extends React.Component<InlandCarrierOfferProps, InlandCarrierOfferState> {

    private ratesCalculator: RatesCalculator;

    constructor(props: InlandCarrierOfferProps) {
        super(props);
        //this.ratesCalculator = new RatesCalculator(this.props.currencies, this.props.sizeTypes, this.props.inlandCriteria);
        this.state = {
            isLogoVisible: true,
            isLoading: false,
            downloadDocumentState: { isLoading: false },
        };
    }

    get inlandCarrierOfferState(): InlandSelectionStore.InlandCarrierOfferState {
        return {
            ...InlandSelectionStore.unloadedInlandCarrierOfferState,
            ...this.props.inlandCarrierOfferState,
        };
    }

    getSource(chargeNameId: number, sizeTypeId: number): Api.ChargeModel {
        return this.props.ratesCalculator
            .findChargesToApplybySizeId(this.props.inlandCarrierOffer.inlandChargeSet, sizeTypeId)
            .find(x => x.chargeNameId === chargeNameId);
    }

    public render() {
        return (
            <div id={getInlandOfferKey(this.props.inlandCarrierOffer)} style={{ marginBottom: 20 }}>
                <div style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center", padding: 5,height: 64,
                    borderRadius: "5px",
                    border: "1px solid black",
                }}>
                    <div style={{ flex: 0.5, textAlign: "center", paddingLeft: 15 }}>
                        {this.props.inlandCarrierOffer.inlandChargeSet
                            && this.props.inlandCarrierOffer.inlandChargeSet.firmId
                            && <div style={{ color: Colors.colors.main3.color }}>
                                <OfferScropePrivate />
                            </div>}
                        {this.props.inlandCarrierOffer.inlandChargeSet
                            && !this.props.inlandCarrierOffer.inlandChargeSet.firmId
                            && <div style={{ color: Colors.colors.main3.color }}>
                                <OfferScropePublic />
                            </div>}
                    </div>
                    <div style={{ flex: 1, textAlign: "center" }}>
                        {this.props.inlandCarrierOffer.inlandChargeSet
                            && this.props.inlandCriteria.priceDisplayMode === "AllIn"
                            && <div style={{ ...priceStyle, color: Colors.colors.main3.color }}>
                                {Math.round(this.props.ratesCalculator.calculateAllIn(this.props.inlandCarrierOffer.inlandChargeSet).totalUsd
                                    / this.props.currencies[this.props.client.inlandCurrencyId].value)}{" "}
                                {this.props.currencies[this.props.client.inlandCurrencyId].code}
                            </div>}
                        {this.props.inlandCarrierOffer.inlandChargeSet
                            && this.props.inlandCriteria.priceDisplayMode === "ByCtr"
                            && <div style={{
                                ...priceStyle,
                                color: `${Colors.baseColors.lightBlue}`,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                flexWrap: "wrap"
                            }}>
                                {this.props.inlandCriteria.inlandCriteriaSizeTypes
                                    .filter(cst => cst.number > 0)
                                    .map(cst => <div style={{ flex: "0 0 auto", marginRight: 6 }}>
                                        {this.props.sizeTypes[cst.sizeTypeId].name}
                                        {": "}{Math.round(this.props.ratesCalculator.calculatePrice(
                                            this.props.ratesCalculator.findChargesToApply(
                                                this.props.inlandCarrierOffer.inlandChargeSet, cst),
                                            this.props.sizeTypes[cst.sizeTypeId].teu).totalUsd
                                            / this.props.currencies[this.props.client.inlandCurrencyId].value)}{" "}
                                        {this.props.currencies[this.props.client.inlandCurrencyId].code}
                                    </div>)}
                            </div>}
                        {this.props.inlandCarrierOffer.offerInformations
                            && this.props.inlandCarrierOffer.offerInformations.some(x => x.level === "High")
                            && <Warning />}
                        {!this.props.inlandCarrierOffer.inlandChargeSet
                            && <div style={{ ...priceStyle, color: `${Colors.baseColors.darkGray}` }}>
                                No Rates
                                </div>}
                    </div>
                    <div style={{ flex: 2, fontWeight: "bold", color: `${Colors.baseColors.lightBlue}` }}>
                        {this.props.inlandCarriers[this.props.inlandCarrierOffer.inlandCarrierId].name}
                    </div>
                    <div style={{ flex: 3 }}>
                        {getText("SlcInlandOfferTitle", {
                            origin: this.props.inlandCarrierOffer.inlandRoute.pickupLocation.name,
                            destination: this.props.inlandCarrierOffer.inlandRoute.dropOffLocation.name,
                        })}
                    </div>
                    <img height='50'
                        style={{ display: this.state.isLogoVisible ? "inline" : "none" }}
                        onError={() => this.setState({ isLogoVisible: false })}
                        src={'/images/inlandCarriers/' + this.props.inlandCarriers[this.props.inlandCarrierOffer.inlandCarrierId].code.toLowerCase() + '.png'} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                    <div style={{
                        borderLeft: `1px solid ${Colors.baseColors.lightGray}`,
                        borderRight: `1px solid ${Colors.baseColors.lightGray}`,
                        display: "flex", flexDirection: "column",
                        alignItems: "stretch",
                        flex: "0 0 auto"
                    }}>
                        <div style={{
                            display: "flex", flexDirection: "row",
                            alignItems: "center", padding: "25px 20px"
                        }}>
                            <div style={{ flex: 2 }}>
                                {getText("SlcInlandOfferRouteType")} <span style={{ fontWeight: "bold" }}>
                                    {this.props.inlandCarrierOffer.inlandRoute.type === "TruckRoundTrip" && "Truck round trip"}
                                    {this.props.inlandCarrierOffer.inlandRoute.type === "TruckOneWay" && "Truck one way"}
                                    {this.props.inlandCarrierOffer.inlandRoute.type === "BargeTruckOneWay" && "Barge truck one way"}
                                    {this.props.inlandCarrierOffer.inlandRoute.type === "RailTruckOneWay" && "Rail truck one way"}
                                </span>
                            </div>
                            <div style={{ flex: 2 }}>
                                {getText("SlcInlandOfferApplyTo")} <div style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
                                    {getAllpliesTo(this.props.inlandCarrierOffer.inlandChargeSet, this.props.inlandCriteria, this.props.sizeTypes)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        borderLeft: `1px solid ${Colors.baseColors.lightGray}`,
                        borderRight: `1px solid ${Colors.baseColors.lightGray}`,
                    }}>
                        <ExpensionPanel expanded={this.props.inlandCarrierOfferState?.detailsOpened ? true : false}
                            onChange={() => { /*manage from store*/ }}
                            head={<div></div>}>
                            <div style={{
                                display: "flex", flexDirection: "row",
                                alignItems: "stretch", padding: "20px 20px",
                                flex: "0 0 auto",
                            }}>
                                <ExpensionPanel expanded={this.props.inlandCarrierOfferState?.detailsOpened ? true : false}
                                    onChange={() => { /*manage from store*/ }}
                                    head={<div></div>}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "start",
                                        width: "100%"
                                    }}>
                                        <div style={{ flex: "0 0 auto", paddingRight: 100, minWidth: 410 }}>
                                            <div style={{ fontWeight: 500, fontSize: 20, marginBottom: 30, color: `${Colors.baseColors.darkGray}` }}>
                                                {getText("SlcOfferVoyageDetails")}
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <div style={{ ...labelStyle, flex: "0 0 auto", }}>{getText("SlcOfferPol")}</div>
                                                <div style={{ flex: "0 0 auto" }}>{getLocationName(this.props.inlandCarrierOffer.inlandRoute.pickupLocation)}</div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <div style={{ ...labelStyle, flex: "0 0 auto" }}>{getText("SlcOfferPod")}</div>
                                                <div style={{ flex: "0 0 auto" }}>{getLocationName(this.props.inlandCarrierOffer.inlandRoute.dropOffLocation)}</div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <div style={{ ...fieldLabelStyle }}>{getText("SlcInlandOfferRouteType")}</div>
                                                <div style={{ ...fieldValueStyle }}>
                                                    {this.props.inlandCarrierOffer.inlandRoute.type === "TruckRoundTrip" && "Truck round trip"}
                                                    {this.props.inlandCarrierOffer.inlandRoute.type === "TruckOneWay" && "Truck one way"}
                                                    {this.props.inlandCarrierOffer.inlandRoute.type === "BargeTruckOneWay" && "Barge truck one way"}
                                                    {this.props.inlandCarrierOffer.inlandRoute.type === "RailTruckOneWay" && "Rail truck one way"}
                                                </div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <div style={{ ...fieldLabelStyle }}>{getText("SlcInlandOfferApplyTo")}</div>
                                                <div style={{ ...fieldValueStyle }}>{getAllpliesTo(this.props.inlandCarrierOffer.inlandChargeSet, this.props.inlandCriteria, this.props.sizeTypes)}</div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <div style={{ ...fieldLabelStyle }}>{getText("SlcInlandOfferPickUpEmpty")}</div>
                                                <div style={{ ...fieldValueStyle }}>
                                                    {this.props.inlandCarrierOffer.inlandChargeSet
                                                        && this.props.inlandCarrierOffer.inlandChargeSet.pickupEmptyName
                                                        ? this.props.inlandCarrierOffer.inlandChargeSet.pickupEmptyName
                                                        : getText("GenNone")}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}></div>
                                        <div style={{}}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                marginBottom: 30
                                            }}>
                                                <div style={{ fontWeight: 500, fontSize: 20, marginRight: 40 }}>{getText("SlcOfferRatesDetails")}</div>

                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", flexWrap: "wrap", rowGap: 16, columnGap: 60 }}>
                                                <div style={{ flex: "0 0 auto" }}>
                                                    {/*FCL charges details*/}
                                                    {this.props.inlandCriteria?.inlandCriteriaSizeTypes
                                                        .filter(cst => cst.number > 0
                                                            && this.props.inlandCarrierOffer.inlandChargeSet
                                                                && this.props.ratesCalculator.findChargesToApply(this.props.inlandCarrierOffer.inlandChargeSet,
                                                                    cst,
                                                                    null).some(x => x.chargeNameId === roadTransportId)).map(cst =>
                                                                        <div key={cst.sizeTypeId} style={{ marginBottom: 16 }}>
                                                                            <ExpensionPanel expanded={this.props.inlandCarrierOfferState?.sizeTypesOpened[cst.sizeTypeId] ? true : false}
                                                                                onChange={() => {
                                                                                    if (this.props.inlandCarrierOfferState?.sizeTypesOpened[cst.sizeTypeId] ? true : false) { this.props.closeContainerDetails(cst.sizeTypeId) }
                                                                                    else {
                                                                                        this.props.openContainerDetails(cst.sizeTypeId)
                                                                                    }
                                                                                }}
                                                                                head={<div style={{
                                                                                    ...ratesTitleStyle,
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                    alignItems: "center",
                                                                                    cursor: "pointer",
                                                                                    color: Colors.baseColors.lightBlue,
                                                                                    marginBottom: 16
                                                                                }}>
                                                                                    <div style={{ flex: 1 }}>
                                                                                        {getText("SlcOfferRatesCtr", {
                                                                                            containerName: this.props.sizeTypes[cst.sizeTypeId].name,
                                                                                            value: this.props.inlandCarrierOffer.inlandChargeSet
                                                                                                ? (Math.round(this.props.ratesCalculator.calculatePrice(
                                                                                                    this.props.ratesCalculator.findChargesToApply(
                                                                                                        this.props.inlandCarrierOffer.inlandChargeSet,
                                                                                                        cst, null),
                                                                                                    this.props.sizeTypes[cst.sizeTypeId].teu).totalUsd /
                                                                                                    this.props.currencies[this.props.client.inlandCurrencyId].value) + " " + this.props.currencies[this.props.client.inlandCurrencyId].code)
                                                                                                : (!this.props.inlandCarrierOffer.inlandChargeSet ? getText("SlcOfferRatesNoRates") : "Disabled")
                                                                                        })}
                                                                                    </div>
                                                                                    <div style={{ flex: "0 0 auto" }}>
                                                                                        {this.props.inlandCarrierOfferState?.sizeTypesOpened[cst.sizeTypeId]
                                                                                            ? <ArrowDropUp />
                                                                                            : <ArrowDropDown />}
                                                                                    </div>
                                                                                </div>}>
                                                                                <div>
                                                                                    {this.props.ratesCalculator.findChargesToApply(this.props.inlandCarrierOffer.inlandChargeSet, cst)
                                                                                        .concat(this.props.ratesCalculator.findBlChargesToApply(this.props.inlandCarrierOffer.inlandChargeSet))
                                                                                        .map(charge => <div style={{ ...chargeFieldStyle }}>
                                                                                            <div style={{ ...chargeFieldLabelStyle }}>{this.props.chargeNames[charge.chargeNameId].shortName}:</div>
                                                                                            <div style={{ ...chargeFieldValueStyle }}>
                                                                                                {charge.chargeType === "Value"
                                                                                                    && <div>
                                                                                                        {getChargeAmount(charge, this.props.sizeTypes[cst.sizeTypeId].teu)}
                                                                                                        {" "}
                                                                                                        {this.props.currencies[charge.currencyId].code}
                                                                                                    </div>}
                                                                                                {charge.chargeType === "Source"
                                                                                                    && charge.type === "Incl"
                                                                                                    && <div>{getText("GenIncluded", { value: this.props.chargeNames[charge.sourceChargeNameId].shortName })}</div>}
                                                                                                {charge.chargeType === "Source"
                                                                                                    && charge.type === "Percentage"
                                                                                                    && <div>
                                                                                                        {this.getSource(charge.sourceChargeNameId, cst.sizeTypeId)
                                                                                                            ? <span>
                                                                                                                {Math.round(getChargeAmount(this.getSource(charge.sourceChargeNameId, cst.sizeTypeId),
                                                                                                                    this.props.sizeTypes[cst.sizeTypeId].teu) * charge.amount * 100) / 100}
                                                                                                                {" "}
                                                                                                                {this.props.currencies[this.getSource(charge.sourceChargeNameId, cst.sizeTypeId).currencyId].code}
                                                                                                            </span>
                                                                                                            : ""}
                                                                                                        {" "}
                                                                                                    ({Math.round(charge.amount * 100 * 100) / 100}% of {this.props.chargeNames[charge.sourceChargeNameId].shortName})
                                                                                                        </div>}
                                                                                            </div>
                                                                                        </div>)}
                                                                                </div>
                                                                            </ExpensionPanel>
                                                                        </div>)}
                                                </div>
                                                <div>
                                                    <ExpensionPanel expanded={this.props.inlandCarrierOfferState?.ratesDetailsOpened ? true : false}
                                                        onChange={() => {
                                                            if (this.props.inlandCarrierOfferState?.ratesDetailsOpened ? true : false) { this.props.closeRatesDetails() }
                                                            else {
                                                                this.props.openRatesDetails()
                                                            }
                                                        }}
                                                        head={<div>
                                                            <div style={{
                                                                ...ratesTitleStyle,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                cursor: "pointer",
                                                                color: Colors.baseColors.lightBlue,
                                                                marginBottom: 16
                                                            }}>
                                                                <div style={{ flex: 1 }}>
                                                                    {getText("SlcOfferRatesInfo")}
                                                                </div>
                                                                <div style={{ flex: "0 0 auto" }}>
                                                                    {this.props.inlandCarrierOfferState?.ratesDetailsOpened
                                                                        ? <ArrowDropUp />
                                                                        : <ArrowDropDown />}
                                                                </div>
                                                            </div>
                                                        </div>}>
                                                        {this.props.inlandCarrierOffer.inlandChargeSet
                                                            ? <div style={{ margin: "16px 0px", maxWidth: 700 }}>
                                                                {<div style={{ ...ratesDetailsField }}>
                                                                    <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesOcqSource")}</div>
                                                                </div>}
                                                                <div style={{ ...ratesDetailsField }}>
                                                                    <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesReference")}</div>
                                                                    <div style={{ ...ratesDetailsValue }}>
                                                                        {this.props.inlandCarrierOffer.inlandChargeSet.reference || <i>No reference</i>}
                                                                    </div>
                                                                </div>
                                                                <div style={{ ...ratesDetailsField }}>
                                                                    <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesShortName")}</div>
                                                                    <div style={{ ...ratesDetailsValue }}>
                                                                        {(this.props.inlandCarrierOffer.inlandRatesOffer &&
                                                                            this.props.inlandCarrierOffer.inlandRatesOffer.shortName) || <i>Not defined</i>}
                                                                    </div>
                                                                </div>
                                                                <div style={{ ...ratesDetailsField, alignItems: "start" }}>
                                                                    <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesDescription")}</div>
                                                                    <div style={{ ...ratesDetailsValue }}>
                                                                        <p style={{ whiteSpace: "pre-wrap" }}>
                                                                            {this.props.inlandCarrierOffer.inlandRatesOffer
                                                                                && this.props.inlandCarrierOffer.inlandRatesOffer.comment
                                                                                ? this.props.inlandCarrierOffer.inlandRatesOffer.comment
                                                                                : <i>Not defined</i>}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                {(this.props.inlandCarrierOffer.offerInformations || [])
                                                                        .map(di => <div key={di.offerInformationId} style={{ marginTop: 6 }}>
                                                                            <div style={{ ...ratesDetailsField, alignItems: "start" }}>
                                                                                <div style={{ ...ratesDetailsLabel }}>
                                                                                    {di.level === "High"
                                                                                        && <Warning style={{ color: Colors.baseColors.lightBlue, height: 14 }} />}
                                                                                    {getText("SlcOfferRatesSpecificInfoTitle")}
                                                                                </div>
                                                                                <div style={{ ...ratesDetailsValue }}>
                                                                                    <ExpensionPanel expanded={this.props.inlandCarrierOfferState
                                                                                        && this.props.inlandCarrierOfferState.documentInfoDetailsOpened[di.offerInformationId] ? true : false}
                                                                                        onChange={(value) => this.props.toggleDocumentInfoDetails(di.offerInformationId, value)}
                                                                                        head={<div><u>{getText("SlcOfferRatesInfoDetails")}</u></div>}>
                                                                                        <p style={{ whiteSpace: "pre-wrap" }}>
                                                                                            <Linkify properties={{ target: "_blank" }}>{di.content}</Linkify>
                                                                                        </p>
                                                                                    </ExpensionPanel>
                                                                                </div>
                                                                            </div>
                                                                        </div>)}
                                                                <div style={{
                                                                    marginTop: 12,
                                                                    color: Colors.baseColors.lightBlue,
                                                                    textDecoration: "underline",
                                                                    fontSize: 18,
                                                                    cursor: "pointer",
                                                                    textAlign: "right",
                                                                    maxWidth: 400
                                                                }}
                                                                    onClick={(e) => {
                                                                        if (!this.props.inlandCarrierOfferState?.downloadDocumentState.isLoading ? true : false) {
                                                                            this.props.requestDownloadDocument();
                                                                        }
                                                                    }}>
                                                                    {getText("SlcOfferRatesDownloadSheet")}
                                                                </div>
                                                            </div>
                                                            : <div style={{ margin: "16px 0px" }}>{getText("SlcOfferRatesNoRates")}</div>}
                                                    </ExpensionPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ExpensionPanel>
                            </div>
                        </ExpensionPanel>
                    </div>
                    <div style={{
                        flex: 3, textAlign: "center",
                        borderTop: `1px solid ${Colors.baseColors.lightGray}`,
                    }}>
                        <button style={detailsStyleButton}
                            onClick={() => {
                                if (this.props.inlandCarrierOfferState?.detailsOpened ? true : false) {
                                    this.props.closeDetails();
                                } else {
                                    this.props.openDetails();
                                }
                            }}>
                            {this.props.inlandCarrierOfferState?.detailsOpened ? getText("SlcOfferDetails") + " –"
                                : getText("SlcOfferDetails") + " +"}
                        </button>
                        <button style={{
                            ...useQuotationStyleButton,
                            ...((!this.props.quotationIsLoaded
                                || !this.props.inlandCarrierOffer.inlandChargeSet)
                                ? { cursor: "not-allowed", backgroundColor: Colors.baseColors.lightGray }
                                : {})
                        }}
                            disabled={!this.props.quotationIsLoaded
                                || !this.props.inlandCarrierOffer.inlandChargeSet}
                            onClick={() => {
                                this.props.loadQuotationInlandChargeSet();
                            }}>
                            {getText("SlcInlandUseInQuot")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}