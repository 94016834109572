import * as React from "react";
import * as Api from "../api/api";
import * as _ from "lodash";
import { CarrierOfferState } from "../store/Selection";
import Select  from "./Select";
import { getRouteConfig } from "../utils/routeUtils";

interface RouteServiceSelectProps {
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    carrierOffer: Api.CarrierOfferModel;
    carrierOfferState: CarrierOfferState;
    selectRouteSerice: (routeServiceId: number) => void;
}

interface RouteServiceSelectState {
}

export default class RouteServiceSelect extends React.Component<RouteServiceSelectProps, RouteServiceSelectState> {

    get routeConfig(): Api.RouteConfigModel {
        return getRouteConfig(this.props.carrierOffer, this.props.carrierOfferState);
    }

    public render() {

        return (
            <Select
                style={this.props.style}
                inputStyle={this.props.inputStyle}
                options={_.uniqBy(this.props.carrierOffer.routeConfigs
                    .map(x => x.routeService
                        ? ({ label: x.routeService.name, value: x.routeService.routeServiceId })
                    : ({ label: '---', value: null })), x => x.value)}
                onChange={(routeServiceId: number) => this.props.selectRouteSerice(routeServiceId)}
                value={this.routeConfig.routeService
                    ? this.routeConfig.routeServiceId
                    : null} />
        );
    }
}