class TasksUtils {
    private taskList = [];

    private reset(): void {
        this.taskList = [];
    }

    public tasksPromises(): Promise<any> {
        return Promise.all(this.taskList)
            .then(() => this.taskList = [])
            .catch(() => this.taskList = []);
    }

    public addTask(task: Promise<any>) {
        this.taskList.push(task);
    }
}


export const tasksUtils = new TasksUtils(); 

export function addTask(task: Promise<any>): void {
    tasksUtils.addTask(task);
} 