import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as InlandSelectionStore from '../store/InlandSelection';
import * as InlandCriteriaState from '../store/InlandCriteria';
import * as ContactBoxStore from '../store/ContactBox';
import * as QuotationStore from '../store/Quotation';
import * as Api from '../api/api';
import { RatesCalculator } from '../utils/ratesUtils';
import { getSubscription } from '../utils/utils';
import { scrollToWhen } from 'react-redux-scroll';
import Pagination from '@material-ui/lab/Pagination';
import { revertColors } from '../styles/colors';
import { getInlandOfferKey } from '../store/InlandSelection';
import InlandCarrierOffer from './InlandCarrierOffer';
import DialogModal from './DialogModal';
import Button from './Button';

// At runtime, Redux will merge together...
type InlandSelectionOffersProps =
    InlandSelectionOffersOwnProps
    & InlandSelectionStore.InlandSelectionState
    & typeof InlandSelectionStore.actionCreators
    & typeof InlandCriteriaState.actionCreators
    & typeof ContactBoxStore.actionCreators
    & typeof QuotationStore.actionCreators
    & InlandSelectionOffersExternalProps;

interface InlandSelectionOffersOwnProps {
    feedback: ContactBoxStore.FeedbackState;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    inlandCarriers: { [id: number]: Api.InlandCarrierModel };
    carrierGroups: { [id: number]: Api.CarrierGroupModel };
    client: Api.ClientModel;
    inlandCriteriaLoaded?: Api.CriteriaModel;
    inlandCriteria: InlandCriteriaState.InlandCriteriaState;
    subscription: Api.SubscriptionModel;
    quotationIsLoaded: boolean;
}

interface InlandSelectionOffersExternalProps {
    ratesCalculator: RatesCalculator;
    inlandCarrierOffersFiltered: Array<Api.InlandCarrierOfferModel>;
}


const offerPerPage = 6;

const isOfferSelected = (action, props): boolean => {
    if (action.type === "SELECT_INLAND_CARRIEROFFER"
        && props.id === action.payload.key) {
        return true;
    }

    return false;
}
const ScrollableOffer =
    scrollToWhen(isOfferSelected, null, null, ["id"])("div");

const importantMessageStyle: React.CSSProperties = {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center"
};

class InlandSelectionOffers extends React.Component<InlandSelectionOffersProps, {}> {

    constructor(props) {
        super(props);
    }

    scrollToOfferDeparts(inlandOfferId: string) {
        this.props.selectPage(Math.floor(this.props.inlandSelection.inlandCarrierOffers
            .findIndex(x => getInlandOfferKey(x) === inlandOfferId) / offerPerPage) + 1);
        setTimeout(() => this.props.selectInlandCarrierOffer(inlandOfferId), 0);
    }

    public render() {
        return (
            <div>
                <div>
                    <div>
                        {this.props.inlandCarrierOffersFiltered
                            .slice((this.props.page - 1) * offerPerPage, this.props.page * offerPerPage)
                            .map((offer, i) =>
                                <ScrollableOffer
                                    id={getInlandOfferKey(offer)}
                                    key={getInlandOfferKey(offer) + i}>
                                    <InlandCarrierOffer
                                        client={this.props.client}
                                        quotationIsLoaded={this.props.quotationIsLoaded}
                                        chargeNames={this.props.chargeNames}
                                        currencies={this.props.currencies}
                                        inlandCarriers={this.props.inlandCarriers}
                                        sizeTypes={this.props.sizeTypes}
                                        inlandCarrierOffer={offer}
                                        inlandCarrierOfferState={this.props.inlandCarrierOffersState[getInlandOfferKey(offer)]}
                                        ratesCalculator={this.props.ratesCalculator}
                                        inlandCriteria={this.props.inlandCriteria.inlandCriteria}
                                        loadQuotationInlandChargeSet={() => this.props.openUseInQuotation(getInlandOfferKey(offer))}
                                        requestDownloadDocument={() => this.props.requestDownloadRatesDocument(new Date().getTime(), getInlandOfferKey(offer))}

                                        openRatesComment={() => this.props.openInlandOfferRatesComment(getInlandOfferKey(offer))}

                                        openRatesDetails={() => this.props.openInlandOfferRatesDetails(getInlandOfferKey(offer))}
                                        closeRatesDetails={() => this.props.closeInlandOfferRatesDetails(getInlandOfferKey(offer))}

                                        openDetails={() => this.props.openInlandOfferDetails(getInlandOfferKey(offer))}
                                        closeDetails={() => this.props.closeInlandOfferDetails(getInlandOfferKey(offer))}

                                        openContainerDetails={(sizeTypeId) => this.props.openInlandOfferContainerDetails(getInlandOfferKey(offer), sizeTypeId)}
                                        closeContainerDetails={(sizeTypeId) => this.props.closeInlandOfferContainerDetails(getInlandOfferKey(offer), sizeTypeId)}
                                        toggleDocumentInfoDetails={(id, val) => this.props.toggleDocumentInfoDetails(getInlandOfferKey(offer), id, val)}
                                    />
                                </ScrollableOffer>
                            )}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Pagination
                            style={{ maxWidth: 400 }}
                            page={this.props.page}
                            count={Math.ceil(this.props.inlandCarrierOffersFiltered.length / offerPerPage)}
                            onChange={(e, page: number) => this.props.selectPage(page)} />
                    </div>
                </div>
                <DialogModal isOpen={this.props.useInQuotationIsOpen}
                    maxWidth={"md"}
                    onRequestClose={() => this.props.closenUseInQuotation()}
                    contentLabel={"Use in quotation"}>
                    <div>
                        <div style={{ fontSize: 20, fontWeight: 500, marginBottom: 20 }}>
                            Transfer inland rates to my current quotation
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 60 }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Button
                                    onClick={() => {
                                        this.props.quotationUseInlandChargeSet(
                                            this.props.inlandSelection.inlandCarrierOffers.find(x => getInlandOfferKey(x) === this.props.selectedOffer).inlandChargeSet,
                                            "Origin");
                                    }}>
                                    Add to pre-transport (export)
                            </Button>
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Button style={{ ...revertColors.main3 }}
                                    onClick={() => {
                                        this.props.quotationUseInlandChargeSet(
                                            this.props.inlandSelection.inlandCarrierOffers.find(x => getInlandOfferKey(x) === this.props.selectedOffer).inlandChargeSet,
                                            "Destination");
                                    }}>
                                    Add to post-transport (import)
                            </Button>
                            </div>
                        </div>
                    </div>
                </DialogModal>
            </div>
        );
    }
}

//So its the same ref
const emptyArray = [];
//Filtering is done here, we'll use reselect for optimization later on, for now we don't care
export default connect(
    (state: ApplicationState) => {
        let subscription = getSubscription(state.account.currentUser.clientModel)
            || { subscriptionType: null };

        return {
            ...state.inlandSelection,
            feedback: state.contactBox.feedback,
            inlandCriteria: state.inlandCriteria,
            inlandCriteriaLoaded: state.inlandSelection.inlandCriteriaLoaded,
            sizeTypes: state.seed.sizeTypes,
            currencies: state.seed.currencies,
            chargeNames: state.seed.chargeNames,
            inlandCarriers: state.seed.inlandCarriers,
            carrierGroups: state.seed.carrierGroups,
            client: state.account.currentUser.clientModel,
            subscription: subscription,
            quotationIsLoaded: state.quotation.isLoaded,
        } as any;
    },
    {
        ...QuotationStore.actionCreators,
        ...InlandSelectionStore.actionCreators,
        ...InlandCriteriaState.actionCreators,
        ...ContactBoxStore.actionCreators
    })
    (InlandSelectionOffers) as any as React.ComponentClass<InlandSelectionOffersExternalProps>;