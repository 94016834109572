import * as React from 'react';
import InputText from "./InputText";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Guid} from "../utils/utils";
import {List, Paper} from "@material-ui/core";
import * as Colors from "../styles/colors";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface AutocompleteProps {
    value: any;
    onChange: (val: any) => void;
    inputValue: string;
    onInputValueChange: (val: string) => void;
    disabled?: boolean;
    items: Array<any>;
    emptyItems: () => void;
    getItemValue: (item: any) => any;
    getItemLabel: (item: any) => string;
    renderItem: (item: any, isHighlighted: boolean) => JSX.Element;
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    isLoading?: boolean;
}

export default class OkAutocomplete extends React.Component<AutocompleteProps, {}> {
    private guid: string;

    constructor(props: AutocompleteProps) {
        super(props);
        this.guid = Guid.newGuid();
        this.state = {
        };
    }
    
    handleInputChange(value: string) {
        value = value || "";
        this.props.onInputValueChange(value);
    }

    public render(){
        return (<Autocomplete
            style={{
                width: 250,
                ...this.props.style
            }}
            open={this.props.items.length !== 0}
            disabled={this.props.disabled}
            options={this.props.items}
            onBlur={(e) => { this.props.onChange(this.props.value) }}
            filterOptions={x => x}
            inputValue={this.props.inputValue}
            onInputChange={(e, value) => {
                //Prevent search on select
                if (e && (e.type === "click" || e.type === "blur")) { //duplicate call with onBlur
                    return;
                }
                if (!value) {
                    //console.log("changed input to null");
                    this.props.onChange(null);
                }
                this.handleInputChange(value);
            }}
            renderInput={(params) => (
                <InputText {...params}
                           InputProps={{
                               ...params.InputProps,
                               style: {
                                   height: 35,
                                   padding: 0,
                                   ...this.props.inputStyle,
                                   ...(this.props.items.length !== 0
                                       ? {
                                           borderBottom: 0
                                       }
                                       : {})
                               },
                               endAdornment: (
                                   <React.Fragment>
                                       {this.props.isLoading
                                           ? <CircularProgress color="inherit" size={20} />
                                           : null}
                                       {params.InputProps.endAdornment}
                                   </React.Fragment>
                               ),
                           }}
                />
            )}
            getOptionLabel={(option) => option ? this.props.getItemLabel(option) : "?"}
            popupIcon={null}
            getOptionSelected={(option: any, value: any) => option
                ? this.props.getItemValue(option) === value
                : false}
            PaperComponent={(props) => <Paper {...props}
                                              className="location-items"
                                              style={{
                                                  borderLeft: `1px solid ${Colors.baseColors.darkGray}`,
                                                  borderRight: `1px solid ${Colors.baseColors.darkGray}`,
                                                  borderBottom: `1px solid ${Colors.baseColors.darkGray}`,
                                                  borderBottomLeftRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  margin: 0,
                                                  padding: 0,
                                                  maxHeight: 300,
                                                  overflowY: 'auto',
                                              }}>
            </Paper>}
            ListboxComponent={(props) => <List {...props}
                                               style={{ ...props.style, overflow: "none", maxHeight: "none" }}>
            </List>}
            renderOption={(option: any, state) =>
                this.props.renderItem(option, state.selected)
            }
            onChange={(e, option) => this.props.onChange(this.props.getItemValue(option))} />);
    }
}