import * as React from 'react';
import * as _ from 'lodash';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import Moment from 'moment';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import {  getFirmName } from '../utils/utils';
import Select from "./Select";
import { getText } from '../utils/langTexts';
import * as Colors from '../styles/colors';
import Button from './Button';
import {hFieldStyle, vFieldStyle, vFormStyle} from "../styles/baseStyles";

type FirmNewsFormProps = ReduxForm.InjectedFormProps<{}, {}, string> & FirmNewsFormOwnProps & FirmNewsFormExternalProps;

interface FirmNewsFormExternalProps {
    actionName: string;
    hideReset?: boolean;
    onSubmit: (values: Api.FirmNewsModel) => void;
}

interface FirmNewsFormOwnProps {
    subscriptions: Array<Api.SubscriptionModel>;
    portsZones: { [id: number]: Api.PortsZoneModel };
}

const defaultFormValues = { dateBegin: Moment().add(0, "days").toDate(), dateEnd: Moment().add(30, "days").toDate() };

const textAreaField = FormField.textAreaField(4, 50);
const btnStyle = { marginRight: 10 };

export const firmNewsLevelOptions = (): Array<{ label: string; value: Api.FirmNewsModelLevelEnum }> => {
    return [
        { label: "Not important", value: "Low" },
        { label: "Normal", value: "Normal" },
        { label: "Important", value: "Important" },
    ];
}

const labelStyle: React.CSSProperties = { display: "block", minWidth: 120 };
class FirmNewsForm extends React.Component<FirmNewsFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div style={{ ...vFormStyle }}>
                    <div className="text-danger" style={{ textAlign: "center" }}>
                        {this.props.error && <span>{this.props.error}</span>}
                    </div>
                    <div style={{ ...hFieldStyle }}>
                        <label style={labelStyle} className="control-label">{getText("MdtNewsEditCompany")}</label>
                        <div className="">
                            <ReduxForm.Field className="form-control"
                                             name="firmId"
                                             validate={[FormField.required]}
                                             component={FormField.getSelectField(_.unionBy(this.props.subscriptions
                                                 .filter(su => su.subscriptionType === "Okargo")
                                                 .filter(x => x.agency.company.companyGroup)
                                                 .map(su => ({
                                                     label: getFirmName(su.agency.company.companyGroup),
                                                     value: su.agency.company.companyGroupId
                                                 })).concat(this.props.subscriptions
                                                     .filter(su => su.subscriptionType === "Okargo")
                                                     .map(su => ({
                                                         label: getFirmName(su.agency.company),
                                                         value: su.agency.companyId
                                                     })).concat(
                                                         this.props.subscriptions
                                                             .filter(su => su.subscriptionType === "Okargo")
                                                             .map(su => ({
                                                                 label: getFirmName(su.agency),
                                                                 value: su.agencyId
                                                             }))
                                                     )), x => `${x.value}`))}
                                             type="text" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }}>
                        <label style={labelStyle} className="control-label">{getText("MdtNewsEditFromDate")}</label>
                        <div className="">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="dateBegin"
                                             component={FormField.DatePickerField}
                                             type="date" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }}>
                        <label style={labelStyle} className="control-label">{getText("MdtNewsEditToDate")}</label>
                        <div className="">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="dateEnd"
                                             component={FormField.DatePickerField}
                                             type="date" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }}>
                        <label style={labelStyle}>{getText("MdtNewsEditFromZone")}</label>
                        <div className="">
                            <ReduxForm.Field className="form-control"
                                             name="originZoneId"
                                             component={(field: any) => (
                                                 <div style={{ width: "100%" }}>
                                                     <Select
                                                         options={_.sortBy(_.values(this.props.portsZones), x => x.name)
                                                             .map(pz => ({
                                                                 label: pz.name,
                                                                 value: pz.portsZoneId
                                                             }))}
                                                         value={field.input.value}
                                                         onChange={(value) => field.input.onChange(value)}
                                                         style={{ width: "16em"}}
                                                         search={true} />
                                                 </div>
                                             )}
                                             type="text" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }}>
                        <label style={labelStyle}>{getText("MdtNewsEditToZone")} </label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             name="destinationZoneId"
                                             component={(field: any) => (
                                                 <div style={{ width: "100%" }}>
                                                     <Select
                                                         options={_.sortBy(_.values(this.props.portsZones), x => x.name)
                                                             .map(pz => ({
                                                                 label: pz.name,
                                                                 value: pz.portsZoneId
                                                             }))}
                                                         value={field.input.value}
                                                         onChange={(value) => field.input.onChange(value)}
                                                         style={{ width: "16em" }}
                                                         search={true}
                                                     />
                                                 </div>
                                             )}
                                             type="text" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }}>
                        <label style={labelStyle} className="control-label">{getText("MdtNewsEditTitle")}</label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="title"
                                             component={FormField.InputTextField}
                                             type="text" />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }}>
                        <label style={{ ...labelStyle }} className="control-label">{getText("MdtNewsEditContent")}</label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="content"
                                             component={textAreaField} />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }}>
                        <label style={{ ...labelStyle }} className="col-sm-3 control-label">{getText("MdtNewsEditLevel")}</label>
                        <div className="col-sm-9">
                            <ReduxForm.Field className="form-control"
                                             validate={[FormField.required]}
                                             name="level"
                                             component={FormField.getSelectField(firmNewsLevelOptions())} />
                        </div>
                    </div>
                    <div style={{ ...hFieldStyle }}>
                        <label style={{ ...labelStyle }} className="col-sm-3 control-label"></label>
                        <div className="col-sm-9"
                             style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {!this.props.hideReset
                                && <Button
                                    style={{ marginRight: 10, color: Colors.baseColors.lightBlue}}
                                    disabled={this.props.pristine || this.props.submitting}
                                    type={"button"}
                                    onClick={(e) => this.props.reset()}>
                                    {getText("GenReset")}
                                </Button>}
                            <Button type="submit"
                                    style={{ color: "white", backgroundColor: Colors.baseColors.lightBlue, marginRight: "1em" }}>
                                {this.props.actionName}
                            </Button>
                        </div>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let editForm = ReduxForm.reduxForm({
    form: 'firmNewsEdit',
    enableReinitialize: true
})(FirmNewsForm) as any;

export const EditFirmNewsForm = connect((state: ApplicationState) =>
    ({
        initialValues: state.firmNews.firmNewses.find(fn => fn.firmNewsId === state.firmNews.editState.firmNewsId) ||
            defaultFormValues,
        portsZones: state.seed.portsZones,
        subscriptions: state.account.currentUser.clientModel.subscriptions
    } as FirmNewsFormOwnProps))(editForm) as any as React.ComponentClass<FirmNewsFormExternalProps>

let createForm = ReduxForm.reduxForm({
    form: 'firmNewsCreate',
    enableReinitialize: true
})(FirmNewsForm) as any;

export const CreateFirmNewsForm = connect((state: ApplicationState) =>
    ({
        initialValues: defaultFormValues,
        portsZones: state.seed.portsZones,
        subscriptions: state.account.currentUser.clientModel.subscriptions
    } as FirmNewsFormOwnProps))(createForm) as any as React.ComponentClass<FirmNewsFormExternalProps>