import { frtId, roadTransportId } from './constants';
import * as Api from '../api/api';
import { getChargeAmount } from '../utils/utils';
import * as _ from 'lodash';

interface ChargeSet {
    charges?: Array<Api.ChargeModel>;
}

interface Criteria {
    loadingCharge?: boolean;
    unLoadingCharge?: boolean;
    prepaidCharge?: boolean;
    collectCharge?: boolean;
    criteriaSizeTypes?: Array<Api.CriteriaSizeTypeModel>;
    chargeCriterias?: Array<Api.CriteriaModelChargeCriteriasEnum>;
    type?: Api.CriteriaModelTypeEnum;
    cbm?: number;
    ton?: number;
}


export function matchExactChriteria(chargeCriteria: Api.ChargeModelCriteriaEnum[], searchValues: Api.ChargeModelCriteriaEnum[]): boolean {

    if (searchValues.length !== chargeCriteria.length) {
        return false;
    }

    return searchValues.every(i => chargeCriteria.includes(i));
}


export class RatesCalculator {
    private mainchargeIds: Array<number>;
    public criteria: Criteria;
    public currencies: { [id: number]: Api.CurrencyModel };
    public sizeTypes: { [id: number]: Api.SizeTypeModel };

    constructor(
        currencies: { [id: number]: Api.CurrencyModel },
        sizeTypes: { [id: number]: Api.SizeTypeModel },
        criteria: Criteria) {

        this.mainchargeIds = [frtId, roadTransportId];
        this.criteria = criteria;
        this.currencies = currencies;
        this.sizeTypes = sizeTypes;
    }

    private chargeMatchUnit(charge: Api.ChargeModel, sizeTypeId?: number): boolean {
        return charge.unit !== "Bl" && ((this.criteria.type === "Fcl"
            && (charge.unit === "Ctr"
                || charge.unit === "Teu"
                || charge.sizeTypeId === sizeTypeId)
            && (!charge.containerType || (sizeTypeId && charge.containerType === this.sizeTypes[sizeTypeId].type)))
            || (this.criteria.type === "Lcl"
                && (charge.unit === "Cbm"
                    || charge.unit === "Ton"
                    || charge.unit === "Wm")));
    }

    private convertToKgs(weight: number, type: Api.CriteriaSizeTypeModelWeightUnitEnum) {
        if (type == "Ton") {
            return weight * 1000;
        }
        return weight;
    }

    private chargeMatchWeight(criteriaSize: Api.CriteriaSizeTypeModel, charge: Api.ChargeModel, chargeSet:Api.ChargeSetModel): boolean {
        if (charge.minWeight !== null || charge.maxWeight !== null) {

            if (criteriaSize != null && criteriaSize.weight >= 0) {
                var criteriaKgs = this.convertToKgs(criteriaSize.weight, criteriaSize.weightUnit);
                if (chargeSet.flags.some(x => x == "WithTare")) {
                    var size = this.sizeTypes[criteriaSize.sizeTypeId];
                    criteriaKgs = criteriaKgs + size.tare;
                }

                if (charge.minWeight !== null && criteriaKgs < charge.minWeight) {
                    return false;
                }
                if (charge.maxWeight !== null && charge.maxWeight <= criteriaKgs) {
                    return false;
                }

            }
        }

        return true;

    }

    private chargeIsSelected(charge: Api.ChargeModel): boolean {
        return (this.criteria.loadingCharge || charge.application !== "Origin")
            && (this.criteria.unLoadingCharge || charge.application !== "Destination")
            && (this.criteria.prepaidCharge !== false || charge.paymentOption !== "Prepaid")
            && (this.criteria.collectCharge !== false || charge.paymentOption !== "Collect")
            ;
    }

    private getLclUnit(charge: Api.ChargeModel): number {
        if (charge.unit === "Cbm")
            return this.criteria.cbm;
        if (charge.unit === "Ton")
            return this.criteria.ton;

        return Math.max(this.criteria.cbm, this.criteria.ton);
    }


    public findChargesToApplybySizeId(
        chargeSet: ChargeSet,
        sizeTypeId?: number,
        application: Api.ChargeModelApplicationEnum = null,
        chargeModifications: Array<ChargeModification> = []): Array<Api.ChargeModel> {
        let dummySize: Api.CriteriaSizeTypeModel = {
            sizeTypeId: sizeTypeId
        }
        return this.findChargesToApply(chargeSet, dummySize, application, chargeModifications);
    }


    public findChargesToApply(
        chargeSet: ChargeSet,
        sizeType: Api.CriteriaSizeTypeModel,
        application: Api.ChargeModelApplicationEnum = null,
        chargeModifications: Array<ChargeModification> = []): Array<Api.ChargeModel> {




        let charges = this.findChargeSetCharges(chargeSet, chargeModifications);
        let frts = charges.filter(ch => ch.chargeType === "Value"
            && this.chargeMatchUnit(ch, sizeType?.sizeTypeId)
            && this.chargeMatchWeight(sizeType, ch, chargeSet)
            && (application === null || ch.application === application)
            && this.mainchargeIds.some(x => ch.chargeNameId === x)
            && (this.criteria.type === "Lcl" || ((!this.criteria.chargeCriterias
                || this.criteria.chargeCriterias.length === 0)
                && !ch.criteria)
                || matchExactChriteria(ch.criteria, this.criteria.chargeCriterias)));


        if (application === null && frts.length === 0)
            return [];



        let surCharges = charges.filter(ch => ch.chargeType === "Value"
            && this.chargeMatchUnit(ch, sizeType?.sizeTypeId)
            && this.chargeMatchWeight(sizeType, ch, chargeSet)
            && this.chargeIsSelected(ch)
            && (application === null || ch.application === application)
            && !this.mainchargeIds.some(x => ch.chargeNameId === x)
            && (!ch.criteria || ch.criteria.length === 0 || (this.criteria.chargeCriterias && matchExactChriteria(ch.criteria, this.criteria.chargeCriterias))));
      
        let valueCharges = application
            ? this.findChargesToApply(chargeSet, sizeType, null, chargeModifications)
                .filter(x => x.chargeType === "Value")
            : frts.concat(surCharges);

        surCharges = surCharges.concat(charges.filter(ch => ch.chargeType === "Source"
            && this.chargeMatchUnit(ch, sizeType?.sizeTypeId)
            && this.chargeMatchWeight(sizeType, ch, chargeSet)
            && this.chargeIsSelected(ch)
            && (application === null
                || ch.application === application)
            && (!ch.criteria || ch.criteria.length === 0
                || (this.criteria.chargeCriterias
                    && matchExactChriteria(ch.criteria, this.criteria.chargeCriterias)))
            && valueCharges.some(och => och.chargeNameId === ch.sourceChargeNameId)));


        if (this.criteria.chargeCriterias && this.criteria.chargeCriterias.length > 0) {
            var chargeNamesIdsWithExactCriteria = charges.filter(ch => !this.mainchargeIds.some(x => ch.chargeNameId === x) && matchExactChriteria(ch.criteria, this.criteria.chargeCriterias)).map(ch => ch.chargeNameId);;

            surCharges = surCharges.filter(ch => (chargeNamesIdsWithExactCriteria.some(id => id == ch.chargeNameId) && matchExactChriteria(ch.criteria, this.criteria.chargeCriterias)) ||
                !chargeNamesIdsWithExactCriteria.some(id => id == ch.chargeNameId));
        }

        let chargesToApply = this.getModifiedCharges(chargeSet, frts.concat(surCharges), chargeModifications);

        if (this.criteria.type === "Lcl") {
            return _.map(_.groupBy(
                chargesToApply, x => x.chargeNameId.toString()),
                xs => _.sortBy(xs, x => getChargeAmount(x, this.getLclUnit(x))).reverse()[0]);
        }

        return chargesToApply;
    }

    public findBlChargesToApply(
        chargeSet: ChargeSet,
        application: Api.ChargeModelApplicationEnum = null,
        chargeModifications: Array<ChargeModification> = []): Array<Api.ChargeModel> {
        let charges = this.findChargeSetCharges(chargeSet, chargeModifications);
        let surCharges = charges.filter(ch => ch.chargeType === "Value"
            && (ch.unit === "Bl" || ch.unit === "Decl")
            && this.chargeIsSelected(ch)
            && (application === null || ch.application === application)
            && !this.mainchargeIds.some(x => ch.chargeNameId === x)
            && (!ch.criteria || ch.criteria.length === 0 || (this.criteria.chargeCriterias && matchExactChriteria(ch.criteria, this.criteria.chargeCriterias))));

        let valueCharges = application
            ? this.findBlChargesToApply(chargeSet, null, chargeModifications)
                .filter(x => x.chargeType === "Value")
            : surCharges;

        surCharges = surCharges.concat(charges.filter(ch => ch.chargeType === "Source"
            && (ch.unit === "Bl" || ch.unit === "Decl")
            && this.chargeIsSelected(ch)
            && (application === null || ch.application === application) &&
            (!ch.criteria || ch.criteria.length === 0 || (this.criteria.chargeCriterias && matchExactChriteria(ch.criteria, this.criteria.chargeCriterias))) &&
            (valueCharges.some(och => och.chargeNameId === ch.sourceChargeNameId)
                || this.mainchargeIds.some(x => ch.chargeNameId === x))));

        if (this.criteria.chargeCriterias && this.criteria.chargeCriterias.length > 0) {
            var chargeNamesIdsWithExactCriteria = charges.filter(ch => !this.mainchargeIds.some(x => ch.chargeNameId === x) && matchExactChriteria(ch.criteria, this.criteria.chargeCriterias)).map(ch => ch.chargeNameId);;

            surCharges = surCharges.filter(ch => (chargeNamesIdsWithExactCriteria.some(id => id == ch.chargeNameId) && matchExactChriteria(ch.criteria, this.criteria.chargeCriterias)) || !chargeNamesIdsWithExactCriteria.some(id => id == ch.chargeNameId));
        }


        return this.getModifiedCharges(chargeSet, surCharges, chargeModifications);
    }

    private findChargeSetCharges(
        chargeSet: ChargeSet,
        chargeModifications: Array<ChargeModification> = [])
        : Array<Api.ChargeModel> {
        return chargeSet.charges.filter(
            ch => !chargeModifications.some(cm =>
                cm.chargeNameId === ch.chargeNameId
                && (!cm.application || cm.application === ch.application)
                && cm.modificationType === "Exclude"))
            .concat(chargeModifications
                .filter(cm => cm.modificationType === "Add")
                .map(cm => ({
                    name: cm.name,
                    amount: cm.amount,
                    application: cm.application,
                    unit: cm.unit,
                    criteria: [cm.criteria],
                    currencyId: cm.currencyId,
                    chargeType: "Value",
                    sizeTypeId: cm.sizeTypeId
                } as Api.ChargeModel)));
    }

    private getModifiedCharges(
        chargeSet: ChargeSet,
        charges: Array<Api.ChargeModel>,
        chargeModifications: Array<ChargeModification> = [])
        : Array<Api.ChargeModel & { margin?: number }> {
        let modifiedCharges = charges.map(ch => {
            let appliedChargeModifications = chargeModifications.filter(cm =>
                cm.chargeNameId === ch.chargeNameId
                && (!cm.originalChargeId || cm.originalChargeId === ch.chargeId) //podpoara pre level na charge
                && (!cm.application || cm.application === ch.application)
                && (!ch.sizeTypeId || ch.sizeTypeId === cm.sizeTypeId)
                && ((cm.modificationType === "AddAmount" && ch.chargeType === "Value")
                    || cm.modificationType === "ReplaceAmount"
                    || cm.modificationType === "ReplaceAsMargin"));

            if (appliedChargeModifications.length === 0)
                return ch;

            let modificationToApply = appliedChargeModifications[0];
            if (modificationToApply.modificationType === "AddAmount")
                return {
                    ...ch,
                    amount: ch.amount + modificationToApply.amount,
                } as Api.ChargeModel;
            else if (modificationToApply.modificationType === "ReplaceAmount")
                return {
                    ...ch,
                    amount: modificationToApply.amount,
                    currencyId: modificationToApply.currencyId
                        || ch.currencyId,
                    chargeType: "Value"
                } as Api.ChargeModel;
            else if (modificationToApply.modificationType === "ReplaceAsMargin")
                return {
                    ...ch,
                    amount: ch.amount,
                    margin: modificationToApply.amount - ch.amount,
                    currencyId: modificationToApply.currencyId
                        || ch.currencyId,
                    chargeType: "Value"
                } as Api.ChargeModel & { margin?: number };
        });

        return modifiedCharges.map(ch => {
            //We set the currency for percentage charges that become values
            if (!ch.currencyId
                && ch.sourceChargeNameId) {
                //Try to get modified charge first which seems more logical
                let sourceCharge = _.sortBy(modifiedCharges
                    .filter(x => x.chargeNameId === ch.sourceChargeNameId
                        && x.chargeType === "Value"),
                    x => x.sizeTypeId === ch.sizeTypeId).reverse()[0]
                    || this.findChargesToApplybySizeId(chargeSet, ch.sizeTypeId, null, [])
                        .find(x => x.chargeNameId === ch.sourceChargeNameId
                            && x.chargeType === "Value")
                    || chargeSet.charges.find(x => x.chargeNameId === ch.sourceChargeNameId
                        && x.chargeType === "Value");
                return {
                    ...ch,
                    currencyId: sourceCharge.currencyId
                };
            }

            return ch;
        });
    }

    public calculatePrice(charges: Array<Api.ChargeModel & { margin?: number }>, teu: number): CurrencyResultSet {
        let currencyResultSet = new CurrencyResultSet();

        charges
            .filter(ch => ch.chargeType === "Value")
            .forEach(ch => {
                currencyResultSet.add(new CurrencyResult(this.currencies[ch.currencyId],
                    getChargeAmount(ch, this.criteria.type === "Lcl"
                        ? this.getLclUnit(ch)
                        : teu),
                    ch.margin
                        ? (ch.margin * (ch.unit === "Teu" ? teu : 1))
                        : 0));
            });
        //Percentage charges
        charges.filter(ch => ch.chargeType === "Source" && ch.type === "Percentage").forEach(ch => {
            let source = charges.find(och => och.chargeType === "Value"
                && och.chargeNameId === ch.sourceChargeNameId);
            if (source) {
                currencyResultSet.add(new CurrencyResult(this.currencies[source.currencyId],
                    getChargeAmount(source, teu) * ch.amount,
                    ch.margin
                        ? (ch.margin * (ch.unit === "Teu" ? teu : 1))
                        : 0));
            }
        });

        return currencyResultSet;
    }

    public calculateAllIn(chargeSet: ChargeSet,
        chargeModifications: Array<ChargeModification> = []): CurrencyResultSet {
        let currencyResultSet = new CurrencyResultSet();
        if (this.criteria.type === "Fcl") {
            this.criteria.criteriaSizeTypes
                .filter(cst => cst.number > 0)
                .forEach(cst => {
                    currencyResultSet.addSet(
                        this.calculatePrice(this.findChargesToApply(chargeSet, cst, null, chargeModifications), this.sizeTypes[cst.sizeTypeId].teu),
                        cst.number);
                });
        } else {
            currencyResultSet.addSet(
                this.calculatePrice(this.findChargesToApply(chargeSet, null, null, chargeModifications), 1),
                1);
        }

        currencyResultSet.addSet(
            this.calculatePrice(this.findBlChargesToApply(chargeSet, null, chargeModifications), 1));
        return currencyResultSet;
    }
}

export interface ChargeModification {
    currencyId?: number;
    chargeNameId?: number;
    sizeTypeId?: number;
    modificationType: ChargemodificationType;
    amount?: number;
    name?: string;
    level?: number;
    originalChargeId?: number;
    application?: Api.ChargeAgentModelApplicationEnum;
    unit?: Api.ChargeAgentModelUnitEnum;
    criteria?: Api.ChargeAgentModelCriteriaEnum;
}

type ChargemodificationType = "AddAmount" | "Exclude" | "ReplaceAmount" | "Add" | "ReplaceAsMargin";

export class CurrencyResultSet {
    constructor() {
        this.currencyResults = [];
    }

    public currencyResults: Array<CurrencyResult>;

    public add(currencyResult: CurrencyResult, mult: number = 1) {
        for (let i = 0; i < mult; i++) {
            var existingCr =
                this.currencyResults
                    .find(cr => cr.currency.currencyId == currencyResult.currency.currencyId);
            if (existingCr != null) {
                existingCr.add(currencyResult);
            }
            else {
                this.currencyResults.push(new CurrencyResult(
                    currencyResult.currency,
                    currencyResult.amount,
                    currencyResult.margin));
            }
        }
    }

    public addSet(currencyResultSet: CurrencyResultSet, mult: number = 1) {
        currencyResultSet.currencyResults.forEach(currencyResult => {
            this.add(currencyResult, mult);
        });
    }

    public get totalUsd(): number { return this.currencyResults.reduce((sum, cr) => sum + cr.totalUsd, 0); }
}


export class CurrencyResult {
    constructor(currency: Api.CurrencyModel, amount: number, margin: number = 0) {
        this.currency = currency;
        this.amount = amount;
        this.margin = margin;
        this.currencyValue = currency.value;
    }

    public currency: Api.CurrencyModel;
    public amount: number;
    public margin: number;
    //Can be different than currency.value (changed by user)
    public currencyValue: number;

    public get total(): number { return this.amount + this.margin; }
    public get totalUsd(): number { return this.total * this.currencyValue; }

    public add(currencyResult: CurrencyResult) {
        this.amount += currencyResult.amount;
        this.margin += currencyResult.margin;
    }
}