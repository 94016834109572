import { CSSProperties } from "react";
import * as colors from "./colors";
import React from "react";

export const hFormStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: 9
};

export const vFormStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: 9
};

export const hFieldStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: 9
};

export const vFieldStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: 9
};

export const hLabelStyle: CSSProperties = {
    flex: "0 0 auto",
    textAlign: "right",
    minWidth: 200
};
export const hInputStyle: CSSProperties = {
    flex: "1"
};

export const pageStyle: React.CSSProperties = { padding: "calc(1vw + 10px) calc(2vw + 20px)", boxSizing: "border-box" };

export const linkStyle: React.CSSProperties = { textDecoration: "none", color: "inherit", cursor: "pointer"  };
export const activeLinkStyle: React.CSSProperties = { ...linkStyle, fontWeight: "bold"  };

export const titleStyle: React.CSSProperties = { fontWeight: "bold", fontSize: "calc(1.5vw + 15px)"  };
export const smTitleStyle: React.CSSProperties = { fontWeight: "bold", fontSize: "calc(1vw + 10px)"  };

export const labelStyle: React.CSSProperties = { fontSize: "calc(0.8vw + 10px)", textAlign: "right" };
export const smLabelStyle: React.CSSProperties = { ...labelStyle, fontSize: "calc(0.5vw + 5px)"  };

export const valueStyle: React.CSSProperties = { fontSize: "calc(0.8vw + 10px)" };
export const smValueStyle: React.CSSProperties = { ...valueStyle, fontSize: "calc(0.5vw + 5px)"  };

export const inputStyle: React.CSSProperties = { padding: "0.2vw 0.4vw", fontSize: "calc(0.6vw + 8px)", border: "1px solid", borderRadius: "4px" };
export const smInputStyle: React.CSSProperties = { ...inputStyle, padding: "0.15vw 0.25vw", fontSize: "calc(0.4vw + 6px)" };

export const buttonStyle: React.CSSProperties = { cursor: "pointer", fontSize: "calc(0.6vw + 8px)", padding: "0.2vw 0.4vw", };
export const smButtonStyle: React.CSSProperties = { cursor: "pointer", fontSize: "calc(0.4vw + 6px)", padding: "0.15vw 0.25vw", };

export const textareaStyle: React.CSSProperties = {  };
export const errorStyle: React.CSSProperties = { color: "darkred"  };