import * as React from 'react';
import * as InlandSelectionStore from '../store/InlandSelection';
import * as InlandCriteriaState from '../store/InlandCriteria';
import * as Api from '../api/api';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getSubscription } from '../utils/utils';
import { RatesCalculator } from '../utils/ratesUtils';
import InlandOfferTable from './InlandOfferTable';
import { getText } from '../utils/langTexts';
import { colors } from '../styles/colors';

type InlandSelectionOffersTableProps =
    InlandSelectionOffersOwnProps
    & InlandSelectionStore.InlandSelectionState
    & typeof InlandSelectionStore.actionCreators
    & typeof InlandCriteriaState.actionCreators
    & InlandSelectionOffersExternalProps

interface InlandSelectionOffersExternalProps {
    ratesCalculator: RatesCalculator;
    inlandCarrierOffersFiltered: Array<Api.InlandCarrierOfferModel>;
}

interface InlandSelectionOffersOwnProps {
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    inlandCarriers: { [id: number]: Api.InlandCarrierModel };
    carrierGroups: { [id: number]: Api.CarrierGroupModel };
    client: Api.ClientModel;
    inlandCriteriaLoaded?: Api.CriteriaModel;
    inlandCriteria: InlandCriteriaState.InlandCriteriaState;
    subscription: Api.SubscriptionModel;
}

const importantMessageStyle: React.CSSProperties = {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center"
};

class InlandSelectionOffersTable extends React.Component<InlandSelectionOffersTableProps, {}> {
    constructor(props) {
        super(props);
    }
    getInlandOfferKey = (offer: Api.InlandCarrierOfferModel): string => {
        let chargeSetId = offer.inlandChargeSet ? offer.inlandChargeSet.inlandChargeSetId : 0;
        return `R${offer.inlandRoute.inlandRouteId}C${chargeSetId}`;
    }
    scrollToOfferDeparts(inlandOfferId: string) {
        // this.props.selectInlandCarrierOffer(inlandOfferId);
        this.props.selectPage(Math.floor(this.props.inlandCarrierOffersFiltered.findIndex(x => this.getInlandOfferKey(x) === inlandOfferId) / 6) + 1);
        setTimeout(() => this.props.selectInlandCarrierOffer(inlandOfferId), 0);
    }
    public render() {
        return (
            <div>
                {this.props.errorMessage &&
                    <div style={{
                        ...importantMessageStyle,
                        marginBottom: 10
                    }}>{this.props.errorMessage}</div>}
                <div style={{ marginBottom: 10 }}>
                    <InlandOfferTable
                        ratesCalculator={this.props.ratesCalculator}
                        carrierRatesStates={this.props.carrierRatesStates}
                        isLoading={this.props.isLoading}
                        currencies={this.props.currencies}
                        inlandCarriers={this.props.inlandCarriers}
                        sizeTypes={this.props.sizeTypes}
                        inlandCriteria={this.props.inlandCriteria.inlandCriteria}
                        inlandSelection={this.props.inlandSelection}
                        currencyId={this.props.client.inlandCurrencyId}
                        inlandCarrierOffers={this.props.inlandCarrierOffersFiltered}
                        onOfferClick={(key) => this.scrollToOfferDeparts(key)} />
                </div>
                <div >
                    <div>
                        <div>
                            <span style={{ color: colors.main3.color }}>
                                <b>{this.props.inlandCarrierOffersFiltered.length}</b> {getText("SlcOffersCount")}
                            </span>
                            <div style={{ width: 400, marginLeft: 5, display: "inline-block" }}>
                            </div>
                        </div>
                    </div>
                    {this.props.warningMessage &&
                        <div
                            style={{
                                ...importantMessageStyle,
                                marginBottom: 10,
                                marginTop: 10
                            }}>{this.props.warningMessage}</div>}
                </div>
            </div>
        );
    }

}

export default connect((state: ApplicationState) => {
    let subscription = getSubscription(state.account.currentUser.clientModel)
        || { subscriptionType: null };
    return {

        ...state.inlandSelection,
        sizeTypes: state.seed.sizeTypes,
        currencies: state.seed.currencies,
        chargeNames: state.seed.chargeNames,
        inlandCarriers: state.seed.inlandCarriers,
        carrierGroups: state.seed.carrierGroups,
        client: state.account.currentUser.clientModel,
        inlandCriteria: state.inlandCriteria,
        inlandCriteriaLoaded: state.inlandSelection.inlandCriteriaLoaded,
        subscription: subscription,
    }
},
    {
        ...InlandSelectionStore.actionCreators,
        ...InlandCriteriaState.actionCreators
    }
)(InlandSelectionOffersTable as any as React.ComponentClass<InlandSelectionOffersExternalProps>)