import * as React from 'react';
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import * as Colors from '../styles/colors';

interface DialogModalProps {
    style?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
    contentLabel?: string;
    maxWidth?: "sm" | "md" | "xs" | "lg" | "xl" | false;
    fullScreen?: boolean;
    isOpen: boolean;
    hideClose?: boolean;
    children: any;
    onRequestClose: () => void;
}

export default class DialogModel extends React.Component<DialogModalProps, {}> {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    public render() {
        return (
            <Dialog open={this.props.isOpen}
                style={{ ...this.props.style }}
                PaperProps={{ style: { border: `1px solid ${Colors.baseColors.darkGray}` }, elevation: 0 }}
                fullWidth={true}
                maxWidth={this.props.maxWidth}
                BackdropProps={{ style: { backgroundColor: "white", opacity: 0.5 } }}
                onClose={(e, reason) => this.props.onRequestClose()}>
                <div style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    padding: "10px 10px",
                    color: Colors.baseColors.lightBlue
                }}>
                    <Close style={{ cursor: "pointer" }} onClick={(e) => this.props.onRequestClose()} />
                </div>
                <div style={{ ...this.props.contentStyle, padding: "0px 10px 10px 10px" }}>
                    {this.props.children}
                </div>
            </Dialog>
            );
    }
}