import * as React from 'react';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import ModeratorRatesOffers from "./ModeratorRatesOffers";
import ModeratorWorkDocuments from "./ModeratorWorkDocuments";
import ModeratorWorkDocumetsUpload from "./ModeratorWorkDocumetsUpload";
import ModeratorFirmNews from "./ModeratorFirmNews";
import ModeratorFirmDocument from "./ModeratorFirmDocument";
import ModeratorAgentPortal from "./ModeratorAgentPortal";
import ModeratorUsers from "./ModeratorUsers";
import ModeratorSellingRates from "./ModeratorSellingRates";
import ModeratorInlandRatesOffer from "./ModeratorInlandRatesOffer";
import ModeratorCompanySettings from "./ModeratorCompanySettings";
import ModeratorCarrierCarrierRating from "./ModeratorCarrierCarrierRating";
import CarrierRatesOfferInformation from './CarrierRatesOfferInformation';
import { Route, Switch } from 'react-router-dom';
import * as Colors from "../styles/colors";
import { getText } from '../utils/langTexts';
import { NavLink } from 'react-router-dom';
import { canModerateRates, canModerateInformations, canModerateAgents, canUseCarrierRates, canManageUsers, canUploadRates } from '../security/userRights';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

type ModeratorProps = ModeratorOwnProps;

interface ModeratorOwnProps {
    subscriptions: Array<Api.SubscriptionModel>;
}

const containerStyle = {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
} as React.CSSProperties;

const navContainerStyle = {
    marginTop: 50,
    order: 1,
    flex: "0 0 200",
    border: `1px solid ${Colors.baseColors.darkGray}`,
    borderRadius: 8
} as React.CSSProperties;
const navInnerContainerStyle = { paddingTop: 20, paddingBottom: 100 } as React.CSSProperties;
const contentContainerStyle = { height: "100%", order: 2, flex: 1, display: "flex", flexDirection: "column" } as React.CSSProperties;
const innerContentStyle = { padding: "20px 50px" } as React.CSSProperties;
const navStyle = { padding: "9px 10px", width: "100%", textAlign: "center" } as React.CSSProperties;
const navLinkTabStyle = { fontSize: "20px", fontWeight: "bold", textAlign: "left" } as React.CSSProperties;
const navLinkActiveStyle = { color: Colors.baseColors.lightBlue, fontWeight: "bold" } as React.CSSProperties;
const moderatorHomeStyle = { testAlign: "center", paddingTop: 50 } as React.CSSProperties;
const homeStyle = { fontSize: "20px", textAlign: "center", width: "100%", fontWeight:"bold" } as React.CSSProperties;

class ModeratorHome extends React.Component<{}, {}> {
    public render() {
        return (
            <div style={moderatorHomeStyle}>
                {getText("MdtHomeMessage")}
            </div>
        );
    }
}

class Moderator extends React.Component<ModeratorProps, {}> {
    public render() {
        return (
            <div style={containerStyle}>
                <div style={{ flex: "0 0 auto", 
                    width: 20 }}></div>
                <div style={navContainerStyle}>
                    <List style={navInnerContainerStyle}>
                        <ListItem>
                            <ListItemText disableTypography style={{ ...navLinkTabStyle, color: Colors.baseColors.lightBlue }} primary="General" />
                        </ListItem>
                        
                        <NavLink className={"navlink"} activeStyle={navLinkActiveStyle} exact to="/moderator/news">
                            <ListItem button style={navStyle}>
                                <span style={navStyle}>
                                    {getText("MdtNews")}
                                </span>

                            </ListItem>
                        </NavLink>


                        {this.props.subscriptions.some(x => canModerateInformations(x)) &&
                            <NavLink className={"navlink"} activeStyle={navLinkActiveStyle} to="/moderator/documents">
                                <ListItem button style={navStyle}>
                                        <span style={navStyle}>
                                            {getText("MdtDocuments")}
                                        </span>
                                </ListItem>
                            </NavLink>
                        }

                        {this.props.subscriptions.some(x => canModerateInformations(x)) &&
                            <NavLink className={"navlink"} activeStyle={navLinkActiveStyle} to="/moderator/companySettings">
                                <ListItem button style={navStyle}>
                                        <span style={navStyle}>
                                            {getText("MdtCompanySettings")}
                                        </span>
                                </ListItem>
                            </NavLink>}

                        {this.props.subscriptions.some(x => canManageUsers(x)) &&
                            <NavLink className={"navlink"} activeStyle={navLinkActiveStyle} to="/moderator/users">
                                <ListItem button style={navStyle}>
                                    <span style={navStyle}>
                                        {getText("MdtUsers")}
                                    </span>
                                </ListItem>
                            </NavLink>}

                        
                        {this.props.subscriptions.some(x => canModerateAgents(x)) &&
                            <NavLink className={"navlink"} activeStyle={navLinkActiveStyle} to="/moderator/agents">
                                <ListItem button style={navStyle}>
                                        <span style={navStyle}>
                                            {getText("MdtAgents")}
                                        </span>
                                </ListItem>
                            </NavLink>
                        }
                        
                        <Divider variant="middle" />

                        <ListItem>
                            <ListItemText disableTypography style={{ ...navLinkTabStyle, color: Colors.baseColors.lightBlue }} primary="FCL" />
                        </ListItem>
                        
                        {this.props.subscriptions.some(x => canModerateInformations(x)) &&
                            <NavLink className={"navlink"} exact to="/moderator/ratesOffers" activeStyle={navLinkActiveStyle}>
                                <ListItem button style={navStyle}>
                                    <span style={navStyle}>
                                        {getText("MdtRatesInfo")}
                                    </span>
                                </ListItem>
                            </NavLink>
                        }

                        {this.props.subscriptions.some(x => canModerateRates(x)) &&
                            <NavLink className={"navlink"} activeStyle={navLinkActiveStyle} exact to="/moderator/workDocuments">
                                <ListItem button style={navStyle}>
                                        <span style={navStyle}>
                                            {getText("MdtRatesDocument")}
                                        </span>
                                </ListItem>
                            </NavLink>}
                        
                        {this.props.subscriptions.some(x => canModerateInformations(x) && canUseCarrierRates(x)) &&
                            <NavLink className={"navlink"} activeStyle={navLinkActiveStyle} to="/moderator/carrierRatesOfferInformation">
                                <ListItem button style={navStyle}>

                                        <span style={navStyle}>
                                            {getText("MdtCarrierQuotesRemarks")}
                                        </span>
                                </ListItem>
                            </NavLink>
                        
                        }
                        {this.props.subscriptions.some(x => canModerateInformations(x)) && 
                            <NavLink className={"navlink"} activeStyle={navLinkActiveStyle} to="/moderator/carrierRatings">
                                <ListItem button style={navStyle}>
                                        <span style={navStyle}>
                                        {getText("MdtCarrierRatings")}
                                        </span>
                                </ListItem>
                            </NavLink>
                        }
                        {this.props.subscriptions.some(x => canModerateRates(x)) &&
                            <NavLink className={"navlink"} activeStyle={navLinkActiveStyle} to="/moderator/sellingrates">
                                <ListItem button style={navStyle}>
                                        <span style={navStyle}>
                                            {getText("MdtSellingRates")}
                                        </span>
                                </ListItem>
                            </NavLink>
                        }
                        {this.props.subscriptions.some(x => canUploadRates(x)) &&
                            <NavLink className={"navlink"} activeStyle={navLinkActiveStyle} to="/moderator/uploadPriceList">
                                <ListItem button style={navStyle}>
                                        <span style={navStyle}>
                                            Self upload
                                        </span>
                                </ListItem>
                            </NavLink>
                        }
                    </List>
                </div>
                <div style={contentContainerStyle}>
                    <div style={innerContentStyle}>
                        <Switch>
                            <Route path="/moderator/companySettings" component={ModeratorCompanySettings} />
                            <Route path="/moderator/agents" component={ModeratorAgentPortal} />
                            <Route path="/moderator/users" component={ModeratorUsers} />
                            <Route path="/moderator/ratesOffers" component={ModeratorRatesOffers} />
                            <Route path="/moderator/inlandratesoffers" component={ModeratorInlandRatesOffer} />
                            <Route path="/moderator/workDocuments" component={ModeratorWorkDocuments} />
                            <Route path="/moderator/news" component={ModeratorFirmNews} />
                            <Route path="/moderator/documents" component={ModeratorFirmDocument} />
                            <Route path="/moderator/carrierRatings" component={ModeratorCarrierCarrierRating as any} />
                            <Route path="/moderator/sellingrates" component={ModeratorSellingRates} />
                            <Route path='/moderator/carrierRatesOfferInformation' component={CarrierRatesOfferInformation} />
                            <Route path='/moderator/uploadPriceList' component={ModeratorWorkDocumetsUpload} />
                            {/*<Route path='/moderator/priceList' component={PriceListStatus} />*/}
                            <Route path="/moderator" component={ModeratorHome as any} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    subscriptions: state.account.currentUser.clientModel
        ? state.account.currentUser.clientModel.subscriptions
        : []
}), null)(Moderator) 