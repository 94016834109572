import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { styled, withStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';
import * as Colors from "../styles/colors";
import Paper from "@material-ui/core/Paper";

const CustomPaper = (props) => {
    return <Paper style={{ width: "200%" }} {...props} />;
};
const OkSelect = (props) => <Select {...props} InputProps={{ ...props, disableUnderline: true }}></Select>;

const autocomplete = (props) => <Autocomplete  {...props} disableUnderline></Autocomplete>;

const AutocompletestyledSelect = styled(autocomplete)({
    "& .MuiOutlinedInput-input": {
        padding: "4px 32px 4px 8px"
    },
    "& .MuiSvgIcon-root": {
        color: Colors.baseColors.lightBlue,
    }
});
const StyledMenuItem = withStyles({
    root: {
        '&:hover': {
            color: Colors.baseColors.lightBlue,
            fontWeight: "bold"
        }
    }
})(MenuItem);

const StyledSelect = styled(OkSelect)({
    "& .MuiOutlinedInput-input": {
        padding: "4px 32px 4px 8px"
    },
    "& .MuiSvgIcon-root": {
        color: Colors.baseColors.lightBlue,
    }
});
type SelectProps = {
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    standard?: boolean;
    options: Array<Option>;
    multiple?: boolean;
    disabled?: boolean;
    search?: boolean;
    value: any | Array<any>;
    nullLabel?: string;
    onChange: (value: any | Array<any>) => void;
    optionComponent?: (opt: Option) => any;
}

export interface Option {
    label: string;
    value: any;
}

export default class extends React.Component<SelectProps, {}> {
    public constructor(props) {
        super(props);
    }
    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: SelectProps) {
    }

    IdToLabel() {
        var temp = ""
        if (this.props.value.label) {
            return this.props.value.label
        } else {
            this.props.options.map((value: Option) => {
                if (value.value === this.props.value) {
                    temp = value.label
                    return value.label
                }
            });
            return temp;
        }
    }
    value() {
        if (this.props.value == "" || !this.props.value) {
            return null;
        } else {
            return this.IdToLabel()
        }
    }
    
    selectedLabel(): string {
        let selected = this.props.options.find(x => x.value == this.props.value);
        return selected?.label || "";
    }
    public render() {
        return (
            this.props.search ?
                <AutocompletestyledSelect
                    options={this.props.options}
                    getOptionLabel={(option, data) => {
                        return option?.label?.toString() || option;
                    }}
                    getOptionSelected={(option, value) => option.value === value}
                    value={this.value()}
                    style={{ minWidth: 80, ...this.props.style, display: "inline-block" }}
                    size="small"
                    PaperComponent={CustomPaper}
                    renderInput={(params) => {
                        return (<TextField {...params} value={this.props.nullLabel && this.props.value === null ? this.props.nullLabel : this.props.value} variant="outlined" />);
                    }} //add label here to make it into the research box
                    onChange={(e, value: Option | null) => {
                        if (value) {
                            this.props.onChange(value.value)
                            e.stopPropagation();
                        } else {
                            this.props.onChange("")
                        }
                    }}
                /> :
                <StyledSelect
                    variant={this.props.standard ? "standard" : "outlined"}
                    style={{ minWidth: 80, ...this.props.style }}
                    SelectDisplayProps={this.props.inputStyle
                        ? { style: this.props.inputStyle }
                        : {}}
                    displayEmpty
                    value={this.props.value ? this.props.value : null}
                    onChange={(e) => {
                        this.props.onChange(e.target.value);
                        e.stopPropagation();
                    }}>
                    {this.props.options.map(opt =>
                        <StyledMenuItem key={`${opt.label}|${opt.value}`} value={opt.value}>
                            {this.props.optionComponent && this.props.optionComponent(opt)}
                            {!this.props.optionComponent && opt.label}
                        </StyledMenuItem>
                    )}
                </StyledSelect>
        );
    }
}



        //return <ReactSelect.default
        //    className={this.props.className}
        //    disabled={this.props.disabled}
        //    dropdownStyle={this.dropDownStyle}
        //    dropdownMenuStyle={{ maxHeight: "300" }}
        //    filterOption={((value: string, option: { key: string }) => {
        //        return !option.key || option.key.toLocaleLowerCase().includes((value || "").toLocaleLowerCase());
        //    }) as any}
        //    multiple={this.props.multiple || false}
        //    value={!this.props.multiple
        //        ? (this.selectedOptions.length !== 0 ? this.selectedOptions[0].label : null)
        //        : this.selectedOptions.map(opt => opt.label)}
        //    onChange={(label: string | Array<string>) => this.handleChange(label)}>
        //    {this.props.options.map(opt =>
        //        <ReactSelect.Option
        //            key={opt.label}
        //            value={opt.label}>
        //            {this.props.optionComponent
        //                ? this.props.optionComponent(opt)
        //                : <div style={{ display: "inline-block" }}>
        //                    {opt.label}
        //                </div>}
        //        </ReactSelect.Option>
        //        )}
        //</ReactSelect.default>