import * as React from 'react';
import * as InlandSelectionStore from '../store/InlandSelection';
import * as Api from '../api/api';
import * as _ from 'lodash';
import * as SelectionStore from '../store/Selection';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import CheckBox from './CheckBox';
import Button from './Button';


type InlandSelectionFilterProps = InlandSelectionFilterOwnProps
    & typeof InlandSelectionStore.actionCreators;

interface InlandSelectionFilterOwnProps {

    filterModel: SelectionStore.SelectionFilterModel;
    inlandCarriers: { [id: number]: Api.InlandCarrierModel };
    filter: InlandSelectionStore.InlandSelectionFilter;
    inlandCarrierOffers: Array<Api.InlandCarrierOfferModel>;
    carriers: { [id: number]: Api.CarrierModel };
    carrierOffers: Array<Api.CarrierOfferModel>;

}

interface CarrierSelected {
    carrierId: number;
    isSelected: boolean;
}

const checkboxLabelStyle: React.CSSProperties = {
    fontSize: "14px" 
}

class InlandSelectionFilter extends React.Component<InlandSelectionFilterProps, {}> {
    get allInlandCarriersSelected(): boolean {
        return !_.uniq(this.props.inlandCarrierOffers.map(x => x.inlandCarrierId))
            .some(x => this.props.filter.inlandCarriersSelected[x] === false);
    }

    get inlandCarriersAvailable(): Array<Api.InlandCarrierModel> {
        return _.sortBy(_.uniq(this.props.inlandCarrierOffers
            .map(x => x.inlandCarrierId))
            .map(x => this.props.inlandCarriers[x]), x => x.name);
    }

    public render() {
        return (
            <div style={{ margin: "10px 0px" }}>
                <div style={{ fontSize: 20, marginBottom: 10, marginTop: 10 }}>Carriers</div>
                <Button
                    onClick={() => {
                        this.props.updateInlandAllCarrierSelected(this.inlandCarriersAvailable.some(x => this.props.filter.inlandCarriersSelected[x.inlandCarrierId] !== false) ? false : true);
                    }} style={{
                        height: 28, minWidth: 90, fontSize: 13
                    }}>{this.inlandCarriersAvailable.some(x => this.props.filter.inlandCarriersSelected[x.inlandCarrierId] !== false)
                        ? "Unselect all"
                        : "Select all"}</Button>
                <div style={{ margin: "auto", width: "50%" }}>
                    {this.inlandCarriersAvailable.map(inlandCarrier => (
                        <div style={{ margin: "5px 0px" }}>
                            <CheckBox
                                labelStyle={checkboxLabelStyle}
                                size={"small"}
                                value={this.props.filter.inlandCarriersSelected[inlandCarrier.inlandCarrierId] !== false}
                                label={this.props.inlandCarriers[inlandCarrier.inlandCarrierId].name}
                                onChange={(value) => this.props.updateInlandCarrierSelected(inlandCarrier.inlandCarrierId, value)} />
                        </div>
                    ))}
                </div>

            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    filter: state.inlandSelection.filter,
    inlandCarriers: state.seed.inlandCarriers,
    inlandCarrierOffers: state.inlandSelection.inlandSelection
        ? state.inlandSelection.inlandSelection.inlandCarrierOffers
        : []
} as any), {
    ...InlandSelectionStore.actionCreators
})(InlandSelectionFilter) as any;