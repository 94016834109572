import * as React from 'react';
import * as Api from '../api/api';
import { getText } from '../utils/langTexts';

interface ModeratorAgentSearchDetailsProps {
    agentSearch: Api.AgentSearchModel;
}

const fieldStyle = {};
const labelStyle = {};
const valueStyle = { display: "inline-block", paddingTop: 7 };

export default class ModeratorAgentSearchDetails extends React.Component<ModeratorAgentSearchDetailsProps, {}> {
    public render() {
        return (
            <div>
                {this.props.agentSearch
                    && <div className="form-horizontal">
                        <div style={fieldStyle} className="form-group row">
                            <label style={labelStyle}
                                className="col-md-4 control-label">{getText("MdtAgentsUser")}</label>
                            <div className="col-md-6">
                                <span style={valueStyle}>{this.props.agentSearch.agentEmail}</span>
                            </div>
                        </div>
                        <div style={fieldStyle} className="form-group row">
                            <label style={labelStyle}
                                className="col-md-4 control-label">{getText("CrtAgentConsignee")}</label>
                            <div className="col-md-6">
                                <span style={valueStyle}>{this.props.agentSearch.criteria.criteriaAgent.consigneeName}</span>
                            </div>
                        </div>
                        <div style={fieldStyle} className="form-group row">
                            <label style={labelStyle}
                                className="col-md-4 control-label">{getText("CrtAgentShipper")}</label>
                            <div className="col-md-6">
                                <span style={valueStyle}>{this.props.agentSearch.criteria.criteriaAgent.shipperName}</span>
                            </div>
                        </div>
                        <div style={fieldStyle} className="form-group row">
                            <label style={labelStyle}
                                className="col-md-4 control-label">{getText("CrtAgentSpot")}</label>
                            <div className="col-md-6">
                                <span style={valueStyle}>{this.props.agentSearch.criteria.criteriaAgent.isSpotOperation
                                    ? getText("GenYes") : getText("GenNo")}</span>
                            </div>
                        </div>
                        <div style={fieldStyle} className="form-group row">
                            <label style={labelStyle}
                                className="col-md-4 control-label">{getText("CrtAgentVolume")}</label>
                            <div className="col-md-6">
                                <span style={valueStyle}>{this.props.agentSearch.criteria.criteriaAgent.volume}</span>
                            </div>
                        </div>
                        <div style={fieldStyle} className="form-group row">
                            <label style={labelStyle}
                                className="col-md-4 control-label">{getText("CrtAgentIncoterm")}</label>
                            <div className="col-md-6">
                                <span style={valueStyle}>{this.props.agentSearch.criteria.criteriaAgent.incoterm}</span>
                            </div>
                    </div>
                    <div style={fieldStyle} className="form-group row">
                        <label style={labelStyle}
                            className="col-md-4 control-label">{getText("CrtAgentCargoDescription")}</label>
                        <div className="col-md-6">
                            <span style={{ ...valueStyle, whiteSpace: "pre-wrap" }}>{this.props.agentSearch.criteria.criteriaAgent.cargoDescription}</span>
                        </div>
                    </div>
                        <div style={fieldStyle} className="form-group row">
                            <label style={labelStyle}
                                className="col-md-4 control-label">{getText("CrtAgentComment")}</label>
                            <div className="col-md-6">
                                <span style={{ ...valueStyle, whiteSpace: "pre-wrap" }}>{this.props.agentSearch.criteria.criteriaAgent.comment}</span>
                            </div>
                        </div>
                    </div>}
            </div>
            );
    }
}