import * as React from 'react';
import * as Colors from "../styles/colors";
import { KeyboardArrowUp } from "@material-ui/icons";
import { scrollToAppTop } from '../utils/utils';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';

type ScrollToTopProps = ScrollToTopOwnProps;

interface ScrollToTopOwnProps {
    scrollPosition: number;
}

interface ScrollToTopState {
}

class ScrollToTop extends React.Component<ScrollToTopProps, ScrollToTopState> {
    constructor(props: ScrollToTopProps) {
        super(props);
        this.state = {
        };
    }


    public render() {
        return (<div style={2 <= this.props.scrollPosition
            ? { position: "fixed", bottom: 70, right: 100 }
            : { display: "none" }}>
            <div style={{
                backgroundColor: "white",
                color: Colors.baseColors.lightBlue,
                border: `1px solid ${Colors.baseColors.lightBlue}`,
                width: 50,
                height: 50,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                borderTopRightRadius: 5,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer"
            }} onClick={() => scrollToAppTop()}>
                <div style={{ textAlign: "center", width: "100%" }}>
                    <KeyboardArrowUp style={{ fontSize: 25 }} />
                </div>
            </div>
        </div>);
    }
}

export default connect((state: ApplicationState) => ({
    scrollPosition: state.scroll.scrollPosition
}), {})(ScrollToTop) as any;