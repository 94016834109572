import * as React from 'react';
import CarrierCarrierRatings from "./CarrierCarrierRatings";
import { getText } from '../utils/langTexts';

export default class ModeratorCarrierCarrierRating extends React.Component<{}, {}> {
    public render() {
        return (
            <div>
                <h2>{getText("MdtCarrierRatings")}</h2>
                <div>
                    <CarrierCarrierRatings />
                </div>
            </div>
            );
    }
}