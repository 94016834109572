import * as React from 'react';
import * as Colors from '../styles/colors';
import { Tooltip } from "@material-ui/core";
import { styled } from '@material-ui/core/styles';

const StyledTooltip = styled(Tooltip)({
    "& .MuiTooltip-tooltip": {
        backgroundColor: "white !important",
        color: `${Colors.baseColors.darkGray} !important`,
        maxWidth: "600px !important"
    },
});

interface TooltipProps {
    children: any;
    trigger?: Array<string>;
    style?: React.CSSProperties;
    disableHoverListener?: boolean;
    placement?: 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
    onlySourceTrigger?: boolean;
    visible?: boolean;
    overlay: any;
    hideDelay?: number;
    showDelay?: number;
}

interface TooltipState {
    visible: boolean;
}

const defaultTigger = ["hover"];

export default class OkTooltip extends React.Component<TooltipProps, TooltipState> {
    constructor(props: TooltipProps) {
        super(props);
        this.state = {
            visible: false
        };
    }

    get visible(): boolean {
        return this.props.visible
            || this.state.visible;
    }

    public render() {
        return (
            <StyledTooltip
                {...(this.props.visible !== undefined
                    || this.props.onlySourceTrigger
                    ? { open: this.visible }
                    : {})}
                style={{ maxWidth: 600 }}
                disableHoverListener={this.props.disableHoverListener}
                PopperProps={{ style: { ...this.props.style } }}
                placement={this.props.placement || "bottom"}
                interactive={true}
                title={<div onClick={(e) => { e.stopPropagation(); }}>{this.props.overlay}</div>}
                enterDelay={(this.props.showDelay || 0) * 1000}
                leaveDelay={(this.props.hideDelay || 0.1) * 1000}>
                <div>
                    {this.props.children}
                </div>
            </StyledTooltip>
        );
    }
}